import React, { useState, useContext, useEffect } from 'react';
import './styles/Request.css'; // Importing custom CSS for styling
import Header from '../components/Header'; // Importing Header component
import Footer from '../components/Footer'; // Importing Footer component
import { useNavigate, useParams } from 'react-router-dom'; // Importing navigation hooks
import { UserContext } from '../UserContext'; // Importing UserContext to get user information
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import {FaCloudUploadAlt} from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { getAutocompleteSuggestions } from '../components/PlacesAutocomplete';
import useDebounce from '../components/UseDebounce';

function Request() {
  const { user, token } = useContext(UserContext); // Getting user and token from UserContext
  
  const navigate = useNavigate(); // Hook for navigation
  const { requestId } = useParams(); // Get request ID from URL params

  // Initial form state
  const [fileName,setFileName]=useState(null);
  const [fileUrl,setFileUrl]=useState('');
  const [formData, setFormData] = useState({
    supportNeeded: '',
    individuals: [{ name: '', type: '', project: '', vertical: '', purpose: '', otherPurpose: '' }],
    source: '',
    destination: '',
    fromDate: '',
    toDate: '',
    batchId: '',
    center: '',
    client: '',
    costCenter: '',
    otherCostCenter:'',
    approvedFrom: '',
    approvedDate: '',
    approvedProof: null,
    email: user ? user.email : '',
    userId: user ? user.id : null,
    courierType: '',
    otherCourierType: '',
    officeLocation: user.officeLocation || '',
    fromLocation: '',
    toLocation: '',
    sentFrom: '',
    receivedBy: '',
    receiverEmail: '',
    receivedOn: '',
    podNumber: '',
    courierCo: '',
    requesterRemarks: '',
  });
  //For Editing Request
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [file, setFile] = useState(null); // Track file upload state
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState({
    source: false,
    destination: false,
  });

  // Use the custom debounce hook
  const debouncedInput = useDebounce(formData.source, 250); // Debounce delay of 1 second

  // Fetch suggestions when the debounced input value changes
  useEffect(() => {
    if (debouncedInput.trim()) {
      const fetchSuggestions = async () => {
        try {
          const suggestions = await getAutocompleteSuggestions(debouncedInput);
          setSourceSuggestions(suggestions);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      };

      fetchSuggestions();
    } else {
      setSourceSuggestions([]); // Clear suggestions if input is empty
      setShowSuggestions((prev) => ({ ...prev, source: false }));
    }
  }, [debouncedInput]); // Dependency array ensures this runs when debouncedInput changes

  // Use the custom debounce hook
  const debouncedDestination = useDebounce(formData.destination, 500); // Debounce delay of 1 second

  // Fetch suggestions when the debounced input value changes
  useEffect(() => {
    if (debouncedDestination.trim()) {
      const fetchSuggestions = async () => {
        try {
          const suggestions = await getAutocompleteSuggestions(debouncedDestination);
          setDestinationSuggestions(suggestions);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      };

      fetchSuggestions();
    } else {
      setDestinationSuggestions([]); // Clear suggestions if input is empty
      setShowSuggestions((prev) => ({ ...prev, destination: false }));
    }
  }, [debouncedDestination]); // Dependency array ensures this runs when debouncedInput changes

  // Effect to fetch request data if editing an existing request
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (requestId) {
          // Fetch existing request data for editing
          const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch request data');
          }
  
          const data = await response.json();
          setFormData({
            supportNeeded: data.supportNeeded,
            individuals: data.individuals.map(individual => {
              const isOthers = individual.purpose !== 'Assessment' && individual.purpose !== 'Meeting';
              return {
                name: individual.name || '',
                type: individual.type || '',
                project: individual.project || '',
                vertical: individual.vertical || '',
                purpose: isOthers ? 'Others' : individual.purpose,
                otherPurpose: isOthers ? individual.purpose : '',
              };
            }),
            source: data.source || '',
            destination: data.destination || '',
            fromDate: data.fromDate ? data.fromDate.split('T')[0] : '',
            toDate: data.toDate ? data.toDate.split('T')[0] : '',
            batchId: data.batchId || '',
            center: data.center || '',
            client: data.client || '',
            costCenter: data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? 'Others' : data.costCenter,
            otherCostCenter:data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? data.costCenter : '',
            approvedFrom: data.approvedFrom || '',
            approvedDate: data.approvedDate ? data.approvedDate.split('T')[0] : '',
            approvedProof: data.approvedProof || null,
            email: data.email,
            userId:data.userId,
            courierType: data.courierType!=='Document'&&data.courierType!=='Package' ? 'Others' : data.courierType,
            otherCourierType:data.courierType!=='Document'&&data.courierType!=='Package' ? data.courierType : '',
            officeLocation: data.officeLocation || '',
            fromLocation: data.fromLocation || '',
            toLocation: data.toLocation || '',
            sentFrom: data.sentFrom || '',
            receivedBy: data.receivedBy || '',
            receiverEmail: data.receiverEmail || '',
            receivedOn: data.receivedOn ? data.receivedOn.split('T')[0] : '',
            podNumber: data.bookingDetails.podNumber || '',
            courierCo: data.bookingDetails.courierCo || '',
            requesterRemarks: data.requesterRemarks || '',
          });
          setName(data.approvedProof.split('$')[0] || '');
          setUrl(data.approvedProof.split('$')[1] || '');
        }
      } catch (error) {
        console.error('Error fetching request data:', error);
      }
    };
  
    fetchData(); // Call fetchData function
  
  }, [requestId, token, user]);

  // Handle suggestion click
  const handleSuggestionClick = (type, suggestion) => {
    if(type==='source'){
      setFormData((prevData) => ({ ...prevData, source: suggestion.description }));
      setShowSuggestions((prev) => ({ ...prev, source: false })); // Hide suggestions after selection
    }else {
      setFormData((prevData) => ({ ...prevData, destination: suggestion.description }));
      setShowSuggestions((prev) => ({ ...prev, destination: false })); // Hide suggestions after selection
    }
  };

  // Handle input changes
  const handleInputChange = async (e, index = null) => {
    const { name, value } = e.target;

    if (name === 'fromDate') {
      // Validate fromDate (should be today or later)
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      if (value < today) {
        notifyError(NotifyMessages.fromDateError,'bottom-center');
        return; // Prevent updating formData if validation fails
      }
    } else if (name === 'toDate') {
      // Validate toDate (should not be before fromDate)
      if (formData.fromDate && value < formData.fromDate) {
        notifyError(NotifyMessages.toDateError,'bottom-center');
        return; // Prevent updating formData if validation fails
      }
    } 
    
    if (index !== null) {
      const updatedIndividuals = formData.individuals.map((individual, i) => {
        if (i === index) {
          const updatedIndividual = { ...individual, [name]: value };
          if (name === 'purpose' && value === 'Meeting') {
            updatedIndividual.project = 'Office Meeting';
            updatedIndividual.vertical = 'Office Meeting';
          }
          return updatedIndividual;
        }
        return individual;
      });
      setFormData({ ...formData, individuals: updatedIndividuals });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file=e.target.files[0];
    setFileName(file.name);
    const url=URL.createObjectURL(file);
    setFileUrl(url);
    setFormData({ ...formData, approvedProof: file });
  };
  //Handle delete input file
  const handleDeleteFile = (e) => {
    setFileName('');
    setFileUrl('');
    setFormData({ ...formData, approvedProof: null });
  };
  // Add a new individual to the individuals array
  const addIndividual = () => {
    setFormData({
      ...formData,
      individuals: [...formData.individuals, { name: '', type: '', project: '', vertical: '', purpose: '', otherPurpose: '' }],
    });
  };

  // Remove an individual from the individuals array
  const removeIndividual = (index) => {
    const updatedIndividuals = formData.individuals.filter((_, i) => i !== index);
    setFormData({ ...formData, individuals: updatedIndividuals });
  };

  //handle upload function while editing
  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile); // Store the file in state
      setName(uploadedFile.name);
      const temp=URL.createObjectURL(uploadedFile);
      setUrl(temp);
    }
  };

  const handleFileDelete = async() => {
    setFile(null);
    setName('');
    setUrl('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(requestId){
      if (!formData.supportNeeded.includes('Courier') && (!name || !url)) {
        notifyError(NotifyMessages.approvedProof, 'bottom-center');
        return;
       }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
          method:'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
        }

        notifySuccess(NotifyMessages.requestEditSuccess, 'bottom-center');
        if(((formData.supportNeeded==='Travel') || (formData.supportNeeded==='Accommodation') || (formData.supportNeeded==='Travel + Accommodation' ))){
          setFormSubmitted(true); // Mark form as submitted
        }
        setTimeout(() => {
          navigate('/'); // Navigate to home or appropriate page after success
        }, 2000);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }
    else{
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key === 'individuals') {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else if (key === 'approvedProof') {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
      let response;
      if(formData.supportNeeded.includes('Courier')){
        response = await fetch(`${process.env.REACT_APP_API_URL}/requests/courier-details`, {
          method:'POST',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });
      }else {
        if(!fileName || !fileUrl){
          notifyError(NotifyMessages.approvedProof, 'bottom-center');
          return;
        }
        response = await fetch(`${process.env.REACT_APP_API_URL}/requests`, {
          method:'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formDataToSend,
        });
      }

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const responseData = await response.json();
      notifySuccess(NotifyMessages.requestSubmitSuccess,'bottom-center');
      if(((formData.supportNeeded==='Travel') || (formData.supportNeeded==='Accommodation') || (formData.supportNeeded==='Travel + Accommodation' ))){
        setTimeout(() => {
          requestId ? navigate(`/planner/${requestId}`) : navigate(`/planner/${responseData.id}`);
        }, 2000)
      }
      else{
        setTimeout(() => {
          navigate('/'); // Navigate to home or appropriate page after success
        }, 2000);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
   }
  };
  
  useEffect(() => {
    if (formSubmitted && file) {
      const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/approval-details/upload/${requestId}`, {
            method:'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body:formData,
          });
    
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
          }

          const data = await response.json();
          setFormData({
              supportNeeded: data.supportNeeded,
              individuals: data.individuals.map(individual => {
                const isOthers = individual.purpose !== 'Assessment' && individual.purpose !== 'Meeting';
                return {
                  name: individual.name || '',
                  type: individual.type || '',
                  project: individual.project || '',
                  vertical: individual.vertical || '',
                  purpose: isOthers ? 'Others' : individual.purpose,
                  otherPurpose: isOthers ? individual.purpose : '',
                };
              }),
              source: data.source || '',
              destination: data.destination || '',
              fromDate: data.fromDate ? data.fromDate.split('T')[0] : '',
              toDate: data.toDate ? data.toDate.split('T')[0] : '',
              batchId: data.batchId || '',
              center: data.center || '',
              client: data.client || '',
              costCenter: data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? 'Others' : data.costCenter,
              otherCostCenter:data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? data.costCenter : '',
              approvedFrom: data.approvedFrom || '',
              approvedDate: data.approvedDate ? data.approvedDate.split('T')[0] : '',
              approvedProof: data.approvedProof || null,
              email: data.email,
              userId:data.userId,
              courierType: data.courierType!=='Document'&&data.courierType!=='Package' ? 'Others' : data.courierType,
              otherCourierType:data.courierType!=='Document'&&data.courierType!=='Package' ? data.courierType : '',
              officeLocation: data.officeLocation || '',
              fromLocation: data.fromLocation || '',
              toLocation: data.toLocation || '',
              sentFrom: data.sentFrom || '',
              receivedBy: data.receivedBy || '',
              receiverEmail: data.receiverEmail || '',
              receivedOn: data.receivedOn ? data.receivedOn.split('T')[0] : '',
              podNumber: data.bookingDetails.podNumber || '',
              courierCo: data.bookingDetails.courierCo || '',
              requesterRemarks: data.requesterRemarks || '',
            });
            setName(data.approvedProof.split('$')[0] || '');
            setUrl(data.approvedProof.split('$')[1] || '');
        } catch (error) {
          console.error(`Error updating booking details for request ${requestId}:`, error);
        }
      };
      uploadFile(); // Upload the file only after form submission
    }
  }, [formSubmitted, file, requestId, token]);

  // Conditional rendering based on supportNeeded
  const renderFieldsBasedOnSupport = () => {
    switch (formData.supportNeeded) {
      case 'Travel':
        return (
          <>
            <fieldset>
              <legend>Route Details:</legend>
              <label>
                Source:
                <input
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={(e) => {
                    handleInputChange(e);
                    // If input is not empty, keep showing suggestions
                    if (e.target.value.trim()) {
                      setShowSuggestions((prev) => ({ ...prev, source: true }));
                    } else {
                      // If input is empty, hide suggestions
                      setShowSuggestions((prev) => ({ ...prev, source: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions((prev) => ({ ...prev, source: false })), 250)}
                  required
                  className="route-input"
                />
              </label>
              {/* Render suggestions dropdown */}
              {showSuggestions.source && sourceSuggestions && sourceSuggestions.length > 0 && (
                <ul className="suggestions-dropdown">
                  {sourceSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick('source', suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}

              <label>
                Destination:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={(e) => {
                    handleInputChange(e);
                    // If input is not empty, keep showing suggestions
                    if (e.target.value.trim()) {
                      setShowSuggestions((prev) => ({ ...prev, destination: true }));
                    } else {
                      // If input is empty, hide suggestions
                      setShowSuggestions((prev) => ({ ...prev, destination: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions((prev) => ({ ...prev, destination: false })), 250)}
                  required
                  className="route-input"
                />
              </label>
              {/* Render suggestions dropdown */}
              {showSuggestions.destination && destinationSuggestions && destinationSuggestions.length > 0 && (
                <ul className="suggestions-dropdown">
                  {destinationSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick('destination', suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
              <label>
                Date of Journey:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Remarks(Optional):
                <input
                  type="text"
                  name="requesterRemarks"
                  value={formData.requesterRemarks}
                  onChange={handleInputChange}
                />
              </label>
            </fieldset>
          </>
        );
      case 'Accommodation':
        return (
          <>
            <fieldset>
              <legend>Route Details:</legend>
              <label>
                Location:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={(e) => {
                    handleInputChange(e);
                    // If input is not empty, keep showing suggestions
                    if (e.target.value.trim()) {
                      setShowSuggestions((prev) => ({ ...prev, destination: true }));
                    } else {
                      // If input is empty, hide suggestions
                      setShowSuggestions((prev) => ({ ...prev, destination: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions((prev) => ({ ...prev, destination: false })), 250)}
                  required
                  className="route-input"
                />
              </label>
              {/* Render suggestions dropdown */}
              {showSuggestions.destination && destinationSuggestions && destinationSuggestions.length > 0 && (
                <ul className="suggestions-dropdown">
                  {destinationSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick('destination', suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
              <label>
                From Date:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                To Date:
                <input
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Remarks(Optional):
                <input
                  type="text"
                  name="requesterRemarks"
                  value={formData.requesterRemarks}
                  onChange={handleInputChange}
                />
              </label>
            </fieldset>
          </>
        );
      case 'Travel + Accommodation':
        return (
          <>
            <fieldset>
              <legend>Route Details:</legend>
              <label>
                Source:
                <input
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={(e) => {
                    handleInputChange(e);
                    // If input is not empty, keep showing suggestions
                    if (e.target.value.trim()) {
                      setShowSuggestions((prev) => ({ ...prev, source: true }));
                    } else {
                      // If input is empty, hide suggestions
                      setShowSuggestions((prev) => ({ ...prev, source: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions((prev) => ({ ...prev, source: false })), 250)}
                  required
                  className="route-input"
                />
              </label>
              {/* Render suggestions dropdown */}
              {showSuggestions.source && sourceSuggestions && sourceSuggestions.length > 0 && (
                <ul className="suggestions-dropdown">
                  {sourceSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick('source', suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}

              <label>
                Destination:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={(e) => {
                    handleInputChange(e);
                    // If input is not empty, keep showing suggestions
                    if (e.target.value.trim()) {
                      setShowSuggestions((prev) => ({ ...prev, destination: true }));
                    } else {
                      // If input is empty, hide suggestions
                      setShowSuggestions((prev) => ({ ...prev, destination: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions((prev) => ({ ...prev, destination: false })), 250)}
                  required
                  className="route-input"
                />
              </label>
              {/* Render suggestions dropdown */}
              {showSuggestions.destination && destinationSuggestions && destinationSuggestions.length > 0 && (
                <ul className="suggestions-dropdown">
                  {destinationSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick('destination', suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
              <label>
                From Date:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                To Date:
                <input
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Remarks(Optional):
                <input
                  type="text"
                  name="requesterRemarks"
                  value={formData.requesterRemarks}
                  onChange={handleInputChange}
                />
              </label>
            </fieldset>
          </>
        );
      default:
        return null;
    }
  };
// Conditional rendering based on supportNeeded
const renderCourierSupport = () => {
  switch (formData.supportNeeded) {
    case 'Send Courier':
      return (
        <>
          <fieldset>
            <legend>Send Courier:</legend>
            <label>
              Courier Type:
              <select
                name="courierType"
                value={formData.courierType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Courier-type</option>
                <option value="Document">Document</option>
                <option value="Package">Package</option>
                <option value="Others">Others</option>
              </select>
            </label>
            {formData.courierType === 'Others' && (
              <label>
                Mention Courier-type if selected Others:
                <input
                  type="text"
                  name="otherCourierType"
                  value={formData.otherCourierType}
                  onChange={handleInputChange}
                  required
                />
              </label>
            )}
            <label>
              Sender Name:
              <input
                type="text"
                name="sentFrom"
                value={formData.sentFrom}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Receiver Name:
              <input
                type="text"
                name="receivedBy"
                value={formData.receivedBy}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Complete Source Address:
              <input
                type="text"
                name="fromLocation"
                value={formData.fromLocation}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Complete Destination Address:
              <input
                type="text"
                name="toLocation"
                value={formData.toLocation}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Remarks(Optional):
              <input
                type="text"
                name="requesterRemarks"
                value={formData.requesterRemarks}
                onChange={handleInputChange}
              />
            </label>
          </fieldset>
        </>
      );
    case 'Receive Courier':
      return (
        <>
          <fieldset>
          <legend>Recieve Courier:</legend>
            <label>
              Courier Type:
              <select
                name="courierType"
                value={formData.courierType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Courier-type</option>
                <option value="Document">Document</option>
                <option value="Package">Package</option>
                <option value="Others">Others</option>
              </select>
            </label>
            {formData.courierType === 'Others' && (
              <label>
                Mention Courier-type if selected Others:
                <input
                  type="text"
                  name="otherCourierType"
                  value={formData.otherCourierType}
                  onChange={handleInputChange}
                  required
                />
              </label>
            )}
            <label>
              Sender Name:
              <input
                type="text"
                name="sentFrom"
                value={formData.sentFrom}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Receiver Name:
              <input
                type="text"
                name="receivedBy"
                value={formData.receivedBy}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Receiver Email:
              <input
                type="email"
                name="receiverEmail"
                value={formData.receiverEmail}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Complete Source Address:
              <input
                type="text"
                name="fromLocation"
                value={formData.fromLocation}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Complete Destination Address:
              <input
                type="text"
                name="toLocation"
                value={formData.toLocation}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Received On:
              <input
                type="date"
                name="receivedOn"
                value={formData.receivedOn}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Proof of Delivery(POD) Number:
              <input
                type="text"
                name="podNumber"
                value={formData.podNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Courier Company:
              <input
                type="text"
                name="courierCo"
                value={formData.courierCo}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Remarks(Optional):
              <input
                type="text"
                name="requesterRemarks"
                value={formData.requesterRemarks}
                onChange={handleInputChange}
              />
            </label>
          </fieldset>
        </>
      );
    default:
      return null;
  }
};
  return (
    <div className="Request">
      <Header />
      <ToastContainer />
      <main className="main-content">
        <form className="reqForm" onSubmit={handleSubmit}>
          <h1>{requestId ? 'Edit Request' : 'Request Details'}</h1>

          <fieldset>
            <legend>Requested For:</legend>
            <label>
              Support Needed:
              <select
                name="supportNeeded"
                value={formData.supportNeeded}
                onChange={handleInputChange}
                required
              >
                <option value="">Select the support needed</option>
                <option value="Travel">Travel</option>
                <option value="Accommodation">Accommodation</option>
                <option value="Travel + Accommodation">Travel + Accommodation</option>
                <option value="Send Courier">Send Courier</option>
                {user && user.role !== 'USER' && 
                 <option value="Receive Courier">Receive Courier</option>
                }
              </select>
            </label>
            
            {(formData.supportNeeded.includes('Courier'))
            && renderCourierSupport()}

            {((formData.supportNeeded==='Travel') || (formData.supportNeeded==='Accommodation') || (formData.supportNeeded==='Travel + Accommodation' ))
            &&
            <>
            <label>
              Batch ID:
              <input
                type="text"
                name="batchId"
                value={formData.batchId}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Center:
              <input
                type="text"
                name="center"
                value={formData.center}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Client:
              <input
                type="text"
                name="client"
                value={formData.client}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Cost Center:
              <select
                name="costCenter"
                value={formData.costCenter}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Cost-Center</option>
                <option value="Govt-Central">Govt-Central</option>
                <option value="Govt-State">Govt-State</option>
                <option value="Govt-Others">Govt-Others</option>
                <option value="Others">Others</option>
              </select>
            </label>
            {formData.costCenter === 'Others' && (
              <label>
                Mention Cost-Center if selected Others:
                <input
                  type="text"
                  name="otherCostCenter"
                  value={formData.otherCostCenter}
                  onChange={handleInputChange}
                  required
                />
              </label>
            )}
            {formData.individuals.map((individual, index) => (
              <div key={index} className="individual">
                <h3>Individual {index + 1}</h3>
                <label>
                  Individual Name:
                  <input
                    type="text"
                    name="name"
                    value={individual.name}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  />
                </label>
                <label>
                  Individual Type:
                  <select
                    name="type"
                    value={individual.type}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <option value="">Select Individual type</option>
                    <option value="Assessor">Assessor</option>
                    <option value="Proctor">Proctor</option>
                    <option value="SME">SME</option>
                    <option value="Employee">Employee</option>
                    <option value="Employee_Assessor">Employee-Assessor</option>
                    <option value="Trainer">Trainer</option>
                    <option value="Counselor">Counselor</option>
                    <option value="Intern">Intern</option>
                  </select>
                </label>
                <label>
                  Purpose:
                  <select
                    name="purpose"
                    value={individual.purpose}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <option value="">Select Purpose</option>
                    <option value="Assessment">Assessment</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Others">Others</option>
                  </select>
                </label>
                {individual.purpose === 'Others' && (
                  <label>
                    Mention Purpose if selected Others:
                    <input
                      type="text"
                      name="otherPurpose"
                      value={individual.otherPurpose}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </label>
                )}
                {(individual.purpose === 'Assessment' || individual.purpose === 'Others') && (
                    <>
                      <label>
                        Project:
                        <input
                          type="text"
                          name="project"
                          value={individual.project}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </label>
                      <label>
                        Vertical:
                        <input
                          type="text"
                          name="vertical"
                          value={individual.vertical}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </label>
                    </>
                )}
                <button
                  type="button"
                  onClick={() => removeIndividual(index)}
                  className="delete-button"
                >
                  Delete
                </button>
              </div>
            ))}
            <button type="button" onClick={addIndividual} className="add-button">
              Add Individual
            </button>
            </>}
          </fieldset>

          {((formData.supportNeeded==='Travel') || (formData.supportNeeded==='Accommodation') || (formData.supportNeeded==='Travel + Accommodation' ))
            &&
            <fieldset>
            <legend>Approval Details:</legend>
            <label>
              Approved From:
              <input
                type="text"
                name="approvedFrom"
                value={formData.approvedFrom}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Approved Date:
              <input
                type="date"
                name="approvedDate"
                value={formData.approvedDate}
                onChange={handleInputChange}
                required
              />
            </label>
            {requestId ? 
                  <div className='upload'>
                  <label htmlFor={`fileUpload_${requestId}`} className="upload-button">
                  <strong>Approval Proof:</strong> {!name & !url && <FaCloudUploadAlt size={20} />} 
                  <span>{name && url ? 
                      <span className='view-download'>
                      <a href={url} target='_blank' rel="noopener noreferrer">
                        {name}
                      </a>
                      </span> 
                  : <p className='upload-icon'>Upload</p>}</span>
                  <input
                      name="file"
                      type="file"
                      onChange={(e) => handleFileUpload(e,requestId)}
                      id={`fileUpload_${requestId}`}
                  />
                  </label>
                  {name && url && 
                    <span className='delete-upload'>
                    <MdDelete onClick={handleFileDelete}/>
                    </span>
                  }
              </div> 
              : 
              <div className='upload'>
              <label htmlFor='file' className="upload-button">
                  Approved Proof: {!fileUrl && <FaCloudUploadAlt size={20} />} 
                  <span>{fileUrl ? 
                    <span className='view-download'>
                      <a href={fileUrl} target='_blank' rel="noopener noreferrer">
                        {fileName}
                      </a>
                    </span> 
                  : <p className='upload-icon'>Upload</p>}</span>
                  <input
                    type="file"
                    id="file"
                    name="approvedProof"
                    onChange={handleFileChange}
                  />
              </label>
              {fileUrl && 
                  <span className='delete-upload'>
                  <MdDelete onClick={handleDeleteFile}/>
                  </span>
              }
              </div>
            }
            
          </fieldset>}

          {renderFieldsBasedOnSupport()}

          <button type="submit" className='submit-button'>
            Submit
          </button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default Request;
