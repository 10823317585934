import React, { useState, useEffect, useContext } from 'react';
import './styles/ViewRequest.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Tabs from '../components/Tabs/Tabs';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import { IoMdArrowBack } from "react-icons/io";
import {FaPlus} from 'react-icons/fa';
import { IoMdSend } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import moment from 'moment';

//Formatting default date
const formatDate=(date) => {
  const temp=date.split('T')[0];
  return moment(temp).format('DD-MM-YYYY');
}
const ViewEditRequests = () => {
  //states to store the data
  const navigate = useNavigate();
  const { user, token } = useContext(UserContext);
  const [myOpenRequests, setMyOpenRequests] = useState([]);
  const [myClosedRequests, setMyClosedRequests] = useState([]);
  const [allOpenRequests, setAllOpenRequests] = useState([]);
  const [allClosedRequests, setAllClosedRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remarksMap, setRemarksMap] = useState({}); // State to store remarks for each requestId
  const [selected, setSelected] = useState({});  
  
  useEffect(() => {
    if (user) {
      fetchMyRequests();
      if (user.role !== 'USER') {
        fetchAllRequests();
      }
    }
  }, [user, token]);

  //fetching my request data for the user
  const fetchMyRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/all/${user.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const responseData = await response.json();
      categorizeMyRequests(responseData);
      initializeRemarks(responseData);
    } catch (error) {
      console.error('Error fetching my requests:', error);
    } finally {
      setLoading(false);
    }
  };

  //fetching all the request data for the admin access
  const fetchAllRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const responseData = await response.json();
      categorizeAllRequests(responseData);
      initializeRemarks(responseData);
    } catch (error) {
      console.error('Error fetching all requests:', error);
    } finally {
      setLoading(false);
    }
  };

  //categorize the open and closed forms
  const categorizeMyRequests = (requests) => {
    const open = [];
    const closed = [];
    const today = moment().startOf('day');

    requests.forEach((request) => {
      let toDatePassed = false;
      if (request.supportNeeded === 'Travel') {
        toDatePassed = moment(request.fromDate).isBefore(today, 'day');
      } else {
        toDatePassed = moment(request.toDate).isBefore(today, 'day');
      }
  
      if (toDatePassed) {
        closed.push(request);
      } else {
        open.push(request);
      }
    });

    setMyOpenRequests(open);
    setMyClosedRequests(closed);
  };

  const categorizeAllRequests = (requests) => {
    const open = [];
    const closed = [];
    const today = moment().startOf('day');

    requests.forEach((request) => {
      let toDatePassed = false;
      if (request.supportNeeded === 'Travel') {
        toDatePassed = moment(request.fromDate).isBefore(today, 'day');
      } else {
        toDatePassed = moment(request.toDate).isBefore(today, 'day');
      }
  
      if (toDatePassed) {
        closed.push(request);
      } else {
        open.push(request);
      }
    });

    setAllOpenRequests(open);
    setAllClosedRequests(closed);
  };
  //Initializing RemarksMap
  const initializeRemarks = (requests) => {
    const initialRemarksMap = {};
    requests.forEach((request) => {
      initialRemarksMap[request.id] = request.adminRemarks || [];
    });
    setRemarksMap(initialRemarksMap);
  };

  //edit request
  const handleEditRequest = (requestId) => {
    navigate(`/create-request/${requestId}`);
  };

  //delete request
  const handleDeleteRequest = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/booking-details/${requestId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      setMyOpenRequests(myOpenRequests.filter(request => request.id !== requestId));
      setMyClosedRequests(myClosedRequests.filter(request => request.id !== requestId));
      notifySuccess(NotifyMessages.requestDeleted,'bottom-center');
    } catch (error) {
      console.error(`Error deleting request ${requestId}:`, error);
    }
  };

  //accept request
  const handleAcceptRequest = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}/accept`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const updatedRequest = await response.json();
      notifySuccess(NotifyMessages.requestAccepted,'bottom-center');
      // Update state locally
      setMyOpenRequests(myOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
      setAllOpenRequests(allOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
      if(!updatedRequest.supportNeeded.includes('Courier')){
        setTimeout(() => {
          navigate(`/planner/${requestId}`);
        }, 2000);
      }
    } catch (error) {
      console.error(`Error accepting request ${requestId}:`, error);
    }
  };

  //deny request
  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      notifySuccess(NotifyMessages.requestDenied,'bottom-center');
      // Update state locally
      const updatedRequest = await response.json();
      setMyOpenRequests(myOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
      setAllOpenRequests(allOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
    } catch (error) {
      console.error(`Error denying request ${requestId}:`, error);
    }
  };

  const handleSuccess = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}/booking-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ bookingStatus: 'Success' })
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      notifySuccess(NotifyMessages.bookingStatusSuccess, 'bottom-center');
      const updatedRequest = await response.json();
      setMyOpenRequests(myOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
      setAllOpenRequests(allOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
    } catch (error) {
      console.error(`Error updating booking status for request ${requestId}:`, error);
    }
  };
  
  const handleFail = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}/booking-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ bookingStatus: 'Fail' })
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }
  
      notifySuccess(NotifyMessages.bookingStatusFail, 'bottom-center');
      const updatedRequest = await response.json();
      setMyOpenRequests(myOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
      setAllOpenRequests(allOpenRequests.map(request => 
        request.id === requestId ? updatedRequest : request
      ));
    } catch (error) {
      console.error(`Error updating booking status for request ${requestId}:`, error);
    }
  };

 // Example of handling remarks change
 const handleChangeRemarks = (event, requestId, index) => {
  const { value } = event.target;
  setRemarksMap((prevRemarksMap) => {
    const currentRemarks = Array.isArray(prevRemarksMap[requestId])
      ? [...prevRemarksMap[requestId]]
      : []; // Ensure it's an array

    currentRemarks[index] = value;
    return {
      ...prevRemarksMap,
      [requestId]: currentRemarks,
    };
  });
};

// Example of adding a new remark
const handleAddRemark = (requestId) => {
  setRemarksMap((prevRemarksMap) => {
    const currentRemarks = Array.isArray(prevRemarksMap[requestId])
      ? prevRemarksMap[requestId]
      : []; // Ensure it's an array

    return {
      ...prevRemarksMap,
      [requestId]: [...currentRemarks, ''], // Add a new empty remark
    };
  });
};
//deleting remarks
const handleDeleteRemark = (requestId, index) => {
  setRemarksMap((prevRemarksMap) => {
    const currentRemarks = Array.isArray(prevRemarksMap[requestId])
      ? [...prevRemarksMap[requestId]]
      : []; // Ensure it's an array

    // Remove the remark at the specified index
    currentRemarks.splice(index, 1);

    return {
      ...prevRemarksMap,
      [requestId]: currentRemarks,
    };
  });
};
//sending remarks to backend w.r.t requestId
const sendRemarks = async (requestId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/sendRemarks/${requestId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ remarks: remarksMap[requestId] }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
    }

    notifySuccess(NotifyMessages.remarksSent,'bottom-center');
  } catch (error) {
    notifyError('Error sending remarks', 'bottom-center');
  }
};
  //tabs for viewing the requests
  const tabData = user.role !== 'USER' ? [
    { label: "My Requests" },
    { label: "All Requests" },
  ] : [
    { label: "My Requests" },
  ];

  const subTabData = [
    { label: "Open" },
    { label: "Closed" },
  ];

  return (
    <div className="ViewEditRequests">
      <Header />
      <ToastContainer />
      <main className='main-view' >
        <div className='createbtn'>
          <button className={`create-request-button ${selected ? 'blur' : ''}`} onClick={() => navigate('/create-request')}>
              <FaPlus className='plus-icon'/>
              <p>Create Request</p>
          </button>
        </div>
      
        <Tabs tabs={tabData}>
          <div>
            <Tabs tabs={subTabData}>
            <div>
                {loading ? (
                  <p className="no-req">Loading...</p>
                ) : myOpenRequests.length === 0 ? (
                  <p className="no-req">No Requests Found</p>
                ) : (
                  <div className="requests-list"> 
                    {myOpenRequests.map((request, index) => (
                      <div key={index}>
                      <div key={request.id} className={`request-card ${selected === request ? 'selected' : ''}`}>
                        <div>
                          <h3>Request ID: {request.id}</h3>  
                          <button className='full-details' onClick={() => setSelected(request)}>Show more</button> 
                        </div>
                        <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                        <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                        <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                      </div>
                      <div className='whole-hide'>
                      {request.supportNeeded.includes('Courier') ? 
                        <div className='hided-details'>
                          <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                          <div>
                            <h3>Request ID: {request.id}</h3>  
                            <span><Link to={`/courier-details/${request.id}`} className='full-details'>View full details</Link></span>  
                          </div>
                          <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                          <div><strong>Courier Type:</strong> {request.courierType}</div>
                          <div><strong>Office Location:</strong> {request.officeLocation}</div>
                          <div className='location'><strong>Source Address:</strong> <span>{request.fromLocation}</span></div>
                          <div className='location'><strong>Destination Address</strong> <span> {request.toLocation}</span></div>
                          <div><strong>POD Number:</strong> {request.bookingDetails.podNumber ? request.bookingDetails.podNumber : 'NA'}</div>
                          <div><strong>Received Status:</strong> {request.receivedStatus ? request.receivedStatus : 'No'}</div>
                          <div><strong>Received On:</strong> {request.receivedOn ? formatDate(request.receivedOn) : 'NA'}</div>
                          <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                          <div><strong>Booking Status:</strong> {request.supportNeeded==='Receive Courier' ? 'NA' : request.bookingDetails.bookingStatus}</div>
                          {
                            request.status !=='Cancelled' &&
                            <div><Link to={`/cancel-request/${request.id}`} className='full-details' target='_blank' rel="noopener noreferrer">Cancel Request</Link></div>
                          }
                          {request.bookingDetails.bookingStatus !== 'Success' && 
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => handleEditRequest(request.id)}>Edit</button>
                              <button className="reqbtn" onClick={() => handleDeleteRequest(request.id)}>Delete</button>
                            </div>
                          }
                        </div> :
                        <div className='hided-details'>
                          <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                          <div>
                            <h3>Request ID: {request.id}</h3>  
                            <span><Link to={`/full-details/${request.id}`} className='full-details'>View full details</Link></span>  
                          </div>
                          <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                          <div><strong>Batch Id:</strong> <span>{request.batchId}</span></div>
                          <div><strong>Center:</strong> <span>{request.center}</span></div>
                          <div><strong>Client:</strong> {request.client}</div>
                          <div><strong>Cost Center:</strong> {request.costCenter}</div>
                          <div className='location'><strong>Source:</strong> <span>{request.source || 'NA'}</span></div>
                          <div className='location'><strong>Destination:</strong> <span> {request.destination || 'NA'}</span></div>
                          <div><strong>Date:</strong>{request.supportNeeded==='Travel' ? <span> {formatDate(request.fromDate)}</span> : <span> {formatDate(request.fromDate)} to {formatDate(request.toDate)}</span>}</div>
                          <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                          <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                          {
                            request.status !=='Cancelled' &&
                            <div><Link to={`/cancel-request/${request.id}`} className='full-details' target='_blank' rel="noopener noreferrer">Cancel Request</Link></div>
                          }
                          {request.bookingDetails.bookingStatus !== 'Success' && 
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => handleEditRequest(request.id)}>Edit</button>
                              <button className="reqbtn" onClick={() => handleDeleteRequest(request.id)}>Delete</button>
                            </div>
                          }
                        </div>}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div>
                {loading ? (
                  <p className="no-req">Loading...</p>
                ) : myClosedRequests.length === 0 ? (
                  <p className="no-req">No Requests Found</p>
                ) : (
                  <div className="requests-list">
                    {myClosedRequests.map((request, index) => (
                      <div key={index}>
                        <div key={request.id} className={`request-card ${selected === request ? 'selected' : ''}`}>
                          <div>
                            <h3>Request ID: {request.id}</h3>  
                            <button className='full-details' onClick={() => setSelected(request)}>Show more</button> 
                          </div>
                          <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                          <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                          <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                        </div>
                        <div className='whole-hide'>
                        {request.supportNeeded.includes('Courier') ? 
                        <div className='hided-details'>
                          <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                          <div>
                            <h3>Request ID: {request.id}</h3>  
                            <span><Link to={`/courier-details/${request.id}`} className='full-details'>View full details</Link></span>  
                          </div>
                          <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                          <div><strong>Courier Type:</strong> {request.courierType}</div>
                          <div><strong>Office Location:</strong> {request.officeLocation}</div>
                          <div className='location'><strong>Source Address:</strong> <span>{request.fromLocation}</span></div>
                          <div className='location'><strong>Destination Address</strong> <span> {request.toLocation}</span></div>
                          <div><strong>POD Number:</strong> {request.bookingDetails.podNumber ? request.bookingDetails.podNumber : 'NA'}</div>
                          <div><strong>Received Status:</strong> {request.receivedStatus ? request.receivedStatus : 'No'}</div>
                          <div><strong>Received On:</strong> {request.receivedOn ? formatDate(request.receivedOn) : 'NA'}</div>
                          <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                          <div><strong>Booking Status:</strong> {request.supportNeeded==='Receive Courier' ? 'NA' : request.bookingDetails.bookingStatus}</div>
                          {
                            request.status !=='Cancelled' &&
                            <div><Link to={`/cancel-request/${request.id}`} className='full-details' target='_blank' rel="noopener noreferrer">Cancel Request</Link></div>
                          }
                          {/* {request.bookingDetails.bookingStatus !== 'Success' && 
                            <div className='request-button'>
                              <button className="reqbtn"><Link to={`/reopen-request/${request.id}`} className='reopen-link'>Reopen Request</Link></button>
                            </div>
                          } */}
                        </div> :
                        <div className='hided-details'>
                          <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                          <div>
                            <h3>Request ID: {request.id}</h3>  
                            <span><Link to={`/full-details/${request.id}`} className='full-details'>View full details</Link></span>  
                          </div>
                          <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                          <div><strong>Batch Id:</strong> {request.batchId}</div>
                          <div><strong>Center:</strong> {request.center}</div>
                          <div><strong>Client:</strong> {request.client}</div>
                          <div><strong>Cost Center:</strong> {request.costCenter}</div>
                          <div className='location'><strong>Source:</strong> <span>{request.source || 'NA'}</span></div>
                          <div className='location'><strong>Destination:</strong> <span> {request.destination || 'NA'}</span></div>
                          <div><strong>Date:</strong>{request.supportNeeded==='Travel' ? <span> {formatDate(request.fromDate)}</span> : <span> {formatDate(request.fromDate)} to {formatDate(request.toDate)}</span>}</div>
                          <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                          <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                          {
                            request.status !=='Cancelled' &&
                            <div><Link to={`/cancel-request/${request.id}`} className='full-details' target='_blank' rel="noopener noreferrer">Cancel Request</Link></div>
                          }
                          {request.bookingDetails.bookingStatus !== 'Success' && 
                            <div className='request-button'>
                              <button className="reqbtn"><Link to={`/reopen-request/${request.id}`} className='reopen-link'>Reopen Request</Link></button>
                            </div>
                          }
                        </div>}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Tabs>
          </div>
          {user && user.role !== 'USER' && (
            <div>
              <Tabs tabs={subTabData}>
                <div>
                  {loading ? (
                    <p className="no-req">Loading...</p>
                  ) : allOpenRequests.length === 0 ? (
                    <p className="no-req">No Requests Found</p>
                  ) : (
                    <div className="requests-list">
                      {allOpenRequests.map((request, index) => (
                          <div key={index}>
                          <div key={request.id} className={`request-card ${selected === request ? 'selected' : ''}`}>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <button className='full-details' onClick={() => setSelected(request)}>Show more</button> 
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                          </div>
                          <div className='whole-hide'>
                          {request.supportNeeded.includes('Courier') ? 
                          <div className='hided-details'>
                            <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <span><Link to={`/courier-details/${request.id}`} className='full-details'>View full details</Link></span>  
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Courier Type:</strong> {request.courierType}</div>
                            <div><strong>Office Location:</strong> {request.officeLocation}</div>
                            <div className='location'><strong>Source Address:</strong> <span>{request.fromLocation}</span></div>
                            <div className='location'><strong>Destination Address</strong> <span> {request.toLocation}</span></div>
                            <div><strong>POD Number:</strong> {request.bookingDetails.podNumber ? request.bookingDetails.podNumber : 'NA'}</div>
                            <div><strong>Received Status:</strong> {request.receivedStatus ? request.receivedStatus : 'No'}</div>
                            <div><strong>Received On:</strong> {request.receivedOn ? formatDate(request.receivedOn) : 'NA'}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => handleAcceptRequest(request.id)}>Accept</button>
                              <button className="reqbtn" onClick={() => handleDenyRequest(request.id)}>Deny</button>
                            </div>
                            {request.supportNeeded !=='Receive Courier' &&
                            <>
                            <div><strong>Booking Status:</strong> {request.supportNeeded==='Receive Courier' ? 'NA' : request.bookingDetails.bookingStatus}</div>
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => {
                                if(!request.bookingDetails.bookingDate || !request.bookingDetails.bookingBy || !request.bookingDetails.amount || !request.bookingDetails.pnrNumber || !request.bookingDetails.fileUrl){
                                  notifyError('Update all the Booking Details','bottom-center');
                                  return;
                                }
                                handleSuccess(request.id);
                              }}>Success</button>
                              <button className="reqbtn" onClick={() => handleFail(request.id)}>Fail</button>
                            </div>
                            <div className='booking-details'><Link to={`/booking-details/${request.id}`} target='_blank' rel="noopener noreferrer">Update/Edit Booking Details</Link></div>
                            </>}
                            <div className='remarks-container'>
                              <strong>Remarks:</strong>
                              {Array.isArray(remarksMap[request.id]) && remarksMap[request.id].length>0 ? (
                                remarksMap[request.id].map((remark, index) => (
                                  <div key={index} className="remark">
                                    <input
                                      type="text"
                                      placeholder="Edit remark"
                                      value={remark}
                                      onChange={(event) => handleChangeRemarks(event, request.id, index)}
                                    />
                                    <button
                                      className="delete-remark-button"
                                      onClick={() => handleDeleteRemark(request.id, index)}
                                    >
                                      <MdDelete/>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <p>No remarks available</p>
                              )}
                              <div className="remarks-buttons-container">
                                <button
                                  className="add-remark-button"
                                  onClick={() => handleAddRemark(request.id)}
                                >
                                  Add Remark
                                </button>
                                <button
                                  className="send-remarks-button"
                                  onClick={() => sendRemarks(request.id)}
                                >
                                  Send <IoMdSend />
                                </button>
                              </div>
                            </div>
                          </div> :
                          <div className='hided-details'>
                            <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <span><Link to={`/full-details/${request.id}`} className='full-details'>View full details</Link></span>  
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Batch Id:</strong> {request.batchId}</div>
                            <div><strong>Center:</strong> {request.center}</div>
                            <div><strong>Client:</strong> {request.client}</div>
                            <div><strong>Cost Center:</strong> {request.costCenter}</div>
                            <div className='location'><strong>Source:</strong> <span>{request.source || 'NA'}</span></div>
                            <div className='location'><strong>Destination:</strong> <span> {request.destination || 'NA'}</span></div>
                            <div><strong>Date:</strong>{request.supportNeeded==='Travel' ? <span> {formatDate(request.fromDate)}</span> : <span> {formatDate(request.fromDate)} to {formatDate(request.toDate)}</span>}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => handleAcceptRequest(request.id)}>Accept</button>
                              <button className="reqbtn" onClick={() => handleDenyRequest(request.id)}>Deny</button>
                            </div>
                            <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                            <div className='request-button'>
                              <button className="reqbtn" onClick={() => {
                                if(!request.bookingDetails.bookingDate || !request.bookingDetails.bookingBy || !request.bookingDetails.amount || !request.bookingDetails.pnrNumber || !request.bookingDetails.fileUrl){
                                  notifyError('Update all the Booking Details','bottom-center');
                                  return;
                                }
                                handleSuccess(request.id);
                              }}>Success</button>
                              <button className="reqbtn" onClick={() => handleFail(request.id)}>Fail</button>
                            </div>
                            <div className='booking-details'><Link to={`/booking-details/${request.id}`} target='_blank' rel="noopener noreferrer">Update/Edit Booking Details</Link></div>
                            <div className='remarks-container'>
                              <strong>Remarks:</strong>
                              {Array.isArray(remarksMap[request.id]) && remarksMap[request.id].length>0 ? (
                                remarksMap[request.id].map((remark, index) => (
                                  <div key={index} className="remark">
                                    <input
                                      type="text"
                                      placeholder="Edit remark"
                                      value={remark}
                                      onChange={(event) => handleChangeRemarks(event, request.id, index)}
                                    />
                                    <button
                                      className="delete-remark-button"
                                      onClick={() => handleDeleteRemark(request.id, index)}
                                    >
                                      <MdDelete/>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <p>No remarks available</p>
                              )}
                              <div className="remarks-buttons-container">
                                <button
                                  className="add-remark-button"
                                  onClick={() => handleAddRemark(request.id)}
                                >
                                  Add Remark
                                </button>
                                <button
                                  className="send-remarks-button"
                                  onClick={() => sendRemarks(request.id)}
                                >
                                  Send <IoMdSend />
                                </button>
                              </div>
                            </div>
                          </div>}
                          </div>
                      </div>
                    ))}
                  </div>
                )}
               </div>
              <div>
                  {loading ? (
                    <p className="no-req">Loading...</p>
                  ) : allClosedRequests.length === 0 ? (
                    <p className="no-req">No Requests Found</p>
                  ) : (
                    <div className="requests-list">
                      {allClosedRequests.map((request, index) => (
                        <div key={index}>
                          <div key={request.id} className={`request-card ${selected === request ? 'selected' : ''}`}>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <button className='full-details' onClick={() => setSelected(request)}>Show more</button> 
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                          </div>
                          <div className='whole-hide'>
                          {request.supportNeeded.includes('Courier') ? 
                          <div className='hided-details'>
                            <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <span><Link to={`/courier-details/${request.id}`} className='full-details'>View full details</Link></span>  
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Courier Type:</strong> {request.courierType}</div>
                            <div><strong>Office Location:</strong> {request.officeLocation}</div>
                            <div className='location'><strong>Source Address:</strong> <span>{request.fromLocation}</span></div>
                            <div className='location'><strong>Destination Address</strong> <span> {request.toLocation}</span></div>
                            <div><strong>POD Number:</strong> {request.bookingDetails.podNumber ? request.bookingDetails.podNumber : 'NA'}</div>
                            <div><strong>Received Status:</strong> {request.receivedStatus ? request.receivedStatus : 'No'}</div>
                            <div><strong>Received On:</strong> {request.receivedOn ? formatDate(request.receivedOn) : 'NA'}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div><strong>Booking Status:</strong> {request.supportNeeded==='Receive Courier' ? 'NA' : request.bookingDetails.bookingStatus}</div>
                            <div className='booking-details'><Link to={`/booking-details/${request.id}`} target='_blank' rel="noopener noreferrer">Update/Edit Booking Details</Link></div>
                            <div className='remarks-container'>
                              <strong>Remarks:</strong>
                              {Array.isArray(remarksMap[request.id]) && remarksMap[request.id].length>0 ? (
                                remarksMap[request.id].map((remark, index) => (
                                  <div key={index} className="remark">
                                    <input
                                      type="text"
                                      placeholder="Edit remark"
                                      value={remark}
                                      onChange={(event) => handleChangeRemarks(event, request.id, index)}
                                    />
                                    <button
                                      className="delete-remark-button"
                                      onClick={() => handleDeleteRemark(request.id, index)}
                                    >
                                      <MdDelete/>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <p>No remarks available</p>
                              )}
                              <div className="remarks-buttons-container">
                                <button
                                  className="add-remark-button"
                                  onClick={() => handleAddRemark(request.id)}
                                >
                                  Add Remark
                                </button>
                                <button
                                  className="send-remarks-button"
                                  onClick={() => sendRemarks(request.id)}
                                >
                                  Send <IoMdSend />
                                </button>
                              </div>
                            </div>
                          </div> :
                          <div className='hided-details'>
                            <div className='back-button' onClick={() => setSelected({})}><span><IoMdArrowBack/> Back</span></div>
                            <div>
                              <h3>Request ID: {request.id}</h3>  
                              <span><Link to={`/full-details/${request.id}`} className='full-details'>View full details</Link></span>  
                            </div>
                            <div><strong>Support Needed:</strong> {request.supportNeeded}</div>
                            <div><strong>Batch Id:</strong> {request.batchId}</div>
                            <div><strong>Center:</strong> {request.center}</div>
                            <div><strong>Client:</strong> {request.client}</div>
                            <div><strong>Cost Center:</strong> {request.costCenter}</div>
                            <div className='location'><strong>Source:</strong> <span>{request.source || 'NA'}</span></div>
                            <div className='location'><strong>Destination:</strong> <span> {request.destination || 'NA'}</span></div>
                            <div><strong>Date:</strong>{request.supportNeeded==='Travel' ? <span> {formatDate(request.fromDate)}</span> : <span> {formatDate(request.fromDate)} to {formatDate(request.toDate)}</span>}</div>
                            <div><strong>Status:</strong> {request.status ? request.status : 'Pending'}</div>
                            <div><strong>Booking Status:</strong> {request.bookingDetails.bookingStatus}</div>
                            <div className='booking-details'><Link to={`/booking-details/${request.id}`} target='_blank' rel="noopener noreferrer">Update/Edit Booking Details</Link></div>
                            <div className='remarks-container'>
                              <strong>Remarks:</strong>
                              {Array.isArray(remarksMap[request.id]) && remarksMap[request.id].length>0 ? (
                                remarksMap[request.id].map((remark, index) => (
                                  <div key={index} className="remark">
                                    <input
                                      type="text"
                                      placeholder="Edit remark"
                                      value={remark}
                                      onChange={(event) => handleChangeRemarks(event, request.id, index)}
                                    />
                                    <button
                                      className="delete-remark-button"
                                      onClick={() => handleDeleteRemark(request.id, index)}
                                    >
                                      <MdDelete/>
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <p>No remarks available</p>
                              )}
                              <div className="remarks-buttons-container">
                                <button
                                  className="add-remark-button"
                                  onClick={() => handleAddRemark(request.id)}
                                >
                                  Add Remark
                                </button>
                                <button
                                  className="send-remarks-button"
                                  onClick={() => sendRemarks(request.id)}
                                >
                                  Send <IoMdSend />
                                </button>
                              </div>
                            </div>
                          </div>}
                          </div>
                      </div>
                    ))}
                  </div>
                )}
               </div>
              </Tabs>
            </div>
          )}
        </Tabs>
      </main>
      <Footer />
    </div>
  );
};

export default ViewEditRequests;
