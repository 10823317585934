import React from 'react'
import Register from './pages/Register'
import Report from './pages/Report'
import Login from './pages/Login'
import Request from './pages/Request'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { UserProvider } from './UserContext'
import Protected from './components/Protected'
import Map from './pages/Map'
import ViewRequest from './pages/ViewRequest'
import CreateAdmin from './pages/CreateAdmin'
import UpdateBooking from './pages/UpdateBooking'
import FullDetails from './pages/FullDetails'
import CancelRequest from './pages/CancelRequest'
import ReopenRequest from './pages/ReopenRequest'
import FeedbackSurvey from './pages/FeedbackSurvey'
import CourierDetails from './pages/CourierDetails'

export default function App() {
  return (
    <div>
      {/* Wrap the application in UserProvider to provide user context throughout the app */}
      <Router>
      <UserProvider>
          <Routes>
            {/* Protected routes require the user to be authenticated */}
            
            {/* Report route*/}
            <Route path='/report' element={<Protected/>}>
              <Route path="/report" element={<Report />} />
            </Route>

            {/* Default route renders the Login component */}
            <Route path="/login" element={<Login/>} />

            {/* Route for user registration */}
            <Route path="/register" element={<Register />} />

            {/* Home page-View request route */}
            <Route path='/' element={<Protected/>}>
              <Route path='/' element={<ViewRequest/>}/>
            </Route>

            {/* Request route */}
            <Route path='/create-request' element={<Protected/>}>
              <Route path='/create-request/:requestId?' element={<Request/>}/>
            </Route>

            {/* Planner route*/}
            <Route path='/planner' element={<Protected/>}>
              <Route path='/planner/:requestId?' element={<Map />}/>
            </Route>

            {/* Create-Admin route*/}
            <Route path='/create-admin' element={<Protected/>}>
               <Route path='/create-admin' element={<CreateAdmin/>}/>
            </Route>

            {/* Booking-Details route*/}
            <Route path='/booking-details' element={<Protected/>}>
               <Route path='/booking-details/:requestId?' element={<UpdateBooking/>}/>
            </Route>

            {/* Full-Details route*/}
            <Route path='/full-details' element={<Protected/>}>
               <Route path='/full-details/:requestId?' element={<FullDetails/>}/>
            </Route>

            {/* Courier-Details route*/}
            <Route path='/courier-details' element={<Protected/>}>
               <Route path='/courier-details/:requestId?' element={<CourierDetails/>}/>
            </Route>

            {/* Cancel-request route*/}
            <Route path='/cancel-request' element={<Protected/>}>
               <Route path='/cancel-request/:requestId?' element={<CancelRequest/>}/>
            </Route>

            {/* Reopen-request route*/}
            <Route path='/reopen-request' element={<Protected/>}>
               <Route path='/reopen-request/:requestId?' element={<ReopenRequest/>}/>
            </Route>

            {/* Feedback survey route*/}
            <Route path='/feedback' element={<Protected/>}>
               <Route path='/feedback' element={<FeedbackSurvey/>}/>
            </Route>
            
          </Routes>
      </UserProvider> 
      </Router> 
    </div>
  )
}
