 const AllAirports  =
    [
      {
        "IATA_code": "AGX",
        "ICAO_code": "VOAT",
        "airport_name": "Agatti Island Airport",
        "city_name": "Agatti Island"
      },
      {
        "IATA_code": "AMD",
        "ICAO_code": "VAAH",
        "airport_name": "Ahmedabad Airport",
        "city_name": "Ahmedabad"
      },
      {
        "IATA_code": "AJL",
        "ICAO_code": "VEAZ",
        "airport_name": "Aizawl Airport",
        "city_name": "Aizawl"
      },
      {
        "IATA_code": "AKD",
        "ICAO_code": "VAAK",
        "airport_name": "Akola Airport",
        "city_name": "Akola"
      },
      {
        "IATA_code": "IXV",
        "ICAO_code": "VEAN",
        "airport_name": "Along Airport",
        "city_name": "Along"
      },
      {
        "IATA_code": "LKO",
        "ICAO_code": "VILK",
        "airport_name": "Amausi Airport",
        "city_name": "Lucknow"
      },
      {
        "IATA_code": "LUH",
        "ICAO_code": "VILD",
        "airport_name": "Amritsar Airport",
        "city_name": "Ludhiana"
      },
      {
        "IATA_code": "IXB",
        "ICAO_code": "VEBD",
        "airport_name": "Bagdogra Airport",
        "city_name": "Bagdogra"
      },
      {
        "IATA_code": "IXE",
        "ICAO_code": "VOML",
        "airport_name": "Bajpe Airport",
        "city_name": "Mangalore"
      },
      {
        "IATA_code": "IXL",
        "ICAO_code": "VILH",
        "airport_name": "Bakula Rimpoche Airport",
        "city_name": "Leh"
      },
      {
        "IATA_code": "RGH",
        "ICAO_code": "VEBG",
        "airport_name": "Balurghat Airport",
        "city_name": "Balurghat"
      },
      {
        "IATA_code": "IXD",
        "ICAO_code": "VIAL",
        "airport_name": "Bamrauli Airport",
        "city_name": "Allahabad"
      },
      {
        "IATA_code": "SHL",
        "ICAO_code": "VEBI",
        "airport_name": "Barapani Airport",
        "city_name": "Shillong"
      },
      {
        "IATA_code": "BEK",
        "ICAO_code": "VOPN",
        "airport_name": "Bareli Airport",
        "city_name": "Bareli"
      },
      {
        "IATA_code": "BEP",
        "ICAO_code": "VOBI",
        "airport_name": "Bellary Airport",
        "city_name": "Bellary"
      },
      {
        "IATA_code": "BLR",
        "ICAO_code": "VOBG",
        "airport_name": "Bengaluru International Airport",
        "city_name": "Bangalore"
      },
      {
        "IATA_code": "BUP",
        "ICAO_code": "VIBT",
        "airport_name": "Bhatinda Airport",
        "city_name": "Bhatinda"
      },
      {
        "IATA_code": "BHU",
        "ICAO_code": "VABV",
        "airport_name": "Bhavnagar Airport",
        "city_name": "Bhavnagar"
      },
      {
        "IATA_code": "BHO",
        "ICAO_code": "VABP",
        "airport_name": "Bhopal Airport",
        "city_name": "Bhopal"
      },
      {
        "IATA_code": "BBI",
        "ICAO_code": "VEBS",
        "airport_name": "Bhubaneswar Airport",
        "city_name": "Bhubaneswar"
      },
      {
        "IATA_code": "BKB",
        "ICAO_code": "VIBK",
        "airport_name": "Bikaner Airport",
        "city_name": "Bikaner"
      },
      {
        "IATA_code": "PAB",
        "ICAO_code": "VABI",
        "airport_name": "Bilaspur Airport",
        "city_name": "Bilaspur"
      },
      {
        "IATA_code": "IXR",
        "ICAO_code": "VERC",
        "airport_name": "Birsa Munda International Airport",
        "city_name": "Ranchi"
      },
      {
        "IATA_code": "GAU",
        "ICAO_code": "VEGT",
        "airport_name": "Borjhar Airport",
        "city_name": "Guwahati"
      },
      {
        "IATA_code": "CBD",
        "ICAO_code": "VECX",
        "airport_name": "Car Nicobar Airport",
        "city_name": "Car Nicobar"
      },
      {
        "IATA_code": "IXC",
        "ICAO_code": "VICG",
        "airport_name": "Chandigarh Airport",
        "city_name": "Chandigarh"
      },
      {
        "IATA_code": "MAA",
        "ICAO_code": "VOMM",
        "airport_name": "Chennai International Airport",
        "city_name": "Chennai"
      },
      {
        "IATA_code": "BOM",
        "ICAO_code": "VABB",
        "airport_name": "Chhatrapati Shivaji International Airport",
        "city_name": "Mumbai"
      },
      {
        "IATA_code": "IXU",
        "ICAO_code": "VAAU",
        "airport_name": "Chikkalthana Airport",
        "city_name": "Aurangabad"
      },
      {
        "IATA_code": "COK",
        "ICAO_code": "VOCI",
        "airport_name": "Cochin International Airport",
        "city_name": "Kochi"
      },
      {
        "IATA_code": "COH",
        "ICAO_code": "VECO",
        "airport_name": "Cooch Behar Airport",
        "city_name": "Cooch Behar"
      },
      {
        "IATA_code": "CDP",
        "ICAO_code": "VOCP",
        "airport_name": "Cuddapah Airport",
        "city_name": "Cuddapah"
      },
      {
        "IATA_code": "UDR",
        "ICAO_code": "VAUD",
        "airport_name": "Dabok Airport",
        "city_name": "Udaipur"
      },
      {
        "IATA_code": "GOI",
        "ICAO_code": "VAGO",
        "airport_name": "Dabolim Airport",
        "city_name": "Goa"
      },
      {
        "IATA_code": "NMB",
        "ICAO_code": "VADN",
        "airport_name": "Daman Airport",
        "city_name": "Daman"
      },
      {
        "IATA_code": "DAE",
        "ICAO_code": "VEDZ",
        "airport_name": "Daparizo Airport",
        "city_name": "Daparizo"
      },
      {
        "IATA_code": "DAI",
        "ICAO_code": "",
        "airport_name": "Darjeeling Airport",
        "city_name": "Darjeeling"
      },
      {
        "IATA_code": "DED",
        "ICAO_code": "VIDN",
        "airport_name": "Dehra Dun Airport",
        "city_name": "Dehra Dun"
      },
      {
        "IATA_code": "DEP",
        "ICAO_code": "",
        "airport_name": "Deparizo Airport",
        "city_name": "Deparizo"
      },
      {
        "IATA_code": "IDR",
        "ICAO_code": "VAID",
        "airport_name": "Devi Ahilyabai Holkar Airport",
        "city_name": "Indore"
      },
      {
        "IATA_code": "DBD",
        "ICAO_code": "VEDB",
        "airport_name": "Dhanbad Airport",
        "city_name": "Dhanbad"
      },
      {
        "IATA_code": "DIB",
        "ICAO_code": "VEMN",
        "airport_name": "Dibrugarh Airport",
        "city_name": "Dibrugarh"
      },
      {
        "IATA_code": "DMU",
        "ICAO_code": "VEMR",
        "airport_name": "Dimapur Airport",
        "city_name": "Dimapur"
      },
      {
        "IATA_code": "DIU",
        "ICAO_code": "VA1P",
        "airport_name": "Diu Airport",
        "city_name": "Diu"
      },
      {
        "IATA_code": "DHM",
        "ICAO_code": "VIGG",
        "airport_name": "Gaggal Airport",
        "city_name": "Dharamsala"
      },
      {
        "IATA_code": "ISK",
        "ICAO_code": "VANR",
        "airport_name": "Gandhinagar Airport",
        "city_name": "Nasik"
      },
      {
        "IATA_code": "GAY",
        "ICAO_code": "VEGY",
        "airport_name": "Gaya Airport",
        "city_name": "Gaya"
      },
      {
        "IATA_code": "GOP",
        "ICAO_code": "VEGK",
        "airport_name": "Gorakhpur Airport",
        "city_name": "Gorakhpur"
      },
      {
        "IATA_code": "JGA",
        "ICAO_code": "VAJM",
        "airport_name": "Govardhanpur Airport",
        "city_name": "Jamnagar"
      },
      {
        "IATA_code": "GUX",
        "ICAO_code": "VAGN",
        "airport_name": "Guna Airport",
        "city_name": "Guna"
      },
      {
        "IATA_code": "GWL",
        "ICAO_code": "VIGR",
        "airport_name": "Gwalior Airport",
        "city_name": "Gwalior"
      },
      {
        "IATA_code": "HSS",
        "ICAO_code": "VIHR",
        "airport_name": "Hissar Airport",
        "city_name": "Hissar"
      },
      {
        "IATA_code": "HBX",
        "ICAO_code": "VAHB",
        "airport_name": "Hubli Airport",
        "city_name": "Hubli"
      },
      {
        "IATA_code": "HYD",
        "ICAO_code": "VOHY",
        "airport_name": "Hyderabad International Airport",
        "city_name": "Hyderabad"
      },
      {
        "IATA_code": "DEL",
        "ICAO_code": "VIDP",
        "airport_name": "Indira Gandhi International Airport",
        "city_name": "New Delhi"
      },
      {
        "IATA_code": "JLR",
        "ICAO_code": "VAJB",
        "airport_name": "Jabalpur Airport",
        "city_name": "Jabalpur"
      },
      {
        "IATA_code": "JGB",
        "ICAO_code": "",
        "airport_name": "Jagdalpur Airport",
        "city_name": "Jagdalpur"
      },
      {
        "IATA_code": "JSA",
        "ICAO_code": "VIJR",
        "airport_name": "Jaisalmer Airport",
        "city_name": "Jaisalmer"
      },
      {
        "IATA_code": "PYB",
        "ICAO_code": "VEJP",
        "airport_name": "Jeypore Airport",
        "city_name": "Jeypore"
      },
      {
        "IATA_code": "JDH",
        "ICAO_code": "VIJO",
        "airport_name": "Jodhpur Airport",
        "city_name": "Jodhpur"
      },
      {
        "IATA_code": "IXH",
        "ICAO_code": "VEKR",
        "airport_name": "Kailashahar Airport",
        "city_name": "Kailashahar"
      },
      {
        "IATA_code": "IXQ",
        "ICAO_code": "VEKM",
        "airport_name": "Kamalpur Airport",
        "city_name": "Kamalpur"
      },
      {
        "IATA_code": "IXY",
        "ICAO_code": "VAKE",
        "airport_name": "Kandla Airport",
        "city_name": "Kandla"
      },
      {
        "IATA_code": "KNU",
        "ICAO_code": "VIKA",
        "airport_name": "Kanpur Airport",
        "city_name": "Kanpur"
      },
      {
        "IATA_code": "IXK",
        "ICAO_code": "VAKS",
        "airport_name": "Keshod Airport",
        "city_name": "Keshod"
      },
      {
        "IATA_code": "HJR",
        "ICAO_code": "VAKJ",
        "airport_name": "Khajuraho Airport",
        "city_name": "Khajuraho"
      },
      {
        "IATA_code": "AGR",
        "ICAO_code": "VIAG",
        "airport_name": "Kheria Airport",
        "city_name": "Agra"
      },
      {
        "IATA_code": "IXN",
        "ICAO_code": "VEKW",
        "airport_name": "Khowai Airport",
        "city_name": "Khowai"
      },
      {
        "IATA_code": "KLH",
        "ICAO_code": "VAKP",
        "airport_name": "Kolhapur Airport",
        "city_name": "Kolhapur"
      },
      {
        "IATA_code": "KTU",
        "ICAO_code": "VIKO",
        "airport_name": "Kota Airport",
        "city_name": "Kota"
      },
      {
        "IATA_code": "CCJ",
        "ICAO_code": "VOCL",
        "airport_name": "Kozhikode Airport",
        "city_name": "Kozhikode"
      },
      {
        "IATA_code": "KUU",
        "ICAO_code": "VIBR",
        "airport_name": "Kullu Manali Airport",
        "city_name": "Bhuntar Kullu."
      },
      {
        "IATA_code": "IXS",
        "ICAO_code": "VEKU",
        "airport_name": "Kumbhirgram Airport",
        "city_name": "Silchar"
      },
      {
        "IATA_code": "IXI",
        "ICAO_code": "VELR",
        "airport_name": "Lilabari Airport",
        "city_name": "Lilabari"
      },
      {
        "IATA_code": "PNQ",
        "ICAO_code": "VAPO",
        "airport_name": "Lohegaon Airport",
        "city_name": "Pune"
      },
      {
        "IATA_code": "IXM",
        "ICAO_code": "VOMD",
        "airport_name": "Madurai Airport",
        "city_name": "Madurai"
      },
      {
        "IATA_code": "LDA",
        "ICAO_code": "VEMH",
        "airport_name": "Malda Airport",
        "city_name": "Malda"
      },
      {
        "IATA_code": "MOH",
        "ICAO_code": "VEMN",
        "airport_name": "Mohanbari Airport",
        "city_name": "Mohanbari"
      },
      {
        "IATA_code": "IMF",
        "ICAO_code": "VEIM",
        "airport_name": "Municipal Airport",
        "city_name": "Imphal"
      },
      {
        "IATA_code": "MZA",
        "ICAO_code": "",
        "airport_name": "Muzaffarnagar Airport",
        "city_name": "Muzaffarnagar"
      },
      {
        "IATA_code": "MZU",
        "ICAO_code": "VEMZ",
        "airport_name": "Muzaffarpur Airport",
        "city_name": "Muzaffarpur"
      },
      {
        "IATA_code": "MYQ",
        "ICAO_code": "VOMY",
        "airport_name": "Mysore Airport",
        "city_name": "Mysore"
      },
      {
        "IATA_code": "NDC",
        "ICAO_code": "VAND",
        "airport_name": "Nanded Airport",
        "city_name": "Nanded"
      },
      {
        "IATA_code": "CCU",
        "ICAO_code": "VECC",
        "airport_name": "Netaji Subhash Chandra Bose International Airport",
        "city_name": "Kolkata"
      },
      {
        "IATA_code": "NVY",
        "ICAO_code": "VONV",
        "airport_name": "Neyveli Airport",
        "city_name": "Neyveli"
      },
      {
        "IATA_code": "OMN",
        "ICAO_code": "",
        "airport_name": "Osmanabad Airport",
        "city_name": "Osmanabad"
      },
      {
        "IATA_code": "PGH",
        "ICAO_code": "VIPT",
        "airport_name": "Pantnagar Airport",
        "city_name": "Pantnagar"
      },
      {
        "IATA_code": "IXT",
        "ICAO_code": "VEPG",
        "airport_name": "Pasighat Airport",
        "city_name": "Pasighat"
      },
      {
        "IATA_code": "IXP",
        "ICAO_code": "VIPK",
        "airport_name": "Pathankot Airport",
        "city_name": "Pathankot"
      },
      {
        "IATA_code": "PAT",
        "ICAO_code": "VEPT",
        "airport_name": "Patna Airport",
        "city_name": "Patna"
      },
      {
        "IATA_code": "CJB",
        "ICAO_code": "VOCB",
        "airport_name": "Peelamedu Airport",
        "city_name": "Coimbatore"
      },
      {
        "IATA_code": "PNY",
        "ICAO_code": "VOPC",
        "airport_name": "Pondicherry Airport",
        "city_name": "Pondicherry"
      },
      {
        "IATA_code": "PBD",
        "ICAO_code": "VAPR",
        "airport_name": "Porbandar Airport",
        "city_name": "Porbandar"
      },
      {
        "IATA_code": "IXZ",
        "ICAO_code": "VOPB",
        "airport_name": "Port Blair Airport",
        "city_name": "Port Blair"
      },
      {
        "IATA_code": "PUT",
        "ICAO_code": "",
        "airport_name": "Puttaparthi Airport",
        "city_name": "Puttaparthi"
      },
      {
        "IATA_code": "RPR",
        "ICAO_code": "VARP",
        "airport_name": "Raipur Airport",
        "city_name": "Raipur"
      },
      {
        "IATA_code": "ATQ",
        "ICAO_code": "VIAR",
        "airport_name": "Raja Sansi Airport",
        "city_name": "Amritsar"
      },
      {
        "IATA_code": "RJA",
        "ICAO_code": "VORY",
        "airport_name": "Rajahmundry Airport",
        "city_name": "Rajahmundry"
      },
      {
        "IATA_code": "RAJ",
        "ICAO_code": "VARK",
        "airport_name": "Rajkot Airport",
        "city_name": "Rajkot"
      },
      {
        "IATA_code": "RJI",
        "ICAO_code": "",
        "airport_name": "Rajouri Airport",
        "city_name": "Rajouri"
      },
      {
        "IATA_code": "RMD",
        "ICAO_code": "",
        "airport_name": "Ramagundam Airport",
        "city_name": "Ramagundam"
      },
      {
        "IATA_code": "RTC",
        "ICAO_code": "VARG",
        "airport_name": "Ratnagiri Airport",
        "city_name": "Ratnagiri"
      },
      {
        "IATA_code": "REW",
        "ICAO_code": "",
        "airport_name": "Rewa Airport",
        "city_name": "Rewa"
      },
      {
        "IATA_code": "RRK",
        "ICAO_code": "VERK",
        "airport_name": "Rourkela Airport",
        "city_name": "Rourkela"
      },
      {
        "IATA_code": "JRH",
        "ICAO_code": "VEJT",
        "airport_name": "Rowriah Airport",
        "city_name": "Jorhat"
      },
      {
        "IATA_code": "BHJ",
        "ICAO_code": "VABJ",
        "airport_name": "Rudra Mata Airport",
        "city_name": "Bhuj"
      },
      {
        "IATA_code": "RUP",
        "ICAO_code": "VERU",
        "airport_name": "Rupsi Airport",
        "city_name": "Rupsi"
      },
      {
        "IATA_code": "SXV",
        "ICAO_code": "VOSM",
        "airport_name": "Salem Airport",
        "city_name": "Salem"
      },
      {
        "IATA_code": "TEZ",
        "ICAO_code": "VETZ",
        "airport_name": "Salonibari Airport",
        "city_name": "Tezpur"
      },
      {
        "IATA_code": "IXG",
        "ICAO_code": "VABM",
        "airport_name": "Sambre Airport",
        "city_name": "Belgaum"
      },
      {
        "IATA_code": "JAI",
        "ICAO_code": "VIJP",
        "airport_name": "Sanganeer Airport",
        "city_name": "Jaipur"
      },
      {
        "IATA_code": "TNI",
        "ICAO_code": "VIST",
        "airport_name": "Satna Airport",
        "city_name": "Satna"
      },
      {
        "IATA_code": "IXJ",
        "ICAO_code": "VIJU",
        "airport_name": "Satwari Airport",
        "city_name": "Jammu"
      },
      {
        "IATA_code": "SSE",
        "ICAO_code": "VASL",
        "airport_name": "Sholapur Airport",
        "city_name": "Sholapur"
      },
      {
        "IATA_code": "SLV",
        "ICAO_code": "VISM",
        "airport_name": "Simla Airport",
        "city_name": "Simla"
      },
      {
        "IATA_code": "IXA",
        "ICAO_code": "VEAT",
        "airport_name": "Singerbhil Airport",
        "city_name": "Agartala"
      },
      {
        "IATA_code": "IXW",
        "ICAO_code": "VEJS",
        "airport_name": "Sonari Airport",
        "city_name": "Jamshedpur"
      },
      {
        "IATA_code": "NAG",
        "ICAO_code": "VANP",
        "airport_name": "Sonegaon Airport",
        "city_name": "Nagpur"
      },
      {
        "IATA_code": "SXR",
        "ICAO_code": "VISR",
        "airport_name": "Srinagar Airport",
        "city_name": "Srinagar"
      },
      {
        "IATA_code": "STV",
        "ICAO_code": "VASU",
        "airport_name": "Surat Airport",
        "city_name": "Surat"
      },
      {
        "IATA_code": "TEI",
        "ICAO_code": "VETJ",
        "airport_name": "Tezu Airport",
        "city_name": "Tezu"
      },
      {
        "IATA_code": "TJV",
        "ICAO_code": "VOTJ",
        "airport_name": "Thanjavur Airport",
        "city_name": "Thanjavur"
      },
      {
        "IATA_code": "TRV",
        "ICAO_code": "VOTV",
        "airport_name": "Thiruvananthapuram International Airport",
        "city_name": "Trivandrum"
      },
      {
        "IATA_code": "TIR",
        "ICAO_code": "VOTP",
        "airport_name": "Tirupati Airport",
        "city_name": "Tirupati"
      },
      {
        "IATA_code": "TRZ",
        "ICAO_code": "VOTR",
        "airport_name": "Trichy Airport",
        "city_name": "Trichy"
      },
      {
        "IATA_code": "TCR",
        "ICAO_code": "",
        "airport_name": "Tuticorin Airport",
        "city_name": "Tuticorin"
      },
      {
        "IATA_code": "BDQ",
        "ICAO_code": "VABO",
        "airport_name": "Vadodara Airport",
        "city_name": "Vadodara"
      },
      {
        "IATA_code": "VNS",
        "ICAO_code": "VIBN",
        "airport_name": "Varanasi Airport",
        "city_name": "Varanasi"
      },
      {
        "IATA_code": "VGA",
        "ICAO_code": "VOBZ",
        "airport_name": "Vijayawada Airport",
        "city_name": "Vijayawada"
      },
      {
        "IATA_code": "VTZ",
        "ICAO_code": "VEVZ",
        "airport_name": "Vishakhapatnam Airport",
        "city_name": "Vishakhapatnam"
      },
      {
        "IATA_code": "WGC",
        "ICAO_code": "VOWA",
        "airport_name": "Warangal Airport",
        "city_name": "Warangal"
      },
      {
        "IATA_code": "ZER",
        "ICAO_code": "VEZO",
        "airport_name": "Zero Airport",
        "city_name": "Zero"
      }
    ]
  
  export default AllAirports;