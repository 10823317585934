import Fuse from 'fuse.js';
import AllTrainStations from './AllTrainStations.js';

// Define the search function
export const searchStation = async (searchTerm) => {
  try {
    // Fetch data from the JSON file
    const data = AllTrainStations.features;

    // Perform search
    if (!searchTerm) return data; // Return all data if searchTerm is empty

    const fuse = new Fuse(data, {
      keys: ['properties.name', 'properties.state', 'properties.address', 'properties.code'], // Specify the fields to search
      threshold: 0.3 // Adjust the threshold for fuzzy search
    });

    const results = fuse.search(searchTerm).map(result => result.item);
    return results;

  } catch (error) {
    console.error('Error fetching data:', error);
    return []; // Return an empty array if there's an error
  }
};
