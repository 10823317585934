
const getFlightRoutes = async (fromAirport, toAirport, departureDate, returnDate, numAdults, cabinClass, sortFlight) => {

    const url = `https://booking-com15.p.rapidapi.com/api/v1/flights/searchFlights?fromId=${fromAirport}&toId=${toAirport}&departDate=${departureDate}&returnDate=${returnDate}&pageNo=1&adults=${numAdults}&sort=${sortFlight}&cabinClass=${cabinClass}&currency_code=INR`;

    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': '67404f592bmsha6ca6765514e6cfp14674bjsndc50100b8885',
		    'x-rapidapi-host': 'booking-com15.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.data.flightOffers || [];
    } catch (error) {
        console.error(error);
    }
}

export default getFlightRoutes;