import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();
const current = new Date();
const nextMonth = new Date();
nextMonth.setMonth(current.getMonth() + 1); // Set expiration to 1 month from now


export const UserContext = createContext(); // Creating a context for user authentication data

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Secret key for AES encryption

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  // State for user, initialized from cookies
  const [user, setUser] = useState(() => {
    const storedUser = cookies.get('user');
    if (storedUser) {
      try {
        // Decrypting stored user data
        const decryptedUser = CryptoJS.AES.decrypt(storedUser, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        if (decryptedUser) {
          return JSON.parse(decryptedUser); // Parsing decrypted JSON to object
        }
      } catch (error) {
        console.error('Error decrypting user:', error);
      }
    }
    return null; // Default state if no user data found
  });
 
  // State for token, initialized from cookies
  const [token, setToken] = useState(() => {
    const storedToken = cookies.get('token');
    if (storedToken) {
      try {
        // Decrypting stored token
        const decryptedToken = CryptoJS.AES.decrypt(storedToken, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        if (decryptedToken) {
          return decryptedToken; // Setting decrypted token
        }
      } catch (error) {
        console.error('Error decrypting token:', error);
      }
    }
    return ''; // Default state if no token found
  });

    // State for refresh token, initialized from cookies
    const [refreshToken, setRefreshToken] = useState(() => {
      const storedToken = cookies.get('refreshToken');
      if (storedToken) {
        try {
          // Decrypting stored token
          const decryptedToken = CryptoJS.AES.decrypt(storedToken, SECRET_KEY).toString(CryptoJS.enc.Utf8);
          if (decryptedToken) {
            return decryptedToken; // Setting decrypted token
          }
        } catch (error) {
          console.error('Error decrypting refresh_token:', error);
        }
      }
      return ''; // Default state if no token found
    });

  useEffect(() => {
    // Effect to store user in cookie when it changes
    if (user) {
      try {
        // Encrypting user data
        const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), SECRET_KEY).toString();
        cookies.set('user', encryptedUser, { path: '/', secure: true, expires: nextMonth }); // Setting cookie with encryption
      } catch (error) {
        console.error('Error encrypting user:', error);
      }
    } else {
      cookies.remove('user'); // Removing user cookie if user state is null
    }
  }, [user]); // Watching 'user' state changes

  useEffect(() => {
    // Effect to store token in cookie when it changes
    if (token) {
      try {
        // Encrypting token data
        const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
        cookies.set('token', encryptedToken, { path: '/', secure: true, expires: nextMonth }); // Setting cookie with encryption
      } catch (error) {
        console.error('Error encrypting token:', error);
      }
    } else {
      cookies.remove('token'); // Removing token cookie if token state is null or empty string
    }
  }, [token]); // Watching 'token' state changes

  useEffect(() => {
    // Effect to store refresh token in cookie when it changes
    if (refreshToken) {
      try {
        // Encrypting token data
        const encryptedToken = CryptoJS.AES.encrypt(refreshToken, SECRET_KEY).toString();
        cookies.set('refreshToken', encryptedToken, { path: '/', secure: true, expires: nextMonth }); // Setting cookie with encryption
      } catch (error) {
        console.error('Error encrypting refresh token:', error);
      }
    } else {
      cookies.remove('refreshToken'); // Removing token cookie if token state is null or empty string
    }
  }, [refreshToken]); // Watching 'refresh token' state changes

  const forceRefreshToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken: refreshToken }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 401 || response.status===403) {
          //Redirect to login page
          navigate('/login');
        }
        throw new Error(errorData.error || 'Network response was not ok.');
      }
      
      const data=await response.json();
      setToken(data.accessToken);
    } catch (error) {
      console.error(error);
      navigate('/login');
    }
  };

  useEffect(() => {
    if(!token) return;
    
    const interval = setInterval(() => {
      forceRefreshToken();
    }, 5 * 60 * 1000); // Refresh every 5 minutes

    return () => clearInterval(interval);
  }, [user]);

  // Providing 'user' and 'token' states and setter functions to child components via context
  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, refreshToken, setRefreshToken, forceRefreshToken}}>
      {children} {/* Rendering child components */}
    </UserContext.Provider>
  );
};
