const AllTrainStations =
{
    "type": "FeatureCollection",
    "features": [
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4516454,
            27.2520587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BDHL",
          "name": "Badhal",
          "zone": "NWR",
          "address": "Kishangarh Renwal, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-BECE",
          "name": "XX-BECE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-BSPY",
          "name": "XX-BSPY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-BPLC",
          "name": "YY-BPLC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.519746,
            28.913427000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KHH",
          "name": "KICHHA",
          "zone": "NER",
          "address": "Kichha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.43499088287354,
            29.55519806064484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRKN",
          "name": "Sherekan",
          "zone": "NWR",
          "address": "MDR 89, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.75103096105158,
            29.238227312503696
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BKKA",
          "name": "Bhukarka",
          "zone": "NWR",
          "address": "Bhukarka, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.77362781763077,
            29.192562738768984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NHR",
          "name": "Nohar",
          "zone": "NWR",
          "address": "State Highway 36, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.231363,
            12.975217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PRGD",
          "name": "Perungudi",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7179570607841,
            29.345637263205944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KNNA",
          "name": "Khinaniyan",
          "zone": "NWR",
          "address": "State Highway 23, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.216684,
            21.838466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKR",
          "name": "JAULKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.03136099999999,
            21.909813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYS",
          "name": "BARSALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.936311,
            23.838185999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GW",
          "name": "GIRWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.019912,
            23.871771000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGD",
          "name": "DANGIDHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.10354600000001,
            23.770740999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DOD",
          "name": "DHODHAR",
          "zone": "WR",
          "address": "Dhodar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.074633,
            23.895862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAJ",
          "name": "GANESHGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.415324,
            22.168635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JNM",
          "name": "Jayanagar Majilpur Halt",
          "zone": "ER",
          "address": "Jaynagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.71699100000001,
            26.294459999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NSD",
          "name": "NASIRABAD",
          "zone": "NWR",
          "address": "Nasirabad, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.192899,
            23.905726
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHA",
          "name": "PATHARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.103238,
            18.679164999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NZB",
          "name": "NIZAMABAD",
          "zone": "SCR",
          "address": "Nizamabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01054599999999,
            25.387446999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJO",
          "name": "RAJENDRA PUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.478668,
            11.009165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ADT",
          "name": "ADUTURAI",
          "zone": "SR",
          "address": "Aduthurai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.388644,
            10.952962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KMU",
          "name": "KUMBAKONAM",
          "zone": "SR",
          "address": "Kumbakonam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.45351,
            9.681538999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MNM",
          "name": "MANAMADURAI JN",
          "zone": "SR",
          "address": "Sivaganga, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.381499,
            26.759311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKP",
          "name": "GORAKHPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJUP",
          "name": "BJUP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.16928300000001,
            22.184274000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRM",
          "name": "VARNAMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.416821,
            26.750649
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKC",
          "name": "GORAKHPUR CANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.156746,
            22.149889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ITA",
          "name": "ITOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIKA",
          "name": "CIKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-CPHO",
          "name": "XX-CPHO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.273006,
            29.09692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTU",
          "name": "BARAUT",
          "zone": "NR",
          "address": "Baraut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.16846699999999,
            30.817296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MOGA",
          "name": "Moga",
          "zone": "NR",
          "address": "Moga, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.503394,
            23.309819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIOB",
          "name": "SAMAKHIALI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLHW",
          "name": "LLHW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.087121,
            27.713786000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RDK",
          "name": "Rasheedpura Khori",
          "zone": "NWR",
          "address": "MDR 87, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.612645,
            16.414205000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVD",
          "name": "PEDDAVADIAPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.85262,
            17.553652000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YLM",
          "name": "ELLAMANCHILI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.655113,
            10.476970999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHPT",
          "name": "Chatrappatti",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.540406,
            27.120389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLD",
          "name": "JARWAL ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.923653,
            21.822718000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRJN",
          "name": "BRAJRAJNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.69417800000001,
            27.137698
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLJ",
          "name": "COLONELGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.8556626477,
            27.1465555756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTHL",
          "name": "KATHOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.903373,
            26.776435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MND",
          "name": "MUNDERWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4806877,
            19.780792299999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNSI",
          "name": "SAINAGAR SHIRDI",
          "zone": "CR",
          "address": "Near New Bus Stand Shirdi Sainagar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.738273,
            21.822549000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DLJ",
          "name": "DHOLA JN",
          "zone": "WR",
          "address": "Dhola, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7655019775,
            31.217555749800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGW",
          "name": "PHAGWARA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-BINP",
          "name": "YY-BINP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.521553,
            28.777449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JUP",
          "name": "Jhunpa",
          "zone": "?",
          "address": "NH 65, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-JSGB",
          "name": "XX-JSGB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.017427,
            21.862274
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSG",
          "name": "JHARSUGUDA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.20715351199999,
            26.2311244405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYW",
          "name": "KARCHHUE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.509909,
            26.000739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHRN",
          "name": "MAHENDRANATH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.688889,
            25.807401000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKVR",
          "name": "TAPPA KHAJURIAY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42225009,
            12.18134581
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPI",
          "name": "DODDAMPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SREE",
          "name": "SREE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.892665,
            26.950232
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AQG",
          "name": "ASHAPURA GOMAT",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.09669699999999,
            11.612765
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEA",
          "name": "NEYKKARAPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.318477,
            25.931048999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRL",
          "name": "GORAUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKJC",
          "name": "DKJC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.339288,
            25.979631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHI",
          "name": "KURHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.26580200000001,
            11.117790999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VNJ",
          "name": "TIRUPPUR VANJIPALAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.954304,
            11.019937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBF",
          "name": "COIMBATORE NORTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.826966,
            30.338918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UMB",
          "name": "AMBALA CANT JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.31786,
            25.650642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRO",
          "name": "SIRATHU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.401934,
            25.605607000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJT",
          "name": "SHUJAATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.44201699999999,
            25.585017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDNP",
          "name": "BIDANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.04829951629999,
            24.6093382288
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MTPC",
          "name": "MOTIPURA CHAUKI",
          "zone": "WCR",
          "address": "Motipura, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.987826,
            25.367027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTZ",
          "name": "HATHIDAH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSKG",
          "name": "TINSUKIA GOODS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.76188,
            26.001459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DDNI",
          "name": "DUDHNOI",
          "zone": "NFR",
          "address": "Dudhnoi Railway Station, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.350991,
            28.067751
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDE",
          "name": "RUNDHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.89123400000001,
            27.081066999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "IJ",
          "name": "ITAUNJA",
          "zone": "NER",
          "address": "Itaunja, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.866078,
            25.722252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RMX",
          "name": "Ramsar",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.813946,
            28.614977999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PBE",
          "name": "PILIBHIT JN",
          "zone": "NER",
          "address": "Pilibhit, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64547982849999,
            28.5301320576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RMB",
          "name": "Rampura Beri",
          "zone": "NWR",
          "address": "Rampura Bhal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.11925000000001,
            25.278149
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGS",
          "name": "MUGHAL SARAI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.993046,
            28.169397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RSWT",
          "name": "Ramgarh Shekhawati",
          "zone": "NWR",
          "address": "Ramgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27473099999999,
            29.035800000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKNA",
          "name": "BAKAINA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.715594,
            22.75274
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HBD",
          "name": "HOSHANGABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.319493,
            23.860522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANA",
          "name": "ASLANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.6812826,
            22.7871143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNI",
          "name": "BUDNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-IDHW",
          "name": "YY-IDHW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.85656900000001,
            27.325566
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DMGN",
          "name": "DHAMALGAON",
          "zone": "NFR",
          "address": "A T Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.463527,
            17.404048000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "LKPL",
          "name": "LAKDIKAPUL",
          "zone": "SCR",
          "address": "Hyderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.814085,
            22.052142
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRUD",
          "name": "BARUD",
          "zone": "WCR",
          "address": "Khandwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.83729,
            19.897509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVL",
          "name": "DEVLALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.76127199999999,
            22.253004999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRTR",
          "name": "Jurtara",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.250615,
            24.537122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "REWA",
          "name": "REWA",
          "zone": "WCR",
          "address": "Rewa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.433842,
            22.731982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DOK",
          "name": "DHOLKA",
          "zone": "WR",
          "address": "Bagodara Dholka, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0952734283,
            22.418331636999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDR",
          "name": "NANDESARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.120353,
            22.050722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYG",
          "name": "MIYAGAM KARJAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.849079,
            25.306914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MBA",
          "name": "MAHOBA",
          "zone": "NCR",
          "address": "Junction Point - Khajuraho, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.198945,
            22.418426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JGZ",
          "name": "Jamgaon",
          "zone": "SECR",
          "address": "Bamhani, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.46243313289999,
            24.8399006513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHKH",
          "name": "BAGHOI KUSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31077099999999,
            28.746571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NOLI",
          "name": "NOLI",
          "zone": "NR",
          "address": "Ghaziabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.26134099999999,
            29.012729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SUJR",
          "name": "SUJRA",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.86546467803419,
            21.990791167399735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AMOD",
          "name": "Amod",
          "zone": "WR",
          "address": "Amod, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.09899399999999,
            21.991872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKD",
          "name": "LAKODARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.23136643506587,
            21.439673931772646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VKL",
          "name": "Vankal",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.833211,
            18.97695
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BY",
          "name": "BYCULLA",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.30109021000001,
            25.858316719999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNR",
          "name": "BHAGWANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.21354088187218,
            14.826788325296278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KTY",
          "name": "Kotturu",
          "zone": "SWR",
          "address": "Kothur Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.143709,
            26.685532
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BLMR",
          "name": "Bhalukmara",
          "zone": "NFR",
          "address": "Bhalukmara, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31896504759789,
            21.163199753077336
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ANJ",
          "name": "Anjangaon",
          "zone": "CR",
          "address": "Anjangaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.306807,
            23.685277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DEB",
          "name": "Debagram",
          "zone": "ER",
          "address": "Debagram, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.51926277764142,
            21.27760302246017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ELP",
          "name": "Achalpur",
          "zone": "CR",
          "address": "Dist. Amravati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.384597,
            23.610275
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BTY",
          "name": "Bethuadahari",
          "zone": "ER",
          "address": "Bethuadahari, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65141943097115,
            20.74690547756532
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WARUD",
          "name": "Warud",
          "zone": "CR",
          "address": "Wardha, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVAC",
          "name": "BVAC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVBC",
          "name": "BVBC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.84618068672717,
            10.686877168293023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VLKN",
          "name": "Velankanni",
          "zone": "?",
          "address": "Velankanni, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.112395,
            16.484823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PCU",
          "name": "PUTLACHERUVU",
          "zone": "SCR",
          "address": "Putlacheruvu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3090478906,
            25.892949366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNPT",
          "name": "BENNIPATTI PIRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.573236,
            20.813007000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JM",
          "name": "JALAMB JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.79406,
            18.346936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GPJ",
          "name": "Gorapur",
          "zone": "ECoR",
          "address": "Jeypore Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.16618146561086,
            13.114499981582952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "ABEO",
          "name": "Pattaravakkam",
          "zone": "SR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.690331,
            20.801724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEG",
          "name": "SHEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1163395764,
            22.2718946035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GDU",
          "name": "Gudru Halt",
          "zone": "SECR",
          "address": "Lamta, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.95045644976199,
            23.458049719226736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGNA",
          "name": "Balgona",
          "zone": "ER",
          "address": "Balgona, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.573216,
            23.206506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KLNP",
          "name": "Kalinarayanpur",
          "zone": "ER",
          "address": "Ranaghat-Bagula Bypass, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.755353,
            20.761640999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGZ",
          "name": "S K NAGJIHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.815865,
            20.724519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PS",
          "name": "PARAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.08753041,
            22.15295838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BRDB",
          "name": "Barda",
          "zone": "SER",
          "address": "Sutahata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.890093,
            20.720684
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAO",
          "name": "GAIGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.468096,
            21.261121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RRL",
          "name": "REWRAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.550792,
            21.242242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHAT",
          "name": "KHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.64752299999999,
            21.238512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRD",
          "name": "BHANDARA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.71240900000001,
            21.263021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOKA",
          "name": "KOKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.678086,
            21.857535000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAO",
          "name": "DAGHORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.742815,
            21.876503999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HGR",
          "name": "HEMAGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.108853,
            22.425676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NIR",
          "name": "Nainpur Junction",
          "zone": "SECR",
          "address": "Nainpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.3939129431,
            26.7081333353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NCH",
          "name": "NAKACHARI",
          "zone": "NFR",
          "address": "Mariani, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.534048,
            26.523279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "APDJ",
          "name": "ALIPUR DUAR JN",
          "zone": "NFR",
          "address": "03564-255091, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.4918665,
            25.5591917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRE",
          "name": "BHARWARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.05297200000001,
            25.626133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JTK",
          "name": "Jhitkia",
          "zone": "NFR",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.55907499999999,
            25.524992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNJ",
          "name": "MANOHARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.613788,
            25.494982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYWN",
          "name": "SAIYID SARAWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.662491,
            25.470171
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRE",
          "name": "MANAURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.741641,
            25.44494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMU",
          "name": "BAMHRAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.789897,
            25.439879
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFG",
          "name": "SUBEDARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.627521,
            16.295185999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLX",
          "name": "KOLAKALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.61690800000001,
            16.379558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLVR",
          "name": "CHILUVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.726495,
            17.007084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVR",
          "name": "KOVVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.77083,
            17.007707
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVN",
          "name": "GODAVARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.75531600000001,
            10.800919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJKD",
          "name": "KANJIKODE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.616164,
            23.52693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SNKR",
          "name": "Sanka",
          "zone": "SER",
          "address": "Garh Dhrubeswar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.902424,
            22.72003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "VNK",
          "name": "Binaiki",
          "zone": "SECR",
          "address": "Binaiki Railway Station, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.45836999999999,
            22.277738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GCN",
          "name": "Gocharan",
          "zone": "ER",
          "address": "Gocharan, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.545769,
            22.075431
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SEY",
          "name": "Seoni",
          "zone": "SECR",
          "address": "Seoni, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.44032,
            23.654478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SDMD",
          "name": "Sudamdih",
          "zone": "SER",
          "address": "Jairampur More to Baliapur, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCLS",
          "name": "PCLS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.988748,
            25.461768000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJU",
          "name": "BARAUNI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.432246,
            23.836693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMO",
          "name": "DAMOH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0346944543,
            22.486628078200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADD",
          "name": "ADAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.16224249999999,
            21.4155588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CLC",
          "name": "Chalala",
          "zone": "WR",
          "address": "Chalala, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.1122662115,
            22.405252095399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNO",
          "name": "RANOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.024928,
            26.012397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HP",
          "name": "HARIPUR",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.0348331158,
            24.1506303119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "KUGT",
          "name": "Kumarghat",
          "zone": "NFR",
          "address": "Kumarghat, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.31064599999999,
            19.859276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AWB",
          "name": "AURANGABAD",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.21298975,
            15.16697376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BNL",
          "name": "BEVINAHALU",
          "zone": "SCR",
          "address": "Rayalaseema Region, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VMC",
          "name": "VMC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.408254,
            20.896131999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SMNH",
          "name": "SOMNATH",
          "zone": "WR",
          "address": "Somnath, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.766287,
            11.715119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CUPJ",
          "name": "CUDDALORE PORT",
          "zone": "SR",
          "address": "Cuddalore Township, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.072387,
            21.921578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLJ",
          "name": "PALEJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.00004,
            25.891938
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GOLE",
          "name": "GOLE",
          "zone": "NWR",
          "address": "National Highway 112, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDX",
          "name": "BDX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.91803499999999,
            21.415039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRO",
          "name": "TIRORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.564842,
            21.874971000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMG",
          "name": "JAMGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMSY",
          "name": "JMSY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.824844,
            26.468381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AHLR",
          "name": "AHALYAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.640413,
            16.242409000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TEL",
          "name": "TENALI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.37926300000001,
            13.707414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TAT",
          "name": "Tummanamgutta",
          "zone": "SCR",
          "address": "Chennai - Anantpur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.690558,
            16.965076999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CU",
          "name": "CHAGALLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.700939,
            16.99331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSDA",
          "name": "PASIVEDALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.59376099999999,
            25.208689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBGA",
          "name": "Marwar Bagra",
          "zone": "NWR",
          "address": "SH 31, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.336284,
            25.827638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JNE",
          "name": "JANIYANA",
          "zone": "NWR",
          "address": "State Highway 68, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.57300699999999,
            25.837202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMR",
          "name": "SAMDHARI JN",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.951414,
            25.970783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SUT",
          "name": "SUTLANA",
          "zone": "NWR",
          "address": "Dundara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.615506,
            21.643597999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PBR",
          "name": "PORBANDAR",
          "zone": "WR",
          "address": "Porbandar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2025684888,
            14.137920273699999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CKVD",
          "name": "Chikkandawadi",
          "zone": "SWR",
          "address": "Chikkandawadi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.712919,
            27.105646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BHG",
          "name": "Bhaton Ki Gali",
          "zone": "NWR",
          "address": "Chomun, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.33120188303292,
            29.574220545495677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HMO",
          "name": "Hanumangarh Town",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.494054,
            26.700858999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SANR",
          "name": "SARDARNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3146452,
            24.182255899999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GRD",
          "name": "Giridih",
          "zone": "ER",
          "address": "Giridih, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.545894,
            26.673882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CC",
          "name": "CHAURI CHAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.5158922174,
            18.023547297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMPT",
          "name": "KOMATIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.50184999999999,
            18.078262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSP",
          "name": "HASANPARTHI RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.734877,
            25.741083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GGY",
          "name": "Gagariya",
          "zone": "NWR",
          "address": "State Highway 40, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.969786,
            26.353209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNPR",
          "name": "NONAPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.151979,
            26.240309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MW",
          "name": "MAIRWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3893135876,
            11.1440279246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUY",
          "name": "TIRUPPUR KULIPALAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.559421,
            10.800415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLL",
          "name": "PARLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.278481,
            23.792417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "AGTL",
          "name": "Agartala",
          "zone": "NFR",
          "address": "Agartala, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.500232,
            10.771526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNY",
          "name": "MANKARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.434467,
            10.753217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDY",
          "name": "LAKKITI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.398385813,
            10.7525187905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLPM",
          "name": "PALAPPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.377993,
            10.770046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OTP",
          "name": "OTTAPPALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.323555,
            10.749322
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNUR",
          "name": "MANNANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.256142,
            26.220819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZRDE",
          "name": "JIRADEI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.359267,
            26.210724000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SV",
          "name": "SIWAN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.415667,
            26.162627999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCK",
          "name": "PACHRUKHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.45894600000001,
            26.086973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDA",
          "name": "DURAUNDHA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.49649600000001,
            26.022117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CW",
          "name": "CHAINWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.923129,
            26.930571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "POK",
          "name": "POKRAN",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.718687,
            26.972848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "OCH",
          "name": "ODHANIYA CHACHA",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.53637,
            25.967099
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EKMA",
          "name": "EKMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.591425,
            25.90709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDP",
          "name": "DAUDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.65281399999999,
            25.851866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPS",
          "name": "KOPA SAMHOTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.29179,
            25.817069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTHL",
          "name": "BITHAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.667801,
            26.594934000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GB",
          "name": "GAURI BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.7385572,
            26.5526634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BALR",
          "name": "BAITALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.7819590961,
            26.5076811676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEOS",
          "name": "DEORIA SADAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.880179,
            26.425483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRA",
          "name": "NUNKHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.786242,
            26.225947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGMN",
          "name": "Agomoni",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.93723505,
            19.14200998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BND",
          "name": "BHANDUP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.858025,
            31.631511999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASR",
          "name": "AMRITSAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.271415,
            10.705843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUC",
          "name": "MULLURKARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.237041,
            10.648956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WKI",
          "name": "WADAKANCHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.90724800000001,
            29.324152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SOG",
          "name": "SURATGARH JN",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.094149,
            21.079845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNV",
          "name": "Sondad",
          "zone": "SECR",
          "address": "National Highway 6, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.000562,
            28.125576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "QMRS",
          "name": "Kayamsar",
          "zone": "NWR",
          "address": "State Highway 41, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.758306,
            16.878268000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AYB",
          "name": "Aliyabad",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.276543,
            25.742614
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBF",
          "name": "Munabao",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.827112,
            21.243022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "CLDR",
          "name": "Chilikidara",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.95507400000001,
            27.835319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KLYT",
          "name": "Kolayat",
          "zone": "NWR",
          "address": "Kolayat, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.798213,
            26.882215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CLD",
          "name": "CHALSA JN",
          "zone": "NFR",
          "address": "Chalsa, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.9704,
            26.880279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CRX",
          "name": "CARRON",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.774856,
            22.61521
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETF",
          "name": "ITARSI F CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.953082,
            24.404224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "CNPI",
          "name": "Chandanpahari",
          "zone": "ER",
          "address": "Chandanpahari, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.647526,
            22.828141000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIG",
          "name": "MIDGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.575956,
            23.848205999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNU",
          "name": "BANDAKPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.599508,
            27.293803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "COD",
          "name": "Chhota Gudha",
          "zone": "NWR",
          "address": "Ringas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.638968,
            22.864326000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHQ",
          "name": "CHOKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.765801,
            28.831621000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MB",
          "name": "MORADABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.85285999999999,
            29.291379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "IRA",
          "name": "ISRANA",
          "zone": "NR",
          "address": "Israna, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.132981,
            22.380046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRCA",
          "name": "KARACHIYA YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.1431251,
            22.3662226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJW",
          "name": "BAJVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.718347,
            11.332713
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CLN",
          "name": "KOLLIDAM",
          "zone": "SR",
          "address": "Vallampadugai, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OSHI",
          "name": "OSHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.629979,
            24.939557999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CNA",
          "name": "CHANDERIYA",
          "zone": "WR",
          "address": "Chittorgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.74565299999999,
            25.307555999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CRC",
          "name": "CHARKHARI ROAD",
          "zone": "NCR",
          "address": "Charkhari, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.934035,
            26.381257
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTT",
          "name": "BHATNI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.266903,
            24.122264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CSZ",
          "name": "Cossimbazar",
          "zone": "ER",
          "address": "Berhampore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.048979,
            21.85838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRE",
          "name": "VAREDIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.030592,
            21.807541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIU",
          "name": "NABIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTTY",
          "name": "KTTY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.010489,
            21.753134000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CVJ",
          "name": "CHAVAJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.000633,
            21.623889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKV",
          "name": "ANKLESHWAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.71665800000001,
            24.984962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CNE",
          "name": "CHANDRANATHPUR",
          "zone": "NFR",
          "address": "National Highway 54, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.978371,
            21.535197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAO",
          "name": "PANOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.86881457700001,
            25.946542716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CNL",
          "name": "CHANDAWAL",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.7180903,
            25.82438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BFY",
          "name": "BHESANA",
          "zone": "NWR",
          "address": "Marwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.969512,
            21.501091000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HAT",
          "name": "HATHURAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.257278,
            12.97518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "COL",
          "name": "Coromandel",
          "zone": "SWR",
          "address": "KGF, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.91647999999999,
            21.37682
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDSD",
          "name": "KUDSAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.890287,
            21.317203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYN",
          "name": "SAYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.290097,
            28.213407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MQP",
          "name": "Makrandpur",
          "zone": "NER",
          "address": "Aonla- Bareilly Link Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.880104,
            21.294043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTX",
          "name": "GOTHANGAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.207809,
            10.596938999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGK",
          "name": "MULANGUNNATHUKAVU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.172004,
            28.591905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "CNKP",
          "name": "CHANAKYAPURI",
          "zone": "NR",
          "address": "Chanakyapuri, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.85479600000001,
            21.232602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URN",
          "name": "UTRAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.840793,
            21.206568
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ST",
          "name": "SURAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.851461,
            21.171156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDN",
          "name": "UDHNA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.605456,
            16.188934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRU",
          "name": "VALIVERU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.63387399999999,
            16.843925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRPL",
          "name": "MARAMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.67550399999999,
            16.898297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDD",
          "name": "NIDADAVOLU JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.287678,
            21.416128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDM",
          "name": "GUDMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.25998102000001,
            21.975035039999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEF",
          "name": "BHUPDEOPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.39067,
            21.891288999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RIG",
          "name": "RAIGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.482238,
            21.883182
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRL",
          "name": "KOTARLIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.775924,
            10.160613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CTND",
          "name": "Chettinad",
          "zone": "SR",
          "address": "Kanadukathan, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.25287,
            28.919358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SEPR",
          "name": "SHERPUR",
          "zone": "NR",
          "address": "State Highway 51, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.914294,
            20.946851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NVS",
          "name": "NAVSARI",
          "zone": "WR",
          "address": "Navsari, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.268446,
            30.60775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JBD",
          "name": "Jalalabad",
          "zone": "NR",
          "address": "Jalalabad, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.67133299999999,
            26.859243000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DDM",
          "name": "DAM DIM",
          "zone": "NFR",
          "address": "National Highway 31, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.655356,
            23.417869
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "COE",
          "name": "CHITROD",
          "zone": "WR",
          "address": "Kutch, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.70272,
            27.158223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "COM",
          "name": "Chomun Samod",
          "zone": "NWR",
          "address": "Chomun, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.78753499999999,
            30.395686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DKT",
          "name": "DHULKOT",
          "zone": "NR",
          "address": "Ambala City, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.21464405030001,
            25.7036520692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUW",
          "name": "KANWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.267724,
            25.261093
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDMR",
          "name": "CHANDAULI MJHWR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IGPX",
          "name": "IGPX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.102658,
            25.761853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGA",
          "name": "KHAGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.16560600000001,
            25.730035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTCE",
          "name": "KATOGHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2620300824,
            25.678728805000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASCE",
          "name": "ATHSARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.706014,
            12.907633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CRLM",
          "name": "Carmelaram",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.047794,
            15.264834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "CNR",
          "name": "CHANDAR",
          "zone": "SWR",
          "address": "CHANRA (chandor), Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.07686199999999,
            18.262764
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CMDP",
          "name": "Chimidipalli",
          "zone": "ECoR",
          "address": "Borra, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.5020212,
            25.160771699999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDL",
          "name": "VINDHYACHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.58628900000001,
            28.136577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSA",
          "name": "TISUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.221682,
            22.797769
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TAS",
          "name": "Thasra",
          "zone": "WR",
          "address": "National Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.5033135022,
            27.1026834977
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHT",
          "name": "GHAGHARA GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.500127,
            26.938525000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BV",
          "name": "BABHNAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.69873199999999,
            27.487001000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CLW",
          "name": "Chilwariya",
          "zone": "NER",
          "address": "Bahraich, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OKAC",
          "name": "OKAC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.56115799999999,
            26.913411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAUR",
          "name": "GAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.639707,
            26.882281000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TH",
          "name": "TINICH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.03310139999999,
            26.3104766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHTR",
          "name": "BHATPAR RANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.354709,
            26.034431
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUR",
          "name": "TURKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.38359600000001,
            26.097367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RD",
          "name": "RAM DAYALU NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.306506,
            28.469954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTO",
          "name": "BHITAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.34185199999999,
            28.433371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKRA",
          "name": "PARSA KHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.42916573,
            28.3087958495
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHTI",
          "name": "CHANETI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.62578099999999,
            28.083668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLPU",
          "name": "BILPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.674809,
            28.03708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MK",
          "name": "MIRANPUR KATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.71796182739999,
            27.988261374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MILA",
          "name": "MILAKPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.741776,
            27.972984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLH",
          "name": "TILHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.353696,
            11.911049
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CQL",
          "name": "CHIRAKKAL",
          "zone": "SR",
          "address": "Kannur district, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.20943,
            10.535053999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNQ",
          "name": "PUNKUNNAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.207919,
            10.514790000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TCR",
          "name": "THRISUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.932807,
            11.934891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CMNR",
          "name": "CHAMARAJANAGAR",
          "zone": "SWR",
          "address": "Chamarajnagar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.637861,
            12.29844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CMJ",
          "name": "CHAMARAJAPURAM",
          "zone": "SWR",
          "address": "Mysore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.649646,
            22.917359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKA",
          "name": "BARKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.587299,
            22.994912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ODG",
          "name": "OBAIDULLA GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.944978,
            20.845167
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ACL",
          "name": "ANCHELI",
          "zone": "WR",
          "address": "Navsari, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.290734,
            21.055196000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CVR",
          "name": "CHORVAD ROAD",
          "zone": "WR",
          "address": "Gadu, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.402851,
            19.859565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CTH",
          "name": "CHIKALTHANA",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.12739309999999,
            26.6360965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTKD",
          "name": "BHARATKUND",
          "zone": "NR",
          "address": "Bikapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.32156499999999,
            31.949113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DHW",
          "name": "Dhariwal",
          "zone": "NR",
          "address": "Dhariwal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27660900000001,
            25.779962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAI",
          "name": "SARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.915988,
            22.068676999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "CPE",
          "name": "Chandiposi",
          "zone": "SER",
          "address": "National Highway 23, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1169303369,
            19.1803282622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CRU",
          "name": "CHUDAWA",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.977952,
            26.023137000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DBB",
          "name": "DHUBRI",
          "zone": "NFR",
          "address": "Dhubri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.96379300000001,
            29.388948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNP",
          "name": "PANIPAT JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27585499999999,
            29.13201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CPS",
          "name": "CHANDPUR SIAU HALT",
          "zone": "NR",
          "address": "Chandpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.53884199999999,
            22.427916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CX",
          "name": "CHANOL",
          "zone": "WR",
          "address": "State Highway 25, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.365246145,
            25.6767202377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CSR",
          "name": "CHAK SIKANDAR",
          "zone": "ECR",
          "address": "Chak Sikander, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.487603186,
            25.3615157718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CRQ",
          "name": "Charpokhari Halt",
          "zone": "ECR",
          "address": "Charpokhari, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9006577881,
            21.0097131944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VNA",
          "name": "VARANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.353298,
            26.7174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DAG",
          "name": "DHANG",
          "zone": "ECR",
          "address": "Bhang, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6466632,
            29.0094584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "CRW",
          "name": "Charaud",
          "zone": "NWR",
          "address": "National Highway 65, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.626207,
            21.218301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTL",
          "name": "BORTALAO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.102414,
            21.99178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHS",
          "name": "KHARSIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.182508,
            21.988744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROB",
          "name": "ROBERTSON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.511718,
            26.202272
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CPK",
          "name": "CHAPARMUKH JN",
          "zone": "NFR",
          "address": "Chaparmukh, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.014229,
            30.365958000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CTW",
          "name": "Chhintanwala",
          "zone": "NR",
          "address": "Sherpur Sodhian Link Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.386431,
            14.231154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CTA",
          "name": "Chitradurg",
          "zone": "SWR",
          "address": "Chitradurga, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AAV",
          "name": "Ambav",
          "zone": "WR",
          "address": "Ambav, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.58380799999999,
            16.124988000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDKU",
          "name": "MODUKURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.58648699999999,
            16.157497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSR",
          "name": "TSUNDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.606702,
            16.831606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBM",
          "name": "NAVABPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.038724,
            21.666901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMBR",
          "name": "N MAYURBHANJ ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0637831,
            21.7029241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTS",
          "name": "BASTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.014286,
            22.314788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CTD",
          "name": "Chhota Udepur",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.61698200000001,
            27.383483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DBY",
          "name": "Digboi",
          "zone": "NFR",
          "address": "Tinsukia, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1556666229,
            19.430619765
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CWI",
          "name": "CHONDI",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.6629705685,
            13.010940720799999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CSDR",
          "name": "CHANNASANDRA",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.641926,
            10.802091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGT",
          "name": "PALAKKAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.33241,
            27.112061
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MNHA",
          "name": "Mindha",
          "zone": "NWR",
          "address": "Minda to Mandha Bhimsingh Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.748038,
            23.168331000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DBO",
          "name": "DABHODA",
          "zone": "WR",
          "address": "Dabhoda, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.54288000000001,
            29.859997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DABN",
          "name": "DHABAN",
          "zone": "NWR",
          "address": "Sangaria, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.19093000000001,
            29.542416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DBI",
          "name": "DABLI RATHAN",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.367959,
            23.918067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SID",
          "name": "SIDDHPUR",
          "zone": "WR",
          "address": "State Highway 41, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.466475,
            23.643461000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "ARGD",
          "name": "ARIGADA",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.287323,
            23.994088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CTJ",
          "name": "CHHATAINI",
          "zone": "WCR",
          "address": "Awasthi Sadan Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.48673398129999,
            23.7758696073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTKD",
          "name": "KATANGI KHURD",
          "zone": "WCR",
          "address": "State Highway 10, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.94413499999999,
            22.450677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CRL",
          "name": "Choral",
          "zone": "WR",
          "address": "SH 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.860783,
            23.930507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "ABSA",
          "name": "Ambassa",
          "zone": "NFR",
          "address": "Ambassa, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.19955399999999,
            23.289155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NVP",
          "name": "Navalpura",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.720809,
            21.361353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DBKA",
          "name": "Dabka",
          "zone": "SCR",
          "address": "Amlakhurd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.80796000000001,
            22.656633999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CQR",
          "name": "Champapukur",
          "zone": "ER",
          "address": "Kholapota, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.166876,
            28.714564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDAZ",
          "name": "NAYA AZADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.16670699999999,
            28.714205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANDI",
          "name": "ADARSH NAGAR DELHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.966203,
            28.288281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CUR",
          "name": "Churu",
          "zone": "NWR",
          "address": "Churu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1377425,
            28.746799999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHD",
          "name": "BADLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.115838,
            28.769717999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHKN",
          "name": "KHERA KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.102334,
            28.801187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HUK",
          "name": "HOLAMBI KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.085727,
            28.846886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NUR",
          "name": "NARELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.953212,
            22.058201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CWA",
          "name": "Chhindwara Junction",
          "zone": "SECR",
          "address": "Chhindwara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.05914800000001,
            28.904132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDDE",
          "name": "RATHDHANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.64532,
            8.932478000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CTPE",
          "name": "Chandanattop Halt",
          "zone": "SR",
          "address": "Kilikkollur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.039135,
            28.945425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNN",
          "name": "HARANA KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.017343,
            28.989423000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNP",
          "name": "SONIPAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.012589,
            29.063432000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLKN",
          "name": "SANDAL KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.012109,
            29.090744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RUG",
          "name": "RAJLU GARHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.01074999999999,
            29.130776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNU",
          "name": "GANAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0082364,
            29.1852092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDMJ",
          "name": "BHODWAL MAJRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.003034,
            29.240172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMK",
          "name": "SAMALKHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.980194,
            29.318564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWNA",
          "name": "DIWANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.966178,
            29.449652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBDE",
          "name": "BABARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.968758,
            29.494782
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFU",
          "name": "KOHAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.96575000000001,
            29.541636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRA",
          "name": "GHARAUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.965812,
            29.613391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZJT",
          "name": "BAZIDA JATAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.969821,
            29.694566000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUN",
          "name": "KARNAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.95536200000001,
            29.751544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZK",
          "name": "BHAINI KHURD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.93858,
            29.797906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRR",
          "name": "TARAORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.925491,
            29.831492
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLKR",
          "name": "NILOKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.852795,
            29.969440000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKDE",
          "name": "KURUKSHETRA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.124352,
            8.378802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "DAVM",
          "name": "DHANUVACHAPURAM",
          "zone": "SR",
          "address": "Dhanuvachapuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.590027,
            18.922722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "CTS",
          "name": "CHATTRIPUT",
          "zone": "ECoR",
          "address": "Jeypore, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.56132,
            31.346977000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DAVJ",
          "name": "D.A.V.C.H. JALANDHAR",
          "zone": "NR",
          "address": "Jalandhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.14358825779999,
            28.628342466499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DLPI",
          "name": "INDRAPURI",
          "zone": "NR",
          "address": "Delhi Cantonment, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.92761300000001,
            23.110295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GRG",
          "name": "Guwarighat",
          "zone": "SECR",
          "address": "Gwarighat, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.186969,
            28.582121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DSJ",
          "name": "DELHI SAFDARJUNG",
          "zone": "NR",
          "address": "Netaji Nagar, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.803295,
            29.253606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DHRN",
          "name": "DHURANA",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.902042,
            25.934458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DUK",
          "name": "DUDIA",
          "zone": "NWR",
          "address": "Dundara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.70013,
            15.227107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DAJ",
          "name": "DUROJI",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01624276369999,
            25.4012639369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTPH",
          "name": "CHAKIA THERMAL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.875333703,
            22.0159803866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDDA",
          "name": "JATHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.050747,
            21.999876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDI",
          "name": "JHARRADIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.761747,
            10.037610999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "DKO",
          "name": "Devakottai Road",
          "zone": "SR",
          "address": "NH 210, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.303084,
            28.19378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DIB",
          "name": "DIBAI",
          "zone": "NR",
          "address": "Dibai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3239098425,
            26.4531901925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNJO",
          "name": "JUHI OUTER CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.58561499999999,
            25.578333999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LMA",
          "name": "LAMBIYA",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBLG",
          "name": "DBLG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.855552,
            25.610206
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DOHM",
          "name": "Devgarh Madriya",
          "zone": "NWR",
          "address": "Devgarh, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHLG",
          "name": "NHLG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.53134999999999,
            17.456247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DYE",
          "name": "DAYANAND NAGAR",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.470315,
            17.674226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DBV",
          "name": "DABILPUR",
          "zone": "SCR",
          "address": "Rangareddy, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGLP",
          "name": "JGLP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.930886,
            28.700863000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BGZ",
          "name": "BAHADURGARH",
          "zone": "NR",
          "address": "Bahadurgarh, Haryana"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNSR",
          "name": "PNSR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.509053,
            21.688346
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DME",
          "name": "DAMNAGAR",
          "zone": "WR",
          "address": "Damnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.613551,
            23.242037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "AG",
          "name": "Aranghata",
          "zone": "ER",
          "address": "Haranath Para Ln, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.64411,
            16.709921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RLG",
          "name": "RELANGI",
          "zone": "SCR",
          "address": "Relangi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.44306300000001,
            21.209789
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCAB",
          "name": "BMY C CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.736112,
            19.106268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DNDI",
          "name": "DHONDI",
          "zone": "SCR",
          "address": "State Highway 148, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.557243,
            16.067904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDO",
          "name": "NIDUBROLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.02541699999999,
            30.408641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "FKA",
          "name": "Fazilka",
          "zone": "NR",
          "address": "Fazilka, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.526314,
            16.809960999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDD",
          "name": "TADEPALLIGUDEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.581431,
            16.820864999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTPU",
          "name": "PRATTIAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.822411,
            21.621177
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BDHT",
          "name": "Bardi Halt",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.652122,
            13.183156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DJL",
          "name": "Dodajala Lake",
          "zone": "SWR",
          "address": "Hosur Road., Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.45086500000001,
            26.453101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DIU",
          "name": "Dhing",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.096828,
            24.631708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DGQ",
          "name": "Dugdol",
          "zone": "NWR",
          "address": "Dhanera, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.047442,
            30.834858
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DAU",
          "name": "Dagru",
          "zone": "NR",
          "address": "Dagru, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.24335073670001,
            25.737421135399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWH",
          "name": "GHOSHAWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2155,
            25.702909000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HJP",
          "name": "HAJIPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ENBG",
          "name": "ENBG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.26070700000001,
            10.420507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUK",
          "name": "PUDUKAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.275994,
            10.392415000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYI",
          "name": "NELLAYI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.448615,
            11.156494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UKL",
          "name": "UTTUKULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.341247,
            11.108906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUP",
          "name": "TIRUPPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.18638,
            11.087247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNO",
          "name": "SOMANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.11729,
            11.049634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUU",
          "name": "SULUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.06913399999999,
            11.017199
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IGU",
          "name": "IRUGUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.03945900000001,
            11.012921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHI",
          "name": "SINGANALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.999566,
            11.033241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLMD",
          "name": "PILAMEDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.966298,
            10.997639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBE",
          "name": "COIMBATORE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.990125,
            10.965425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTJ",
          "name": "PODANUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.94295000000001,
            10.900858
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDKI",
          "name": "MADUKARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.280924,
            10.339456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IJK",
          "name": "IRINJALAKUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3218076,
            10.3017975
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKI",
          "name": "CHALAKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3363143628,
            10.2853993721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DINR",
          "name": "DIVINE NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.355901,
            10.260123
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRAN",
          "name": "KORATTI ANGADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.373077,
            10.225757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUC",
          "name": "KARUKKUTTY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37786,
            10.183676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AFK",
          "name": "ANGAMALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37218899999999,
            10.133064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CWR",
          "name": "CHOVVARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.263326,
            27.038151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DJ",
          "name": "Darjeeling",
          "zone": "NFR",
          "address": "Darjeeling, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.356508,
            10.108163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AWY",
          "name": "ALUVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.32695600000001,
            10.061818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLMR",
          "name": "KALAMASSERY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.298271,
            10.034338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPL",
          "name": "EDAPPALLY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2861,
            9.991582000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ERN",
          "name": "ERNAKULAM TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.89715500000001,
            10.898788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETMD",
          "name": "ETTIMADAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8376135147,
            10.8471810329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WRA",
          "name": "WALAYAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.276917865,
            30.3379133215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DBN",
          "name": "Dhablan",
          "zone": "NR",
          "address": "Gurudwara Ishar Darbar Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.488923,
            18.927979999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DBF",
          "name": "Dabpal",
          "zone": "?",
          "address": "Dabpal, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.481039,
            26.455873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DBZ",
          "name": "Dhing Bazar",
          "zone": "NFR",
          "address": "Mathaouri Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.801793,
            10.837164999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLMD",
          "name": "CHULLIMADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.693457,
            10.815731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTKU",
          "name": "KOTTEKAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.65658,
            28.417832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DLC",
          "name": "DALMERA",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.30765699999999,
            9.933729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNU",
          "name": "TIRUNETTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3116,
            9.906723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUMM",
          "name": "KUMBALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.30283299999999,
            9.8791031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AROR",
          "name": "AROOR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.302391,
            9.820288000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EZP",
          "name": "EZHUPPUNNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.309979,
            9.772809
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUVR",
          "name": "TURAVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.28985899999999,
            9.978060000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ERSC",
          "name": "ERNAKULAM JN. C",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.50739300000001,
            18.945173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "DIR",
          "name": "DHANAPUR ORISSA",
          "zone": "ECoR",
          "address": "National Highway 43, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.476473,
            28.64478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DRN",
          "name": "DEORANIAN",
          "zone": "NER",
          "address": "SH 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.134476,
            25.425296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BGS",
          "name": "BEGU SARAI",
          "zone": "ECR",
          "address": "06243-222853, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.96972000000001,
            23.846381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQQ",
          "name": "BAKHLETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.65595449829999,
            25.9027951891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GBP",
          "name": "GULABPURA",
          "zone": "NWR",
          "address": "Gulabpura, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.140267,
            23.904168
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REI",
          "name": "RITHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.7596338837,
            28.83865785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBWC",
          "name": "MORADABAD WEST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.73559542849999,
            28.8719872056
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRH",
          "name": "HARTHALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.439153,
            26.480421000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GOA",
          "name": "GOHAD ROAD",
          "zone": "NCR",
          "address": "Bhind, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.13275402709999,
            19.465517438899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DHNR",
          "name": "DHANORA DECCAN",
          "zone": "SCR",
          "address": "Kinwat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.828816,
            25.446241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALD",
          "name": "ALLAHABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65391899999999,
            27.571173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ETAH",
          "name": "Etah",
          "zone": "NCR",
          "address": "Etah, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.568831,
            23.02676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GG",
          "name": "GANDHIGRAM",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.78867170000001,
            21.0472812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSL",
          "name": "BHUSAVAL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.077841,
            19.211345
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOPR",
          "name": "KOPAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.087286,
            23.71822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GGLE",
          "name": "GANGATIKURI",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.084599,
            24.953195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWD",
          "name": "KARWANDIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.64304,
            25.743851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GDD",
          "name": "Gadra Road",
          "zone": "NWR",
          "address": "Gadara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.019423,
            24.955926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSM",
          "name": "SASARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.31182199999999,
            23.277796
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DTAE",
          "name": "DHATRIGRAM",
          "zone": "ER",
          "address": "Dhatrigram, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.71490899999999,
            20.776649999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLOR",
          "name": "SELOO ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.883342,
            20.816446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNI",
          "name": "SINDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.19870999999999,
            23.351916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DNUA",
          "name": "Dhansura",
          "zone": "WR",
          "address": "State Highway 68, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.743775,
            22.019979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGRD",
          "name": "SULGARE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.813724,
            22.011725000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUA",
          "name": "BARADWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.956526,
            22.011785000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKT",
          "name": "SAKTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05712899000001,
            10.515627989999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "EDU",
          "name": "ERIODU",
          "zone": "SR",
          "address": "State Highway 74, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.648076,
            27.111885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUJ",
          "name": "SARJU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.786203,
            8.830736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "GDN",
          "name": "GANGAIKONDAN",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.941557,
            29.814493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDR",
          "name": "LANDAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.13019,
            28.658317
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUN",
          "name": "DUGANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.371946,
            28.396895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBJ",
          "name": "CLUTTER BUCK GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4108444,
            28.337154299999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BE",
          "name": "BAREILLY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHGJ",
          "name": "NHGJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.798715,
            25.090538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DTC",
          "name": "DITOKCHERRA",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.581099,
            24.872902
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BPG",
          "name": "BADURPUR GHAT",
          "zone": "NFR",
          "address": "National Highway 53, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.794583,
            25.845508
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GIW",
          "name": "Girdharpur",
          "zone": "NCR",
          "address": "State Highway 6, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.47445900000001,
            28.26396
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RYS",
          "name": "RASURIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.099366,
            25.763263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYO",
          "name": "NAYAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.133951,
            25.741290999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMU",
          "name": "PARMANANDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.5441789069,
            27.526839296800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GIR",
          "name": "Gainsari Junction",
          "zone": "NER",
          "address": "Balrampur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.46842480000001,
            15.9086144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPP",
          "name": "BAPATLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.514466,
            15.970371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APL",
          "name": "APPIKATLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.54154100000001,
            16.025343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCVM",
          "name": "MACHAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.396533,
            16.819948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CEL",
          "name": "CHEBROL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.468503,
            26.282768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GGH",
          "name": "Ghogardiha",
          "zone": "ECR",
          "address": "Ghoghardiha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.473751,
            16.828104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPY",
          "name": "BADAMPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.102374,
            21.734258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARD",
          "name": "AMARDA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.628672,
            11.25334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PY",
          "name": "PERUNDURAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.57257200000001,
            30.189431000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "FSR",
          "name": "FAQARSAR",
          "zone": "NR",
          "address": "Fakarsar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.0956546234,
            26.5616754046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CFG",
          "name": "CHAKITING",
          "zone": "NFR",
          "address": "Dhodar Ali, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.587299,
            11.22501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IGR",
          "name": "INGUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.522231,
            11.195525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VZ",
          "name": "VIJAYAMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42932499999999,
            24.998509000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLA",
          "name": "DOULTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.414552,
            25.039255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBT",
          "name": "TALBAHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.40284000000001,
            25.0851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZX",
          "name": "MATATILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.394254,
            25.142923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZY",
          "name": "BASAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.451748,
            25.237832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAB",
          "name": "BABINA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.478365,
            25.304157999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHJ",
          "name": "KHAJRAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.51774610000001,
            25.3764678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJI",
          "name": "BIJAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.545787,
            25.41949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHSA",
          "name": "JHANSI A CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55298499999999,
            25.443585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHS",
          "name": "JHANSI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.509084,
            25.530800000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRQ",
          "name": "KARARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.456356,
            25.641070000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAA",
          "name": "DATIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.409099,
            25.730668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOR",
          "name": "SONAGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.381661,
            25.811253999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTRA",
          "name": "KOTRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.331015,
            25.881197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBA",
          "name": "DABRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3042595734,
            25.9219956094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMTL",
          "name": "SIMARIATAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2761320917,
            25.955389501299997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AEH",
          "name": "ANANT PAITH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.143212,
            27.081164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FRH",
          "name": "Farhatnagar",
          "zone": "NR",
          "address": "Farhatnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6329945703,
            23.3498809788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GATD",
          "name": "Gautamdhara",
          "zone": "SER",
          "address": "Gautamdhara, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.919246,
            20.906347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GEG",
          "name": "Gir Gadhara",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.81311799999999,
            25.623979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FLD",
          "name": "Phulad",
          "zone": "NWR",
          "address": "State Highway 62, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.930509,
            27.20976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GADM",
          "name": "Ganga Dham",
          "zone": "NER",
          "address": "Ganga Dham, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.550321,
            20.714515000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DTR",
          "name": "Dhamtari",
          "zone": "SECR",
          "address": "Dhamtari, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.378575,
            23.960815999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DRW",
          "name": "DHAREWADA",
          "zone": "WR",
          "address": "Unjha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.279577,
            25.300189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KZQ",
          "name": "KHIMEL",
          "zone": "NWR",
          "address": "Jawai Bandh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2834127417,
            23.9150542164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDU",
          "name": "HARDUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.533732,
            29.215097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "HDW",
          "name": "HALDWANI",
          "zone": "NER",
          "address": "Haldwani, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.422049,
            22.029121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKT",
          "name": "AKALTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.298175,
            17.988470000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HBU",
          "name": "Halbarga",
          "zone": "SCR",
          "address": "Halbarga, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.755511,
            18.973377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GNH",
          "name": "GANGAKHER",
          "zone": "SCR",
          "address": "State Highway 217, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.56458900000001,
            22.022963999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIA",
          "name": "NAILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.946305,
            24.976944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMGE",
          "name": "KUMAHU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.895453,
            24.999464999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSG",
          "name": "SHIU SAGAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.665149,
            22.034731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPH",
          "name": "CHAMPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.68198989999999,
            23.2008894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GPX",
          "name": "Gautampura Road",
          "zone": "WR",
          "address": "Barnagar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.183592,
            23.596666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RGQ",
          "name": "RAIGADH ROAD",
          "zone": "NWR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.36834,
            22.68118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GS",
          "name": "GODHANESHWAR",
          "zone": "WR",
          "address": "Bagodara Dholka Link Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.801799,
            28.582962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GLH",
          "name": "Gulaothi",
          "zone": "NR",
          "address": "Gulaothi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.912953,
            22.570155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HNB",
          "name": "Hasanabad",
          "zone": "ER",
          "address": "Taki, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.324846,
            16.809911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUA",
          "name": "PULLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.36421899999999,
            16.815341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKRM",
          "name": "KAIKORAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.22049100000001,
            21.79749
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JER",
          "name": "JALESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.893347,
            25.869213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FYZ",
          "name": "FAIZULLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72593400000001,
            11.327682
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ED",
          "name": "ERODE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4910470972,
            28.569053803099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HR",
          "name": "Harpalu",
          "zone": "NWR",
          "address": "Bahal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.977088,
            25.826999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RUB",
          "name": "RASULABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.03942,
            25.795325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNIE",
          "name": "SATH NARAINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.49801,
            15.754344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DUR",
          "name": "DESUR",
          "zone": "SWR",
          "address": "Desur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.855323,
            16.571696999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CNC",
          "name": "CHINCHLI",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.656727,
            28.989821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTB",
          "name": "MATLABPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.621471,
            29.059768000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNT",
          "name": "KANTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.613167,
            29.118385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWE",
          "name": "MEWA NAWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.580977,
            29.204807000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEO",
          "name": "SEOHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.536047,
            29.249784000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAJ",
          "name": "CHAKRAJ MAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.501588,
            29.305446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPR",
          "name": "DHAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.47897300000001,
            29.349034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HBW",
          "name": "HABIBWALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.38171899999999,
            21.237962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GKD",
          "name": "GADHAKDA",
          "zone": "WR",
          "address": "Gadhakada Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.445574,
            29.388929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNI",
          "name": "PURAINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.424119,
            29.437919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGG",
          "name": "NAGINA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3932854,
            29.522577000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEK",
          "name": "BUNDKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.4272883557,
            25.280144010999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GYN",
          "name": "GYANPUR ROAD",
          "zone": "NER",
          "address": "Bhadohi District, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3756456903,
            29.57239523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSDR",
          "name": "MURSHADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.335733,
            29.603540000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBD",
          "name": "NAJIBABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27795372130001,
            29.5937410175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FZL",
          "name": "FAZALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.152782,
            29.576786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNK",
          "name": "CHANDOK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1124328,
            29.6274345
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLW",
          "name": "BALAWALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0664335504,
            29.6928321018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSI",
          "name": "RAISI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.9720505023,
            23.552978399500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AAR",
          "name": "ADESAR",
          "zone": "WR",
          "address": "Dist. Kachchh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.812314,
            14.515431
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HRR",
          "name": "HARIHAR",
          "zone": "SWR",
          "address": "Harihar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.1072235,
            18.66763705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GVA",
          "name": "Ganguwada",
          "zone": "ECoR",
          "address": "Ganguwada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.58074500000001,
            21.629554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GUX",
          "name": "Gurhi",
          "zone": "SCR",
          "address": "Khandwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.297633,
            29.614904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HMH",
          "name": "HANUMANGARH JN",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.20734300000001,
            10.979489000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "AAM",
          "name": "Angadippuram",
          "zone": "SR",
          "address": "Angadipuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.78576100000001,
            31.016907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHR",
          "name": "PHILLAUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.78581100000001,
            31.016957
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDW",
          "name": "LADHOWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.84787299999999,
            30.912367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDH",
          "name": "LUDHIANA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.87188,
            30.894741
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHOI",
          "name": "DHANDARIKALAN BLOCK HUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8767,
            21.186952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HKR",
          "name": "Kiwar Khed",
          "zone": "SCR",
          "address": "State Highway 194, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.90949099999999,
            30.874477
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDL",
          "name": "DHANDARI KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9795,
            30.837436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNL",
          "name": "SANAHWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.04330300000001,
            30.804137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOA",
          "name": "DORAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0801522656,
            30.7847375851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSPN",
          "name": "JASPALON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.127464975,
            30.7594625741
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHA",
          "name": "CHAWAPALL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.22513400000001,
            30.707973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNN",
          "name": "KHANNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2970739075,
            30.6694834261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVG",
          "name": "GOVINDGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.383194,
            30.624222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIR",
          "name": "SIRHIND JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.44847259640001,
            30.5828632034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDY",
          "name": "SADHOO GARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.515682,
            30.538386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBJ",
          "name": "SARAI BANJARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5940201901,
            30.486458949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPJ",
          "name": "RAJPURA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.684143667,
            30.4290274715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMU",
          "name": "SAMBHU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.738546,
            29.896397999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDL",
          "name": "CHODIALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.803799,
            29.875114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IQB",
          "name": "IQBALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.87417,
            29.851671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RK",
          "name": "ROORKEE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9019227979,
            29.8434029837
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNRA",
          "name": "DHANDHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.979636,
            29.784745
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSNI",
          "name": "DAUSNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.203824,
            12.659673000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CPT",
          "name": "CHANNAPATNA",
          "zone": "SWR",
          "address": "Channapatna, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.796689,
            28.826066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGF",
          "name": "KATHGHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.87718699999999,
            28.820438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLP",
          "name": "DALPATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.939038,
            28.800366999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPH",
          "name": "MUNDHA PANDE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.023419,
            28.778862999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMU",
          "name": "RAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.05214699999999,
            28.750033000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAR",
          "name": "SHAHZAD NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.087745,
            28.707129000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAM",
          "name": "DHAMORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9690033484,
            25.751433665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AABH",
          "name": "AMBIKA BHAWANI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.003168,
            25.743938
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGA",
          "name": "DIGHWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.03663800000001,
            25.768739999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STLR",
          "name": "SITALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.906874,
            26.581722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JDR",
          "name": "JIWDHARA",
          "zone": "ECR",
          "address": "Jiwdhara, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.702865,
            28.939542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AWP",
          "name": "AGHWANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.913948,
            23.390711
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KIA",
          "name": "KHARI AMRAPUR",
          "zone": "WR",
          "address": "State Highway 237, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.719362,
            16.802926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "IMR",
          "name": "IBRAHIMPUR",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.457612,
            21.530606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JND",
          "name": "JUNAGADH JN",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.6324910046,
            21.8163569109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JA",
          "name": "JALIYA",
          "zone": "WR",
          "address": "Ranuja Village Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.87706899999999,
            16.479094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "IDP",
          "name": "INDUPALLI",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.351193,
            13.216441000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DBL",
          "name": "DODBELE",
          "zone": "SWR",
          "address": "National Highway 4, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.848303,
            25.021489000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVD",
          "name": "KHURMABAD ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.410031,
            23.715138999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JBO",
          "name": "Jamadobu",
          "zone": "SER",
          "address": "Jharia, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.79337100000001,
            25.048552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTQ",
          "name": "KUDRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.72151,
            25.097235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSE",
          "name": "PUSAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.211206,
            16.797409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STPT",
          "name": "SITAMPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.098669,
            23.749987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JHBN",
          "name": "JHAMATPUR BAHARAN",
          "zone": "ER",
          "address": "Goai, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.763996,
            30.378497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UBC",
          "name": "AMBALA CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.461461,
            23.098255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JIT",
          "name": "JIRAT",
          "zone": "ER",
          "address": "Jirat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.26109679,
            16.80848173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMD",
          "name": "BHIMADOLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2010774705559,
            27.731618001396782
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JDD",
          "name": "Jerthi Dadhia",
          "zone": "NWR",
          "address": "MDR 87, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.25095300000001,
            21.842011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LXD",
          "name": "LAKSHANNATH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.81771,
            26.028001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JGJN",
          "name": "JUGIJAN",
          "zone": "NFR",
          "address": "National Highway 36, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.9604479663,
            26.3877071452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JMI",
          "name": "JAMGURI",
          "zone": "NFR",
          "address": "National Highway 39, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.897226,
            15.344532000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "CSM",
          "name": "CANSAULIM",
          "zone": "SWR",
          "address": "Arossim Beach Rd, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42955,
            25.211179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPW",
          "name": "BURHPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.767021,
            26.318344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "IDM",
          "name": "Indemau",
          "zone": "NR",
          "address": "Indemau, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.728838,
            27.139983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSR",
          "name": "KASTURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.773788,
            27.141734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIR",
          "name": "MAIJAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.592052,
            22.191279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JNO",
          "name": "Junnor Deo",
          "zone": "CR",
          "address": "Dongar Parasia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.93402688110001,
            27.1402272205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDK",
          "name": "GONDA KACHAHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.055181,
            27.122672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRCK",
          "name": "BARUACHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.88271399999999,
            21.696442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JOL",
          "name": "Jogal",
          "zone": "?",
          "address": "Jogal, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.178833,
            25.520955
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ICL",
          "name": "Ichauli",
          "zone": "NCR",
          "address": "Maudaha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.103635,
            27.101387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOTG",
          "name": "MOTIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.17287785639999,
            27.072604043299997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLHI",
          "name": "JHILAHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.0246425354,
            25.6337383216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KAU",
          "name": "Kachna",
          "zone": "NFR",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2487596802,
            26.137192374999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBHL",
          "name": "Bela Bela",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.75514700000001,
            18.559639999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JOA",
          "name": "JANWAL",
          "zone": "SCR",
          "address": "Latur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.952369,
            23.745814000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JADR",
          "name": "Jadar",
          "zone": "WR",
          "address": "State Highway 9, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.642431,
            22.253213000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JJW",
          "name": "Jojwa",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.227648,
            27.039058
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUR",
          "name": "MANKAPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2775887885,
            27.0276257858
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKNR",
          "name": "LAKHPAT NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3597396039,
            27.0013792301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSW",
          "name": "MASKANWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.820053,
            11.396939999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANU",
          "name": "ANANGUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.512918,
            25.160225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POE",
          "name": "Pawapuri Road Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3961659851,
            26.9810021017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNC",
          "name": "S NARAYAN CHPLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.34929577759999,
            25.2665823122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HSB",
          "name": "Hasanbazar Halt",
          "zone": "ECR",
          "address": "Hasan Bazar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.4273550012,
            26.9662637829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNGY",
          "name": "BAHMAN JYOTIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.45215499999999,
            26.95565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PATI",
          "name": "PARSA TEWARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.91664399999999,
            25.431145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JI",
          "name": "JHUSI",
          "zone": "NER",
          "address": "NH 2, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.707468,
            26.864223000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOVR",
          "name": "GOVINDNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.77005899999999,
            26.815678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BST",
          "name": "BASTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.83031100000001,
            26.802388999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ORW",
          "name": "ORWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.97763599999999,
            26.782155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRV",
          "name": "CHUREB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.96656200000001,
            31.612827000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOW",
          "name": "MANANWALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.05441200000001,
            31.589979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNL",
          "name": "JANDIALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.107232,
            31.576425999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRA",
          "name": "TANGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.44396800000001,
            27.668988
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JSH",
          "name": "Jaswantgarh",
          "zone": "NWR",
          "address": "Sujangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.183274,
            31.556444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTR",
          "name": "BUTARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.238827,
            31.541835000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBKR",
          "name": "BABA BAKALARAYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2908085,
            31.5193738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEAS",
          "name": "BEAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.335011,
            31.498953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIW",
          "name": "DHILWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4078429907,
            31.4659508779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RYM",
          "name": "RAMIDI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.29979800000001,
            26.881072
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JKI",
          "name": "Kauriaa Jungle",
          "zone": "?",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.432702,
            31.45628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HMR",
          "name": "HAMIRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.492544,
            31.432388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRE",
          "name": "KARTARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.541964,
            31.380553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRX",
          "name": "SURA NUSSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65731229,
            10.93262802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POM",
          "name": "Punthottam",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.619424,
            28.006921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LOHA",
          "name": "Loha",
          "zone": "NWR",
          "address": "Ratangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5648681348,
            31.3530203474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBSL",
          "name": "BABA SODHAL NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.591499,
            31.331665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUC",
          "name": "JALANDHAR CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.48584299999999,
            13.666284000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KBA",
          "name": "Kurabalakota",
          "zone": "SCR",
          "address": "Kurabalakota Railway Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.63210600000001,
            31.306235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRC",
          "name": "JALANDHAR CANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.963828,
            26.368567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "ABE",
          "name": "Ambikeshwar",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.70669127869999,
            31.262694745
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CEU",
          "name": "CHIHERU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.769234,
            31.175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLIH",
          "name": "MAULI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.769544,
            31.17531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAUL",
          "name": "MAULA ALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.77419300000001,
            31.129722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRY",
          "name": "GORAYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.122043,
            31.100734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "JTO",
          "name": "Jutogh",
          "zone": "NR",
          "address": "Shimla, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.857438,
            29.699949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JPS",
          "name": "Jamalpur Shaikhan",
          "zone": "?",
          "address": "MDR 101, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.780007,
            31.071444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTTN",
          "name": "BHATIAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9361293799,
            25.768730655699997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPDA",
          "name": "PANCHPATIA DEORIA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.983444,
            19.297247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KHBV",
          "name": "KHARBAO",
          "zone": "CR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.329069,
            27.015612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KEV",
          "name": "KELA DEVI",
          "zone": "WCR",
          "address": "Bharatpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.49003499999999,
            10.771022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KDE",
          "name": "KORADACHERI",
          "zone": "SR",
          "address": "Koradacheri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6268054126,
            27.086514837299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHGN",
          "name": "SHRI GHASINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.879653656,
            22.434330919300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "QGT",
          "name": "KOLAGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6960924713,
            27.110623039700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BW",
          "name": "BIWAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.453837,
            26.72824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHM",
          "name": "KUSMHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.86004199999999,
            25.393138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYN",
          "name": "NAINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.842854,
            24.862627000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CTHR",
          "name": "CHITAHRA",
          "zone": "WCR",
          "address": "MDR 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.827717,
            24.568619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "STA",
          "name": "SATNA",
          "zone": "WCR",
          "address": "Satna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3899776996,
            15.08644671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KALS",
          "name": "KALAS HALT",
          "zone": "SWR",
          "address": "State Highway 6, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9946102,
            8.847387005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KLPM",
          "name": "KAILASAPURAM",
          "zone": "SR",
          "address": "Thattaparai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.382161,
            17.942175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KHNP",
          "name": "Khanapur Deccan",
          "zone": "SCR",
          "address": "State Highway 4, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.482965,
            24.255819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "KBSH",
          "name": "Krishna Ballabh",
          "zone": "ER",
          "address": "Giridih, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.766265,
            23.217031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KEMA",
          "name": "KUKMA",
          "zone": "WR",
          "address": "Kutch, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.79331499999999,
            10.068518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KKDI",
          "name": "Karaikkudi Junction",
          "zone": "SR",
          "address": "Ariyakudi Road, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.95139499999999,
            19.407451000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JXN",
          "name": "JIRONA",
          "zone": "?",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.923013,
            23.282475
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KJZ",
          "name": "KARONJI",
          "zone": "SECR",
          "address": "Devipur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.106996,
            30.965544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KDZ",
          "name": "Kandaghat",
          "zone": "NR",
          "address": "Kandhaghat, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.848443,
            10.764487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NPB",
          "name": "Nagapattinam Beach",
          "zone": "SR",
          "address": "Nagapattinam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.834833,
            21.372513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNU",
          "name": "MUNDIKOTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.355654,
            30.673761
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JWN",
          "name": "Jiwa Arain",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.981657,
            21.430310000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWN",
          "name": "KACHEWANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3746491022,
            21.361292855400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGN",
          "name": "AMGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNMA",
          "name": "LNMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.291837,
            21.200326999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DURG",
          "name": "DURG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.494827,
            22.026018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPNA",
          "name": "KAPAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.33250699999999,
            21.926994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDTR",
          "name": "KIRODIMALNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.61914300000001,
            25.173236999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTGE",
          "name": "MUTHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.406913,
            15.868003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPF",
          "name": "STUARTPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.11976200000001,
            16.717816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EE",
          "name": "ELURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.18047200000001,
            16.781406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEL",
          "name": "DENDULURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.26992299999999,
            21.892581999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGV",
          "name": "ANGUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.284055,
            21.930376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNT",
          "name": "DANTAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.397414,
            26.494365000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KHTN",
          "name": "Khutauna",
          "zone": "ECR",
          "address": "Khutuana, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.618913,
            25.173007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBU",
          "name": "BHABUA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.658063,
            27.814972
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KJC",
          "name": "Kasganj City",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.539684,
            25.207897000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGO",
          "name": "DURGAUTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.89921204650001,
            11.4809140096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVPM",
          "name": "MAVELIPALAIYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.870439,
            11.445732
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGE",
          "name": "SANKARIDURG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.4908372314,
            25.2271654039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DCX",
          "name": "DHANICHHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.236073,
            25.451955
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KID",
          "name": "KHAIRAR JN",
          "zone": "NCR",
          "address": "NH-76 Banda(05192-224813), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.979976,
            25.209491000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KHOH",
          "name": "KHOH",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.51987899999999,
            30.418387999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KLI",
          "name": "Kauli",
          "zone": "NR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.63751,
            25.29552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KLAR",
          "name": "KULPAHAR",
          "zone": "NCR",
          "address": "Kulpahar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.430892,
            25.239738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMS",
          "name": "KARAMNASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.822716,
            23.897087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KHBJ",
          "name": "KHANNA BANJARI",
          "zone": "WCR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.35118,
            25.253531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYJ",
          "name": "SAIDRAJA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.18436299999999,
            25.273922000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAQ",
          "name": "GANJ KHAWAJA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.172391,
            25.289358999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FOCM",
          "name": "FLYOVER CABIN MUGHALSARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.924798,
            25.645355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KCT",
          "name": "Kirakat",
          "zone": "NER",
          "address": "Jaunpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.055073,
            26.77453
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLD",
          "name": "KHALILABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.133741,
            26.750013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHH",
          "name": "MAGHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.16487227660001,
            26.757903231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIPR",
          "name": "SIHAPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.2147,
            26.763075
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWA",
          "name": "SAHJANWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.685594,
            26.017246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWH",
          "name": "MALWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.278505,
            26.772224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTB",
          "name": "JAGATBELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.56702899999999,
            12.401425999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KJS",
          "name": "Krishnarajasagar",
          "zone": "SWR",
          "address": "Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.931759,
            30.837667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KLK",
          "name": "Kalka",
          "zone": "NR",
          "address": "Kalka, Haryana"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNS",
          "name": "PILLAMSHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.752455,
            25.955085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKS",
          "name": "KURASTI KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.801869,
            25.917489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FTP",
          "name": "FATEHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.341993,
            26.765251
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMG",
          "name": "DOMINGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.09492,
            26.265917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTK",
          "name": "BANKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.63034779659999,
            31.4751812122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KKL",
          "name": "KALA BAKRA",
          "zone": "NR",
          "address": "Dhogri Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.906765,
            25.765088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAGL",
          "name": "BARAGOPAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.956774,
            25.764395999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATNR",
          "name": "AWATAR NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.754761,
            25.952482999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KHKT",
          "name": "KHOTKHOTI",
          "zone": "NFR",
          "address": "Golaghat Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2266994458,
            26.0352337224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARI",
          "name": "ANTRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.779037,
            27.862753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KDPS",
          "name": "KADIPUR SANI HA",
          "zone": "NER",
          "address": "Kadipur Sani, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.788481,
            27.902943999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KITN",
          "name": "KHIRI TOWN",
          "zone": "NER",
          "address": "Kheri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.236678,
            25.162718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNF",
          "name": "MANDA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.56986900000001,
            25.13435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZP",
          "name": "MIRZAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.6513464526,
            25.124474674400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHG",
          "name": "JHINGURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7247816913,
            25.1197842638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRE",
          "name": "PAHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.800991,
            25.098881000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAP",
          "name": "DAGMAGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.874703,
            25.103351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAR",
          "name": "CHUNAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.947581,
            25.155223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYT",
          "name": "KAILAHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0188834607,
            25.1965007241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARW",
          "name": "AHRAURA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0618954504,
            25.2313981241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JEP",
          "name": "JEONATHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.10921,
            25.273789999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEWC",
          "name": "NEW WEST CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.747363,
            22.673479
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRKD",
          "name": "POWERKHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5462,
            23.049837
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ITKL",
          "name": "ITAYAKALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.837282,
            27.156663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KOZ",
          "name": "KOSMA",
          "zone": "NCR",
          "address": "Kosma, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.511077,
            23.093747
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDDP",
          "name": "MANDI DIP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.557389,
            11.069825000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KTM",
          "name": "KUTRALAM",
          "zone": "SR",
          "address": "Kuthalam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.659526,
            20.307616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGN",
          "name": "NANDGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.62706700000001,
            24.83436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KTX",
          "name": "KATAKHAL JN",
          "zone": "NFR",
          "address": "Hailakandi, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.71301299999999,
            22.337584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KRBA",
          "name": "Korba",
          "zone": "SECR",
          "address": "Korba, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.767128,
            22.608258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ET",
          "name": "ITARSI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.891059,
            16.306774
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KSAR",
          "name": "KUDALA SANGAM",
          "zone": "SWR",
          "address": "NH 13, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1481127075,
            13.3161963923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KIAT",
          "name": "KYATSANDRA",
          "zone": "SWR",
          "address": "Tumkur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.145838,
            22.120085999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSPR",
          "name": "KASHIPURA SARAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.33984000000001,
            22.078584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNT",
          "name": "CONTAI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.789851,
            22.286675000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KRMD",
          "name": "Kuarmunda",
          "zone": "SER",
          "address": "Sundargarh, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6733909,
            23.8293873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GEA",
          "name": "GHATERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.46165,
            17.414221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KQD",
          "name": "KHAIRATABAD DCN",
          "zone": "SCR",
          "address": "Hyderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.31496800000001,
            29.411154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHUL",
          "name": "Mehuwala Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.891318,
            30.091597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ORKI",
          "name": "ORKI",
          "zone": "NR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.477006,
            30.337054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "HYA",
          "name": "HADIAYA",
          "zone": "NR",
          "address": "Khudi Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.29694900000001,
            23.779733
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MLQ",
          "name": "Malkera Junction",
          "zone": "SER",
          "address": "National Highway 32, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71197699999999,
            17.608886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KOHR",
          "name": "Kohir Deccan",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.860597,
            29.750497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "HMQ",
          "name": "HIMMATPURA",
          "zone": "NR",
          "address": "Tohana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.10026,
            23.661572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NBKH",
          "name": "NABA GRAM KANKU",
          "zone": "ER",
          "address": "Nabagram Kakurhati, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.353984,
            23.211377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ABKA",
          "name": "AMBIKA KALNA",
          "zone": "ER",
          "address": "Kalna, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.44421,
            23.054229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KMAE",
          "name": "KHAMARGACHHI",
          "zone": "ER",
          "address": "Khamargachi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.38512200000001,
            15.851913000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPPM",
          "name": "IPURUPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.300375,
            28.321248999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KNNK",
          "name": "Kanina Khas",
          "zone": "NWR",
          "address": "MDR 124, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.96152799999999,
            16.649931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NZD",
          "name": "NUZVID",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.15770702,
            25.99510551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KNH",
          "name": "Konch",
          "zone": "NCR",
          "address": "Kocha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.044473,
            16.69451
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VAT",
          "name": "VATLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.416841,
            26.688907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HOJ",
          "name": "HAMITONGANJ",
          "zone": "NFR",
          "address": "Hamiltonganj, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPRA",
          "name": "IPRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.122339,
            20.904161000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMKD",
          "name": "KHAMKHED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.30618600000001,
            22.004654000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSI",
          "name": "NIKURSINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.297550556,
            30.0411022365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KTKL",
          "name": "KOTLI KALAN",
          "zone": "NR",
          "address": "State Highway 12A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.304284,
            20.290186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JSRD",
          "name": "Jhankad Sarala Road",
          "zone": "?",
          "address": "Jhankad, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.524416,
            29.929458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPT",
          "name": "Dalpat Singhpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.33691374,
            22.07144398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLDA",
          "name": "BELDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.223009,
            24.169924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAKR",
          "name": "MALKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.987264,
            11.55092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DC",
          "name": "MAGUDANCHAVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2603362,
            24.1358767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJQ",
          "name": "BAGHORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.33112799999999,
            24.051525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYE",
          "name": "KHURAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.399411,
            24.006945
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMRR",
          "name": "SUMRERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.934133,
            23.310434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KOY",
          "name": "KHEROL",
          "zone": "WR",
          "address": "State Highway 237, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.877596,
            23.227583000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JLM",
          "name": "JALIYA MATH",
          "zone": "WR",
          "address": "State Highway 68, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.478729,
            23.973744999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRK",
          "name": "JERUWA KHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.760945,
            11.351027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CV",
          "name": "KAVERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.548987,
            23.927214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ISH",
          "name": "ISARWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPMR",
          "name": "CPMR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.604147,
            23.89567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NOI",
          "name": "NARIAOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.464704,
            28.411947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KSI",
          "name": "Kosli",
          "zone": "NWR",
          "address": "Tumna Rd, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.685372,
            23.83539
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTZ",
          "name": "RATONA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.74292,
            23.84735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGO",
          "name": "SAUGOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.67854899999999,
            8.960373
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KUV",
          "name": "Kundara",
          "zone": "SR",
          "address": "Kundara, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.116577,
            28.613534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DEC",
          "name": "DELHI CANTT",
          "zone": "NR",
          "address": "Mayapuri Industrial Area Phase II/Lajwanti Garden, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.80807700000001,
            23.867050000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKRN",
          "name": "MAKRONIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.87232300000001,
            23.852972
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDA",
          "name": "LIDHORA KHURD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.50456299999999,
            23.858463999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYX",
          "name": "KARHIYA BHADELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.738652,
            23.798776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTY",
          "name": "GOLA PATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8114389709,
            23.811896152499997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAO",
          "name": "SAGONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.96911659119999,
            23.8460764217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYA",
          "name": "SALAIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.276887,
            18.854521000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KMLR",
          "name": "Kamalur",
          "zone": "ECoR",
          "address": "Kamalur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3474740967,
            23.883133289299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJGP",
          "name": "MAJHGAWAN PHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4014844,
            23.8343563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMZ",
          "name": "KATNI MURWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.73248199999999,
            22.294845000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "KNPS",
          "name": "Kendposi",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.580557,
            26.116562000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKO",
          "name": "BINDKI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.625838,
            26.074302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSQ",
          "name": "KANSPUR GUGAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.14076899999999,
            18.539932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NGTN",
          "name": "NAGOTHANE",
          "zone": "CR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.19215700000001,
            23.820217999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "KNF",
          "name": "Khanudih",
          "zone": "SER",
          "address": "Bokaro, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.216912,
            24.234023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGD",
          "name": "AGASOD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.265477,
            24.330273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOA",
          "name": "KARONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2889,
            24.378687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXS",
          "name": "MOHASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.498091,
            28.187268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KORI",
          "name": "Khori",
          "zone": "NWR",
          "address": "State Highway 26, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.321791,
            24.44965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUA",
          "name": "DHAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.341911,
            24.547359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLN",
          "name": "JAKHALAUN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.361937,
            24.608534000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRO",
          "name": "JIRON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.395791,
            24.688222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAR",
          "name": "LALITPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.392945,
            24.78059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWA",
          "name": "DAILWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.400513,
            24.870821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHA",
          "name": "JAKHAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.160353,
            25.506883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKB",
          "name": "GHATAKA VARANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.91018299999999,
            26.21389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LRD",
          "name": "LAR ROAD",
          "zone": "NER",
          "address": "Deoria, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.162817,
            30.236037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LHM",
          "name": "LEHRA MUHABBAT",
          "zone": "NR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.56287499999999,
            21.018114999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JL",
          "name": "JALGAON JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.01867416990001,
            25.3897784806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARE",
          "name": "ARNETHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2061342529,
            19.176458097999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LBG",
          "name": "LIMBGAON",
          "zone": "SCR",
          "address": "Huzur Sahib Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.937733,
            25.324522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPTN",
          "name": "KESHORAI PATAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9046559998,
            25.2972303873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNCN",
          "name": "GURLA N CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.9366600031,
            20.424540226700003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPR",
          "name": "HIRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.90029863459999,
            25.2936031538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GQLN",
          "name": "GURLA N",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.13917000000001,
            30.099805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MASK",
          "name": "MAISAR KHANA",
          "zone": "NR",
          "address": "State Highway 12A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.99951399999999,
            22.409215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LKBL",
          "name": "LAKHABAWAL",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8848308038,
            25.2903301845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GQLW",
          "name": "GURLA W",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.09973,
            8.566912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KZY",
          "name": "Kayalpattinam",
          "zone": "SR",
          "address": "Arumuganeri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.88526800000001,
            25.270233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GQL",
          "name": "GURLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.98861337459999,
            21.5758091157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNJL",
          "name": "SANJALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-CLDO",
          "name": "XX-CLDO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDYX",
          "name": "DDYX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.529002,
            26.48788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KURO",
          "name": "Korari",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-SUNB",
          "name": "XX-SUNB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.95459799999999,
            21.464052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSB",
          "name": "KOSAMBA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.879265,
            25.239081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCCN",
          "name": "KCCN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNVL",
          "name": "MNVL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.504945,
            27.299546000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KMNP",
          "name": "Kishan Manpura",
          "zone": "NWR",
          "address": "State Highway 19, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.896472,
            23.723864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STN",
          "name": "SITARAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.70137,
            23.769198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNW",
          "name": "THAPAR NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.096319,
            26.354898000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MAI",
          "name": "MEHSI",
          "zone": "ECR",
          "address": "Mehsi, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.90050000000001,
            16.3459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "LMT",
          "name": "ALMATTI",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73904300000001,
            28.833444999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KXK",
          "name": "Kharkhauda",
          "zone": "NR",
          "address": "State Highway 18, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.68124399999999,
            16.534517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "LKDU",
          "name": "Lankalakoderu",
          "zone": "SCR",
          "address": "Lankala Koderu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.14510899999999,
            23.137032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "ABKP",
          "name": "AMBIKAPUR",
          "zone": "SECR",
          "address": "Surguja, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.789599,
            22.566039999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LM",
          "name": "LIMBDI",
          "zone": "WR",
          "address": "Limbdi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.630185,
            25.642195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LGO",
          "name": "LalgopalGanj",
          "zone": "NR",
          "address": "Lal Gopalganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.74709499999999,
            25.785987000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CI",
          "name": "CHHAPRA KACHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.827254,
            25.754174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJH",
          "name": "GOLDINGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGWD",
          "name": "KAGWAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.963719,
            24.302357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LAP",
          "name": "Lohapur",
          "zone": "ER",
          "address": "Sagardighi Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.333681,
            15.809517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAQ",
          "name": "JANDRAPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.353602,
            15.830755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLX",
          "name": "CHIRALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.912201,
            16.615947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRVL",
          "name": "VIRAVALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.852845957,
            30.6007316295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KUP",
          "name": "KUP",
          "zone": "NR",
          "address": "Kup Khurd, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRWL",
          "name": "CHAK RAKHWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.973175,
            26.172392000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LLJ",
          "name": "LalGanj",
          "zone": "NR",
          "address": "Lalganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14905300000001,
            26.051333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKB",
          "name": "CHAUTH KA BARWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.12201900000001,
            24.567234000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CRKR",
          "name": "CHAURAKHERI",
          "zone": "WCR",
          "address": "Chaurakher, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.01539600000001,
            25.196344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DXD",
          "name": "DIGOD",
          "zone": "WCR",
          "address": "Kota, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.18466500000001,
            23.615327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KJME",
          "name": "KAJORAGRAM",
          "zone": "ER",
          "address": "Near Andal, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.270911,
            18.989244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MAE",
          "name": "Matheran",
          "zone": "CR",
          "address": "Matheran, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.827673,
            27.809478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MADA",
          "name": "Maonda",
          "zone": "NWR",
          "address": "State Highway 13, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.07458100000001,
            11.59699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRPD",
          "name": "VIRAPANDY ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.502629,
            26.200502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBN",
          "name": "KARBIGWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.544495,
            26.16423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AUNG",
          "name": "AUNG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.190477,
            18.040769
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LNN",
          "name": "LONAND",
          "zone": "CR",
          "address": "Jn Point Daund /Miraj /Pune, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19639500000001,
            26.113488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLV",
          "name": "SANDALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.18700399999999,
            26.145594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STLI",
          "name": "SITHOULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.18229199999999,
            26.216483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWL",
          "name": "GWALIOR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.12986400000001,
            26.307454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RRU",
          "name": "RAYARU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0989272,
            26.355565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAO",
          "name": "BANMOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.000153,
            25.130668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KXA",
          "name": "Kuanriya",
          "zone": "NWR",
          "address": "State Highway 12, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.078092,
            26.406043999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NUB",
          "name": "NURABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.059751,
            26.430519999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SANK",
          "name": "SANK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.003442,
            26.500504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRA",
          "name": "MORENA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.942969,
            26.611148
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HET",
          "name": "HETAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.950981,
            26.666953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHER",
          "name": "GHER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.90604900000001,
            26.697592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHO",
          "name": "DHAULPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.03964,
            8.585754999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KZB",
          "name": "Kurumbur",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.17773399999999,
            14.020565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NCU",
          "name": "Nallacheruvu",
          "zone": "SCR",
          "address": "Chennai - Anantpur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.91964999999999,
            26.805806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIA",
          "name": "MANIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.936725,
            26.934561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JJ",
          "name": "JAJAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.987169,
            27.202619000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BFP",
          "name": "BILOCHPURA AGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.590102,
            25.110195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LWS",
          "name": "LUKWASA",
          "zone": "WCR",
          "address": "Lukwasa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.89793499999999,
            27.22285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNKA",
          "name": "RUNKUTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.276461,
            28.547701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKEC",
          "name": "TUGLAKABAD EAST CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.916972,
            28.592851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MALA",
          "name": "MALA",
          "zone": "NER",
          "address": "Pilibhit, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.421058,
            26.450839000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MBO",
          "name": "Mairabari",
          "zone": "NFR",
          "address": "Nagaon, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.70044,
            24.432277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MAA",
          "name": "MAVAL",
          "zone": "NWR",
          "address": "National Highway 14, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8424186963,
            24.5405071601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KWI",
          "name": "KIVARLI",
          "zone": "NWR",
          "address": "Shri Amirgadh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.00889543950001,
            24.421213361299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLBN",
          "name": "SALAI BANWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIWR",
          "name": "DIWR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CUKI",
          "name": "CUKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4204539438,
            23.8136370394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKJ",
          "name": "NEW KATNI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.157354,
            9.280516
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MMM",
          "name": "MANDAPAM",
          "zone": "SR",
          "address": "National Highway 49, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.74320900000001,
            28.461762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MJHL",
          "name": "MAJADA HALT",
          "zone": "NR",
          "address": "Majada Halt, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1605882117,
            17.3251381906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MLMG",
          "name": "MALLEMADUGU",
          "zone": "SCR",
          "address": "Khammam, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8925106329,
            23.8365760851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTGN",
          "name": "RATANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1904672913,
            23.914016508499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTHD",
          "name": "PATOHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1232376385,
            19.8680489165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MLSU",
          "name": "MALSAILU",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-BDXO",
          "name": "XX-BDXO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.19568617739999,
            30.1393834225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "ABS",
          "name": "ABOHAR",
          "zone": "NR",
          "address": "Abohar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.963309,
            26.259236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MONJ",
          "name": "MONGLAJHORA",
          "zone": "NFR",
          "address": "Dhubri Kochugaon Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.246984,
            25.605166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MHO",
          "name": "MAHPUR",
          "zone": "NER",
          "address": "Mahpur Bhimapar Marg, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.059225,
            23.381806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GSPR",
          "name": "GOSALPUR",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.920425,
            23.159475
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MML",
          "name": "MADAN MAHAL",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.610219,
            15.672447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MLP",
          "name": "MALLAPUR",
          "zone": "SWR",
          "address": "State Highway 30, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5868746,
            26.0707762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ABW",
          "name": "Abutara Halt",
          "zone": "NFR",
          "address": "Dinhata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.074337,
            20.522648999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGL",
          "name": "VAGHLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.994996,
            14.349934000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MGB",
          "name": "Mudigubba",
          "zone": "SCR",
          "address": "Mudigubba, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5180445245,
            25.625350216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MKSR",
          "name": "Mokalsar",
          "zone": "NWR",
          "address": "Mokalsar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.08138100000001,
            14.284214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MYK",
          "name": "MAYAKONDA",
          "zone": "SWR",
          "address": "State Highway 47, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.201256,
            28.664731999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DKZ",
          "name": "DELHI KISHANGANJ",
          "zone": "NR",
          "address": "Sarai Rohilla, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.454814,
            22.946834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MORA",
          "name": "MORAIYA",
          "zone": "WR",
          "address": "National Highway 8A, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.681044,
            28.83154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KRZ",
          "name": "KHARAWAR",
          "zone": "NR",
          "address": "Kharawar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.367553,
            19.859544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MKDD",
          "name": "MUKUNDWADI HALT",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2166847791,
            19.6893606972
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ABX",
          "name": "AMBARI",
          "zone": "SCR",
          "address": "Kinwat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.15689900000001,
            23.589945
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQT",
          "name": "BAKTAR NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.11698,
            23.602851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNG",
          "name": "RANIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.090288,
            23.626716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMC",
          "name": "NIMCHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.01593,
            23.6652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPK",
          "name": "KALIPAHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.991176,
            23.686432
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASNE",
          "name": "ASANSOL EAST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.975152,
            23.691441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASN",
          "name": "ASANSOL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.92813299999999,
            23.706726
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCQ",
          "name": "BARACHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.120963,
            26.296423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MIAL",
          "name": "Milaoli",
          "zone": "NCR",
          "address": "National Highway 3, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.734105,
            15.94768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ZARP",
          "name": "ZARAP",
          "zone": "KR",
          "address": "Sindhudurg, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.146605,
            26.213719
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MGI",
          "name": "Manigachi",
          "zone": "ECR",
          "address": "Darbhanga, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.318628,
            26.882301000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MHN",
          "name": "Mahanadi",
          "zone": "NFR",
          "address": "Pankhabari Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.666955,
            18.566875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "MKRD",
          "name": "Machhakunda",
          "zone": "ECoR",
          "address": "Padwa Rd, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6197250085,
            25.343215469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MHI",
          "name": "Manihari",
          "zone": "NFR",
          "address": "Manihari Railway Station, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "HLG",
          "name": "Hillgrove",
          "zone": "SR",
          "address": "Nilgiris, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.890556,
            16.598822000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TOU",
          "name": "TELAPROLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.869091,
            23.626630000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MDKD",
          "name": "MADHUKUNDA",
          "zone": "SER",
          "address": "Barakar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4835424152,
            26.2451000771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMPR",
          "name": "PAMPORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.638839,
            16.819946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MRJ",
          "name": "MIRAJ JN",
          "zone": "CR",
          "address": "Maniknagar Bypass, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.96680748,
            27.06906445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHA",
          "name": "BHANDAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.13222499999999,
            32.769105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "MNWL",
          "name": "MANWAL",
          "zone": "NR",
          "address": "Jandrah, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.990153,
            27.157992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGC",
          "name": "AGRA CANTT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.996613,
            27.193811
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKM",
          "name": "RAJA KI MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.817054,
            27.253709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXM",
          "name": "KITHAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75808599999999,
            27.318183
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FHT",
          "name": "FARAH TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.1607440393,
            16.518809308199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MDVL",
          "name": "MANDAVALLI",
          "zone": "SCR",
          "address": "Mandavalli, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.743659,
            27.334474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FAR",
          "name": "FARAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.46063699999999,
            23.839677000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CPLE",
          "name": "CHINPAI",
          "zone": "ER",
          "address": "National Highway 60, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.68965184,
            27.3959406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAD",
          "name": "BAAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.622835,
            27.555461
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRBD",
          "name": "VRINDABAN ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.55842299999999,
            27.63615
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJH",
          "name": "AJHAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.502978,
            27.711992000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHJ",
          "name": "CHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.445999,
            27.788551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSV",
          "name": "KOSI KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.362553,
            27.953084999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNCR",
          "name": "BANCHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.554869,
            28.011996999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MJNL",
          "name": "MAJRI NANGAL",
          "zone": "NWR",
          "address": "State Highway 52, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1382563446,
            11.858919162400001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSPT",
          "name": "DANISHPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.360583,
            27.973644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHLK",
          "name": "SHOLAKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.34223200000001,
            28.151841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWL",
          "name": "PALWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.323569,
            28.250878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AST",
          "name": "ASAOTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.317824,
            28.288129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PYLA",
          "name": "PIYALA BLOCK HUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.312775968,
            28.3408609239
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVH",
          "name": "BALLABGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.09880299999999,
            11.812422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNT",
          "name": "TINNAPPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.090379,
            11.724176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPPR",
          "name": "KARUPPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30936799999999,
            28.376247999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FDN",
          "name": "FARIDABAD NEW TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.303032,
            28.458853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKDC",
          "name": "TUGLAKABAD CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.294046,
            28.511962999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKD",
          "name": "TUGLAKABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.38446715209999,
            28.0273441203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MIN",
          "name": "Mihinpurwa",
          "zone": "NER",
          "address": "Mihinpurwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65827900000001,
            20.053466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MME",
          "name": "Mul Marora",
          "zone": "SECR",
          "address": "Mul, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.594728,
            32.359168000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MDPB",
          "name": "MADHOPUR PUNJAB",
          "zone": "NR",
          "address": "Madhopur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64393899999999,
            32.151615
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GILA",
          "name": "GHIALA",
          "zone": "NR",
          "address": "MDR 42, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.61183799999999,
            31.941873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MEX",
          "name": "MUKERIAN",
          "zone": "NR",
          "address": "Mukerian, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.862645,
            28.654232
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MLC",
          "name": "MALKISAR",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.07132899999999,
            24.856164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KVJ",
          "name": "KESHAVGANJ",
          "zone": "NWR",
          "address": "Jawai Bandh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0722485132,
            25.409592285400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NEMA",
          "name": "Nema Halt",
          "zone": "?",
          "address": "National Highway 22, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.62868,
            11.095237000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MV",
          "name": "MAYILADUTURAI JN",
          "zone": "SR",
          "address": "Mayiladuthurai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.00764,
            24.625338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NILE",
          "name": "NIMTITA",
          "zone": "ER",
          "address": "NH-34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.703754,
            22.80462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BLRD",
          "name": "BALA ROAD",
          "zone": "WR",
          "address": "State Highway 19, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.59157300000001,
            18.681887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MRTY",
          "name": "MURTI",
          "zone": "SCR",
          "address": "State Highway 169, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.731401,
            10.792057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MCJ",
          "name": "MANJATTIDAL",
          "zone": "SR",
          "address": "Mela Kalkandar Kottai Tiruchchirappalli 620011, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.052968,
            23.129098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VG",
          "name": "VIRAMGAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.859800722,
            20.3628714458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RHNE",
          "name": "ROHINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.09033854249999,
            23.1031879212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XSK",
          "name": "SOKALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1353813153,
            23.0872076126
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKA",
          "name": "JAKHVADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1961836121,
            23.0784842293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VCN",
          "name": "VIROCHANNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.499954,
            19.701406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGR2",
          "name": "BLOCK AND CATCH SIDING CABINE NO.2",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.769465,
            23.118661000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MZH",
          "name": "MAUHARI",
          "zone": "SECR",
          "address": "Amarkantak Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.746926,
            16.620372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MVD",
          "name": "MULVAD",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2731255127,
            13.245141950999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NDV",
          "name": "NIDVANDA",
          "zone": "SWR",
          "address": "NH 207, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.2509186072,
            23.049123517599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CE",
          "name": "CHHARODI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9345261145,
            24.9133804384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MRHA",
          "name": "Murahara",
          "zone": "?",
          "address": "Murahara, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3131250098,
            23.026557611799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WSE",
          "name": "VASAN IYAWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.63786800000001,
            21.617209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "NANR",
          "name": "Naranpur",
          "zone": "ECoR",
          "address": "Naranpur Railway Station, Orissa"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPBK",
          "name": "SPBK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3847494653,
            23.024215821600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAU",
          "name": "SANAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.454405,
            23.038847999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGM",
          "name": "GORA GHUMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.494044,
            23.05437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABD",
          "name": "AMBLI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.543606,
            23.074516000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLDY",
          "name": "CHANDLODIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7290964111,
            27.003881709799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NDH",
          "name": "Nindhar Benar",
          "zone": "NWR",
          "address": "Benad Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8805,
            25.223553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOTA",
          "name": "KOTA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.57010600000001,
            23.085912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBTF",
          "name": "SABARMATI F CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.148507,
            25.418978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MTH",
          "name": "MATAUNDH",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.41249099999999,
            23.45592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAD",
          "name": "NAGDA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.55448093439999,
            22.7050879399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRSA",
          "name": "KHARSALIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.462158,
            22.630205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRL",
          "name": "DEROL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71248600000001,
            13.384141999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NDY",
          "name": "Nandi Halt",
          "zone": "SWR",
          "address": "Nandi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.181065,
            22.310756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRC",
          "name": "VADODARA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.4192042773,
            22.5881026822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKRL",
          "name": "BAKROL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.358665,
            12.082270000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNGR",
          "name": "THONGANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3127518,
            12.044658609999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDY",
          "name": "BUDDIREDDIPPATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2462805359,
            11.984698435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQI",
          "name": "BOMMIDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.174049,
            11.927787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LCR",
          "name": "LOKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.57413700000001,
            26.430697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MZQ",
          "name": "MAJGAON ASSAM",
          "zone": "NFR",
          "address": "National HIghway 31, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.393378,
            22.541916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPN",
          "name": "CHAMPANER RD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.363557,
            22.523268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAN",
          "name": "LOTANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.302104,
            22.483966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMLA",
          "name": "SAMLAYA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.239854,
            22.440575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AIR",
          "name": "ALINDRA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.186675,
            22.39641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PIO",
          "name": "PILOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.70963400000001,
            24.19691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MQX",
          "name": "MERALGRAM",
          "zone": "ECR",
          "address": "Garhwa, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.179698,
            22.388284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CYI",
          "name": "CHHAYAPURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.831855,
            31.204703000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MYE",
          "name": "Mandhali",
          "zone": "NR",
          "address": "Mandhali, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.603905,
            21.251382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRWN",
          "name": "SARASWATINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.905844,
            24.388752999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MWF",
          "name": "MAGARDAHA",
          "zone": "ECR",
          "address": "Obra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.919501,
            23.951414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MFQ",
          "name": "MAHROI",
          "zone": "WCR",
          "address": "State Highway 10, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.574139,
            23.7639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LEK",
          "name": "LAKHAKHERA",
          "zone": "WCR",
          "address": "Lakhakhera, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.730551,
            16.507237
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NDM",
          "name": "NIDAMANURU",
          "zone": "SCR",
          "address": "Vijayawada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1681972229,
            26.257038720999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MTR",
          "name": "MOTIPUR",
          "zone": "ECR",
          "address": "National Highway 28, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.77319899999999,
            25.017163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MRLI",
          "name": "Murli Halt",
          "zone": "ER",
          "address": "Pakur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.711553,
            22.165407000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "MWRN",
          "name": "Madwarani",
          "zone": "SECR",
          "address": "Janjgir-Champa, Chhattisgarh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDAC",
          "name": "DDAC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-ANK",
          "name": "XX-ANK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.43035699999999,
            26.35704
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RXM",
          "name": "ROOMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.20908244200001,
            18.1049649229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NIRA",
          "name": "NIRA",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HALT",
          "name": "HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.84886999999999,
            23.737816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULT",
          "name": "KULTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.56294000000001,
            17.768291
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MTV",
          "name": "Matalkunta",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.818859,
            23.7416829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRR",
          "name": "BARAKAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.794065,
            23.747453
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMME",
          "name": "KUMARDUBI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.747614,
            23.751414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMU",
          "name": "MUGMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.661294,
            23.758149
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAO",
          "name": "KALUBATHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.515977,
            23.772589999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKA",
          "name": "PRADHANKHUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.530303,
            27.422483999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MSTH",
          "name": "Misrikh Tirath",
          "zone": "NR",
          "address": "Sitapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.000446,
            25.888506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MRIJ",
          "name": "Murliganj",
          "zone": "ECR",
          "address": "Murliganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.480808,
            23.782884
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOKM",
          "name": "DOKRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.428956,
            23.790966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHN",
          "name": "DHANBAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.69625099999999,
            25.817255999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MGWN",
          "name": "MAHGAWAN HALT",
          "zone": "NR",
          "address": "Nawab Yousuf Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.79552,
            25.672848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DDD",
          "name": "DHONDHA DIH",
          "zone": "NER",
          "address": "MDR 94E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.40881499999999,
            17.712059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KHED",
          "name": "KHED",
          "zone": "KR",
          "address": "Khed, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.147683,
            14.876286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AT",
          "name": "ASNOTI",
          "zone": "KR",
          "address": "Uttara Kannada, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.137763,
            16.171258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MTM",
          "name": "Machilipatnam",
          "zone": "SCR",
          "address": "Machilipatnam, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.936146626,
            23.1606089618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JN",
          "name": "JHUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.850678,
            23.134597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SADL",
          "name": "SADLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.672912326,
            21.0236632507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDI",
          "name": "BHADLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.63321499999999,
            21.025557000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRW",
          "name": "TARSOD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4079921729,
            18.3772296262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "OSA",
          "name": "AUSA ROAD",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.140405,
            20.573753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJ",
          "name": "KAJGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.57945000000001,
            26.280074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NMD",
          "name": "NOMODA",
          "zone": "WCR",
          "address": "MDR 111, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.722863,
            19.332338999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HDGR",
          "name": "HADGAON ROAD",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.862688,
            23.439324000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PRJ",
          "name": "PRANTIJ",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.999049,
            20.464109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CSN",
          "name": "CHALISGAON JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.159395,
            22.363671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGZ",
          "name": "VADODARA E CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.217642,
            26.240375999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NRV",
          "name": "NARIYAR",
          "zone": "ECR",
          "address": "Nariyaar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.88966099999999,
            28.734528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NRY",
          "name": "Nyoriya Husenpur",
          "zone": "NER",
          "address": "Neoria Husainpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.377466,
            26.164809
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PPF",
          "name": "PIPLAJ",
          "zone": "NWR",
          "address": "Beawar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.249302,
            26.110972999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "APA",
          "name": "AMARPURA",
          "zone": "NWR",
          "address": "Kharwa, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.51416499999999,
            24.285735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "NUQ",
          "name": "NAGAR UNTARI",
          "zone": "ECR",
          "address": "Garhwa, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.86573000000001,
            21.255892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSE",
          "name": "KOSAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.56808299999999,
            12.977595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SBC",
          "name": "BANGALORE CITY JN",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.794586,
            21.550734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLD",
          "name": "TILDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.69565899999999,
            16.069516
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GED",
          "name": "GULEDAGUDDA RD",
          "zone": "SWR",
          "address": "SH 44, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.859907,
            17.118384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NBL",
          "name": "NIMBAL",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.436386,
            22.088704
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NVG",
          "name": "Nawagaon",
          "zone": "CR",
          "address": "Nayagaon, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.254779,
            11.278805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "NIL",
          "name": "Nilambur Road",
          "zone": "SR",
          "address": "Nilambur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.513684,
            22.15311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "NOMD",
          "name": "Noamundi",
          "zone": "SER",
          "address": "National Highway 215, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.54541499999999,
            26.444642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ACH",
          "name": "Achalganj",
          "zone": "NR",
          "address": "Bithar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.77929449999999,
            23.1782623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "OSRA",
          "name": "Osra",
          "zone": "WR",
          "address": "Madhya Pradesh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.577652,
            16.541296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PAGM",
          "name": "Pennada Agraharam",
          "zone": "SCR",
          "address": "West Godavari, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.3888097504,
            25.7743785055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NLN",
          "name": "NAILALUNG",
          "zone": "NFR",
          "address": "TV Centre Road, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.57149100000001,
            12.626458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDY",
          "name": "KETTANDAPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-GTLB",
          "name": "XX-GTLB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.194463,
            15.650668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UGD",
          "name": "UPPUGUNDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-TNGC",
          "name": "XX-TNGC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-HPTB",
          "name": "XX-HPTB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.236773,
            15.689502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CJM",
          "name": "CHINNA GANJAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2572977045,
            15.722540512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVDU",
          "name": "KADAVAKUDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.9872737866,
            26.429808896500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "OTN",
          "name": "OATING",
          "zone": "NFR",
          "address": "National Highway 39, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.72847999999999,
            26.905961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NZR",
          "name": "NAZIRA",
          "zone": "NFR",
          "address": "Nazira Ali, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.777507,
            10.327487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NMN",
          "name": "Namanasamudram",
          "zone": "SR",
          "address": "Namunasamudiran, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.204092,
            24.385641000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "NPU",
          "name": "Nadiapur",
          "zone": "NFR",
          "address": "Nadiapur, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.442397,
            29.712202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NNX",
          "name": "NANAUTA",
          "zone": "NR",
          "address": "MDR 2W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.67311699999999,
            27.480145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTJ",
          "name": "MATHURA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.118302,
            21.156859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ITRN",
          "name": "Itwari Jn (Ng)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.66784,
            27.501369999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTSR",
          "name": "BHUTESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.38869,
            27.886147
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDL",
          "name": "HODAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.264825,
            28.559332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OKA",
          "name": "OKHLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.74816,
            27.819798
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "OEL",
          "name": "OEL",
          "zone": "NER",
          "address": "Oel, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.631949,
            27.993457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PD",
          "name": "PHARADAHAN",
          "zone": "NER",
          "address": "SH 21, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.541815,
            12.466956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOLK",
          "name": "MOLAKARAMPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.510114,
            12.412541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEY",
          "name": "KAGANKARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.49737599999999,
            12.360518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNNT",
          "name": "KUNNATHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.484032,
            12.311594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLY",
          "name": "SAMALPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.438356,
            12.251026000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DST",
          "name": "DASAMPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.393874,
            12.124073000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAP",
          "name": "MORAPPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.311271,
            28.112457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NSA",
          "name": "Nishangara",
          "zone": "NER",
          "address": "Uttar Pradesh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.259747,
            12.95409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "OGM",
          "name": "Oorgaum",
          "zone": "SWR",
          "address": "KGF, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.433342,
            21.839609
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "NYG",
          "name": "Nayagarh",
          "zone": "?",
          "address": "National Highway 215, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.78564800000001,
            24.488460999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MHUR",
          "name": "Mohanpur",
          "zone": "ER",
          "address": "Mohanpur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.791337,
            11.343714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ONR",
          "name": "Coonoor",
          "zone": "SR",
          "address": "Coonoor, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.836422,
            15.009799000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "OBM",
          "name": "Obalapuram",
          "zone": "SWR",
          "address": "Bellary-Hiriyur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.30895,
            19.041434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KPRR",
          "name": "KOTAPAR ROAD",
          "zone": "ECoR",
          "address": "Bhansuli Kotpad PMGSY Rd, Chhattisgarh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "INDRG",
          "name": "INDRG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNSYM",
          "name": "GNSYM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.7022163385,
            23.076354329900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTX",
          "name": "JATPIPLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.77207677000001,
            23.10173524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAJN",
          "name": "BAJANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.5952838086,
            23.0351625148
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VSV",
          "name": "VASADVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.4791076,
            22.9871651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHG",
          "name": "DHRANGDHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.243815,
            23.014848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUK",
          "name": "SUKHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.1829095,
            23.0185158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HVD",
          "name": "HALVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.084085,
            23.009171000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHNL",
          "name": "DHANALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.01248185,
            23.02777493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVY",
          "name": "DEVALIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.90346000000001,
            23.056445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHXB",
          "name": "KHAKHRECHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.828521,
            23.07645
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WDHR",
          "name": "WADHARVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.75488469119999,
            23.103166868099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MALB",
          "name": "MALIYA MIYANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.604533,
            23.289037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KATR",
          "name": "KATARIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.399876,
            23.303951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VONB",
          "name": "VONDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.34497200000001,
            23.301391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCOB",
          "name": "BHACHAU BG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.242149,
            23.208800999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHIB",
          "name": "CHIRAI BG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.174975,
            23.186504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMSB",
          "name": "BHIMASAR BG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.150716,
            23.095023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GIMC",
          "name": "GANDHIDHAM CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.007483,
            24.203692999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PIP",
          "name": "PIPLIA",
          "zone": "WR",
          "address": "Panth Pipalia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.635315,
            18.634293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PNF",
          "name": "PANGAON",
          "zone": "SCR",
          "address": "Pangaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.525851,
            25.902065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PNRA",
          "name": "PANIARA",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.524424,
            18.853005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PRLI",
          "name": "PARLI VAIJNATH",
          "zone": "SCR",
          "address": "Parli-Vaijnath, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.03849100000001,
            32.104872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "HRDR",
          "name": "HARSAR DEHRI",
          "zone": "NR",
          "address": "Harsar Dehri, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.903803,
            23.260621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RKH",
          "name": "RAKHIYAL",
          "zone": "WR",
          "address": "Rakhiyal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.582392,
            28.426644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PLK",
          "name": "PALIA KALAN",
          "zone": "NER",
          "address": "Palia Kalan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.743134,
            10.754287999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KVL",
          "name": "KIZHELUR",
          "zone": "SR",
          "address": "THIRUKKANNANGUDI Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.484589,
            13.013974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHU",
          "name": "SHOLINGHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.98573499999999,
            26.489784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PPA",
          "name": "PIPRA",
          "zone": "ECR",
          "address": "National Highway 28, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.58928200000001,
            23.077545999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBT",
          "name": "SABARMATI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.59293100000001,
            23.050805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBTA",
          "name": "SABARMATI A CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.965733,
            22.561307
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANND",
          "name": "ANAND JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.00645,
            22.515278000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VXD",
          "name": "VADOD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.05999100000001,
            22.452351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDA",
          "name": "VASAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.178656,
            22.283932999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VS",
          "name": "VISHVAMITRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.175422,
            22.232706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPR",
          "name": "MAKARPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.821624,
            21.046545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSCN",
          "name": "BHUSAWAL C CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.647361,
            15.374513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PDL",
          "name": "PENDEKALLU",
          "zone": "SCR",
          "address": "Kurnool, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.237184,
            10.473341999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OLR",
          "name": "THRISSUR OLLUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7520832336,
            12.5019534049
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BDRL",
          "name": "BYADARAHALLI",
          "zone": "SWR",
          "address": "State Highway 17, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.82957502260001,
            12.5106209267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "Y",
          "name": "YELIYUR",
          "zone": "SWR",
          "address": "Bangalore-Mysore Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.934247,
            20.976217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACG",
          "name": "ACHEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.360645,
            26.428098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNBI",
          "name": "CHANDARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.16912099999999,
            15.619244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPRL",
          "name": "RAPARLA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.281949,
            15.761413999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPLL",
          "name": "KOTTA PANDILA PALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.30065,
            15.783376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VTM",
          "name": "VETAPALEMU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.385512,
            15.852303000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPPN",
          "name": "IPURUPALEM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.916104,
            9.023336
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PUU",
          "name": "Punalur",
          "zone": "SR",
          "address": "Punalur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.851752,
            26.001794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HJI",
          "name": "HOJAI",
          "zone": "NFR",
          "address": "Hojai Rd. Ph:- 03674-252136., Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5544692444,
            25.6871480115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RHI",
          "name": "Rakhi",
          "zone": "NWR",
          "address": "MDR 13, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.87452,
            27.451000999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MHBG",
          "name": "Mahadev Buzurg",
          "zone": "NER",
          "address": "Mahadeva Buzrug, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.59822100000001,
            25.969294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PGC",
          "name": "Panchgachia",
          "zone": "ECR",
          "address": "Station Rd, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.13113915769999,
            17.0281747457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDPT",
          "name": "GUDAPARTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.168724,
            17.044827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLO",
          "name": "SAMALKOT JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.257512,
            17.11087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAP",
          "name": "PITHAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.76459,
            17.718238
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PGP",
          "name": "Panduranga Pura",
          "zone": "SCR",
          "address": "Manuguru, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.290817561,
            17.1516644909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLP",
          "name": "GOLLAPROLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3152851379,
            17.1810250515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVRM",
          "name": "MALLAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3489940906,
            17.2124765738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGDG",
          "name": "DURGADA GATE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.368151,
            17.230245
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RVD",
          "name": "RAVIKAMPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.41967700000001,
            17.269633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANV",
          "name": "ANNAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.210289,
            22.281143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PRTN",
          "name": "Pratapnagar",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.4504126416,
            17.288936392500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMPM",
          "name": "TIMMAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.15507600000001,
            21.764461999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGT",
          "name": "RAJGHAT HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.61736699999999,
            15.041948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RRJ",
          "name": "RAMARAJU PALLI",
          "zone": "SCR",
          "address": "Rayalaseema Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.431242,
            9.316791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KVTA",
          "name": "KARUVATTA HALT",
          "zone": "SR",
          "address": "Ashramam- Pulipra, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMYD",
          "name": "SMYD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.15264699999999,
            21.874341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PLM",
          "name": "PANELI MOTI",
          "zone": "WR",
          "address": "Paneli Moti, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6236025668,
            28.0665867073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RTGH",
          "name": "Ratangarh Junction",
          "zone": "NWR",
          "address": "Ratangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.587162,
            23.072985
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBI",
          "name": "SABARMATI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.272772,
            30.811762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PMH",
          "name": "Parao Mahna",
          "zone": "NR",
          "address": "Mehna, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.057147,
            12.844886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GI",
          "name": "GUDUVANCHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.07175099999999,
            12.867247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UPM",
          "name": "URAPPAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.08439299999999,
            12.89128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDR",
          "name": "VANDALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.095486,
            12.905451
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRGL",
          "name": "PERUNGULATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.38008,
            27.484876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PLSN",
          "name": "Palsana",
          "zone": "NWR",
          "address": "Palsana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.356234,
            27.149463
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RNW",
          "name": "Renwal",
          "zone": "NWR",
          "address": "Kishangarh Renwal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.488653,
            24.267557999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CTT",
          "name": "CHITRASANI",
          "zone": "NWR",
          "address": "Chitrasani, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5337815405,
            24.3460328561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "IQG",
          "name": "IQBAL GADH",
          "zone": "NWR",
          "address": "Banaskantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.592045,
            24.377144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SZA",
          "name": "SAROTRA ROAD",
          "zone": "NWR",
          "address": "Sarotra, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.110095,
            24.925895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NANA",
          "name": "NANA",
          "zone": "NWR",
          "address": "Nana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27122100000001,
            13.102361
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBQ",
          "name": "BASIN BRIDGE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.13704246809999,
            13.1155489623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMVL",
          "name": "TIRUMULLAIVAYIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.862172,
            13.099513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBT",
          "name": "KADAMBATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.763145,
            13.090096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TO",
          "name": "TIRUVALANGADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.57336000000001,
            10.879069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "EL",
          "name": "ELAMANUR",
          "zone": "SR",
          "address": "NH 67, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.17639000000001,
            15.332581
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUBL",
          "name": "HUBLI SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.488904,
            28.717268999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RR",
          "name": "RICHHA ROAD",
          "zone": "NER",
          "address": "State Highway 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7971486487,
            26.873842826500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RGH",
          "name": "RAMGARHWA",
          "zone": "ECR",
          "address": "NH 28A, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.841822,
            24.07001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SES",
          "name": "SEMARI",
          "zone": "NWR",
          "address": "MDR 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7303939545,
            11.5405299099
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PUC",
          "name": "PUDUCHATIRAM",
          "zone": "SR",
          "address": "NH 45A, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.568184,
            23.173344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RHA",
          "name": "Ranaghat Junction",
          "zone": "ER",
          "address": "Ranaghat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.19204,
            10.884228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PVL",
          "name": "PASUPATIKOVIL",
          "zone": "SR",
          "address": "Ayyampet, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.72805600000001,
            24.582417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RPZ",
          "name": "Ranapratapnagar",
          "zone": "NWR",
          "address": "Udaipur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.409255,
            9.725053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "RAGM",
          "name": "RAJAGAMBIRAM",
          "zone": "SR",
          "address": "Rajagambeeram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.800933,
            11.255404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CVD",
          "name": "CHAVADIPALAIYAM",
          "zone": "SR",
          "address": "SH 84, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.26840578,
            26.9979518998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RCRA",
          "name": "Ramchaura",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8769002625,
            24.903476070500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RJAK",
          "name": "RAJNAGAR",
          "zone": "NCR",
          "address": "Rajnagar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.24940600000001,
            25.420471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DPL",
          "name": "DANAULI PHLWRIA",
          "zone": "ECR",
          "address": "Danauli Phlwria, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.279108,
            27.011243999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SFE",
          "name": "Sanodiya",
          "zone": "NWR",
          "address": "Hingonia, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.997689,
            21.706907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BH",
          "name": "BHARUCH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.017046,
            20.9035827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDWD",
          "name": "BODWAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.058938,
            20.900243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLHD",
          "name": "KOLHADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.46377,
            11.778949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "DMD",
          "name": "DHARMADAM",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.693924,
            11.446139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "QLD",
          "name": "QUILANDI",
          "zone": "SR",
          "address": "Quilandi, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.0911894116,
            26.7380786343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SK",
          "name": "SAKHUN",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.45828601,
            14.04849031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HNHL",
          "name": "Harnahalli",
          "zone": "SWR",
          "address": "Harnahalli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.667991,
            13.081512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJJ",
          "name": "ARAKKONAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.56735042999999,
            13.05050406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AVN",
          "name": "ANAVARDIKHANPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.67971387700001,
            16.5262013806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RMV",
          "name": "RAMAVARAPPADU",
          "zone": "SCR",
          "address": "Vijayawada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.313203,
            26.454423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOY",
          "name": "GOVINDPURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.57781999999999,
            12.560851999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTJ",
          "name": "JOLARPETTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.31806900000001,
            26.453654999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPNL",
          "name": "KANPUR C PANEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.770888,
            24.113489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DEOR",
          "name": "DIYODAR",
          "zone": "WR",
          "address": "Banaskantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.097619,
            23.652483999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PFL",
          "name": "PIPRALA",
          "zone": "WR",
          "address": "National Highway 15, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.33955999999999,
            26.452292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMC",
          "name": "KANPUR GOODS MARSHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.17217,
            17.612152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RMP",
          "name": "RAHIMATPUR",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.16420749999999,
            22.440956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "RRP",
          "name": "Rairangpur",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.783901,
            16.984159000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJY",
          "name": "RAJAMUNDRY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8145455212,
            28.4764188903
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PTPR",
          "name": "Pratabpur",
          "zone": "NER",
          "address": "Pilibhit, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.450472,
            29.535956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RSNR",
          "name": "Rai Singh Nagar",
          "zone": "NWR",
          "address": "Raisinghnagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.44262,
            26.579427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RNB",
          "name": "Ranpura",
          "zone": "NCR",
          "address": "Rajpura, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.324776,
            21.058881000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "RSA",
          "name": "Risama",
          "zone": "SECR",
          "address": "Durg Rajhara Rd, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.026949,
            13.123744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TI",
          "name": "TIRUNINRAVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.87191,
            14.440531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RLX",
          "name": "Ramanujampalli",
          "zone": "SCR",
          "address": "Ramanujampalli Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.998727,
            13.123937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VEU",
          "name": "VEPPAMPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6862378271,
            13.0834968254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLMG",
          "name": "PULIYAMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.879858,
            16.930994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSVM",
          "name": "KESAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.922661,
            16.927556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWP",
          "name": "DWARAPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.62126500000001,
            27.559273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AWR",
          "name": "ALWAR",
          "zone": "NWR",
          "address": "Alwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.953181,
            16.935817
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APT",
          "name": "ANAPARTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.031148067,
            16.9693168335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVL",
          "name": "BIKKAVOLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.078986,
            16.990821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBD",
          "name": "PEDABRAMADVAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.096133,
            17.005091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPU",
          "name": "MEDAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2231096558,
            17.085409527899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRPM",
          "name": "CHANDRAMPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.484867,
            17.309946999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HVM",
          "name": "HAMSAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.542462,
            17.361079999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUNI",
          "name": "TUNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.63345,
            17.409805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLU",
          "name": "GULLIPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.724605,
            17.452852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRP",
          "name": "NARSIPATNAM RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.790267,
            17.505201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REG",
          "name": "REGUPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.930592,
            17.660147
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVM",
          "name": "BAYYAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.963751,
            17.673315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSK",
          "name": "KASIMKOTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.00885099999999,
            17.695503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKP",
          "name": "ANAKAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.06429200000001,
            17.686438000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THY",
          "name": "THADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.152059,
            17.703843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVD",
          "name": "DUVVADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.703505,
            20.171116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ROL",
          "name": "Rajuli",
          "zone": "SECR",
          "address": "Rajoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.335537,
            15.308524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MRB",
          "name": "MUNIRABAD",
          "zone": "SWR",
          "address": "Munirabad, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.326499,
            21.400715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RTK",
          "name": "Ramtek",
          "zone": "SECR",
          "address": "Ramtek, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.867426,
            20.969475
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "RIM",
          "name": "Rajim",
          "zone": "SECR",
          "address": "Nawapara Nagar, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.61346499999999,
            32.31198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SJNP",
          "name": "SUJANPUR",
          "zone": "NR",
          "address": "Sujanpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.466053,
            29.792507999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SGRA",
          "name": "SANGARIA",
          "zone": "NWR",
          "address": "Sangariya, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1191573807,
            12.926035689399999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBM",
          "name": "TAMBARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.131614,
            12.937978000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBMS",
          "name": "TAMBARAM SANATORIUM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.141157,
            12.951627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMP",
          "name": "CHROMEPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.152451,
            12.968273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PV",
          "name": "PALLAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.774617,
            25.280002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JSR",
          "name": "JASRA",
          "zone": "NCR",
          "address": "Jasra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5576150342,
            12.7312724613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SBHR",
          "name": "Subrahmanya Road",
          "zone": "SWR",
          "address": "Nettana Railway Station, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.786795,
            24.830337999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SCL",
          "name": "SILCHAR",
          "zone": "NFR",
          "address": "03842-263094, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.315615,
            22.555508
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SHM",
          "name": "KOLKATA SHALIMAR",
          "zone": "SER",
          "address": "Howrah/Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.34805200000001,
            19.494861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SCO",
          "name": "SATUNA",
          "zone": "SCR",
          "address": "Satona, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5687357202,
            24.278919666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SAPT",
          "name": "Sugapahari",
          "zone": "ER",
          "address": "Madhupur, Jharkhand"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-TNAB",
          "name": "XX-TNAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.680471,
            25.655084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SAF",
          "name": "SALKHAPUR",
          "zone": "NR",
          "address": "Kathiravan Paramalpatti Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.089004,
            21.153567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGP",
          "name": "NAGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.493224,
            21.30163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKS",
          "name": "SALEKASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.75567199999999,
            25.862203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SOD",
          "name": "SOJAT ROAD",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9154615387,
            26.372988907099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SMV",
          "name": "Sumaoli",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.642735,
            24.204257000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SGRL",
          "name": "SINGRAULI",
          "zone": "ECR",
          "address": "Singrauli, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.10472999999999,
            28.003861999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SKW",
          "name": "Sheikhupur",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.559848,
            22.178375
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SAK",
          "name": "Sak Bahadurpur",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.503862,
            27.015884999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SAHI",
          "name": "SATHI",
          "zone": "ECR",
          "address": "Paroraha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.28233900000001,
            28.903375
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SFA",
          "name": "SANHERA HALT",
          "zone": "NR",
          "address": "Sunehra/Khekra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.66142400000001,
            29.39793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SFDE",
          "name": "SAFIDON",
          "zone": "NR",
          "address": "Safidon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.837587,
            27.281001999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SD",
          "name": "SIDHAULI",
          "zone": "NER",
          "address": "Sidhauli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.51785100000001,
            13.022630999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDVE",
          "name": "MAHENDRA VADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.86031200000001,
            26.84708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMG",
          "name": "ALAMNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.4550875531,
            25.6614908641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SDG",
          "name": "SAHADAI BUZURG",
          "zone": "ECR",
          "address": "Buzurg, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.909396,
            21.244692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "SGDP",
          "name": "Sagadapata",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.236441,
            26.459172000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNK",
          "name": "PANKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.756816,
            26.917281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SLGR",
          "name": "SIMALUGURI JN",
          "zone": "NFR",
          "address": "way to SLGR, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.043015,
            28.559235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SG",
          "name": "SHAHGARH",
          "zone": "NER",
          "address": "Pilibhit, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.275679,
            18.247286000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MNI",
          "name": "MANGAON",
          "zone": "KR",
          "address": "Lonere - Mangaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.373769,
            24.860717
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KXJ",
          "name": "Karimganj Junction",
          "zone": "NFR",
          "address": "Karimganj Town, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.259657,
            14.740761999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HAA",
          "name": "HARWADA",
          "zone": "KR",
          "address": "Kochi - Panavel Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.332936,
            14.645347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ANKL",
          "name": "ANKOLA",
          "zone": "KR",
          "address": "Uttara Kannada, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.12019500000001,
            25.379137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SDC",
          "name": "SAIDABAD",
          "zone": "NER",
          "address": "Handia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.260193,
            13.077677999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MS",
          "name": "CHENNAI EGMORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.11315599999999,
            15.553014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRV",
          "name": "KARAVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.148353,
            15.596402
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANB",
          "name": "AMMANABROLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.604782,
            16.448173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAQ",
          "name": "KOLANUKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.604553,
            16.477152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCC",
          "name": "KRISHNA CANAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.982854,
            18.814443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "SPRM",
          "name": "Sitapuram Halt",
          "zone": "ECoR",
          "address": "Paralakhemundi, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.066187,
            18.196655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SLPM",
          "name": "Shivalingapuram",
          "zone": "ECoR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.61864800000001,
            16.518262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZA",
          "name": "VIJAYAWADA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.027508,
            12.514891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "RYC",
          "name": "Rayakkottai",
          "zone": "SWR",
          "address": "Hosur- Dharmapuri Highway, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.667911,
            16.537426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GALA",
          "name": "GUNADALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.390668,
            23.659482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SBW",
          "name": "Shewbabudih",
          "zone": "SER",
          "address": "Sbewbabudi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.73176799999999,
            16.546882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBD",
          "name": "MUSTABADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.795715,
            16.555839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWM",
          "name": "GANNAVARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.839432,
            16.562545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAVP",
          "name": "PEDDA AVUTAPALE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.105398,
            24.287789
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SDI",
          "name": "Sagardighi",
          "zone": "ER",
          "address": "Murshidabad District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.834992,
            8.639358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SDNR",
          "name": "Seydunganallur",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.897403,
            23.186898
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RUT",
          "name": "RATNAL",
          "zone": "WR",
          "address": "SH 46, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.290672,
            9.969541999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ERS",
          "name": "ERNAKULAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.15859180000001,
            22.747203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DK",
          "name": "Dakor",
          "zone": "WR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.666123,
            20.643535999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGLI",
          "name": "Mangli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.354092,
            23.636393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SVH",
          "name": "Sonadanga",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.120256,
            32.528358000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "SMBX",
          "name": "SAMBA",
          "zone": "NR",
          "address": "Ram Nagar, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.88447099999999,
            25.943966000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SEX",
          "name": "Seroni Road",
          "zone": "NCR",
          "address": "State Highway 23, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.450999,
            25.520225999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SOS",
          "name": "SOMESAR",
          "zone": "NWR",
          "address": "Somesar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.726061,
            18.714329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "SUKU",
          "name": "Suku",
          "zone": "ECoR",
          "address": "State Highway 48, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.98366,
            12.730200000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWU",
          "name": "PARANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0010589618,
            12.7624604323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKL",
          "name": "SINGAPERUMAL KOIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.019875,
            12.796619999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMNK",
          "name": "MARAIMALAM NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.026118,
            12.805715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTM",
          "name": "KATTANGULATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.03693,
            12.820867999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POTI",
          "name": "POTHERI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.166111,
            12.98064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLM",
          "name": "TRISULAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.174625,
            12.98432
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MN",
          "name": "MINAMBAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.186651,
            12.990115000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PZA",
          "name": "PALAVANTHANGAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.199392,
            12.994906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STM",
          "name": "ST THOMAS MOUNT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.211922,
            13.007672000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDY",
          "name": "GUINDY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.224183,
            13.02415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SP",
          "name": "SAIDAPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.228164,
            13.038154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBM",
          "name": "MAMBALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.231305,
            13.052565999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKK",
          "name": "KODAMBAKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.232742,
            13.065363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBK",
          "name": "NUNGAMBAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.24337,
            13.074663000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSC",
          "name": "CHENNAI CHETPAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.582849,
            24.913078000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SQF",
          "name": "SUKRITIPUR",
          "zone": "NFR",
          "address": "K Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.518251,
            11.869105000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SXR",
          "name": "SERNDANUR",
          "zone": "SR",
          "address": "National Highway 45C, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.460155,
            32.08098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "PRAR",
          "name": "PAROR",
          "zone": "NR",
          "address": "National Highway 20, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9987814502,
            14.3589476214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HNH",
          "name": "HANUMANAHALLI",
          "zone": "SWR",
          "address": "Hiretogaleri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.760983,
            13.270203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SPGR",
          "name": "SAMPIGE ROAD",
          "zone": "SWR",
          "address": "Tumkur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.10955799999999,
            26.334709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SPX",
          "name": "SAPATGRAM",
          "zone": "NFR",
          "address": "Dhubri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.32479556150001,
            30.0282558081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SSZ",
          "name": "SADDA SINGHWALA",
          "zone": "NR",
          "address": "State Highway 12A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.18645099999999,
            23.942472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CWLE",
          "name": "CHOWRIGACHA",
          "zone": "ER",
          "address": "Chowrigacha Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.680536,
            26.429998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LRU",
          "name": "LALPUR UMRI",
          "zone": "WCR",
          "address": "SH 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.460845,
            26.160967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MXL",
          "name": "MOKHOLI",
          "zone": "WCR",
          "address": "MDR 111, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.66392400000001,
            15.203137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TNGL",
          "name": "TORANAGALLU",
          "zone": "SWR",
          "address": "Toranagallu, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3193615509,
            15.4300360491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SVHE",
          "name": "SISVINHALLI",
          "zone": "SWR",
          "address": "Dharwad, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.302374,
            23.502512000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LKX",
          "name": "LAKSHMIPUR",
          "zone": "ER",
          "address": "Lakshmipur Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.119763,
            22.050131999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYGL",
          "name": "MIYAGAM KARJAN JUNCTION NG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.82678299999999,
            25.219375000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SWC",
          "name": "SHIVRAMPUR",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8964954901,
            25.065781416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SPDM",
          "name": "SINGHPUR DUMRA",
          "zone": "NCR",
          "address": "Singhpurdumra, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.43266200000001,
            23.040053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DMLE",
          "name": "DUMURDAHA",
          "zone": "ER",
          "address": "Dumurdahadham, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.740791,
            29.364244000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRZ",
          "name": "SARDARGARH",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.069304,
            22.585067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DL",
          "name": "DALADI",
          "zone": "WR",
          "address": "Daladi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.159087,
            22.344458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGY",
          "name": "VADODARA D CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.836903,
            18.944481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CSTM",
          "name": "MUMBAI CST",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.238908,
            21.225502000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNHN",
          "name": "KANTHAN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.71503200000001,
            26.930598
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLD",
          "name": "MALIHABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.190842,
            21.462435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "G",
          "name": "GONDIA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.516308,
            26.282698
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SYF",
          "name": "Semai",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.70277399999999,
            26.657818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRTE",
          "name": "Soorothee",
          "zone": "NCR",
          "address": "Bari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.45049,
            21.21335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBEC",
          "name": "DEVBALODA CHARODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.27219600000001,
            10.758854999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRR",
          "name": "SHORANUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.402106,
            22.027897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LATI",
          "name": "LATIA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.84907630000001,
            21.827212300000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPH",
          "name": "BELPAHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DNGD",
          "name": "Dungarda",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHVP",
          "name": "PHVP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.578044,
            18.314695999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SSI",
          "name": "Shirsai",
          "zone": "CR",
          "address": "Daund-Baramati Rd Daund, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.053112,
            22.473359000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSBP",
          "name": "BIR SHIBPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.25607790219999,
            22.5801540304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRGM",
          "name": "MAURIGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.939298,
            13.647065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SPV",
          "name": "Sivapur",
          "zone": "SWR",
          "address": "Bangalore Honavar Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.15471199999999,
            14.142728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SRF",
          "name": "Sagar Jambagaru",
          "zone": "SWR",
          "address": "Sagar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.233108,
            25.222941000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMPA",
          "name": "Shampura",
          "zone": "WCR",
          "address": "Bhilwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.53577899999999,
            26.950169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DXK",
          "name": "DHANA KHERLI",
          "zone": "NCR",
          "address": "Fatehpur Sikri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5909632294,
            26.9903803821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RBS",
          "name": "RUPBAS",
          "zone": "NCR",
          "address": "Roopvas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.670597,
            27.093989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FTS",
          "name": "FATEHPUR SIKRI",
          "zone": "NCR",
          "address": "Agra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.924593761,
            27.1568431942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PTLI",
          "name": "PATHAULI",
          "zone": "NCR",
          "address": "Agra Fort, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.033546,
            15.337535
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNR",
          "name": "TANGUTURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.057404,
            15.498064000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OGL",
          "name": "ONGOLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.984729,
            26.479318
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMW",
          "name": "ROSHAN MAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.025181,
            26.476492
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTO",
          "name": "MAITHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.97072399999999,
            15.267911000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "MAO",
          "name": "MADGAON",
          "zone": "KR",
          "address": "Madgaon. Ph:- 0832-2712790., Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.593121,
            15.891067999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SXB",
          "name": "SAMBRE",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.252881,
            17.200729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNE",
          "name": "SHENOLI",
          "zone": "CR",
          "address": "Shenoli Station, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.296582,
            28.360763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SW",
          "name": "SEHRAMAU",
          "zone": "NER",
          "address": "State Highway 21, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.917581,
            26.885533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NKB",
          "name": "NAGRAKOTA",
          "zone": "NFR",
          "address": "Nagrakata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.48526169979999,
            26.6574376742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GRU",
          "name": "GAROPARA",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.95710500000001,
            18.303639999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SMLG",
          "name": "Shimiliaguda",
          "zone": "ECoR",
          "address": "Way to Araku, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.54311899999999,
            23.259379000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "THP",
          "name": "Taherpur",
          "zone": "ER",
          "address": "State Highway 11, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.256873,
            13.108338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VJM",
          "name": "VYASARPADI JEEVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.24453299999999,
            13.10702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PER",
          "name": "PERAMBUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84685300000001,
            19.1174289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ADH",
          "name": "ANDHERI",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.668805,
            20.2955
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SYE",
          "name": "Sindewahi",
          "zone": "SECR",
          "address": "Bhendala, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.806077,
            22.316377000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SSKL",
          "name": "Suskal",
          "zone": "?",
          "address": "State Highway 11, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.132531,
            18.116239
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SUP",
          "name": "Srungavarapukota",
          "zone": "ECoR",
          "address": "Srungavarapu kota, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.981519,
            12.692863999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGL",
          "name": "CHENGALPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNP",
          "name": "CNP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.82300099999999,
            28.870965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GOT",
          "name": "GOT",
          "zone": "NER",
          "address": "MDR 65W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.76725900000001,
            27.135742999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TKHA",
          "name": "TAKHA",
          "zone": "NCR",
          "address": "Takha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.023914,
            30.900491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "DMP",
          "name": "Dharampur Himachal",
          "zone": "NR",
          "address": "Dharampur, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.975729,
            17.393190999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TVL",
          "name": "TADWAL",
          "zone": "SWR",
          "address": "Mundhewadi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.699632,
            24.566862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "UDZ",
          "name": "UDAIPUR CITY",
          "zone": "NWR",
          "address": "Udaipur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.771734,
            24.522588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "UMRA",
          "name": "UMRA",
          "zone": "NWR",
          "address": "State Highway 32, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1834,
            10.844904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TT",
          "name": "TITTE",
          "zone": "SR",
          "address": "Thanjavur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0638054443,
            23.3740319962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "TGB",
          "name": "Tangarbasuli",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.276521,
            32.09448999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KGMR",
          "name": "KANGRA MANDIR",
          "zone": "NR",
          "address": "Kangra, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.823761,
            10.786552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TOM",
          "name": "TONDAMANPATTI",
          "zone": "SR",
          "address": "Tondaimanpatti, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.360215,
            22.024158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTSH",
          "name": "KOTMI SONAN HLT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.776419,
            10.787167
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TRB",
          "name": "TIRUVERUMBUR",
          "zone": "SR",
          "address": "Thiruverumbur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.354538,
            22.753941
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PPI",
          "name": "PIPARIYA",
          "zone": "WCR",
          "address": "Piparia, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-BSBC",
          "name": "XX-BSBC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.389861,
            21.517291
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MWA",
          "name": "MANDWA",
          "zone": "CR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.397032,
            21.463933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NPNR",
          "name": "NEPANAGAR",
          "zone": "CR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.282434,
            21.746916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UA",
          "name": "UPLETA",
          "zone": "WR",
          "address": "Dist. Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.91065900000001,
            21.111116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SAV",
          "name": "SAVDA",
          "zone": "CR",
          "address": "Jalgaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1332331232,
            13.8632737545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HSD",
          "name": "HOSDURGA ROAD",
          "zone": "SWR",
          "address": "Nilagiri Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.201224,
            13.922427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TKU",
          "name": "Tanakallu",
          "zone": "SCR",
          "address": "Tanakallu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.027126,
            15.253117999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKM",
          "name": "SINGARAYAKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.826878,
            26.515363999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AAP",
          "name": "AMBIAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.561419,
            12.497951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPT",
          "name": "TIRUPATTUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.624967,
            13.634506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VLD",
          "name": "Vayalpad",
          "zone": "SCR",
          "address": "Vayalpadu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.57461305,
            23.762753179999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAM",
          "name": "CHHOTA AMBANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.490876,
            15.236108999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PKL",
          "name": "PAPINAYAKNAHALI",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.06525500000001,
            12.966994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LTI",
          "name": "LATTERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.878645,
            19.007080000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "TPQ",
          "name": "Topokal",
          "zone": "ECoR",
          "address": "Raikot, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.96927299999999,
            12.948577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVN",
          "name": "KAVANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.701487,
            17.914855999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CLE",
          "name": "CHINTALPALLI",
          "zone": "SCR",
          "address": "Narsampet Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.916157,
            12.937470999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GYM",
          "name": "GUDIYATTAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.734225,
            26.554994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JJK",
          "name": "JHINJHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.839324,
            12.889517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLT",
          "name": "VALATHOOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.154881,
            32.92664
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "UHP",
          "name": "UDHAMPUR",
          "zone": "NR",
          "address": "Railway road, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.797049,
            12.859507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPI",
          "name": "MELPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.721266,
            12.782934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AB",
          "name": "AMBUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.67871600000001,
            12.728899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGM",
          "name": "VINNAMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.621651,
            12.678487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VN",
          "name": "VANIYAMBADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.155817,
            17.514242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TAZ",
          "name": "TARGAON",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.4114096783,
            27.531769928899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TLR",
          "name": "Tulsipur",
          "zone": "NER",
          "address": "Tulasipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.517354,
            27.059455
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAN",
          "name": "SANDILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-DKJC",
          "name": "YY-DKJC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.335511,
            23.681625999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "TLE",
          "name": "Talgaria",
          "zone": "SER",
          "address": "National Highway 32, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.600638797,
            26.9993432467
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RBD",
          "name": "RAHIMABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.404252,
            17.998115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KFD",
          "name": "KARANJADI",
          "zone": "KR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.875384,
            12.917803000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MEH",
          "name": "MELALATHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.535143,
            27.464468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TII",
          "name": "Tingrai",
          "zone": "NFR",
          "address": "National Highway 38, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.4205625555,
            24.1003209724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UM",
          "name": "UMARDASHI",
          "zone": "WR",
          "address": "Chitrasani, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.024717,
            12.961297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VJ",
          "name": "VIRINCHIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.14775399999999,
            23.069086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GIMB",
          "name": "GANDHIDHAM BG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27485630000001,
            13.0847613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAS",
          "name": "CHENNAI CENTRAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.225105,
            13.107826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PEW",
          "name": "PERAMBUR LOCOMOTIVE WORKS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.83276000000001,
            13.093945
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPAM",
          "name": "SENJI PANAMBAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4104337677,
            29.592663211399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "THBN",
          "name": "THANA BHAWAN",
          "zone": "NR",
          "address": "Thana Bhawan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.60522300000001,
            20.678242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TEP",
          "name": "Tempa",
          "zone": "SECR",
          "address": "Tempa, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.45029299999999,
            10.914737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MUQ",
          "name": "MARUDUR",
          "zone": "SR",
          "address": "Nagappattinam - Trichy - Karur- Coimbatore - Mettupalayam - Ooty - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.098875,
            16.300176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VMD",
          "name": "Vadlamannadu",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.46418718439999,
            16.580375476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "UNDI",
          "name": "UNDI",
          "zone": "SCR",
          "address": "West Godavari, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.889325,
            25.134973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "EKH",
          "name": "EKSARI HALT",
          "zone": "ECR",
          "address": "Eksari, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.60618600000001,
            13.166875999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TLS",
          "name": "Bettahalsoor",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.532205,
            17.530163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TPY",
          "name": "Tadakalpudi",
          "zone": "SCR",
          "address": "Kothagudem To Bhadrachalam Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1099430353,
            18.3906018255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UDGR",
          "name": "Udgir",
          "zone": "SCR",
          "address": "Dist. Latur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.218817,
            8.302348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KZT",
          "name": "KULITTHURAI",
          "zone": "SR",
          "address": "Marthandam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.95084399999999,
            12.590197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTM",
          "name": "PADALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.97565300000001,
            12.623128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OV",
          "name": "OTIVAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.988514,
            12.670639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRX",
          "name": "TIRUMANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.60581400000001,
            18.912675
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UKH",
          "name": "UKHALI",
          "zone": "SCR",
          "address": "State Highway 169, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.405555,
            23.723330999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "VAA",
          "name": "Bhaga Junction",
          "zone": "SER",
          "address": "Jamadoba, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.013734,
            25.410723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAE",
          "name": "SIMARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.517705,
            17.983151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZJT",
          "name": "KAZIPET TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.561183,
            17.975697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZJE",
          "name": "KAZIPET E CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.127493,
            12.910718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VLR",
          "name": "VELLORE CANT",
          "zone": "SR",
          "address": "Vellore-632001, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.511652,
            27.351481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UGP",
          "name": "UGARPUR",
          "zone": "NCR",
          "address": "Ugarpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.487264,
            22.152185
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VAN",
          "name": "Vadhvana",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.703785,
            11.195825000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VDL",
          "name": "VITHISVARANKOL",
          "zone": "SR",
          "address": "Pullirukkuvelur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.16915,
            28.296051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AO",
          "name": "AONLA",
          "zone": "NR",
          "address": "Dist. Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.30312500000001,
            25.11793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DBR",
          "name": "DABHAURA",
          "zone": "NCR",
          "address": "Dabhaura, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.214385,
            22.943112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NU",
          "name": "NARSINGHPUR",
          "zone": "WCR",
          "address": "Narsimhapur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.79173399999999,
            22.899459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GAR",
          "name": "GADARWARA",
          "zone": "WCR",
          "address": "Narsinghpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75641,
            22.604976999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUH",
          "name": "JUJHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.48869499999999,
            30.775754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMNN",
          "name": "KHAMANON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.98023400000001,
            23.768893
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "AMLO",
          "name": "AMLO",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.71148570000001,
            13.264571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AMSA",
          "name": "AMMASANDRA",
          "zone": "SWR",
          "address": "Tumkur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.0411941809,
            22.1759911277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GRJA",
          "name": "GORINJA",
          "zone": "WR",
          "address": "Khambhalia-Dwarka (SH 6), Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.22767,
            22.053427000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTW",
          "name": "GATORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.114795,
            10.841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VEI",
          "name": "VELLIYANI",
          "zone": "SR",
          "address": "Dindigul - Guziliamparai - Karur Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.419624,
            27.118537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLQ",
          "name": "DALELNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.619321,
            16.785593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VID",
          "name": "VILAVADE",
          "zone": "KR",
          "address": "Ratnagiri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.420652,
            14.436871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KT",
          "name": "KUMTA",
          "zone": "KR",
          "address": "Kumta, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.5542552124,
            26.632118317699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHD",
          "name": "PHAPHUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6354260596,
            26.59792371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNS",
          "name": "KANCHAUSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73159,
            13.32831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "VTE",
          "name": "Venkatagiri Kote Halt",
          "zone": "SWR",
          "address": "National Highway 7, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.524011,
            26.812601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AGI",
          "name": "AMGURI",
          "zone": "NFR",
          "address": "Amguri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.23076900000001,
            13.304293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APB",
          "name": "ANUPPAMBATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.19879900000001,
            13.332319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PON",
          "name": "PONNERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.146879,
            13.36347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVP",
          "name": "KAVARAIPPETTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.123431,
            13.409631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPD",
          "name": "GUMMIDIPUNDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.11617799999999,
            13.458350999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ELR",
          "name": "ELAVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.074371,
            13.532686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKM",
          "name": "ARAMBAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.035435,
            13.587948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TADA",
          "name": "TADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.635315,
            12.273762000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AP",
          "name": "ASHOKAPURAM",
          "zone": "SWR",
          "address": "Mysore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0188634064,
            13.6491060718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKAT",
          "name": "AKKAMPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.018161,
            13.696463
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPE",
          "name": "SULLURUPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9800264775,
            13.7594908008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PEL",
          "name": "POLIREDDIPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.829736,
            25.442410000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ALY",
          "name": "ALLAHABAD CITY",
          "zone": "NER",
          "address": "Allahabad(0532-1072), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.951308,
            13.814297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVR",
          "name": "DORAVARI CHATRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.602231,
            16.850569999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VRB",
          "name": "VISHRAMBAG",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.900898,
            13.908321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYP",
          "name": "NAYADUPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.61131300000001,
            25.496532000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "UWNR",
          "name": "Udwant Nagar Halt",
          "zone": "ECR",
          "address": "State Highway 12, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.875372,
            14.021481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PYA",
          "name": "PEDAPARIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.855368,
            14.072185000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ODUR",
          "name": "ODUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.845121,
            14.148227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDR",
          "name": "GUDUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.87707,
            14.226551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBL",
          "name": "MANUBOLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.893501,
            14.257776999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMLP",
          "name": "KOMMARAPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.62356999999999,
            16.327559
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIG",
          "name": "DUGGIRALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.330741,
            20.850672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "URR",
          "name": "Umred",
          "zone": "SECR",
          "address": "Umred, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.759423,
            10.246016
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TYM",
          "name": "Tirumayam",
          "zone": "SR",
          "address": "Pudukkottai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.30014299999999,
            25.902315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UCR",
          "name": "Unchahar Junction",
          "zone": "NR",
          "address": "Unchahar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.46715300000001,
            24.826792
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "GBHA",
          "name": "GAROBIGHA HALT",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.65068500000001,
            21.510235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AMX",
          "name": "Amlakhurd",
          "zone": "SCR",
          "address": "MSH 6, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.380205,
            11.798583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVNL",
          "name": "TIRVNLNLUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.386975,
            11.811502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDMD",
          "name": "KANDAMBAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.500101,
            11.942971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VM",
          "name": "VILLUPURAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.5116865137,
            11.995680489400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYP",
          "name": "MUNDILYAMPAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.538038,
            12.037928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VVN",
          "name": "VIKRAVANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.552577,
            12.104212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PEI",
          "name": "PERANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.56707,
            12.128075
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMO",
          "name": "NEDI MOLLYANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.60220000000001,
            12.160501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTL",
          "name": "MAILAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65133499999999,
            12.229410999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMV",
          "name": "TINDIVANAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.691931,
            12.275612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCLM",
          "name": "PANCHALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.72191900000001,
            12.306462999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OLA",
          "name": "OLAKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.749958,
            12.333838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSGL",
          "name": "KARASANGAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.783575,
            12.367080999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TZD",
          "name": "TOZHUPPEDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.821722,
            12.406776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACK",
          "name": "ACHARAPAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.83380100000001,
            12.429554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLMR",
          "name": "MELMARUVATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.325158,
            26.400204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "VPH",
          "name": "Vachaspati Nagar",
          "zone": "ECR",
          "address": "National Highway 57, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.850569,
            12.465125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQM",
          "name": "PAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.876748,
            22.591636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VTL",
          "name": "Vadtal Swaminarayan",
          "zone": "WR",
          "address": "Vadtal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.89308700000001,
            12.50442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMK",
          "name": "MADURANTAKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.849389,
            19.164447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GMN",
          "name": "GOREGAON",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.177527,
            25.263973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "WDA",
          "name": "WADRENGDISA",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.32740791990001,
            27.2881821763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAF",
          "name": "Bap",
          "zone": "?",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.829518,
            30.683642000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "AHH",
          "name": "AHMADGARH",
          "zone": "NR",
          "address": "Ahmedgarh, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.46692,
            26.606593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AGE",
          "name": "Angai",
          "zone": "NCR",
          "address": "Angai, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4030697949,
            17.8447367757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AHI",
          "name": "ASHTI",
          "zone": "CR",
          "address": "State Highway 161, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7272273724,
            11.6178033436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ALP",
          "name": "ALAPAKAM",
          "zone": "SR",
          "address": "NH 45A, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.678145,
            18.446006999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BANL",
          "name": "BHATANGLI",
          "zone": "?",
          "address": "MSH3, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.065281,
            20.300507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AMW",
          "name": "AMAN VADI",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.68058,
            10.756748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AYM",
          "name": "ADIYAKKAMANGALAM",
          "zone": "SR",
          "address": "Thiruvarur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.612937,
            23.043981000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ASV",
          "name": "ASARVA JN",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.31573800000001,
            10.779506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AMT",
          "name": "AMMAPET",
          "zone": "SR",
          "address": "Ammapet, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.607241,
            31.594934000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "ATT",
          "name": "ATARI",
          "zone": "NR",
          "address": "Atari, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.573854,
            27.354893999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JJA",
          "name": "JAJAN PATTI",
          "zone": "WCR",
          "address": "State Highway 33, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.72470399999999,
            11.270467
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ARS",
          "name": "ARASUR",
          "zone": "SR",
          "address": "NH 45A, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.911821,
            26.641030999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KNDP",
          "name": "KHANDIP",
          "zone": "WCR",
          "address": "State Highway 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.44308500000001,
            12.714668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "YDM",
          "name": "Yedamangala",
          "zone": "SWR",
          "address": "Panja Kadaba Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.635578,
            26.344258999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NNW",
          "name": "NARAYANPUR TATWAR",
          "zone": "WCR",
          "address": "Sawai Madhopur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84045439,
            19.05445443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BA",
          "name": "BANDRA",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.268322,
            22.582088000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AEJ",
          "name": "ARNEJ",
          "zone": "WR",
          "address": "Arnej, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2758,
            13.268080999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPKM",
          "name": "NANDIYAMPAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21665700000001,
            23.557854000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DDCE",
          "name": "DUNDI",
          "zone": "WCR",
          "address": "Dundi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.86729,
            22.632067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GRO",
          "name": "GURRA",
          "zone": "WCR",
          "address": "Itarsi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.988271,
            14.915993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVZ",
          "name": "KAVALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.00582100000001,
            15.042824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TTU",
          "name": "TETTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.01336400000001,
            15.167112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UPD",
          "name": "ULAVAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.602425,
            22.545189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTZ",
          "name": "KHUTWANSA",
          "zone": "WCR",
          "address": "Hoshangabad, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.85944699999999,
            22.166444000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KKN",
          "name": "KHIRKIYA",
          "zone": "WCR",
          "address": "State Highway 15, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.1319768447429,
            22.266245011489417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BALI",
          "name": "Bhaili",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.99684813804924,
            10.164469056963739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ATQ",
          "name": "Arantangi",
          "zone": "SR",
          "address": "Aranthangi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.22611699999999,
            23.58096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "AGAE",
          "name": "AGRADWIP",
          "zone": "ER",
          "address": "Agradwip, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3078029926,
            22.5986336217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSNR",
          "name": "DASHNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.324322,
            22.599036
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPKR",
          "name": "TIKIAPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PFMA",
          "name": "PFMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.508313,
            22.161144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AMLP",
          "name": "Amalpur",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.21122979931533,
            28.947951113517377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AUS",
          "name": "Anupshahr",
          "zone": "NWR",
          "address": "Anupshaher Rd, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ARVI",
          "name": "Arvi",
          "zone": "CR",
          "address": "Dist. Wardha, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ARX",
          "name": "Areli",
          "zone": "NER",
          "address": "Areli, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ASM",
          "name": "Asarma",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ATB",
          "name": "Alattambadi",
          "zone": "SR",
          "address": "Mazavarayanallu Rd, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ATDA",
          "name": "Atladara",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "ATX",
          "name": "Alinagar Tola",
          "zone": "ECR",
          "address": "Purnea, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.07484199999999,
            28.368987999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ASLU",
          "name": "Aslu",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AYI",
          "name": "Ayingudi",
          "zone": "SR",
          "address": "Peravurani - Aranthangi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.360808,
            26.72284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHSR",
          "name": "GHASARA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.404392,
            26.701949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULD",
          "name": "ACHALDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.25310499999999,
            28.100118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "AEL",
          "name": "Ateli",
          "zone": "NWR",
          "address": "Dist. Mahendragarh, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.135767,
            17.89054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WTR",
          "name": "WATHAR",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.1605539337,
            18.1836347437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WLH",
          "name": "VALHA",
          "zone": "CR",
          "address": "Indraprastha Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.086816,
            22.052122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BAAR",
          "name": "Bandar P.H.",
          "zone": "?",
          "address": "Haldia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.228012,
            21.607831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AE",
          "name": "Amreli",
          "zone": "WR",
          "address": "Amreli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.257914,
            27.269928999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MST",
          "name": "MASIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.296314,
            27.228081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGH",
          "name": "BAGHAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.087316,
            19.078901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TPND",
          "name": "TALOJA PANCHAND",
          "zone": "CR",
          "address": "Navi Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.592514,
            16.921753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ADVI",
          "name": "ADAVALI",
          "zone": "KR",
          "address": "Ratnagiri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.09820837459999,
            15.4117921224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AGL",
          "name": "AMARGOL",
          "zone": "SWR",
          "address": "Hubli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.87196899999999,
            28.933796
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AS",
          "name": "ARJANSAR",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.09631900000001,
            25.776942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BAKT",
          "name": "Barhara Kothi",
          "zone": "ECR",
          "address": "Purnia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.267384,
            27.05777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CM",
          "name": "Campierganj",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.415466,
            22.137755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DB",
          "name": "Dabhoi Junction",
          "zone": "WR",
          "address": "Dabhoi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.317787,
            11.702177
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULU",
          "name": "ULUNDURPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.147342,
            19.068103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "AGZ",
          "name": "AMAGURA",
          "zone": "ECoR",
          "address": "Markel, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.239507,
            19.062527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "AGB",
          "name": "AMBAGAON",
          "zone": "ECoR",
          "address": "Ambagaon, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.951915,
            24.309449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYKR",
          "name": "MATYAKHERI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.36351860779999,
            27.316999355900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BBLK",
          "name": "B L DASPURI",
          "zone": "NCR",
          "address": "B L Daspuri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.209033,
            12.630801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ARV",
          "name": "ARNI ROAD",
          "zone": "SR",
          "address": "Kalambur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.61817468,
            26.65005846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BARI",
          "name": "Bari",
          "zone": "NCR",
          "address": "Bari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1944316,
            31.8214816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BAT",
          "name": "Batala Junction",
          "zone": "NR",
          "address": "Batala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.383427,
            17.778326000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BBV",
          "name": "BABHULGOAN",
          "zone": "CR",
          "address": "State Highway 161, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.39386,
            27.243104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ASI",
          "name": "Arseni",
          "zone": "NR",
          "address": "MORADABAD, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.84409600000001,
            10.758823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "APE",
          "name": "ANDANAPPETTAI",
          "zone": "SR",
          "address": "Pappakoil, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.3797154,
            24.0002687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BEHR",
          "name": "BEOHARI",
          "zone": "WCR",
          "address": "Beohari, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.391729,
            12.512857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ARNH",
          "name": "Arjunahalli",
          "zone": "SWR",
          "address": "Arjunahalli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.52218902999999,
            26.79623183
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BFG",
          "name": "Bagthar",
          "zone": "NCR",
          "address": "Bandh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.21186200000001,
            24.001215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BBAR",
          "name": "BARA",
          "zone": "WCR",
          "address": "Mahroi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.452872,
            22.425122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BDYP",
          "name": "Bidyadharpur",
          "zone": "ER",
          "address": "Bidyadharpur, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AKL",
          "name": "Anakhol",
          "zone": "WR",
          "address": "State HIghway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.882594,
            29.40129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ASAN",
          "name": "ASAN",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.9271335858,
            20.544593354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ATUL",
          "name": "ATUL",
          "zone": "WR",
          "address": "Valsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.91960900000001,
            26.448121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AONI",
          "name": "Amoni",
          "zone": "NFR",
          "address": "Amoni, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BASA",
          "name": "Banosa",
          "zone": "CR",
          "address": "Daryapur, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BATS",
          "name": "Bhatsar",
          "zone": "WR",
          "address": "Brahmanwada, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.147877,
            27.399414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRI",
          "name": "HARDOI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.623332,
            24.953077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BBE",
          "name": "BAGHI BARDIHA",
          "zone": "ECR",
          "address": "Baghi Bardiha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.684033,
            25.051952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BFX",
          "name": "BAGHI GHAUSPUR",
          "zone": "ECR",
          "address": "Baghi Ghauspur, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BCF",
          "name": "Bankapasi",
          "zone": "ER",
          "address": "Bankapasi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.74904447,
            12.075565500000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BDV",
          "name": "BADANAVALU",
          "zone": "SWR",
          "address": "Nanjungudu-Chamarajanagara Rd, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BCHR",
          "name": "Bachar",
          "zone": "WR",
          "address": "Palej, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BCM",
          "name": "Buranpudi",
          "zone": "ECR",
          "address": "Supaul, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BDKE",
          "name": "Bhadsivni",
          "zone": "CR",
          "address": "Bhadsivni, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.989954,
            14.496976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGU",
          "name": "PADUGUPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.99490800000001,
            14.564331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJJ",
          "name": "KODAVALURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.998171,
            14.607124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMC",
          "name": "TALAMANCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.989036,
            14.710541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AXR",
          "name": "ALLURU ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.98314599999999,
            14.768584800000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTTR",
          "name": "BITRAGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.982262,
            14.806671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVPM",
          "name": "S VENKTESWRPALM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7783649164,
            27.1230467855
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPO",
          "name": "KARANPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8145379749,
            27.1289860174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUDA",
          "name": "BHUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8508226947,
            27.1560976594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MURD",
          "name": "MANDAWAR MAHUA RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.270698,
            27.103714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ANDN",
          "name": "Anand Nagar",
          "zone": "NER",
          "address": "Pharenda, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.90338,
            27.179904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOS",
          "name": "GHOSRANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.9610701135,
            27.1971512117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTF",
          "name": "DANTLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BDRN",
          "name": "Bhadran",
          "zone": "WR",
          "address": "Borsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0364244739,
            27.2002567722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KL",
          "name": "KHERLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.86256,
            18.333991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ARK",
          "name": "Arakku",
          "zone": "ECoR",
          "address": "Araku, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.104134,
            27.205008000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBL",
          "name": "TARCHERRA BRLRM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BF",
          "name": "Bagetar",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2080875122,
            27.213674308399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBI",
          "name": "NADBAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.308829,
            27.226004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPEA",
          "name": "PAPRERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.378757,
            27.234442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HK",
          "name": "HELAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.488613,
            27.237106999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTE",
          "name": "BHARATPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.204918,
            21.998734000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "ATR",
          "name": "Attar",
          "zone": "WR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.546395,
            27.223368999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHB",
          "name": "NOH BACHHAMDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.608741,
            27.211065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IK",
          "name": "IKRAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.663925,
            27.199896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIK",
          "name": "CHIKSANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.091842,
            8.579144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ANY",
          "name": "Arumuganeri",
          "zone": "SR",
          "address": "Arumuganeri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.91169348999999,
            24.8659010332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BAKA",
          "name": "Banka",
          "zone": "?",
          "address": "Banka, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7578389026,
            27.1817602284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AH",
          "name": "ACHHNERA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.233285,
            27.207747
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDL",
          "name": "TUNDLA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.50634099999999,
            13.073539
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BAW",
          "name": "CHIK BANAVAR",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.31580600000001,
            11.600544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVN",
          "name": "PAVUNUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8278150574,
            29.258822195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BFN",
          "name": "Bugana",
          "zone": "NR",
          "address": "National Highway 65, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.209164,
            14.073324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ANF",
          "name": "Anandapuram",
          "zone": "SWR",
          "address": "Anandapuram, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.36905800000001,
            22.104577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Odisha",
          "code": "BBN",
          "name": "Barbil",
          "zone": "SER",
          "address": "Kiriburu, Odisha"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.31461900000001,
            27.175703000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNG",
          "name": "HIRANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.386691,
            27.147415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FZD",
          "name": "FIROZABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4715,
            27.119485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNR",
          "name": "MAKKHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.57495499999999,
            27.085686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKB",
          "name": "SHIKOHABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.664813,
            27.029
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAA",
          "name": "KAURARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.73749459999999,
            26.9793918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDN",
          "name": "BHADAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8237102228,
            26.9205023929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBL",
          "name": "BALRAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.893731,
            26.872978000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGR",
          "name": "JASWANTNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.94470899999999,
            26.837847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SB",
          "name": "SARAI BHOPAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.021502,
            26.785970000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETW",
          "name": "ETAWAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.116455,
            26.76628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EKL",
          "name": "EKDIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2163612,
            26.7502248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNT",
          "name": "BHARTHANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.849937,
            15.389334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "DBM",
          "name": "DABOLIM",
          "zone": "SWR",
          "address": "Mormugao, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.564613,
            25.665207000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AUWA",
          "name": "AUWA",
          "zone": "NWR",
          "address": "Somesar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.786923,
            26.920203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JP",
          "name": "JAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.2429681,
            23.9320594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BEB",
          "name": "Beldanga",
          "zone": "ER",
          "address": "Beldanga, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.27417043950001,
            17.5887732682
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BHLI",
          "name": "BOHALI",
          "zone": "CR",
          "address": "State Highway 161, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.680769,
            29.094392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BASN",
          "name": "BHAINSWAN",
          "zone": "NR",
          "address": "Gohana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.64165885999999,
            23.31226134
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BHNA",
          "name": "Bhayna",
          "zone": "ER",
          "address": "Mamjoan Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.05509864230001,
            23.7566729304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "BHME",
          "name": "BHANDARIDAH",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.0951862,
            30.209881999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BCU",
          "name": "BHUCHCHU",
          "zone": "NR",
          "address": "Bhucho Mandi, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63077340000001,
            25.3435508
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BHL",
          "name": "BHILWARA",
          "zone": "NWR",
          "address": "Bhilwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.538716,
            25.867466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KVA",
          "name": "KAVAS",
          "zone": "NWR",
          "address": "NH-112 Barmer-Jodhpur Hwy, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6913786,
            26.1253008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BDW",
          "name": "BANDANWARA",
          "zone": "NWR",
          "address": "Bandanwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65527399999999,
            28.574785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BJV",
          "name": "Bijauria",
          "zone": "NER",
          "address": "Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.731497,
            26.283912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHJ",
          "name": "Barhaj Bazar",
          "zone": "NER",
          "address": "Barhaj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.74336799999999,
            19.991217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DRD",
          "name": "DAHANU ROAD",
          "zone": "WR",
          "address": "Dahanu, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6568744922,
            27.100774588100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJRA",
          "name": "BHAJERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.983187,
            28.464735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BDXT",
          "name": "BASAI DHANKOT",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.847673,
            22.402073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BCHN",
          "name": "Bochasan Junction",
          "zone": "WR",
          "address": "Anand, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.34013,
            29.387323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BHT",
          "name": "Bhattu",
          "zone": "NWR",
          "address": "Bhattu Kalan, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.110265,
            23.467136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SHR",
          "name": "SIHORA ROAD",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.544215,
            21.946109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SGBJ",
          "name": "SURGAON BANJARI",
          "zone": "WCR",
          "address": "Khandwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.928979,
            21.36507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BADR",
          "name": "Bhader",
          "zone": "WR",
          "address": "Amreli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.202714,
            19.296504000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLA",
          "name": "TITVALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.82792500000001,
            21.736131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BJUD",
          "name": "BAJUD",
          "zone": "WR",
          "address": "State Highway 31, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.108666,
            9.944876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KON",
          "name": "KUDALNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.22266067937016,
            29.831708406844584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BDSW",
          "name": "Budhsinghwala",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3149711,
            26.1089078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BER",
          "name": "BEAWAR",
          "zone": "NWR",
          "address": "Beawar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5881717944,
            20.2787142311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BGDA",
          "name": "Bogada",
          "zone": "?",
          "address": "Bogada, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.47340000000001,
            27.464247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AWG",
          "name": "Awa Garh",
          "zone": "NCR",
          "address": "Awagarh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.67465299999999,
            25.632101000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SBK",
          "name": "SHAHBAZ KULI",
          "zone": "NER",
          "address": "Shahbaz Quli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.32212448269999,
            13.218195717099999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ENR",
          "name": "ENNORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.983464,
            14.444474999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLS",
          "name": "NELLORE SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.679671,
            21.077372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AVL",
          "name": "AMRITVEL",
          "zone": "WR",
          "address": "Amrutwel Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.989383,
            14.460592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLR",
          "name": "NELLORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BGR",
          "name": "Bhagdara",
          "zone": "CR",
          "address": "Bhagdara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.51904643,
            23.44693617
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGA",
          "name": "BAGALIA",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.72660900000001,
            23.548271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BERO",
          "name": "BERO",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-MEJB",
          "name": "XX-MEJB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BHHT",
          "name": "Barhara",
          "zone": "?",
          "address": "Barhara, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.037941,
            11.580796
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVBH",
          "name": "MAKUDAN VIRAPANDI BLOCK HUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72667899999999,
            13.299092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AVT",
          "name": "Avati",
          "zone": "SWR",
          "address": "Avati, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XX-KJMB",
          "name": "XX-KJMB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHOJ",
          "name": "Bhoj Padra",
          "zone": "WR",
          "address": "Vadodara Jambusar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.324566,
            22.438916000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BIV",
          "name": "Bamhani Banjur",
          "zone": "SECR",
          "address": "Bamhani, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.19469228000001,
            22.49310248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGB",
          "name": "Budge Budge",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "BHRD",
          "name": "Baghara Road",
          "zone": "SER",
          "address": ""
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDBC",
          "name": "DDBC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.431195,
            25.461085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "UMNR",
          "name": "UMESHNAGAR",
          "zone": "ECR",
          "address": "National Highway 31, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BHWR",
          "name": "Bhitiharwa Ashram",
          "zone": "ECR",
          "address": "Pashchim Champaran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.98253549799999,
            24.6355270351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BDBP",
          "name": "BASUDEBPUR",
          "zone": "ER",
          "address": "Basudebpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.43486,
            22.676562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MOL",
          "name": "MULI ROAD",
          "zone": "WR",
          "address": "Surendranagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.613927,
            26.099339999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AZA",
          "name": "AZARA",
          "zone": "NFR",
          "address": "Guwahati, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.56637599999999,
            14.250321000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BAHI",
          "name": "Balenahalli",
          "zone": "SWR",
          "address": "State Highway 48, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.29116829590001,
            29.218093878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BDHP",
          "name": "BHUDPUR",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.320694,
            26.734186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHW",
          "name": "SAMHON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4794183441,
            26.6666191003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PATA",
          "name": "PATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.634896,
            26.596933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PJY",
          "name": "PARA JANI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.908216,
            26.485377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RURA",
          "name": "RURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.12429014,
            26.46811536
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPU",
          "name": "BHAUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.350966,
            26.454240000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNB",
          "name": "KANPUR CENTRAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.40654599999999,
            26.381159999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHK",
          "name": "CHAKERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.462852,
            26.297301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSL",
          "name": "SIRSAUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.8532993597,
            25.8911534211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAMA",
          "name": "RAMVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.61057,
            27.333596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BKF",
          "name": "Bhikhna Thori",
          "zone": "ECR",
          "address": "WestChamparan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.387421,
            22.482615000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGJT",
          "name": "Bagha Jatin",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.013166,
            21.042219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BGPL",
          "name": "Baghuapal",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5390747,
            25.206116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BEHS",
          "name": "Bihar Sharif",
          "zone": "ECR",
          "address": "06112-222306, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.54106045,
            24.19545969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Mizoram",
          "code": "BHRB",
          "name": "Bhairabi",
          "zone": "?",
          "address": "Kolasib, Mizoram"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.896516228,
            29.1741168151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BDWL",
          "name": "BIRADHWAL",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.010639,
            26.222963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BANE",
          "name": "BASNI",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.689054,
            24.9628178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BHZ",
          "name": "BIHARA",
          "zone": "NFR",
          "address": "K Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.5003221,
            28.777391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHI",
          "name": "BAHERI",
          "zone": "NER",
          "address": "SH 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5871439,
            25.553762900000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BHB",
          "name": "BADLA GHAT",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.65305889999999,
            25.931003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BJNR",
          "name": "BIJAINAGAR",
          "zone": "NWR",
          "address": "Bijainagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.018663,
            24.555903
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BGHI",
          "name": "BAGAHAI ROAD",
          "zone": "WCR",
          "address": "Baghai Road, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.295846,
            21.361072999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BDDR",
          "name": "BADODAR",
          "zone": "WR",
          "address": "National Highway 8D, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.55125199999999,
            23.2358754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BIJ",
          "name": "Birnagar",
          "zone": "ER",
          "address": "Birnagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.14680469999999,
            29.3856298
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BJO",
          "name": "BIJNOR",
          "zone": "NR",
          "address": "Bijnor, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.4287921,
            23.181967399999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BHLA",
          "name": "BEHULA",
          "zone": "ER",
          "address": "Behula, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.254263,
            28.865600999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BLQR",
          "name": "BILASPUR ROAD",
          "zone": "NER",
          "address": "Rampur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0100837976,
            24.9206004744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BHLE",
          "name": "Barahat",
          "zone": "ER",
          "address": "State Highway 19, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.4525522,
            23.1215453
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGAE",
          "name": "BALAGARH",
          "zone": "ER",
          "address": "Balagarh, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.1189461,
            23.250338600000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BJRI",
          "name": "BIJURI",
          "zone": "SECR",
          "address": "Shahdol, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.2803623,
            22.0881542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHTA",
          "name": "BHATIYA",
          "zone": "WR",
          "address": "National Highway 8E, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.87557819999999,
            23.7851503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "BKRO",
          "name": "BOKARO THERMAL",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.354918,
            21.241422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHCR",
          "name": "CHACHER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.42759080629999,
            21.3221098696
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNL",
          "name": "DHANOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.01226592063904,
            26.35666473117378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "LLP",
          "name": "Lalitgram",
          "zone": "ECR",
          "address": "Lalitgram, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8366330817,
            20.3484309504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NI",
          "name": "NAYDONGRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.342811,
            22.583533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HJN",
          "name": "HOWRAH JN. CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7559997266,
            20.307455334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKE",
          "name": "PIMPAR KHED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.606561,
            20.275504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PJN",
          "name": "PANJHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.57099,
            20.283239000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSL",
          "name": "HISVAHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.494903,
            20.26527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNV",
          "name": "PANEVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.438305,
            20.249887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMR",
          "name": "MANMAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.485951,
            19.674468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGR3",
          "name": "BLOCK AND CATCH SIDING CABINE NO.3",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.861228,
            11.990316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BDGP",
          "name": "BADANAGUPPE",
          "zone": "SWR",
          "address": "SH 57, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.12974,
            19.234716000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYN",
          "name": "KALYAN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.538072,
            28.200105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMR",
          "name": "PITAMBARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.457241,
            26.93277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIQ",
          "name": "BANSI PAHARPUR",
          "zone": "NCR",
          "address": "Rupbas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.536566,
            14.036147999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CTTP",
          "name": "CHITRAPUR HALT",
          "zone": "KR",
          "address": "Uttara Kannada, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BMDI",
          "name": "Brahmanwada",
          "zone": "WR",
          "address": "State Highway 7, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.277512,
            27.164263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BFD",
          "name": "BORHAT",
          "zone": "NFR",
          "address": "Nahar Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5095639,
            15.8492433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BGM",
          "name": "BELGAUM",
          "zone": "SWR",
          "address": "Belgaum, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27333399999999,
            26.738281999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BGU",
          "name": "BAIRGANIA",
          "zone": "ECR",
          "address": "Barahwa Pool Rd, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.20154742999999,
            28.04769242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BGRM",
          "name": "Biggabas Ramsara",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.24197815000001,
            12.9786413
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BEML",
          "name": "BEML Nagar",
          "zone": "SWR",
          "address": "KGF, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.922331,
            26.983452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BKT",
          "name": "BAKSHI KA TALAB",
          "zone": "NER",
          "address": "Bakshi Ka Talab, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8051375,
            28.375464097400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BFPA",
          "name": "Bhopatpur",
          "zone": "NER",
          "address": "Bhopatpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7400012,
            16.8310603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BJP",
          "name": "BIJAPUR",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.304187,
            27.065209000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BILA",
          "name": "Bheslana",
          "zone": "NWR",
          "address": "Jobner to Nawa Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.519729,
            16.237226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VJA",
          "name": "VEJANDLA",
          "zone": "SCR",
          "address": "Guntur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.570521,
            16.246589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SJL",
          "name": "SANGAM JAGARLAMUDI",
          "zone": "SCR",
          "address": "High School Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.71657979999999,
            22.2701906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BDE",
          "name": "Bodeli",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.431558,
            21.204607999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GCAB",
          "name": "GCAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.4217238,
            21.202598300000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIA",
          "name": "BHILAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.375901,
            21.208119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPHB",
          "name": "BHILAI POWER HOUSE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.33434510000001,
            21.2013382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQR",
          "name": "BHILAI NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2733021582,
            29.0725646906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BADK",
          "name": "BARKA",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08383554000001,
            20.365448699999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BDKT",
          "name": "Badakhandita",
          "zone": "ECoR",
          "address": "State Highway 12, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.981353,
            24.97843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BELA",
          "name": "Bela",
          "zone": "ECR",
          "address": "Gaya, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.957106,
            14.404478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDE",
          "name": "VEDAYAPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.913105,
            13.115764
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRL",
          "name": "TIRUVALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5137793,
            17.9116836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BIDR",
          "name": "Bidar",
          "zone": "SCR",
          "address": "Bidar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.305807,
            13.180491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WCN",
          "name": "WIMCO NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.31674199999999,
            13.204343999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAVM",
          "name": "KATHIVAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.92732000000001,
            14.327256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKT",
          "name": "VENKATACHALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.894193,
            31.197575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BHM",
          "name": "Bahram",
          "zone": "NR",
          "address": "Behram, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2293551874,
            13.143803441600001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BGPA",
          "name": "Bageshapura",
          "zone": "SWR",
          "address": "Bageshapura, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.02941299999999,
            23.516602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHRJ",
          "name": "Becharji",
          "zone": "WR",
          "address": "State Highway 7, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.045682,
            15.407571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDM",
          "name": "SURAREDDIPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.106602,
            16.713189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRH",
          "name": "POWERPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.42356000000001,
            16.824498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGT",
          "name": "UNGUTURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.67527695,
            16.930258209999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMGM",
          "name": "BRAHMANAGUDEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.832674,
            16.914337999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYM",
          "name": "KADIYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.00037565999999,
            16.96580143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBPM",
          "name": "BALABHADRAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.891038,
            17.606751
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NASP",
          "name": "NARASINGAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.214877,
            17.748818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPT",
          "name": "GOPALAPATNAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.36345,
            22.118541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKD",
          "name": "BAKHRABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.380456,
            22.174233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYA",
          "name": "NARAYANGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.37624084000001,
            22.271352309999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPE",
          "name": "BENAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.32016899999999,
            22.319921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HIJ",
          "name": "HIJILLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.261499,
            17.031591000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BKL",
          "name": "BONA KALU",
          "zone": "SCR",
          "address": "Bonakalu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.968812,
            24.635909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BLO",
          "name": "BHULON",
          "zone": "WCR",
          "address": "Kota, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.70639,
            29.514761999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BMHR",
          "name": "BAMANHERI",
          "zone": "NR",
          "address": "Muzaffarnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.397067,
            25.744521900000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BME",
          "name": "BARMER",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2735908,
            19.5520321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BLC",
          "name": "BOLDA",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.98581800000001,
            30.143292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BKWA",
          "name": "BAKAYANWALA",
          "zone": "NR",
          "address": "Diwan Khera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.008187,
            24.194251
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BLDI",
          "name": "BHILDI",
          "zone": "WR",
          "address": "Bhildi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.6315064,
            28.401499599999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BJ",
          "name": "BAHJOI",
          "zone": "NR",
          "address": "Bahjoi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.60171600000001,
            23.025715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADIJ",
          "name": "AHMEDABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.88691945187747,
            23.533660939738297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BMSR",
          "name": "Bhimasar",
          "zone": "WR",
          "address": "Kutch, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.830427,
            22.382129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BLWR",
          "name": "BILESHWAR",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.557193,
            29.378719
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BKDE",
          "name": "BUDHAKHERA",
          "zone": "NR",
          "address": "SH 14, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.46489100000001,
            21.220990999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HCAB",
          "name": "BMY H CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27837727340001,
            13.120489801700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOK",
          "name": "KORUKKUPET JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.23614485659999,
            21.8478371804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHY",
          "name": "BHAYAVADAR",
          "zone": "WR",
          "address": "Sahid Kharachia, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.9335291,
            20.6085595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BL",
          "name": "VALSAD",
          "zone": "WR",
          "address": "Valsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28092114379999,
            13.1262400028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNP",
          "name": "TONDIARPETTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.050022,
            25.055505
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BNSP",
          "name": "BANSAPAHAR",
          "zone": "WCR",
          "address": "Uttar Pradesh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.093209,
            22.472428
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULB",
          "name": "ULUBARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.16066989870001,
            27.4158206073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BLP",
          "name": "Balrampur",
          "zone": "NER",
          "address": "Balrampur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28745678409999,
            13.140301627300001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VOC",
          "name": "V.O.C. NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.597909,
            13.068171999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTRE",
          "name": "CHITTERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.42612799999999,
            13.003678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUG",
          "name": "THALANGAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.2045643,
            29.1968977
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "APH",
          "name": "ANUPGARH",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.360511,
            12.970676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WJR",
          "name": "WALAJAH ROAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.65152893999999,
            32.052625660000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BJMR",
          "name": "BAIJNATH MANDIR",
          "zone": "NR",
          "address": "Baijnath, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.289067,
            12.977678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCN",
          "name": "MUKUNDARAYAPURM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.296454,
            13.160025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVT",
          "name": "TIRUVOTTIYUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BOTR",
          "name": "Bhavdhari",
          "zone": "NWR",
          "address": "Bhiwani, Haryana"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BOX",
          "name": "Bhojasar",
          "zone": "NWR",
          "address": "State Highway 8, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.586316,
            23.082226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BMR",
          "name": "BIKRAMPUR",
          "zone": "WCR",
          "address": "Balaghat, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BPAH",
          "name": "Benipur Halt",
          "zone": "ECR",
          "address": "Darbhanga, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5379969,
            22.7748819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BKH",
          "name": "BANKHEDI",
          "zone": "WCR",
          "address": "Bankheri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.249021,
            22.514668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "AKRA",
          "name": "Akra",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4807316095,
            22.4703575471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BPF",
          "name": "BANAPURA",
          "zone": "WCR",
          "address": "Seoni Malwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.023514,
            13.502322000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BLKR",
          "name": "BALLEKERE HALT",
          "zone": "SWR",
          "address": "National Highway 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.466792,
            8.149479
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SUCH",
          "name": "SUCHINDRAM",
          "zone": "SR",
          "address": "Suchindram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.25286799999999,
            28.312786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BJWS",
          "name": "Bojawas",
          "zone": "NWR",
          "address": "State Highway 24, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5887032642,
            12.385698676899999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BLGA",
          "name": "Belagula",
          "zone": "SWR",
          "address": "Belagula, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.0209756,
            15.151725800000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "BLLI",
          "name": "BALLI",
          "zone": "KR",
          "address": "Bali, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.41239200000001,
            22.1665327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "BJMD",
          "name": "Bara Jamda",
          "zone": "SER",
          "address": "Kiriburu, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.867537,
            20.603943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BMP",
          "name": "Bramhapuri",
          "zone": "SECR",
          "address": "Brahmapuri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2720808,
            28.1830705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BIC",
          "name": "Bichia",
          "zone": "NER",
          "address": "Bichia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.6004021,
            21.443078399999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BILK",
          "name": "Bilkha",
          "zone": "WR",
          "address": "Bilkha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.18081819999999,
            22.2640234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BMPR",
          "name": "Badampahar",
          "zone": "SER",
          "address": "National Highway 6, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.1912832245,
            25.622139865799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BMGR",
          "name": "Bamangram",
          "zone": "NFR",
          "address": "Hemtabad, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.76185515,
            23.45186861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BPN",
          "name": "Banpur",
          "zone": "ER",
          "address": "Banpur-Majhdia Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.244079,
            28.832463999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BMLL",
          "name": "Bamla",
          "zone": "NWR",
          "address": "State Highway 16A, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.364152,
            20.922361
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BMW",
          "name": "Bamhni",
          "zone": "SECR",
          "address": "Nagpur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.184062,
            13.113345
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOTR",
          "name": "KORATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.23865900000001,
            13.107240000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCW",
          "name": "PERAMBUR CARRIAGE WORKS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.209621,
            13.110062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLK",
          "name": "VILLIVAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.554391,
            14.243859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNHL",
          "name": "Bellenahalli",
          "zone": "SWR",
          "address": "Chitradurga, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.166656,
            13.114872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVM",
          "name": "PATTARAVKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1525249074,
            13.114305980800001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABU",
          "name": "AMBATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.126743,
            13.116018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANNR",
          "name": "ANNANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.402042,
            14.051954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMSI",
          "name": "Kumsi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.10051800000001,
            13.118247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AVD",
          "name": "AVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.074903,
            13.118538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HC",
          "name": "HINDU COLLEGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.06542400000001,
            13.11926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAB",
          "name": "PATTABIRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.045378,
            13.121643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEC",
          "name": "NEMILICHERRY HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.96668799999999,
            13.124996999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVR",
          "name": "SEVVAPET ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.93709999999999,
            13.122922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTLR",
          "name": "PUTLUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.88406,
            13.106198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EGT",
          "name": "EGATTUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4469993,
            23.642174999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "BJE",
          "name": "Bhojudih Junction",
          "zone": "SER",
          "address": "Jairampur More to Baliapur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.801508,
            13.096025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAF",
          "name": "MANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7188409808,
            13.0857348949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSU",
          "name": "MOSUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.659245,
            13.080671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLPM",
          "name": "MELPAKKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.399298,
            12.99726
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRLM",
          "name": "MARUDALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1463827,
            29.1578998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "BPZ",
          "name": "BAZPUR",
          "zone": "NER",
          "address": "Bazpur, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4109744,
            28.3372843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRY",
          "name": "BAREILLY",
          "zone": "NER",
          "address": "Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.91224299999999,
            24.747459000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BQZ",
          "name": "BALLALPUR",
          "zone": "ER",
          "address": "Farakka, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5492466,
            30.370396799999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BNN",
          "name": "BARNALA",
          "zone": "NR",
          "address": "Barnala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.92378099999999,
            29.344605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BNJL",
          "name": "BINJHOL HALT",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.140955,
            21.168195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAV",
          "name": "KALAMNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.92503,
            22.245963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BNH",
          "name": "BHIMNATH",
          "zone": "WR",
          "address": "Bhimnath, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.79885780000001,
            21.9330444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BNVD",
          "name": "BHANVAD",
          "zone": "WR",
          "address": "Bhanvad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.344106,
            24.684059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BRGM",
          "name": "Baraigram Junction",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.98289000000001,
            23.179178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BSPR",
          "name": "BISHRAMPUR",
          "zone": "SECR",
          "address": "District Sarguja, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.5298836,
            23.317286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BRH",
          "name": "BAIKUNTHPUR RD",
          "zone": "SECR",
          "address": "NH 78, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.292976,
            21.236542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAL",
          "name": "SALWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.56730199046433,
            13.987012001486875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BTKL",
          "name": "Bhatkal",
          "zone": "KR",
          "address": "Bhatkal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.41467,
            21.256602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAR",
          "name": "THARSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.75141900000001,
            21.342437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMR",
          "name": "TUMSAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.058885,
            21.443153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJ",
          "name": "GANGAJHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.859791,
            29.343806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BNSR",
          "name": "BHAGWANSAR",
          "zone": "NWR",
          "address": "Bhagwan Sar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.693768,
            21.183732000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PJB",
          "name": "PANIAJOB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.245745,
            12.974779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THL",
          "name": "TIRUVALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.70970576,
            30.85173825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BQH",
          "name": "Bhanohad Punjab",
          "zone": "NR",
          "address": "Bhanohad, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.189821,
            12.971785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVUR",
          "name": "SEVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.13534100000001,
            12.972734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPD",
          "name": "KATPADI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.943488,
            25.340728000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BPRA",
          "name": "BARIPURA",
          "zone": "NCR",
          "address": "National Highway 86, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.757365,
            12.822656
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCKM",
          "name": "PACHACHAKUPAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BRPM",
          "name": "Baripur Mandala",
          "zone": "WR",
          "address": "Mandala, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BRTL",
          "name": "Bharthali",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.759857,
            21.185492
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGG",
          "name": "DONGARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.73709000000001,
            21.9227408
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BPO",
          "name": "Baripada",
          "zone": "SER",
          "address": "Baripada, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.909158,
            22.401548000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BO",
          "name": "Borsad",
          "zone": "WR",
          "address": "Borsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.821965,
            21.1798
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTR",
          "name": "JATKANHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8954879211,
            28.067563416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BS",
          "name": "Banisar",
          "zone": "NWR",
          "address": "Benisar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3072684,
            25.1611771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ATH",
          "name": "ANTAH",
          "zone": "WCR",
          "address": "Antah, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1976736633,
            25.171061136400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BON",
          "name": "BHONRA",
          "zone": "WCR",
          "address": "Kota, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.71272400000001,
            21.171626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BOV",
          "name": "Bhatgaon",
          "zone": "SECR",
          "address": "Bhatgaon, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.879372,
            21.152966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUA",
          "name": "MUSRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.6184701,
            13.001479999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNCE",
          "name": "BANGALORE EAST",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNDV",
          "name": "KNDV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.431647,
            22.366657999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BRP",
          "name": "Baruipur Junction",
          "zone": "ER",
          "address": "Baruipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.683783,
            26.562635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AAH",
          "name": "ITEHAR",
          "zone": "NCR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.38188099999999,
            26.456937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RYT",
          "name": "RAYAT PURA",
          "zone": "NCR",
          "address": "National Highway 92, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.181356,
            26.30595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BBY",
          "name": "BHADROLI",
          "zone": "NCR",
          "address": "Gwalior, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.940946,
            21.123637
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAKL",
          "name": "BAKAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.48266943,
            23.236313600000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BTKB",
          "name": "Bathna Krittibas Halt",
          "zone": "ER",
          "address": "Bathna Krittibas, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.97815200000001,
            27.154613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GD",
          "name": "GONDA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8877858557,
            23.385124400800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "AKZ",
          "name": "Akashi",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.364108,
            26.910367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BR",
          "name": "BANDH BARETA",
          "zone": "NCR",
          "address": "Dumariya, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.264748,
            28.512293999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAN",
          "name": "DHANETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.672178,
            26.001213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CKYD",
          "name": "CHAKRA ROAD",
          "zone": "NER",
          "address": "Chakara Road Halt, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.14815399999999,
            21.183141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUP",
          "name": "MURHIPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.3810615,
            17.0490546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BOKE",
          "name": "BHOKE",
          "zone": "KR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2164,
            21.204118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSM",
          "name": "RASMARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3769414349,
            20.7292951895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHQ",
          "name": "PARDHANDE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.34831,
            20.668385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PC",
          "name": "PACHORA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.045099,
            24.388941000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "BSKH",
          "name": "Basukinath",
          "zone": "ER",
          "address": "Basukinath, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3312086395,
            23.4987569498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BSKN",
          "name": "Bhesana Manknaj",
          "zone": "WR",
          "address": "State Highway 41, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.27700034419999,
            20.6095998065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAA",
          "name": "GALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1981061108,
            20.6030016547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGD",
          "name": "NAGARDEVLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.751085,
            19.817370999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AV",
          "name": "ASVALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.66573299999999,
            19.783928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PI",
          "name": "PADLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.63594400000001,
            19.722911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GO",
          "name": "GHOTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.56237899999999,
            19.694053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IGP",
          "name": "IGATPURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.158611,
            19.244702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHAD",
          "name": "SHAHAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.85877285,
            28.73653776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ASE",
          "name": "ASAUDAH",
          "zone": "NR",
          "address": "Delhi-Rohtak Corridor, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.629155,
            28.857765
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ABO",
          "name": "ASTHAL BOHAR",
          "zone": "NR",
          "address": "Rohtak, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4289531995,
            29.0805810005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KZH",
          "name": "KILA ZAFARGARH",
          "zone": "NR",
          "address": "NH 71, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4743493237,
            29.845590837699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BNQL",
          "name": "BHANKALA HALT",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.07468750000001,
            23.224387099999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BQA",
          "name": "BANKURA",
          "zone": "SER",
          "address": "Bankura, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.424988,
            26.552056
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRLI",
          "name": "Barauli",
          "zone": "NCR",
          "address": "National Highway 11B, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.694138,
            22.115136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BPRH",
          "name": "Balpur Halt",
          "zone": "SECR",
          "address": "Umreli, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.193664,
            25.885092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BNKI",
          "name": "Banmankhi Junction",
          "zone": "ECR",
          "address": "Purnia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2889459058,
            13.2611631708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AIP",
          "name": "ATTIPPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.26019199999999,
            13.281979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJR",
          "name": "MINJUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.301496,
            22.517861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BRJ",
          "name": "Brace Bridge",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.38341799999999,
            24.607593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BZGT",
          "name": "Bazurghat",
          "zone": "NFR",
          "address": "Bazurghat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.59591800000001,
            24.754753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "ALGP",
          "name": "Algapur",
          "zone": "?",
          "address": "Hailakandi, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.06701699999999,
            23.264582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAN",
          "name": "TARANA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.12218,
            30.175451000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "DWO",
          "name": "DOIWALA",
          "zone": "NR",
          "address": "Dehradun, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.14079000000001,
            28.613526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "BRSQ",
          "name": "BRAR SQUARE",
          "zone": "NR",
          "address": "Delhi Cantonment, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.736988,
            29.273712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KAT",
          "name": "KHATAULI",
          "zone": "NR",
          "address": "Khatauli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.0612084,
            28.2401014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BUB",
          "name": "Bissau",
          "zone": "NWR",
          "address": "Bissau, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.698335,
            29.05601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PQY",
          "name": "PABLI KHAS",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.455792,
            28.703751999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GUH",
          "name": "GULDHAR",
          "zone": "NR",
          "address": "Ghaziabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.766138,
            20.122424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BRRD",
          "name": "BORDI ROAD",
          "zone": "WR",
          "address": "Bordi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.24239742,
            27.151418149999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BRU",
          "name": "BHAIROGANJ",
          "zone": "ECR",
          "address": "Bhairoganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.5229561,
            26.8058847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BTH",
          "name": "BETTIAH",
          "zone": "ECR",
          "address": "Bettiah, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.202946,
            20.891974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKU",
          "name": "MALKAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.06039,
            24.036488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BSDL",
          "name": "BHARSENDI",
          "zone": "WCR",
          "address": "Sidhi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8482415,
            28.3919187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSC",
          "name": "Bulandshahr",
          "zone": "NR",
          "address": "Bulandshahr, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.573321,
            22.350122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BTPG",
          "name": "Betheria Ghola",
          "zone": "ER",
          "address": "Betheria Ghola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.573657,
            17.543986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BTPD",
          "name": "Bethampurdi",
          "zone": "SCR",
          "address": "Kothagudem To Bhadrachalam Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2851956,
            28.288009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTG",
          "name": "BASHARATGANJ",
          "zone": "NR",
          "address": "Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.954044,
            30.19088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BTIC",
          "name": "BHATINDA CANTT",
          "zone": "NR",
          "address": "State Highway 17, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.72074952000001,
            30.377724999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "ALL",
          "name": "ALAL",
          "zone": "NR",
          "address": "Mullowal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.279846955,
            20.8885446711
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WADO",
          "name": "WADODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.401107,
            20.866943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJL",
          "name": "KHUMGAON BURTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.46015799999999,
            20.838889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NN",
          "name": "NANDURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.6655966,
            22.182870599999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BTD",
          "name": "BOTAD JN",
          "zone": "WR",
          "address": "Botad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.575644,
            27.149686000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BU",
          "name": "BASWA",
          "zone": "NWR",
          "address": "Alwar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.005497,
            20.723084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AK",
          "name": "AKOLA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.580751,
            21.271299000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKS",
          "name": "DAREKASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.23897337168455,
            29.79175066957987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BUGL",
          "name": "Buglanwali",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.938506,
            18.86512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BSX",
          "name": "BASAR",
          "zone": "SCR",
          "address": "Basara, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.742678,
            30.862116999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BWZ",
          "name": "Baddowal",
          "zone": "NR",
          "address": "Baddowa, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.060902,
            20.576054000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BSQ",
          "name": "BARSI TAKLI",
          "zone": "SCR",
          "address": "Dist. Akola, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.642772,
            25.705583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PARH",
          "name": "PARAKHEDA",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1953108,
            26.237555
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLNR",
          "name": "BIRLANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.875125,
            22.995932999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BUQ",
          "name": "Bargi",
          "zone": "SECR",
          "address": "Bargi Nagar - Ghansore Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.96285300000001,
            26.569564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIKD",
          "name": "SIKRODA KWANRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.2116131934,
            24.8477620899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BSJ",
          "name": "Bhupalsagar",
          "zone": "NWR",
          "address": "Rajsamand, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.434731,
            22.676284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BRPK",
          "name": "Bisharpara Kodaliya",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.936903,
            9.467438999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TY",
          "name": "TULUKAPATI",
          "zone": "SR",
          "address": "National Highway 7, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.13056325,
            22.12030128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BYSA",
          "name": "Basulyasutahata",
          "zone": "SER",
          "address": "Haldia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.4500584753,
            25.164270416300003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRG",
          "name": "BARGARH",
          "zone": "NCR",
          "address": "Kaushambi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.472003,
            19.647159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSRA",
          "name": "KASARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.422651,
            19.628026000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OMB",
          "name": "OOMBERMALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.446664,
            25.100178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MH",
          "name": "Marahra",
          "zone": "CR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.393442,
            19.579952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KE",
          "name": "KHARDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.35181299999999,
            19.549981
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THS",
          "name": "THANSIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.40197699999999,
            30.452385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BSGR",
          "name": "Bhagsar",
          "zone": "NR",
          "address": "Ramgarh Chungha, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.3276912396,
            19.5031813038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATG",
          "name": "ATGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.307859,
            19.439277999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASO",
          "name": "ASANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.26768899999999,
            19.406422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VSD",
          "name": "VASIND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.21871043,
            19.35621892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDV",
          "name": "KHADAVLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.17171,
            19.267587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABY",
          "name": "AMBIVLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.041905,
            19.188394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIVA",
          "name": "DIVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.839733,
            18.962311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNRD",
          "name": "MUMBAI SANDHURST ROAD",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.999562,
            31.177139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BXB",
          "name": "Banga",
          "zone": "NR",
          "address": "Nawan Shahar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.97541700000001,
            19.185757000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNA",
          "name": "THANE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.19313199999999,
            30.548939999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BVW",
          "name": "Bahminiwala",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.956933,
            19.172355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLND",
          "name": "MULUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.102675,
            27.462683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUF",
          "name": "KAURHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.19623499999999,
            27.32161
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAR",
          "name": "KARNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.946683,
            19.154646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHU",
          "name": "NAHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.8435187,
            21.741339999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BTQ",
          "name": "Betnoti",
          "zone": "SER",
          "address": "Betnoti, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.659317,
            13.265332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BSN",
          "name": "BANASANDRA",
          "zone": "SWR",
          "address": "SH19, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.136306,
            25.035574999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MOI",
          "name": "MORI BERA",
          "zone": "NWR",
          "address": "Nana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.192517,
            25.170546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BRLY",
          "name": "BIROLIYA",
          "zone": "NWR",
          "address": "Biroliya Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0953188199,
            25.921475609399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RT",
          "name": "ROHAT",
          "zone": "NWR",
          "address": "SH 64, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82814753,
            16.47986853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BSRX",
          "name": "BAGEVADI RD",
          "zone": "SWR",
          "address": "Bagevadi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.299606,
            13.249326
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AIPP",
          "name": "ATTIPPATTU PUDU NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.66684099999999,
            31.058267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BZG",
          "name": "Bilga",
          "zone": "NR",
          "address": "Bilga, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.14841382110001,
            22.5718124104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LMC",
          "name": "LAKHAMANCHI",
          "zone": "WR",
          "address": "State Highway 113, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.6920701,
            24.4892623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "BDME",
          "name": "Baidyanathdham Deoghar",
          "zone": "ER",
          "address": "Deoghar, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.718169,
            18.240993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BTW",
          "name": "BARSI TOWN",
          "zone": "CR",
          "address": "Barshi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0302621,
            22.255445899999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BWW",
          "name": "Barwaha",
          "zone": "WR",
          "address": "Barwah, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.003772,
            32.14165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "JWLS",
          "name": "JAWAN WALA SHEHAR",
          "zone": "NR",
          "address": "Jawan Wala Shehar, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.926702,
            21.399697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIM",
          "name": "KIM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDLD",
          "name": "Chandlodiya",
          "zone": "WR",
          "address": "Ahmadabad, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CDMA",
          "name": "Chandeshwarsthan",
          "zone": "ECR",
          "address": "Chandeshwarsthan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.035087,
            9.981546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SER",
          "name": "SAMAYANALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.45218163914979,
            21.99195697308774
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDD",
          "name": "Chandod",
          "zone": "WR",
          "address": "State Highway 160, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.123182,
            22.028156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPH",
          "name": "DADHAPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.29651100000001,
            22.034344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRMG",
          "name": "JAIRAMNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.136829,
            20.122591999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UGN",
          "name": "UGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.62797752581537,
            26.076284459879247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BXT",
          "name": "Bamanhat",
          "zone": "NFR",
          "address": "Kurigram Nageshwari Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.80071699999999,
            25.069785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BXK",
          "name": "BANDARKHAL",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.849153,
            30.225526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BWX",
          "name": "BAHMAN DIWANA",
          "zone": "NR",
          "address": "Behman Diwana, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.22659200000001,
            25.566931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BYHA",
          "name": "BARYA RAM",
          "zone": "NR",
          "address": "THAKUR Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.171177,
            22.367273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CRK",
          "name": "CHARKHERA",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3208,
            22.485657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BUTA",
          "name": "BHADUTALA",
          "zone": "SER",
          "address": "Lalgarh-Medinipur Road, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.340588,
            22.739076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CDGR",
          "name": "CHANDRAKONA RD",
          "zone": "SER",
          "address": "Chandrakona Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.231711,
            29.394379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BZO",
          "name": "BARSOLA",
          "zone": "NR",
          "address": "District Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.985472,
            10.35381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DG",
          "name": "DINDIGUL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.951415,
            10.317775999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VO",
          "name": "VELLODU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.924468,
            10.272226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABI",
          "name": "AMBATURAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.909593,
            10.179613000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQN",
          "name": "KODAIKANAL ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.945674,
            10.086114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDP",
          "name": "VADIPPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.37251094,
            24.18394723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDQ",
          "name": "CHADOTAR",
          "zone": "WR",
          "address": "National Highway 14, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.572402,
            28.070281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BWL",
          "name": "BAWAL",
          "zone": "NWR",
          "address": "Bawal, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.516853,
            16.547348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BVRT",
          "name": "BHIMAVARAM TOWN",
          "zone": "SCR",
          "address": "Bhimavaram, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.787801,
            25.895725000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BQN",
          "name": "BAGRI NAGAR",
          "zone": "NWR",
          "address": "Marwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.94839300000001,
            10.044464999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYR",
          "name": "KARUPPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.96508200000001,
            10.02227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDN",
          "name": "SHOLAVANDAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CIO",
          "name": "Chikhli Road",
          "zone": "WR",
          "address": "SH 179, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.066991,
            27.130045000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BUG",
          "name": "BAGAHA",
          "zone": "ECR",
          "address": "Bagaha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.53063900000001,
            26.94163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CAI",
          "name": "CHANPATIA",
          "zone": "ECR",
          "address": "Sikta-Mainatad Rd, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.04636,
            26.417182
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CAA",
          "name": "CHAKIA",
          "zone": "ECR",
          "address": "Chakia, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "CKNA",
          "name": "Chikna",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.915268,
            28.379381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BXM",
          "name": "BELRAYAN",
          "zone": "NER",
          "address": "Tikapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.51678100000001,
            17.008048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BVQ",
          "name": "BHILAVDI",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.559763,
            23.481082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ANR",
          "name": "ANARA",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.44328689999999,
            27.9104715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BWPL",
          "name": "Balwant Pura Chelasi",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.73716,
            21.015051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "CATD",
          "name": "Chataud",
          "zone": "SECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.287402,
            22.411417999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CID",
          "name": "Chiraidongri",
          "zone": "SECR",
          "address": "Chiraidongri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8082265295,
            28.832108335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGFR",
          "name": "KATHGHAR RIGHT BANK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8095,
            28.832685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGFE",
          "name": "KATHGHAR EAST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.992655,
            25.732598999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BHGJ",
          "name": "Bihariganj",
          "zone": "ECR",
          "address": "Kishanganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.444628,
            21.620099999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HTGR",
          "name": "HATHIGADH",
          "zone": "WR",
          "address": "SH 115, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.287381,
            24.515852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CHBN",
          "name": "Chandkhira Bagn",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-MMRX",
          "name": "YY-MMRX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.628737,
            10.87109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MKY",
          "name": "MEKKUDI",
          "zone": "SR",
          "address": "Nagappattinam - Trichy - Karur- Coimbatore - Mettupalayam - Ooty - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.16411699999999,
            28.612475
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIL",
          "name": "MILAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.205555,
            28.550179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRS",
          "name": "NAGARIA SADAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.88703100000001,
            29.367534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BXC",
          "name": "Barwala",
          "zone": "NR",
          "address": "Barwala, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.352497,
            27.167362999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLM",
          "name": "BALAMU JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.473465,
            27.086986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UTA",
          "name": "UMAR TALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.946241,
            25.352227999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CBG",
          "name": "Charbhuja Road",
          "zone": "NWR",
          "address": "Amet, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.433196,
            14.316931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HNA",
          "name": "HONNAVAR",
          "zone": "KR",
          "address": "Honavar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6840119482,
            13.717680182199999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SEN",
          "name": "SENAPURA",
          "zone": "KR",
          "address": "Udupi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.416794,
            24.845883999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CGX",
          "name": "Chargola",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0445917407,
            10.4023417558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMP",
          "name": "TAMARAIPADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1021599634,
            10.4363355768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDM",
          "name": "VADAMADURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.157049,
            10.483391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AYR",
          "name": "AYYALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2343675623,
            10.518065672899999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFC",
          "name": "KALPATTICHATRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3098143988,
            10.544055019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPJ",
          "name": "VALYAMPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3662318384,
            10.575644554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CII",
          "name": "CHETTIYAPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.85303900000001,
            19.311766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BYR",
          "name": "BHAYANDAR",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.38933899999999,
            23.709061000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHU",
          "name": "BHANDU MOTIDAU",
          "zone": "WR",
          "address": "Mahesana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.667458,
            22.313264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CG",
          "name": "Canning",
          "zone": "ER",
          "address": "Canning, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.11878948120001,
            22.2299461588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "CGLA",
          "name": "Changotola",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.636651,
            32.259653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CHKB",
          "name": "CHAKKI BANK",
          "zone": "NR",
          "address": "Pathankot, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2506963452,
            25.273607371900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAR",
          "name": "Junair Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.780268,
            28.458626000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CH",
          "name": "CHANDAUSI JN",
          "zone": "NR",
          "address": "Chandausi, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSLX",
          "name": "BSLX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.949119,
            20.687336000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DGI",
          "name": "DUNGRI",
          "zone": "WR",
          "address": "NH 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.33919345,
            20.885157149999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIS",
          "name": "BISWA BRIDGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.07497000000001,
            20.724559000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YAD",
          "name": "YEULKHED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0478709076,
            26.8568018382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKT",
          "name": "Kath Kuiyan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1796502,
            20.7290593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGN",
          "name": "BORGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.258143,
            31.237553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DDY",
          "name": "DUDWINDI",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.25938099999999,
            20.723777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTP",
          "name": "KATEPURNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3431299774,
            20.2117506011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUM",
          "name": "SUMMIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.22597599999999,
            20.154847999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LS",
          "name": "LASALGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0336011,
            30.3140377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "DDN",
          "name": "DEHRADUN",
          "zone": "NR",
          "address": "Dehradun, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.35482499999999,
            20.736468000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZR",
          "name": "MURTAJAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.943986,
            25.212948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CDSL",
          "name": "CHANDRESAL",
          "zone": "WCR",
          "address": "Kota, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.82208,
            30.7019773
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chandigarh",
          "code": "CDG",
          "name": "CHANDIGARH",
          "zone": "NR",
          "address": "Darua, Chandigarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.469063,
            20.761491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MANA",
          "name": "MANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.52258400000001,
            20.78015
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNDA",
          "name": "MANDURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.69990700000001,
            26.926388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CPJ",
          "name": "KAPTANGANJ JN",
          "zone": "NER",
          "address": "Captainganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.051843,
            27.15958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "AWS",
          "name": "ASWANI HALT",
          "zone": "ECR",
          "address": "Bagaha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.58733000000001,
            20.808396000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUM",
          "name": "KURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.65695,
            20.833253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKI",
          "name": "TAKLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.79835,
            26.885454000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKJ",
          "name": "KAKORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.909614,
            26.829443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNWT",
          "name": "LUCKNOW WEST (ALH)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.4087656586,
            25.6683593565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DES",
          "name": "DESARI",
          "zone": "ECR",
          "address": "Vaishali, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.079481,
            20.096584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NR",
          "name": "NIPHAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73231969999999,
            20.8567925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BD",
          "name": "BADNERA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.75968040000001,
            13.477458799999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BKJ",
          "name": "BARKUR",
          "zone": "KR",
          "address": "Udupi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.04278599999999,
            12.385524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "BFR",
          "name": "BEKAL FORT",
          "zone": "SR",
          "address": "Pallikere, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.737851,
            18.987443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DMK",
          "name": "Dilmili",
          "zone": "ECoR",
          "address": "National Highway 16, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.69712126,
            24.51178242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "DGHR",
          "name": "Deoghar",
          "zone": "ER",
          "address": "Deoghar, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.99049337,
            12.98251226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ALUR",
          "name": "Alur Halt",
          "zone": "SWR",
          "address": "Hassan - Alur Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.79476000000001,
            20.812496
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMT",
          "name": "TIMTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.862831,
            20.821793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLR",
          "name": "MALKHED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9821558,
            20.8045192
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CND",
          "name": "CHANDUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.040981,
            20.79317
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIP",
          "name": "DIPORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.138054,
            20.784674000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMN",
          "name": "DHAMANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.230026,
            20.754248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLN",
          "name": "TALNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.318111,
            20.726734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLO",
          "name": "PULGAON JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.376203,
            20.737041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAOT",
          "name": "KAOTHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.463093,
            20.729315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAE",
          "name": "DAHEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.594157,
            20.732777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WR",
          "name": "WARDHA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.619225,
            20.739076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEGM",
          "name": "SEVAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65067138549999,
            20.7471655405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VADR",
          "name": "WARUD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.808237,
            20.800701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGP",
          "name": "TULJAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.97444766000001,
            20.860176040000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOK",
          "name": "BORKHEDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.01362896,
            20.91847276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTBR",
          "name": "BUTI BORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.88002100000001,
            30.445148
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CBX",
          "name": "CHAND BHAN",
          "zone": "NR",
          "address": "Jaitu, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.030314,
            20.993595999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMG",
          "name": "GUMGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.57136412999999,
            15.44947863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DEV",
          "name": "DEVARAYI",
          "zone": "SWR",
          "address": "State Highway 34, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.048839,
            21.049338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRI",
          "name": "KHAPTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0825197485,
            21.1268588122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJNI",
          "name": "AJNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.197947,
            21.21172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KP",
          "name": "KAMPTEE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.13891000000001,
            22.486845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGA",
          "name": "CHENGEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.676645,
            21.573844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DGBZ",
          "name": "Dongri Buzurg",
          "zone": "SECR",
          "address": "State Highway 251, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.1635216052,
            22.5035911202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVA",
          "name": "BAURIA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.185699,
            22.529208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NALR",
          "name": "NALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.32451142,
            27.48655053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DEEG",
          "name": "Deeg",
          "zone": "NCR",
          "address": "Bharatpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.19939199999999,
            22.547877
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABB",
          "name": "ABADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.2090743463,
            22.5613094942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEL",
          "name": "SANKRALL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.239643,
            22.575169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADL",
          "name": "ANDUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.284011,
            22.58393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRC",
          "name": "SANTRAGACHI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.295438,
            22.587778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMJ",
          "name": "RAMRAJATALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.419,
            22.650758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DGNR",
          "name": "Durganagar",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4177652356,
            10.6075620105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPA",
          "name": "MANAPARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.926117,
            26.354394000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BHGN",
          "name": "BHILGAON",
          "zone": "NFR",
          "address": "National Highway 39, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.493545,
            10.652687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMDM",
          "name": "SAMUDRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.547696,
            10.700678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLS",
          "name": "KOLATUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.1720581342,
            27.481420709000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CHB",
          "name": "CHABUA",
          "zone": "NFR",
          "address": "Chabua, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.705156,
            27.977317000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DEO",
          "name": "DEOKALI",
          "zone": "NER",
          "address": "State Highway 90, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.35904533,
            32.46131096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "CHNR",
          "name": "CHHAN ARORIAN",
          "zone": "NR",
          "address": "Chhan Arorian, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.432931,
            32.440429
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "BDHY",
          "name": "BUDHI",
          "zone": "NR",
          "address": "Kathua, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.623498,
            32.083103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CKKN",
          "name": "CHAK KALAN",
          "zone": "NR",
          "address": "National Highway 1A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.61231335209999,
            32.0195148161
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BNGL",
          "name": "BHANGALA",
          "zone": "NR",
          "address": "National Highway 1A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.529672,
            23.078406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CDH",
          "name": "Chakdaha",
          "zone": "ER",
          "address": "Chakdaha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.639894,
            31.617259999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CGH",
          "name": "CHOLANG",
          "zone": "NR",
          "address": "Adda Cholang, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.859587,
            19.250166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DIC",
          "name": "DAHISAR",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.271993,
            31.902255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CHN",
          "name": "Chhina",
          "zone": "NR",
          "address": "Ghuman Kalan Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.136281,
            22.831668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MLYA",
          "name": "Maliya",
          "zone": "ER",
          "address": "Maliya, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.392178,
            22.290179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DMU",
          "name": "Dhamua",
          "zone": "ER",
          "address": "Sherpur Dhamua Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.104438,
            23.230256999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "DON",
          "name": "DERAWAN HALT",
          "zone": "SER",
          "address": "Derawan, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.167585,
            22.057473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSP",
          "name": "BILASPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DMJ",
          "name": "Dharmaj",
          "zone": "WR",
          "address": "State Highway 6, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.210494,
            19.916367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DLB",
          "name": "DAULATABAD",
          "zone": "SCR",
          "address": "Maharashtra., Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.599522,
            25.590821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DHT",
          "name": "DHAMARA GHAT",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.9887134,
            17.120967200000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DLGN",
          "name": "DHALGAON",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.10298900000001,
            18.353216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DKY",
          "name": "DHOKI",
          "zone": "CR",
          "address": "Dhoki Town, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.75084299999999,
            32.289064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DLSR",
          "name": "DALHOGI ROAD",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.87109099999999,
            26.283979000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DRBR",
          "name": "Deoraha Baba Road",
          "zone": "NER",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.423264,
            25.799898
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DLR",
          "name": "DULLAHAPUR",
          "zone": "NER",
          "address": "SH 67, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.477969,
            21.226612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DCAB",
          "name": "DCAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.126587,
            25.427907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DKE",
          "name": "DIHAKHO",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.499084,
            21.236052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CABN",
          "name": "BHILAI CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.658334,
            23.799131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DNEA",
          "name": "DANEA",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.88112287595868,
            29.182572629698868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DPLN",
          "name": "Diplana",
          "zone": "NWR",
          "address": "State Highway 36, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.516054,
            21.243782
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMI",
          "name": "KUMHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.953112,
            22.39911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DOW",
          "name": "Davol",
          "zone": "WR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.09925299999999,
            24.592205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DHR",
          "name": "DHARNAODA",
          "zone": "WCR",
          "address": "Dharnawada, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.5736163281,
            21.2532719083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SZB",
          "name": "SARONA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8075098584,
            30.5176409515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DHPR",
          "name": "DAPPAR",
          "zone": "NR",
          "address": "National Highway 22, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.629328,
            21.256462000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "R",
          "name": "RAIPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.639183,
            21.277967999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WRC",
          "name": "W R S COLONY PH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.644569,
            21.289034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URK",
          "name": "URKURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.533639,
            26.576993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DUMR",
          "name": "Dumra",
          "zone": "ECR",
          "address": "NH 77, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1796003,
            24.237125199999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DISA",
          "name": "DISA",
          "zone": "WR",
          "address": "Disa, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.42156278,
            16.58967159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CKV",
          "name": "CHERUKUVADA",
          "zone": "SCR",
          "address": "Cherukawada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.925291,
            26.830675
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKO",
          "name": "LUCKNOW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.228356,
            28.661815999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DLI",
          "name": "OLD DELHI",
          "zone": "NR",
          "address": "Shyama Prasad Mukherji Marg, Delhi NCT"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DOLK",
          "name": "Dholikua",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.704606,
            21.33807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDH",
          "name": "MANDHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.755534,
            21.426336000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLH",
          "name": "SILIARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.7800294,
            21.4976724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKTH",
          "name": "BAIKUNTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.15435,
            12.213321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CHV",
          "name": "CHARVATTUR",
          "zone": "SR",
          "address": "Kasaragod district, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.168292,
            12.179818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CDRA",
          "name": "CHANDERA",
          "zone": "SR",
          "address": "Kasaragod, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.077189,
            21.961973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYL",
          "name": "BELHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.620151,
            10.748083000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUG",
          "name": "PUNGGUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.685355,
            10.794067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPJ",
          "name": "TIRUCHIRAPPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.112168,
            22.003082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHBT",
          "name": "CHAKARBHATHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8399187915,
            27.733596247399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DTRD",
          "name": "Diyatara Road",
          "zone": "?",
          "address": "Diyatra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.94878100000001,
            21.815777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IB",
          "name": "IB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bangladesh",
          "code": "DSNB",
          "name": "Darsana Border",
          "zone": "?",
          "address": "Chuadanga, Bangladesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.32845599999999,
            22.341431999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGP",
          "name": "KHARAGPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.11604889590001,
            22.4778756365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FLR",
          "name": "PHULESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5868746,
            26.0707762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DHH",
          "name": "Dinhata",
          "zone": "NFR",
          "address": "Dinhata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.399338,
            22.308871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DKDP",
          "name": "Dakshin Durgapur",
          "zone": "ER",
          "address": "Dakshin Durgapur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3332302,
            26.8521353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DJRZ",
          "name": "To Darjeeling",
          "zone": "NFR",
          "address": "Darjeeling, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.30695200000001,
            27.367712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DJG",
          "name": "DULIAJAN",
          "zone": "NFR",
          "address": "Duliajan, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.0806742816,
            27.4642265659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DKM",
          "name": "DIKOM",
          "zone": "NFR",
          "address": "A T Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.9869386938,
            25.6464732123
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DHNA",
          "name": "Dhachna",
          "zone": "NFR",
          "address": "National Highway 34, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3141277319,
            24.929548151899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTIN",
          "name": "Jethian",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DURP",
          "name": "Durgapuri",
          "zone": "NCR",
          "address": "Khojeepura, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.16824700000001,
            24.366946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "DMR",
          "name": "Dharmanagar",
          "zone": "NFR",
          "address": "Dharmanagar, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.459855,
            22.315994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DPDP",
          "name": "Dhapdhapi",
          "zone": "ER",
          "address": "Dhapdhapi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6579341054894,
            29.44410399265112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ENB",
          "name": "Ellenabad",
          "zone": "NWR",
          "address": "State Highway 23, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.477671,
            24.505297000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DLCR",
          "name": "Dullabcherra",
          "zone": "NFR",
          "address": "Anipur Nivia Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.86207,
            21.706411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DVH",
          "name": "Devi Halt",
          "zone": "SECR",
          "address": "Devi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.61368900000001,
            26.444446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DGX",
          "name": "Dagaon",
          "zone": "NFR",
          "address": "Mathaouri Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.32336400000001,
            28.579787000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DKWA",
          "name": "Dokwa",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2042468,
            13.08842214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DUH",
          "name": "Dudda",
          "zone": "SWR",
          "address": "Dudda, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.768847,
            20.887752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DHI",
          "name": "Dhule",
          "zone": "CR",
          "address": "Dhule, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.441145,
            23.483546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DHU",
          "name": "Dhubulia",
          "zone": "ER",
          "address": "Nadia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.73412167570001,
            12.9040478098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DOGL",
          "name": "Donigal",
          "zone": "SWR",
          "address": "Mangalore - Bangalore Hwy, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.35638399999999,
            10.944384000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "DSM",
          "name": "DARASURAM",
          "zone": "SR",
          "address": "Kumbakonam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.051191,
            22.977556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DWX",
          "name": "DEWAS",
          "zone": "WR",
          "address": "Dewas, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.796892,
            25.895799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DOR",
          "name": "DUNDARA",
          "zone": "NWR",
          "address": "Dhundhara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.782638,
            25.908513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DMH",
          "name": "Dauram Madhepura",
          "zone": "ECR",
          "address": "Madhepura, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.25159995444119,
            29.76082671390587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DPK",
          "name": "Dholipal",
          "zone": "NWR",
          "address": "Dholipal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.653432493,
            26.411579930800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AHO",
          "name": "ADARSHNAGAR",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.467351,
            32.134789
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DNN",
          "name": "Dina Nagar",
          "zone": "NR",
          "address": "Dinanagar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.74651899999999,
            18.365186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "DPC",
          "name": "Darliput",
          "zone": "ECoR",
          "address": "Darliput, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            68.980434,
            22.251023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DWK",
          "name": "DWARKA",
          "zone": "WR",
          "address": "Dwarka, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.039019,
            21.101186000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJN",
          "name": "RAJ NANDGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DWM",
          "name": "Darwha Moti Bagh Junction",
          "zone": "CR",
          "address": "Darwha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.619546,
            26.395604000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DOZ",
          "name": "DAURAI",
          "zone": "NWR",
          "address": "Madar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.09869,
            21.140859000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMS",
          "name": "PARMALKASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4585689563,
            30.3792190643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DOC",
          "name": "Daun Kalan",
          "zone": "NR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.35973,
            21.15958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "MXA",
          "name": "MARAUDA",
          "zone": "SECR",
          "address": "Bhilai, Chhattisgarh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ER08",
          "name": "Kalinagar",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.009932,
            25.039892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BQF",
          "name": "BARAHMUAFI",
          "zone": "WCR",
          "address": "Uttar Pradesh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.009808,
            23.298434999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DOE",
          "name": "DEORI",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8264017633,
            22.0812732116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DRHI",
          "name": "DAGARHKERI",
          "zone": "WCR",
          "address": "State Highway 15, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.36406699999999,
            21.641314
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DGN",
          "name": "DONGARGAON",
          "zone": "CR",
          "address": "Borgaon, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.02714900000001,
            20.072296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBSN",
          "name": "KASBE-SUKENE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.973978,
            20.046948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KW",
          "name": "KHERVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.910456,
            20.01601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ODHA",
          "name": "ODHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.841897,
            19.947627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NK",
          "name": "NASIK ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.815119,
            19.852905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LT",
          "name": "LAHAVIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.928041,
            19.128181
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJMG",
          "name": "KANJUR MARG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.908591,
            19.085984999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GC",
          "name": "GHATKOPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.89173899999999,
            19.070320000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LTT",
          "name": "LOKMANYA TILAK TERM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.805352,
            24.606987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DRB",
          "name": "Debari",
          "zone": "NWR",
          "address": "Debari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.293521,
            28.846965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FAP",
          "name": "FAKHARPUR HALT",
          "zone": "NR",
          "address": "Fakharpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.293154,
            28.673098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DSA",
          "name": "DELHI SHAHDARA",
          "zone": "NR",
          "address": "Circular Rd, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.60480199999999,
            25.600279999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GZT",
          "name": "GHAZIPUR GHAT",
          "zone": "NER",
          "address": "Ghazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.54751,
            10.889271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PGN",
          "name": "PERUGAMANI",
          "zone": "SR",
          "address": "Nagappattinam - Trichy - Karur- Coimbatore - Mettupalayam - Ooty - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.72781599999999,
            25.905701999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Nagaland",
          "code": "DMV",
          "name": "DIMAPUR",
          "zone": "NFR",
          "address": "Dimapur, Nagaland"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.31403358,
            15.31333714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DWF",
          "name": "DUDH SAGAR WATER FALLS",
          "zone": "SWR",
          "address": "National Highway 4A, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.026016,
            18.455828999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "FSG",
          "name": "PHURSUNGI",
          "zone": "CR",
          "address": "Phursungi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.49634,
            23.229896999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "FLU",
          "name": "Phulia",
          "zone": "ER",
          "address": "Fulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.59273,
            22.167964
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "DPS",
          "name": "Dangoaposi",
          "zone": "SER",
          "address": "Nurda, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.136032,
            22.066831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DZKT",
          "name": "Durga Chak Town",
          "zone": "SER",
          "address": "Haldia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.835426,
            19.00694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "EPR",
          "name": "Mumbai Elphinstone Road",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.33263099999999,
            24.665398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "ELL",
          "name": "Eraligu",
          "zone": "NFR",
          "address": "Eraligu, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.668324,
            25.419424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLN",
          "name": "DILDARNAGAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2908951074,
            25.4345036206
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DNWH",
          "name": "Daniyawan Bazar Halt",
          "zone": "?",
          "address": "Daniawan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.511544,
            25.252404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AHM",
          "name": "AHIMANPUR",
          "zone": "NER",
          "address": "St Ravidas Nagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.23495299999999,
            25.235808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FA",
          "name": "FALNA",
          "zone": "NWR",
          "address": "Falna, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.52193700000001,
            30.841607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DODH",
          "name": "Dodh",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.87998999999999,
            25.44265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DRGJ",
          "name": "DARAGANJ",
          "zone": "NER",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.032364,
            24.506528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DQN",
          "name": "Dhanera",
          "zone": "NWR",
          "address": "Dhanera, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.254975,
            24.289527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "DUMK",
          "name": "Dumka",
          "zone": "ER",
          "address": "Dumka, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.696348,
            10.857918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRGM",
          "name": "SRIRANGAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.709755,
            10.791837
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOC",
          "name": "PONMALAI GOLDEN ROCK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.15527399999999,
            22.082011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DZK",
          "name": "Durgachak",
          "zone": "SER",
          "address": "Haldia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.702834,
            10.829084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPTN",
          "name": "TIRUCHIRAPALI TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.702346,
            10.877948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UKV",
          "name": "UTTAMARKOVIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.720236,
            10.881112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BXS",
          "name": "PICHCHANDARKOVL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.757917,
            10.872869000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLDE",
          "name": "VALADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.78619599999999,
            10.873068
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAND",
          "name": "MANDURAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.813672,
            10.875733
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLI",
          "name": "LALGUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.855173,
            10.895833999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTTR",
          "name": "KATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.91757299999999,
            10.940214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMB",
          "name": "PULLAMBADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.948759,
            10.966118999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKPM",
          "name": "KALLAKKUDI PLGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.00542629819999,
            11.0256145326
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLGM",
          "name": "KALLAGAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0368619916,
            11.095633418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLTH",
          "name": "SILLAKKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.06831799999999,
            11.150035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALU",
          "name": "ARIYALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.109674,
            11.222726000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OTK",
          "name": "OTTAKOVIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.13844999999999,
            11.242453000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VER",
          "name": "VELLUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.168778,
            11.256462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNDI",
          "name": "SENDURAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.20580299999999,
            11.318107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTUR",
          "name": "MATHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2197551199,
            11.371003803499999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ICG",
          "name": "ICHCHANGADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.219978,
            11.397962999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNDM",
          "name": "PENNADAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.24125000000001,
            11.436374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLNR",
          "name": "TALANALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.279462,
            11.482273000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRT",
          "name": "VRIDDHACHALM TN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.938771,
            16.468807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DPD",
          "name": "DOSAPADU",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.03507563844323,
            29.160244032368652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GAMI",
          "name": "Gogameri",
          "zone": "NWR",
          "address": "MDR 18, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.758368,
            18.476969999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GANI",
          "name": "GHARNI",
          "zone": "?",
          "address": "MSH3, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.689981,
            19.274562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DVN",
          "name": "DEVTHANA",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.480363,
            28.730283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DXH",
          "name": "DUHAI",
          "zone": "NR",
          "address": "Uttar Pradesh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9930409827,
            25.010329682000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DWLE",
          "name": "Dhauni",
          "zone": "ER",
          "address": "Dhauni, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.86660300000001,
            30.372740999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DUI",
          "name": "DHURI JN",
          "zone": "NR",
          "address": "Dhuri, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.971043,
            28.000728000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FPS",
          "name": "Fatehpur Shekhawati",
          "zone": "NWR",
          "address": "Fatehpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.109076,
            26.316418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DWJ",
          "name": "DWARKAGANJ",
          "zone": "NR",
          "address": "Sultanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0869,
            19.218218
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DI",
          "name": "DOMBIVILI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.928312,
            19.11196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VK",
          "name": "VIKHROLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.897181,
            19.079258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VVH",
          "name": "VIDYAVIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.76318500000001,
            19.882845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VGN",
          "name": "VANGAON",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GALG",
          "name": "GoldingGanj",
          "zone": "?",
          "address": "GoldingGanj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2322638,
            25.2224815
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "EKR",
          "name": "Ekangarsarai",
          "zone": "ECR",
          "address": "Ekangar Sarai, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.010739,
            28.489165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "GGN",
          "name": "GURGAON",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.616682,
            29.893255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DTW",
          "name": "DATEWAS",
          "zone": "NR",
          "address": "Bareta, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.223984,
            22.500255999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NAI",
          "name": "Nangi",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.828486,
            28.467066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "FN",
          "name": "Farukhnagar",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.79558800000001,
            21.972471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GDL",
          "name": "GONDAL",
          "zone": "WR",
          "address": "Gondal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.52017599999999,
            27.269838999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GAH",
          "name": "Gawnaha",
          "zone": "ECR",
          "address": "Pashchim Champaran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.628452,
            27.731813000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GAL",
          "name": "GHATLA",
          "zone": "NWR",
          "address": "State Highway 14, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.0786586,
            23.077052700000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AI",
          "name": "ADIPUR",
          "zone": "WR",
          "address": "Dist. Kachchh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.832659,
            18.986789
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CHG",
          "name": "MUMBAI CHINCHPOKLI",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.35453600000001,
            18.907733
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DWZ",
          "name": "Dantewara",
          "zone": "ECoR",
          "address": "Dantewada, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.499375,
            22.712403000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DXR",
          "name": "DIGSAR",
          "zone": "WR",
          "address": "State Highway 17, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.673338,
            23.009106
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "FTD",
          "name": "Fatehabad Chowk Junction",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.39169434200001,
            28.291078574500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DZB",
          "name": "Dahina Zainabad",
          "zone": "NWR",
          "address": "Tumna Rd, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.67348799999999,
            12.956333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TMLP",
          "name": "TIRUMALPUR",
          "zone": "SR",
          "address": "Tirumalpur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7120436792,
            12.841137186300001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CJE",
          "name": "KANCHIPURAM EAST",
          "zone": "SR",
          "address": "Kanchipuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.378973,
            23.803358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DUJ",
          "name": "DUBRAJPUR",
          "zone": "ER",
          "address": "Dubrajpur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.977861,
            26.173306
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DGU",
          "name": "DIGARU",
          "zone": "NFR",
          "address": "Digaru, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.1395124182,
            26.1204333815
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BRHU",
          "name": "BARAHU",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.36716,
            26.189127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DML",
          "name": "DHARAMTUL",
          "zone": "NFR",
          "address": "Dharamtul, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.38771,
            27.326628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHGP",
          "name": "Bhagirathpur",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.880626,
            25.981050999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DHRY",
          "name": "DHALPUKHURI",
          "zone": "NFR",
          "address": "Hojai Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.225136,
            25.755626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BRLF",
          "name": "BAR LANGFER S",
          "zone": "NFR",
          "address": "Lumding, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.4827626898,
            26.783438032899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SLX",
          "name": "SELENG HAT",
          "zone": "NFR",
          "address": "National Highway 61, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.93525791020001,
            27.463606565899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DBRG",
          "name": "DIBRUGARH",
          "zone": "NFR",
          "address": "Dibrugarh, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.6674744,
            26.9601891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIL",
          "name": "DILAWARNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.396867,
            25.54457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NDJ",
          "name": "NANDGANJ",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.87601000000001,
            28.603253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DYU",
          "name": "DIYURI",
          "zone": "NER",
          "address": "Diyuri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.880117,
            32.706975
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "JAT",
          "name": "JAMMU TAWI",
          "zone": "NR",
          "address": "Jammu Cantt, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.668807,
            32.184403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KNDI",
          "name": "KANDRORI",
          "zone": "NR",
          "address": "District Kangra Pin- 176402, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.248588,
            26.182799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DYP",
          "name": "Daryapur",
          "zone": "NR",
          "address": "National Highway 24B, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63586428650001,
            29.9125697133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BMK",
          "name": "BIRANG KHERA",
          "zone": "NWR",
          "address": "MDR 105, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.616355,
            28.363519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DHRR",
          "name": "DHIRERA",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.00154,
            25.744108999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CBN",
          "name": "CHIT BARAGAON",
          "zone": "NER",
          "address": "Chitbaragaon, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.725004,
            25.788320000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPR",
          "name": "CHHAPRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.869395,
            25.748425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRI",
          "name": "DUMRI JUARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.16685100000001,
            25.696849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEE",
          "name": "SONPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.12602199999999,
            25.557959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FRDH",
          "name": "Faridaha Halt",
          "zone": "NER",
          "address": "Aunrihar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.50363,
            23.244576000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KLL",
          "name": "KALOL JN",
          "zone": "WR",
          "address": "Kalol, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.44125299999999,
            23.401485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DNW",
          "name": "DANGARWA",
          "zone": "WR",
          "address": "Jagudan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.40022099999999,
            24.034518000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CHP",
          "name": "CHHAPI",
          "zone": "WR",
          "address": "Banaskantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.039834,
            24.791042
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SOH",
          "name": "SIROHI ROAD",
          "zone": "NWR",
          "address": "Pindwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.15535899999999,
            8.806109000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TN",
          "name": "TUTICORIN",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.320972,
            22.844562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GBA",
          "name": "GARHBETA",
          "zone": "SER",
          "address": "Medinipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2308476031,
            12.0686868024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "ELM",
          "name": "ELIMALA",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.52096599999999,
            26.426289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GARA",
          "name": "Garha",
          "zone": "ECR",
          "address": "NH 77, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0615538,
            27.9358522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GJN",
          "name": "Gajner",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.404673,
            22.465958999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GIA",
          "name": "Garia",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.308161,
            8.212549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ERL",
          "name": "ERANIEL",
          "zone": "SR",
          "address": "Kanyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.316073,
            11.534965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRI",
          "name": "VRIDHACHALAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.30663799999999,
            11.676385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPLY",
          "name": "MEPPULIYUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.370923,
            11.778527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRKL",
          "name": "PARIKKAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.91149,
            12.531918999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGZ",
          "name": "KARUNGUZHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.17023400000001,
            17.171933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PNDP",
          "name": "PANDILLAPALLI",
          "zone": "SCR",
          "address": "Pandillapalli Railway Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.05678,
            17.172707
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GLV",
          "name": "GULVANCHI",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.929697,
            28.43755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "GHH",
          "name": "GARHI HARSARU",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.90809078,
            22.0539696502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GOP",
          "name": "GOP JAM",
          "zone": "WR",
          "address": "State Highway 27, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.302351,
            20.948836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "GDZ",
          "name": "Gundardehi",
          "zone": "SECR",
          "address": "Gundardehi, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.663342,
            30.201319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GDB",
          "name": "GIDDARBAHA",
          "zone": "NR",
          "address": "Giddarbaha, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.871735,
            29.585509000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GJW",
          "name": "Gajuwala",
          "zone": "NR",
          "address": "State Highway 2, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.82892199999999,
            23.802650999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "GMIA",
          "name": "GUMIA",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.141225,
            28.005897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GDHA",
          "name": "GODHA",
          "zone": "NR",
          "address": "Godha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.254223,
            27.008643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GHUM",
          "name": "Ghoom",
          "zone": "NFR",
          "address": "Darjeeling, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GNPT",
          "name": "Ganpatpura",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.019628,
            29.754037999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LRJ",
          "name": "LAKSAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.011307,
            25.142617
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GSDH",
          "name": "GARSANDA HALT",
          "zone": "ECR",
          "address": "Garsanda, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.888017,
            21.714043999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GELA",
          "name": "Ghadela Halt",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.8132154,
            24.1751953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GHQ",
          "name": "GARHWA",
          "zone": "ECR",
          "address": "Garhwa, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.987594,
            16.429888
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GDV",
          "name": "GUDIVADA JN",
          "zone": "SCR",
          "address": "Krishna District, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.519981,
            24.217437999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GNDI",
          "name": "GONDWALI",
          "zone": "WCR",
          "address": "Bargawa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.031939,
            25.326563999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEI",
          "name": "KASHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0384061804,
            25.315887671800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBRM",
          "name": "BABA BHAGWAN RAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.071351,
            25.29701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VYN",
          "name": "VYASNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.12283778,
            25.09724423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GNK",
          "name": "Garhnokha",
          "zone": "ECR",
          "address": "State Highway 12, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.102203,
            25.274869
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHBV",
          "name": "BLOCK HUT B",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.986468,
            25.327281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSB",
          "name": "VARANASI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5510144354,
            22.5120383739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DKI",
          "name": "DHARMKUNDI",
          "zone": "WCR",
          "address": "Hoshangabad, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.62227,
            29.832554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NGL",
          "name": "NANGAL",
          "zone": "NR",
          "address": "Saharanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.707807,
            29.468689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MOZ",
          "name": "MUZAFFARNAGAR",
          "zone": "NR",
          "address": "MuzaffarNagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.70616000000001,
            29.114015000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DRLA",
          "name": "DAURALA",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.571716,
            28.827907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MDNR",
          "name": "MODINAGAR",
          "zone": "NR",
          "address": "Ghaziabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.941024,
            22.227752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRI",
          "name": "BHIRINGI",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.786737,
            23.491197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GEDE",
          "name": "Gede",
          "zone": "ER",
          "address": "R 748, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.09767799999999,
            19.225768000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THK",
          "name": "THAKRULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.99853599999999,
            28.691982000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "GHE",
          "name": "GHEVRA",
          "zone": "NR",
          "address": "Ghevra, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.70440599999999,
            30.789872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GHA",
          "name": "GOLEHWALA",
          "zone": "NR",
          "address": "Golehwala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.24506478820001,
            27.4546557588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GAW",
          "name": "Gainjahwa",
          "zone": "NER",
          "address": "MDR 10E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.424279,
            31.117935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GNZ",
          "name": "Gahndran Halt",
          "zone": "NR",
          "address": "Malsian Rd, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGSO",
          "name": "MGSO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.7137745453,
            27.0539516142
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GH",
          "name": "GHUGHULI",
          "zone": "NER",
          "address": "Ghughli Bazar, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLWS",
          "name": "DLWS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.365134,
            25.30009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SJGH",
          "name": "SARAI JAGDISH",
          "zone": "NER",
          "address": "National Highway 2, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.919123,
            19.33655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KARD",
          "name": "KAMAN ROAD",
          "zone": "CR",
          "address": "Section 2, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GOV",
          "name": "Govindpuri",
          "zone": "NCR",
          "address": "Kanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.956092,
            20.811373000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AML",
          "name": "AMALSAD",
          "zone": "WR",
          "address": "Amalsad, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GPLG",
          "name": "Gopalpurgram",
          "zone": "ER",
          "address": "Contai Nandakumar Rd, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GQD",
          "name": "Gourdaha Halt",
          "zone": "ER",
          "address": "Gourdaha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.636453,
            31.065852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GTQ",
          "name": "Gumtali Halt",
          "zone": "NR",
          "address": "Gumtali, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.20310172849999,
            23.3616934176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GLD",
          "name": "Ghelda",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.00175999999999,
            29.767009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LRJC",
          "name": "LAKSAR NORTH CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.568045,
            25.583144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GCT",
          "name": "GHAZIPUR CITY",
          "zone": "NER",
          "address": "Ghazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.76513,
            26.668256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GIS",
          "name": "Garhi Sandra",
          "zone": "NCR",
          "address": "Dholpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.045457,
            10.596896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "GUV",
          "name": "GURUVAYUR",
          "zone": "SR",
          "address": "Guruvayoor 0487-2556820, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.196038,
            25.315812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EOCM",
          "name": "EOCM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.21503,
            25.318877999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCA",
          "name": "KUCHMAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.289193,
            25.331472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLD",
          "name": "SAKALDIHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.35123571720001,
            25.3422743391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLAM",
          "name": "TULSI ASHRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.423069,
            25.354392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHA",
          "name": "DHEENA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.059049,
            19.187012
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DTVL",
          "name": "DATIVLI",
          "zone": "CR",
          "address": "Kalyan Shilphata Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.11494900000001,
            19.035116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KLMG",
          "name": "KALAMBOLI GOODS",
          "zone": "CR",
          "address": "Navi Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.489726,
            25.364918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHCL",
          "name": "BAHORA CHANDIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.54365,
            25.373856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZNA",
          "name": "ZAMANIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.60180199999999,
            25.393753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRV",
          "name": "DARAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.706967,
            25.434324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "USK",
          "name": "USIA KHAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0867978516,
            18.735604455900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PEN",
          "name": "PEN",
          "zone": "CR",
          "address": "Pen, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.53976300000001,
            27.069813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GKA",
          "name": "Gokhula",
          "zone": "ECR",
          "address": "Dhaminaha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.720039,
            11.405279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CMC",
          "name": "CHEMANCHERI",
          "zone": "SR",
          "address": "Dist. Kozhikode, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.741119,
            11.346337
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "ETR",
          "name": "ELATTUR",
          "zone": "SR",
          "address": "Kozhikode district, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.893393,
            18.652065999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "GPBN",
          "name": "Gandhi Park Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.780407,
            11.246234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CLT",
          "name": "KOZHIKODE",
          "zone": "SR",
          "address": "Calicut, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8302845,
            11.174972200000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "FK",
          "name": "FEROK",
          "zone": "SR",
          "address": "Feroke, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.861006,
            11.045910000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PGI",
          "name": "PARPANANGADI",
          "zone": "SR",
          "address": "Parappanangadi, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.290829,
            10.758913999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPZA",
          "name": "BHARATHAPUZHA H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.280933,
            10.731563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VTK",
          "name": "VALLATHOL NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.738354,
            25.4504253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWH",
          "name": "BHADAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.26559,
            27.547928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GIO",
          "name": "Goriyan",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.139236,
            17.487076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GLA",
          "name": "GARLA",
          "zone": "SCR",
          "address": "Garla, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.12451399999999,
            31.152633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GOD",
          "name": "GIDARPINDI",
          "zone": "NR",
          "address": "Jalandhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.98805800000001,
            27.499556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GVH",
          "name": "Govind Garh",
          "zone": "NCR",
          "address": "Govindgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.617006,
            23.509341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GRB",
          "name": "GARHDHRUBESWAR",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.846139,
            22.097988
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GNW",
          "name": "Gangiwara",
          "zone": "CR",
          "address": "Chhindwara, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHLG",
          "name": "PHLG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.955291,
            25.998261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GRI",
          "name": "GURIYA",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.538423,
            23.211966999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HBE",
          "name": "Habibpur",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.46819099999999,
            17.609541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GWV",
          "name": "GOWDAVALLI",
          "zone": "SCR",
          "address": "Rangareddy, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.9611898,
            26.508626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "GLGT",
          "name": "Golaghat",
          "zone": "NFR",
          "address": "Golaghat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3948243298,
            23.7866102083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MDRR",
          "name": "MADHAVNAGAR ROA",
          "zone": "WCR",
          "address": "Madhavnagar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0778207251,
            22.682206476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GMD",
          "name": "GURAMKHEDI",
          "zone": "WCR",
          "address": "Sohagpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.452384,
            22.255044
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HGA",
          "name": "Hogla",
          "zone": "ER",
          "address": "Hogla H, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9937073,
            22.6307113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BGTA",
          "name": "BAGRA TAWA",
          "zone": "WCR",
          "address": "Bagra Tawa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.56420899999999,
            27.959391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AIA",
          "name": "AJARAKA",
          "zone": "NWR",
          "address": "Ajaraka, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.47936741759999,
            15.5431155625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GNJ",
          "name": "GUNJI",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "H",
          "name": "Venkatagiri Kote Halt",
          "zone": "SWR",
          "address": "Venkatagiri Kote, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7598229541,
            16.240718896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GPB",
          "name": "GHATPRABHA",
          "zone": "SWR",
          "address": "Ghatprabha, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30096,
            28.807889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GTRA",
          "name": "GOTRA HALT",
          "zone": "NR",
          "address": "Gotra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.967556543,
            26.6723715203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GLTA",
          "name": "GAHLOTA",
          "zone": "NWR",
          "address": "Kishangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.90948,
            30.318715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GNA",
          "name": "GONEANA",
          "zone": "NR",
          "address": "Goniana, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.436739,
            29.658053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GJS",
          "name": "Gajsinghpur",
          "zone": "NWR",
          "address": "State Highway 3, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.87951100000001,
            30.445082000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GJUT",
          "name": "GANGSAR JAITU",
          "zone": "NR",
          "address": "Jaitu, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.449853,
            23.378698999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GUS",
          "name": "GHUMASAN",
          "zone": "WR",
          "address": "State Highway 41, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.53452700000001,
            24.871222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GSD",
          "name": "Ghosunda",
          "zone": "NWR",
          "address": "Chittaurgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.75716073,
            25.6034373373
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HBP",
          "name": "HARPAR BOCHAHA",
          "zone": "ECR",
          "address": "Pagra Rd, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRLA",
          "name": "MARIYAL GANGAVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.657904,
            17.609727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GLE",
          "name": "Gajulaguden",
          "zone": "SCR",
          "address": "Gajulaguden, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.43554700000001,
            26.454202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GOPG",
          "name": "Gopalganj",
          "zone": "NER",
          "address": "Harkhua, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.36719699999999,
            14.582791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GOK",
          "name": "GOKARNA ROAD",
          "zone": "KR",
          "address": "Uttara Kannada, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.400253,
            11.866899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CS",
          "name": "KANNUR SOUTH",
          "zone": "SR",
          "address": "Kannur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.147349,
            21.222011000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GNL",
          "name": "Gongle",
          "zone": "SECR",
          "address": "Gondia, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.44379699999999,
            11.806194999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "ETK",
          "name": "ETAKKOT",
          "zone": "SR",
          "address": "NH 17, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.5696156628,
            25.4118931462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GQN",
          "name": "Garhani",
          "zone": "ECR",
          "address": "Garhani, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.62916200000001,
            23.233379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GNC",
          "name": "Gandhinagar Capital",
          "zone": "WR",
          "address": "Gandhinagar, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HJO",
          "name": "Harangajao",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.381896,
            22.218519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GUA",
          "name": "Gua",
          "zone": "SER",
          "address": "Kiriburu, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.776745,
            22.583661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HKH",
          "name": "Haranya Kheri",
          "zone": "WR",
          "address": "National Highway 3, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.046037,
            16.348371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GVL",
          "name": "Gudlavalleru",
          "zone": "SCR",
          "address": "Gudlavalleru, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.25171539,
            13.0202759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "GWYR",
          "name": "Greenways Road",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.420769,
            32.044111
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GSP",
          "name": "Gurdaspur",
          "zone": "NR",
          "address": "Gurdaspur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.56789,
            24.680276000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HKD",
          "name": "Hailakandi",
          "zone": "?",
          "address": "Hailakandi, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.8099599005,
            19.069058897799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "GNPR",
          "name": "Gunupur",
          "zone": "ECoR",
          "address": "Gunupur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.151266,
            32.004278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "GULR",
          "name": "GULER",
          "zone": "NR",
          "address": "State Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8861198969,
            30.138926513900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GSW",
          "name": "GURSAR SHNEWALA",
          "zone": "NWR",
          "address": "Bagwali, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HRD",
          "name": "Harsud",
          "zone": "WCR",
          "address": "Harsud, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.11814,
            22.468974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HAPA",
          "name": "HAPA",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.43800800000001,
            28.674404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GZN",
          "name": "NEW GHAZIABAD",
          "zone": "NR",
          "address": "Ghaziabad, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HSWS",
          "name": "Hansiawas",
          "zone": "NWR",
          "address": "Hansiawas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38578,
            15.286807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HPT",
          "name": "HOSPET JN",
          "zone": "SWR",
          "address": "Hospet, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.06589,
            22.045284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HLZ",
          "name": "Haldia",
          "zone": "SER",
          "address": "Sutahata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.868855,
            19.416708999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HEM",
          "name": "HIMAYATNAGAR",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6445661014,
            26.354279161199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HTD",
          "name": "HATUNDI",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64646099999999,
            15.81959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HLAR",
          "name": "HOLE ALUR",
          "zone": "SWR",
          "address": "Badami Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.33546100000001,
            25.290501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HPP",
          "name": "HARPALPUR",
          "zone": "NCR",
          "address": "Dist - Chhatarpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.320806,
            22.530677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GSL",
          "name": "GODAPIASAL",
          "zone": "SER",
          "address": "Godapiasal, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.275060462,
            29.290081553100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HLDR",
          "name": "HALDAUR",
          "zone": "NR",
          "address": "State Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.73799100000001,
            20.08688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GVD",
          "name": "GHOLVAD",
          "zone": "WR",
          "address": "Gholvad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.347661,
            26.72689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HSA",
          "name": "HASIMARA",
          "zone": "NFR",
          "address": "Hasimara, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.72970199999999,
            21.873814999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GTT",
          "name": "GOMTA",
          "zone": "WR",
          "address": "Gomta, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.757645,
            28.484002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GTF",
          "name": "GUMTHAL",
          "zone": "NR",
          "address": "Raja Ka Sahaspur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.615189,
            27.864848000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HSI",
          "name": "HARSAULI",
          "zone": "NWR",
          "address": "Harsoli, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.77798899999999,
            23.469777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HRSR",
          "name": "Harish Nagar Halt",
          "zone": "ER",
          "address": "BSF Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14336899999999,
            29.531288
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "GSO",
          "name": "GHASO",
          "zone": "NR",
          "address": "District Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.979837,
            12.565639000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HNK",
          "name": "HANAKERE",
          "zone": "SWR",
          "address": "Mandya, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63746400000001,
            26.456612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AII",
          "name": "AJMER JN",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.277079,
            25.317354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HIL",
          "name": "Hilsa",
          "zone": "ECR",
          "address": "Hilsa, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.466397041,
            29.8223584375
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "GXG",
          "name": "Geong",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.672506,
            26.355638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HBN",
          "name": "Haibargaon",
          "zone": "NFR",
          "address": "Nagaon, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.27241649478674,
            29.710120646310926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HLW",
          "name": "Hirnawali",
          "zone": "NWR",
          "address": "Hirnawali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5407224109,
            25.371762836200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HRT",
          "name": "Harnaut",
          "zone": "ECR",
          "address": "Harnaut, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.94176085389999,
            18.8219370968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "HBF",
          "name": "Hadobhangi Halt",
          "zone": "ECoR",
          "address": "Paralakhemundi, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05801798589528,
            27.594876304064115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HRF",
          "name": "Hathras Kila",
          "zone": "NCR",
          "address": "Hathras(05722-242803), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.209037,
            25.65472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GRPA",
          "name": "Garhpura",
          "zone": "ECR",
          "address": "Begusarai, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.724305,
            29.152341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "HSR",
          "name": "Hisar",
          "zone": "NWR",
          "address": "Hisar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.174712,
            26.151304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GTM",
          "name": "Ghatampur",
          "zone": "NCR",
          "address": "Ghatampur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.86392,
            24.442298
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "GRMA",
          "name": "Ghormara",
          "zone": "ER",
          "address": "Deoghar, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.072073,
            12.297417999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PCV",
          "name": "Palakkodu",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3618194318,
            12.570459617700001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HAH",
          "name": "Hosa Agrahara",
          "zone": "SWR",
          "address": "Hosa Agrahara, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.092628,
            25.881401999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HPI",
          "name": "Harapatti",
          "zone": "?",
          "address": "State Highway 65, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.919616,
            17.211288999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "IDR",
          "name": "INDI ROAD",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.45967900000001,
            29.039597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "HLDD",
          "name": "HALDI ROAD",
          "zone": "NER",
          "address": "NH 87, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.421171,
            28.388265999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "IZN",
          "name": "IZZATNAGAR",
          "zone": "NER",
          "address": "Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.320851,
            31.301125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "HSQ",
          "name": "HUSAINPUR",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.94306200000001,
            24.575192
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HNM",
          "name": "HINAUTARAMBAN",
          "zone": "WCR",
          "address": "Satna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.62051100000001,
            24.951767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HLX",
          "name": "HILARA",
          "zone": "NFR",
          "address": "Cachar, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "INDR",
          "name": "Indira Nagar",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.467377,
            17.393362999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "HYB",
          "name": "HYDERABAD DECCAN",
          "zone": "SCR",
          "address": "Hyderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.397778,
            27.131193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JCU",
          "name": "JAICHOLI",
          "zone": "WCR",
          "address": "SH 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.42206700000001,
            16.748424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HTK",
          "name": "HATKANAGALE",
          "zone": "CR",
          "address": "Hatkanangale, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.960358,
            23.591655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HMT",
          "name": "HIMMATNAGAR",
          "zone": "WR",
          "address": "Himatnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.053879,
            22.493992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JAM",
          "name": "JAMNAGAR",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1280258472,
            27.9904756211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HGJ",
          "name": "HARDUAGANJ",
          "zone": "NCR",
          "address": "Aligarh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.867119,
            30.458736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "HMI",
          "name": "HIMMATANA",
          "zone": "NR",
          "address": "SH 11, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.07713600000001,
            26.386419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HRNG",
          "name": "HARPUR NAG HALT",
          "zone": "ECR",
          "address": "National Highway 28, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.15489899999999,
            29.947787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "HW",
          "name": "HARIDWAR JN",
          "zone": "NR",
          "address": "Haridwar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.868072,
            22.717139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "INDM",
          "name": "Indore Junction MG",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7189807,
            28.319499
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "IHP",
          "name": "INCHHAPURI",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.98586300000001,
            25.871926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HWX",
          "name": "HABAIPUR",
          "zone": "NFR",
          "address": "Station Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.086161,
            18.81964
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HMRR",
          "name": "HAMARAPUR",
          "zone": "CR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3634743811,
            29.530388830099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HIND",
          "name": "HIND",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.291469,
            15.335637
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HTNL",
          "name": "HITNAL",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4184168683,
            12.8482854964
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HJL",
          "name": "HEJJALA",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.310464,
            23.812162
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "JGNR",
          "name": "Jogendranagar",
          "zone": "NFR",
          "address": "Agartala, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.410686,
            14.7943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HVR",
          "name": "HAVERI",
          "zone": "SWR",
          "address": "Haveri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.455749812,
            28.3108582783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JDL",
          "name": "JAGDEVWALA",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.048296,
            13.341342
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HEI",
          "name": "HEGGERE HALT",
          "zone": "SWR",
          "address": "Heggere, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1887639459,
            13.2815759392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HHL",
          "name": "HIREHALI",
          "zone": "SWR",
          "address": "NH 4, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37469700000001,
            22.495442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JDP",
          "name": "Jadabpur",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.989964,
            25.224705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JHD",
          "name": "Jehanabad",
          "zone": "ECR",
          "address": "Jahanabad, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.841154,
            20.934947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HRM",
          "name": "Hadmadiya",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.197137,
            21.342807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HDM",
          "name": "Hirdamali",
          "zone": "SECR",
          "address": "Gondia, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JJPR",
          "name": "Jhanjharpur Bazar Halt",
          "zone": "?",
          "address": "Jhanjharpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.061232,
            24.587316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JARI",
          "name": "Jari",
          "zone": "NWR",
          "address": "Dhanera, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.3402527405,
            26.3689018769
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HTW",
          "name": "Hathua",
          "zone": "NER",
          "address": "Hathua, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.336804,
            14.222474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HLV",
          "name": "Haliyuru",
          "zone": "SWR",
          "address": "State Highway 48, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.03223700000001,
            22.421623999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "BETI",
          "name": "Bhetasi",
          "zone": "WR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.178835,
            18.240644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KMNR",
          "name": "Kamalnagar",
          "zone": "SCR",
          "address": "Kamal Nagar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.513628,
            23.347507999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JKL",
          "name": "Jalal Khali",
          "zone": "?",
          "address": "State Highway 11, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9850695752,
            25.1558255368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HPLE",
          "name": "Hatpuraini",
          "zone": "ER",
          "address": "Bhagalpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.47275900000001,
            30.801197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JGN",
          "name": "Jagraon",
          "zone": "NR",
          "address": "Jagraon, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.39466399999999,
            12.479685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HPA",
          "name": "Hampapura",
          "zone": "SWR",
          "address": "Hampapura, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9878194543,
            25.156632436000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "IAM",
          "name": "Mai Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.98415622680001,
            25.2011434752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JHDC",
          "name": "Jehanabad Court",
          "zone": "ECR",
          "address": "National Highway 83, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.63429,
            25.35418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JOR",
          "name": "Jalor",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.950919,
            23.164636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JBP",
          "name": "JABALPUR",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.78325099999999,
            23.421550999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "IRN",
          "name": "Irgaon",
          "zone": "SER",
          "address": "Lohardaga, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.318381,
            30.288215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JHK",
          "name": "JETHUKE",
          "zone": "NR",
          "address": "Jettuke, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.396508,
            29.120124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JNA",
          "name": "JULANA",
          "zone": "NR",
          "address": "Julana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.51814539669999,
            15.7079285213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "IDJ",
          "name": "IDALHOND",
          "zone": "SWR",
          "address": "Garlgunj - Idalhond Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.244351,
            18.548665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "IDL",
          "name": "INDALVAI",
          "zone": "SCR",
          "address": "Indalvai, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.171451,
            18.286557
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JJR",
          "name": "JEJURI",
          "zone": "CR",
          "address": "Pune, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.12437581,
            24.79884918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JRBM",
          "name": "JIRIBAM",
          "zone": "NFR",
          "address": "Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.43198600000001,
            23.827373
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "JRNA",
          "name": "Jirania",
          "zone": "NFR",
          "address": "Jirania Railway Station, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.317477,
            29.309693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JCY",
          "name": "JIND CITY",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.788887,
            31.988433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "JDNX",
          "name": "JOGINDER NAGAR",
          "zone": "NR",
          "address": "Joginder Nagar, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.608869,
            11.548117000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "IGL",
          "name": "IRINGAL",
          "zone": "SR",
          "address": "Kochi - Panavel Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2915349414,
            29.2719947091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AILM",
          "name": "AILAM",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.073534,
            22.467657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JONR",
          "name": "Jeonara Ph",
          "zone": "SECR",
          "address": "Nainpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.650291,
            15.845019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "JAK",
          "name": "Jakanur",
          "zone": "SWR",
          "address": "Badami Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.78164699999999,
            23.445293999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "IBL",
          "name": "INDRABIL",
          "zone": "SER",
          "address": "Purulia District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84906600000001,
            19.136550999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JOS",
          "name": "JOGESHWARI",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.40164899999999,
            23.521841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JDN",
          "name": "JAGUDAN",
          "zone": "WR",
          "address": "Mehsana, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PBS",
          "name": "Purab Sarai",
          "zone": "ER",
          "address": "Munger, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.35067155089999,
            25.3053173094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JGG",
          "name": "JANGIGANJ",
          "zone": "NER",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.77482301741838,
            20.80642760713478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JMNR",
          "name": "Jamner",
          "zone": "CR",
          "address": "Jamner, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.00795843824744,
            25.132585153047003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JTG",
          "name": "Jatinga",
          "zone": "NFR",
          "address": "Jatinga, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JMB",
          "name": "Jambusar Junction",
          "zone": "WR",
          "address": "Bharuch Jambusar, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JMBC",
          "name": "Jambusar City",
          "zone": "WR",
          "address": "Jambusar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.615184,
            21.735312999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JTP",
          "name": "Jetpur",
          "zone": "WR",
          "address": "Jetpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.527405,
            24.307199
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JMRA",
          "name": "Jamira",
          "zone": "?",
          "address": "Bairabi Rd, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JONA",
          "name": "Jonha",
          "zone": "SER",
          "address": "State Highway 2, Jharkhand"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JPAH",
          "name": "Jagdishpur Halt",
          "zone": "ECR",
          "address": "Darbhanga, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.593046,
            21.032797000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JBB",
          "name": "Jambur",
          "zone": "WR",
          "address": "Jambur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1270959845,
            23.3522113974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "ITKY",
          "name": "Itky",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.39017865434289,
            28.106884063857194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JJN",
          "name": "Jhunjhunun",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.76050000000001,
            22.28593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JBU",
          "name": "Jabugam",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.217106,
            26.750692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JTTN",
          "name": "Jorhat Town",
          "zone": "NFR",
          "address": "Jorhat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.602033,
            30.510447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JBW",
          "name": "Jhabelwali",
          "zone": "NR",
          "address": "State Highway 16, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.24586000000001,
            25.716785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JSA",
          "name": "Jasai",
          "zone": "?",
          "address": "State Highway 40, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.01088,
            23.835435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "IDAR",
          "name": "Idar",
          "zone": "WR",
          "address": "Sabarkantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.890113,
            27.850784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JLLO",
          "name": "Jhilo",
          "zone": "NWR",
          "address": "State Highway 13, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.785833,
            28.671653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HZR",
          "name": "Hafizpur",
          "zone": "NR",
          "address": "Hafizpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.545676,
            32.218706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JK",
          "name": "Jakolari",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.802358,
            26.344599
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JPO",
          "name": "Jora Alapur",
          "zone": "NCR",
          "address": "Joura, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.960351,
            23.091963999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JPV",
          "name": "Jamtara Paraswara",
          "zone": "SECR",
          "address": "Howbadh Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.768653,
            28.560472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JRJ",
          "name": "JARGAON",
          "zone": "NR",
          "address": "Chandausi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.110555,
            17.2166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JTRD",
          "name": "JATH ROAD",
          "zone": "CR",
          "address": "State Highway 71, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.777193,
            18.274834000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KCB",
          "name": "KUSLAMB",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.101751,
            23.829547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KCNR",
          "name": "KACHNARA ROAD",
          "zone": "WR",
          "address": "State Highway 31, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.764389,
            24.557577000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JWO",
          "name": "JAWAD ROAD",
          "zone": "WR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.86827000000001,
            24.22064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JYM",
          "name": "JAI SAMAND ROAD",
          "zone": "NWR",
          "address": "Dengari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.041353,
            28.346844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KEG",
          "name": "KARENGI",
          "zone": "NR",
          "address": "Karengi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.92823899999999,
            26.91655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JSM",
          "name": "JAISALMER",
          "zone": "NWR",
          "address": "Jaisalmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.197461,
            26.126113999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JID",
          "name": "JAGI ROAD",
          "zone": "NFR",
          "address": "Jagiroad, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.369563,
            25.740942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JKN",
          "name": "JAKHANIAN",
          "zone": "NER",
          "address": "State Highway 67, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.903128,
            23.168783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KEQ",
          "name": "KACHHPURA",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.96576,
            20.729778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JRS",
          "name": "JORAVASAN",
          "zone": "WR",
          "address": "Joravasan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.57391,
            21.729374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JLR",
          "name": "JETALSAR JN",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.596143,
            22.78247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JHT",
          "name": "JUNEHTA",
          "zone": "WCR",
          "address": "State Highway 22, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.740844,
            27.521338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KB",
          "name": "KHAIRATABAD",
          "zone": "NER",
          "address": "Sitapur National Highway 24, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.345267,
            21.450312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JIR",
          "name": "JIRA ROAD",
          "zone": "WR",
          "address": "SH 236, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.36926500000001,
            29.16948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JJT",
          "name": "JAI JAI WANTI",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.728934,
            27.680642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JKP",
          "name": "JHAREKAPUR",
          "zone": "NER",
          "address": "Sitapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.226651,
            25.902334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JSB",
          "name": "Yamuna South Bank",
          "zone": "NCR",
          "address": "Uttar Pradesh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.904491,
            23.353362999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JPH",
          "name": "JHANTIPAHARI",
          "zone": "?",
          "address": "Bankura District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.116949,
            25.881604
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JNKR",
          "name": "Janakinagar",
          "zone": "ECR",
          "address": "Chapra, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.739115,
            29.839460000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KAGR",
          "name": "KAGANGARH",
          "zone": "NR",
          "address": "MDR 102, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.897525,
            21.825569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KFP",
          "name": "Kukra Khapa",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KALD",
          "name": "Kalanad",
          "zone": "SR",
          "address": "Kizur Rd, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KALN",
          "name": "Kalana",
          "zone": "NWR",
          "address": "MDR 18, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KAPM",
          "name": "Kottapalem",
          "zone": "ECoR",
          "address": "Vishakhapatnam, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KBGN",
          "name": "Khubagaon",
          "zone": "CR",
          "address": "Khubagaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.55467900000001,
            18.896375
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JYP",
          "name": "JEYPORE",
          "zone": "?",
          "address": "Jeypore, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.57373,
            29.209394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JKHI",
          "name": "Jakhod Khera",
          "zone": "NWR",
          "address": "Jakhodlkhera, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.863805,
            27.944171
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KCO",
          "name": "Kachhia Bridge",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.39808402583003,
            22.47229104660886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KAVI",
          "name": "Kavi",
          "zone": "WR",
          "address": "Bharuch, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.77066197879999,
            22.4200106276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JNK",
          "name": "Jhinkpani",
          "zone": "SER",
          "address": "Jorapokhar, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.1955095032,
            25.8474354519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KAI",
          "name": "KAIRLA",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.606343,
            10.873546000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "JPM",
          "name": "JIYAPURAM",
          "zone": "?",
          "address": "NH 67, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KCY",
          "name": "Kaichar",
          "zone": "ER",
          "address": "Kaichar, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KDRA",
          "name": "Kadampura",
          "zone": "ECR",
          "address": "Kadampura, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.912796,
            21.668795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JRG",
          "name": "Jugpura",
          "zone": "?",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.84733,
            28.199319000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JOH",
          "name": "Juharpura",
          "zone": "NWR",
          "address": "State Highway 69, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.218369,
            29.493381999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JOK",
          "name": "Jodhka",
          "zone": "?",
          "address": "Sirsa, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0950627991,
            25.474492932500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "JTDM",
          "name": "Jatdumri Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.757506,
            11.919269000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Puducherry",
          "code": "VI",
          "name": "Villianur",
          "zone": "SR",
          "address": "NH 45A, Puducherry"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1076480029,
            23.222302523099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JKS",
          "name": "Jaksi",
          "zone": "WR",
          "address": "State HIghway 138, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.534887,
            22.705299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KBGH",
          "name": "Karea Kadambagachhi",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.374648,
            28.50653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JRL",
          "name": "Jharili",
          "zone": "NWR",
          "address": "Jhajjar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.377777,
            32.002349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JHWR",
          "name": "Jhawar",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.330404,
            25.6393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KAJ",
          "name": "Kaliyaganj",
          "zone": "NFR",
          "address": "RaiGanj, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.111598,
            26.409469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KBE",
          "name": "KUREBHAR",
          "zone": "NR",
          "address": "MDR 66E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.54633,
            29.266583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "KGM",
          "name": "KATHGODAM",
          "zone": "NER",
          "address": "Kathgodam, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.811482,
            16.530215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KDGI",
          "name": "KUDGI",
          "zone": "SWR",
          "address": "NH 13, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.02555000000001,
            31.166967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KHHJ",
          "name": "Khatkar Kalan",
          "zone": "NR",
          "address": "Nawan Shahar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.81051,
            24.02828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KDLG",
          "name": "KUNDALGARH",
          "zone": "NWR",
          "address": "Dungarpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.447487,
            26.687507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KCF",
          "name": "KALCHINI",
          "zone": "NFR",
          "address": "NH 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.157805,
            17.298963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JVA",
          "name": "JAVALE",
          "zone": "CR",
          "address": "State Highway 71, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.399136,
            25.557129999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JUA",
          "name": "JARAUNA",
          "zone": "NR",
          "address": "Alamganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.551613,
            16.791339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JSP",
          "name": "JAYASINGPUR",
          "zone": "CR",
          "address": "Jaysingpur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.875061,
            15.459597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KBI",
          "name": "KAMBRGANVI",
          "zone": "SWR",
          "address": "State Highway 34, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.00460199999999,
            21.739200999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KDMR",
          "name": "KODIYAR MANDIR",
          "zone": "WR",
          "address": "SH 21, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.55121799999999,
            30.901616999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KIQ",
          "name": "Khai Phemeki",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.120775,
            29.928457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "JWP",
          "name": "JWALAPUR",
          "zone": "NR",
          "address": "Jwalapur, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0382402954,
            25.385191218099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KBR",
          "name": "KABRAI",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.578988,
            25.316453
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KCR",
          "name": "KHONKER",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.43343,
            15.251727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KGW",
          "name": "KARIGANURU",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14395900000001,
            15.353868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KBL",
          "name": "KOPPAL",
          "zone": "SWR",
          "address": "Koppal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.48431,
            12.918047
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KGI",
          "name": "KENGERI",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.671641,
            30.856795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KBU",
          "name": "KASU BEGU",
          "zone": "NR",
          "address": "Kasu Begu, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.719041,
            15.447816999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KGX",
          "name": "KANGINHAL",
          "zone": "SWR",
          "address": "Gadag, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.840078,
            19.069166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KHAR",
          "name": "KHAR",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.852098,
            19.204551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KILE",
          "name": "KANDIVLI",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.507154,
            24.291967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JTY",
          "name": "JETHI",
          "zone": "NWR",
          "address": "BANASKANTHA, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.35885900000001,
            25.755537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JSD",
          "name": "Jaisingder",
          "zone": "?",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KIE",
          "name": "Khiria Khurd",
          "zone": "NER",
          "address": "Shahbaznagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.2952717633,
            24.5804202061
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KKGT",
          "name": "Kalkalighat",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.68023,
            14.591669999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BOMN",
          "name": "Bommagundanakere",
          "zone": "SWR",
          "address": "Bellary-Hiriyur Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.957617,
            11.238610999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KAY",
          "name": "Karaimadai",
          "zone": "SR",
          "address": "Karamadai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.932064,
            21.653052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KKK",
          "name": "Khakharia",
          "zone": "WR",
          "address": "State Highway 110, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75277,
            28.785394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KALI",
          "name": "Kaili",
          "zone": "NR",
          "address": "State Highway 18, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.09337895,
            22.83998676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KKAE",
          "name": "Kaikala",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.495544,
            30.80909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JTH",
          "name": "Jhoktahal Singh",
          "zone": "NR",
          "address": "Jhoktahal Sing, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.644475,
            26.441079000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JRX",
          "name": "Juriagaon",
          "zone": "NFR",
          "address": "Mathaouri Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.213236,
            31.126338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KGKD",
          "name": "Kang Khurd",
          "zone": "NR",
          "address": "Kang Khurd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.420529,
            27.998447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KARH",
          "name": "Kakraha Rest House",
          "zone": "NER",
          "address": "State Highway 26, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.547042,
            24.453018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KLCR",
          "name": "Katlicherra",
          "zone": "?",
          "address": "Bairabi Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.20132346,
            21.90539421
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KHGR",
          "name": "Kashinagar Halt",
          "zone": "ER",
          "address": "Kashinagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.022519,
            8.579343999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KCHV",
          "name": "Kachchanvilai",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.041259,
            24.029405999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KDBM",
          "name": "Khed Brahma",
          "zone": "WR",
          "address": "Khedbrahma, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.52110400000001,
            28.331448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JTS",
          "name": "Jatusana",
          "zone": "NWR",
          "address": "Nanagal Pathani Rd, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.509547,
            25.714175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KHNM",
          "name": "Kunda Harnamganj",
          "zone": "NR",
          "address": "Kunda, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.54669299999999,
            16.623242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KI",
          "name": "KONDAPALLI",
          "zone": "SCR",
          "address": "Kondapalli, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.722552,
            11.43755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KII",
          "name": "KILLE",
          "zone": "SR",
          "address": "NH 45A, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.080298,
            23.166227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KLPG",
          "name": "KAMALPURGRAM",
          "zone": "SECR",
          "address": "National Highway 78, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.59816,
            25.661041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KFA",
          "name": "KOPARIA",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.716676,
            22.361075999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KHDI",
          "name": "KHANDERI",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.82999099999999,
            22.39349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KHC",
          "name": "KHORANA",
          "zone": "WR",
          "address": "State Highway 113, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.86363300000001,
            9.002019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KDU",
          "name": "KADAMBUR",
          "zone": "SR",
          "address": "Kadambur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.76356899999999,
            15.215160000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KDN",
          "name": "KUDATINI",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.34125900000001,
            22.992315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KKB",
          "name": "KARAK BEL",
          "zone": "WCR",
          "address": "Karak Bel, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.407636,
            14.851426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KJG",
          "name": "KARAJGI",
          "zone": "SWR",
          "address": "haveri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.90153400000001,
            20.316435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KEB",
          "name": "KARAMBELI",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.56394,
            19.98294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KEZ",
          "name": "Kelzar",
          "zone": "SECR",
          "address": "Chandrapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.277017,
            22.233139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KEP",
          "name": "Kelanpur",
          "zone": "WR",
          "address": "SH 11, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.28142,
            22.227958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KDHL",
          "name": "Kundhela",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.61854,
            29.950588999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KESR",
          "name": "Kesri Singhpur",
          "zone": "NWR",
          "address": "Kesrisinghpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.11480900000001,
            19.035625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KLMC",
          "name": "KALAMBOLI",
          "zone": "CR",
          "address": "Navi Mumbai., Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.987652,
            12.491175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KGQ",
          "name": "KASARAGOD",
          "zone": "SR",
          "address": "Kasaragod, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.57787300000001,
            23.097473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KLRD",
          "name": "KALI ROAD",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.590073,
            28.130539000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KNGT",
          "name": "KARNAWAS",
          "zone": "NWR",
          "address": "Karnawas, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.61526599999999,
            27.669476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PSL",
          "name": "PARISAL",
          "zone": "NWR",
          "address": "Khairthal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.0366109,
            23.1194723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AJE",
          "name": "ANJAR",
          "zone": "WR",
          "address": "Anjar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.48125700000001,
            22.011786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KHA",
          "name": "Khaigaon",
          "zone": "WCR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.335544,
            12.758925999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KHLL",
          "name": "KETOHALLI",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.188728,
            24.810754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KLNA",
          "name": "KOLHANA HALT",
          "zone": "?",
          "address": "Kothana, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.851134,
            25.918097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KJP",
          "name": "Khojeepura",
          "zone": "NCR",
          "address": "Khojeepura, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KMB",
          "name": "Kalv Amba",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.47502999999999,
            22.412606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KLKR",
          "name": "Kalikapur",
          "zone": "ER",
          "address": "Kalikapur Staion Rd, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KNDR",
          "name": "Kandari",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KNGM",
          "name": "Kangam",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.256019,
            22.076525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KLW",
          "name": "Kalwan Halt",
          "zone": "ER",
          "address": "Kalwan, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.729967,
            21.131542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KDRI",
          "name": "Kendri",
          "zone": "SECR",
          "address": "Kendri, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.49286000000001,
            27.277760999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KKNA",
          "name": "Khunkhuna",
          "zone": "NWR",
          "address": "State Highway 60, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.326722,
            24.638740000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KNBR",
          "name": "Kanaibazar",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6489122412,
            27.530759924999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KHRA",
          "name": "Khachera",
          "zone": "NWR",
          "address": "State Highway 37, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.891017,
            21.470222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KLOD",
          "name": "Kelod",
          "zone": "SECR",
          "address": "Kelod, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.331631,
            27.116118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KHTU",
          "name": "Khatu",
          "zone": "NWR",
          "address": "Khatu Khurd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.691337,
            8.969608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KFV",
          "name": "Kundara East",
          "zone": "SR",
          "address": "Kollam-Tirumangalam Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.90885,
            22.374838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KLZ",
          "name": "Keolari",
          "zone": "SECR",
          "address": "State Highway 11A, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.397796,
            29.792735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KLE",
          "name": "Kaithal",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.61649100000001,
            25.891166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KKNH",
          "name": "Karukhirharnagar Halt",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.475805,
            32.070953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SLHP",
          "name": "SULAH HIMACHAL PRADESH",
          "zone": "NR",
          "address": "Sulah, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.765986,
            9.000279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KKZ",
          "name": "Kottarakara",
          "zone": "SR",
          "address": "Kottarakkara, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2281810498,
            29.679483800099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KIY",
          "name": "Kalayat",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.27664089999999,
            22.3331028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KIJ",
          "name": "Kuldiha",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.04934,
            18.371892000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KMRD",
          "name": "KALAMB ROAD",
          "zone": "CR",
          "address": "Tadwale, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.335858,
            18.32586
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KMC",
          "name": "KAMAREDDI",
          "zone": "SCR",
          "address": "Kamareddi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.78581199999999,
            27.133898
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KLB",
          "name": "KIRAOLI",
          "zone": "NCR",
          "address": "Kiraoli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.20243500000001,
            19.618502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KNVT",
          "name": "KINWAT",
          "zone": "SCR",
          "address": "Kinwat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.21890400000001,
            29.331864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KJLU",
          "name": "KHARI JHALU",
          "zone": "NR",
          "address": "Jhalu, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4817815491,
            29.0138203113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KHV",
          "name": "KARAINTHI",
          "zone": "NR",
          "address": "NH 71, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.336659,
            29.227482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KIU",
          "name": "KINANA",
          "zone": "NR",
          "address": "Brarkhera, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.952638,
            29.701333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KLWN",
          "name": "KALWAN",
          "zone": "NR",
          "address": "MDR 111, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.818173,
            30.578495999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KKP",
          "name": "KOT KAPURA",
          "zone": "NR",
          "address": "Kotkapura, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42089299999999,
            10.932727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KLT",
          "name": "KULITALAI",
          "zone": "SR",
          "address": "Kulithalai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.08187667179999,
            26.1271784614
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KKET",
          "name": "KAMRUP KHETRI",
          "zone": "NFR",
          "address": "Khetri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.18884299999999,
            15.293341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "KM",
          "name": "KALEEM",
          "zone": "SWR",
          "address": "Kalay Railway Station, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.49975681459999,
            15.6379344079
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KNP",
          "name": "KHANAPUR",
          "zone": "SWR",
          "address": "Khanapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.41038356721401,
            28.764429948734062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PRSR",
          "name": "Paharsar",
          "zone": "NWR",
          "address": "Paharsar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.375949,
            23.863182
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KMLI",
          "name": "KAMLI",
          "zone": "WR",
          "address": "Chhapi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.163601,
            18.434555000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SHIV",
          "name": "SHINDAWANE",
          "zone": "CR",
          "address": "Walti Goan, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.71987089999999,
            16.2587773
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KKW",
          "name": "KANKAVALI",
          "zone": "KR",
          "address": "Kankavli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.943503,
            12.590263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KMQ",
          "name": "KUMBLA",
          "zone": "SR",
          "address": "Kumbla, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KNPL",
          "name": "Kandanur Puduvayal",
          "zone": "SR",
          "address": "State Highway 28, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KORA",
          "name": "Kora",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KORL",
          "name": "Kural",
          "zone": "WR",
          "address": "Vadodara Jambusar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6327939999,
            8.9182952655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KLQ",
          "name": "Kilikollur",
          "zone": "SR",
          "address": "Kollam, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KQV",
          "name": "Kinkhed",
          "zone": "CR",
          "address": "State Highway 201, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KR",
          "name": "Kevdi",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2798777728,
            26.9529472159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KNDL",
          "name": "Khandel",
          "zone": "NWR",
          "address": "MDR 59, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.308694,
            28.83755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KHRK",
          "name": "Kharak",
          "zone": "NWR",
          "address": "kharak Village, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11366699999999,
            21.279557
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KPKD",
          "name": "Khapri Kheda",
          "zone": "SECR",
          "address": "State Highway 243, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.994519,
            30.852821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KOTI",
          "name": "Koti",
          "zone": "NR",
          "address": "National Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.987831,
            25.18966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KKTA",
          "name": "Kollikhutaha",
          "zone": "ER",
          "address": "State Highway 19, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.074534,
            30.367614999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KKRL",
          "name": "Kakrala",
          "zone": "NR",
          "address": "Nabha, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.84116100000001,
            28.252454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KJY",
          "name": "Khurja City",
          "zone": "NR",
          "address": "Sikri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.13311999999999,
            25.339050999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KQI",
          "name": "KALACHAND",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.96071,
            29.204978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "KPV",
          "name": "KASHIPUR",
          "zone": "NER",
          "address": "Kashipur, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.876238,
            22.488122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KNKT",
          "name": "KANAKOT",
          "zone": "WR",
          "address": "State Highway 113, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.00525300000001,
            19.710119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KODI",
          "name": "KODI",
          "zone": "SCR",
          "address": "Ranjani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.250168,
            15.258352
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KNO",
          "name": "KUNDGOL",
          "zone": "SWR",
          "address": "Kundgol, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.551922,
            32.397897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "KTHU",
          "name": "KATHUA",
          "zone": "NR",
          "address": "Kathua, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.087931,
            30.120715999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KTF",
          "name": "KOT FATTEH",
          "zone": "NR",
          "address": "Bathinda, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.64170412169999,
            27.797324415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KRH",
          "name": "KHAIRTHAL",
          "zone": "NWR",
          "address": "Khairthal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.695807,
            20.797371000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KODR",
          "name": "Kodinar",
          "zone": "WR",
          "address": "Kodinar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.62065939360001,
            26.3046614487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KQS",
          "name": "Kailaras",
          "zone": "NCR",
          "address": "Kailaras, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KRJN",
          "name": "Karanjali Halt",
          "zone": "ER",
          "address": "Tengrabichi, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRJT",
          "name": "Karanja Town",
          "zone": "CR",
          "address": "Karanja, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.233643,
            15.392095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KUG",
          "name": "KUSUGALI",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.855553,
            26.588968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KSG",
          "name": "KISHANGARH",
          "zone": "NWR",
          "address": "Kishangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.151844,
            24.808297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KRJR",
          "name": "KARJARA",
          "zone": "ECR",
          "address": "State Highway 8, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0377370813,
            25.152408658800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KRTR",
          "name": "KAROTA PATRI HALT",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.223871,
            18.392294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KOL",
          "name": "KOLAD",
          "zone": "KR",
          "address": "Kolad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.9246644,
            15.490212900000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "KRMI",
          "name": "KARMALI",
          "zone": "KR",
          "address": "Karmali North, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.724107,
            10.696433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KRMG",
          "name": "Kumaramangalam",
          "zone": "SR",
          "address": "Avur Rd, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KSAI",
          "name": "Kosadi Halt",
          "zone": "WR",
          "address": "Mosali, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KSBG",
          "name": "Khusta Buzurg",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.31381,
            11.984076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KPQ",
          "name": "KANNAPURAM",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6360420268029,
            30.726293624427043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KRHR",
          "name": "Kharar",
          "zone": "NR",
          "address": "Kharar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.48152183368802,
            20.491825750710376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRJA",
          "name": "Karanja",
          "zone": "CR",
          "address": "Karanja, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.00541057619999,
            25.263396537800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KRON",
          "name": "Karona Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.353216,
            24.721172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KTGM",
          "name": "Kayasthagram",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.87569620789999,
            18.8568644473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KNGR",
          "name": "Kashinagar Halt",
          "zone": "ECoR",
          "address": "State Highway 34, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.26235799999999,
            22.054579999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KRBO",
          "name": "Karaboh",
          "zone": "SECR",
          "address": "SH 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.451231,
            22.946024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KPA",
          "name": "Kanchrapara",
          "zone": "ER",
          "address": "Kanchrapara, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KTNI",
          "name": "Kapustalni",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KTNR",
          "name": "Kirtyanand Nagar",
          "zone": "ECR",
          "address": "Purnea, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.449759,
            22.330258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KRXM",
          "name": "Krishnamohan Halt",
          "zone": "ER",
          "address": "Krishnamohan, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.696451,
            20.833165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KRX",
          "name": "Kurud",
          "zone": "SECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.10140100000001,
            21.249152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRDH",
          "name": "Koradih",
          "zone": "SECR",
          "address": "Nagpur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.24820899999999,
            13.013835
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KTPM",
          "name": "Kotturpuram",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.25613200000001,
            24.406330999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KRP",
          "name": "Krishnapur",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.918994,
            25.498041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KUTL",
          "name": "Kuwanthal",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.369799,
            23.765615
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KRKN",
          "name": "Karkend",
          "zone": "SER",
          "address": "Karkend, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.248086,
            13.005609
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KTBR",
          "name": "Kasturba Nagar",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.9263402661,
            21.649251036200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KNAD",
          "name": "Kanad",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.311248,
            26.991165000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KRC",
          "name": "Kiroda",
          "zone": "NWR",
          "address": "Degana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.888706,
            22.191568
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KRO",
          "name": "KURAWAN",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.334546145,
            19.6395023854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KSAE",
          "name": "KOSAI",
          "zone": "SCR",
          "address": "Kinwat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.8841357111,
            21.8521799207
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KTLA",
          "name": "KATKOLA JN",
          "zone": "WR",
          "address": "Katkola Junction, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.171715,
            22.061244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KRGA",
          "name": "KURANGA",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.794095,
            24.417762999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KRCD",
          "name": "KHARWA CHANDA",
          "zone": "NWR",
          "address": "Kharwa Chanda, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.811737,
            19.454407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VR",
          "name": "VIRAR",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.285092,
            26.198944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KTI",
          "name": "KANTI",
          "zone": "ECR",
          "address": "Kanti, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.498336,
            23.430415999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KSU",
          "name": "KUSHTAUR",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3016887134,
            22.6282749467
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KTGD",
          "name": "KOTH GANGAD",
          "zone": "WR",
          "address": "Koth, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7112187811,
            23.3115927105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KTO",
          "name": "KATORA",
          "zone": "SECR",
          "address": "Patna, Chhattisgarh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KV",
          "name": "Kayavarohan",
          "zone": "WR",
          "address": "Kayavarohan, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KVO",
          "name": "Kevdi Road",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.252274,
            21.297662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KSD",
          "name": "KESHOD",
          "zone": "WR",
          "address": "Keshod, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.714584,
            31.610411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KSA",
          "name": "KHASA",
          "zone": "NR",
          "address": "Khasa, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.248092,
            26.615923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KQY",
          "name": "KHARIKATIA",
          "zone": "NFR",
          "address": "Dhodar Ali, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.6886636169,
            25.965774583199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KS",
          "name": "KHETA SARAI",
          "zone": "NR",
          "address": "Nawab Yousuf Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.123647,
            24.980982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KTR",
          "name": "KOTHAR",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.19387900000001,
            21.8796
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KWDP",
          "name": "Kakdwip",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.619336,
            26.851588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NMT",
          "name": "NAMTIALI",
          "zone": "NFR",
          "address": "National Highway 61, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.96794799999999,
            15.442249
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KRKP",
          "name": "KYARKOP",
          "zone": "SWR",
          "address": "Dharwad, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.85868699999999,
            16.62608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KUD",
          "name": "KUDACHI",
          "zone": "SWR",
          "address": "Kudchi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.147622,
            17.690976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRG",
          "name": "KOREGAON",
          "zone": "CR",
          "address": "Koregaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.024008,
            12.423507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KQK",
          "name": "KOTIKULAM",
          "zone": "SR",
          "address": "Bekal, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0092961456,
            18.4218389359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KTKR",
          "name": "Kumtha Khurd",
          "zone": "SCR",
          "address": "State Highway 217, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.03339700000001,
            10.845723999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KTU",
          "name": "KUTTIPPURAM",
          "zone": "SR",
          "address": "Kuttippuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.68256799999999,
            26.070884
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KRNI",
          "name": "KRISHNAI",
          "zone": "NFR",
          "address": "NH 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.35072000000001,
            21.007558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KUHI",
          "name": "Kuhi",
          "zone": "SECR",
          "address": "Nagpur, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KXD",
          "name": "Kokalda",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KZPB",
          "name": "Kazipara Barasat",
          "zone": "ER",
          "address": "Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.986389,
            22.899659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KTAL",
          "name": "Kathalal",
          "zone": "WR",
          "address": "Kathalal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.79207688,
            10.57217515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KUTR",
          "name": "Kulathur",
          "zone": "SR",
          "address": "Keeranur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.386531,
            21.73935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LAT",
          "name": "Lathi",
          "zone": "WR",
          "address": "Lathi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.123575,
            23.639805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KWF",
          "name": "Katwa",
          "zone": "ER",
          "address": "Katwa, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.760114,
            25.861013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KYH",
          "name": "Khairah",
          "zone": "NER",
          "address": "Saran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.414597,
            22.343456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KYP",
          "name": "Kalyanpur",
          "zone": "ER",
          "address": "Baruipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.752631,
            22.235720999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KTNA",
          "name": "Kathana",
          "zone": "WR",
          "address": "Bhadran, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.787292,
            10.576654999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KRUR",
          "name": "Keeranur",
          "zone": "SR",
          "address": "Keeranur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3437941406,
            28.1331067668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KTWS",
          "name": "Kathuwas",
          "zone": "NWR",
          "address": "State Highway 26, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.165591,
            14.114788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KRY",
          "name": "Kadiri",
          "zone": "SCR",
          "address": "Kadiri, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.51158,
            26.874522000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KUMB",
          "name": "KUMARBAGH",
          "zone": "ECR",
          "address": "Kumarbagh, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.205004,
            25.416359999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "LAK",
          "name": "LAKHO",
          "zone": "ECR",
          "address": "Lakho, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.94491099999999,
            17.007517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KVK",
          "name": "KAVATHE MAHANKL",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.241642,
            10.775636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KXO",
          "name": "KUDIKADU",
          "zone": "SR",
          "address": "Poondi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.321958,
            22.110055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LKPR",
          "name": "Lakshmikantpur",
          "zone": "ER",
          "address": "Lakshmikantapur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.32629040219999,
            21.3918244015
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LAL",
          "name": "LUSHALA",
          "zone": "WR",
          "address": "State Highway 99, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.47495599999999,
            31.359247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KWJ",
          "name": "KHOJEWALA",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.38272400000001,
            31.366846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KXH",
          "name": "KAPURTHALA",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3615834955126,
            25.290391816004586
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LING",
          "name": "Ling",
          "zone": "CR",
          "address": "Ling, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.76651700000001,
            28.546470999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KZS",
          "name": "KUAKHERA HALT",
          "zone": "NR",
          "address": "Chandausi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.799272,
            12.033304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KVE",
          "name": "KAVALANDE",
          "zone": "SWR",
          "address": "SH 57, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.6577872687,
            26.1606011328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KWM",
          "name": "KAMPUR",
          "zone": "NFR",
          "address": "Kampur, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.01084399999999,
            30.154130000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KZW",
          "name": "KATAR SINGHWALA",
          "zone": "NR",
          "address": "State Highway 17, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.209792,
            8.323734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KZTW",
          "name": "KULITTURAI WEST",
          "zone": "SR",
          "address": "Kanyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.034114,
            27.832586
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LNH",
          "name": "Lachhmangarh Sikar",
          "zone": "NWR",
          "address": "Laxmangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.86427300000001,
            26.626403999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KUTI",
          "name": "Khumtai",
          "zone": "NFR",
          "address": "Khumtai, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.855877,
            21.582533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LDE",
          "name": "Lokdhikhera",
          "zone": "SECR",
          "address": "Sausar, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LDD",
          "name": "Ladkhed",
          "zone": "CR",
          "address": "SH 212, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.23944300000001,
            10.868287
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KZC",
          "name": "Kulukkalur",
          "zone": "SR",
          "address": "Kulukkallur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.34701899999999,
            25.055439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LDM",
          "name": "Ledarmer",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LFG",
          "name": "Lower Haflong",
          "zone": "NFR",
          "address": "Haflong, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LGN",
          "name": "Lehgaon",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "LKKA",
          "name": "Lal Kuan",
          "zone": "NER",
          "address": "Lalkuan, Uttarakhand"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LMB",
          "name": "Limbara",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.467499,
            22.969219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KYI",
          "name": "Kalyani",
          "zone": "ER",
          "address": "Kalyani, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8447011951,
            29.777708816700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KUDN",
          "name": "Kudni",
          "zone": "NR",
          "address": "MDR 101, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.736915,
            11.381409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KXT",
          "name": "Ketti",
          "zone": "SR",
          "address": "Granduff Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.379037,
            27.25415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "LIR",
          "name": "Lachmipur",
          "zone": "NER",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.936403,
            21.963575000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LIG",
          "name": "Linga",
          "zone": "SECR",
          "address": "Linga, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.119614,
            30.49849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LDK",
          "name": "Ladhuka",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.081765,
            28.272292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KYBR",
          "name": "Khairatiya Bandh Road",
          "zone": "NER",
          "address": "Khairatiya, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.725374,
            27.288435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LEDO",
          "name": "Ledo",
          "zone": "NFR",
          "address": "Ledo, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.26577,
            20.789828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "LBO",
          "name": "Latabor",
          "zone": "SECR",
          "address": "Latabore, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.068043,
            31.146278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KYY",
          "name": "Kariha",
          "zone": "NR",
          "address": "Kariha, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.087812,
            20.637273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KYS",
          "name": "Kusumkasa",
          "zone": "SECR",
          "address": "Kusumkasa, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.786317,
            10.112077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KTYR",
          "name": "Kottaiyur",
          "zone": "SR",
          "address": "Kottaiyur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.257513,
            24.421152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LGL",
          "name": "Lalgola",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.042973,
            23.610866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KVH",
          "name": "Khambhel",
          "zone": "WR",
          "address": "State Highway 7, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.309269,
            25.426072
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "LKN",
          "name": "LAKHMINIA",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.96712500000001,
            17.084723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LNP",
          "name": "LANGARPETH",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.858526,
            22.742296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LMNR",
          "name": "LAKSHMIBAI NAGAR",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.222393,
            22.55299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LHBK",
          "name": "LOTHAL BHURKI",
          "zone": "WR",
          "address": "State Highway 1, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.244136,
            28.57927
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "LPNR",
          "name": "LAJPAT NAGAR",
          "zone": "NR",
          "address": "Lajpat Nagar, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28015,
            13.061372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MCPK",
          "name": "Chepauk",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.01148900000001,
            19.926081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LSR",
          "name": "LASUR",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.46563,
            26.244934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LNA",
          "name": "LAMANA",
          "zone": "NWR",
          "address": "Beawar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.73019000000001,
            25.250449999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LOG",
          "name": "LOHGARA",
          "zone": "NCR",
          "address": "Lohgara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.149207,
            26.772568999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTRA",
          "name": "DANTRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.640107,
            22.666791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LBTL",
          "name": "Lebutala",
          "zone": "ER",
          "address": "Lebutala, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.702802,
            22.090056
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LTD",
          "name": "LATHIDAD",
          "zone": "WR",
          "address": "Tajpar Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7941,
            30.479756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LLU",
          "name": "LALRU",
          "zone": "NR",
          "address": "Lalru, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.9524202618,
            22.194537580600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LPJ",
          "name": "LALPUR JAM",
          "zone": "WR",
          "address": "Lalpur, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LPU",
          "name": "Lakhpuri",
          "zone": "CR",
          "address": "Lakhpuri, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LSN",
          "name": "Lasina",
          "zone": "CR",
          "address": "Lasina, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1244293225,
            22.1390377316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LTA",
          "name": "Lamta",
          "zone": "SECR",
          "address": "Lamta, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.810079,
            29.937832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LHA",
          "name": "LEHRA GAGA",
          "zone": "NR",
          "address": "Sangrur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.51160300000001,
            23.665992000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "RRME",
          "name": "RANCHI ROAD",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.592567,
            16.600703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "LKSH",
          "name": "LAKSHMINARAYANAPURAM",
          "zone": "SCR",
          "address": "L Narayanapuram, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MARD",
          "name": "Mavur Road",
          "zone": "SR",
          "address": "State Highway 23, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MAX",
          "name": "Mangudi",
          "zone": "SR",
          "address": "State Highway 23, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.738116,
            15.424244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "LWR",
          "name": "ALNAVAR JN",
          "zone": "SWR",
          "address": "Alnavar, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MBH",
          "name": "Mobha Road",
          "zone": "WR",
          "address": "Vadodara Jambusar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.58674500000001,
            23.339664
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LKZ",
          "name": "LAKADIYA",
          "zone": "WR",
          "address": "Lakadia, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.77672700000001,
            22.873403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LTR",
          "name": "LAKHTAR",
          "zone": "WR",
          "address": "Lakhtar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.862567,
            22.954447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LPR",
          "name": "LILAPUR ROAD",
          "zone": "WR",
          "address": "State Highway 17, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.372863,
            21.540317
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LMO",
          "name": "LILIYA MOTA",
          "zone": "WR",
          "address": "Liliya Mota, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.25726300000001,
            26.050472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LMN",
          "name": "Lachhmanpur",
          "zone": "NR",
          "address": "Unchahar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.59598917299999,
            26.2003076494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LPN",
          "name": "LAOPANI",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.958001,
            25.914426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LKA",
          "name": "LANKA",
          "zone": "NFR",
          "address": "Lanka, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.274188,
            13.07406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MCPT",
          "name": "Chintadripet",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.046846,
            25.796536
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LKG",
          "name": "LAMSAKHANG",
          "zone": "NFR",
          "address": "Lamsakhan Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.3046356757,
            25.759322935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LCT",
          "name": "LANGCHOLIET",
          "zone": "NFR",
          "address": "TV Centre Road, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.0953363135,
            27.0797307213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LPTA",
          "name": "LONGPATIA",
          "zone": "NFR",
          "address": "Nabhokotia Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.999525,
            27.4525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LHL",
          "name": "LAHOAL",
          "zone": "NFR",
          "address": "A T Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.9989317505,
            23.9881925448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "MANU",
          "name": "Manu",
          "zone": "NFR",
          "address": "Manu, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.43331500000001,
            22.396985
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MAK",
          "name": "Mallikpur",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.341781,
            30.447834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LKW",
          "name": "Lakhewali",
          "zone": "NR",
          "address": "Court Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.229057,
            26.220882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "LNO",
          "name": "Lohna Road",
          "zone": "ECR",
          "address": "Naruar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.67687000000001,
            27.19839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LHW",
          "name": "Loharwara",
          "zone": "NWR",
          "address": "Sikar Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.89496100000001,
            22.131808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "LTK",
          "name": "Lathikata",
          "zone": "SER",
          "address": "Latkata, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.849833,
            18.913242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "LRI",
          "name": "Lihuri Halt",
          "zone": "ECoR",
          "address": "Lihuri Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.44533799999999,
            28.854316999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "LHLL",
          "name": "Lahli",
          "zone": "NWR",
          "address": "State Highway 16, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.37553642820001,
            27.2041163361
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MAAR",
          "name": "Malhar",
          "zone": "?",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3554837085,
            23.526717930500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LCH",
          "name": "Linch",
          "zone": "WR",
          "address": "State Highway 41, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.366849,
            16.921826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MDR",
          "name": "MADHIRA",
          "zone": "SCR",
          "address": "K.Ponnavaram, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.042126,
            26.350708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MDB",
          "name": "MANDOR",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.534813,
            11.823788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TUY",
          "name": "THIRUTHURAIYUR",
          "zone": "SR",
          "address": "Thiruthuraiyur Railway Station Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.59395891049999,
            25.439631738299997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MDL",
          "name": "MANDAL",
          "zone": "NWR",
          "address": "Mandal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.81170376339999,
            22.2625878623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MDPR",
          "name": "MODPUR",
          "zone": "WR",
          "address": "Khambhalia-Lalpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.712778,
            23.13609
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MDRA",
          "name": "MEDRA",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.355908,
            22.586017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MFR",
          "name": "Mandla Fort",
          "zone": "SECR",
          "address": "Mandla Fort, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.90539757810001,
            21.608000903100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MDHA",
          "name": "Madhada",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MDNP",
          "name": "Madanpur Halt",
          "zone": "ER",
          "address": "Madanpur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.22772,
            30.062745999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MAUR",
          "name": "MAUR",
          "zone": "NR",
          "address": "Maur Mandi, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.80929,
            23.593092000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MDF",
          "name": "MURADI",
          "zone": "SER",
          "address": "Barakar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.757452,
            29.212025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MDLA",
          "name": "MUNDLANA",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.34996600000001,
            23.781878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VU",
          "name": "VAGHPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.91377,
            25.698324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MEM",
          "name": "MAU AIMMA",
          "zone": "NR",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.124891,
            26.60377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MEQ",
          "name": "MALETHU KANAK",
          "zone": "NR",
          "address": "Bikapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.552278,
            25.249931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MBS",
          "name": "MADHOSINGH",
          "zone": "NER",
          "address": "SH 5, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0202584961,
            24.8344029798
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MDVB",
          "name": "Mandar Vidyapith Halt",
          "zone": "ER",
          "address": "Mandar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.783198,
            21.004164000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "MCF",
          "name": "Manik Chauree Halt",
          "zone": "SECR",
          "address": "Manikchauri, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.26482899999999,
            13.802188000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MCU",
          "name": "Mulacalacheruvu",
          "zone": "SCR",
          "address": "Mulakalacheruvu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.68722700000001,
            26.465014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MD",
          "name": "MADAR",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.275398,
            25.000632999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBNL",
          "name": "Marwar Bhinmal",
          "zone": "NWR",
          "address": "Bhinmal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.80896700000001,
            15.812975
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MADR",
          "name": "MADURE",
          "zone": "KR",
          "address": "Sawantwadi Vengurla Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4932361584,
            14.1744388792
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MANK",
          "name": "MANKI",
          "zone": "KR",
          "address": "Manki, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MGR",
          "name": "Munger",
          "zone": "ER",
          "address": "Munger, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.88006,
            12.866193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MAJN",
          "name": "MANGALORE JN",
          "zone": "SR",
          "address": "Mangalore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.700155,
            29.966227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MBY",
          "name": "MANDI DABWALI",
          "zone": "NWR",
          "address": "Mandi Dabwali, Haryana"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MGX",
          "name": "Mailongdisa",
          "zone": "NFR",
          "address": "Mailongdisa, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.546967,
            11.699226999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Puducherry",
          "code": "MAHE",
          "name": "MAHE",
          "zone": "SR",
          "address": "Mahe, Puducherry"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84917,
            19.187089
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MDD",
          "name": "MALAD",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.7101089758,
            23.8838302513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "MGKM",
          "name": "Mungiakami",
          "zone": "NFR",
          "address": "Mungiyakami, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.02615830570001,
            24.8060780645
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MDLE",
          "name": "Mandar Hill",
          "zone": "ER",
          "address": "Mandar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.33981800000001,
            30.807611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "AJL",
          "name": "Ajitwal",
          "zone": "NR",
          "address": "Ajitwal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.347323,
            22.119207
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MDBP",
          "name": "Madhabpur",
          "zone": "ER",
          "address": "State Highway 3, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.738947,
            22.931326000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AJN",
          "name": "Ajnod",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0439060474,
            25.366289184800003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MDCR",
          "name": "Masaudhi Court Halt",
          "zone": "?",
          "address": "National Highway 22, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.197734,
            23.219746999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MDGR",
          "name": "Manendragarh",
          "zone": "SECR",
          "address": "Manendragarh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.83618299999999,
            30.891527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MG",
          "name": "Modelgram",
          "zone": "NR",
          "address": "Ludhiana, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.72458900000001,
            25.907006000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MEE",
          "name": "Methai",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.850984,
            25.969081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MEW",
          "name": "Marhaura",
          "zone": "NER",
          "address": "National Highway 28B, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.351907,
            23.773417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "LYD",
          "name": "Layabad",
          "zone": "SER",
          "address": "Loyabad, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.719031,
            23.414561999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MIJ",
          "name": "Majhdia",
          "zone": "ER",
          "address": "Banpur-Majhdia Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.26700809939999,
            24.7994691685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBW",
          "name": "Malwara",
          "zone": "NWR",
          "address": "Marwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8085433154,
            26.1022287466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MHC",
          "name": "Masrakh",
          "zone": "NER",
          "address": "Mashrakh, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.956134,
            21.37586
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MGVK",
          "name": "Malegaon Vyenku",
          "zone": "SECR",
          "address": "National Highway 69, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.706748,
            31.018428
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MFM",
          "name": "MAHALAM",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.87169200000001,
            30.529403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MET",
          "name": "MALERKOTLA",
          "zone": "NR",
          "address": "Maler Kotla, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.771097,
            22.55518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MHOW",
          "name": "Mhow",
          "zone": "WR",
          "address": "Mhow, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.472164,
            24.85653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MINA",
          "name": "MIYANA",
          "zone": "WCR",
          "address": "Guna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8660122473,
            27.151203539700003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MIQ",
          "name": "MIRHAKUR",
          "zone": "NCR",
          "address": "Idgah Agra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.31641039811075,
            21.88940753391553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MLSR",
          "name": "Malsar",
          "zone": "WR",
          "address": "Sinor, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.09365700000001,
            25.063492
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MKP",
          "name": "MANIKPUR JN",
          "zone": "NCR",
          "address": "Manikpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.134188,
            9.908505
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MES",
          "name": "MADURAI EAST",
          "zone": "SR",
          "address": "Madurai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.284783,
            26.294081000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MHRL",
          "name": "Mahrail",
          "zone": "ECR",
          "address": "Mahrail, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.107881626,
            24.3428549076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MGLE",
          "name": "MANIGRAM",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.003204,
            26.485986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MLH",
          "name": "MANAKLAO",
          "zone": "NWR",
          "address": "State Highway 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.298965,
            16.703574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VV",
          "name": "VALIVADE",
          "zone": "CR",
          "address": "Valivade, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.90525,
            26.655520000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MKI",
          "name": "BAPUDHAM MOTIHARI",
          "zone": "ECR",
          "address": "Motihari, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.12631435489999,
            26.301858406599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MHL",
          "name": "MAHWAL",
          "zone": "ECR",
          "address": "Muzaffarpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.076322,
            19.419884999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MHMB",
          "name": "MAHIMBA",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0097415,
            13.7283327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AJP",
          "name": "AJJAMPUR",
          "zone": "SWR",
          "address": "Ajjampur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.949202,
            23.677025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MHDP",
          "name": "Mahadevpara",
          "zone": "WR",
          "address": "State Highway 9, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.856072,
            19.280299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MIRA",
          "name": "MIRA ROAD",
          "zone": "WR",
          "address": "Mira Bhayandar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.922408,
            15.314045
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "MJO",
          "name": "MAJORDA",
          "zone": "KR",
          "address": "South Goa, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.858785,
            25.322705000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MGRR",
          "name": "MAGARPUR",
          "zone": "NCR",
          "address": "Mau Ranipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.191957,
            26.693327999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MJE",
          "name": "MUJNAL",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MKRL",
          "name": "Moti Koral",
          "zone": "WR",
          "address": "Maninageshwar Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.694333,
            25.871178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MIH",
          "name": "MIHRAWAN",
          "zone": "NR",
          "address": "Nawab Yousuf Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84687600000001,
            19.040547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MM",
          "name": "MUMBAI MAHIM JN",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.54072000000001,
            24.400845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MOAR",
          "name": "Manipur Bagan",
          "zone": "?",
          "address": "Bairabi Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.887582,
            12.724197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "MJS",
          "name": "MANJESHWAR",
          "zone": "SR",
          "address": "NH 17, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.018413,
            24.304919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MGAE",
          "name": "Morgram",
          "zone": "ER",
          "address": "Murshidabad Distt., West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.642542,
            12.338553999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MNGT",
          "name": "MYSORE NEW GOOD",
          "zone": "SWR",
          "address": "Mysore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.203117,
            28.701227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MHU",
          "name": "Manheru",
          "zone": "NWR",
          "address": "Bhiwani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.440958,
            27.485742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MJN",
          "name": "Makum Junction",
          "zone": "NFR",
          "address": "Makum, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.276539,
            13.044645999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MLHS",
          "name": "Light House",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.421243,
            23.529636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MGM",
          "name": "Muragacha",
          "zone": "ER",
          "address": "Nadia, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MNGV",
          "name": "Mota Miya Mangrol",
          "zone": "WR",
          "address": "Mosali, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MNLI",
          "name": "Manali Halt",
          "zone": "SR",
          "address": "State Highway 23, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.260507,
            13.027861000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MNDY",
          "name": "Mandaveli",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.77978399999999,
            20.863002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MHAD",
          "name": "Mohadi Pragane Laling",
          "zone": "CR",
          "address": "Dhule, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.826515,
            28.24322
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MIW",
          "name": "Mighauna",
          "zone": "NER",
          "address": "Mighauna, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.739025,
            28.13104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MIO",
          "name": "Molisar",
          "zone": "NWR",
          "address": "Churu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.946947,
            22.817129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MHUA",
          "name": "Mahudha",
          "zone": "WR",
          "address": "Madhuda, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.29969399999999,
            31.102966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MLKH",
          "name": "Mulewal Khaihra",
          "zone": "NR",
          "address": "Neewa Meianwal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.45434999999999,
            9.697777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MNME",
          "name": "Manamadurai East",
          "zone": "SR",
          "address": "Manamadurai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.550058,
            24.611951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MNCR",
          "name": "Monacherra",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.504257,
            26.417226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MJV",
          "name": "Manjhagarh",
          "zone": "NER",
          "address": "National Highway 28, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.177678,
            29.981150000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "MOTC",
          "name": "MOTICHUR",
          "zone": "NR",
          "address": "Haridwar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.29970100000001,
            21.15957
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MLHA",
          "name": "MALIYA HATINA",
          "zone": "WR",
          "address": "Malia, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4055439008,
            17.9165477096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MLB",
          "name": "MODNIMB",
          "zone": "CR",
          "address": "Shetfal, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.822432,
            16.971395
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MNL",
          "name": "MINCHNAL",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.618982,
            27.390236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MKH",
          "name": "MALAKHERA",
          "zone": "NWR",
          "address": "Mala Khera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.972666,
            24.265618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MLG",
          "name": "MALHARGARH",
          "zone": "WR",
          "address": "Malhargarh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.947114,
            25.017393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MKD",
          "name": "MARKUNDI",
          "zone": "WCR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.509661,
            19.148087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MUE",
          "name": "MUDKHED",
          "zone": "SCR",
          "address": "Mudkhed, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.26149799999999,
            28.953119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MNDR",
          "name": "MANDI DHANAURA",
          "zone": "NR",
          "address": "Jyotiba Phule Nagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.387152,
            15.225027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MLGT",
          "name": "MALLAPPA GATE",
          "zone": "SCR",
          "address": "Guntakal, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.034774,
            27.216507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MNQ",
          "name": "MAINPURI",
          "zone": "NCR",
          "address": "Mainpuri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.015018,
            13.335173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MLSA",
          "name": "MALLASANDRA",
          "zone": "SWR",
          "address": "NH 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.514271,
            30.47821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MKS",
          "name": "Muktsar",
          "zone": "NR",
          "address": "Muktsar, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MQW",
          "name": "Mallannagar",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MRD",
          "name": "Manund",
          "zone": "WR",
          "address": "State HIghway 55, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.1911711557,
            24.2875453877
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPLR",
          "name": "MAHIPAL ROAD",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.62755,
            28.946971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MKLI",
          "name": "MAKRAULI",
          "zone": "NR",
          "address": "Rohtak, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.504477691,
            25.654307800700003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MNO",
          "name": "MEHNAR ROAD",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.847143,
            19.028606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MRU",
          "name": "MUMBAI MATUNGA ROAD",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.037935174,
            26.3087448868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MMC",
          "name": "MAHAMANDIR",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.978778,
            26.532173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MMY",
          "name": "MARWAR MATHANYA",
          "zone": "NWR",
          "address": "Mathania, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.00869499999999,
            22.411601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MTHP",
          "name": "MITHAPUR",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.447631,
            25.187428
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MON",
          "name": "Modran",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.498609,
            29.884915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MNZ",
          "name": "MANANI",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.266800144,
            26.1446457224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AJRE",
          "name": "AUJARI",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.347318,
            28.284349000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MLN",
          "name": "MAILANI",
          "zone": "NER",
          "address": "Lakhimpur Kheri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.5881226697,
            25.4756828749
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MNS",
          "name": "Manshahi",
          "zone": "NFR",
          "address": "National Highway 81, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.60191599999999,
            27.043053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MRJD",
          "name": "Marajdwa",
          "zone": "ECR",
          "address": "Sikta-Mainatad Rd, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.66501600000001,
            30.83811
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MLX",
          "name": "Mullanpur",
          "zone": "NR",
          "address": "Mullanpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.226329,
            24.312218
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MPUR",
          "name": "New Madanpur",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.142853,
            24.67822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MSQ",
          "name": "Marwar Ratanpur",
          "zone": "NWR",
          "address": "State Highway 128, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.45225400000001,
            22.693938
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MMG",
          "name": "Madhyamgram",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.910005,
            21.812143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MPML",
          "name": "Mohapani Mal",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.272805,
            11.06543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "MLTR",
          "name": "Melattur",
          "zone": "SR",
          "address": "Melattoor, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.191328,
            22.049857000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MQQ",
          "name": "Markahandi U Halt",
          "zone": "SECR",
          "address": "State Highway 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.354756,
            31.110293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MQS",
          "name": "Malsian Shahkot",
          "zone": "NR",
          "address": "Shahkot-Moga Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.501566,
            22.11073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MKDN",
          "name": "Morkadhana",
          "zone": "CR",
          "address": "Nayagaon, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.402584,
            29.989694999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MSZ",
          "name": "MANSA",
          "zone": "NR",
          "address": "Mansa, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.162107,
            24.30631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPLE",
          "name": "MAHIPAL",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.9150573724,
            19.2036411097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MQL",
          "name": "MIRKHAL",
          "zone": "SCR",
          "address": "Purna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.864509,
            26.133968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MTJR",
          "name": "MOTERJHAR",
          "zone": "NFR",
          "address": "Dhubri Kochugaon Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.12533900000001,
            26.709217000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MSOD",
          "name": "MASODHA",
          "zone": "NR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.142209,
            26.249743000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MTJL",
          "name": "Motijheel",
          "zone": "NCR",
          "address": "Gwalior, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.832613,
            26.958112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "MSDH",
          "name": "MASNADIH",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.719182,
            29.355377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MSP",
          "name": "MANSURPUR",
          "zone": "NR",
          "address": "Muzaffarnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.973721,
            24.794585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MVJ",
          "name": "MAVLI JN",
          "zone": "NWR",
          "address": "Mavli, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14639600000001,
            30.363819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NBA",
          "name": "Nabha",
          "zone": "NR",
          "address": "Nabha, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.686937,
            29.015997000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUT",
          "name": "MEERUT CANT",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.58927,
            26.981256000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MWT",
          "name": "MARWAR LOHWAT",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.675106,
            28.977610000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MTC",
          "name": "MEERUT CITY",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.506774,
            28.759017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUD",
          "name": "MURADNAGAR",
          "zone": "NR",
          "address": "Ghaziabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.62741799999999,
            27.412426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MSRP",
          "name": "MURHESI RAMPUR",
          "zone": "WCR",
          "address": "SH 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.319686,
            23.479665999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MTFA",
          "name": "MERTALA PHALEYA",
          "zone": "ER",
          "address": "Mertala Phaleya, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.88999799999999,
            21.019949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MRL",
          "name": "MAROLI",
          "zone": "WR",
          "address": "Maroli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.455639,
            10.674541000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MQ",
          "name": "Mannargudi",
          "zone": "SR",
          "address": "Mannargudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7018564789,
            8.7067184087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MP",
          "name": "MELAPPALAIYAM",
          "zone": "SR",
          "address": "Tirunelveli, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.838561,
            18.952385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MSD",
          "name": "BOMBAY MASJID",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MTAP",
          "name": "Matania Anantpur",
          "zone": "ER",
          "address": "Matania Anantapur Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.60887100000001,
            27.483719999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MWW",
          "name": "MAHWA",
          "zone": "NWR",
          "address": "Alwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.823908,
            18.982103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MX",
          "name": "Mumbai Mahalakshmi",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MTT",
          "name": "Mutupet",
          "zone": "SR",
          "address": "Muthupet, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.897256,
            12.529176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MYA",
          "name": "MANDYA",
          "zone": "SWR",
          "address": "Mandya, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.804962,
            13.068517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MULK",
          "name": "MULKI",
          "zone": "KR",
          "address": "Mulki, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.267038,
            13.03497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MTMY",
          "name": "Thirumayilai",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.608482,
            32.112895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MRTL",
          "name": "MIRTHAL",
          "zone": "NR",
          "address": "Mirthal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8034186484,
            24.502604961899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MXO",
          "name": "MORTHALA",
          "zone": "NWR",
          "address": "NH 14, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.55344699999999,
            27.784934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MT",
          "name": "Matera",
          "zone": "NER",
          "address": "Bahraich, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.071748,
            26.66857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MOBD",
          "name": "Moabund",
          "zone": "NFR",
          "address": "Mohbondha, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.010799,
            22.176298999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MSDL",
          "name": "Mahisadal",
          "zone": "SER",
          "address": "Haldia Mechada via Tamluk, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.6726,
            27.289315000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MRG",
          "name": "Margherita",
          "zone": "NFR",
          "address": "Ledo, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.86108700000001,
            28.330372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MOM",
          "name": "Maman",
          "zone": "NR",
          "address": "National Highway 91, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28020599999999,
            13.054862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MTCN",
          "name": "Triplicane",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.122747,
            25.368686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MUPA",
          "name": "MUPA",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.419984,
            30.803353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NNKR",
          "name": "Nanaksar",
          "zone": "NR",
          "address": "Nanaksar, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MZRT",
          "name": "Murtajapur Town",
          "zone": "CR",
          "address": "Murtajapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.06563200000001,
            27.227142
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MPUE",
          "name": "MAINPURI KACHRI",
          "zone": "NCR",
          "address": "Mainpuri Kachri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.621531,
            28.885405000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUZ",
          "name": "MOHIUDDINPUR",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NADA",
          "name": "Nada",
          "zone": "WR",
          "address": "Mota Habipura Nada Rd, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NBGH",
          "name": "Nowbagh",
          "zone": "CR",
          "address": "Nowbagh, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.303949,
            18.387703000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NEI",
          "name": "NEOLI",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.6654016473,
            27.607994992800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NKRA",
          "name": "Nokhra",
          "zone": "?",
          "address": "Nokhra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.56320699999999,
            13.001289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MWM",
          "name": "MALLESWARAM",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.421273,
            26.92382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NGLT",
          "name": "NAGLATULA",
          "zone": "NCR",
          "address": "Bayana, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "NDLH",
          "name": "Nandlalee Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.534516,
            19.358161
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MVO",
          "name": "MANWATH ROAD",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.459164,
            23.315091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "NGE",
          "name": "NAGAR",
          "zone": "SECR",
          "address": "National Highway 78, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.983505,
            31.105935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MXH",
          "name": "MAKHU",
          "zone": "NR",
          "address": "Makhnu, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.846188,
            10.818430000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NCR",
          "name": "Nagore",
          "zone": "SR",
          "address": "Nagore, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.41529899999999,
            18.017962999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MZL",
          "name": "MIRZAPALI",
          "zone": "SCR",
          "address": "Medak, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.10081100000001,
            20.012339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KKG",
          "name": "KEKATUMAR",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8134271344,
            31.0470049078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MWX",
          "name": "MALLANWALA KHAS",
          "zone": "NR",
          "address": "Mallanwala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.3105186481,
            24.227740021099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MXY",
          "name": "MAHUARIYA",
          "zone": "ECR",
          "address": "Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.37057999999999,
            24.136642000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MZHL",
          "name": "MAJHAOLI",
          "zone": "WCR",
          "address": "Bargawa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.77675500000001,
            15.397805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MYL",
          "name": "MALLIYALA",
          "zone": "SCR",
          "address": "Kurnool, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.43085099999999,
            15.432164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NGR",
          "name": "ANNIGERI",
          "zone": "SWR",
          "address": "Dist. Dharwad, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.836685,
            24.098212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MWJ",
          "name": "MARWASGRAM",
          "zone": "WCR",
          "address": "Madhya Pradesh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.008415,
            22.576568
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NDNI",
          "name": "Nidhani",
          "zone": "SECR",
          "address": "Ghonsor, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.306631,
            24.814294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MZW",
          "name": "MANJHWE",
          "zone": "ECR",
          "address": "Manjhwe, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.23651100000001,
            10.954704000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MYU",
          "name": "MAYANOOR",
          "zone": "SR",
          "address": "NH 67, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.70935200000001,
            22.0252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NGA",
          "name": "NINGALA JN",
          "zone": "WR",
          "address": "State Highway 117, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.845954,
            19.350875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NIG",
          "name": "NAIGAON",
          "zone": "WR",
          "address": "Naigaon West, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.73056000000001,
            16.387453999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NAN",
          "name": "NANDGAON ROAD",
          "zone": "KR",
          "address": "Sindhudurg, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.30820299999999,
            26.652434999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MXN",
          "name": "MARIANI JN",
          "zone": "NFR",
          "address": "Mariani, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.79288199999999,
            26.962757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MUGN",
          "name": "MAHUTGAON",
          "zone": "NFR",
          "address": "B G Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.313642,
            27.19627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NAM",
          "name": "NAMRUP",
          "zone": "NFR",
          "address": "Borhat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.638614,
            18.953174999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "MVG",
          "name": "MALIGURA",
          "zone": "ECoR",
          "address": "Maligura Station, Orissa"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NGX",
          "name": "Nigan",
          "zone": "ER",
          "address": "Nigan, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.37814700000001,
            28.030462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUH",
          "name": "Murthiha",
          "zone": "NER",
          "address": "State Highway 26, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.934533,
            11.299729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MTP",
          "name": "Mettupalayam",
          "zone": "SR",
          "address": "Mettupalayam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.214325,
            21.973426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NCP",
          "name": "Nishchindapur",
          "zone": "ER",
          "address": "Tengrabichi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.97536099999999,
            25.017219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "NERH",
          "name": "Ner Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.661013,
            23.363017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MYHT",
          "name": "Mayurhat",
          "zone": "ER",
          "address": "Mayurhat-Taraknagar Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.21761215939999,
            28.038590055100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NAL",
          "name": "Nal Halt",
          "zone": "?",
          "address": "NH 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.16622600000001,
            22.829712999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NKL",
          "name": "Nalikul",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.201872,
            12.816193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MVC",
          "name": "Mavinkere",
          "zone": "SWR",
          "address": "Mavinkere, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.651081,
            12.380243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NHY",
          "name": "NAGANAHALLI",
          "zone": "SWR",
          "address": "Mysore District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.356472,
            23.396593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NDAE",
          "name": "NABADWIP DHAM",
          "zone": "ER",
          "address": "Nabadwip, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.349049,
            23.279379000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "NPRD",
          "name": "NAGPUR ROAD",
          "zone": "SECR",
          "address": "Koria, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.66476800000001,
            11.775944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NPM",
          "name": "NELLAIKUPPAM",
          "zone": "SR",
          "address": "Nellikuppam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4582228,
            16.3100404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NGNT",
          "name": "NEW GUNTUR",
          "zone": "SCR",
          "address": "Guntur City, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.46287099999999,
            17.423337999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NLRD",
          "name": "NECKLACE ROAD",
          "zone": "SCR",
          "address": "Hyderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.444755,
            22.687235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NBE",
          "name": "New Barrackpore",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.84640800000001,
            31.497981000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NAS",
          "name": "Nasrala",
          "zone": "NR",
          "address": "Distt.Hoshiarpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38879399999999,
            32.108728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "NGRT",
          "name": "NAGROTA",
          "zone": "NR",
          "address": "Nagrota Bagwan, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.863795,
            30.483469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "AJTM",
          "name": "AJITGILL MATTA",
          "zone": "NR",
          "address": "Kotkapura Jaitu Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.645074,
            11.127231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NID",
          "name": "NIDUR",
          "zone": "SR",
          "address": "Neivasal, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31047799999999,
            19.160522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NED",
          "name": "HUZUR SAHIB NANDED",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5590299896,
            16.964028561899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NDE",
          "name": "NANDRE",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.845998,
            10.759221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NGT",
          "name": "NAGAPPATTINAM",
          "zone": "SR",
          "address": "Nagapattinam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.582174,
            8.315971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NPK",
          "name": "NORTH PANAKUDI",
          "zone": "SR",
          "address": "Panakudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.39630899999999,
            18.110863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AKE",
          "name": "AKANAPET",
          "zone": "SCR",
          "address": "Medak, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NPV",
          "name": "Narpatganj",
          "zone": "ECR",
          "address": "National Highway 57, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NRKE",
          "name": "Nari Khetri",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2376282849,
            19.5948820434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NDPR",
          "name": "NANDAPUR",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.724837,
            26.87368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NMZ",
          "name": "NEW MAL JN",
          "zone": "NFR",
          "address": "National Highway 31. 03562-255097, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.815845,
            23.17417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NHM",
          "name": "NANDOL DEHEGAM",
          "zone": "WR",
          "address": "Dehegam, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.009693,
            8.455233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "NEM",
          "name": "NEMEM",
          "zone": "SR",
          "address": "Thiruvananthapuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0173352112,
            25.296538788200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NDU",
          "name": "Nadauj",
          "zone": "ECR",
          "address": "Nadaul, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.45597599999999,
            21.046574999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RJU",
          "name": "Rajula City",
          "zone": "WR",
          "address": "Distt. Amreli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.239558,
            21.772235000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NMKA",
          "name": "Namkhana",
          "zone": "ER",
          "address": "Namkhana, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.601431,
            31.081588999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NRM",
          "name": "Nurmahal",
          "zone": "NR",
          "address": "Philur Nurmahal Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.24737400000001,
            24.051233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NBPH",
          "name": "New Balarampur Halt",
          "zone": "ER",
          "address": "New Balarampur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.450856,
            28.177004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PALI",
          "name": "Pali",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.12340101560001,
            22.210719843299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NWA",
          "name": "Nagarwara",
          "zone": "SECR",
          "address": "Nainpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.988344,
            24.937206
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "NYM",
          "name": "Neyamatpur",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9789721362,
            20.7084913199
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARNG",
          "name": "Arunnagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.123133,
            28.044396
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "NNL",
          "name": "Narnaul",
          "zone": "NWR",
          "address": "Narnaul, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.67253075,
            26.356211990000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NGAN",
          "name": "Nagaon",
          "zone": "NFR",
          "address": "Nagaon, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NRPM",
          "name": "Narayanpur Murli Halt",
          "zone": "ECR",
          "address": "Saraygarh, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.228517,
            17.07433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NVC",
          "name": "NAGALWANCHA",
          "zone": "SCR",
          "address": "Jagannadhapuram To Matkepalli Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NRUR",
          "name": "Nareshwar Road",
          "zone": "WR",
          "address": "Nareshwar Rd, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NRWI",
          "name": "Narwasi",
          "zone": "NWR",
          "address": "MDR 18, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.00915947140001,
            23.047142090399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NRGR",
          "name": "NARANJIPUR",
          "zone": "WR",
          "address": "Dewas, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.819464,
            19.418238000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NSP",
          "name": "NALLA SOPARA",
          "zone": "WR",
          "address": "Nallasopara station Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.852903,
            32.281043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NUPR",
          "name": "NURPUR ROAD",
          "zone": "NR",
          "address": "Nurpur, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NUIH",
          "name": "Neuri Halt",
          "zone": "ECR",
          "address": "Darbhanga, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NXR",
          "name": "Nagsar",
          "zone": "ECR",
          "address": "Ghazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4544374475,
            17.7900915824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PAPM",
          "name": "PALAT POTARAM",
          "zone": "SCR",
          "address": "National Highway 7, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.511398,
            11.034797000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NPT",
          "name": "NARASINGAMPET",
          "zone": "SR",
          "address": "Thiruvavaduthurai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.236446,
            28.290931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NSU",
          "name": "NISUI",
          "zone": "NR",
          "address": "Aonla- Bareilly Link Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NZH",
          "name": "Nidaghatta",
          "zone": "?",
          "address": "Bangalore-Mysore Rd, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "P",
          "name": "",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.464045,
            27.105035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NKE",
          "name": "NARKATIAGANJ JN",
          "zone": "ECR",
          "address": "Narkatiaganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.486113,
            16.769023999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NMGT",
          "name": "NIMSHIRGAON TAMDALGE",
          "zone": "CR",
          "address": "Miraj Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.05696599999999,
            28.686261000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "NNO",
          "name": "NANGLOI",
          "zone": "NR",
          "address": "JJ Colony No 1, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.541646,
            20.102455
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NSL",
          "name": "NAGARSOL",
          "zone": "SCR",
          "address": "Nashik, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.109286,
            29.588632
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "NRW",
          "name": "NARWANA JN",
          "zone": "NR",
          "address": "Way to Narwana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.412736,
            10.773563999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NMJ",
          "name": "NIDAMANGALAM",
          "zone": "SR",
          "address": "Needamangalam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.889707,
            29.318787999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "NLH",
          "name": "NAULTHA",
          "zone": "NR",
          "address": "Brahman Majra, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.013256,
            24.053618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NWB",
          "name": "NIWAS ROAD",
          "zone": "WCR",
          "address": "Sidhi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.476271,
            29.951767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NPX",
          "name": "NARINDARPURA",
          "zone": "NR",
          "address": "Mansa, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.307785,
            28.775277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NTG",
          "name": "NURSRATABAD KHARKHAR",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.149035,
            24.27276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "PASG",
          "name": "Panisagar",
          "zone": "NFR",
          "address": "Panisagar, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.246367,
            24.156525000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NLSF",
          "name": "NIYALISH PARA",
          "zone": "ER",
          "address": "Berhampore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0462042108,
            22.511799793599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PDE",
          "name": "Pindrai",
          "zone": "SECR",
          "address": "State Highway 11A, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.66259199999999,
            8.4891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NNN",
          "name": "NANGUNERI",
          "zone": "SR",
          "address": "Nanguneri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.202275,
            23.147973999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ODM",
          "name": "ONDAGRAM",
          "zone": "SER",
          "address": "Onda, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.520696,
            12.941162
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NYH",
          "name": "NAYANDAHALLI",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.83577600000001,
            26.182961000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NNGE",
          "name": "NARANGI",
          "zone": "NFR",
          "address": "Panikhaiti, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.334128,
            27.479748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NTSK",
          "name": "NEW TINSUKIA JN",
          "zone": "NFR",
          "address": "A T Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.189651,
            17.918333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AKI",
          "name": "ADARKI",
          "zone": "CR",
          "address": "Pune, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.57461500000001,
            26.305325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NMA",
          "name": "Nirmali",
          "zone": "ECR",
          "address": "Supaul, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.699909,
            16.445891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NS",
          "name": "Narasapur",
          "zone": "SCR",
          "address": "Narsapur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.46277400000001,
            25.122359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NLD",
          "name": "Nalanda",
          "zone": "ECR",
          "address": "National Highway 82, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.69100300000001,
            28.588172999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SSC",
          "name": "Shahi",
          "zone": "NER",
          "address": "Shahi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.07392999999999,
            19.062273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "NKX",
          "name": "NAKTISEMRA",
          "zone": "ECoR",
          "address": "Jagdalpur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.73256,
            27.626063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "OTD",
          "name": "Ootwar",
          "zone": "NCR",
          "address": "Alwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.09017872437835,
            22.237238915003488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PDRA",
          "name": "Padra",
          "zone": "WR",
          "address": "Padra, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.07946299999999,
            22.65471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "OD",
          "name": "Od",
          "zone": "WR",
          "address": "Od, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.28570594266057,
            28.058125832368518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NUA",
          "name": "Nua",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.282913,
            11.120495
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TUV",
          "name": "Tuvvur",
          "zone": "SR",
          "address": "Thuvvur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.262036,
            22.804834999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NSF",
          "name": "Nasibpur",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.41769,
            22.453419999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NRPR",
          "name": "Narendrapur Halt",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9668384277,
            23.3782939954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "NJA",
          "name": "Nagjua",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.499193,
            28.250468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "NNU",
          "name": "Nangal Mundi",
          "zone": "NWR",
          "address": "State Highway 24, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.68546380999999,
            18.47264914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BHJA",
          "name": "Bheja",
          "zone": "ECoR",
          "address": "Bheja Station, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.25409499999999,
            22.208105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NTA",
          "name": "Netra",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.98843942260001,
            25.143791455699997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "NZP",
          "name": "Niyazipur Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3062197015,
            22.5683088157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PDPK",
          "name": "PADMAPUKAR",
          "zone": "SER",
          "address": "Howrah, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.191169,
            18.357557
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PCP",
          "name": "PALSAP",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9846818945,
            17.3223703821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PDNR",
          "name": "PADNUR",
          "zone": "SWR",
          "address": "Shegao to Alagi Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.39203,
            19.18427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PARD",
          "name": "PATHRAD",
          "zone": "SCR",
          "address": "Huzur Sahib Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.90699699999999,
            12.766651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PALR",
          "name": "PALUR",
          "zone": "SR",
          "address": "SH 58, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.505599,
            24.0512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PKRD",
          "name": "PAKARA ROAD",
          "zone": "WCR",
          "address": "Pakara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.99070900000001,
            11.051701999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PGR",
          "name": "PUGALUR",
          "zone": "SR",
          "address": "Karur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3695901074,
            23.7204627184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NWR",
          "name": "NIWAR",
          "zone": "WCR",
          "address": "Katni, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.073775,
            22.470959999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "OKHA",
          "name": "OKHA",
          "zone": "WR",
          "address": "Okha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.1038005835,
            22.0956416097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "OKD",
          "name": "OKHA MADHI",
          "zone": "WR",
          "address": "NH 8E, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.47560800000001,
            31.111697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NRO",
          "name": "Nakodar Junction",
          "zone": "NR",
          "address": "Nakodar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8558716895,
            13.3016546778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NTR",
          "name": "NITTUR",
          "zone": "SWR",
          "address": "NH 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.079282,
            29.483825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PGK",
          "name": "PILI BANGAN",
          "zone": "NWR",
          "address": "Pilibanga, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.80548800000001,
            25.923565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PEE",
          "name": "Paterhi",
          "zone": "NER",
          "address": "Saran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.813703,
            28.563319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NTZ",
          "name": "NATHWANA",
          "zone": "NWR",
          "address": "Malkisar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.61672100000001,
            21.764344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NUD",
          "name": "NAVAGADH",
          "zone": "WR",
          "address": "Jetpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.732879,
            30.732663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PKZ",
          "name": "PIPLI PAKHI KALAN",
          "zone": "NR",
          "address": "Faridkot, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.559809,
            27.926801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PIH",
          "name": "Parhihara",
          "zone": "NWR",
          "address": "Sujangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.102716,
            19.052388999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NVRD",
          "name": "NAVADE ROAD",
          "zone": "CR",
          "address": "Ghotkamp Koyana vele, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.16420749999999,
            22.440956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "OND",
          "name": "Aunlajori Junction",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.806474,
            13.133125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAND",
          "name": "NANDIKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.68442999999999,
            12.12211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NTW",
          "name": "NANJANGUD TOWN",
          "zone": "SWR",
          "address": "Nanjangudu, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.82961999999999,
            18.994919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PL",
          "name": "Mumbai Lower Parel",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.47622299999999,
            11.538814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NVL",
          "name": "Neyveli",
          "zone": "SR",
          "address": "SH 10, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.975949,
            24.438661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "OBR",
          "name": "OBRA DAM",
          "zone": "ECR",
          "address": "Obra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.303969,
            23.743195
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PCX",
          "name": "Pagla Chandi",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.801946,
            10.372456999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PDKT",
          "name": "Pudukkottai",
          "zone": "SR",
          "address": "Pudukkottai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.41266,
            22.052145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PED",
          "name": "Pipardahi",
          "zone": "SECR",
          "address": "SH 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.976271,
            8.563997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NZT",
          "name": "Nazareth",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PHU",
          "name": "Pahur",
          "zone": "CR",
          "address": "Pahur, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PIJ",
          "name": "Pij",
          "zone": "WR",
          "address": "State Highway 149, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PKP",
          "name": "Pallikkara",
          "zone": "SR",
          "address": "Kasaragod, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.68701800000001,
            20.988298
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PCC",
          "name": "Prachi Road Junction",
          "zone": "WR",
          "address": "Jambur, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PKT",
          "name": "Pattukottai",
          "zone": "SR",
          "address": "Pattukkottai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37759799999999,
            12.62489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AKK",
          "name": "Akkihebbalu",
          "zone": "SWR",
          "address": "Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.10823,
            24.196225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "PEC",
          "name": "Pencharthal",
          "zone": "NFR",
          "address": "Pecharthal, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.108571,
            31.1213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NSS",
          "name": "Nawanshahr Doaba Junction",
          "zone": "NR",
          "address": "Bhaddi, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.44582399999999,
            30.76024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KRSW",
          "name": "Kohar Singhwala",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.342438,
            24.641534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PKGM",
          "name": "Phakhoagram",
          "zone": "NFR",
          "address": "Phakhoagram, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0136837024,
            24.8770456448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PJLE",
          "name": "Panjwara Road",
          "zone": "ER",
          "address": "Mandar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.78411,
            30.887454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PHS",
          "name": "Ferozeshah",
          "zone": "NR",
          "address": "Firozpur Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.580494,
            28.369685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PHX",
          "name": "PATHAKPUR",
          "zone": "NR",
          "address": "Chandausi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.62014099999999,
            20.280364
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PRDP",
          "name": "Paradip",
          "zone": "ECoR",
          "address": "Paradip, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.330151,
            23.771990000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PCR",
          "name": "PANCHRA",
          "zone": "ER",
          "address": "Birbhum, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.59504241879999,
            25.826562590800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PMYA",
          "name": "PARMINIYA HALT",
          "zone": "ECR",
          "address": "Parminiya, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.60534299999999,
            22.429596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PDH",
          "name": "PADADHARI",
          "zone": "WR",
          "address": "Paddhari, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.112409,
            24.806929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PMI",
          "name": "PAIMAR",
          "zone": "ECR",
          "address": "Paimar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.67346400000001,
            19.294984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PG",
          "name": "PERGAON",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8577266418,
            19.21840134
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PIZ",
          "name": "PINGLI",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.748627,
            30.213009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRSH",
          "name": "KARAMGARH SDRGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.778291,
            24.306134999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PDQ",
          "name": "PADLA",
          "zone": "NWR",
          "address": "Padla, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.474264,
            21.662813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PCT",
          "name": "PANCHTALAVDA ROAD",
          "zone": "WR",
          "address": "SH 115, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.351451,
            22.42054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PGL",
          "name": "PAGDHAL",
          "zone": "WCR",
          "address": "Pagdhal, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.8757005432,
            26.2119648863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PHI",
          "name": "PANIKHAITI",
          "zone": "NFR",
          "address": "Chandrapur Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.4205444342,
            25.321358352999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PIRO",
          "name": "Piro",
          "zone": "ECR",
          "address": "Piro, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.26854200000001,
            10.922767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PML",
          "name": "PAPANASAM",
          "zone": "SR",
          "address": "Rajagiri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.72917899999999,
            27.729917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PMM",
          "name": "PARSEHRA MAL",
          "zone": "NER",
          "address": "Parsehra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.933839,
            27.167486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "PNYA",
          "name": "PANIAHWA",
          "zone": "NER",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.591131,
            9.539071
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PMK",
          "name": "PARAMAKKUDI",
          "zone": "SR",
          "address": "Paramagudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.83750400000001,
            19.009045
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PR",
          "name": "MUMBAI PAREL",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.322164,
            22.956881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PBA",
          "name": "PIARDOBA",
          "zone": "SER",
          "address": "Bankura, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.65216699999999,
            10.7753892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PGTN",
          "name": "PALGHAT TOWN",
          "zone": "SR",
          "address": "Palakkad, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.121756,
            18.990918999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PNVL",
          "name": "PANVEL",
          "zone": "CR",
          "address": "Panvel, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2596,
            12.021507999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PAZ",
          "name": "PAYANGADI",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.065488,
            10.840824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PEU",
          "name": "PERSSANNUR",
          "zone": "SR",
          "address": "Koodallur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.098643,
            25.486605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PFT",
          "name": "Pothahi",
          "zone": "ECR",
          "address": "Pothahi, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1099123547,
            25.5255878987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "PNUG",
          "name": "Punpun Ghat Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PMGN",
          "name": "Pimpalgaon",
          "zone": "CR",
          "address": "Pimpalgaon, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "POHE",
          "name": "Pohe",
          "zone": "CR",
          "address": "Pohe, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PPV",
          "name": "Pratapganj",
          "zone": "ECR",
          "address": "Pratapganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.51744099999999,
            22.388254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PLF",
          "name": "Piali",
          "zone": "ER",
          "address": "Champahati To Peyali Rd, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PRGT",
          "name": "Pargothan",
          "zone": "CR",
          "address": "Wardha, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PRKA",
          "name": "Parikha",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PRNC",
          "name": "Purnia Court",
          "zone": "ECR",
          "address": "Purnia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.927499,
            13.662347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PIL",
          "name": "Piler",
          "zone": "SCR",
          "address": "Piler Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.82319700000001,
            21.538300999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PIT",
          "name": "Palitana",
          "zone": "WR",
          "address": "Palitana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.599435,
            23.221017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PNCB",
          "name": "Pancheberia",
          "zone": "ER",
          "address": "Haranath Para Ln, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.366743,
            27.124794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PLC",
          "name": "Phalodi Junction",
          "zone": "NWR",
          "address": "Phalodi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.840166,
            22.342282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PAVI",
          "name": "Pavi",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6204606189,
            29.88477778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PHWR",
          "name": "Pehowa Road",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.71438400000001,
            31.052119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PPB",
          "name": "Partabpura",
          "zone": "NR",
          "address": "Partabpura, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.08063800000001,
            18.786579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PLH",
          "name": "Parlakhemundi",
          "zone": "ECoR",
          "address": "Paralakhemundi, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.545481,
            23.128660999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PDX",
          "name": "Payradanga",
          "zone": "ER",
          "address": "Payradanga, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.70440469360001,
            29.9189828048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PDS",
          "name": "Pindarsi",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.738083,
            8.709862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PCO",
          "name": "Palayankottai",
          "zone": "SR",
          "address": "Tirunelveli, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.39939700000001,
            24.866567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PMO",
          "name": "Pandoli",
          "zone": "NWR",
          "address": "State Highway 9, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.511715,
            25.159209999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PQE",
          "name": "Pawapuri Road",
          "zone": "ECR",
          "address": "Distt Nalanda, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.598318,
            24.864358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PNGM",
          "name": "PANCHGRAM",
          "zone": "NFR",
          "address": "Panchgram, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.83533,
            28.952153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PLS",
          "name": "PIPALSANA",
          "zone": "NER",
          "address": "State Highway 49, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.86677300480001,
            18.283703174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PJR",
          "name": "PANGRI",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.54001000000001,
            32.069052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "PLMX",
          "name": "PALAMPUR HIMACHAL",
          "zone": "NR",
          "address": "Palampur, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.4319,
            25.823775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PRU",
          "name": "PARLU",
          "zone": "NWR",
          "address": "Parlu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5313135,
            23.2603976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PJH",
          "name": "Pirjhalar",
          "zone": "WR",
          "address": "Ratlam, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.934426,
            22.345082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PPLI",
          "name": "PIPLI",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.648699,
            28.925112000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PRTP",
          "name": "PARTAPUR",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.971557,
            8.855091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PYM",
          "name": "PANDIYAPURAM",
          "zone": "SR",
          "address": "State HIghway 75, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.288498,
            31.264295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PJA",
          "name": "PAJIAN",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.259304,
            22.410159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RAMP",
          "name": "Rampuri",
          "zone": "SECR",
          "address": "Bamhani, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.37130900000001,
            27.087305999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PNGR",
          "name": "PINGORA",
          "zone": "WCR",
          "address": "SH 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.815973,
            22.301904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PUE",
          "name": "Palari",
          "zone": "SECR",
          "address": "State Highway 11A, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8118731085,
            28.4516032684
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PUF",
          "name": "Pauta",
          "zone": "NER",
          "address": "Pauta, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.49388,
            29.354607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PKDE",
          "name": "PILU KHERA",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.255615343,
            26.4688026951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PLWR",
          "name": "Phulwaria",
          "zone": "NER",
          "address": "Phulwaria, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.11383797849999,
            22.3172086118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PNJ",
          "name": "Padriganj",
          "zone": "SECR",
          "address": "Nainpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.80494999999999,
            22.633145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RAU",
          "name": "Rau",
          "zone": "WR",
          "address": "Rau, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.52731516850001,
            29.8508008993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "TIK",
          "name": "Tik",
          "zone": "NR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.188481,
            23.25057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PRDL",
          "name": "Paradol",
          "zone": "SECR",
          "address": "Manendragarh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.985675,
            10.874721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TUA",
          "name": "TIRUNNAVAYA",
          "zone": "SR",
          "address": "Thirunavaya, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.333771,
            9.449651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PNPR",
          "name": "PUNNAPURA",
          "zone": "SR",
          "address": "Alappuzha, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.342348,
            9.423187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PUPR",
          "name": "PUNNAPRA",
          "zone": "SR",
          "address": "Alappuzha, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.091535,
            25.552533999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PLP",
          "name": "PHULPUR",
          "zone": "NR",
          "address": "Phulpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.867081,
            25.467939
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PRG",
          "name": "PRAYAG",
          "zone": "NR",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.327309,
            25.790692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PMY",
          "name": "PALI MARWAR",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PRNG",
          "name": "Pritam Nagar",
          "zone": "WR",
          "address": "Ratlam, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PTG",
          "name": "Patna Ghat",
          "zone": "ECR",
          "address": "Sabalpur, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PTRT",
          "name": "Pathrot",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.28058300000001,
            24.359325000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PRTL",
          "name": "Pirtala",
          "zone": "ER",
          "address": "Pirtala, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.49578699999999,
            32.169809
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PMQ",
          "name": "Parna Nand",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PVI",
          "name": "Peravurani",
          "zone": "SR",
          "address": "Peravurani, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RAS",
          "name": "Ras",
          "zone": "WR",
          "address": "Bhadran, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.717145,
            16.522244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PKO",
          "name": "Palakollu",
          "zone": "SCR",
          "address": "Palakollu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.80435100000001,
            16.061989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "POA",
          "name": "Palikona",
          "zone": "SCR",
          "address": "Bhattiprolu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.854197,
            21.541244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PSK",
          "name": "Paradsinga Halt",
          "zone": "SECR",
          "address": "Paradsinga, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.31911,
            24.604506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PTKD",
          "name": "Patharkandi",
          "zone": "NFR",
          "address": "Patharkandi, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.268809,
            26.909188999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PKBS",
          "name": "Peeplee-ka-bas",
          "zone": "NWR",
          "address": "MDR 59, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0037506653,
            24.9531673111
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PNSA",
          "name": "Punsia",
          "zone": "ER",
          "address": "Punsia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7684964,
            24.1550254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KRSL",
          "name": "Krishnashilla",
          "zone": "ECR",
          "address": "Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.379064,
            30.341138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PTE",
          "name": "Patiala Cantt.",
          "zone": "NR",
          "address": "Patiala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.866432,
            29.628831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PLT",
          "name": "Pirthala Llauda",
          "zone": "NR",
          "address": "Tohana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8416662744,
            30.6369312333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RHW",
          "name": "ROHIRA GHALUGHRA",
          "zone": "NR",
          "address": "Sangrur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.695447,
            16.140169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PRKH",
          "name": "PARKANHATTI",
          "zone": "SWR",
          "address": "Parakanhatti, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.240156,
            26.397857000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RAKL",
          "name": "RETHORAKALAN",
          "zone": "NCR",
          "address": "Malanpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5736995709,
            13.2673774949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RDI",
          "name": "KARDI",
          "zone": "SWR",
          "address": "Karadi Cross, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.55550000000001,
            27.324191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RKR",
          "name": "RANIKUND RARAH",
          "zone": "WCR",
          "address": "Rarah, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.020731,
            26.049173999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RMGJ",
          "name": "RAMGANJ",
          "zone": "NR",
          "address": "State Highway 9, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.85347200000001,
            28.399583000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PT",
          "name": "PATLI",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.616541,
            24.956611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "POR",
          "name": "PIPALDA ROAD",
          "zone": "WCR",
          "address": "Baren, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.755565,
            28.350738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PTRD",
          "name": "PATAUDI ROAD",
          "zone": "NR",
          "address": "Hailley Mandi, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8751352301,
            19.953696573000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PSD",
          "name": "PARSODA",
          "zone": "SCR",
          "address": "Rotegaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.12177700000001,
            19.918567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "POZ",
          "name": "POTUL",
          "zone": "SCR",
          "address": "Lasur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.94859100000001,
            28.38702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PRKE",
          "name": "PURUA KHERA",
          "zone": "NR",
          "address": "Bijnour, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.107047,
            28.318709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RBK",
          "name": "REOTI BAHORA KHERA",
          "zone": "NR",
          "address": "Reoti B Khera, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.743187,
            16.178119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VMU",
          "name": "Vemuru",
          "zone": "SCR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.94717999999999,
            12.736973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "RDY",
          "name": "REDIPALAYAM",
          "zone": "SR",
          "address": "State Highway 58, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.93171099999999,
            24.42109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PPKD",
          "name": "PAPRAKUND",
          "zone": "ECR",
          "address": "Obra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.626565,
            16.635175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RAJP",
          "name": "RAJAPUR ROAD",
          "zone": "KR",
          "address": "Ratnagiri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.31039,
            25.347582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RANI",
          "name": "RANI",
          "zone": "NWR",
          "address": "Rania, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.347422,
            11.941779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PPNS",
          "name": "PAPPINISSERY",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.908499,
            27.435147999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PRZ",
          "name": "Parsa",
          "zone": "NER",
          "address": "Itwa Badhayan Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.875358,
            25.113462000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PSLI",
          "name": "Parsoli",
          "zone": "WCR",
          "address": "NH 76, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.828736,
            16.019794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RAL",
          "name": "Repalle",
          "zone": "SCR",
          "address": "Repalle, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82954600000001,
            22.670031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RJQ",
          "name": "Rajendranagar",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.10892366617918,
            31.059323805951863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RHU",
          "name": "Rahon",
          "zone": "NR",
          "address": "Rahon, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.522244,
            26.225359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RAHA",
          "name": "Raha",
          "zone": "NFR",
          "address": "Roha, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.068704,
            13.145847999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PRES",
          "name": "Pattabiram East",
          "zone": "SR",
          "address": "Pattabiram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.14960500000001,
            20.351679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "RCTC",
          "name": "Raghunathpur",
          "zone": "ECoR",
          "address": "Raghunathpur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.11887899999999,
            25.550023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PRBZ",
          "name": "Parsa Bazar",
          "zone": "ECR",
          "address": "NH 83, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RGV",
          "name": "Rathopur",
          "zone": "ECR",
          "address": "Saraygarh, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.521374,
            21.789139000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PRNR",
          "name": "Porjanpur",
          "zone": "?",
          "address": "National Highway 215, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.470826,
            26.266253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PPCK",
          "name": "Pipalwali Chowk",
          "zone": "NCR",
          "address": "Pipalwali Chowk, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.418359,
            28.313863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RNIR",
          "name": "Rajnarainpur",
          "zone": "NER",
          "address": "State Highway 90, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.09876768800001,
            25.488229642500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "PPN",
          "name": "Punpun",
          "zone": "ECR",
          "address": "Patna, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.060056,
            25.756709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RBN",
          "name": "Raghubans Nagar",
          "zone": "ECR",
          "address": "Raghubans Nagar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.826509,
            21.684348999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RMO",
          "name": "Ramakona",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.811256,
            27.576612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RAH",
          "name": "Ramgarh",
          "zone": "NCR",
          "address": "Alwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.02551828,
            25.3146062619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "TNRI",
          "name": "Teneri Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.53987000000001,
            32.068766000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "PTRJ",
          "name": "PATTI RAJPURA HALT",
          "zone": "NR",
          "address": "Majhairan, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4137045734,
            23.9143072844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PTWA",
          "name": "PATWARA",
          "zone": "WCR",
          "address": "Katni, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.592784,
            26.270537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PUY",
          "name": "Phulaguri",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83368499999999,
            25.979937999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RENH",
          "name": "RENHAT",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.609414,
            28.202779000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "RE",
          "name": "REWARI",
          "zone": "NWR",
          "address": "Rewari, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.607541,
            27.240827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RHG",
          "name": "RAJGARH",
          "zone": "NWR",
          "address": "Rajgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.974734,
            29.405815999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RMH",
          "name": "RANGMAHAL",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.352035,
            22.634966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RA",
          "name": "RAMPARDA",
          "zone": "WR",
          "address": "Surendranagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.887282,
            25.150233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RGLI",
          "name": "RANGAULI",
          "zone": "NCR",
          "address": "Mahoba, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.12223900000001,
            29.390223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "RMR",
          "name": "RAMNAGAR",
          "zone": "NER",
          "address": "Ramnagar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.76233599999999,
            23.579891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RKI",
          "name": "RAMKANALI JN",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.24423,
            15.334036
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "QLM",
          "name": "KULEM",
          "zone": "SWR",
          "address": "South Goa Distt., Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5309343896,
            25.274192601499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RRE",
          "name": "Rahul Road",
          "zone": "ECR",
          "address": "National Highway 31, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.39886600000001,
            29.185337
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RMSR",
          "name": "RAMSINGHPUR",
          "zone": "NWR",
          "address": "Ramsinghpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.617595,
            11.516715999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PYOL",
          "name": "PAYYOLI",
          "zone": "SR",
          "address": "Payyoli, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.529502,
            24.855344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RPB",
          "name": "Rupasibari",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1123604352,
            10.8413738184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PUM",
          "name": "PALLIPPURAM",
          "zone": "SR",
          "address": "Palakkad, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.41469699999999,
            25.033068999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RGD",
          "name": "Rajgir",
          "zone": "ECR",
          "address": "Rajgir, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.56360400000001,
            27.373379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RGS",
          "name": "Ringas Junction",
          "zone": "NWR",
          "address": "Ringas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.416927,
            24.572086000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RTBR",
          "name": "Ratabari",
          "zone": "NFR",
          "address": "Ratabari, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.95595800000001,
            25.988405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RGJI",
          "name": "RANGJULI",
          "zone": "NFR",
          "address": "Rangjuli Railway Station, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.057937,
            25.879328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RPLY",
          "name": "Rupauli",
          "zone": "ECR",
          "address": "Murliganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.46727399999999,
            27.235415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PW",
          "name": "Pirwa",
          "zone": "NWR",
          "address": "Degana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.856828,
            14.705815
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RDG",
          "name": "Rayadurg",
          "zone": "SWR",
          "address": "Rayadurg, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RPP",
          "name": "Ranu Pipri",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.11323059809999,
            25.5318741843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "RSMN",
          "name": "Ramgovindsingh Mahuli Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.47771200000001,
            28.033672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RJR",
          "name": "Rajaldesar",
          "zone": "NWR",
          "address": "Rajaldesar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.80838331540001,
            26.1808149265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RPV",
          "name": "Rajapatti",
          "zone": "NER",
          "address": "Musepur To Rajapatti Railway Statipn, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.78676899999999,
            26.36319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PUQ",
          "name": "Puranigudam",
          "zone": "NFR",
          "address": "Puranigudam, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.203446,
            26.232167999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PTRE",
          "name": "Patara",
          "zone": "NCR",
          "address": "Kanpur Nagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.127696,
            25.619125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RGJ",
          "name": "RaiGanj",
          "zone": "NFR",
          "address": "Raiganj, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.736073,
            20.454247000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TUD",
          "name": "Talodhi Road",
          "zone": "SECR",
          "address": "Nagbhir, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.941672,
            20.643085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RM",
          "name": "Rajmane",
          "zone": "CR",
          "address": "Kunzar Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.96208800000001,
            29.959833999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RMN",
          "name": "Raman",
          "zone": "NWR",
          "address": "Rama Mandi, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.772834,
            29.158401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "RPHR",
          "name": "Raipur Hariyana Junction",
          "zone": "NR",
          "address": "Hisar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.349742,
            29.763968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "QTP",
          "name": "Quatabpur",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.471651,
            27.971208999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RBJ",
          "name": "Raibojha",
          "zone": "NER",
          "address": "State Highway 26, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.17244099999999,
            26.721887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RWH",
          "name": "Rowriah Sdg",
          "zone": "NFR",
          "address": "Jorhat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.209877,
            30.032143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "RWL",
          "name": "RAIWALA",
          "zone": "NR",
          "address": "Rishikesh, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.635807,
            25.771723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RPI",
          "name": "RUPAHELI",
          "zone": "NWR",
          "address": "Bhilwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.68575799999999,
            29.575725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RNA",
          "name": "ROHANA KALAN",
          "zone": "NR",
          "address": "Muzaffarnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.356715,
            16.724871999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RKD",
          "name": "RUKADI",
          "zone": "CR",
          "address": "Kolhapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.107028,
            19.646129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RNE",
          "name": "RANJANI",
          "zone": "SCR",
          "address": "Jalna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.715179,
            25.868228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Nagaland",
          "code": "RXRX",
          "name": "RANGAPAHAR CRS",
          "zone": "NFR",
          "address": "Diphu Rd, Nagaland"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8305174701,
            30.2437913998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SAG",
          "name": "SANGRUR",
          "zone": "NR",
          "address": "Sangrur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.82182599999999,
            19.576592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SAH",
          "name": "SAPHALE",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.21524,
            23.75937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RUJ",
          "name": "RANUJ",
          "zone": "WR",
          "address": "Patan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.635054,
            14.625461
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RNR",
          "name": "RANIBENNUR",
          "zone": "SWR",
          "address": "Ranebennur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.974749,
            13.591101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RRB",
          "name": "BIRUR JN",
          "zone": "SWR",
          "address": "Birur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.425711,
            26.644544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RIGA",
          "name": "RIGA",
          "zone": "ECR",
          "address": "Barahwa, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.708219,
            11.350091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VMP",
          "name": "VALLAMPADUGAI",
          "zone": "SR",
          "address": "Vallampadugai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.306787,
            9.280973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "RMM",
          "name": "RAMESWARAM",
          "zone": "SR",
          "address": "Rameshwaram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.92457042261958,
            21.852581313966382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SAZ",
          "name": "Samni",
          "zone": "WR",
          "address": "Samni, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4598714294,
            29.8055980689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RPMN",
          "name": "RAMPURMANI HARA",
          "zone": "NR",
          "address": "Saharanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.739952,
            30.666125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SASN",
          "name": "SAS NAGAR MOHALI",
          "zone": "NR",
          "address": "Mohali, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.79191399999999,
            21.714732
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RNBD",
          "name": "RANA BORDI",
          "zone": "WR",
          "address": "State Highway 27, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.679535,
            29.090127000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "RKX",
          "name": "RUKHI",
          "zone": "NR",
          "address": "Sonepat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.61020425893366,
            28.072785335054363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RXW",
          "name": "Ratangarh West",
          "zone": "NWR",
          "address": "Ratangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.039896,
            24.203558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RNQ",
          "name": "RENUKUT",
          "zone": "ECR",
          "address": "Sonbhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.12357712000001,
            18.44649043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ROHA",
          "name": "ROHA",
          "zone": "CR",
          "address": "Roha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.57955000000001,
            28.890904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ROK",
          "name": "ROHTAK JN",
          "zone": "NR",
          "address": "Rohtak, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.850048,
            30.510636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RLS",
          "name": "ROMANA ALBEL SINGH",
          "zone": "NR",
          "address": "Kotkapura Jaitu Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.87966899999999,
            26.255836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RRS",
          "name": "Raghuraj Singh",
          "zone": "NR",
          "address": "Raghuraj Singh Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.717303,
            22.359871000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RUR",
          "name": "RANPUR",
          "zone": "WR",
          "address": "Ranpur, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RVT",
          "name": "Revelganj Ghat",
          "zone": "NER",
          "address": "Ghaghra Bank, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RW",
          "name": "Rankua",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SAHA",
          "name": "Sarahula",
          "zone": "ECR",
          "address": "Dildarnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.273323,
            26.960337
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SAD",
          "name": "Sonada",
          "zone": "NFR",
          "address": "Sonada, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.2858752405,
            26.9578306035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RJ",
          "name": "Rawat Ganj",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.797247,
            22.353228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RGX",
          "name": "Raghunathbari",
          "zone": "SER",
          "address": "Panskura - Tamluk Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3768327,
            23.06077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RNJ",
          "name": "Runija",
          "zone": "WR",
          "address": "Ujjain, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.325116,
            22.439466000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SAKA",
          "name": "Simariya Kajanwada",
          "zone": "SECR",
          "address": "Mandla Fort, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.433753,
            22.413546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SBGR",
          "name": "Subhas Gram",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.204293,
            24.750455
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RNV",
          "name": "Raniwara",
          "zone": "NWR",
          "address": "Raniwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.25620300000001,
            30.444112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RRW",
          "name": "Roranwala",
          "zone": "NR",
          "address": "Jandwala Bhimeshah, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.035777,
            24.405870999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RXN",
          "name": "Ramsan",
          "zone": "NWR",
          "address": "State Highway 27, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.671939,
            24.827278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SCA",
          "name": "SALCHAPRA",
          "zone": "NFR",
          "address": "Salchapra, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.549884,
            24.915225999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RSJ",
          "name": "RAYSER",
          "zone": "WCR",
          "address": "Guna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.167107,
            28.595813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "SDPR",
          "name": "SARDAR PATEL ROAD",
          "zone": "NR",
          "address": "Delhi Cantonment, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.285208,
            23.614369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SBD",
          "name": "SLEEMANABAD RD",
          "zone": "WCR",
          "address": "Sleemanabad, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.59565906430001,
            25.7938081327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SBM",
          "name": "SONBARSA KACHERI",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.023569,
            22.413052999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RY",
          "name": "RAYAKA",
          "zone": "WR",
          "address": "SH 1 Dhandhuka to Fadara Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.484558,
            26.498827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SCN",
          "name": "SONDHA ROAD",
          "zone": "NCR",
          "address": "National Highway 92, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4501792358,
            17.832927985
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BMPL",
          "name": "BRAHMANPALLI",
          "zone": "SCR",
          "address": "National Highway 7, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.379212,
            28.969281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "RUPC",
          "name": "RUDRAPUR CITY",
          "zone": "NER",
          "address": "Udham Singh Nagar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.111097,
            23.774579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SALE",
          "name": "SALAR",
          "zone": "ER",
          "address": "Salar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.43326400000001,
            19.448059999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SELU",
          "name": "SELU",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.641987,
            23.070758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SDGM",
          "name": "SARDARGRAM",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.200996,
            23.591585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SHBA",
          "name": "SAHEBTALA",
          "zone": "ER",
          "address": "Sahebtala, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.520002,
            23.2126
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SHRD",
          "name": "SAIJ SERTHA RD",
          "zone": "WR",
          "address": "Jhulasan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.33612949587405,
            21.91134392138467
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SINR",
          "name": "Sinor",
          "zone": "WR",
          "address": "Sinor, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.44911521,
            25.559103213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SAHR",
          "name": "SAHERI",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.228712,
            22.814558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SIU",
          "name": "Singur",
          "zone": "ER",
          "address": "Singur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.151919,
            18.894001999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RSYI",
          "name": "RASAYANI",
          "zone": "CR",
          "address": "Rasayani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.752769,
            16.720293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SED",
          "name": "SHEDBAL",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SDRN",
          "name": "Shendurni",
          "zone": "CR",
          "address": "Shendurni, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.277063,
            23.106248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RSG",
          "name": "RAMSAGAR",
          "zone": "SER",
          "address": "Bankura, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.632889,
            27.994397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RZJ",
          "name": "RAZAGANJ",
          "zone": "NER",
          "address": "Lakhimpur Kheri, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SGBA",
          "name": "Sir Gurudas Banerjee Halt",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.91106267820001,
            25.1344757598
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AHMP",
          "name": "ADARSH MANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SHKY",
          "name": "Saharsa Kutchery Halt",
          "zone": "ECR",
          "address": "Bihra Panchayat, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SHRG",
          "name": "Sri Ramgarh Halt",
          "zone": "NWR",
          "address": "Ramgarh Railway Station, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.290472144261,
            28.893890064746117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDMK",
          "name": "Sidmukh",
          "zone": "NWR",
          "address": "Sidhmukh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.031546,
            26.705766999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SALI",
          "name": "SALI",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.292124,
            24.27321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SBNM",
          "name": "Subarnamrigi",
          "zone": "ER",
          "address": "Police Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.138165,
            31.107469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SHZ",
          "name": "Summer Hill",
          "zone": "NR",
          "address": "Shimla, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.017775,
            25.412362
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RTR",
          "name": "RAMNATHPUR",
          "zone": "NER",
          "address": "Handia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.795126,
            22.498243000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SIPA",
          "name": "Singhookharia",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.519694,
            26.410576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RUSD",
          "name": "Runnisaidpur",
          "zone": "ECR",
          "address": "Runni, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.241169,
            31.110308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SDZ",
          "name": "Sindhar",
          "zone": "NR",
          "address": "Sindhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.785795,
            28.012356
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDF",
          "name": "Sudsar",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5964046204,
            27.5544208128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SIRD",
          "name": "Sird",
          "zone": "?",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.386989,
            28.631138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDLP",
          "name": "Sadulpur Junction",
          "zone": "NWR",
          "address": "Rajgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.85342399999999,
            22.698715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SFNR",
          "name": "Saifinagar Halt",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8605782111,
            28.4076088967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SAWN",
          "name": "Sohansra",
          "zone": "NWR",
          "address": "State Highway 24, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.296444,
            29.726968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SJM",
          "name": "Sajuma",
          "zone": "NR",
          "address": "High School Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.822659,
            28.918565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SEW",
          "name": "SEHAL",
          "zone": "NER",
          "address": "MDR 65W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.909935,
            16.914611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SGRE",
          "name": "SULGARE",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.335655,
            23.621619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SJS",
          "name": "SHAMLAJI ROAD",
          "zone": "NWR",
          "address": "State Highway 5, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.194338,
            22.693813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SGP",
          "name": "SOHAGPUR",
          "zone": "WCR",
          "address": "Sohagpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.643764,
            30.380452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SEQ",
          "name": "SEKHA",
          "zone": "NR",
          "address": "Off Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1564133228,
            21.9419101772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SMC",
          "name": "Samnapur",
          "zone": "SECR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.60279299999999,
            32.046378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "MNHL",
          "name": "MAJHAIRAN HIMACHAL",
          "zone": "NR",
          "address": "Majhairan, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.682861,
            8.607001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SGLM",
          "name": "SENGULAM",
          "zone": "SR",
          "address": "National Highway 7, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.991164,
            28.368424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DUB",
          "name": "DABTARA",
          "zone": "NR",
          "address": "Bisauli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.082999,
            23.692561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SHBL",
          "name": "SHIBLUN",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SIZ",
          "name": "Shrikhanda",
          "zone": "ER",
          "address": "Shrikhanda, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SJTR",
          "name": "Sojitra",
          "zone": "WR",
          "address": "Anand, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.15210099999999,
            25.841387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SKHK",
          "name": "Sukhasan Kothi",
          "zone": "ECR",
          "address": "Purnia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.497486,
            22.981944000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SEJ",
          "name": "SARKHEJ",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.594953,
            25.875207999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SHC",
          "name": "SAHARSA JN",
          "zone": "ECR",
          "address": "Saharsa, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.929931,
            26.608902
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SHTT",
          "name": "Silghat Town",
          "zone": "NFR",
          "address": "Silghat, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.42960699999999,
            26.7073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SGUT",
          "name": "SILIGURU TOWN",
          "zone": "NFR",
          "address": "Siligur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.413663,
            26.723204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SGUJ",
          "name": "SILIGURI JN",
          "zone": "NFR",
          "address": "Siligur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.46238562464714,
            29.550109363942525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SJSM",
          "name": "Salemgarhmasani",
          "zone": "NWR",
          "address": "Salemgarhmasani, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.874083,
            21.077673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SCH",
          "name": "SACHIN",
          "zone": "WR",
          "address": "Sachin, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.621561,
            31.343172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SCPD",
          "name": "SUCHIPIND",
          "zone": "NR",
          "address": "Jalandhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27389099999999,
            10.958125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SEV",
          "name": "SITHALAVAI",
          "zone": "SR",
          "address": "Krishnarayapuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.546234,
            17.201998999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SGR",
          "name": "SANGMESHWAR",
          "zone": "KR",
          "address": "NH 17, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.886759,
            21.728936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SGD",
          "name": "SONGADH",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.854024,
            25.132088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SHK",
          "name": "SHEIKPURA",
          "zone": "ECR",
          "address": "Sheikhpura, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SMDR",
          "name": "Simodara",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SMTN",
          "name": "Somthan",
          "zone": "CR",
          "address": "Somthan, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.334804,
            22.048501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SMSR",
          "name": "Samaswara",
          "zone": "SECR",
          "address": "SH 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.15337799999999,
            27.616275
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SIKR",
          "name": "Sikar Junction",
          "zone": "NWR",
          "address": "Sikar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.876271,
            20.735376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SHF",
          "name": "Shirud",
          "zone": "CR",
          "address": "Shirud, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.12423700000001,
            31.038759000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SGS",
          "name": "Shoghi",
          "zone": "NR",
          "address": "Shoghi, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.170322,
            29.515832999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SHN",
          "name": "Suchan Kotli",
          "zone": "NWR",
          "address": "Suchan, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.466557,
            22.293128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SJPR",
          "name": "Surjyapur",
          "zone": "ER",
          "address": "Surjyapur F, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8330358765,
            26.035816445600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SMKR",
          "name": "Sham Kauria",
          "zone": "NER",
          "address": "National Highway 28B, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.947251,
            30.080230999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SGA",
          "name": "Shergarh",
          "zone": "NWR",
          "address": "Bathinda, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.974841,
            17.45892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SLGE",
          "name": "SULERJAVALGE",
          "zone": "SWR",
          "address": "NH 13, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.36905499999999,
            16.582653999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AKVD",
          "name": "AKIVIDU",
          "zone": "SCR",
          "address": "Coast Andhra, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.203016,
            31.211713
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SQR",
          "name": "SULTANPUR LODI",
          "zone": "NR",
          "address": "Kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.21222487729999,
            19.5087818977
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SIF",
          "name": "SIRLI",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71804900000001,
            29.192626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SKF",
          "name": "SAKHOTI TANDA",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.316231,
            29.444596999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SMQL",
          "name": "SHAMLI",
          "zone": "NR",
          "address": "Shamli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.96766400000001,
            26.684155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMBJ",
          "name": "SHRI MAHABIRJI",
          "zone": "WCR",
          "address": "Karauli, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.108388,
            14.226683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SLU",
          "name": "SASALU",
          "zone": "SWR",
          "address": "State Highway 47, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1402539871633,
            28.560827504028268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SQK",
          "name": "Shankar",
          "zone": "NR",
          "address": "Jalandhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.275262,
            10.77933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SMM",
          "name": "SALIYAMANGALAM",
          "zone": "SR",
          "address": "NH 67, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.833241,
            21.748997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SKR",
          "name": "SAKHPUR",
          "zone": "WR",
          "address": "to railway station, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.669157,
            25.043599999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "SONW",
          "name": "SONWARSA HALT",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.130516,
            30.934311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SLR",
          "name": "Salogra",
          "zone": "NR",
          "address": "NH 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.87207,
            15.421498999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SOQ",
          "name": "SOMPUR ROAD",
          "zone": "SWR",
          "address": "Sompur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.820594,
            20.19165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SJN",
          "name": "SANJAN",
          "zone": "WR",
          "address": "Sanjan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.05375,
            20.971268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "SKND",
          "name": "Sukinda Road",
          "zone": "ECoR",
          "address": "Duburi, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.86771300000001,
            15.370790999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "SKVL",
          "name": "SANKVAL",
          "zone": "SWR",
          "address": "Sancoale, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.80617699999999,
            12.989191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SL",
          "name": "SURATHKAL",
          "zone": "KR",
          "address": "Surathkal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5962538599,
            15.437494047800001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "SJDA",
          "name": "SANJUJE DA AREY",
          "zone": "SWR",
          "address": "CURTORIM, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.905991,
            18.659388000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SKNR",
          "name": "Shakar Nagar",
          "zone": "SCR",
          "address": "Bodhan, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SOF",
          "name": "Saota",
          "zone": "ER",
          "address": "Saota, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.588939,
            16.858669000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SLI",
          "name": "SANGLI",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.1573548438,
            17.3606825795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SIW",
          "name": "SHIRRAVDE",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.17178775389999,
            17.963070994400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SLP",
          "name": "SALPA",
          "zone": "CR",
          "address": "SH 61, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.7274215704,
            28.4746409298
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SOI",
          "name": "Sonaripur",
          "zone": "NER",
          "address": "MDR 6C, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SNBD",
          "name": "Sunderabad",
          "zone": "WR",
          "address": "Ratlam, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SPS",
          "name": "Shripat Shrikhand",
          "zone": "ER",
          "address": "Shripat Shrikhand, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.666995,
            26.333838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SQW",
          "name": "Sidhwalia",
          "zone": "NER",
          "address": "Saran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.591856,
            20.747436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "SNKX",
          "name": "Sankra",
          "zone": "SECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.592671,
            13.927613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SME",
          "name": "Shimoga",
          "zone": "SWR",
          "address": "State Highway 57, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.01186038569999,
            25.285709039300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "SONH",
          "name": "Seonan Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.32876,
            22.224929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SNU",
          "name": "Sangrampur",
          "zone": "ER",
          "address": "State Highway 3, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0008507605,
            25.0658316907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SJJ",
          "name": "Sanjha",
          "zone": "ER",
          "address": "Sanjha, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.990043,
            26.503912999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SLON",
          "name": "Salona",
          "zone": "NFR",
          "address": "Salona, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.65749699999999,
            21.763167000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SJPA",
          "name": "Sujalpur",
          "zone": "?",
          "address": "Digha Contai Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.434638,
            25.078598999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SILO",
          "name": "Silao",
          "zone": "ECR",
          "address": "Nalanda, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.530032,
            25.273946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SOW",
          "name": "Suhsarai",
          "zone": "ECR",
          "address": "National Highway 31, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.656972,
            31.342859999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BLND",
          "name": "Bolinna Doaba",
          "zone": "NR",
          "address": "Patara Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.704734,
            24.122899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SKTN",
          "name": "Shakti Nagar",
          "zone": "ECR",
          "address": "Dist. Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.834297,
            22.90699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SOY",
          "name": "Sukrimangela",
          "zone": "SECR",
          "address": "Shikara - Dhooma Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.07770599999999,
            31.292873999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SQJ",
          "name": "Saila Khurd",
          "zone": "NR",
          "address": "Saila Khurd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.110741,
            31.257426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SNB",
          "name": "Satnaur Badesron",
          "zone": "NR",
          "address": "Satnaur Badesron, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.925046,
            21.387489000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SONR",
          "name": "Saoner Junction",
          "zone": "SECR",
          "address": "Saoner, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.196287,
            28.581210000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "SOJ",
          "name": "SAROJINI NAGAR",
          "zone": "NR",
          "address": "Sarojini Nagar, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.3763963708,
            21.758513999399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SPD",
          "name": "SUPEDI",
          "zone": "WR",
          "address": "Supedi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4494025903,
            29.689652272500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SNX",
          "name": "SANEH ROAD",
          "zone": "NR",
          "address": "Saneh Road, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8136981409,
            26.711946963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SRA",
          "name": "SEMRA",
          "zone": "ECR",
          "address": "National Highway 28A, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.28521798737347,
            25.86331602863504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SRSI",
          "name": "Sarsi",
          "zone": "ECR",
          "address": "State Highway 77, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72370699999999,
            27.116202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SNPR",
          "name": "SINGARPUR",
          "zone": "NCR",
          "address": "Rupbas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.62566,
            25.123246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SNYN",
          "name": "SONIYANA",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.6190324237,
            25.1813005966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SRJ",
          "name": "SHANKARGARH",
          "zone": "NCR",
          "address": "Shankargarh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.680541,
            27.024365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "STF",
          "name": "Sikta",
          "zone": "ECR",
          "address": "Sikta, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.73429978452623,
            28.30168415387253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRGH",
          "name": "Surajgarh",
          "zone": "NWR",
          "address": "Surajgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.793105,
            18.921849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SQE",
          "name": "SAMRLA",
          "zone": "SCR",
          "address": "Dharmabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.721146,
            26.8662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRK",
          "name": "SAMRAU",
          "zone": "NWR",
          "address": "State Highway 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.348341,
            31.974211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SOHL",
          "name": "Sohal",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.748458,
            27.883353999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRNK",
          "name": "Soron",
          "zone": "NER",
          "address": "Soron, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.895784,
            23.491604000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SNSN",
          "name": "SONASAN",
          "zone": "WR",
          "address": "Salal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.484397,
            23.033843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SRID",
          "name": "SHRIDHAM",
          "zone": "WCR",
          "address": "Shridham, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.94977499999999,
            22.613017999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SQL",
          "name": "SONTALAI",
          "zone": "WCR",
          "address": "Itarsi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.773299,
            28.781933000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SPZ",
          "name": "SAMPLA",
          "zone": "NR",
          "address": "Sampla, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.177296,
            23.796393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SNHR",
          "name": "SANKHAI",
          "zone": "WR",
          "address": "Patan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.160918,
            27.110416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SPK",
          "name": "SAPEKHATI",
          "zone": "NFR",
          "address": "Moran to Naharkatiya Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.031581,
            26.081657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SPRA",
          "name": "Sillipur",
          "zone": "NCR",
          "address": "State Highway 2, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84133899999999,
            19.082018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "STC",
          "name": "SANTA CRUZ",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.9546461121,
            18.4732023111
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SSV",
          "name": "SASVAD ROAD",
          "zone": "CR",
          "address": "Sasvad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.603023,
            26.117556999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SOU",
          "name": "Supaul",
          "zone": "ECR",
          "address": "Supaul, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.957197,
            21.724798000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SOJN",
          "name": "SIHOR GUJARAT",
          "zone": "WR",
          "address": "Shihor, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.41302,
            20.800933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRNG",
          "name": "Kargaon Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.990982,
            28.572634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SNDY",
          "name": "SANDAI",
          "zone": "NER",
          "address": "Sandai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.67934,
            16.111322
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNDD",
          "name": "SINDHUDURG",
          "zone": "KR",
          "address": "Oros, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.822277,
            25.548656
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SPGL",
          "name": "Sarai Gopal",
          "zone": "NR",
          "address": "Allahabad Bypass, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.56736599999999,
            28.543189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "STH",
          "name": "Sethal",
          "zone": "NER",
          "address": "Bareilly, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.52704197999999,
            25.395161271299997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SRMN",
          "name": "Semraon",
          "zone": "ECR",
          "address": "State Highway 12, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.647908,
            16.525488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "STPM",
          "name": "S NARAYANAPURAM",
          "zone": "SCR",
          "address": "Vijayawada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.272502,
            22.524414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SSP",
          "name": "Santoshpur",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.47988299999999,
            23.660992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SNDT",
          "name": "Sindri Town",
          "zone": "?",
          "address": "Sindri, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.748798,
            27.883694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRN",
          "name": "Soron",
          "zone": "NER",
          "address": "Soron, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.01247500000001,
            22.602487999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SNA",
          "name": "Sadanapura",
          "zone": "WR",
          "address": "Sadanapura, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.97751000000001,
            25.006278000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "WW",
          "name": "Wanabar",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.434523,
            22.346539999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SSRD",
          "name": "Shasan Road",
          "zone": "ER",
          "address": "Baruipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.90811500000001,
            22.301973999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SMTG",
          "name": "Saheed Matangini",
          "zone": "SER",
          "address": "Medinipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.70038600000001,
            25.675538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SOE",
          "name": "Sheopur Kalan",
          "zone": "NCR",
          "address": "Sheopur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.824623,
            28.179883999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMNN",
          "name": "Shri Makri Nath Nagar",
          "zone": "NWR",
          "address": "Churu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.609091,
            16.245325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AKU",
          "name": "ANGALAKUDURU",
          "zone": "SCR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4413220264,
            25.289429766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GTI",
          "name": "GHUTAI",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.425499,
            27.177901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SWAR",
          "name": "SEWAR",
          "zone": "WCR",
          "address": "Bharatpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.468139,
            27.052218
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "STSN",
          "name": "SHAITAN SINGH NAGAR",
          "zone": "NWR",
          "address": "State Highway 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.126194,
            23.79372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MHTR",
          "name": "MALIHATI TALIBPUR ROAD",
          "zone": "ER",
          "address": "Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.605353,
            27.18282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRRG",
          "name": "SURERGOTH",
          "zone": "NWR",
          "address": "State Highway 25, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.31266699999999,
            21.335082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SVKD",
          "name": "SAVAR KUNDLA",
          "zone": "WR",
          "address": "Savar Kundla, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.846279,
            28.431279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SSKA",
          "name": "SISARKA",
          "zone": "NR",
          "address": "State Highway 43, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.624105,
            25.697556000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SSR",
          "name": "SARERI",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.032634,
            25.375998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRNT",
          "name": "SARNATH",
          "zone": "NER",
          "address": "Varanasi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.048971,
            20.674328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SVW",
          "name": "SHIVNI SHIVAPUR",
          "zone": "SCR",
          "address": "Akola, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.939328,
            15.291947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "SRVX",
          "name": "SURAVALI",
          "zone": "KR",
          "address": "Margao, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.394212,
            23.542306999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SXS",
          "name": "SHOBHASAN",
          "zone": "WR",
          "address": "State Highway 41, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.79053,
            21.500203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "STBJ",
          "name": "Sitabanji",
          "zone": "ECoR",
          "address": "National Highway 215, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.10866,
            15.264064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "SVM",
          "name": "SANVERDAM CHUCH",
          "zone": "SWR",
          "address": "Curchorem, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.67814298520001,
            16.001728105999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SUL",
          "name": "SULADHAL",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SURR",
          "name": "Surera",
          "zone": "NWR",
          "address": "Surera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.468742,
            26.880144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SVQ",
          "name": "SIVOK",
          "zone": "NFR",
          "address": "Sevoke, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.294121,
            16.309801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VMLD",
          "name": "VEMULURIPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SWQ",
          "name": "Sangwi",
          "zone": "CR",
          "address": "Sangwi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.54695000000001,
            31.095558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SWG",
          "name": "Sidhwan",
          "zone": "NR",
          "address": "Sidhwan, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.952587,
            25.132853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SRY",
          "name": "SIRARI",
          "zone": "ECR",
          "address": "State Highway 6, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.679664,
            30.917223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SWX",
          "name": "Saidanwala",
          "zone": "NR",
          "address": "Saidanwala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.49906899999999,
            9.852970000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SVGA",
          "name": "Sivaganga",
          "zone": "SR",
          "address": "SH 33, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.034944,
            29.539153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SSA",
          "name": "Sirsa",
          "zone": "NWR",
          "address": "Sirsa, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.00914999999999,
            24.11526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQA",
          "name": "Matoda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.471495,
            20.994517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SVB",
          "name": "Savni",
          "zone": "WR",
          "address": "Maljinjava, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.34871700000001,
            26.226705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SVC",
          "name": "Siwan Kachari",
          "zone": "NER",
          "address": "Siwan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.906323,
            8.622475999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SVV",
          "name": "Srivaikuntam",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.65894399999999,
            16.535628999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SVDC",
          "name": "Sivadevunichkla",
          "zone": "SCR",
          "address": "P.P.Road, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.966232,
            28.382338
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "STNL",
          "name": "Satnali",
          "zone": "NWR",
          "address": "Satnali, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.2281676154,
            21.6079811042
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AEP",
          "name": "Amreli Para",
          "zone": "WR",
          "address": "Amreli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.572957,
            18.306268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SSF",
          "name": "Sirsuphal",
          "zone": "CR",
          "address": "Daund, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.074567,
            22.17581
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SWD",
          "name": "Sanawad",
          "zone": "WR",
          "address": "Sanawad, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.612939,
            29.389883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SXE",
          "name": "SILA KHERI HALT",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TAM",
          "name": "Tilaivilagam",
          "zone": "SR",
          "address": "Pattukkotai-Thiruthuraipoondi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.915553,
            22.587179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TKF",
          "name": "Taki Road",
          "zone": "ER",
          "address": "Taki, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.746072,
            10.623543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TNLR",
          "name": "Thondaimanallur",
          "zone": "SR",
          "address": "Nallur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.15003700000001,
            18.352916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TER",
          "name": "THAIR",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TCT",
          "name": "Tiruchitrambalam",
          "zone": "SR",
          "address": "Musiri - Kulithalai - Manapparai - Pudukottai - Alangudi - Peravoorani - Sethubavachatram Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.726353,
            11.240305
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SY",
          "name": "SIRKAZHI",
          "zone": "SR",
          "address": "Sirkazhi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.680184,
            23.38018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TNX",
          "name": "Tarak Nagar",
          "zone": "ER",
          "address": "Shivniwas Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.366053,
            30.302839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "TAPA",
          "name": "TAPA",
          "zone": "NR",
          "address": "Tapa Mandi, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.97958463810001,
            25.5394209652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SYC",
          "name": "SARAI CHANDI",
          "zone": "NR",
          "address": "Phulpur, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "THZI",
          "name": "Takazhi",
          "zone": "SR",
          "address": "Takazhi, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "TJNR",
          "name": "Taj Nagar",
          "zone": "NR",
          "address": "Gurgaon, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.9344515665,
            24.7176851264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SXP",
          "name": "SANKOPARA",
          "zone": "ER",
          "address": "State Highway 5, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2294,
            22.375860000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TBN",
          "name": "TIMARNI",
          "zone": "WCR",
          "address": "Timarni, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.7297354559,
            22.2346247943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SYQ",
          "name": "SINHAN",
          "zone": "WR",
          "address": "SH 6, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.50160639360547,
            29.54286582323833
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TIBI",
          "name": "Tibi",
          "zone": "NWR",
          "address": "Tibi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72944226749999,
            8.7841938937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TAY",
          "name": "TALAIYUTHU",
          "zone": "SR",
          "address": "Thalaiyuthu, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.707979,
            8.73636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TEN",
          "name": "TIRUNELVELI",
          "zone": "SR",
          "address": "Tirunelveli, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.23297400000001,
            25.539724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SYH",
          "name": "SAIYEDPUR BHTRI",
          "zone": "NER",
          "address": "Saidpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.964562,
            14.399208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "THN",
          "name": "TOLAHUNSE",
          "zone": "SWR",
          "address": "Tholahunase, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.862021,
            26.195595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SZR",
          "name": "SARUPATHAR",
          "zone": "NFR",
          "address": "Sarupathar Road Thana Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.733005,
            24.773701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SYL",
          "name": "SALPURA",
          "zone": "WCR",
          "address": "Kawari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.889577,
            26.554730000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TIW",
          "name": "TIVARI",
          "zone": "NWR",
          "address": "Tinwari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.880597,
            10.97954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TA",
          "name": "TANUR",
          "zone": "SR",
          "address": "Malappuram, Kerala"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TLI",
          "name": "Tulwara Jhil",
          "zone": "NWR",
          "address": "Tibi Railway Station, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.75594199999999,
            22.358169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "TABU",
          "name": "Talaburu",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TNK",
          "name": "Tirunellikaval",
          "zone": "SR",
          "address": "State Highway 23, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.571554,
            28.262787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KGBS",
          "name": "Kishangarh Balawas",
          "zone": "NWR",
          "address": "Kishengarh, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.245466,
            24.465833
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "TBX",
          "name": "Tilbhum",
          "zone": "NFR",
          "address": "National Highway 44, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.61636,
            16.680149999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AL",
          "name": "ATTILI",
          "zone": "SCR",
          "address": "West Godavari, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.60875899999999,
            22.343647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TLX",
          "name": "Taldi",
          "zone": "ER",
          "address": "Taldi Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.677055,
            13.023783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TKO",
          "name": "TAKKOLAM",
          "zone": "SR",
          "address": "Arakkonam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.038112915,
            25.3552301906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TEA",
          "name": "Taregna",
          "zone": "ECR",
          "address": "Taregna, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.617734,
            23.861247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "TLMR",
          "name": "Teliamura",
          "zone": "NFR",
          "address": "Teliamura, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0262456,
            24.8058876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TELY",
          "name": "Telia",
          "zone": "?",
          "address": "Telia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.01452424259999,
            26.139957444700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SZ",
          "name": "SALAWAS",
          "zone": "NWR",
          "address": "Luni, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.657383,
            26.218533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TB",
          "name": "Tharbitia",
          "zone": "ECR",
          "address": "State Highway 76, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.52720500000001,
            25.171993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TNGI",
          "name": "Tungi Halt",
          "zone": "?",
          "address": "Bihar Sharif, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.117118,
            8.501774
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TCN",
          "name": "Tiruchendur",
          "zone": "SR",
          "address": "Tiruchchendur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.60210496000946,
            25.571721452321867
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TRG",
          "name": "Tarighat",
          "zone": "ECR",
          "address": "Ghazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.86436,
            24.99538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TKYR",
          "name": "TIKARIA",
          "zone": "WCR",
          "address": "MDR 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4437843848,
            21.9272990641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TLV",
          "name": "TALVADYA",
          "zone": "WCR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.16145411878824,
            29.101436164956112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TSD",
          "name": "Tahsil Bhadra",
          "zone": "NWR",
          "address": "Bhadra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.86933565,
            16.84890756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BLNK",
          "name": "BELANKI",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.590775,
            20.06888
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TR",
          "name": "TARU",
          "zone": "SCR",
          "address": "Rotegaon Railway Station, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.64430899999999,
            29.766916000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "THJ",
          "name": "TALHERI BUZURG",
          "zone": "NR",
          "address": "SH 59, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.401101,
            24.821998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TIA",
          "name": "TILAYA",
          "zone": "ECR",
          "address": "State Highway 8, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.475987,
            13.256152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TTR",
          "name": "TIPTUR",
          "zone": "SWR",
          "address": "Tiptur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.10243899999999,
            13.333033
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TK",
          "name": "TUMKUR",
          "zone": "SWR",
          "address": "Tumkur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.904581,
            29.7266
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "TUN",
          "name": "TOHANA",
          "zone": "NR",
          "address": "Tohana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.9222836917,
            26.234006114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TKC",
          "name": "THAKURKUCHI",
          "zone": "NFR",
          "address": "Hatisila, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.145808,
            8.808373999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TME",
          "name": "TUTI MELUR",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.199758,
            26.602048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TTB",
          "name": "TITABAR",
          "zone": "NFR",
          "address": "Titabor, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.36337599999999,
            17.129756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TKR",
          "name": "TAKARI",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.69366422,
            10.80725953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TPE",
          "name": "TIRUCHIRAPPALLI PALAKKARAI",
          "zone": "SR",
          "address": "Tiruchirapalli, Tamil Nadu"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TPN",
          "name": "Tapona",
          "zone": "CR",
          "address": "Tapona, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9932459985,
            25.108394454899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TKLE",
          "name": "Tikani",
          "zone": "ER",
          "address": "Tikani, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TTP",
          "name": "Tiruturaipudi Junction",
          "zone": "SR",
          "address": "Thiruthuraipoondi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.694891,
            29.353194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRPR",
          "name": "SARUPSAR JN",
          "zone": "NWR",
          "address": "MDR 103, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.31541102290001,
            25.688767621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "AYRN",
          "name": "AKSHAYWAT RAI NAGAR",
          "zone": "ECR",
          "address": "Vaishali, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.914093,
            14.214011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TLGP",
          "name": "Talguppa",
          "zone": "SWR",
          "address": "Talaguppa, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.92363399999999,
            30.861364000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "TWB",
          "name": "Talwandi",
          "zone": "NR",
          "address": "Talwandi Bhai, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.086947,
            30.463401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "TQL",
          "name": "Theh Qalandar",
          "zone": "NR",
          "address": "State Highway 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.836307,
            16.485676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TGU",
          "name": "TARIGOPPULA",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.87610400000001,
            15.62955
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "THVM",
          "name": "THIVIM",
          "zone": "KR",
          "address": "North Goa, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.812209,
            13.710886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TKE",
          "name": "Tarikere Junction",
          "zone": "SWR",
          "address": "Tarikere, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.829167,
            12.963579000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TOK",
          "name": "THOKUR",
          "zone": "KR",
          "address": "Mangalore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.858222,
            12.818211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TKOT",
          "name": "TOKKOTTU",
          "zone": "SR",
          "address": "Ullal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.492987,
            11.75314
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TLY",
          "name": "THALASSERY",
          "zone": "SR",
          "address": "Kannur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.92186500000001,
            10.917757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TIR",
          "name": "TIRUR",
          "zone": "SR",
          "address": "Malappuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.24008199999999,
            12.978438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TRMN",
          "name": "Taramani",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.925812,
            21.164083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "TGRL",
          "name": "Tangiriapal",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.394398,
            26.438991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "THE",
          "name": "Thawe Junction",
          "zone": "NER",
          "address": "Gopalganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.824336,
            29.966374000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "TNDE",
          "name": "Thanesar City",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.13844220089999,
            21.9959060669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TTW",
          "name": "Titwa",
          "zone": "SECR",
          "address": "Lamta, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.91914399999999,
            22.275813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TMZ",
          "name": "Tamluk",
          "zone": "SER",
          "address": "Tamluk, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.487037,
            27.824805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TLC",
          "name": "Talchhapar",
          "zone": "NWR",
          "address": "Sujangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8507402155,
            26.3568878952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "THR",
          "name": "Thara",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.73774364929999,
            25.3152769684
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "THEA",
          "name": "Thalera",
          "zone": "WCR",
          "address": "Talera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.932709,
            29.143805999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "ALJ",
          "name": "ALAI",
          "zone": "NER",
          "address": "National Highway 74, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.97927499999999,
            23.087044000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "UDM",
          "name": "UNDASA MADHAWPU",
          "zone": "WR",
          "address": "State Highway 18, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.11757300000001,
            24.548879000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TZR",
          "name": "TURKI ROAD",
          "zone": "WCR",
          "address": "Rewa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.642724,
            18.184369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UPI",
          "name": "UPLAI",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.94796099999999,
            23.351347999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TOD",
          "name": "TALOD",
          "zone": "WR",
          "address": "Talod, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1442991,
            28.825698499999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BNWC",
          "name": "Bhiwani City",
          "zone": "NWR",
          "address": "Bhiwani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.63389799999999,
            10.764347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TVR",
          "name": "THIRUVARUR JN",
          "zone": "SR",
          "address": "Thiruvarur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.58327200000001,
            29.915727999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TPZ",
          "name": "TAPRI JN",
          "zone": "NR",
          "address": "Saharanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.79082100000001,
            20.153499
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UBR",
          "name": "UMBARGAM ROAD",
          "zone": "WR",
          "address": "Umbergaon, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.78774656355381,
            26.617781357717547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TZTB",
          "name": "Tezpore",
          "zone": "NFR",
          "address": "Tezpur, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.44363799999999,
            25.788344000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "UTL",
          "name": "UTARLAI",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.25119000000001,
            12.989302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TYMR",
          "name": "Tiruvanmiyur",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.047264,
            26.295354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TPK",
          "name": "TIPKAI",
          "zone": "NFR",
          "address": "Dhubri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.077782,
            12.238103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TNM",
          "name": "TIRUVANNAMALAI",
          "zone": "SR",
          "address": "Thiruvannamalai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.258693,
            9.821456999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TVN",
          "name": "TIRUPPUVANAM",
          "zone": "SR",
          "address": "Tiruppuvanam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.139253,
            31.0758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "TVI",
          "name": "Taradevi",
          "zone": "NR",
          "address": "Shimla, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.981217,
            28.378823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TQN",
          "name": "TIKUNIA",
          "zone": "NER",
          "address": "Tikapur Municipality, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.148864,
            23.822157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TYAE",
          "name": "TENYA",
          "zone": "ER",
          "address": "Tenya, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UMPD",
          "name": "Umarpada",
          "zone": "WR",
          "address": "Umarpada to Jumavadi, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UNI",
          "name": "Unai Vansada Road",
          "zone": "WR",
          "address": "Gujarat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.022581618,
            20.8165878335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AJU",
          "name": "Arjuni",
          "zone": "SECR",
          "address": "Gondia, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.344217489,
            9.773153933800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TPC",
          "name": "TIRUPPACHETTI",
          "zone": "SR",
          "address": "Thiruppachethi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.311179,
            25.526193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TRN",
          "name": "TARAON",
          "zone": "NER",
          "address": "Tranav Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.670581,
            21.427444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TTZ",
          "name": "Tukaithad",
          "zone": "SCR",
          "address": "Akot, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.929175,
            12.754406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VB",
          "name": "VILLIYAMBAKKAM",
          "zone": "SR",
          "address": "State Highway 58, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.643654,
            31.860857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "UCB",
          "name": "UNCHI BASSI",
          "zone": "NR",
          "address": "National Highway 1A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.705115,
            21.905096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UJ",
          "name": "UJALVAV",
          "zone": "WR",
          "address": "State Highway 31, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.674075,
            16.752035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TNKU",
          "name": "TANUKU",
          "zone": "SCR",
          "address": "131, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.813991,
            16.488886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TNRU",
          "name": "TENNERU",
          "zone": "SCR",
          "address": "Manthena, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.68336699999999,
            26.436293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TRBE",
          "name": "Tarabari",
          "zone": "NFR",
          "address": "Nagaon, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.00541,
            26.135912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TTLA",
          "name": "TETELIA",
          "zone": "NFR",
          "address": "Tetelia-Maloibari Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.08496134640001,
            22.055305150200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "UIH",
          "name": "Umaria Ispa Halt",
          "zone": "SECR",
          "address": "Umaria Ispa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.879298,
            21.874783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "ULA",
          "name": "Umra Nala",
          "zone": "SECR",
          "address": "Umranala Station, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.678489,
            12.168831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TXM",
          "name": "TANDAVAPURA",
          "zone": "SWR",
          "address": "Thandavapura, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.954267,
            30.845271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "TSL",
          "name": "Taksal",
          "zone": "NR",
          "address": "Parwanoo, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.381517,
            22.153761
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TWV",
          "name": "Thuwavi",
          "zone": "WR",
          "address": "SH 11, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.356367,
            27.4864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TSK",
          "name": "Tinsukia Junction",
          "zone": "NFR",
          "address": "Tinsukia, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.017399,
            20.396528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KDRP",
          "name": "Kandarpur",
          "zone": "ECoR",
          "address": "Jagatsinghpur Rd, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.12594999999999,
            27.189993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UB",
          "name": "Uska Bazar",
          "zone": "NER",
          "address": "State Highway 1A, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.694727,
            11.404584999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "UAM",
          "name": "Udagamandalam",
          "zone": "SR",
          "address": "Ooty, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.070201,
            11.931538000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TPP",
          "name": "Toppur",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.853196,
            21.689791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VDV",
          "name": "Vadiya Devli",
          "zone": "WR",
          "address": "Vadiya, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.232537,
            23.653692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "UKA",
          "name": "UKHRA",
          "zone": "ER",
          "address": "Ukhra, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.27447,
            19.532217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UPR",
          "name": "USMANPUR",
          "zone": "SCR",
          "address": "D1, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.01509300000001,
            9.30813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "UCP",
          "name": "UCHIPPULI",
          "zone": "SR",
          "address": "Uchipuli, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.79457,
            24.38137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "UHR",
          "name": "UNCHHERA",
          "zone": "WCR",
          "address": "Unchhera, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.022663,
            18.248154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UMD",
          "name": "USMANABAD",
          "zone": "CR",
          "address": "National Highway 211, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.08875499999999,
            21.255951999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "WGA",
          "name": "WAGHODA",
          "zone": "CR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.822126,
            16.669705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "UGR",
          "name": "UGAR KHURD",
          "zone": "SWR",
          "address": "Belgaum, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.04360399999999,
            23.948406000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VAE",
          "name": "Vadali",
          "zone": "WR",
          "address": "Vadali, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VASO",
          "name": "Vaso",
          "zone": "WR",
          "address": "Rampur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.64117200000001,
            18.929472999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VDGN",
          "name": "VADGAON NILA",
          "zone": "SCR",
          "address": "State Highway 169, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "VDNP",
          "name": "Baijnath Andoli",
          "zone": "ECR",
          "address": "Baijnath Andoli, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.147986,
            20.103101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WHM",
          "name": "WASHIM",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VEP",
          "name": "Velpuru Road",
          "zone": "SCR",
          "address": "Station Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.76033699999999,
            19.754447000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UOI",
          "name": "UMROLI",
          "zone": "WR",
          "address": "Boisar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84363,
            19.099273999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VLP",
          "name": "VILE PARLE",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VLC",
          "name": "Velachha",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VLN",
          "name": "Vilegaon",
          "zone": "CR",
          "address": "Vilegaon, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VMM",
          "name": "Valaramanikkam",
          "zone": "SR",
          "address": "State Highway 28, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.43129599999999,
            23.800674
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VRX",
          "name": "VARAHI",
          "zone": "WR",
          "address": "Banaskantha, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VOL",
          "name": "Virol",
          "zone": "WR",
          "address": "Anand, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.90912700000001,
            20.374337999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VAPI",
          "name": "VAPI",
          "zone": "WR",
          "address": "Valsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.934385,
            13.667160999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KRNH",
          "name": "Karanahalli",
          "zone": "SWR",
          "address": "Bangalore Honavar Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.918698,
            20.461236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UVD",
          "name": "UDVADA",
          "zone": "WR",
          "address": "Udvada, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.771733,
            13.336341000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "UD",
          "name": "UDUPI",
          "zone": "KR",
          "address": "Udupi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21921699999999,
            12.967092000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VLCY",
          "name": "Velachery",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.530022,
            25.573159999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "VNN",
          "name": "BHANAUR",
          "zone": "NR",
          "address": "State Highway 36, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.93641500000001,
            20.874651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VDH",
          "name": "VEDCHHA",
          "zone": "WR",
          "address": "Navsari, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.492663,
            21.611243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VAL",
          "name": "VADAL",
          "zone": "WR",
          "address": "Vadal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.931669,
            31.665699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "VKA",
          "name": "Verka Junction",
          "zone": "NR",
          "address": "Amritsar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.883133,
            29.498980000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "UKN",
          "name": "Uklana",
          "zone": "NR",
          "address": "State Highway 2, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.80234268800001,
            25.601974857400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "VPN",
          "name": "VIDYAPATINAGAR",
          "zone": "ECR",
          "address": "Raod For VIDYAPATI DHAM, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.780125,
            16.492803000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "UPL",
          "name": "UPPALUR",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.12425499999999,
            15.377158999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "UNK",
          "name": "UNKAL",
          "zone": "SWR",
          "address": "Hubli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.85477900000001,
            12.791319999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ULL",
          "name": "ULLAL",
          "zone": "SR",
          "address": "Someshwar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.05519899999999,
            21.746198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VTJ",
          "name": "VARTEJ",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.383365,
            22.269458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "UTN",
          "name": "Uttar Radhanagar Halt",
          "zone": "ER",
          "address": "Sherpur Dhamua Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.252871,
            10.788139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VDKS",
          "name": "Vadanam Kurussi Halt",
          "zone": "SR",
          "address": "Shornur Perinthalmanna Hwy, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.036053,
            20.819025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UNA",
          "name": "Una",
          "zone": "WR",
          "address": "Una, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.27566,
            31.478782000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "UHL",
          "name": "Una Himachal",
          "zone": "NR",
          "address": "Una, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.119782,
            22.693375999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UMH",
          "name": "Umreth",
          "zone": "WR",
          "address": "Umreth, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.299391,
            26.804337999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UGU",
          "name": "Ugu",
          "zone": "NR",
          "address": "Unnao, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.251864,
            30.074509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "VRH",
          "name": "VIRBHADRA",
          "zone": "NR",
          "address": "Rishikesh, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3552,
            23.413186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "VSPR",
          "name": "BISHNUPRIYA",
          "zone": "ER",
          "address": "Nabadwip, West Bengal"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VRJ",
          "name": "Vadaj",
          "zone": "WR",
          "address": "State Highway 160, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VRKD",
          "name": "Varkhedi",
          "zone": "CR",
          "address": "Jalgaon, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VSI",
          "name": "Vishvamitri",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.796975,
            10.46709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VEL",
          "name": "Vellanur",
          "zone": "SR",
          "address": "Pudukkottai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.97657,
            18.317377999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "YSI",
          "name": "YEDESHI",
          "zone": "CR",
          "address": "National Highway 211, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VUL",
          "name": "Virul",
          "zone": "CR",
          "address": "Wardha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.61399,
            8.376159999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VLY",
          "name": "VALLIYUR",
          "zone": "SR",
          "address": "Vallioor, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.125213,
            12.923646999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VT",
          "name": "VELLORE TOWN",
          "zone": "SR",
          "address": "Vellore - 632008, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.502962,
            22.79511
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VKG",
          "name": "Vavadi Khurd",
          "zone": "WR",
          "address": "National Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.455417,
            17.972607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "WDR",
          "name": "WADIARAM",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.704026,
            11.765793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VKP",
          "name": "VARAKALPATTU",
          "zone": "SR",
          "address": "State Highway 9, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.911814,
            9.337064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VTV",
          "name": "VALANTARAVAL",
          "zone": "SR",
          "address": "National Highway 49, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.575526,
            22.197917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VQD",
          "name": "VIRAMDAD",
          "zone": "WR",
          "address": "Khambhalia-Dwarka (SH 6), Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.171511,
            18.398719
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ABLE",
          "name": "AMBALE",
          "zone": "CR",
          "address": "Malshiras Rd, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WRD",
          "name": "Warudkhed",
          "zone": "CR",
          "address": "Wardha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.849578,
            19.517672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VTN",
          "name": "VAITARNA",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "XBAC",
          "name": "Bachharpur Halt",
          "zone": "ECR",
          "address": "Bachharpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.08846,
            13.439281999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "VNR",
          "name": "DEVANUR",
          "zone": "SWR",
          "address": "National Highway 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.034555,
            27.195058000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "VKNR",
          "name": "VALMIKINAGAR RD",
          "zone": "ECR",
          "address": "National Highway 28B, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.765232,
            11.28603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "WH",
          "name": "WEST HILL",
          "zone": "SR",
          "address": "Calicut, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.441677,
            21.062395000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VVV",
          "name": "VAVERA",
          "zone": "WR",
          "address": "Rajula-Vijapadi Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.36512300000001,
            22.833389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VLA",
          "name": "BAVLA",
          "zone": "WR",
          "address": "Bavla, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.542504,
            8.088028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CAPE",
          "name": "KANYAKUMARI",
          "zone": "SR",
          "address": "Kanyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.596052,
            24.932654
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "XSAD",
          "name": "SADIPUR HALT",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.260513,
            11.188459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VNB",
          "name": "Vaniyambalam",
          "zone": "SR",
          "address": "Vaniyambalam, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.30107100000001,
            23.065499000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "VSU",
          "name": "BISHNUPUR",
          "zone": "SER",
          "address": "Bishnupur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.77461,
            11.264509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VLL",
          "name": "VELLAYIL",
          "zone": "SR",
          "address": "Calicut, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.719118,
            22.416769
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VJK",
          "name": "VEJALKA",
          "zone": "WR",
          "address": "State Highway 38, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.165859,
            10.939107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VRQ",
          "name": "VIRARAKKIYAM",
          "zone": "SR",
          "address": "Verarakiyam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.775695,
            24.470775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ABR",
          "name": "ABU ROAD",
          "zone": "NWR",
          "address": "Sirohi Dist, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.31783892586827,
            21.45148506222334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ZKV",
          "name": "Zankhvav",
          "zone": "WR",
          "address": "SH 166, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.11973326839507,
            20.387302241829445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "YTL",
          "name": "Yavatmal",
          "zone": "CR",
          "address": "Yavatmal, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.252495,
            10.842075999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VPZ",
          "name": "Vailapuzha",
          "zone": "SR",
          "address": "Vallapuzha, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.724646,
            20.967861000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VLDR",
          "name": "Valadar",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.205261,
            20.740984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BXA",
          "name": "Balod",
          "zone": "SECR",
          "address": "Balod, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.622206,
            12.823403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "YDK",
          "name": "Yedekumeri",
          "zone": "SWR",
          "address": "Yedukumeri Station, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.63254,
            16.542123999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VVM",
          "name": "Viravasaram",
          "zone": "SCR",
          "address": "Palakollu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.793468,
            21.714204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VVD",
          "name": "Vavdi",
          "zone": "WR",
          "address": "State Highway 31, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.471049,
            16.825374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "YP",
          "name": "ERRUPALEM",
          "zone": "SCR",
          "address": "Yerrupalem, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.99559,
            17.504915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AHD",
          "name": "AHERWADI",
          "zone": "SWR",
          "address": "Sholapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.74703099999999,
            17.860901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "YGL",
          "name": "YELGUR",
          "zone": "SCR",
          "address": "Yelgur Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.699156,
            21.944012999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ALMR",
          "name": "ALAMPUR",
          "zone": "WR",
          "address": "State Highway 31, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2501103802,
            19.1668082025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WNG",
          "name": "WANEGAON",
          "zone": "SCR",
          "address": "Chudawa, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.962635,
            20.06638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WANI",
          "name": "WANI",
          "zone": "CR",
          "address": "Wani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6767002,
            15.958918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BDM",
          "name": "BADAMI",
          "zone": "SWR",
          "address": "Bagalkot, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7002383,
            16.1787931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BGK",
          "name": "BAGALKOT",
          "zone": "SWR",
          "address": "Bagalkot, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.905957,
            28.405553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AFR",
          "name": "ASAFPUR",
          "zone": "NR",
          "address": "Bijnor, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.81836999999999,
            12.797321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "WJ",
          "name": "WALAJABAD",
          "zone": "SR",
          "address": "Walajabad, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.96351299999999,
            20.624198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WSA",
          "name": "Wadsa",
          "zone": "SECR",
          "address": "Chandrapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6740109,
            23.142728299999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BHTN",
          "name": "BHITONI",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.468143,
            28.58606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AMA",
          "name": "ATAMANDA",
          "zone": "NER",
          "address": "State Highway 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.71400799999999,
            16.199033
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ZPI",
          "name": "Zampini",
          "zone": "SCR",
          "address": "Zampani, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.10036199999999,
            8.397108999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "AMVA",
          "name": "AMARAVILA HALT",
          "zone": "SR",
          "address": "Thiruvananthapuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.25887100000001,
            24.237941
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ACLE",
          "name": "AZIMGANJ CITY",
          "zone": "ER",
          "address": "Police Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.873569,
            21.799383000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WSJ",
          "name": "WANSJALIYA",
          "zone": "WR",
          "address": "Tarsai - Vansjaliya, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.03422499999999,
            27.30686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AHU",
          "name": "Ahirauli",
          "zone": "NER",
          "address": "State Highway 1A, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.921995,
            15.145135
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BAY",
          "name": "BELLARY JN",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.210367,
            22.44677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ALB",
          "name": "ALIA BADA",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6702185,
            24.8821354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ATRU",
          "name": "ATRU",
          "zone": "WCR",
          "address": "Atru, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.225637,
            24.813122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "WZJ",
          "name": "WAZERGANJ",
          "zone": "ECR",
          "address": "Gaya, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.77743530000001,
            26.588904900000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BIX",
          "name": "BHIND",
          "zone": "NCR",
          "address": "Bhind, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.956182,
            22.611810000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WKRC",
          "name": "WANKANER CITY",
          "zone": "WR",
          "address": "Wankaner, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.916171,
            32.63896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "BBMN",
          "name": "BARI BRAHMAN",
          "zone": "NR",
          "address": "Bari Brahmana, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5319560193,
            25.3266239799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "WENA",
          "name": "Wena",
          "zone": "ECR",
          "address": "Wena, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.961069,
            21.895867000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WAB",
          "name": "BALWA",
          "zone": "WR",
          "address": "SH 126, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.61447700000001,
            32.101414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLCA",
          "name": "BLOCK HUT BEAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.61021299999999,
            17.674958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ZB",
          "name": "Zahirabad",
          "zone": "SCR",
          "address": "Dist. Medak, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14827000000001,
            28.281073000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ZP",
          "name": "Zarpur Pali",
          "zone": "NWR",
          "address": "Road to Nangal Mala, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.914959,
            24.983198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BJN",
          "name": "Bejnal",
          "zone": "NWR",
          "address": "Rajsamand, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.0133366,
            26.2484972
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BGKT",
          "name": "BHAGAT KI KOTHI",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.54704889770001,
            25.396291565400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRNN",
          "name": "Karnauti Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.843856,
            10.775837000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VXM",
          "name": "Vellipalayam",
          "zone": "SR",
          "address": "Nagapattinam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.31875600000001,
            21.352410000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AMDI",
          "name": "Amdi Halt",
          "zone": "SECR",
          "address": "Amadi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5752165,
            25.448874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BISH",
          "name": "Bishengarh",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.75140999999999,
            25.756558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "YDV",
          "name": "Yadvendranagar",
          "zone": "NER",
          "address": "Yadvendranagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.952866,
            18.678662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "YDP",
          "name": "Yedapalli",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.728543,
            27.454598
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "YEAM",
          "name": "Yogendra Dham Halt",
          "zone": "NER",
          "address": "Yogendra Dham, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.139995,
            25.613687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AKW",
          "name": "Akora",
          "zone": "NCR",
          "address": "National Highway 86, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.999264,
            22.392856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ALV",
          "name": "Anklav",
          "zone": "WR",
          "address": "Anklav, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.858906,
            30.319025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BSS",
          "name": "BAHADUR SINGH WALA",
          "zone": "NR",
          "address": "SH 11, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.256764,
            24.219053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "AZ",
          "name": "AZIMGANJ JN",
          "zone": "ER",
          "address": "Police Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.79116400000001,
            16.823628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ARAG",
          "name": "ARAG",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.833817,
            27.341282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BEU",
          "name": "Bisheshwarganj",
          "zone": "NER",
          "address": "Bisheshwarganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.900353,
            26.184354000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BSI",
          "name": "BASBARI",
          "zone": "NFR",
          "address": "Dhubri Kochugaon Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.44724,
            28.4939826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BPR",
          "name": "BHOJIPURA JN",
          "zone": "NER",
          "address": "SH 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.520667,
            26.479307000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "APD",
          "name": "Alipur Duar",
          "zone": "NFR",
          "address": "Alipurduar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.78304,
            30.217553000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BHX",
          "name": "BULLUANA",
          "zone": "NR",
          "address": "Balluana, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.970474,
            20.763319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BIM",
          "name": "BILIMORA JN",
          "zone": "WR",
          "address": "Bilimora, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.78720299999999,
            26.80763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BKC",
          "name": "BHIKAMKOR",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5154167,
            17.5335493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BMO",
          "name": "BOLARUM",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5496866,
            29.9217422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BLZ",
          "name": "BUDHLADA",
          "zone": "NR",
          "address": "Budhlada, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.228081,
            18.047011100000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BHLK",
          "name": "Bhalki",
          "zone": "SCR",
          "address": "Bhalki, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.722483,
            19.873979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BDU",
          "name": "BADNAPUR",
          "zone": "SCR",
          "address": "Jalna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.88173556,
            25.89293086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BMQ",
          "name": "BHIMARLAI",
          "zone": "NWR",
          "address": "National Highway 112, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.036416,
            15.38694828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNP",
          "name": "BHANAPUR",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.248683,
            25.830070999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BLT",
          "name": "BALOTRA JN",
          "zone": "NWR",
          "address": "Balotra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.512537,
            28.046924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BFV",
          "name": "BAHELIA BUZURG",
          "zone": "NER",
          "address": "Bahelia Buzurg, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2321793958,
            26.4968303633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHBR",
          "name": "Bathua Bazar",
          "zone": "NER",
          "address": "Phulwaria, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.76767899999999,
            25.217166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTKP",
          "name": "BHARAT KUP",
          "zone": "NCR",
          "address": "Chitrakoot, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.486132,
            28.334799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BIK",
          "name": "BHIRA KHERI",
          "zone": "NER",
          "address": "Lakhimpur Kheri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.278015354,
            29.140457035199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BAOL",
          "name": "BAOLI",
          "zone": "NR",
          "address": "Bagpat, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.55448188960001,
            25.2767862477
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTX",
          "name": "BELA TAL",
          "zone": "NCR",
          "address": "Chhatarpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.43942509,
            25.78029392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BOM",
          "name": "BOMADRA",
          "zone": "NWR",
          "address": "Pali Marwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.42341227,
            22.00024281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BSPX",
          "name": "Banspani",
          "zone": "SER",
          "address": "National Highway 215, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.174223,
            26.155568000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIB",
          "name": "Birpur",
          "zone": "NCR",
          "address": "State Highway 2, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.314466,
            13.999308999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ARU",
          "name": "Arasalu",
          "zone": "SWR",
          "address": "Arasalu, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.152895,
            11.984107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MPC",
          "name": "Muttampatti",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.034499,
            22.633029999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BAJ",
          "name": "Bhalej",
          "zone": "WR",
          "address": "Bhalej, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.80126,
            17.821267000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AWM",
          "name": "Aswapuram",
          "zone": "SCR",
          "address": "Sarapaka, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.74489899999999,
            21.052203000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "AVP",
          "name": "Abhanpur Junction",
          "zone": "SECR",
          "address": "Raipur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.837917,
            19.011494000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BDXX",
          "name": "Bade Arapur",
          "zone": "ECoR",
          "address": "Bade Arapur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.607005,
            22.674548
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGRD",
          "name": "Beliaghata Road",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.285956,
            21.893954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "ANI",
          "name": "Ajanti",
          "zone": "WR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.76868,
            25.897537999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BUT",
          "name": "BAYTU",
          "zone": "NWR",
          "address": "State Highway 40, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.28427905,
            23.73804814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BMGA",
          "name": "BHIMGARA",
          "zone": "ER",
          "address": "Birbhum, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.841719,
            26.121418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BLTR",
          "name": "BELTHARA ROAD",
          "zone": "NER",
          "address": "Belthara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.792278,
            22.282963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BKNG",
          "name": "BHAKTI NAGAR",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.92033749999999,
            28.2368573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DEP",
          "name": "Depalsar",
          "zone": "NWR",
          "address": "Churu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1277773999,
            23.270810878299997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BKD",
          "name": "Bhankoda",
          "zone": "WR",
          "address": "State Highway 138, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.2818798,
            23.6710024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DHJ",
          "name": "DHINOJ",
          "zone": "WR",
          "address": "Dhinoj, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0141142,
            25.3341176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BCY",
          "name": "VARANASI CITY",
          "zone": "NER",
          "address": "Varanasi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.9159773981,
            22.9137476381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BNE",
          "name": "BOHANI",
          "zone": "WCR",
          "address": "Narsinghpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84346500000001,
            19.019731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DDR",
          "name": "MUMBAI DADAR WEST",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59932690000001,
            12.9936735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNC",
          "name": "BANGALORE CANT",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.323906,
            29.274012000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BSPH",
          "name": "BISHANPUR HARYANA",
          "zone": "NR",
          "address": "Bishanpur, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.28291748000001,
            23.51930706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BQY",
          "name": "BELERHAT",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.613599,
            27.296265000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DGW",
          "name": "DHIGAWARA",
          "zone": "NWR",
          "address": "Baswa, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.779466,
            26.018425999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BXJ",
          "name": "BOKAJAN",
          "zone": "NFR",
          "address": "Bokajan, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.03240600000001,
            27.0635982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BOJ",
          "name": "BHOJO",
          "zone": "NFR",
          "address": "Bhojo Railway Station, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.840535,
            19.061911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BDTS",
          "name": "MUMBAI BANDRA TERMINUS",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.87912899999999,
            26.646825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BLPR",
          "name": "Badulipar",
          "zone": "NFR",
          "address": "Badulipar, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.044404,
            25.73181
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BBC",
          "name": "Bhachhbar",
          "zone": "NWR",
          "address": "State Highway 40, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.416286,
            28.158478000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BNKJ",
          "name": "BANKE GANJ",
          "zone": "NER",
          "address": "Lakhimpur Kheri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.55765600000001,
            27.406108
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DIA",
          "name": "Didwana",
          "zone": "NWR",
          "address": "Didwana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.05239089999999,
            26.747088299999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BNV",
          "name": "BINNAGURI",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.136244,
            26.715384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DLO",
          "name": "DALGAON",
          "zone": "NFR",
          "address": "Birpara, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.219674,
            25.999791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BOKO",
          "name": "BOKO",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.16531499999999,
            28.4587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DKX",
          "name": "Dudhwakhara",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.65895300000001,
            30.532580000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BRW",
          "name": "Bariwala",
          "zone": "NR",
          "address": "Mandi Bariwala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.05132087999999,
            8.430107451
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "BRAM",
          "name": "BALARAMAPURAM",
          "zone": "SR",
          "address": "Balaramapuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.686417,
            11.919465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CBU",
          "name": "Chinna Babu Samudram",
          "zone": "SR",
          "address": "Villupuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.765669,
            26.944321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DKBJ",
          "name": "Dahar Ka Balaji",
          "zone": "NWR",
          "address": "Jaipur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3122585,
            19.9400612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CAF",
          "name": "Chanda Fort",
          "zone": "SECR",
          "address": "Chandrapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7369583,
            22.3971312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BRMP",
          "name": "Biramitrapur",
          "zone": "SER",
          "address": "Birmitrapur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.22942499999999,
            25.626454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BJY",
          "name": "Bangalbaree",
          "zone": "NFR",
          "address": "State Highway 10A, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.05365,
            26.075249999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DMW",
          "name": "Dalmau Junction",
          "zone": "NR",
          "address": "Dalmau, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.210042,
            29.13221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "BRDT",
          "name": "BERIA DAULAT",
          "zone": "NER",
          "address": "National Highway 74, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.53653800000001,
            27.292281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DUM",
          "name": "DHAURMUI JAGHNA",
          "zone": "WCR",
          "address": "State Highway 33, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0537095,
            10.77766297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ALK",
          "name": "ALAKKUDI",
          "zone": "SR",
          "address": "Alakudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.26535758,
            18.77690197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BHNS",
          "name": "Bhansi",
          "zone": "ECoR",
          "address": "Dhurli-Basenpur Rd, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.234121,
            26.864673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DY",
          "name": "DUMARIYA",
          "zone": "WCR",
          "address": "State Highway 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.564974,
            27.10333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GLNA",
          "name": "GULANA",
          "zone": "NWR",
          "address": "State Highway 25, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.731628,
            25.351848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BWR",
          "name": "BARWA SAGAR",
          "zone": "NCR",
          "address": "Jhansi(0510-2440441), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.350657,
            21.427163999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CDI",
          "name": "CHANDNI",
          "zone": "CR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.67032900000001,
            27.108219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BUBT",
          "name": "BURAHA BHARTHA",
          "zone": "NCR",
          "address": "Buraha Bharthara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.84804777400001,
            30.6111449411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GHG",
          "name": "GHAGGHAR",
          "zone": "NR",
          "address": "National Highway 22, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.624757,
            25.061279000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GGR",
          "name": "GANGRAR",
          "zone": "NWR",
          "address": "Gangrar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.162162,
            25.227501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DJA",
          "name": "DAOTUHAJA",
          "zone": "NFR",
          "address": "National Highway 54, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.194667,
            18.613288
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DHP",
          "name": "DICHPALLI",
          "zone": "SCR",
          "address": "Dichpalli, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.908517,
            23.651021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DMA",
          "name": "DAMODAR JUNCTION",
          "zone": "SER",
          "address": "Bardhaman, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4900954675,
            14.6997230018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BYD",
          "name": "BYADGI",
          "zone": "SWR",
          "address": "National Highway 4, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.742995,
            24.855593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "ARCL",
          "name": "ARUNACHAL",
          "zone": "NFR",
          "address": "Cachar, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8156104224,
            30.165006839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BZ",
          "name": "BHARUR",
          "zone": "NR",
          "address": "Sunam, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.247613,
            15.348226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GIN",
          "name": "GINIGERA",
          "zone": "SWR",
          "address": "Ginigera, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1400537476,
            13.395698823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BVR",
          "name": "BANAVAR",
          "zone": "SWR",
          "address": "National Highway 234, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.066009,
            25.979779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DPRA",
          "name": "DHUP DHARA",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.004552,
            15.440386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DWR",
          "name": "DHARWAR",
          "zone": "SWR",
          "address": "Dharwad, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.212366,
            23.453415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DNPR",
          "name": "Dhanpura",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.00269200000001,
            26.465652000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "FKG",
          "name": "FURKATING JN",
          "zone": "NFR",
          "address": "K B Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.772519,
            30.033151999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BWB",
          "name": "BAGWALI",
          "zone": "NWR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.11868199999999,
            21.770889999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BVP",
          "name": "BHAVANAGAR PARA",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.141059,
            21.781668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BVC",
          "name": "BHAVNAGAR TERMINUS",
          "zone": "WR",
          "address": "Bhavnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.42625,
            12.433086000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DOY",
          "name": "Dornahalli",
          "zone": "SWR",
          "address": "Dornahalli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.348164,
            21.997011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BXY",
          "name": "Bordhal",
          "zone": "CR",
          "address": "Amla, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.507655,
            21.622377
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DGHA",
          "name": "Digha Flag Station",
          "zone": "?",
          "address": "State Highway 57, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38862689999999,
            12.4454105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KRNR",
          "name": "Krishnarajanagara",
          "zone": "SWR",
          "address": "Krishnarajanagara, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.20593600000001,
            28.125245999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GTP",
          "name": "Ghatpuri",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.38629999999999,
            25.640548000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DLX",
          "name": "Dalimgaon",
          "zone": "NFR",
          "address": "Dhankoil to Dalimgaoun, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.65854499999999,
            22.665494000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BSLA",
          "name": "Bhasila",
          "zone": "ER",
          "address": "Bhasila Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.882498,
            25.537166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DJKR",
          "name": "Dolaji Ka Khera",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.079953,
            17.532281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GUU",
          "name": "GUNDRATIMADUGU",
          "zone": "SCR",
          "address": "Mahbuababad-Badrachalam Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1469610779,
            26.8216101334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FSP",
          "name": "FATEH SINGHPURA",
          "zone": "WCR",
          "address": "State Highway 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.654362,
            22.575660999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DRA",
          "name": "DULARIA",
          "zone": "WCR",
          "address": "Itarsi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.002212,
            26.722854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKDM",
          "name": "SIKRODA MINA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.599178,
            15.219036000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GNR",
          "name": "GADIGANURU",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.845641,
            23.129052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DRSN",
          "name": "DHURWASIN",
          "zone": "SECR",
          "address": "Parasi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.952056,
            26.078321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "GUP",
          "name": "GAURIPUR",
          "zone": "NFR",
          "address": "Gauripur, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.07289439819999,
            24.404804119599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GALE",
          "name": "GANKAR",
          "zone": "ER",
          "address": "Gankar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.91966500000001,
            24.363234000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HKL",
          "name": "HARKIA KHAL",
          "zone": "WR",
          "address": "State Highway 31, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.488371,
            17.568097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GDPL",
          "name": "GUNDLA POCHAMPALLI",
          "zone": "SCR",
          "address": "Rangareddy, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5868746,
            26.0707762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "FLM",
          "name": "Falimari",
          "zone": "NFR",
          "address": "Falimari, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.893142,
            19.830781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "J",
          "name": "JALNA",
          "zone": "SCR",
          "address": "Jalna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.525172,
            24.273027000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JGD",
          "name": "Jagadishpur",
          "zone": "ER",
          "address": "Jagadishpur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.74961099999999,
            20.92654
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GTWD",
          "name": "Ghatwad",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.511242,
            12.935329000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GNB",
          "name": "JNANA BHARTI HALT",
          "zone": "SWR",
          "address": "Mysore Road Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.68937799999999,
            29.140297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "GHNA",
          "name": "GOHANA",
          "zone": "NR",
          "address": "Gohana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.414159,
            25.416805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DWI",
          "name": "DINGWAHI",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.301364624,
            29.4068014069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GLBN",
          "name": "GUJRAN BALWA",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.613598,
            25.810836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Nagaland",
          "code": "DSR",
          "name": "DHANSIRI",
          "zone": "NFR",
          "address": "Bridge, Nagaland"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.475468,
            28.084651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GK",
          "name": "GOLA GOKARANATH",
          "zone": "NER",
          "address": "Gola, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.657932,
            31.80853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DZA",
          "name": "DASUYA",
          "zone": "NR",
          "address": "Dasuya, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.9319259351,
            24.6581702628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRPJ",
          "name": "SWARUPGANJ",
          "zone": "NWR",
          "address": "Sirohi Road, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.94724099999999,
            29.088276999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "HNS",
          "name": "Hansi",
          "zone": "NWR",
          "address": "Hissar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.26504100000001,
            28.526882999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HYL",
          "name": "Hadyal",
          "zone": "NWR",
          "address": "MDR 97, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.46164300000001,
            27.520546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GDO",
          "name": "Goverdhan",
          "zone": "NCR",
          "address": "Mathura District, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.880759,
            27.539231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JBS",
          "name": "Jadoli Ka Bas",
          "zone": "NCR",
          "address": "Alwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.600792,
            30.962007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "FZP",
          "name": "Firozpur City",
          "zone": "NR",
          "address": "Firozpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.715068,
            8.979142999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "EKN",
          "name": "Ezhukone",
          "zone": "SR",
          "address": "Kollam-Tirumangalam Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14241700000001,
            31.21799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GSR",
          "name": "Garhshankar",
          "zone": "NR",
          "address": "Garhshankar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.150553,
            26.215133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GOPA",
          "name": "Ghosipura",
          "zone": "NCR",
          "address": "Gwalior, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.736062,
            22.265791999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "URGA",
          "name": "Urga",
          "zone": "SECR",
          "address": "Korba, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.78289199999999,
            22.651678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GGV",
          "name": "Ghovarash Ghona",
          "zone": "ER",
          "address": "Ghoraras Ghona, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.40315299999999,
            25.786329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GRMR",
          "name": "Garhi Manikpur",
          "zone": "NR",
          "address": "Garhi Manikpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.71403099999999,
            25.763299999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DTQ",
          "name": "Dantarda Kalan",
          "zone": "NCR",
          "address": "State Highway 6, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.870355,
            29.012363999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JLQ",
          "name": "JALPUR",
          "zone": "?",
          "address": "MDR 65W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.90404000000001,
            23.161136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HRV",
          "name": "HARRAD",
          "zone": "SECR",
          "address": "Shahdol, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.268733,
            32.487007000000006
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "HRNR",
          "name": "HIRA NAGAR",
          "zone": "NR",
          "address": "Hiranagar, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05386,
            18.70264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "JKM",
          "name": "JANKAMPET JN",
          "zone": "SCR",
          "address": "Nizamabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.68033899999999,
            23.516393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JOC",
          "name": "JOYCHANDI PAHAR",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.324507,
            27.156647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "HIR",
          "name": "HARINAGAR",
          "zone": "ECR",
          "address": "Ramnagar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.87245142,
            19.35986171
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JCNR",
          "name": "JUCHANDRA",
          "zone": "CR",
          "address": "NH 8, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.17975597290001,
            27.4330507545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JKNI",
          "name": "Jharkhandi",
          "zone": "NER",
          "address": "MDR 10E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.46222900000001,
            9.279602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "HAD",
          "name": "HARIPPAD",
          "zone": "SR",
          "address": "Haripad 0479-2412714, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.35227499999999,
            25.721525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HMPR",
          "name": "HURMUJPUR HALT",
          "zone": "CR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.820315,
            22.23946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JIL",
          "name": "JALILA ROAD",
          "zone": "WR",
          "address": "Botad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.0254415802,
            27.275552861799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ITE",
          "name": "Intiyathok",
          "zone": "NER",
          "address": "MDR 10E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.399597,
            22.420182999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JALD",
          "name": "JALIYA DEVANI",
          "zone": "WR",
          "address": "Ranuja Village Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.09130300000001,
            25.44872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KDQ",
          "name": "KADIPUR",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.81928099999999,
            25.317889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "IDGJ",
          "name": "IRADATGANJ",
          "zone": "NCR",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.47043762019999,
            22.4279053359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HM",
          "name": "HADMATIYA JN",
          "zone": "WR",
          "address": "State Highway 98, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.507837,
            11.737975
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "JGE",
          "name": "JAGANNATH TEMPLE GATE",
          "zone": "SR",
          "address": "Mahe, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.843299,
            24.68924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HATI",
          "name": "HATI",
          "zone": "WCR",
          "address": "MDR 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1300544724,
            14.038157957300001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HLK",
          "name": "HOLALKERE",
          "zone": "SWR",
          "address": "National Highway 13, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.0773198273,
            17.7507667858
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JSV",
          "name": "JARANDESHWAR",
          "zone": "CR",
          "address": "SH 61, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.801131,
            13.643625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KCI",
          "name": "Kallkiri",
          "zone": "SCR",
          "address": "Punganur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.809835,
            12.025333999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KRNU",
          "name": "KONANUR",
          "zone": "SWR",
          "address": "Nanjungudu-Chamarajanagara Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.288916,
            28.871499999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KEX",
          "name": "KHEKRA",
          "zone": "NR",
          "address": "Tronica City, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6040669287,
            28.9148353709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SWNI",
          "name": "Siwani",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.66510099999999,
            18.890456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JRT",
          "name": "JARTI",
          "zone": "?",
          "address": "Jarti Station, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.18941,
            25.357472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HDK",
          "name": "HANDIA KHAS",
          "zone": "NER",
          "address": "Handia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.267833,
            26.228493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JJP",
          "name": "Jhanjharpur",
          "zone": "?",
          "address": "Madhubani, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.2221512,
            29.8325238
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JRKN",
          "name": "Jorkian",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.82005,
            21.824613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KCV",
          "name": "Krishnachandrapur",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.30634,
            27.459871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JSC",
          "name": "Jalesar City",
          "zone": "NCR",
          "address": "Jalesar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.689994,
            22.661628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HRO",
          "name": "Harua Road",
          "zone": "ER",
          "address": "Harua Road Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.927221,
            21.296402
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASTG",
          "name": "Astegaon",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.711385,
            22.207929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "IKR",
          "name": "Iklehra",
          "zone": "CR",
          "address": "Dongar Parasia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9873709528,
            29.0117786412
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JKZ",
          "name": "Jitakheri",
          "zone": "?",
          "address": "Bhiwani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2495220447,
            13.226848573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HHT",
          "name": "Habanghata",
          "zone": "SWR",
          "address": "Habanghata, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.796092,
            16.236489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KLM",
          "name": "KADLIMATTI",
          "zone": "SWR",
          "address": "State Highway 20, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9098425183,
            23.1048189579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KDHA",
          "name": "KARCHHA",
          "zone": "WR",
          "address": "State Highway 18, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.01505399999999,
            26.020077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KDF",
          "name": "KHUNDAUR",
          "zone": "NR",
          "address": "Ramapur Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.413149,
            23.016799000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KJU",
          "name": "KUNTIGHAT",
          "zone": "ER",
          "address": "Kuntighat Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.966186,
            30.143767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KTPK",
          "name": "KOTHA PAKKI",
          "zone": "NR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.11242,
            30.137221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KLWL",
          "name": "KILANWALI PUNJAB",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.9804719385,
            23.0627851439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KHRS",
          "name": "KHARESHWAR ROAD",
          "zone": "WR",
          "address": "State Highway 7, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.19175,
            18.163878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KCP",
          "name": "Kalgupur",
          "zone": "SCR",
          "address": "Hulsoor, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.70545000000001,
            25.685669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KJTW",
          "name": "KAJGAON TERHWAN",
          "zone": "NR",
          "address": "Kajgaon, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.67836199999999,
            28.274856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KIP",
          "name": "KHALILPUR",
          "zone": "NR",
          "address": "State Highway 26, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.49498,
            25.352584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KHU",
          "name": "KHURHAND",
          "zone": "NCR",
          "address": "Atarra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.617075,
            25.563233999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KAW",
          "name": "KHAJRI",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.41635799999999,
            17.084152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KOV",
          "name": "KIRLOSKARVADI",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.62690500000001,
            21.66029
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "KDJR",
          "name": "Kendujhargarh",
          "zone": "ECoR",
          "address": "Kendujhar, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.531081,
            21.664080000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TKRA",
          "name": "Tikra Ph",
          "zone": "?",
          "address": "State Highway 4, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.969431,
            28.915440999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "KHMA",
          "name": "Khatima",
          "zone": "NER",
          "address": "Khatima, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.42653,
            26.204383999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KRW",
          "name": "KHARWA",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.860581,
            25.700369000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KMDR",
          "name": "KARIMUDDIN PUR",
          "zone": "NER",
          "address": "Karimdinpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.215686,
            17.310011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRD",
          "name": "KARAD",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.515552,
            17.477292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KMAH",
          "name": "KAMATHE",
          "zone": "KR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.678046,
            16.837853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KLDI",
          "name": "KALDHARI",
          "zone": "SCR",
          "address": "Kaldhari Main Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.822278,
            27.378437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KMP",
          "name": "KAMLAPUR",
          "zone": "NER",
          "address": "Kamalpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82803299999999,
            11.133481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KN",
          "name": "KADALUNDI",
          "zone": "SR",
          "address": "Kozhikode district, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.634249,
            25.250251
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KFK",
          "name": "KATKA",
          "zone": "NER",
          "address": "Mirzapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.619792,
            18.883305
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KKLU",
          "name": "Kaklur",
          "zone": "ECoR",
          "address": "Kaklur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.772358,
            20.971057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JVL",
          "name": "Jamwala",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.2738395703,
            24.9119408017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KOF",
          "name": "Marwar Kori",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.82471799999999,
            21.35276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JTV",
          "name": "Jetalvad",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.941435,
            30.369503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KLSX",
          "name": "Kaulseri",
          "zone": "NR",
          "address": "Sherpur Sodhian, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.493636,
            23.388714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KNJ",
          "name": "Krishnanagar City Junction",
          "zone": "ER",
          "address": "Krishnanagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.821206,
            17.05336
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KTK",
          "name": "KYATANEAKERI RD",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.196445,
            31.165462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "LNK",
          "name": "LOHIAN KHAS JN",
          "zone": "NR",
          "address": "Malsian Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.421111,
            23.685409999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LSD",
          "name": "LUSADIYA",
          "zone": "NWR",
          "address": "Lusadiya, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.64049800000001,
            25.626255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SPPR",
          "name": "SHUDNIPUR",
          "zone": "NR",
          "address": "State Highway 5, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.110265,
            25.49759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LGT",
          "name": "LANGTING",
          "zone": "NFR",
          "address": "Langting, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.085087,
            10.965642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KRR",
          "name": "KARUR",
          "zone": "SR",
          "address": "Karur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.660871,
            28.255391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KWMD",
          "name": "KUMBHAWAS MUNDA",
          "zone": "NR",
          "address": "Kumbhawas, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.579324,
            10.766395
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KU",
          "name": "KULIKARAI",
          "zone": "SR",
          "address": "Thiruvarur - Mannargudi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.42761829999999,
            28.3110435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KRTL",
          "name": "Kartauli",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.39848079709999,
            28.145183762800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KUND",
          "name": "Kund",
          "zone": "NWR",
          "address": "State Highway 26, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.793413,
            24.291847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MCQ",
          "name": "MIRCHADHORI",
          "zone": "ECR",
          "address": "National Highway 75, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BDO",
          "name": "Bahalda Road",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.6983925134,
            26.9877753343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KSNR",
          "name": "KHUSHAL NAGAR",
          "zone": "NER",
          "address": "Ghughli Bazar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.637024,
            29.298804999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KYNT",
          "name": "KALYANKOT",
          "zone": "NWR",
          "address": "Kalyankot, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.036258,
            22.585491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LXR",
          "name": "LUNSERIYA",
          "zone": "WR",
          "address": "State Highway 113, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.339233,
            10.95132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "LP",
          "name": "LALAPET",
          "zone": "SR",
          "address": "NH 67, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.293239,
            29.324701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KQL",
          "name": "KANDHLA",
          "zone": "NR",
          "address": "MDR 22W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2938848779,
            29.382584655000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KZI",
          "name": "KHANDRAWALI",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.056352,
            26.529561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "KXL",
          "name": "KAMARBANDHA ALI",
          "zone": "NFR",
          "address": "K B Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.313909,
            28.043638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LGH",
          "name": "LALGARH JN",
          "zone": "NWR",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.4371491022,
            26.7412013647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LH",
          "name": "LAHING",
          "zone": "NFR",
          "address": "Mudoijan, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.227856,
            10.782804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KRKD",
          "name": "KARAKKAD",
          "zone": "SR",
          "address": "Kondayur Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.777625,
            27.947414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LMP",
          "name": "LAKHIMPUR",
          "zone": "NER",
          "address": "Lakhimpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.281598,
            24.176963999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MBB",
          "name": "Murshidabad",
          "zone": "ER",
          "address": "Berhampore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.798394,
            22.140449999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KUX",
          "name": "Khirsadoh Junction",
          "zone": "CR",
          "address": "Chhindwara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.53352199999999,
            18.896086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KWGN",
          "name": "Kawargaon",
          "zone": "ECoR",
          "address": "Kawargaon, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.4683445782,
            27.5260420401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LBW",
          "name": "Laiburwa Halt",
          "zone": "NER",
          "address": "Laiburwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.08094,
            25.194176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MLGH",
          "name": "Mandalgarh",
          "zone": "WCR",
          "address": "Mandalgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4056,
            27.64255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LAU",
          "name": "Ladnun",
          "zone": "NWR",
          "address": "Ladnun, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.672936,
            22.202912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MLKA",
          "name": "Maluka",
          "zone": "SER",
          "address": "Jaipur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.534634,
            26.231078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MLZ",
          "name": "MALARNA",
          "zone": "WCR",
          "address": "Sawai Madhopur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.489717,
            23.005927999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPJ",
          "name": "Madanpur",
          "zone": "ER",
          "address": "Madanpur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.074022,
            13.568344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MPT",
          "name": "Mangalampeta",
          "zone": "SCR",
          "address": "Naravari Palle Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.80370199999999,
            28.772575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MCV",
          "name": "MACHARYA",
          "zone": "NR",
          "address": "Kundarkhi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.304944,
            23.451923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MDSA",
          "name": "Modasa",
          "zone": "WR",
          "address": "way to railwaystation, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.827774,
            25.700913
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MFJ",
          "name": "Muftiganj",
          "zone": "NER",
          "address": "Jaunpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.74572699999999,
            14.709669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MOMU",
          "name": "Molakalmuru",
          "zone": "SWR",
          "address": "State Highway 19, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.49550799999999,
            30.184671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MOT",
          "name": "MALOUT",
          "zone": "NR",
          "address": "Malaut, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.37390499,
            11.95223333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MMP",
          "name": "MAMBALAPPATTU JN",
          "zone": "SR",
          "address": "Mambalapattu, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.764284,
            25.273637
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MFX",
          "name": "MADARAHA",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.5188330261,
            25.1789843066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MJHR",
          "name": "MAJHIARI",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.811656,
            24.913389000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MJG",
          "name": "MAJHAGAWAN",
          "zone": "WCR",
          "address": "Majhgawa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.704462,
            21.082538000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MQZ",
          "name": "MOTA JADRA",
          "zone": "WR",
          "address": "Mota Jadra Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.691157,
            25.917549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MNKN",
          "name": "MANIKALAN HALT",
          "zone": "NR",
          "address": "Nawab Yousuf Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.70332322690001,
            25.606296391399997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MOG",
          "name": "MOHIUDDINNAGAR",
          "zone": "ECR",
          "address": "Samastipur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.427262,
            19.17485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MGC",
          "name": "MUGAT",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.829277,
            22.825342
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MVI",
          "name": "MORBI",
          "zone": "WR",
          "address": "Morbi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.45439599999999,
            17.75573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MOB",
          "name": "MANOHARABAD",
          "zone": "SCR",
          "address": "Medak, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1583349207,
            27.0362379616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MLW",
          "name": "Mallanwan",
          "zone": "NR",
          "address": "Mallawan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.896007,
            22.619981
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPN",
          "name": "Madhyampur",
          "zone": "ER",
          "address": "Madhyampur Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3047403595,
            13.2391442032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MDLL",
          "name": "MUDDALINGAHALLI",
          "zone": "SWR",
          "address": "NH 207, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.844068,
            12.862325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MAQ",
          "name": "MANGALORE CNTL",
          "zone": "SR",
          "address": "Mangalore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.938923,
            26.909235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MBP",
          "name": "MOHIBULLAPUR",
          "zone": "NER",
          "address": "Aliganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.849604,
            27.218852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MANW",
          "name": "MANWA",
          "zone": "NER",
          "address": "Manwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0905744531,
            28.6832457826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "MGLP",
          "name": "MANGOLPURI",
          "zone": "NR",
          "address": "Outter Ring Rd, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27281099999999,
            26.413117999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MLAR",
          "name": "MALANPUR",
          "zone": "NCR",
          "address": "National Highway 3, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.55378492,
            26.70055271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MHF",
          "name": "Mohari Junction",
          "zone": "NCR",
          "address": "State Highway 43, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.890651,
            8.882102999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MEJ",
          "name": "VANCHIMANIYACHI",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.32431100000001,
            31.405746999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "MTPR",
          "name": "Raimehatpur",
          "zone": "NR",
          "address": "Mehatpur, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.383621,
            29.680074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MNSR",
          "name": "MANAKSAR",
          "zone": "NWR",
          "address": "Hanumangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.51740114900001,
            26.0727512878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MRZA",
          "name": "MIRZA",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.609301,
            25.720478999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MJ",
          "name": "MARWAR JN",
          "zone": "NWR",
          "address": "Marwar Junction, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.44924400000001,
            21.74264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MRDD",
          "name": "Mordar",
          "zone": "SCR",
          "address": "Khandwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.904782,
            20.688190000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MWK",
          "name": "Mordad Tanda",
          "zone": "CR",
          "address": "State Highway 22, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14863000000001,
            28.281433000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MHRG",
          "name": "Mahendragarh",
          "zone": "NWR",
          "address": "Mahendragarh, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.972079,
            25.300001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUV",
          "name": "MANDUADIH",
          "zone": "NER",
          "address": "Varanasi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.973751,
            22.398362
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MKT",
          "name": "Mukhtiar Balwar",
          "zone": "WR",
          "address": "SH 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.46655700000001,
            9.724592999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MEKM",
          "name": "Melakkonnakkulam",
          "zone": "SR",
          "address": "National Highway 226, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.899341,
            28.831681000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "MJZ",
          "name": "Majhola Pakarya",
          "zone": "NER",
          "address": "State Hiughway 29, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.088969,
            31.325405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MGWD",
          "name": "Mahngarwal Doaba",
          "zone": "NR",
          "address": "Mahngarwal Doaba, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.263789,
            12.918968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MKM",
          "name": "Marikuppam",
          "zone": "SWR",
          "address": "Kolar Gold Fields, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.17285799999999,
            17.971931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MVW",
          "name": "Mallividu",
          "zone": "ECoR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.123109,
            25.707319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MYD",
          "name": "MANDERDISA",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.80459300000001,
            17.794877999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NKD",
          "name": "NEKONDA",
          "zone": "SCR",
          "address": "Nekkonda, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71298900000001,
            28.915414000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NRNR",
          "name": "Nurnagar",
          "zone": "NR",
          "address": "Meerut, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.51778,
            29.017708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "PBW",
          "name": "PANTNAGAR",
          "zone": "NER",
          "address": "Pantnagar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.115499,
            32.013177
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "NDBT",
          "name": "NANDPUR BHATAULI",
          "zone": "NR",
          "address": "Nandpur Bhatauli, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1415966599,
            28.6376790996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "NRVR",
          "name": "NARAINA VIHAR",
          "zone": "NR",
          "address": "Inderpuri, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.126299,
            25.187996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MXR",
          "name": "MAHUR",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.10663,
            27.964290000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MZGI",
          "name": "MANZURGARHI",
          "zone": "NCR",
          "address": "Aligarh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.5327,
            23.890185
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SURI",
          "name": "SIURI",
          "zone": "ER",
          "address": "Siuri, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38997105099999,
            21.8802561555
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MTA",
          "name": "MATHELA",
          "zone": "WCR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.067161,
            23.455521
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NLI",
          "name": "NAMLI",
          "zone": "WR",
          "address": "Namli, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.694312,
            24.623252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NBH",
          "name": "NIMBAHERA",
          "zone": "WR",
          "address": "Nimbahera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.333363,
            26.437569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NNE",
          "name": "NONERA",
          "zone": "NCR",
          "address": "National Highway 92, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.44347,
            8.17385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NCJ",
          "name": "NAGERCOIL JN",
          "zone": "SR",
          "address": "Nagercoil, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.975186,
            21.160781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NB",
          "name": "NIMBHORA",
          "zone": "CR",
          "address": "Jalgaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.82007,
            25.328061
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NEW",
          "name": "NIVARI",
          "zone": "NCR",
          "address": "Jhansi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.520106,
            26.980403000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSWN",
          "name": "RAMESHWAR NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.98716300000001,
            13.661093
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NVF",
          "name": "NAGAVANGALA",
          "zone": "SWR",
          "address": "Melanahalli Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.679344,
            26.884475000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MZA",
          "name": "MEZENGA S",
          "zone": "NFR",
          "address": "Nazira Ali, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.24608317762613,
            27.844017432134876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NWH",
          "name": "Nawalgarh",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.32977500000001,
            27.289403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NHK",
          "name": "NAHARKATIYA",
          "zone": "NFR",
          "address": "Naharkatiya, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0656737195,
            25.3966869286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NDW",
          "name": "Nadwan",
          "zone": "ECR",
          "address": "National Highway 83, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.799034,
            17.925272
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MUGR",
          "name": "Manuguru",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6313754486,
            25.6254080511
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NNNL",
          "name": "NANDANI LAGUNIA",
          "zone": "ECR",
          "address": "Mahnar Bhartendunagar Rd, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.648401,
            10.865860000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MTNL",
          "name": "MUTTARASANALLUR",
          "zone": "SR",
          "address": "Nagappattinam - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.193029,
            26.796463999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NRI",
          "name": "NARAINA",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.106567,
            18.472247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NIDI",
          "name": "NIDI",
          "zone": "CR",
          "address": "Roha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.758511,
            25.251216000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NTU",
          "name": "NIGATPUR",
          "zone": "NER",
          "address": "Mirzapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.454552,
            16.946588000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NIV",
          "name": "NIVASAR",
          "zone": "KR",
          "address": "Ratnagiri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.76816500000001,
            13.197027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PDD",
          "name": "PADUBIDRI",
          "zone": "KR",
          "address": "Muloor, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.70180500000001,
            12.104692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NBU",
          "name": "NARASAMBUDHI",
          "zone": "SWR",
          "address": "Nanjangud Taluk, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6213530695,
            15.424554374700001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NAG",
          "name": "NAGARGALI",
          "zone": "SWR",
          "address": "State Highway 34, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1892654,
            23.414388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NGW",
          "name": "Nauganwan",
          "zone": "WR",
          "address": "Ratlam, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.08829899999999,
            27.275029999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NUH",
          "name": "Naugarh",
          "zone": "NER",
          "address": "Naugarh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.545961,
            22.156554999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "PDPH",
          "name": "Padapahar Junction",
          "zone": "SER",
          "address": "National Highway 215, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0107869,
            27.9667684942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NIP",
          "name": "Nizampur",
          "zone": "NWR",
          "address": "Nizampur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.175744,
            17.373688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PPY",
          "name": "PAPATAPALLI",
          "zone": "SCR",
          "address": "GUDURU PADU, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.564492,
            32.056252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "PHRH",
          "name": "PANCH RUKHI",
          "zone": "NR",
          "address": "National Highway 20, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.23737999999999,
            30.269357000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PUL",
          "name": "RAMPURA PHUL",
          "zone": "NR",
          "address": "Rampura Phul, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.077822,
            24.683136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RBGJ",
          "name": "ROBERTS GANJ",
          "zone": "NCR",
          "address": "Robertsganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.930204,
            26.340803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "POKL",
          "name": "PEOKOL",
          "zone": "NER",
          "address": "Deoria, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.74805500000001,
            23.997173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RDD",
          "name": "RIKHABDEV ROAD",
          "zone": "NWR",
          "address": "Kalyanpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.535848,
            26.828307
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PPC",
          "name": "PIPRAICH",
          "zone": "NER",
          "address": "Gorakhpur Bypass, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.75787,
            19.956132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RGO",
          "name": "ROTEGAON",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.14542599999999,
            32.863528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "RMJK",
          "name": "RAM NAGAR J K",
          "zone": "NR",
          "address": "Jandrah, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.13055800000001,
            12.511327000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PRL",
          "name": "POLUR",
          "zone": "SR",
          "address": "Polur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.581025,
            19.829473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PMKT",
          "name": "PIMPALKHUTI",
          "zone": "CR",
          "address": "Pimpalkhuti, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.555153,
            11.773239
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PRT",
          "name": "PANRUTI",
          "zone": "SR",
          "address": "Panruti, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.92431099999999,
            20.513153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PAD",
          "name": "PARDI",
          "zone": "WR",
          "address": "Valsad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.866406,
            25.524255999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PFM",
          "name": "PHAPHAMAU JN",
          "zone": "NR",
          "address": "Phaphamau, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.242126,
            10.913696
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PDV",
          "name": "PANDARAVADAI",
          "zone": "SR",
          "address": "Rajagiri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.189495,
            25.081679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PNHI",
          "name": "PANHAI",
          "zone": "NCR",
          "address": "Manikpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.13727399999999,
            24.323279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NWMS",
          "name": "NOAPARA MAHISHA",
          "zone": "ER",
          "address": "Sagardighi Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.951417,
            26.226727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PNB",
          "name": "PANBARI",
          "zone": "NFR",
          "address": "Chandrapur, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.624639,
            26.866793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ODB",
          "name": "OODLABARI",
          "zone": "NFR",
          "address": "Udlabari, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.430172,
            24.174433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PNU",
          "name": "PALANPUR JN",
          "zone": "WR",
          "address": "Palanpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.805056,
            19.014074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PLSG",
          "name": "Palasingi",
          "zone": "ECoR",
          "address": "Palasingi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.76432,
            22.193186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PUX",
          "name": "Parasia",
          "zone": "CR",
          "address": "Dongar Parasia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.23179499999999,
            11.020627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PKQ",
          "name": "Pattikkad",
          "zone": "SR",
          "address": "Pattikad, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.18448500000001,
            18.593147
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PDSN",
          "name": "Peddasana",
          "zone": "ECoR",
          "address": "National Highway 5, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.9294731,
            22.5452909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PTD",
          "name": "Petlad Junction",
          "zone": "WR",
          "address": "Petlad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.659087,
            22.201012
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PCLI",
          "name": "Palachauri",
          "zone": "CR",
          "address": "Dongar Parasia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.79984999999999,
            22.829589000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PLA",
          "name": "Palia",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.689931,
            21.21457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SB04",
          "name": "Mana",
          "zone": "?",
          "address": "Mana, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.887727,
            29.046556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RHN",
          "name": "ROSHANPUR",
          "zone": "NER",
          "address": "Tanda, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.41000100000001,
            26.093062999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RNT",
          "name": "RANTHAMBHORE",
          "zone": "WCR",
          "address": "MDR 111, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.828347,
            24.622107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SAGM",
          "name": "SAGMA",
          "zone": "WCR",
          "address": "Satna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.548025,
            23.551806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RUI",
          "name": "Rukni",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.044594,
            21.222201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RV",
          "name": "RAVER",
          "zone": "CR",
          "address": "Jalgaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5973840167,
            25.7208597875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SBV",
          "name": "SIMRI BHAKHTIYARPUR",
          "zone": "ECR",
          "address": "Simri Bakhtiarpur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.1551290765,
            18.384143186099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RJW",
          "name": "RAJEVADI",
          "zone": "CR",
          "address": "Malshiras Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1611348285,
            12.753547405299999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMM",
          "name": "KANNAMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.713698,
            20.942193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SDHR",
          "name": "Sandhanidhar",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.631634,
            23.061366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SAHP",
          "name": "SAHIJPUR",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.55405,
            21.677450999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RMRB",
          "name": "Ramnagar Bengal",
          "zone": "?",
          "address": "Egra Ramnagar Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.83253,
            30.088059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SGF",
          "name": "SANGAT",
          "zone": "NWR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.198369,
            26.363371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SAC",
          "name": "SANICHARA",
          "zone": "NCR",
          "address": "National Highway 3, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.236769,
            25.293896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RORA",
          "name": "RORA",
          "zone": "NCR",
          "address": "Jhansi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.356471,
            18.085549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SAPE",
          "name": "SAPE WAMNE",
          "zone": "KR",
          "address": "SH 70, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8421637408,
            29.0674933054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RJS",
          "name": "RAJIYASAR",
          "zone": "NWR",
          "address": "Biradhwal, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1419178821,
            12.6442282946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SET",
          "name": "SETTIHALLY",
          "zone": "SWR",
          "address": "Bangalore-Mysore Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.605713,
            24.239748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "RMF",
          "name": "RAMNA",
          "zone": "ECR",
          "address": "Dist. Garhwa, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.601906,
            16.543756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SGKM",
          "name": "Srungavruksham",
          "zone": "SCR",
          "address": "Srungavruksham, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.866472,
            22.324313
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RGA",
          "name": "Rajgoda",
          "zone": "SER",
          "address": "Rajgoda /RGA Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.528605351,
            26.6201438112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RVK",
          "name": "RAJA BHAT KHAWA",
          "zone": "NFR",
          "address": "Rajabhat Khawa, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.8507290414,
            25.2639068026
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RTB",
          "name": "RAJA TALAB",
          "zone": "NER",
          "address": "Mirzapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.259683,
            8.26515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PYD",
          "name": "PALLIYADI",
          "zone": "SR",
          "address": "Kanyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.602443,
            21.168995000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SASG",
          "name": "Sasan Gir",
          "zone": "WR",
          "address": "SH 111, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.244037,
            24.01772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SGV",
          "name": "Sargachhi",
          "zone": "ER",
          "address": "Sargachhi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.831451,
            25.052535
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "RJL",
          "name": "Rajmahal",
          "zone": "ER",
          "address": "State Highway 10, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.605514,
            27.688822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RS",
          "name": "Risia",
          "zone": "NER",
          "address": "Risia Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.549492,
            16.545258999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RCA",
          "name": "Rentachintala",
          "zone": "SCR",
          "address": "Guntur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.59740799999999,
            27.534312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RAK",
          "name": "Ramkot",
          "zone": "NR",
          "address": "Ramkot, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.520722,
            23.059458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PXR",
          "name": "Palpara",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.008011,
            28.070311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDGH",
          "name": "Sri Dungargarh",
          "zone": "NWR",
          "address": "Churu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.6424819141,
            26.3116130653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SCE",
          "name": "Senchoa Junction",
          "zone": "NFR",
          "address": "Senchoa, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4087421698,
            26.2512745542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SBL",
          "name": "Sabalgarh",
          "zone": "NCR",
          "address": "Sabalgarh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4001292639,
            25.4270372397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SKJ",
          "name": "SAHIBPUR KAMAL JN",
          "zone": "ECR",
          "address": "National Highway 31, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.57852799999999,
            32.252678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SRM",
          "name": "Sarna",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.458716,
            29.844419000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRW",
          "name": "Shri Karanpur",
          "zone": "NWR",
          "address": "State Highway 7B, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.083308,
            29.166922000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "SKX",
          "name": "SARKARA",
          "zone": "NER",
          "address": "National Highway 74, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.641918,
            24.771439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMP",
          "name": "SHAMBHUPURA",
          "zone": "WR",
          "address": "Shambhupura, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.48234500000001,
            9.602939000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SUX",
          "name": "SUDIYUR",
          "zone": "SR",
          "address": "Madurai-Rameshwaram Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3473074512,
            23.6789703455
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SNRR",
          "name": "SANSARPUR",
          "zone": "WCR",
          "address": "National Highway 7, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.93969899999999,
            19.764637
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SVD",
          "name": "SARWARI",
          "zone": "SCR",
          "address": "Ranjani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.879823,
            24.118095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SZK",
          "name": "SUR KHAND KA KHEDA",
          "zone": "NWR",
          "address": "Kejed, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.82137900000001,
            26.283587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "STZ",
          "name": "Satraon",
          "zone": "NER",
          "address": "Deoria, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.03679699999999,
            24.571584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SPLE",
          "name": "SUJNIPARA",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.239613,
            18.902861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHOK",
          "name": "Chowk",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.92156999999999,
            9.357545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SRT",
          "name": "SATUR",
          "zone": "SR",
          "address": "Sattur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.78898557980001,
            26.2094647257
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APRH",
          "name": "Alehpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.433032,
            23.13916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SOAE",
          "name": "SOMRA BAZAR",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.880146,
            23.289233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SJQ",
          "name": "SURAJPUR ROAD",
          "zone": "SECR",
          "address": "Surajpur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.9526427954,
            23.0348065663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SR",
          "name": "SABLI ROAD",
          "zone": "WR",
          "address": "State Highway 17, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.097531,
            30.907937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SOL",
          "name": "Solan",
          "zone": "NR",
          "address": "Solan, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.044078,
            32.80617
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "SGRR",
          "name": "SANGAR",
          "zone": "NR",
          "address": "Jammu Cantt, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.51864460750001,
            28.9608890803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SMF",
          "name": "SAMAR GOPALPUR",
          "zone": "NR",
          "address": "NH 71, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.697762,
            27.558728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "STP",
          "name": "SITAPUR",
          "zone": "NER",
          "address": "Sitapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.721182,
            21.283915999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "STDR",
          "name": "Satadhar",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.60840800000001,
            21.07527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SJF",
          "name": "SAJANVAR ROAD",
          "zone": "WR",
          "address": "MDR, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.37105700000001,
            21.478281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SHH",
          "name": "SHAPUR JN",
          "zone": "WR",
          "address": "Vanthali-Shapur(Sorath) Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.51016399999999,
            23.041337
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SMX",
          "name": "Simurali",
          "zone": "ER",
          "address": "Chakdaha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5690913623,
            25.3640471585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SNAR",
          "name": "Srinagar Rajasthan",
          "zone": "WCR",
          "address": "Rd, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.145524,
            18.952233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SMNE",
          "name": "SOMTANE",
          "zone": "CR",
          "address": "Kolkhe Village, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.636053,
            22.734565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SRGT",
          "name": "SURENDRANAGAR GATE",
          "zone": "WR",
          "address": "Surendranagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.785308,
            15.866512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SWV",
          "name": "SAWANTWADI ROAD",
          "zone": "KR",
          "address": "SH123, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.643675,
            24.405948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SIM",
          "name": "SHRI AMIRGADH",
          "zone": "NWR",
          "address": "Amirgadh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.65672125239999,
            23.820462097900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SLHA",
          "name": "SALHANA",
          "zone": "WCR",
          "address": "State Highway 10, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.375168,
            26.518904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRMT",
          "name": "Sirmuttra",
          "zone": "NCR",
          "address": "Sir Mathura, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.86885600000001,
            14.914015000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SLM",
          "name": "Somalapuram",
          "zone": "SWR",
          "address": "Anantapur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.46617599999999,
            23.667035000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SNMY",
          "name": "Sindri Marshalling Yard",
          "zone": "ECR",
          "address": "Pathardih, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1294882373,
            28.4266753002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRSL",
          "name": "Sirsala",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.57804878910001,
            24.2790237518
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SGPA",
          "name": "Sugapahari",
          "zone": "ER",
          "address": "Madhupur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.518574,
            27.521887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SVO",
          "name": "Sanvrad",
          "zone": "NWR",
          "address": "Didwana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.191033988,
            25.1438922456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SJV",
          "name": "Sanjhauli Halt",
          "zone": "ECR",
          "address": "State Highway 12, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.804862,
            28.380488999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SNZ",
          "name": "Sherganj",
          "zone": "NER",
          "address": "Sherganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.583174,
            13.943036999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SMET",
          "name": "Shimoga Town",
          "zone": "SWR",
          "address": "Shimoga, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.68168063,
            24.49030743
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SSNR",
          "name": "Satsang Nagar Halt",
          "zone": "ER",
          "address": "Deoghar, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.285568,
            20.868216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SKSO",
          "name": "Sikosa",
          "zone": "SECR",
          "address": "Sikosa, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.06853100000001,
            25.441178999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TIL",
          "name": "TILRATH",
          "zone": "ECR",
          "address": "NH 31, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4373421246,
            19.661586179100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "UMM",
          "name": "UMRAM",
          "zone": "SCR",
          "address": "Sitagondi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.453706,
            10.990634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TDR",
          "name": "TIRUVIDAIMARUTHUR",
          "zone": "SR",
          "address": "Thiruvidaimaruthur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.97071700000001,
            21.651992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "TKH",
          "name": "Thakurtola",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.204696,
            32.00601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "TRPL",
          "name": "TRIPAL HALT",
          "zone": "NR",
          "address": "Tripal, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.903937,
            32.239023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "TLRA",
          "name": "TALARA",
          "zone": "NR",
          "address": "Raja-ka-Talab, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.356638,
            18.239215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TMD",
          "name": "TALMADLA",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.474665,
            28.308471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHKL",
          "name": "BHAKRAULI",
          "zone": "NR",
          "address": "State Highway 39, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.381391,
            24.74613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TRWT",
          "name": "TARAVATA",
          "zone": "WCR",
          "address": "Guna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.96561700000001,
            22.622552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WKR",
          "name": "WANKANER JN",
          "zone": "WR",
          "address": "Wankaner, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.86926100000001,
            25.577037999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SWE",
          "name": "SIWAITH",
          "zone": "NR",
          "address": "Prayag, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.40385900000001,
            26.199171
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "TGE",
          "name": "THEKERAGURI",
          "zone": "NFR",
          "address": "Ahotguri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3316696973,
            23.7745106829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "TSAH",
          "name": "Tata Sijua Halt",
          "zone": "SER",
          "address": "Sijua, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.53014599999999,
            15.310975000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TGL",
          "name": "TUGGALI",
          "zone": "SCR",
          "address": "Kurnool, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.148104,
            15.350302000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "UBL",
          "name": "HUBLI JN",
          "zone": "SWR",
          "address": "Hubli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.948621,
            22.296445000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TID",
          "name": "TAGDI",
          "zone": "WR",
          "address": "Tagadi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.615439,
            13.253131999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ARGP",
          "name": "ARALAGUPPE",
          "zone": "SWR",
          "address": "State Highway 74, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.178544,
            29.466064000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "UCA",
          "name": "UCHANA",
          "zone": "NR",
          "address": "District Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.013437,
            22.882582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TAK",
          "name": "Tarakeswar",
          "zone": "ER",
          "address": "Tarkeshwar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.423181,
            23.447386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UMN",
          "name": "AMBLIYASAN",
          "zone": "WR",
          "address": "Mehsana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.904536,
            27.893402000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SZP",
          "name": "Shahjahanpur",
          "zone": "NER",
          "address": "Shahjahanpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.875152,
            8.627298
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TTQ",
          "name": "Thathankulam",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.90478800000001,
            22.350958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TJH",
          "name": "Tajgadh",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.4559422882,
            17.1297597205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UKC",
          "name": "UKSHI",
          "zone": "KR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.906488,
            12.6698
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "UAA",
          "name": "UPPALA",
          "zone": "SR",
          "address": "Kasaragod, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.176143,
            12.142185
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TKQ",
          "name": "TRIKARPUR",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.346365,
            11.927728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VAPM",
          "name": "VALAPATTANAM",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.294811,
            26.921803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TUNG",
          "name": "Tung",
          "zone": "NFR",
          "address": "NH 55, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.626246,
            11.490426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "TKT",
          "name": "TIKKOTTI",
          "zone": "SR",
          "address": "Thikkodi, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.009265,
            20.806248999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UGWE",
          "name": "Ugwe",
          "zone": "SCR",
          "address": "State Highway 204, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.724564,
            21.688066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TRDI",
          "name": "Tirodi",
          "zone": "SECR",
          "address": "Balaghat, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.782017,
            26.311678999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TQA",
          "name": "Takia",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.10904500000001,
            29.067103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "TPU",
          "name": "Tanakpur",
          "zone": "NER",
          "address": "Tanakpur, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.70463099999999,
            13.246834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DHL",
          "name": "Devanhalli",
          "zone": "SWR",
          "address": "Near Bellary Road Bangalore Rural Dt., Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.522164,
            21.04184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TAV",
          "name": "Talala Junction",
          "zone": "WR",
          "address": "Junagadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.47803025,
            26.83193514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TPO",
          "name": "Tantpur",
          "zone": "NCR",
          "address": "Tantpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.675366,
            14.450854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "THKU",
          "name": "Thallak",
          "zone": "SWR",
          "address": "Bellary-Hiriyur Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.2916898169,
            22.6141762638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VD",
          "name": "VAGDIYA",
          "zone": "WR",
          "address": "State Highway 113, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64807800000001,
            15.905354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "YGA",
          "name": "Yerra Goppa Halt",
          "zone": "SWR",
          "address": "Yerra Goppa, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.268065,
            21.378418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AGQ",
          "name": "ASIRGARH ROAD",
          "zone": "CR",
          "address": "State Highway 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.861033,
            27.172456999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AA",
          "name": "ATARIA",
          "zone": "NER",
          "address": "Sitapur National Highway 24, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.14765299999999,
            17.377406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WSD",
          "name": "WASUD",
          "zone": "CR",
          "address": "solapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.612583,
            27.029152
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AED",
          "name": "AULENDA",
          "zone": "NCR",
          "address": "Kiraoli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.28580221,
            29.26223824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ARH",
          "name": "AMHERA",
          "zone": "NR",
          "address": "Amhera, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.456292,
            29.340294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SWDE",
          "name": "SIWAHA",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.535256,
            23.787792
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BHWA",
          "name": "BECHHIWARA",
          "zone": "NWR",
          "address": "Bichhiwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.07902999999999,
            23.612331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VRV",
          "name": "VIRAVADA",
          "zone": "NWR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4094012659,
            15.0342535867
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "YLG",
          "name": "YALVIGI",
          "zone": "SWR",
          "address": "Yalvigi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3472840454,
            13.2834201678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ADHL",
          "name": "ADIHALLI",
          "zone": "SWR",
          "address": "National Highway 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.56831600000001,
            25.586146
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "WRGN",
          "name": "WARIGAON NEWADA",
          "zone": "NR",
          "address": "Warigaon, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.152629,
            18.863471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "APTA",
          "name": "APTA",
          "zone": "CR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.67236,
            22.702931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WC",
          "name": "WADHWAN CITY",
          "zone": "WR",
          "address": "Surendranagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.67466499999999,
            23.495992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ADRA",
          "name": "ADRA JN",
          "zone": "SER",
          "address": "Purulia.Div HQ SER, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.047217,
            21.095661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AKOT",
          "name": "Akot",
          "zone": "SCR",
          "address": "Akola, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.6778278,
            23.2660225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHUJ",
          "name": "BHUJ",
          "zone": "WR",
          "address": "Bhuj, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.85165099999999,
            11.09046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VLI",
          "name": "VALLIKUNNU",
          "zone": "SR",
          "address": "Vallikkunnu, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.314016,
            25.316112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ATRR",
          "name": "ATRAURA",
          "zone": "NER",
          "address": "Handia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.662635,
            16.725963999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VPU",
          "name": "VELPURU",
          "zone": "SCR",
          "address": "Velupuru, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.020996,
            32.57197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "VJPJ",
          "name": "VIJAYPUR JAMMU",
          "zone": "NR",
          "address": "Gadwal, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.849699,
            28.191347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ZNP",
          "name": "Zindpura",
          "zone": "NER",
          "address": "State Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.61637323000001,
            32.27104809
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BHRL",
          "name": "BHAROLI JUNCTION",
          "zone": "NR",
          "address": "Bharoli, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.95733100000001,
            22.332118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PQT",
          "name": "Puniyavant",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.573803,
            11.917402000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VRA",
          "name": "Valavanur",
          "zone": "SR",
          "address": "Vallavanur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.27373100000001,
            25.218913999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "XBKJ",
          "name": "Bikramganj",
          "zone": "ECR",
          "address": "Bikramganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.6653098,
            26.3029681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "VTG",
          "name": "Bhetaguri",
          "zone": "NFR",
          "address": "Bhetaguri, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2672099268,
            27.134570267199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ATKS",
          "name": "Atwa Kursath",
          "zone": "NR",
          "address": "Atwa Kursath, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.740729,
            30.564802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "WDJ",
          "name": "Wanderjatana",
          "zone": "NR",
          "address": "State Highway 16, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7577863,
            20.930082900000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AMI",
          "name": "Amravati",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.39224899999999,
            25.129644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIPR",
          "name": "Bheempura",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.745663,
            25.572021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ARP",
          "name": "Atrampur",
          "zone": "NR",
          "address": "Atrampur Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.786078,
            11.3559
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "WEL",
          "name": "Wellington",
          "zone": "SR",
          "address": "Wellington, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.331277,
            25.867316000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ARKA",
          "name": "Arkha",
          "zone": "NR",
          "address": "Lucknow Allahabad Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.32494000000001,
            26.922783000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAMA",
          "name": "BIRAMBAD",
          "zone": "NCR",
          "address": "Dumariya, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2201080066,
            28.0971879576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AUR",
          "name": "ATRAULI ROAD",
          "zone": "NR",
          "address": "Dibai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.417009,
            23.67077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "BCB",
          "name": "Bhowra Junction",
          "zone": "SER",
          "address": "KUSTORE, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1952487683,
            19.4962759853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BHBK",
          "name": "BODHADI BUJRUG",
          "zone": "SCR",
          "address": "Kinwat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.33741400000001,
            21.2703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BDHA",
          "name": "BADHADA",
          "zone": "WR",
          "address": "Badhada, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8613128,
            21.1278829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHET",
          "name": "BHESTAN",
          "zone": "WR",
          "address": "Surat, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.32981731,
            23.43319157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BFZ",
          "name": "BHANDARTIKURI",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.05862,
            23.231203999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BATL",
          "name": "BAIHATOLA",
          "zone": "SECR",
          "address": "Boridand, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1657234,
            27.2590416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BGQ",
          "name": "BHONGAON",
          "zone": "NCR",
          "address": "Mainpuri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.040289,
            18.275624
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BGHU",
          "name": "Borraguhallu",
          "zone": "ECoR",
          "address": "Way to Rly Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.299879,
            22.981891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BELD",
          "name": "BELKHERA",
          "zone": "WCR",
          "address": "State Highway 22, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.420409,
            22.432086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BIF",
          "name": "BHAIRONPUR",
          "zone": "WCR",
          "address": "State Highway 15, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1986787,
            21.3343976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BAU",
          "name": "BURHANPUR",
          "zone": "CR",
          "address": "Burhanpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3956989,
            25.145112400000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BJK",
          "name": "BIJORA",
          "zone": "WCR",
          "address": "Antah, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.314595,
            27.399181
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BDT",
          "name": "BORDUBI ROAD",
          "zone": "NFR",
          "address": "Madhubon to Naoholia Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6452234,
            31.556593699999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BPRS",
          "name": "BHOGPUR SIRWAL",
          "zone": "NR",
          "address": "Bhogpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.998599,
            25.137088000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BIP",
          "name": "BAHILPURWA",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.75360002999999,
            16.30777084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BGWD",
          "name": "BAGEWADI",
          "zone": "SWR",
          "address": "Gokak TQ, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.90592400000001,
            27.238993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BGAN",
          "name": "Bangain",
          "zone": "NER",
          "address": "Bangain, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.39029839999999,
            12.8004252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BID",
          "name": "BIDADI",
          "zone": "SWR",
          "address": "Bengaluru District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.93010100000001,
            25.173406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BNDI",
          "name": "Barundini",
          "zone": "WCR",
          "address": "National Highway 76, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.616024,
            17.550365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BDCR",
          "name": "Bhadrachalam Road",
          "zone": "SCR",
          "address": "Kottagudem, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.1624991003,
            23.1816003962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KISN",
          "name": "KALISEN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.641696,
            26.356599
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AYU",
          "name": "ABHAYAPURI ASSAM",
          "zone": "NFR",
          "address": "Abhayapuri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.241584,
            23.9869677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BFT",
          "name": "Bhabta",
          "zone": "ER",
          "address": "Bhabta, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.9575192,
            25.2878753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHLP",
          "name": "BHULANPUR",
          "zone": "NER",
          "address": "Varanasi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.744382,
            26.32975
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BAHP",
          "name": "Band Hamirpur",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.473873,
            27.581307
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BLSD",
          "name": "Balsamand",
          "zone": "NWR",
          "address": "Didwana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.640956,
            26.310185
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BEE",
          "name": "Bebejia",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.039851,
            24.300532
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BAA",
          "name": "Barala",
          "zone": "ER",
          "address": "Murshidabad District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.526557,
            23.3046657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BDZ",
          "name": "Badkulla",
          "zone": "ER",
          "address": "State Highway 11, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.20137015629999,
            28.0474529072
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIGA",
          "name": "Bigga",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.20732600000001,
            23.216364
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BAYD",
          "name": "Beyad",
          "zone": "WR",
          "address": "Sabarkantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.854932,
            22.654259999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BBLA",
          "name": "Bhyabla Halt",
          "zone": "ER",
          "address": "Basirhat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.234682,
            23.358779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHAN",
          "name": "Bhoyani",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.487603,
            25.2130523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BK",
          "name": "Bakra Road",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.568611,
            27.964290000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BBJ",
          "name": "Babaganj",
          "zone": "NER",
          "address": "Babaganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.42761300000001,
            22.66472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BBT",
          "name": "Birati",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.71014699999999,
            20.38793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AWH",
          "name": "Alewahi",
          "zone": "SECR",
          "address": "Palasgaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.85700899999999,
            25.902768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BDMA",
          "name": "Budhma",
          "zone": "ECR",
          "address": "National Highway 107, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.703548,
            27.287485999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BGLI",
          "name": "Bargolai",
          "zone": "NFR",
          "address": "Ledo, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.962109,
            22.894163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BLAX",
          "name": "BARLAI",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.395641,
            22.957288000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BSAE",
          "name": "BANSH BARIA",
          "zone": "ER",
          "address": "Bansberia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.100072,
            32.025465000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BRHL",
          "name": "BARYAL HIMACHAL",
          "zone": "NR",
          "address": "Baryal, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.66001700000001,
            14.315154000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CHKE",
          "name": "Challakere",
          "zone": "SWR",
          "address": "Chitradurga, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.178765,
            23.267799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRND",
          "name": "BORIDAND",
          "zone": "SECR",
          "address": "Shahdol, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.34955079999999,
            23.1894855
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "CHRM",
          "name": "Chirmiri",
          "zone": "SECR",
          "address": "Chirimiri, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.3687405,
            22.122780300000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BPKA",
          "name": "BHOPALKA",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.38339300000001,
            13.194258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNKH",
          "name": "BHAIRANAYAKANAH",
          "zone": "SWR",
          "address": "State Highway 74, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.09309499999999,
            18.451229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ALN",
          "name": "ALANDI",
          "zone": "CR",
          "address": "Ramdara Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.0927610413,
            23.5556827445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRLA",
          "name": "BRAYLA CHAURASI",
          "zone": "WR",
          "address": "Badayla Chorasi Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.309816,
            24.333178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BQG",
          "name": "Bhagwangola",
          "zone": "ER",
          "address": "Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.407732,
            22.051583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BQM",
          "name": "Barelipur",
          "zone": "CR",
          "address": "Nayagaon, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.94180892,
            15.40662732
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNA",
          "name": "BANNI KOPPA",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.39915082280001,
            18.1644907169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BKU",
          "name": "BHIKNUR",
          "zone": "SCR",
          "address": "Nizamabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7029205847,
            19.0012905282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BLSA",
          "name": "BOLSA",
          "zone": "SCR",
          "address": "Dharmabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.631226,
            19.164065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BMBE",
          "name": "BIMBARI",
          "zone": "SCR",
          "address": "Bhokar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.666535,
            19.213965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BOKR",
          "name": "BHOKAR",
          "zone": "SCR",
          "address": "Bhokar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.850989,
            24.680339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CAG",
          "name": "CHHABRA GUGOR",
          "zone": "WCR",
          "address": "Baran, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.580569,
            25.008594000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CJW",
          "name": "CHAJAWA",
          "zone": "WCR",
          "address": "Baren, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.777975,
            21.489690999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKTW",
          "name": "Baikunth",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.535285,
            19.680104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ADB",
          "name": "ADILABAD",
          "zone": "SCR",
          "address": "Adilabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.272589,
            28.957503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BPM",
          "name": "BAGPAT ROAD",
          "zone": "NR",
          "address": "Bagpat, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.655843,
            16.224010999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CIV",
          "name": "Chinnaravuru",
          "zone": "SCR",
          "address": "Tenali, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.522429,
            17.542507399999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CHI",
          "name": "CHIPLUN",
          "zone": "KR",
          "address": "Chiplun, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3683267,
            11.870694199999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CAN",
          "name": "KANNUR",
          "zone": "SR",
          "address": "Kannur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6111208,
            23.0304117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BNG",
          "name": "Barnagar",
          "zone": "WR",
          "address": "Barnagar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55442400000001,
            13.644442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MPL",
          "name": "Madanapalle Road",
          "zone": "SCR",
          "address": "Madanapalle Road Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.58596154,
            26.87063169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BRQ",
          "name": "BAGRAKOT",
          "zone": "NFR",
          "address": "National Highway 31, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.025682,
            26.797885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BNQ",
          "name": "BANARHAT",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.197098,
            27.195536999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BMJ",
          "name": "Bridgemanganj",
          "zone": "NER",
          "address": "Bridgmanganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.6075183,
            27.581536
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRK",
          "name": "Bahraich",
          "zone": "NER",
          "address": "Bahraich, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.56082099999999,
            27.489413
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRMX",
          "name": "Barmi",
          "zone": "NR",
          "address": "Barmi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1255797,
            28.7984261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BNW",
          "name": "Bhiwani",
          "zone": "NWR",
          "address": "Bhiwani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0732101,
            28.991685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "BNSA",
          "name": "Banbasa",
          "zone": "NER",
          "address": "H01, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.86896,
            22.650516
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BSHT",
          "name": "Basirhat",
          "zone": "ER",
          "address": "Basirhat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.13898800000001,
            28.796205999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "CHRU",
          "name": "CHAMRUA",
          "zone": "NER",
          "address": "Chamrua, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6576745148,
            11.143966349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ANP",
          "name": "ANANDTANDAVPUR",
          "zone": "SR",
          "address": "Uluthakuppai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.84589116000001,
            16.451873659999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ANGR",
          "name": "ANGADGERI HALT",
          "zone": "SWR",
          "address": "Angadgeri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5292646945,
            8.2494513612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AAY",
          "name": "ARALVAYMOZHI",
          "zone": "SR",
          "address": "Dist. Kanniyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.31557099999999,
            28.34054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ALNP",
          "name": "Akelahanspur Halt",
          "zone": "NER",
          "address": "Akelahanspur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.615774,
            24.065279999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BAH",
          "name": "BHABHAR",
          "zone": "WR",
          "address": "SH 72, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.692248,
            27.114343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AON",
          "name": "ARAON",
          "zone": "NCR",
          "address": "Araon, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.1828793,
            23.8757926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BZLE",
          "name": "BAZARSAU",
          "zone": "ER",
          "address": "Bazarsau, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.47674199999999,
            9.232679000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CHPD",
          "name": "CHEPPAD HALT",
          "zone": "SR",
          "address": "Cheppad Railway Station Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.314105,
            22.406953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CGO",
          "name": "CHHIDGAON",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.565608,
            23.258801000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AJM",
          "name": "Adraj Moti",
          "zone": "WR",
          "address": "Adraj Moti, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.76949432980001,
            16.3690477592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CKR",
          "name": "CHIKODI ROAD",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8322217,
            19.3824486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BSR",
          "name": "VASAI ROAD",
          "zone": "WR",
          "address": "Thane Dist., Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.13497100000001,
            13.907468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ABGT",
          "name": "ARBAGATTA HALT",
          "zone": "SWR",
          "address": "State Highway 47, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.935379,
            25.801897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTJ",
          "name": "BISHNATHGANJ",
          "zone": "NR",
          "address": "Pratapgarh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.651011,
            25.248423000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BUS",
          "name": "BADAUSA",
          "zone": "NCR",
          "address": "National Highway 76, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.993174,
            23.283904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "ANCR",
          "name": "ANCHURI",
          "zone": "SER",
          "address": "National Highway 60A, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.167419,
            25.825412999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSZ",
          "name": "Bharwa Sumerpur",
          "zone": "NCR",
          "address": "Hamirpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.494466,
            26.388205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BSW",
          "name": "Baneswar",
          "zone": "NFR",
          "address": "Baneswar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.13167200000001,
            23.853292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PTN",
          "name": "PATAN",
          "zone": "WR",
          "address": "Patan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.955924,
            21.123876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ABZ",
          "name": "Adgaon Buzurg",
          "zone": "SCR",
          "address": "Akola, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.330818,
            20.986794999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ADE",
          "name": "ADARI ROAD",
          "zone": "WR",
          "address": "kadukada to railway fatak, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.24556342560001,
            14.1395487324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AMC",
          "name": "Amritapura",
          "zone": "SWR",
          "address": "State Highway 48, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.7500982013,
            22.495211350199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDA",
          "name": "CHUDA",
          "zone": "WR",
          "address": "State Highway 38, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.4940121521,
            25.5713190547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AKS",
          "name": "ANKUSPUR",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.399308,
            17.620604999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "ANO",
          "name": "ANJANI",
          "zone": "KR",
          "address": "Anjani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.526808,
            26.499254999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "APDC",
          "name": "Alipur Duar Court",
          "zone": "NFR",
          "address": "Alipur Duar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.563224,
            23.111868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDK",
          "name": "CHANDKHERA ROAD",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.732445,
            12.084594000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CGHD",
          "name": "CHINNADAGUDI",
          "zone": "SWR",
          "address": "Nanjungudu-Chamarajanagara Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.401044,
            19.020403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "CJS",
          "name": "CHARAMULA KUSUM",
          "zone": "ECoR",
          "address": "Bhansuli Kotpad PMGSY Rd, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2593350818,
            14.1738302683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BTGH",
          "name": "Bettadnagenhali",
          "zone": "SWR",
          "address": "Amritapura, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.468851,
            29.283136000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ADR",
          "name": "Mandi Adampur",
          "zone": "NWR",
          "address": "Hisar, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.812599,
            28.290515999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSUR",
          "name": "Bisalpur",
          "zone": "NER",
          "address": "Bisalpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.282814,
            12.7379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ANC",
          "name": "Annechakanahali",
          "zone": "SWR",
          "address": "Annechakanahali, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8420563,
            14.4427884187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CCI",
          "name": "Chinnekuntapali",
          "zone": "SCR",
          "address": "Chennai - Anantpur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.92398,
            27.984069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BTRI",
          "name": "Bitrol",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.282558,
            22.806751
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AGCI",
          "name": "Angadi",
          "zone": "WR",
          "address": "National Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.3455775659,
            26.2947140024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "ALS",
          "name": "Amlori Sarsar",
          "zone": "NER",
          "address": "Siwan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.279311,
            30.147265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BFE",
          "name": "BAHAWAL BASI",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55585400000001,
            26.525539
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AXK",
          "name": "ASOKHAR",
          "zone": "NCR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.91711000000001,
            22.585991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "AXA",
          "name": "AMARSAR",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.882249,
            16.384407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BENL",
          "name": "BENL",
          "zone": "SWR",
          "address": "Dam Inspection Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.6381910315,
            26.8780004923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BBW",
          "name": "BODARWAR",
          "zone": "NER",
          "address": "State Highway 64, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.508955,
            12.720476000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BAJE",
          "name": "Bajakare Halt",
          "zone": "SWR",
          "address": "Panja Kadaba Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.527215,
            17.487039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AMQ",
          "name": "AMMUGUDA",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4034137,
            28.2664932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BBA",
          "name": "BABRALA",
          "zone": "NR",
          "address": "Babrala, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.515006,
            17.505779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ALW",
          "name": "ALWAL",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.576491,
            25.291367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ATE",
          "name": "ATARRA",
          "zone": "NCR",
          "address": "Dist. Banda(05192-224813), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.642236,
            31.425164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "AWL",
          "name": "ALAWALPUR",
          "zone": "NR",
          "address": "Jalandhar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.206701,
            10.896267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AZP",
          "name": "AYYAMPET",
          "zone": "SR",
          "address": "Ayyampet, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2520848,
            13.314425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "ASK",
          "name": "ARSIKERE JN",
          "zone": "SWR",
          "address": "Arasikere, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.981443,
            10.782843000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "BAL",
          "name": "BUDALUR",
          "zone": "SR",
          "address": "SH 99, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.50512839999999,
            25.0967701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAZ",
          "name": "BARAN",
          "zone": "WCR",
          "address": "Baran, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.53816,
            17.316531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AVRD",
          "name": "ARAVALI ROAD",
          "zone": "KR",
          "address": "Kochi - Panavel Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.944693,
            26.662157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BBGN",
          "name": "Barua Bamungaon",
          "zone": "NFR",
          "address": "Barua Bamungaon, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.10713108815253,
            33.738534244499846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "ANT",
          "name": "Anantnag",
          "zone": "NR",
          "address": "Anantnag, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.616227,
            21.710487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BDPA",
          "name": "Badalpur Ph",
          "zone": "?",
          "address": "State Highway 4, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.852807,
            9.036513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "AVS",
          "name": "Auvaneeswaram",
          "zone": "SR",
          "address": "Avaneeswaram Pathanapuram Rd, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8881657,
            18.645933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BDHN",
          "name": "Bodhan",
          "zone": "SCR",
          "address": "Bodhan, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.54301509999999,
            26.74699159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BED",
          "name": "Baseri",
          "zone": "NCR",
          "address": "Baseri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.460168,
            30.459894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BDBG",
          "name": "Badhai Balamgarh Halt",
          "zone": "NR",
          "address": "Badhai Balamgarh, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.769203,
            11.359124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AVK",
          "name": "Aravankadu",
          "zone": "SR",
          "address": "Dist. Nilgiris, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.415479,
            27.273989999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ATW",
          "name": "Atwa Muthia Halt",
          "zone": "NR",
          "address": "Atwa Muthia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.22205951000001,
            26.89181589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BGMU",
          "name": "Bangarmau",
          "zone": "NR",
          "address": "Bangarmau, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.238534,
            18.703461
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "BCHL",
          "name": "Bacheli",
          "zone": "ECoR",
          "address": "Dhurli-Basenpur Rd, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9743682056,
            25.0558596095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BBHT",
          "name": "Barabar Halt",
          "zone": "?",
          "address": "Vanavar Halt, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.01429200000001,
            20.538301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BFJ",
          "name": "Bhoras Budrukh",
          "zone": "CR",
          "address": "Kargaon Tanda-Chalisgaon Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.80997500000001,
            28.494092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BARL",
          "name": "Baral",
          "zone": "NR",
          "address": "State Highway 18, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.519951,
            20.276721000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BDBA",
          "name": "Badabandha Road",
          "zone": "ECoR",
          "address": "Badabandha, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.00164099999999,
            29.697657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BGD",
          "name": "Bara Gudah",
          "zone": "NWR",
          "address": "Bara Gudha, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.953339,
            32.762368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "BLA",
          "name": "BAJALTA",
          "zone": "NR",
          "address": "Khana Chorgol, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.4936287,
            22.164096299999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BHTL",
          "name": "BHATEL",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.748404,
            31.03264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "TSS",
          "name": "TALLI SAIDASAHU",
          "zone": "NR",
          "address": "Talhi Saidasahu, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.56182792,
            24.983339819999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BDWS",
          "name": "BADARWAS",
          "zone": "WCR",
          "address": "Badarwas, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.632508,
            32.050368999999996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BJPL",
          "name": "BAIJNATH PAPROLA",
          "zone": "NR",
          "address": "Paprola, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.27572365,
            15.16290019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BLL",
          "name": "BANTANAHAL",
          "zone": "SCR",
          "address": "Rayalaseema Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3616019397,
            21.7371288607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BMA",
          "name": "BAGMAR",
          "zone": "CR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.719998,
            16.823851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BLWD",
          "name": "BOLWAD",
          "zone": "CR",
          "address": "Bolwad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.23764299999999,
            17.512801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BMNI",
          "name": "BAMANI",
          "zone": "CR",
          "address": "State Highway 161, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7918337,
            27.4957951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BNY",
          "name": "Barhni",
          "zone": "NER",
          "address": "Semari - Bisokhar Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.702832,
            13.842428700000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BDVT",
          "name": "Bhadravati",
          "zone": "SWR",
          "address": "Bhadravati, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.90529000000001,
            26.655732999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BMKI",
          "name": "BAPUDHAM MOTIHARI",
          "zone": "ECR",
          "address": "Motihari, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.045654,
            19.268544000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BIRD",
          "name": "BHIWANDI ROAD",
          "zone": "CR",
          "address": "Bhiwandi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.468715,
            24.411465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BI",
          "name": "BARI SADRI",
          "zone": "NWR",
          "address": "Bari Sadri, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.887446,
            20.275323200000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BLD",
          "name": "BHILAD",
          "zone": "WR",
          "address": "Bhilad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.489941,
            25.259915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ALMW",
          "name": "ALMAW",
          "zone": "NER",
          "address": "Allahabad, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.821842,
            25.916759000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BGX",
          "name": "BAGRI SAJJANPUR",
          "zone": "NWR",
          "address": "Haripur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.517982,
            23.00802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VTP",
          "name": "VASTRAPUR",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.356879,
            21.434145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BNTH",
          "name": "BANDHNATH",
          "zone": "WR",
          "address": "Shapur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6420535,
            13.8221962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BIJR",
          "name": "BIJOOR",
          "zone": "KR",
          "address": "Udupi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.98872560000001,
            24.7131638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BNS",
          "name": "BANAS",
          "zone": "NWR",
          "address": "Sirohi Road, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.323477,
            22.196154999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "BILP",
          "name": "Bhilpur",
          "zone": "WR",
          "address": "Vadodara, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.36331200000001,
            25.419012000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BGPR",
          "name": "BHAGWANPURA",
          "zone": "NWR",
          "address": "Somesar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.52738176000001,
            25.60180707
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BGG",
          "name": "BANTA RAGHUNATHGARH",
          "zone": "NWR",
          "address": "Somesar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.305586,
            26.196877
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BJPR",
          "name": "Bijaipur Road",
          "zone": "NCR",
          "address": "Bijaipur Road, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3291058,
            28.26312382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BMY",
          "name": "Bamiana",
          "zone": "NER",
          "address": "To Vishartganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.20503345,
            20.33902661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BNHR",
          "name": "Banbihari Gwalipur",
          "zone": "ECoR",
          "address": "State Highway 12, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.674711,
            26.327284000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BHAT",
          "name": "Bhatpura",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.089708,
            27.439639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BINR",
          "name": "Brij Nagar",
          "zone": "NCR",
          "address": "Bharatpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.57044308,
            26.46830942
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BKS",
          "name": "Balikotia",
          "zone": "NFR",
          "address": "Mathaouri Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.547232,
            22.05264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BIR",
          "name": "Bir",
          "zone": "WCR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.56775470000001,
            25.6766583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHDR",
          "name": "Bhadri",
          "zone": "NR",
          "address": "National Highway 24B, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.209461,
            27.453929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BEDM",
          "name": "Bedham",
          "zone": "NCR",
          "address": "State Highway 14, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.5563227,
            24.865177499999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BPB",
          "name": "BADARPUR JN",
          "zone": "NFR",
          "address": "03845-269546., Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.563483,
            18.722977999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GTU",
          "name": "GHAT NANDUR",
          "zone": "SCR",
          "address": "Ghat Nadur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.034456,
            12.878545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNTL",
          "name": "Bantawala",
          "zone": "SWR",
          "address": "Bantwal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38519600000001,
            12.667132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BRBL",
          "name": "Birahalli",
          "zone": "SWR",
          "address": "Birahalli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.34822341,
            25.53140767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSCK",
          "name": "BASUCHAK",
          "zone": "NER",
          "address": "Basuchak-Rampur Mangha Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.16898527,
            12.088426380000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AND",
          "name": "ANDAMPALIAM",
          "zone": "SR",
          "address": "Maniyam St, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.69240260000001,
            29.859333900000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BRZ",
          "name": "BARETA",
          "zone": "NR",
          "address": "Bareta, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.455989,
            24.197002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRGW",
          "name": "BARGAWAN",
          "zone": "WCR",
          "address": "Bargawa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.473946,
            25.564132999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSY",
          "name": "BARSATHI",
          "zone": "NR",
          "address": "State Highway 36, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.017326,
            21.964332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BUF",
          "name": "Bimlagarh Junction",
          "zone": "SER",
          "address": "Sundargarh, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.213283,
            29.497228000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSKR",
          "name": "BASI KIRATPUR",
          "zone": "NR",
          "address": "Kiratpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.3128162,
            24.2064915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CDS",
          "name": "CHANDISAR",
          "zone": "WR",
          "address": "Banaskantha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.932083,
            30.209488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BTI",
          "name": "BHATINDA JN",
          "zone": "NR",
          "address": "Bathinda, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.333576,
            27.236867999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BTZ",
          "name": "BALIMARA",
          "zone": "NFR",
          "address": "Naharkatiya to Nigam Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.644427,
            32.271804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PTK",
          "name": "PATHANKOT",
          "zone": "NR",
          "address": "NH 20, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.312946,
            17.156895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BVNR",
          "name": "BHAVANI NAGAR",
          "zone": "CR",
          "address": "Tasgaon Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.6934544,
            26.3551471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BQP",
          "name": "Bighapur",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.171838,
            10.561834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "AMLR",
          "name": "AMALA NAGAR",
          "zone": "SR",
          "address": "Thrissur, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.19988529999999,
            21.8116285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BTC",
          "name": "Balaghat Junction",
          "zone": "SECR",
          "address": "Balaghat, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.511335,
            27.41454869
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BOTI",
          "name": "Baori Thikria",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0829696,
            30.890852900000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BOF",
          "name": "Barog",
          "zone": "NR",
          "address": "Barog, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.67658999999999,
            21.548768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "BSTP",
          "name": "Basantapur",
          "zone": "ECoR",
          "address": "National Highway 215, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.30580244,
            13.74759508
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BTM",
          "name": "Battulapuram",
          "zone": "SCR",
          "address": "Battulapuram Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.83471200000001,
            26.284843000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BSWA",
          "name": "Baiswara",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "AMJ",
          "name": "Amin Gaon",
          "zone": "NFR",
          "address": "Guwahati, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AMNR",
          "name": "Ammanur",
          "zone": "SR",
          "address": "Mazavarayanallu Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.263642,
            24.0890841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BPC",
          "name": "Berhampore Court",
          "zone": "ER",
          "address": "Berhampore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.38580758869648,
            10.332285228976446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AMM",
          "name": "Atirampattinam",
          "zone": "SR",
          "address": "Muthupet, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.65068451687694,
            28.223341365041605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CRWA",
          "name": "Chirawa",
          "zone": "NWR",
          "address": "SH 13, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ANKI",
          "name": "Anakhi",
          "zone": "WR",
          "address": "Bharuch, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "ANW",
          "name": "Anawal",
          "zone": "WR",
          "address": "Anawal, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.029894,
            29.91997899999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BWC",
          "name": "Banwali",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.48348299999999,
            22.723879999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BT",
          "name": "Barasat",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.653766,
            25.901014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BYP",
          "name": "Baijnathpur",
          "zone": "ECR",
          "address": "State Highway 59, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.4633792,
            23.438519499999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BPD",
          "name": "Bahadurpur",
          "zone": "ER",
          "address": "Bahadurpur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.119799,
            21.847398000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Odisha",
          "code": "BXF",
          "name": "Barsuan",
          "zone": "SER",
          "address": "Sundargarh, Odisha"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.478487,
            27.211161
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "AMO",
          "name": "Amolwa",
          "zone": "ECR",
          "address": "Pashchim Champaran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.29713100000001,
            26.916354000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BXN",
          "name": "BAYANA JN",
          "zone": "WCR",
          "address": "Bayana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.111962,
            25.790577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "SKEN",
          "name": "Sukhshena",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.69546199999999,
            23.867175000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DNRP",
          "name": "DUNGARPUR",
          "zone": "NWR",
          "address": "State Highway 10, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.118735,
            26.469531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CHBR",
          "name": "CHAURE BAZAR",
          "zone": "NR",
          "address": "State Highway 9, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.672161,
            28.50831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DDW",
          "name": "DUDWA",
          "zone": "NER",
          "address": "Dudwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.525813,
            21.659142000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CKE",
          "name": "CHOKI SORATH",
          "zone": "WR",
          "address": "National Highway 8D, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.990864,
            27.35207
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CIH",
          "name": "Chilhia",
          "zone": "NER",
          "address": "State Highway 1A, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.937644,
            24.672086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DGLE",
          "name": "DHULIAN GANGA",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7033411,
            11.391471000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CDM",
          "name": "CHIDAMBARAM",
          "zone": "SR",
          "address": "Chidambaram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.805294,
            26.558703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "COO",
          "name": "CHHOTI ODAI",
          "zone": "WCR",
          "address": "SH 25, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.40083419070001,
            27.137702177799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CAMU",
          "name": "CHAMUA",
          "zone": "ECR",
          "address": "National Highway 28B, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.10305100000001,
            23.486172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DHLI",
          "name": "Dethli",
          "zone": "WR",
          "address": "State Highway 19, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.17026899999999,
            23.60191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DHAE",
          "name": "DAINHAT",
          "zone": "ER",
          "address": "Daihaat Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.57614099999999,
            14.66779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DAD",
          "name": "DEVARGUDDA",
          "zone": "SWR",
          "address": "Hanumanamatti, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0792665497,
            20.8911603401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DEW",
          "name": "Dewalgaon",
          "zone": "SECR",
          "address": "Warsa Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.051292,
            28.536700000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "BWSN",
          "name": "BIJWASAN",
          "zone": "NR",
          "address": "Bijwasan, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2491554279,
            13.2331801567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DBS",
          "name": "DOBBSPET",
          "zone": "SWR",
          "address": "Dobbasapete, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.975613,
            22.384024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DCK",
          "name": "DHANDHUKA",
          "zone": "WR",
          "address": "Dhandhuka, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.6653098,
            26.3029681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "COB",
          "name": "Cooch Behar",
          "zone": "NFR",
          "address": "Cooch Behar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.22811899999999,
            10.929228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "CQA",
          "name": "Cherukara",
          "zone": "SR",
          "address": "Cherukara, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.187106,
            28.662992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DEE",
          "name": "DELHI SARAI ROHILLA",
          "zone": "NR",
          "address": "New Rohtak road, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.17138999999999,
            28.667329000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "DBSI",
          "name": "DAYA BASTI",
          "zone": "NR",
          "address": "Zakhira, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.578082,
            30.501186999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CWN",
          "name": "Charawan",
          "zone": "NR",
          "address": "State Highway 16, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.23735384456813,
            27.93879936977548
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DOB",
          "name": "Dundlodh Mukundgarh",
          "zone": "NWR",
          "address": "Mukandgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.032707,
            28.939509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "BWK",
          "name": "Bawani Khera",
          "zone": "NWR",
          "address": "Bawani Khera, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.44446,
            22.205278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BARU",
          "name": "Baharu",
          "zone": "ER",
          "address": "Baharu, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.432729,
            28.434064000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DOX",
          "name": "DOHNA",
          "zone": "NER",
          "address": "State Highway 37, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.153157,
            17.445987000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DKJ",
          "name": "DORNAKAL JN",
          "zone": "SCR",
          "address": "Garla, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.72770799999999,
            26.468979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GGC",
          "name": "GANGAPUR CITY",
          "zone": "WCR",
          "address": "Gangapur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.265728,
            28.150306
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DMPR",
          "name": "DHARAMPUR",
          "zone": "NR",
          "address": "National Highway 93, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.44478000000001,
            21.72217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DJI",
          "name": "DHORAJI",
          "zone": "WR",
          "address": "Dhoraji, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.105361,
            23.948636999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DLD",
          "name": "DALAUDA",
          "zone": "WR",
          "address": "Dalauda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.212452,
            27.274196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TKRU",
          "name": "TAKHRAU",
          "zone": "NCR",
          "address": "Takhrau, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.839083,
            21.073708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DSK",
          "name": "DUSKHEDA",
          "zone": "CR",
          "address": "Jalgaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2606046,
            29.461053699999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DING",
          "name": "Ding",
          "zone": "NWR",
          "address": "Sirsa, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.246087,
            24.195866000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DHPD",
          "name": "DHAPARA DHAM",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.177888,
            19.647058
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DNE",
          "name": "DHAMNI",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.924339,
            25.745642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DHRJ",
          "name": "DHIRGANJ",
          "zone": "NR",
          "address": "State Highway 9, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.642481,
            15.437048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GDG",
          "name": "GADAG JN",
          "zone": "SWR",
          "address": "Gadag, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.176780918,
            18.2300065666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DNJ",
          "name": "DAUNDAJ",
          "zone": "CR",
          "address": "Indraprastha Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.92229,
            14.467834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DVG",
          "name": "DAVANGERE",
          "zone": "SWR",
          "address": "Davanagere, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.35246400000001,
            24.096925000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DRGM",
          "name": "DEORAGRAM",
          "zone": "WCR",
          "address": "Gajarbahara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.99678700000001,
            23.971207999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DMYA",
          "name": "DAMOY",
          "zone": "WCR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.008846,
            13.552501000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "DRU",
          "name": "KADUR",
          "zone": "SWR",
          "address": "Kadur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.004006,
            29.688827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DTN",
          "name": "DHAMTAN SAHIB",
          "zone": "NR",
          "address": "Jind District, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.76137999999999,
            30.682857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "FDK",
          "name": "FARIDKOT",
          "zone": "NR",
          "address": "Faridkot, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.88859,
            29.452122999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DULP",
          "name": "Daulatpur Haryana",
          "zone": "NR",
          "address": "National Highway 65, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.202954,
            25.983503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DPSR",
          "name": "Dapsaura",
          "zone": "NCR",
          "address": "National Highway 86, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.914683,
            22.185683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "DMF",
          "name": "Dumerta",
          "zone": "SER",
          "address": "Rourkela, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.154742,
            12.126595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "DPJ",
          "name": "Dharmapuri",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.041529,
            20.773429
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DVA",
          "name": "Delvada",
          "zone": "WR",
          "address": "Delvada, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.312269,
            21.310416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DKU",
          "name": "Dumri Khurd",
          "zone": "SECR",
          "address": "Dumri Khurd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.801658,
            29.211731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DNX",
          "name": "Dhansu",
          "zone": "NR",
          "address": "National Highway 65, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.0826,
            22.44572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DIBL",
          "name": "Dholi Bhal",
          "zone": "WR",
          "address": "Rayaka, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.817047,
            22.029346999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DSPN",
          "name": "Deshpran Ph",
          "zone": "?",
          "address": "Bajkul, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1765459082,
            23.332027810299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DTJ",
          "name": "Detroj",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.80467660219999,
            29.874366636999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GRN",
          "name": "GURNAY",
          "zone": "NR",
          "address": "MDR 102, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.848169,
            30.840521000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GILL",
          "name": "GILL",
          "zone": "NR",
          "address": "Ludhiana, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.395751,
            23.289638
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DTL",
          "name": "DARRITOLA",
          "zone": "SECR",
          "address": "National Highway 78, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.6653098,
            26.3029681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DWT",
          "name": "Dewan Hat",
          "zone": "NFR",
          "address": "Dewan, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.6044763025,
            25.6187302859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "FNO",
          "name": "FUNGO HALT",
          "zone": "ECR",
          "address": "Fungo, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.7937161591,
            28.711716968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FRD",
          "name": "FARHEDI",
          "zone": "NR",
          "address": "Moradabad- Chandausi Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.83064999999999,
            26.108359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "GKJ",
          "name": "GOLAKGANJ",
          "zone": "NFR",
          "address": "Golokganj, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.26179,
            26.843511
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "FTC",
          "name": "Fatehpur Chursi",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.86309299999999,
            25.668107000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GNGL",
          "name": "Gangauli",
          "zone": "NER",
          "address": "Jaunpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.738839,
            16.205164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GKK",
          "name": "GOKAK ROAD",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.771432,
            27.185738999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GRRG",
          "name": "GURLI RAMGARHWA",
          "zone": "NER",
          "address": "Ghughli Bazar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.974481,
            18.824644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "FKB",
          "name": "FAKHRABAD",
          "zone": "SCR",
          "address": "Basara, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.893448392,
            18.5278209233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GPR",
          "name": "GHORPURI",
          "zone": "CR",
          "address": "Pune, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.413983,
            13.148289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GHL",
          "name": "GOLHALLI",
          "zone": "SWR",
          "address": "State Highway 74, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.029894,
            29.91997899999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FSW",
          "name": "Fateh Singhwala",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.19780499999999,
            28.46474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DYK",
          "name": "DUDHIA KHURD",
          "zone": "NER",
          "address": "State Highway 21, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64500100000001,
            27.240582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GND",
          "name": "Govindgarh Malikpur",
          "zone": "NWR",
          "address": "Govindgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.30253,
            18.162498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GNO",
          "name": "GOREGAON ROAD",
          "zone": "KR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.418174,
            17.855413000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DWV",
          "name": "DIWANKHAVATI",
          "zone": "KR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.306151,
            22.340478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GMDN",
          "name": "GIRIMAIDAN",
          "zone": "SER",
          "address": "Kharagpur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.65349208250001,
            31.7805618081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GSB",
          "name": "GARNA SAHIB",
          "zone": "NR",
          "address": "National Highway 1A, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.293716,
            22.375923
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GKL",
          "name": "GOKULPUR",
          "zone": "SER",
          "address": "Midnapore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.403784,
            26.802802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GLMA",
          "name": "GULMA",
          "zone": "NFR",
          "address": "National Highway 31, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.484975,
            23.294039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "EN",
          "name": "ISAND",
          "zone": "WR",
          "address": "Khodiyar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.21679153630001,
            24.2090318514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DXN",
          "name": "DUDDHINAGAR",
          "zone": "ECR",
          "address": "Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.31176099999999,
            24.05344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GAJB",
          "name": "GAJARA BAHARA",
          "zone": "WCR",
          "address": "Gajarbahara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.97194,
            30.842438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "GMM",
          "name": "Gumman",
          "zone": "NR",
          "address": "Parwanoo, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.723237,
            21.514747
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "GBRI",
          "name": "Gobarwahi",
          "zone": "SECR",
          "address": "State Highway 251, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.067973,
            20.586729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "DRZ",
          "name": "Dalli Rajhara",
          "zone": "SECR",
          "address": "Dalli Rajhara, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.687208,
            20.988488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GRHM",
          "name": "Gir Hadmatiya",
          "zone": "WR",
          "address": "Jambur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.260098,
            20.308158000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "GRKN",
          "name": "Gorakhnath",
          "zone": "ECoR",
          "address": "Jagatsinghpur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.64640372000001,
            23.335464709999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGL",
          "name": "Bagula",
          "zone": "ER",
          "address": "Bagula, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.399987,
            30.715001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GHS",
          "name": "Guru Harsahai",
          "zone": "NR",
          "address": "Panjeke, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.605596,
            26.019349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GEB",
          "name": "Garh Baruari",
          "zone": "ECR",
          "address": "Garh Baruari, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.64566,
            22.348523999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "GAD",
          "name": "Gevra Road",
          "zone": "SECR",
          "address": "Korba, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.503293,
            27.373959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GHRI",
          "name": "Ghori Halt",
          "zone": "NR",
          "address": "sharda nehar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.177008,
            26.987889000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GJMB",
          "name": "Ganjmuradabad",
          "zone": "NR",
          "address": "Sharda Nehar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.37297299999999,
            27.204231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GAJU",
          "name": "Gaju Halt",
          "zone": "NR",
          "address": "State Highway 25, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.498954,
            16.781754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GNN",
          "name": "GANGINENI",
          "zone": "SCR",
          "address": "Yerrupalem, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.097705,
            30.260892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "HRW",
          "name": "HARRAWALA",
          "zone": "NR",
          "address": "Dehradun, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.09101,
            32.051899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "NGRS",
          "name": "NAGROTA SURIYAM",
          "zone": "NR",
          "address": "State Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.24646,
            12.786494999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HLN",
          "name": "Hole Narsipur",
          "zone": "SWR",
          "address": "Hassan District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.48091199999999,
            18.385198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HGL",
          "name": "HARANGUL",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.576122,
            15.520135999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HBL",
          "name": "HOMBAL",
          "zone": "SWR",
          "address": "Belavaniki, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.10084,
            22.338414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HD",
          "name": "HARDA",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.09084800000001,
            25.611069999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HTL",
          "name": "HATIKHALI",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.11563199999999,
            23.627772999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JAO",
          "name": "JAORA",
          "zone": "WR",
          "address": "Jaora, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.925834,
            30.147102999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HMK",
          "name": "HINDUMALKOTE",
          "zone": "NR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.420592,
            30.320113000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GUNS",
          "name": "GHUNAS",
          "zone": "NR",
          "address": "Ghunas Railway Station, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.728229,
            27.769192
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HA",
          "name": "HARGAON",
          "zone": "NER",
          "address": "Hargaon, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.616939,
            25.187264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HMG",
          "name": "HAMIRGARH",
          "zone": "NWR",
          "address": "Hamirgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0517473206,
            15.1512387259
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HGI",
          "name": "HAGARI",
          "zone": "SCR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.938107,
            23.540458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HPRD",
          "name": "HAPA ROAD",
          "zone": "WR",
          "address": "National Highway 8, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1813366473,
            10.8019329802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PTB",
          "name": "PATTAMBI",
          "zone": "SR",
          "address": "Pattambi, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6527179736,
            29.895695706599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PBJM",
          "name": "Pabnawa Jasmahinder Halt",
          "zone": "NR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.979148,
            15.153264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HDD",
          "name": "HADDINAGUNDU",
          "zone": "SCR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.398985,
            28.24751
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JMS",
          "name": "JAMSAR",
          "zone": "?",
          "address": "Bikaner, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.62065899999999,
            26.126534999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "GLPT",
          "name": "GOALPARA TOWN",
          "zone": "NFR",
          "address": "Goalpara Town Rail Station, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.283878,
            15.42206
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HBS",
          "name": "HEBSUR",
          "zone": "SWR",
          "address": "Hebsur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.077533,
            16.45715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GUK",
          "name": "GUNTAKONDURU",
          "zone": "SCR",
          "address": "P.P.Road, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.39687099999999,
            25.462722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JAL",
          "name": "JAWALI",
          "zone": "NWR",
          "address": "Rania, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.665909,
            28.633331000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HZN",
          "name": "Hazrat Nagar Halt",
          "zone": "NR",
          "address": "Hazrat Nagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.2607646,
            22.7428208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "HLD",
          "name": "Haludpukur",
          "zone": "SER",
          "address": "State Highway 50, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2918266571,
            27.135126874500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GSGJ",
          "name": "Ghausganj",
          "zone": "NR",
          "address": "Ghausganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.251975,
            21.943048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JMV",
          "name": "Jambara",
          "zone": "CR",
          "address": "Amla, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.117359,
            13.003182
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HAS",
          "name": "Hassan Junction",
          "zone": "SWR",
          "address": "Hassan, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.470282,
            22.711098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HHR",
          "name": "Hridaypur",
          "zone": "ER",
          "address": "Madhyamgram, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.97130647339999,
            18.4269033932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HER",
          "name": "Her",
          "zone": "SCR",
          "address": "Latur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.83866138549999,
            16.2842670722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "JRKT",
          "name": "JADARAMA KUNTE",
          "zone": "SWR",
          "address": "National Highway 13, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.067432,
            31.093842
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JGW",
          "name": "JOGIWALA",
          "zone": "NR",
          "address": "State Highway 19, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.099997,
            29.891989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "IKK",
          "name": "IKKAR",
          "zone": "NR",
          "address": "Haridwar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.308307,
            26.999949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JCH",
          "name": "JETHA CHANDAN",
          "zone": "NWR",
          "address": "Chandan, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1417954028,
            30.0785698608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "QSR",
          "name": "KANSRAO",
          "zone": "NR",
          "address": "Dehradun, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.799492,
            28.372602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JSKA",
          "name": "JATAULA SAMPHKA",
          "zone": "NR",
          "address": "Hailley Mandi, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7074,
            26.210097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JWS",
          "name": "JHARWASAA",
          "zone": "NWR",
          "address": "Jharwasa, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.226427,
            32.015744000000005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "JMKR",
          "name": "JAWLMUKHI ROAD",
          "zone": "NR",
          "address": "Jwalamukhi, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.76982299999999,
            32.282959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KAWL",
          "name": "KANDWAL HALT",
          "zone": "NR",
          "address": "Pathankot, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.907292,
            19.90643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KAYR",
          "name": "KAYAR",
          "zone": "?",
          "address": "State Highway 233, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.863876,
            24.740226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JTW",
          "name": "JAITWAR",
          "zone": "WCR",
          "address": "Jaitwar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0759090985,
            20.2854429153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JUK",
          "name": "JAULKA",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.432389,
            23.986873000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JKE",
          "name": "JUKEHI",
          "zone": "WCR",
          "address": "Jukehi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.11800600000001,
            30.992082999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KANO",
          "name": "Kanoh",
          "zone": "NR",
          "address": "Sadhupul, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.28961699999999,
            29.328875999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JIND",
          "name": "JIND JN",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.03138700000001,
            21.906659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JDH",
          "name": "JAM JODHPUR JN",
          "zone": "WR",
          "address": "Jamjodhpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.991109,
            26.563801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JKB",
          "name": "Jakhalabandha",
          "zone": "NFR",
          "address": "Jakhalabandha, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.192446,
            24.080516999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JVT",
          "name": "JIVANTI HALT",
          "zone": "ER",
          "address": "State Highway 11, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.741306,
            26.108194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JMK",
          "name": "JAMUNAMUKH",
          "zone": "NFR",
          "address": "National Highway 36, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.909586,
            25.049118999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KDL",
          "name": "Kankroli",
          "zone": "NWR",
          "address": "Rajsamand, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.164150647,
            24.1796156953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JRQ",
          "name": "JHAROKHAS",
          "zone": "ECR",
          "address": "National Highway 75, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.360755,
            26.803135
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JEA",
          "name": "Nakaha Jungle",
          "zone": "NER",
          "address": "Gorakhpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.762682,
            24.096599
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JOBA",
          "name": "JOBA",
          "zone": "WCR",
          "address": "Madhya Pradesh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.292477,
            24.81523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "JMN",
          "name": "JAMUAWAN",
          "zone": "?",
          "address": "Jamuawan, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.419294,
            21.952769
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "JRLI",
          "name": "Jaroli",
          "zone": "?",
          "address": "Juruli, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.649455,
            29.005748999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JSS",
          "name": "JASIA",
          "zone": "?",
          "address": "National Highway 71A, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.079446,
            18.64059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KASU",
          "name": "KASU",
          "zone": "CR",
          "address": "Maharashtra, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.670312,
            9.977293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KAL",
          "name": "Kallai",
          "zone": "SR",
          "address": "Kallal, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.183139,
            14.821387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KAWR",
          "name": "KARWAR",
          "zone": "KR",
          "address": "Karwar, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2173007153,
            25.138577101499997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "IPR",
          "name": "Islampur",
          "zone": "ECR",
          "address": "Islampur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.013757,
            19.066004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "JDB",
          "name": "JAGDALPUR",
          "zone": "ECoR",
          "address": "Jagdalpur, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.716049,
            12.478701999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CGKP",
          "name": "CHANDAGIRIKOPAL",
          "zone": "SWR",
          "address": "State Highway 7, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.14732500000001,
            25.114043000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JWB",
          "name": "JAWAI BANDH",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.134971,
            31.349656
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JJJ",
          "name": "Jaijon Doaba",
          "zone": "NR",
          "address": "Jaijon Doaba, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.27698,
            24.244268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JJG",
          "name": "JiaGanj",
          "zone": "ER",
          "address": "Police Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.397713,
            22.4727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGRI",
          "name": "New Garia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.822839,
            12.717606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "HSRA",
          "name": "Hosur",
          "zone": "SWR",
          "address": "Hosur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.864884,
            25.626813000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KBK",
          "name": "Khamil Ghat",
          "zone": "NWR",
          "address": "State Highway 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.683667,
            21.385151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JCN",
          "name": "Junichavand",
          "zone": "WR",
          "address": "Junichavand, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.64004200000001,
            21.244101999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KANS",
          "name": "Kansiya Nes",
          "zone": "WR",
          "address": "State Highway 111, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.89647099999999,
            17.686252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KDM",
          "name": "KESAMUDRAM",
          "zone": "SCR",
          "address": "Kesamudram, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.45468500000001,
            18.099269000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CNHL",
          "name": "CHINK HILL",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.7880650638,
            28.680444428799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KD",
          "name": "KUNDARKHI",
          "zone": "NR",
          "address": "Kundarki, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.240057,
            16.955204000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "COA",
          "name": "Kakinada Port",
          "zone": "SCR",
          "address": "Kakinada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.04076,
            24.459515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "JRLE",
          "name": "JANGIPUR ROAD",
          "zone": "ER",
          "address": "Raghunathganj, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.922775,
            25.217610999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CKTD",
          "name": "CHITRAKUTDHAM KARWI",
          "zone": "NCR",
          "address": "Chitrakoot Karvi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.099683,
            14.21716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KCM",
          "name": "Kalasumudram",
          "zone": "SCR",
          "address": "Chennai - Anantpur Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.36547849,
            21.7026228941
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KDK",
          "name": "KOHDAD",
          "zone": "CR",
          "address": "State Highway 15, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.262564,
            12.936251
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CHU",
          "name": "Champion",
          "zone": "SWR",
          "address": "KGF, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.555883,
            22.735539
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CJR",
          "name": "CHAMARAJ",
          "zone": "WR",
          "address": "State Highway 17, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.81228634829999,
            30.042985535099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CJL",
          "name": "CHAJLI",
          "zone": "NR",
          "address": "Jakhal Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73819999999999,
            18.944571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KEK",
          "name": "KARKHELI",
          "zone": "SCR",
          "address": "Biloli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.582933,
            23.729821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "CNPR",
          "name": "CHAINPUR",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.68394941769999,
            22.2788440574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KDI",
          "name": "KUNDLI",
          "zone": "WR",
          "address": "State Highway 38, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.794278,
            31.621878000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CIA",
          "name": "CHHEHARTA",
          "zone": "NR",
          "address": "Amritsar, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.039454,
            15.006905999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "CNO",
          "name": "CANCONA",
          "zone": "KR",
          "address": "Ph:-0832-2643331., Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.818104,
            26.062329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CJA",
          "name": "CHONGAJAN",
          "zone": "NFR",
          "address": "local Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.334484,
            15.397879
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CLR",
          "name": "CASTLE ROCK",
          "zone": "SWR",
          "address": "National Highway 4A, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.663729,
            12.190406999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KDO",
          "name": "KADAKOLA",
          "zone": "SWR",
          "address": "Mysore District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.576384,
            26.241537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "JPZ",
          "name": "JOGIGHOPA",
          "zone": "NFR",
          "address": "National Highway 31B, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CMK",
          "name": "Chamak",
          "zone": "CR",
          "address": "Amravati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.277001,
            26.877401000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KGN",
          "name": "Kurseong",
          "zone": "NFR",
          "address": "Kurseong, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0317514441,
            25.330397498299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "CKMI",
          "name": "Chhotaki Masaudhi Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.67626636840001,
            27.5895214437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KAWT",
          "name": "Kanwat",
          "zone": "NWR",
          "address": "Kanwat, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.741029,
            23.145849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CNN",
          "name": "Chintaman Ganes",
          "zone": "WR",
          "address": "Ujjain, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.169432,
            26.715526999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CMA",
          "name": "Cinnamara",
          "zone": "NFR",
          "address": "Cinnamora, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.463656,
            17.516012
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CMW",
          "name": "Chima Pahad",
          "zone": "SCR",
          "address": "Khammam, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.53707700000001,
            17.823439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CHTT",
          "name": "Chitta Halt",
          "zone": "NCR",
          "address": "Chitta Halt, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.279921,
            28.600799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "CKD",
          "name": "Charkhi Dadri",
          "zone": "NWR",
          "address": "Dist. Bhiwani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.842478,
            28.211203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CKSR",
          "name": "Chak Safaura Halt",
          "zone": "NER",
          "address": "Mighauna, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.79989095580001,
            28.541970328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CHWT",
          "name": "Chhaprawat",
          "zone": "NR",
          "address": "SH 18, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.476631,
            12.718252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KDBA",
          "name": "Kodimbala Halt",
          "zone": "SWR",
          "address": "Panja Kadaba Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.19779600000001,
            28.809864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KEMR",
          "name": "KEMRI",
          "zone": "NER",
          "address": "MDR 33W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.109226,
            25.886233999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KHTX",
          "name": "KHED TEMPLE HALT",
          "zone": "NWR",
          "address": "Tilwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.025843,
            24.51403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CPU",
          "name": "CHOPAN",
          "zone": "ECR",
          "address": "Chopan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.618291,
            22.955606999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DAC",
          "name": "DAHINSARA JN",
          "zone": "WR",
          "address": "SH 24, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.038231,
            21.0022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PTZ",
          "name": "Patsul",
          "zone": "SCR",
          "address": "MSH24, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.126813,
            26.525308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KJA",
          "name": "KHAJURHAT",
          "zone": "NR",
          "address": "Bikapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.67087339999999,
            29.680031500000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DBD",
          "name": "DEOBAND",
          "zone": "NR",
          "address": "Deoband, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.61766999999999,
            27.40119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CRG",
          "name": "CHURAMAN NAGRI",
          "zone": "WCR",
          "address": "SH 33, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.623687,
            24.874340999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "COR",
          "name": "CHITTAURGARH",
          "zone": "WR",
          "address": "Chittorgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.518694,
            17.494734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CVB",
          "name": "CAVALRY BARRCKS",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.1032006,
            24.6496787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CUK",
          "name": "CHURK",
          "zone": "NCR",
          "address": "State Highway 14, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.312762,
            24.86221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KIN",
          "name": "Kapasan",
          "zone": "NWR",
          "address": "Kapasan, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.9857744763,
            24.8020366077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KHRY",
          "name": "KHAIRAHI",
          "zone": "NCR",
          "address": "State Highway 5, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8493415012,
            18.888260310099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DAB",
          "name": "DHARMABAD",
          "zone": "SCR",
          "address": "Degaon, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.83265999999999,
            18.9937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CRD",
          "name": "MUMBAI CURREY ROAD",
          "zone": "CR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.495374,
            24.842143999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CTHT",
          "name": "CHATAR HALT",
          "zone": "ECR",
          "address": "Chatar, Bihar"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CRND",
          "name": "Choranda Junction",
          "zone": "WR",
          "address": "Choranda, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.998515,
            12.467679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KLAD",
          "name": "KALANAD HALT",
          "zone": "SR",
          "address": "Kasaragod, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.51800800000001,
            21.782545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DAS",
          "name": "DHASA JN",
          "zone": "WR",
          "address": "Dhasa, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.548244,
            23.156554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KHD",
          "name": "KHODIYAR",
          "zone": "WR",
          "address": "Sarkhej Gandhinagar Hwy, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.46851799999999,
            23.26246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CTRL",
          "name": "CHHATRAL",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.35722999999999,
            27.155534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "CTKT",
          "name": "Choti Khatu",
          "zone": "NWR",
          "address": "Degana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.85927000000001,
            22.47801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KKD",
          "name": "Kalkund",
          "zone": "WR",
          "address": "Mhow, Madhya Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DAVC",
          "name": "Jalalabad DAV College",
          "zone": "NR",
          "address": "Jalalabad, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.929889,
            23.044097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CRE",
          "name": "Charghat Piparia",
          "zone": "SECR",
          "address": "Guwarighat, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.28148300000001,
            22.214924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "D",
          "name": "Deula",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.275556,
            21.740875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KJV",
          "name": "Khijadiya Junction",
          "zone": "WR",
          "address": "Amreli, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.027594,
            21.326767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DARI",
          "name": "Dhari Junction",
          "zone": "WR",
          "address": "Dhari, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.280471,
            22.798157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DEA",
          "name": "Diara",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1401154919,
            22.042038383999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CRN",
          "name": "Charegaon",
          "zone": "SECR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.218128,
            21.745689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CTL",
          "name": "Chital",
          "zone": "WR",
          "address": "SH 34, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.250076,
            19.972516
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPUR",
          "name": "Dadpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.234894,
            23.760124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "KHRI",
          "name": "Kharkhari",
          "zone": "SER",
          "address": "National Highway 32, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.59603216999999,
            18.23255147
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KFH",
          "name": "Katphal",
          "zone": "CR",
          "address": "Baramati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.595999,
            11.572583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KJPD",
          "name": "Kurinjipadi",
          "zone": "SR",
          "address": "Kurinjippadi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.84238599999999,
            24.690834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KLH",
          "name": "Khemli",
          "zone": "NWR",
          "address": "Khemli, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.389612,
            28.8349236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KLNK",
          "name": "Kalanaur Kalan",
          "zone": "NWR",
          "address": "Kalanaur, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.738503,
            30.895838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DDK",
          "name": "Dhindsa",
          "zone": "NR",
          "address": "Dhindsa, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.138491,
            17.249347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KMT",
          "name": "KHAMMAM",
          "zone": "SCR",
          "address": "Khammam, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DEU",
          "name": "Devsana",
          "zone": "WR",
          "address": "State Highway 133, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DEWA",
          "name": "Dewa",
          "zone": "WR",
          "address": "Dewa, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.86085,
            24.802248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTHA",
          "name": "KHUTAHA",
          "zone": "WCR",
          "address": "MDR 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.55322600000001,
            21.078274
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "DGJ",
          "name": "DUNGAR JN",
          "zone": "WR",
          "address": "Dungar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.845912,
            24.611085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KMA",
          "name": "KAIMA",
          "zone": "WCR",
          "address": "Satna, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1462260938,
            19.9439708108
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KNRG",
          "name": "KANHARGAON NAKA",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.865703,
            12.616200000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KMLM",
          "name": "Kelamangalam",
          "zone": "SWR",
          "address": "Krishnagiri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.179001,
            23.991771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KTLR",
          "name": "KANTHALIYA ROAD",
          "zone": "ER",
          "address": "Khagraghat - Chowrigacha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.681135,
            18.589152000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KRPR",
          "name": "KAREPUR",
          "zone": "SCR",
          "address": "State Highway 168, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.353227,
            21.823596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KNW",
          "name": "KHANDWA",
          "zone": "CR",
          "address": "Khandwa, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.015194,
            31.7287
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KNG",
          "name": "Kathunangal",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.18991600000001,
            22.191902
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DH",
          "name": "Diamond Harbour",
          "zone": "ER",
          "address": "Diamond Harbour, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0559746887,
            29.6399235976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "DHY",
          "name": "DHARODI",
          "zone": "NR",
          "address": "MDR 111, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.589847,
            27.911754000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KNAR",
          "name": "KHANPUR AHIR",
          "zone": "NWR",
          "address": "MDR 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.237695,
            16.70257
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KOP",
          "name": "C SHAHU M RAJ KOLHAPUR TERM",
          "zone": "CR",
          "address": "Kolhapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.790369,
            24.727194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KOLI",
          "name": "KESHOLI",
          "zone": "WCR",
          "address": "Baren, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.49169,
            19.406622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DGPP",
          "name": "DHENGLI PP GOAN",
          "zone": "SCR",
          "address": "Satuna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.70858000000001,
            22.156964000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KTHD",
          "name": "Kothari Road",
          "zone": "SECR",
          "address": "Kothari, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.657344,
            22.217513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KMBL",
          "name": "KHAMBHALIYA",
          "zone": "WR",
          "address": "Khambhalia, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.25813099999999,
            18.757486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KRDL",
          "name": "Kirandul",
          "zone": "ECoR",
          "address": "Kirandul, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.896549,
            22.319955
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KNLS",
          "name": "KANALAS JN",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1428790756,
            10.816930512899999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KODN",
          "name": "KODUMUNDA",
          "zone": "SR",
          "address": "Thrithala, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.731866,
            29.392008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KSDE",
          "name": "KARSINDHU",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.718754,
            25.070798999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KSC",
          "name": "KASHI CHAK",
          "zone": "ECR",
          "address": "Nalanda, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.75131900000001,
            25.088974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DGNH",
          "name": "DERGAON HALT",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.24421199999999,
            28.416819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KRYA",
          "name": "KURRAIYA",
          "zone": "NER",
          "address": "Chauhan Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.45567100000001,
            24.218469000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KRJD",
          "name": "KARJODA",
          "zone": "NWR",
          "address": "Chhapi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.28593,
            29.182769999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KPKI",
          "name": "QASIMPUR KHERI",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.671506,
            18.912986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "KMSD",
          "name": "Kumar Sadra",
          "zone": "ECoR",
          "address": "National Highway 16, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2979015082,
            27.5158216097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KPE",
          "name": "Kauwapur",
          "zone": "NER",
          "address": "MDR 10E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.170262,
            23.120701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KSUA",
          "name": "Kashipura",
          "zone": "WR",
          "address": "State Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27186499999999,
            17.511408000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KRA",
          "name": "Karepalli",
          "zone": "SCR",
          "address": "Karepally, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.246237,
            23.409181
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KTRD",
          "name": "Katosan Road",
          "zone": "WR",
          "address": "State Highway 216, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.371504,
            22.51554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DHK",
          "name": "Dhakuria",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.97985,
            29.835057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "KNL",
          "name": "Kalanwali",
          "zone": "NWR",
          "address": "Kalanwali, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.801613,
            28.427051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "LHU",
          "name": "Loharu",
          "zone": "NWR",
          "address": "Bhiwani Distt., Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.103482,
            26.803925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DNHK",
          "name": "DHINDHORA HKMKD",
          "zone": "WCR",
          "address": "Dhindhora, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.627712,
            15.870282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "LKY",
          "name": "LAKHMAPUR",
          "zone": "SWR",
          "address": "Muttalageri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.268484,
            27.082641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LPW",
          "name": "Loharpurwa",
          "zone": "NER",
          "address": "Loharpurwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1797,
            32.018944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "LNS",
          "name": "LUNSU HALT",
          "zone": "NR",
          "address": "Lunsu, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.23414199999999,
            27.161243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LER",
          "name": "Lehra",
          "zone": "NER",
          "address": "Lehra Railway Station Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5333715979,
            26.310925310800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MKRA",
          "name": "MAKRERA",
          "zone": "NWR",
          "address": "Kharwa, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9717014606,
            17.2795737583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LHN",
          "name": "LACHYAN",
          "zone": "SWR",
          "address": "Solapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3950100488,
            18.0338728828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LAUL",
          "name": "LAUL",
          "zone": "CR",
          "address": "State Highway 161, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.309233,
            26.179892000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KVC",
          "name": "KAPARPURA",
          "zone": "ECR",
          "address": "National Highway 28, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.216548,
            28.579455000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "LDCY",
          "name": "LODI COLONY",
          "zone": "NR",
          "address": "Najafkhan Rd, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63571300000001,
            25.261369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MDPA",
          "name": "MANDPIYA",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.400543,
            23.833385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTE",
          "name": "KATNI",
          "zone": "WCR",
          "address": "Katni, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.066765,
            22.932344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KY",
          "name": "KARELI",
          "zone": "WCR",
          "address": "Kareli, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.703397,
            15.399929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "LMD",
          "name": "LINGANENIDODDI",
          "zone": "SCR",
          "address": "Rayalaseema Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.11088000000001,
            20.187338999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KXX",
          "name": "KATA ROAD",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8900217,
            24.7969839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KURJ",
          "name": "KHAJURAHO",
          "zone": "NCR",
          "address": "National Highway 75, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.531958,
            15.434667000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "LKT",
          "name": "HULKOTI",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.70733,
            23.445967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "LAD",
          "name": "Lohardaga",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.40095653559999,
            14.888042065499999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KVS",
          "name": "KALSUR",
          "zone": "SWR",
          "address": "haveri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.188491,
            23.909854000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KZPR",
          "name": "KAZIPARA",
          "zone": "ER",
          "address": "Khagraghat - Chowrigacha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.17660000000001,
            25.750035999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LMG",
          "name": "LUMDING JN",
          "zone": "NFR",
          "address": "Lumding. Ph:-03674-263366, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.728726,
            26.504385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LR",
          "name": "LADPURA",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.647484,
            31.729693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KZX",
          "name": "KHUDDA KORALA",
          "zone": "NR",
          "address": "Khuda, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.85366599999999,
            28.793007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MHJ",
          "name": "MAHAJAN",
          "zone": "NWR",
          "address": "Mahajan, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.743155,
            28.493395
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LKS",
          "name": "LUNKARANSAR",
          "zone": "NWR",
          "address": "Lukhransar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.287814,
            26.784587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJBT",
          "name": "MAJBAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.003175,
            26.001255999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LUNI",
          "name": "LUNI JN",
          "zone": "NWR",
          "address": "Luni, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.228735,
            26.310011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KTRR",
          "name": "Kathara Road",
          "zone": "NCR",
          "address": "Kanpur Nagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.40369700000001,
            27.473814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KWW",
          "name": "Kuswa",
          "zone": "NCR",
          "address": "MDR 106W, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.67813000000001,
            16.017651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KUDL",
          "name": "KUDAL",
          "zone": "KR",
          "address": "Kudal, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.788884,
            11.234606999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KUL",
          "name": "KALLAYI",
          "zone": "SR",
          "address": "Calicut, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.069608,
            16.328919999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KVM",
          "name": "Kavutaram",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DNZ",
          "name": "Dhanori",
          "zone": "CR",
          "address": "SH244, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.049522,
            12.604742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MAD",
          "name": "MADDUR",
          "zone": "SWR",
          "address": "Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.72317,
            31.424854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KUPR",
          "name": "Khurdpur",
          "zone": "NR",
          "address": "Adampur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.767506,
            26.271855000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDYR",
          "name": "Bidyadabri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.135457,
            27.117963000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MAH",
          "name": "Madhoganj",
          "zone": "NR",
          "address": "Hardoi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.704285,
            11.382403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "LOV",
          "name": "Lovedale",
          "zone": "SR",
          "address": "Ooty, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.463013,
            26.543198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "LKQ",
          "name": "Laukaha Bazar",
          "zone": "ECR",
          "address": "Madhubani, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.027912,
            11.831114000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KVLR",
          "name": "Karuvalli",
          "zone": "SWR",
          "address": "Salem district, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.948453,
            25.272755999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "LSG",
          "name": "Lawa Sardargarh",
          "zone": "NWR",
          "address": "State Highway 56, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.619139,
            26.802772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MJL",
          "name": "MAJHOWLIA",
          "zone": "ECR",
          "address": "Vikash Village, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7141335645,
            16.723002286099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JML",
          "name": "Jumnal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.728511,
            25.872726999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MNKB",
          "name": "MIYONKA BARA",
          "zone": "NWR",
          "address": "Samdhari, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.13537099999999,
            25.30497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MBG",
          "name": "MAIBANG",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.411496,
            15.256635000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MKR",
          "name": "MADDIKERA",
          "zone": "SCR",
          "address": "Guntakal, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.134997,
            9.281533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MC",
          "name": "MANDAPAM CAMP",
          "zone": "SR",
          "address": "Mandapam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.55604899999999,
            18.429166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LUR",
          "name": "LATUR",
          "zone": "CR",
          "address": "Latur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.160402,
            28.300646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MJPB",
          "name": "Manjhra Purab",
          "zone": "NER",
          "address": "Manjhra Purab, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.845289,
            18.506494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LTRR",
          "name": "LATUR ROAD",
          "zone": "SCR",
          "address": "SH 3, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1300715057,
            27.060569440800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MKHR",
          "name": "MARWAR KHARA",
          "zone": "NWR",
          "address": "Jodhpur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.999792,
            17.597561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MABD",
          "name": "MAHBUBABAD",
          "zone": "SCR",
          "address": "Mahbubabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.91805099999999,
            22.815074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MGG",
          "name": "MANGLIYA GAON",
          "zone": "WR",
          "address": "Mangilya, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.74938300000001,
            16.223191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MGL",
          "name": "MANGELA",
          "zone": "?",
          "address": "Mugalolli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.07634300000001,
            24.075937000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MDS",
          "name": "MANDASOR",
          "zone": "WR",
          "address": "Mandsaur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.68323000000001,
            26.030315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MAKH",
          "name": "MOKHAMPURA",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.598166,
            25.597006
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MAY",
          "name": "MARIAHU",
          "zone": "NR",
          "address": "Mariahu, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.809711,
            29.399824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MLDE",
          "name": "MADLAUDA",
          "zone": "NR",
          "address": "Panipat, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.474557,
            17.639335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MED",
          "name": "MEDCHAL",
          "zone": "SCR",
          "address": "Rangareddy, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4669570667,
            17.8805365542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "ME",
          "name": "MASAIPET",
          "zone": "SCR",
          "address": "Medak, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.575493,
            16.886605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MDVR",
          "name": "MADHAVNAGAR",
          "zone": "CR",
          "address": "Sangli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.86161,
            24.368371999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KDRD",
          "name": "KHULDIL ROAD",
          "zone": "ECR",
          "address": "Obra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.492705,
            26.278197000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MLI",
          "name": "MANGALIYAWAS",
          "zone": "NWR",
          "address": "Mangaliyawas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.274559021,
            26.694551388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MDT",
          "name": "MADARIHAT",
          "zone": "NFR",
          "address": "National Highway 31C, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7432486377,
            26.2652295458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "DWDI",
          "name": "Dighwa Dubauli",
          "zone": "NER",
          "address": "Saran, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.756011,
            21.09412
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MHV",
          "name": "MAHUVA JN",
          "zone": "WR",
          "address": "Mahuva, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.957379,
            27.391561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MHBZ",
          "name": "Mahtha Bazar",
          "zone": "NER",
          "address": "Mahtha Bazar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.342407,
            26.838267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MIM",
          "name": "Maniram",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.198447,
            28.077937000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "MBV",
          "name": "Mirzapur Bachhaud",
          "zone": "NWR",
          "address": "State Highway 26, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.596581,
            18.36212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MDDG",
          "name": "Malad Gaon",
          "zone": "CR",
          "address": "National Highway 9 Daund, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.178625,
            25.994578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MNJR",
          "name": "Manjhlepur",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.378905,
            12.730158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MGF",
          "name": "Mandagere",
          "zone": "SWR",
          "address": "Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.54626499999999,
            21.726323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "GADH",
          "name": "Goaldih",
          "zone": "?",
          "address": "Padmapur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.979105,
            25.077130999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MDE",
          "name": "Makhdumpur Gaya",
          "zone": "ECR",
          "address": "Jehanabad, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.27078300000001,
            23.747481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MHQ",
          "name": "Mahuda",
          "zone": "SER",
          "address": "National Highway 32, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.38903300000001,
            24.232416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "MMD",
          "name": "Maheshmunda",
          "zone": "ER",
          "address": "Maheshmunda, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.9193076,
            27.1829782
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MRHT",
          "name": "MORANHAT",
          "zone": "NFR",
          "address": "Sibsagar, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.320896,
            16.956602
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MTMI",
          "name": "MOTIMARI",
          "zone": "SCR",
          "address": "Motimarri Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.087335,
            8.811577000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MVN",
          "name": "MILAVITTAN",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0285688654,
            28.6884911989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "MQC",
          "name": "MUNDKA",
          "zone": "NR",
          "address": "Mundka, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.09815599999999,
            19.233398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MRV",
          "name": "MARSUL",
          "zone": "SCR",
          "address": "Purna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.595866,
            11.079116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MY",
          "name": "MALLIYAM",
          "zone": "SR",
          "address": "State Highway 64, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.845031,
            29.992445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MOPR",
          "name": "MOHANPURA",
          "zone": "NR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.14420899999999,
            17.404241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MSR",
          "name": "MASUR",
          "zone": "CR",
          "address": "Satara, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.023359,
            11.021579000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MPLM",
          "name": "MARTHIPALAYAM",
          "zone": "SR",
          "address": "Erode - Unjalur - Kodumudi - Karur Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.658017,
            23.085662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NRD",
          "name": "NARODA",
          "zone": "WR",
          "address": "Ahmedabad, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.59392199999999,
            26.854361
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MUUA",
          "name": "MAHUAWA KHURD",
          "zone": "NER",
          "address": "State Highway 64, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.388708,
            23.602623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "MSH",
          "name": "MAHESANA JN",
          "zone": "WR",
          "address": "Mehsana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.079908,
            19.155061999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NIIJ",
          "name": "NILAJE",
          "zone": "CR",
          "address": "Nilaje, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.55036199999999,
            11.68423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "MUKE",
          "name": "MUKKALI",
          "zone": "SR",
          "address": "Chombala, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.695903,
            18.839732
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "MVF",
          "name": "MANABAR",
          "zone": "ECoR",
          "address": "Manabar Station, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.646013,
            12.318946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MYS",
          "name": "MYSORE JN",
          "zone": "SWR",
          "address": "JLB Road Mysore, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.771485,
            17.54542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MRF",
          "name": "Marpalli",
          "zone": "SCR",
          "address": "Veerlapally, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.735905,
            13.804192
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MSS",
          "name": "Masarahalli",
          "zone": "SWR",
          "address": "Shimoga, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.76282300000001,
            22.651892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPE",
          "name": "Malatipur",
          "zone": "ER",
          "address": "Malotipur Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.56399,
            27.980942000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NPS",
          "name": "Napasar",
          "zone": "NWR",
          "address": "Napasar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.01732899999999,
            12.388944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MZU",
          "name": "Marandahalli",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.965393,
            31.184014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MXP",
          "name": "Malupota",
          "zone": "NR",
          "address": "Phagwara - Mohali Expy, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.818201,
            27.908304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MPG",
          "name": "Manpur Nagaria",
          "zone": "NER",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.946128,
            20.441551999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "MTND",
          "name": "Mattagajpur",
          "zone": "ECoR",
          "address": "Mattagajpur, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.277416,
            23.711965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PAW",
          "name": "PANDABESWAR",
          "zone": "ER",
          "address": "Bardhaman, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.763148,
            24.255485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MYR",
          "name": "MAIHAR",
          "zone": "WCR",
          "address": "Maihar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.40995965580001,
            27.3287489158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NBUE",
          "name": "NIBKARORI",
          "zone": "NCR",
          "address": "Nibkarori, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.774119,
            19.257460000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PBN",
          "name": "PARBHANI JN",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.024985,
            19.180526999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PAU",
          "name": "PURNA JN",
          "zone": "SCR",
          "address": "Purna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.899779,
            26.729223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "OSN",
          "name": "OSIYAN",
          "zone": "NWR",
          "address": "Osiyan, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5868746,
            26.0707762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NGTG",
          "name": "New Gitaldaha",
          "zone": "NFR",
          "address": "New Gitaldaha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.213482,
            9.279729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PBM",
          "name": "PAMBAN JN",
          "zone": "SR",
          "address": "Pamban, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6304202759,
            24.835766629899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ORDI",
          "name": "ORDI",
          "zone": "WR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.484435,
            27.355137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NM",
          "name": "Naimisharanya",
          "zone": "NR",
          "address": "MDR 74C, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.129081,
            10.718615000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PALM",
          "name": "PALAIYAM",
          "zone": "SR",
          "address": "Karur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.339879,
            23.635161999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PHC",
          "name": "PANCHOT",
          "zone": "WR",
          "address": "State Highhway 55, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1232722153,
            19.7986346861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NVLN",
          "name": "NAWALGOHAN",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.13575,
            12.255885000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "NLE",
          "name": "NILESHWAR",
          "zone": "SR",
          "address": "Nileswaram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.54864500000001,
            24.886758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "NWD",
          "name": "NAWADIH",
          "zone": "ECR",
          "address": "Nawada, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.35421099999999,
            31.378961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "NNGL",
          "name": "Naya Nangal",
          "zone": "NR",
          "address": "Near CAN plant nangal, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.066065,
            26.836932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMM",
          "name": "JAIMURTINAGAR F",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.575581,
            11.639509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "NAU",
          "name": "NADAPURAM ROAD",
          "zone": "SR",
          "address": "Madappallly, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.92503500000001,
            26.212312999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NHF",
          "name": "Nihasta Halt",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.681387,
            12.463018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PANP",
          "name": "PANDAVAPURA",
          "zone": "SWR",
          "address": "SH 19 Junction Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.055491,
            15.415517999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NVU",
          "name": "NAVALUR",
          "zone": "SWR",
          "address": "Dharwad - Hubli Hwy, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.08111000000001,
            8.41041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "NYY",
          "name": "NEYYATTINKARA",
          "zone": "SR",
          "address": "Thiruvananthapuram, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.496786,
            28.364489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "NLQ",
          "name": "Nangal Pathani Halt",
          "zone": "NWR",
          "address": "Nangal Pathani, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.29242739200001,
            12.7463856826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NRJ",
          "name": "Narimogaru",
          "zone": "SWR",
          "address": "Puttur subrahmanya state highway 100, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "GNV",
          "name": "Gandevi",
          "zone": "WR",
          "address": "Gandevi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0156235815,
            23.3756204426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "NRKP",
          "name": "Narkopi",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.95091,
            22.658083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GNS",
          "name": "Ghonsor",
          "zone": "SECR",
          "address": "Ghansor, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.512594,
            27.859887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NNP",
          "name": "Nanpara Junction",
          "zone": "NER",
          "address": "Nanpara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.865713,
            26.601184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NMGY",
          "name": "Numaligarh",
          "zone": "NFR",
          "address": "NMGY, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.60073,
            28.010872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NPR",
          "name": "Nepalganj Road",
          "zone": "NER",
          "address": "Midwest, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.150998,
            22.11119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NKR",
          "name": "Nimar Kheri",
          "zone": "WR",
          "address": "State Highway 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.42129700000001,
            27.42688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NTV",
          "name": "Nautanwa",
          "zone": "NER",
          "address": "Nautanwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.78913999999999,
            10.506577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NTM",
          "name": "Narthamalai",
          "zone": "SR",
          "address": "Trchy - Pudukottai - Ramanathapuram Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.90761,
            29.085837
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "PAQ",
          "name": "PADIYA NAQLA",
          "zone": "NER",
          "address": "National Highway 74, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.304318,
            27.302522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PKNA",
          "name": "PAKHNA",
          "zone": "NCR",
          "address": "Pakhna, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.73604499999999,
            11.476847000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PO",
          "name": "PARANGIPETTAI",
          "zone": "SR",
          "address": "Parangipettai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.500574,
            25.879046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PPH",
          "name": "PIPRI DIH",
          "zone": "NER",
          "address": "State Highway 67, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.038949,
            26.137823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PPU",
          "name": "PIPARPUR",
          "zone": "NR",
          "address": "State Highway 9, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.095153,
            16.470923000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PSLP",
          "name": "PASALAPUDI",
          "zone": "SCR",
          "address": "Putlacheruvu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.051142,
            16.439968999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "OTR",
          "name": "MOTURU",
          "zone": "SCR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.86438199999999,
            26.606366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PDZ",
          "name": "PILIODA",
          "zone": "WCR",
          "address": "SH 1, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.057408,
            30.137676000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PJK",
          "name": "PANJ KOSI",
          "zone": "NR",
          "address": "Diwan Khera, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.35550400000001,
            30.164759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PKK",
          "name": "PAKKI",
          "zone": "NR",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.013194,
            18.770457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NVT",
          "name": "NAVIPET",
          "zone": "SCR",
          "address": "Nizamabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1230451306,
            19.9603213601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PGG",
          "name": "PENGANGA",
          "zone": "SCR",
          "address": "Washim, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.695712,
            18.659914999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PBV",
          "name": "Paliba",
          "zone": "ECoR",
          "address": "Paliba Station, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.14738700000001,
            19.629143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PDG",
          "name": "PARADGAON",
          "zone": "SCR",
          "address": "Usmanpur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37130800000001,
            29.307976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PPDE",
          "name": "PANDU PINDARA",
          "zone": "NR",
          "address": "Jind, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.040396,
            13.598983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PCL",
          "name": "Pulicherla",
          "zone": "SCR",
          "address": "Pulicherla Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.336246,
            25.477677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BNDA",
          "name": "BANDA JN",
          "zone": "NCR",
          "address": "Banda(05192-224813), Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82748600000001,
            30.759177
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "QRP",
          "name": "KILA RAIPUR",
          "zone": "NR",
          "address": "Delhon Pakhowal Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.36686300000001,
            27.124914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PLCJ",
          "name": "PHALODI JN",
          "zone": "NWR",
          "address": "Phalodi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.776186,
            27.407067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PDR",
          "name": "Payagpur",
          "zone": "NER",
          "address": "State Highway 30, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.2261646533,
            24.2602726462
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PRDG",
          "name": "PORADANGA HALT",
          "zone": "ER",
          "address": "Lalgola, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.85708699999999,
            11.236640999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PAS",
          "name": "PASUR",
          "zone": "SR",
          "address": "Namakkal, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.323896,
            23.453302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PSAE",
          "name": "PURBASTHALI",
          "zone": "ER",
          "address": "Purbasthali, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.55599600000001,
            26.158866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PNVT",
          "name": "PANCHARATNA",
          "zone": "NFR",
          "address": "Makri Rail Station, Assam"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "HFG",
          "name": "Haflong Hill",
          "zone": "NFR",
          "address": "North Cachar Hills, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.657808,
            27.528038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PPW",
          "name": "Pachperwa",
          "zone": "NER",
          "address": "Semari - Bisokhar Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.1029777271,
            25.7789313494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PKB",
          "name": "PATHARKHOLA S",
          "zone": "NFR",
          "address": "NH54, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.2499423993,
            27.4967510918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "PNT",
          "name": "PANITOLA",
          "zone": "NFR",
          "address": "A T Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.612621,
            9.921169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PNGI",
          "name": "Panangudi",
          "zone": "SR",
          "address": "Madurai - Thondi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.672174,
            16.093145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "PCH",
          "name": "PACHHAPUR",
          "zone": "SWR",
          "address": "Rustumpur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.111907,
            17.812195000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PLV",
          "name": "PALSI",
          "zone": "CR",
          "address": "Wathar-Koregaon Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.08117200000001,
            28.54694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PRSP",
          "name": "PRASADPUR",
          "zone": "NER",
          "address": "Prasadpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.294438,
            26.930569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PJ",
          "name": "Peppeganj",
          "zone": "NER",
          "address": "Sahibganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.47975,
            23.307875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "PN",
          "name": "PANSAR",
          "zone": "WR",
          "address": "Palanpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.828127,
            11.925445999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Puducherry",
          "code": "PDY",
          "name": "Puducherry",
          "zone": "SR",
          "address": "South Blvd, Puducherry"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.20610187930001,
            23.3295427441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "PIS",
          "name": "Piska",
          "zone": "SER",
          "address": "Ranchi, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.687882,
            18.396744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PFU",
          "name": "Padua",
          "zone": "ECoR",
          "address": "Paduwa Station, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.022402,
            16.386599
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "NUJ",
          "name": "Nujella",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.39900800000001,
            22.308540999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HT",
          "name": "Hotar",
          "zone": "ER",
          "address": "Sherpur Dhamua Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.806909,
            22.63341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "PRSL",
          "name": "Pandrasali",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.081469892,
            18.7407526287
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PHM",
          "name": "Patapatnam",
          "zone": "ECoR",
          "address": "Srikakulam District, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.09295,
            28.585656
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "PM",
          "name": "PALAM",
          "zone": "NR",
          "address": "Sadh Nagar I, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.25171385990001,
            23.5475854116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PTAE",
          "name": "PATULI",
          "zone": "ER",
          "address": "State Highway 6, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5954738599,
            15.4367140478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKIT",
          "name": "BINKADAKATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.319283,
            17.66849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PVR",
          "name": "PANDHARPUR",
          "zone": "CR",
          "address": "Pandharpur Ph:02186-223227, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.671814,
            26.312897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ROS",
          "name": "RAJOSI",
          "zone": "NWR",
          "address": "MDR 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.803446,
            22.312206
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RJT",
          "name": "RAJKOT JN",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.868429,
            22.71757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "INDB",
          "name": "INDORE JN BG",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1254542236,
            13.9501446899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RGI",
          "name": "RAMGIRI",
          "zone": "SWR",
          "address": "Shivani Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.80682566349999,
            16.483374512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RBG",
          "name": "RAYBAG",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.523389,
            29.240494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SBNR",
          "name": "SHRI VIJAINAGAR",
          "zone": "NWR",
          "address": "Vijaynagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.014931,
            26.099221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PNHR",
          "name": "PANIHAR",
          "zone": "WCR",
          "address": "Gwalior, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.625041,
            26.300344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRBR",
          "name": "KERKHABARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.728178,
            28.510828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PVZ",
          "name": "Parvezpur",
          "zone": "NWR",
          "address": "damkora, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.150951,
            28.514911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PP",
          "name": "PURANPUR",
          "zone": "NER",
          "address": "Puranpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.52353600000001,
            25.75038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RKZ",
          "name": "RAJKIAWAS",
          "zone": "NWR",
          "address": "Marwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.283451,
            12.720096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RMGM",
          "name": "RAMANAGARAM",
          "zone": "SWR",
          "address": "Ramanagara, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.81438200000001,
            22.176807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RKY",
          "name": "KOTHARIYA",
          "zone": "WR",
          "address": "State Highway 122, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.80095200000001,
            22.124961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RBR",
          "name": "RIBADA",
          "zone": "WR",
          "address": "State Highway 233, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.19716,
            30.434234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CKA",
          "name": "Chak Pakhhewala",
          "zone": "NR",
          "address": "Chimne Wala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.356938,
            17.002793
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "RN",
          "name": "RATNAGIRI",
          "zone": "KR",
          "address": "Ratnagiri 02352228951, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.45759,
            16.29953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIX",
          "name": "Manipuram Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.259255,
            23.850089999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "REJ",
          "name": "Rejinagar",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.405224,
            20.298605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "RHMA",
          "name": "Rahama",
          "zone": "ECoR",
          "address": "Nathang, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.357146,
            26.2365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RMPH",
          "name": "Rampahari",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.107034,
            26.083856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RDV",
          "name": "Radha Balampur",
          "zone": "NR",
          "address": "State Highway 13A, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.33368800000001,
            28.557035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "PUW",
          "name": "Patuwas Mehrana",
          "zone": "NWR",
          "address": "Mehrana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.129683,
            25.686945
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RGU",
          "name": "Ragaul",
          "zone": "NCR",
          "address": "Maudaha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.476502,
            26.627948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PTYR",
          "name": "Patiyara",
          "zone": "NR",
          "address": "Patiyara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.77586000000001,
            16.131433
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PUMU",
          "name": "Penumarru",
          "zone": "SCR",
          "address": "Koteparu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.918286,
            26.656417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RNGG",
          "name": "Rangaliting",
          "zone": "NFR",
          "address": "Rangaliting, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.562937,
            16.577504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RYP",
          "name": "RAYANAPAD",
          "zone": "SCR",
          "address": "Rayanapad Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.83686200000001,
            9.358387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "RMD",
          "name": "RAMANATHAPURAM",
          "zone": "SR",
          "address": "Ramanathapuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.35476700000001,
            26.744343999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SFPR",
          "name": "Safipur",
          "zone": "NR",
          "address": "Unnao, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.52398098632693,
            28.158119435028283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RSH",
          "name": "Ratan Shahr",
          "zone": "NWR",
          "address": "Jhunjhunu, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.154333,
            22.054052000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "JLY",
          "name": "Jhilmili",
          "zone": "SECR",
          "address": "Jhilmili, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.894135,
            22.546528000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SDD",
          "name": "SINDHAWADAR",
          "zone": "WR",
          "address": "Sindhavadar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.865598,
            16.31992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SII",
          "name": "SITIMANI",
          "zone": "SWR",
          "address": "Dam Inspection Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.00795299999999,
            19.402665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SHSK",
          "name": "SAHARSRAKUND",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.808761,
            30.130335000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SFM",
          "name": "SUNAM",
          "zone": "NR",
          "address": "Sunam, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.515941,
            27.030421
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SBLT",
          "name": "SHRI BHADRIYA LATHI",
          "zone": "NWR",
          "address": "Lathi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.7563490747,
            27.1495669522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SBZ",
          "name": "SISWA BAZAR",
          "zone": "NER",
          "address": "Siswa Bazar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.73934,
            26.758187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SGL",
          "name": "SAGAULI JN",
          "zone": "ECR",
          "address": "Sugauli, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.68275899999999,
            26.061014999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SGW",
          "name": "SINGWAL",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5662047516,
            26.3404260124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDH",
          "name": "SARADHNA",
          "zone": "NWR",
          "address": "Mangaliyawas, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.641724,
            15.912563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SBH",
          "name": "SULEBHAVI",
          "zone": "SWR",
          "address": "Belagavi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.308384,
            25.673309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SDT",
          "name": "SADAT",
          "zone": "NER",
          "address": "Mahpur Sadat Marg, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4619205481,
            17.9099557799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SHAN",
          "name": "SRINIVASA NAGAR",
          "zone": "SCR",
          "address": "Ramanthapur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.271132,
            18.487478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SCP",
          "name": "SIRNAPALLI",
          "zone": "SCR",
          "address": "Sirnapalli, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.130772,
            25.497689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RJI",
          "name": "RAJAWARI",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.194143,
            26.077973999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SEU",
          "name": "SENDRA",
          "zone": "NWR",
          "address": "Sendra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.778745,
            12.945368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SKLR",
          "name": "Sakleshpur",
          "zone": "SWR",
          "address": "Sakleshpur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0582362866,
            13.8111396742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SHV",
          "name": "SHIVANI",
          "zone": "SWR",
          "address": "Shivani Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.726078,
            21.698214
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RWO",
          "name": "RANAVAV",
          "zone": "WR",
          "address": "Ranavav, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.59396600000001,
            13.930712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SHMI",
          "name": "SHIROOR",
          "zone": "KR",
          "address": "Uttara Kannada, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.678033,
            12.4247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "S",
          "name": "SHRIRANGAPATNA",
          "zone": "SWR",
          "address": "Mandya District, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.84456399999999,
            26.987018000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "RXL",
          "name": "RAXAUL JN",
          "zone": "ECR",
          "address": "Raxaul, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.17270445264876,
            29.902641430417788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SDS",
          "name": "Sadulshahr",
          "zone": "NWR",
          "address": "Sadul Shahar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.59829400000001,
            27.457628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SMPR",
          "name": "Shri Madhopur",
          "zone": "NWR",
          "address": "Sri Madhopur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.76642,
            31.45851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SCQ",
          "name": "Sham Chaurasi",
          "zone": "NR",
          "address": "Distt. Hoshiarpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.067464,
            28.367897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDRT",
          "name": "Nangal Degrota",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.447091,
            23.692944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "SDBH",
          "name": "Sindri Block Hut",
          "zone": "ECR",
          "address": "Pathardih, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.0290537952,
            30.3576339273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JWKA",
          "name": "Jandwala Kharta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.360945,
            32.098872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SMLT",
          "name": "SAMLOTI",
          "zone": "NR",
          "address": "National Highway 20, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.821887,
            24.999741
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SKGH",
          "name": "SAKTESGARH",
          "zone": "NCR",
          "address": "Saktesgarh Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7364,
            19.188596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SNGR",
          "name": "SANGANAPUR",
          "zone": "SCR",
          "address": "State Highway 148, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.78985700000001,
            23.303628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SPDR",
          "name": "SHEOPRASADNAGER",
          "zone": "SECR",
          "address": "National Highway 78, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.923271,
            26.298048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRU",
          "name": "SALEMPUR JN",
          "zone": "NER",
          "address": "Salempur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4525283289,
            29.7582805083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SNAP",
          "name": "SONA ARJUNPUR",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.704868,
            9.400229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SQD",
          "name": "SATTIRAKKUDI",
          "zone": "SR",
          "address": "Ramanathapuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.235523,
            15.167166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SNKG",
          "name": "SANKARAGUMMANUR HALT",
          "zone": "SCR",
          "address": "National Highway 63, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55522599999999,
            26.527305000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SONI",
          "name": "SONI",
          "zone": "NCR",
          "address": "SH 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.324967,
            23.335767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SMAE",
          "name": "SAMUDRAGARH",
          "zone": "ER",
          "address": "Samudragarh, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.53311000000001,
            17.461671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SFX",
          "name": "SAFILGUDA",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5883907849,
            19.080130931400003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SLGH",
          "name": "SHELGOAN H",
          "zone": "SCR",
          "address": "Mudkhed, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.295781,
            15.206786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SNH",
          "name": "SAUNSHI",
          "zone": "SWR",
          "address": "Saunshi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.124918,
            24.023428
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SSGJ",
          "name": "SURSARAIGHAT JH",
          "zone": "WCR",
          "address": "Shidi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.143792,
            16.252107000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PAV",
          "name": "Pedana",
          "zone": "SCR",
          "address": "Pedana, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.675521,
            12.140253000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SJPM",
          "name": "SUJATA PURAM HALT",
          "zone": "SWR",
          "address": "Nanjangudu, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.063727,
            17.688361
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "STR",
          "name": "SATARA",
          "zone": "CR",
          "address": "SH 58, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.355604,
            12.743443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SRVN",
          "name": "Sravanur",
          "zone": "SWR",
          "address": "Sravanur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73236299999999,
            26.336915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SKU",
          "name": "Sikroda",
          "zone": "NCR",
          "address": "Morena(0753-2226392), Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.321899,
            22.640971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SLB",
          "name": "SALBONI",
          "zone": "SER",
          "address": "Salbani, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.83509699999999,
            23.403864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SRJM",
          "name": "SIRJAM",
          "zone": "SER",
          "address": "Purulia District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1299115857,
            28.680227328700003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "SSB",
          "name": "SHAKURBASTI",
          "zone": "NR",
          "address": "Pitampura, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.159932,
            31.102794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SML",
          "name": "Shimla",
          "zone": "NR",
          "address": "Shimla, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.192092,
            23.835655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KARO",
          "name": "Khario P.H.",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.363493,
            26.790887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SN",
          "name": "Sukna",
          "zone": "NFR",
          "address": "Hill Cart Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.632594,
            15.766715999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SMKT",
          "name": "Somankatti",
          "zone": "SWR",
          "address": "State Highway 30, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21523499999999,
            26.367008000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SIRA",
          "name": "Sirhi Itara",
          "zone": "NCR",
          "address": "Sirhiltara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.96929300000001,
            27.374710999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SOT",
          "name": "Shohratgarh",
          "zone": "NER",
          "address": "Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.635169,
            28.633209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRMP",
          "name": "Sirsi Mukhdumpr",
          "zone": "NR",
          "address": "Sirsi Mukhdumpr, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.406758,
            28.661958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SURP",
          "name": "Suratpura",
          "zone": "NWR",
          "address": "National Highway 65, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.010677,
            21.344445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PTS",
          "name": "Patansaongi",
          "zone": "SECR",
          "address": "Nagpur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.731233,
            20.990441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SIY",
          "name": "Sirry",
          "zone": "SECR",
          "address": "National Highway 43, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2045015,
            12.7683965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KBPR",
          "name": "Kabakaputtur",
          "zone": "SWR",
          "address": "Puttur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.004229,
            30.894075
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SWO",
          "name": "Sonwara",
          "zone": "NR",
          "address": "National Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.643517,
            26.989967999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SRTN",
          "name": "SIBSAGAR TOWN",
          "zone": "NFR",
          "address": "Sibsagar, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.98554700000001,
            20.574085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "JMD",
          "name": "Jamdha",
          "zone": "CR",
          "address": "Jamda, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.20653300000001,
            22.578960000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "THAN",
          "name": "THAN JN",
          "zone": "WR",
          "address": "Thangadh, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.022486,
            8.828581
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TIP",
          "name": "TATTAPPARAI",
          "zone": "SR",
          "address": "State Highway 77, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.138296,
            10.778085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TJ",
          "name": "THANJAVUR",
          "zone": "SR",
          "address": "Thanjavur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3773798279,
            18.1941221367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "THPR",
          "name": "TIPPAPUR",
          "zone": "SCR",
          "address": "National Highway 7, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.630056,
            22.7443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SUNR",
          "name": "SURENDRANAGAR",
          "zone": "WR",
          "address": "Surendra Nagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3963542407,
            19.6417758258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TLMG",
          "name": "TALMADUGU",
          "zone": "?",
          "address": "Sitagondi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.93419399999999,
            26.652675
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TL",
          "name": "TILONIYA",
          "zone": "NWR",
          "address": "Ajmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.22052500000001,
            28.097372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SUNM",
          "name": "SUNAMAI HALT",
          "zone": "NR",
          "address": "Sunamai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.077426,
            26.992725999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "THM",
          "name": "THALYAT HAMIRA",
          "zone": "NWR",
          "address": "Hamira, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.404471,
            15.449131999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TGT",
          "name": "TINAI GHAT",
          "zone": "SWR",
          "address": "National Highway 4A, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.344328,
            22.807897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SVL",
          "name": "Sevaliya",
          "zone": "WR",
          "address": "Sevaliya, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5062158319,
            8.2282578413
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "THX",
          "name": "TOVALAI",
          "zone": "SR",
          "address": "Thovalai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.91469331479999,
            25.539218856799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "THW",
          "name": "THARWAI",
          "zone": "NR",
          "address": "Tharwai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4063188531,
            14.9617089325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SVNR",
          "name": "SAVANUR",
          "zone": "SWR",
          "address": "State Highway 6, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4788766602,
            12.423381703999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "STE",
          "name": "Sagarkatte",
          "zone": "SWR",
          "address": "Sagarkatte, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.402047,
            29.581129999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TBTN",
          "name": "THANA BHAWAN TN",
          "zone": "NR",
          "address": "Thana Bhawan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.37531,
            10.952368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TIC",
          "name": "TIMMACHIPURAM",
          "zone": "SR",
          "address": "Karur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.637595,
            31.672179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "TDO",
          "name": "TANDA URMAR",
          "zone": "NR",
          "address": "Urmar Tanda, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.88703000000001,
            27.928906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SZN",
          "name": "Shahbaznagar",
          "zone": "NER",
          "address": "Shahbaznagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.118844,
            12.039871999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SZV",
          "name": "Sivadi",
          "zone": "SWR",
          "address": "Dharmapuri, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.385859,
            22.781628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TBA",
          "name": "Timba Road",
          "zone": "WR",
          "address": "National Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.01764299999999,
            27.26251
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SUBR",
          "name": "Subhagpur",
          "zone": "NER",
          "address": "MDR 10E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.732324,
            22.212297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SRBA",
          "name": "Saragbundia",
          "zone": "SECR",
          "address": "Janjgir-Champa, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.672904,
            18.982188
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SZY",
          "name": "Silak Jhori",
          "zone": "ECoR",
          "address": "National Highway 16, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.92059400000001,
            24.303242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TKP",
          "name": "Takipur",
          "zone": "ER",
          "address": "National Highway 60, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.27076000000001,
            23.818590999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SRJN",
          "name": "Sirajnagar Halt",
          "zone": "ER",
          "address": "Sirajnagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.58227500000001,
            22.682186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SXC",
          "name": "Sondalia",
          "zone": "ER",
          "address": "Sondalia Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.67053,
            21.235642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "TBD",
          "name": "Telibandha",
          "zone": "SECR",
          "address": "Telibandha, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.92129,
            27.183053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TNUE",
          "name": "TINDAULI",
          "zone": "NCR",
          "address": "Tindauli, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.428212,
            10.975208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TRM",
          "name": "TIRUNAGESVARAM",
          "zone": "SR",
          "address": "Ayyavadi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.078131,
            25.893262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "TWL",
          "name": "TILWARA",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.89084100000001,
            26.16121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TTI",
          "name": "TURTIPAR",
          "zone": "NER",
          "address": "Mujouna Gawn, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.316108,
            18.424892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "UPW",
          "name": "UPPALAVAI",
          "zone": "SCR",
          "address": "Uppalwai Main Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.189171,
            25.568212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "URPR",
          "name": "UGRASENPUR",
          "zone": "NR",
          "address": "Janghai, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.018709,
            24.690424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "VBN",
          "name": "VALLABHNAGAR",
          "zone": "NWR",
          "address": "MDR 36A, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.463163,
            22.800702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TUWA",
          "name": "Tuwa",
          "zone": "WR",
          "address": "National Highway 59, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.381669,
            23.796456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "UJA",
          "name": "UNJHA",
          "zone": "WR",
          "address": "Unjha, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.56909499999999,
            16.578434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VND",
          "name": "VENDRA",
          "zone": "SCR",
          "address": "Mogollu Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.131692,
            15.158976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VP",
          "name": "VIRAPUR",
          "zone": "SCR",
          "address": "Anantapur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6628751227,
            15.414853643999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "TVG",
          "name": "TAVARGATTI",
          "zone": "SWR",
          "address": "State Highway 34, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.688891,
            10.825594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TP",
          "name": "TIRUCHIRAPPALLI FORT",
          "zone": "SR",
          "address": "Tiruchirapalli Fort Station Road, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.471435,
            26.788432
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UNLA",
          "name": "UNAULA",
          "zone": "NER",
          "address": "Gorakhpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.1526926862,
            27.1425658407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KPB",
          "name": "KHARPOKHRA",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.370689,
            20.913351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VRL",
          "name": "VERAVAL",
          "zone": "WR",
          "address": "Veraval, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.3300770068,
            18.113401793300003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VEER",
          "name": "VEER",
          "zone": "KR",
          "address": "Lonere - Mangaon 02145270055, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.707645,
            16.512013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VBW",
          "name": "VAIBHAVWADI RD",
          "zone": "KR",
          "address": "Sindhudurg, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.98502287230001,
            25.1130684669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "THA",
          "name": "Tehta",
          "zone": "ECR",
          "address": "Tehta, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.912519,
            15.353587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "VEN",
          "name": "VERNA",
          "zone": "KR",
          "address": "South Goa 0832-2754289, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.701077,
            27.508190000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TPB",
          "name": "Trilokpur",
          "zone": "NER",
          "address": "Semari - Bisokhar Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.51619,
            28.524363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DBNR",
          "name": "Dibnapur",
          "zone": "NER",
          "address": "Dibnapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.975352,
            21.107333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "TMKA",
          "name": "Tomka",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.01491,
            27.995236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UJH",
          "name": "Ujhani",
          "zone": "NER",
          "address": "Ujhani, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.163629,
            26.118214000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "UBN",
          "name": "Ubarni",
          "zone": "NR",
          "address": "National Highway 24B, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.741784,
            21.383833000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TMS",
          "name": "Tumsar Town",
          "zone": "SECR",
          "address": "State Highway 249, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.04218999999999,
            18.217966999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TXD",
          "name": "Tyada",
          "zone": "ECoR",
          "address": "Vishakhapatnam, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.419161,
            11.535044000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "UMG",
          "name": "Uttangal Mangalam",
          "zone": "SR",
          "address": "State Highway 10, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27598499999999,
            13.07995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MPKT",
          "name": "CHENNAI PARK TOWN",
          "zone": "SR",
          "address": "Chennai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.604929,
            17.972477
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "WL",
          "name": "WARANGAL",
          "zone": "SCR",
          "address": "Warangal, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.136793,
            25.261270999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MRPR",
          "name": "MAU RANIPUR",
          "zone": "NCR",
          "address": "NH 75, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            68.9678414502,
            22.2995032641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VVA",
          "name": "VARVALA",
          "zone": "WR",
          "address": "Jamnagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.501817,
            18.111324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MGO",
          "name": "MAHISGAON",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.731959,
            24.358621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "ZW",
          "name": "ZAWAR",
          "zone": "NWR",
          "address": "National Highway 8, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.642177,
            25.015425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "WRS",
          "name": "WARIS ALEGANJ",
          "zone": "ECR",
          "address": "Warisaliganj, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.86398899999999,
            28.148542000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "VZPH",
          "name": "Vazeerpur Halt",
          "zone": "NER",
          "address": "Lilhar Mudia Link Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.321802,
            22.429409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WTJ",
          "name": "JAM WANTHALI",
          "zone": "WR",
          "address": "Ranuja Village Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.076506,
            29.851996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "PRI",
          "name": "PATHRI",
          "zone": "NR",
          "address": "Haridwar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.914447,
            16.473819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VPG",
          "name": "VENTRAPRAGADA",
          "zone": "SCR",
          "address": "Krishna, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.183702,
            28.664098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "VVKP",
          "name": "VIVEKANAND PURI HALT",
          "zone": "NR",
          "address": "Sarai Rohilla, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01025,
            23.767488999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "PUS",
          "name": "PHUSRO",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.813425,
            15.397241000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "VSG",
          "name": "VASCO DA GAMA",
          "zone": "SWR",
          "address": "Mormugao, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.135398,
            23.078738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VLTR",
          "name": "Vadali Luter Road",
          "zone": "WR",
          "address": "Vadali Luter, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.69849099999999,
            23.075768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LOD",
          "name": "Lekoda",
          "zone": "WR",
          "address": "Ujjain, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.696289906,
            16.7778119655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VJR",
          "name": "VIJAYANAGAR",
          "zone": "SWR",
          "address": "Miraj Road, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.707212,
            21.848343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VRR",
          "name": "VIRPUR",
          "zone": "WR",
          "address": "Rajkot, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.991898,
            24.915065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "OREH",
          "name": "Ore Halt",
          "zone": "?",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.74868400000001,
            25.639006
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "YFP",
          "name": "YUSUFPUR",
          "zone": "NER",
          "address": "MDR 94E, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.449442,
            21.17981
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VJD",
          "name": "VIJPADI ROAD",
          "zone": "WR",
          "address": "Dolti-Rajula Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.220496,
            10.934013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MVS",
          "name": "MANAVASI",
          "zone": "SR",
          "address": "Manavasi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.38400999999999,
            17.907649
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "VINH",
          "name": "VINHERE",
          "zone": "KR",
          "address": "Raigad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.50524700000001,
            14.100277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MRDW",
          "name": "MURDESHWAR",
          "zone": "KR",
          "address": "Murudeshwara, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.728366,
            13.612909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KUDA",
          "name": "KUNDAPURA",
          "zone": "KR",
          "address": "Basrur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.08524799999999,
            12.320267999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "KZE",
          "name": "KANHANGAD",
          "zone": "SR",
          "address": "Kanhangad, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.08143399999999,
            21.758482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "NROD",
          "name": "NARI ROAD",
          "zone": "WR",
          "address": "SH 6, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.981617,
            20.76451
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WDG",
          "name": "Wadegaon",
          "zone": "SECR",
          "address": "Warsa Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.755622,
            21.341738000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VSW",
          "name": "Visavadar",
          "zone": "WR",
          "address": "Visavadar, Gujarat"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "NRPD",
          "name": "Narayanpet Road",
          "zone": "SCR",
          "address": "SH 51, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "SBY",
          "name": "Solan Brewery",
          "zone": "NR",
          "address": "Solan, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.64517,
            27.801268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KSJF",
          "name": "Kasganj Mg",
          "zone": "NER",
          "address": "Kasganj, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.82043300000001,
            21.21501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WND",
          "name": "Wan Road",
          "zone": "SCR",
          "address": "Dhulghat, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.547974,
            11.557654999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VLU",
          "name": "Vadalur",
          "zone": "SR",
          "address": "Vadalur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.068221,
            13.528576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "VLV",
          "name": "Vallivedu",
          "zone": "SCR",
          "address": "Vallivedu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.23126776609999,
            25.1739936806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "MANI",
          "name": "Mani Halt",
          "zone": "ECR",
          "address": "Mani, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9541837128,
            17.626312868499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TAA",
          "name": "TADLA PUSAPALLI",
          "zone": "SCR",
          "address": "Mahbubabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.869711,
            17.175076999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CHRG",
          "name": "CHORGI",
          "zone": "SWR",
          "address": "Bijapur, Karnataka"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SNAD",
          "name": "Saniyad",
          "zone": "WR",
          "address": "Palej, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.47905756533146,
            28.439590510764504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SRDR",
          "name": "Sardarshahr",
          "zone": "NWR",
          "address": "SH 6, Rajasthan"
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SRGR",
          "name": "Saraygarh",
          "zone": "ECR",
          "address": "Saraygarh, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.212767,
            19.575715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PTU",
          "name": "PARTUR",
          "zone": "SCR",
          "address": "Jalna, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.056105,
            24.59896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AGY",
          "name": "AGORI KHAS",
          "zone": "NCR",
          "address": "State Highway 5, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.638246,
            25.438444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SVPI",
          "name": "SHIVPURI",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.005257,
            22.272733000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MUO",
          "name": "MASANGAON",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.124085,
            23.640315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KWAE",
          "name": "KATWA",
          "zone": "ER",
          "address": "Katwa, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.0035287323,
            23.0950624331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "VNRD",
          "name": "VANI ROAD",
          "zone": "WR",
          "address": "State Highway 17, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.92881,
            23.769553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "JAN",
          "name": "JARANGDIH",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.76189900000001,
            19.7985273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BOR",
          "name": "BOISAR",
          "zone": "WR",
          "address": "Boisar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.50047372281551,
            20.772638155885446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "WGI",
          "name": "Waghai",
          "zone": "WR",
          "address": "Waghai, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.15177759640001,
            19.3334723149
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BMF",
          "name": "BASMAT",
          "zone": "SCR",
          "address": "Basmath, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.3509509503,
            27.4496656596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CGF",
          "name": "CHARALI",
          "zone": "NFR",
          "address": "Madhubon to Naoholia Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.70314900000001,
            12.848947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CJ",
          "name": "KANCHIPURAM",
          "zone": "SR",
          "address": "Kanchipuram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.304858,
            32.478914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "CKDL",
          "name": "CHAKDAYALA",
          "zone": "NR",
          "address": "Chhan Rorian, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.975734,
            23.305386000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CJN",
          "name": "CHHATNA",
          "zone": "SER",
          "address": "Chhatna, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.515528,
            17.405007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SVX",
          "name": "SAVARDA",
          "zone": "KR",
          "address": "Ratnagiri, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.273732,
            22.100085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "URP",
          "name": "Udairampur",
          "zone": "ER",
          "address": "Udairampur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82118,
            29.108936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "STD",
          "name": "Satrod",
          "zone": "NWR",
          "address": "Haryana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.194643,
            22.3355771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "CHHU",
          "name": "Chhanva",
          "zone": "SER",
          "address": "Mayurbhanj, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.16548966,
            8.3398400051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PASA",
          "name": "PARASHSHALA",
          "zone": "SR",
          "address": "Parassala, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.445719,
            19.00582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "KDPA",
          "name": "KHADAPA",
          "zone": "ECoR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.779691,
            27.741573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NMK",
          "name": "Nim Ka Thana",
          "zone": "NWR",
          "address": "Neem Ka Thana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.872524,
            21.274489000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLAT",
          "name": "Khelpatta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5330143262,
            25.341408340100003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUHI",
          "name": "Murhari Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.871166,
            21.520105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SZH",
          "name": "Saonga Halt",
          "zone": "SECR",
          "address": "Saonga, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.411641,
            22.636349000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DDC",
          "name": "Dum Dum Cantt.",
          "zone": "ER",
          "address": "Dum Dum Cantt, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.28447,
            23.777986000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "PLY",
          "name": "Plassey",
          "zone": "ER",
          "address": "Plassey, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8840283246,
            18.4875607596
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "CKX",
          "name": "Chakur",
          "zone": "SCR",
          "address": "MSH3, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.389317,
            27.201471
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "PCMK",
          "name": "Pacharmalikpura",
          "zone": "NWR",
          "address": "State Highway 19, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.311632,
            21.951502
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BCRD",
          "name": "Barchi Road",
          "zone": "CR",
          "address": "State Highway 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.65069899999999,
            25.407922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BUDI",
          "name": "Bundi",
          "zone": "WCR",
          "address": "Bundi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.90421199999999,
            22.101526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "CJQ",
          "name": "Champajharam",
          "zone": "SER",
          "address": "National Highway 23, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.817754,
            23.162187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "VRG",
          "name": "VIKRAMNAGAR",
          "zone": "WR",
          "address": "Ujjain, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.89450500000001,
            15.150286000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BYC",
          "name": "BELLARY CANT",
          "zone": "SWR",
          "address": "Bellary, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.896803,
            31.080357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "BWF",
          "name": "BUTEWALA",
          "zone": "NR",
          "address": "Village Sadhara Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.638003,
            20.780632
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "SPY",
          "name": "Sarsonpuri",
          "zone": "SECR",
          "address": "National Highway 43, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.668563,
            25.860229999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BSDA",
          "name": "BANIYA SANDA DHORA",
          "zone": "NWR",
          "address": "Barmer, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.899672,
            26.274562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BXP",
          "name": "BARPATHAR",
          "zone": "NFR",
          "address": "Railway Station, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.671009,
            24.693873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GRF",
          "name": "GAMBHIRI ROAD",
          "zone": "WR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.61523199999999,
            24.896441999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEC",
          "name": "BERACH JN CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.04895300000001,
            24.38141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPNI",
          "name": "PARAS PANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.909396,
            24.92025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NDT",
          "name": "Nathdwara",
          "zone": "NWR",
          "address": "SH 49, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.69969599999999,
            25.762849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JNU",
          "name": "JAUNPUR JN",
          "zone": "NR",
          "address": "Jaunpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.642682,
            19.0418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "UMRI",
          "name": "UMRI",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.011944,
            24.453381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BXLL",
          "name": "BILLI",
          "zone": "ECR",
          "address": "Obra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.349067,
            22.22962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BHPA",
          "name": "Bahrupiya Halt",
          "zone": "ER",
          "address": "Bahirpuya, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5813,
            25.639425999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "SPP",
          "name": "SHAHPUR PATOREE",
          "zone": "ECR",
          "address": "Samastipur, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.992929,
            30.848579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "MSSD",
          "name": "Maheshari Sandhuan",
          "zone": "NR",
          "address": "Firozpur Rd, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.908616,
            26.836088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASSH",
          "name": "AISHBAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5869409,
            11.593321399999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "BDJ",
          "name": "VADAKARA",
          "zone": "SR",
          "address": "Calicut, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.054715,
            25.035047
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KADH",
          "name": "Khairadih Halt",
          "zone": "ECR",
          "address": "Khairadih, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.13517327999999,
            23.1943639299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BXL",
          "name": "BHEDUASOL",
          "zone": "SER",
          "address": "National Highway 60, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.078953,
            29.483399000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AMPR",
          "name": "AMARPURA RATHAN",
          "zone": "NWR",
          "address": "MDR 103, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.4924808,
            25.5596627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BWA",
          "name": "BHINWALIYA",
          "zone": "NWR",
          "address": "Pindwara, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.62821,
            25.270948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "JNX",
          "name": "Jagnath Road Halt",
          "zone": "NWR",
          "address": "Jalor, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.545106,
            27.357827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBSK",
          "name": "Marwar Balia",
          "zone": "NWR",
          "address": "Marwar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.440599,
            27.299916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BEJ",
          "name": "Beniganj",
          "zone": "NR",
          "address": "Sitapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.822754,
            18.970276000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BSDR",
          "name": "Bansadhara Halt",
          "zone": "ECoR",
          "address": "Lihuri Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.429773,
            22.443359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SPR",
          "name": "Sonarpur Junction",
          "zone": "ER",
          "address": "Kolkata, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.69314200000001,
            28.630277
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SPB",
          "name": "Sonekpur Halt",
          "zone": "NR",
          "address": "Bilari, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.06675999999999,
            21.312575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PQA",
          "name": "Pipla Halt",
          "zone": "SECR",
          "address": "Pipla, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.233733,
            25.937062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "BIRL",
          "name": "Biraul",
          "zone": "ECR",
          "address": "Darbhanga, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.177379,
            29.648169000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "GRZ",
          "name": "Gurthuri",
          "zone": "NR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.10219199999999,
            31.773393000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "JNT",
          "name": "Jaintipura",
          "zone": "?",
          "address": "National Highway 15, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.18200300000001,
            21.304069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQH",
          "name": "Pindkepar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.0377359918,
            24.3273122158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JNZ",
          "name": "Jenal",
          "zone": "NWR",
          "address": "Bhildi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.368601,
            25.825794000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PQN",
          "name": "Pariawan Kalakankar Road",
          "zone": "NR",
          "address": "Pwn Klaknder Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.191952,
            23.75454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JNN",
          "name": "Jamuniatand",
          "zone": "ECR",
          "address": "Jamuniatand, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.56567299999999,
            30.814885999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CKM",
          "name": "Chauki Man",
          "zone": "NR",
          "address": "Chauki Maan, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64768061160001,
            13.9025358525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEI",
          "name": "Shimoga Bidare",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1360237,
            28.0274922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BEM",
          "name": "Budaun",
          "zone": "NER",
          "address": "Badaun, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.418467,
            26.698845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "METR",
          "name": "Methi Tikur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.51148736,
            20.76925671
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BWV",
          "name": "Bhiwapur",
          "zone": "SECR",
          "address": "Bhiwapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.375851,
            26.0356
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMDC",
          "name": "SAWAI MADHOPUR D CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.6779178894,
            21.67359117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APQ",
          "name": "ADITPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2537294641,
            27.2889777447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MOTA",
          "name": "MOTA",
          "zone": "NCR",
          "address": "Mota, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.22770799999999,
            28.579299000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "SWNR",
          "name": "SEWA NAGAR",
          "zone": "NR",
          "address": "South Extension 1, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.166923,
            22.512244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LO",
          "name": "LOLIYA",
          "zone": "WR",
          "address": "State Highway 1, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.3707734497,
            25.2864711772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NON",
          "name": "Nonar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.13179600000001,
            22.487223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "HLB",
          "name": "HADALA BHAL",
          "zone": "WR",
          "address": "Limbadi to Hadala Rd, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.596325,
            25.630348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RLR",
          "name": "RAILA ROAD",
          "zone": "NWR",
          "address": "Raila, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.84270550779999,
            24.939958728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EDA",
          "name": "ITWAN DUNDAILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.14983,
            25.221835000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDJ",
          "name": "PHIDING",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.071838,
            28.560927
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "SMDP",
          "name": "SHAHABAD MOHAMADPUR",
          "zone": "NR",
          "address": "Dwarka, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.72634199999999,
            23.916929999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KTOA",
          "name": "KOTANA",
          "zone": "NWR",
          "address": "Kotana, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.090728,
            12.336963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TJM",
          "name": "TURINJAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.368646,
            10.776294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KYV",
          "name": "KOYILVENNI",
          "zone": "SR",
          "address": "Kovilvenni, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8140929907,
            28.7611280103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ROHN",
          "name": "ROHEDNAGAR HALT",
          "zone": "NR",
          "address": "Delhi-Rohtak Corridor, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.30063,
            10.941722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SPL",
          "name": "SUNDARAPERUMAL KOVIL",
          "zone": "SR",
          "address": "Thanjavur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.93176899999999,
            11.060239
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NOY",
          "name": "NOYAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.98011055180001,
            25.1331170814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCYN",
          "name": "KACHHIYANA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.08968,
            24.217193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LW",
          "name": "LORWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.589906,
            15.329698
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "EDD",
          "name": "EDDULADODDI",
          "zone": "SCR",
          "address": "Pyapili, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.799259,
            15.434833000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "RLP",
          "name": "HARLAPUR",
          "zone": "SWR",
          "address": "National Highway 63, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.12762727,
            26.82028731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRH",
          "name": "GHORASAHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8785035535,
            28.0989194076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "NOH",
          "name": "Nigohi",
          "zone": "NER",
          "address": "Nigohi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.27163889890001,
            29.0477960432
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AIH",
          "name": "ALAWALPUR I PUR",
          "zone": "NR",
          "address": "Alawalpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.438384,
            25.83725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DPU",
          "name": "DIPHU",
          "zone": "NFR",
          "address": "Diphu, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.390923,
            22.140587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MPRD",
          "name": "Mathurapur Road",
          "zone": "ER",
          "address": "State Highway 3, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.51715200000001,
            25.820221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DLDE",
          "name": "DALDALI",
          "zone": "NFR",
          "address": "Diphu, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.496982,
            15.450273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "LD",
          "name": "LONDA JN",
          "zone": "SWR",
          "address": "Londa, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.261348,
            25.333548
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BYH",
          "name": "BHITI",
          "zone": "NER",
          "address": "Handia, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.53636700000001,
            26.53866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPO",
          "name": "DAMANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.436792,
            22.921498999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HLR",
          "name": "Halisahar",
          "zone": "ER",
          "address": "Kanchrapara, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.819703,
            25.940085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TJW",
          "name": "Tejpurwa Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.710974,
            12.813180000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HLE",
          "name": "Heelalige",
          "zone": "SWR",
          "address": "Chandapura -Dommasandra Road Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1913272595,
            21.872721159199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DPW",
          "name": "Dhapewara",
          "zone": "SECR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0260572961,
            11.7955548355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SMDT",
          "name": "Semmandappatti",
          "zone": "SWR",
          "address": "Salem, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5545000909,
            25.5031445488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAWA",
          "name": "Balwara",
          "zone": "NWR",
          "address": "State Highway 38, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.490871,
            23.725742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "RKJE",
          "name": "Rakhitpur",
          "zone": "ECR",
          "address": "Jairampur More to Baliapur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.349702,
            22.524250000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LMU",
          "name": "Limarua",
          "zone": "SECR",
          "address": "Chiraidongri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.50348000000001,
            23.244426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLLF",
          "name": "Kalol Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2806012268,
            26.429190879300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LB",
          "name": "Lain Bazar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.365526,
            12.717207
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KNYR",
          "name": "Kaniuru Halt",
          "zone": "SWR",
          "address": "Kaniyoor, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5297094055,
            25.2669627882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APP",
          "name": "Ambapendarpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.326105,
            23.292133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KADI",
          "name": "Kadi",
          "zone": "WR",
          "address": "Kadi, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.43452699999999,
            24.545735999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "APU",
          "name": "Anipur",
          "zone": "NFR",
          "address": "Anipur, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.07610899999999,
            28.02178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "APJ",
          "name": "Amarpur Jorasi",
          "zone": "NWR",
          "address": "Narnaul, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.10189147,
            9.524631680999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APK",
          "name": "Aruppukkottai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.92262799379999,
            10.3998079865
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "API",
          "name": "Akkaraippatti",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.173654,
            22.078204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTKH",
          "name": "Kotlakheri",
          "zone": "WR",
          "address": "East Nimar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.814406,
            22.908845999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXZ",
          "name": "KESARIYA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.310319,
            26.984965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SABD",
          "name": "SALABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.924226,
            29.400184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KURN",
          "name": "KHUKRANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.005407,
            26.468217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXR",
          "name": "KURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.05984199999999,
            12.845834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLKH",
          "name": "Kalladaka",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.274465,
            23.438252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTYA",
          "name": "Bhatariya",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.780097,
            28.626216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RJK",
          "name": "RAJA KA SAHASPUR",
          "zone": "NR",
          "address": "Bilari, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.979022,
            23.202833000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KTMA",
          "name": "KOTMA",
          "zone": "SECR",
          "address": "Kotma, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.822458,
            21.049919000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSBN",
          "name": "BHUSAWAL B CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.40378299999999,
            26.763435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSRY",
          "name": "PASHWASHRAYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.133437,
            30.423043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "CKLA",
          "name": "Chak Banwala",
          "zone": "NR",
          "address": "Chimne Wala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.08687905000001,
            24.8100917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JIGT",
          "name": "JIRIGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.057502,
            23.885219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KADR",
          "name": "Kadiyadra",
          "zone": "WR",
          "address": "State Highway 10, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.92969,
            25.728138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TJD",
          "name": "TAJPUR DEHMA",
          "zone": "NER",
          "address": "Gosalpur Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.73529039,
            14.5614031141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CLI",
          "name": "CHALGERI",
          "zone": "SWR",
          "address": "Chalageri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6267,
            23.746057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGBR",
          "name": "JOGESHWAR BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.490385,
            13.091395
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SDVL",
          "name": "SOLDEVANAHALLI",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.08778,
            14.939155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LOL",
          "name": "LOLIEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.774173,
            20.593933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMMD",
          "name": "Kirmiti Mendha",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.724227,
            18.807373000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRPC",
          "name": "KHARIA-PIPRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.51446299999999,
            26.800480999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLRE",
          "name": "BELSIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.793439,
            26.865524999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRQ",
          "name": "DHARMINIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.635791,
            31.40278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "DRE",
          "name": "DHOGRI",
          "zone": "NR",
          "address": "Dhogri, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.21831599999999,
            16.551026
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KKLR",
          "name": "KAIKOLUR",
          "zone": "SCR",
          "address": "Kaikalur, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.428861,
            23.427144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KYSD",
          "name": "KAIYAL SEDHAVI",
          "zone": "WR",
          "address": "State Highway 217, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.497258,
            12.421123999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KLYH",
          "name": "Kallur Yedahli",
          "zone": "SWR",
          "address": "Kallur Yedahli, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.428531,
            23.426814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYSB",
          "name": "KAIYAL SEDHAVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.998947,
            25.604879
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DHE",
          "name": "Dodhi",
          "zone": "NER",
          "address": "Dodhi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4119287744,
            26.7017793648
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BRDV",
          "name": "Baradev",
          "zone": "NR",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.901807,
            25.971659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TKLN",
          "name": "Tarrakalan",
          "zone": "NCR",
          "address": "Tarrakalan, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.46113700000001,
            26.661427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "MKHI",
          "name": "Makhi",
          "zone": "NR",
          "address": "Unnao, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.162137,
            16.202721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CLU",
          "name": "Chilakalapudi",
          "zone": "SCR",
          "address": "Machilipatnam, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.799478,
            22.136014999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRDH",
          "name": "Bardhana Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.378783,
            28.037882999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSV",
          "name": "Parsneu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.253929,
            28.58701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNZM",
          "name": "Delhi Hazrat Nizamuddin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.983053,
            21.361133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "TKLB",
          "name": "Takli Bhansali",
          "zone": "SECR",
          "address": "National Highway 69, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.025915,
            21.335452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PSX",
          "name": "Patansaongi Town Halt",
          "zone": "SECR",
          "address": "Patansaongi, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.819383,
            18.970667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BCT",
          "name": "Mumbai Central",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.836786,
            23.519468000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDF",
          "name": "PADAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.332848,
            26.851551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TDH",
          "name": "Tindharia",
          "zone": "NFR",
          "address": "National Highway 55, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.868376,
            27.182926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KZA",
          "name": "KHADA",
          "zone": "NER",
          "address": "Khadda Katai Bharpurva Bandh, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.37463899999999,
            28.297728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGBD",
          "name": "RAMGANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.417372,
            30.175886000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "QBW",
          "name": "QUBARWALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.246908,
            26.224332999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPRH",
          "name": "PIPARAHAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.641128,
            25.01154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DET",
          "name": "DET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.182478,
            12.05415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACN",
          "name": "ADHICHCHANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.25044299999999,
            32.05191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KPLR",
          "name": "KOPARLAHAR",
          "zone": "NR",
          "address": "National Highway 88, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.90609338009999,
            22.7603546992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MU",
          "name": "MAKANSAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.287498,
            30.107173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "RKSH",
          "name": "RISHIKESH",
          "zone": "NR",
          "address": "Rishikesh, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.855228,
            22.814612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NZG",
          "name": "NAZARBAG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.094386,
            24.206087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPF",
          "name": "MUIRPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.854093,
            14.498218999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AVC",
          "name": "AMRAVATHI COLONY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.590411,
            25.224926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KLRS",
          "name": "KOLARAS",
          "zone": "WCR",
          "address": "Shivpuri, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.11188299999999,
            23.710492
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CSMA",
          "name": "Chanasma Junction",
          "zone": "WR",
          "address": "Chanasma, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.730177,
            12.485166999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CGKR",
          "name": "CHANDAGIRIKOPAL",
          "zone": "?",
          "address": "State Highway 7, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.41188299999999,
            24.786919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDJR",
          "name": "BHADORA JAGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.033542879,
            14.3305086886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "KAG",
          "name": "KODAGANUR",
          "zone": "SWR",
          "address": "State Highway 47, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            68.992445,
            22.389889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMRN",
          "name": "BHIMRANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.724365,
            25.787896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAPA",
          "name": "Chhapra Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.520612,
            15.456312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDPL",
          "name": "NDPL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.113189,
            26.063048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAR",
          "name": "BAR",
          "zone": "NWR",
          "address": "Haripur, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.58350800000001,
            29.929192999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJGY",
          "name": "Khanalampura Gy",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.479251,
            21.103699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RLA",
          "name": "RAJULA JN",
          "zone": "WR",
          "address": "Baraf Tana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7089663623,
            26.3019109091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKND",
          "name": "Trivikramdeo Nagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.59185599999999,
            23.938342
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVGM",
          "name": "DEVGAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.310137,
            26.172784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNBR",
          "name": "SONUABARY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.985001,
            26.72438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRSN",
          "name": "HARISINGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.3515292572,
            27.4659128037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPGN",
          "name": "SRIPURIA GAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.82424935060001,
            28.3853476007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNJR",
          "name": "TAJ NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.292427,
            15.309987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LIM",
          "name": "SONALIUM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.928281,
            29.265794999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPRN",
          "name": "PIPRAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.1030164429,
            23.083595579100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPPR",
          "name": "GOPALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.555273,
            28.361736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMNL",
          "name": "BAMANWALI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.178413,
            28.486234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDKN",
          "name": "UDAYPUR KHURD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.471355,
            23.326699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JUL",
          "name": "JHULASAN",
          "zone": "WR",
          "address": "Mehsana, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.496473584,
            14.024984822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGVE",
          "name": "Konagavalli Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.88587199999999,
            28.028812000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DOT",
          "name": "Dhakia Tiwari",
          "zone": "NER",
          "address": "Dhakia Tiwari, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.90718700000001,
            25.894911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHTH",
          "name": "Bhairopatti Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.712797,
            21.424028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTWN",
          "name": "Mitewani",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.078323,
            23.01905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KVNJ",
          "name": "Kapadvanj",
          "zone": "WR",
          "address": "Kapadvanj, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.75496600000001,
            21.881341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NCN",
          "name": "Nachinda Ph",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.903227,
            22.764138999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VINA",
          "name": "Vina",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.788161,
            26.927786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LIJ",
          "name": "Lakshmiganj",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.656456,
            26.369411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KULH",
          "name": "Kulha-Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.673891665,
            25.333656684799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTKS",
          "name": "Kantakosh Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.435028,
            23.262660999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "STB",
          "name": "Shantipur",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1854899005,
            13.988212499000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NCE",
          "name": "Nalacheruvu East",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.795843,
            23.523411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSN",
          "name": "Darsana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8406655457,
            24.7672962666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKMG",
          "name": "Tikamgarh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.338857,
            24.995699000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKPU",
          "name": "Nekpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.696016,
            12.83778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGVL",
          "name": "Kadagaravalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.189939,
            19.549184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDPJ",
          "name": "Madanapur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.448635,
            26.256947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PATR",
          "name": "Paramjeevar Tarajeevar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.12580920779999,
            14.007537361799999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLNR",
          "name": "NULEMURU HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.517641,
            29.071574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "LKU",
          "name": "LAL KUAN",
          "zone": "NER",
          "address": "Lalkuan, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.9240362146,
            19.9493826187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAJG",
          "name": "KARANJGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.251585,
            18.380482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "MRX",
          "name": "MURUD",
          "zone": "CR",
          "address": "State Highway 162, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.90078,
            24.607481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKAR",
          "name": "SAKARIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.92223899999999,
            9.269174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLL",
          "name": "NALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.684608,
            10.683752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDGM",
          "name": "PUDUNAGARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.8083238586,
            21.8996981538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLVD",
          "name": "KALAVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8245009833,
            29.8018131127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "JHL",
          "name": "JAKHAL JN",
          "zone": "NR",
          "address": "Jakhal, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4382772,
            16.4695102
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MCLA",
          "name": "Macherla",
          "zone": "SCR",
          "address": "Macherla, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.305982,
            26.794394999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MEG",
          "name": "MELENG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.61468985409999,
            23.0072062075
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WWA",
          "name": "VAVANIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.85015269780001,
            25.300468743099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEIT",
          "name": "Dehit",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.93477800000001,
            24.875571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMGN",
          "name": "Thamla Mogana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.605319,
            28.187504999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMRI",
          "name": "ANAJ MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.778666,
            25.891285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMPT",
          "name": "Banni Mahamman",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.917318,
            25.300234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TKA",
          "name": "TEHARKA",
          "zone": "NCR",
          "address": "National Highway 75, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.152541,
            18.97295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKHS",
          "name": "Chikhale",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30022000000001,
            28.677356999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSBC",
          "name": "DELHI SHAHDARA BLOCK CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.03158,
            24.164576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDPN",
          "name": "SINDPAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.690156,
            32.02843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "AHJU",
          "name": "AHJU",
          "zone": "NR",
          "address": "National Highway 20, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.101493,
            30.827548999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLKN",
          "name": "LAL KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5159651358,
            17.517847537100003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BOZ",
          "name": "BOLARUM BAZAR",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.54447048829999,
            19.1086533083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SVN",
          "name": "SIVUNGAON",
          "zone": "SCR",
          "address": "Nanded, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.24009,
            19.526469000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNSD",
          "name": "PANGARSHINDE HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6709042426,
            25.6149631854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJJN",
          "name": "RAJAJAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.422431,
            23.446636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UMNF",
          "name": "Ambliyasan Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.068505,
            25.621426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPTA",
          "name": "PATLIPUTRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.85816200000001,
            22.741887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKBR",
          "name": "LAKSHMI BAI NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9464005609,
            27.888685430000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DBLA",
          "name": "Dabla",
          "zone": "NWR",
          "address": "National Highway 79, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.80243268800001,
            25.602064857400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPDA",
          "name": "VIDYAPATIDHAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3426508783,
            15.167523391300001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTLW",
          "name": "GUNTAKAL WEST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.935736,
            32.982814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVDK",
          "name": "S M V D KATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.682658,
            26.8215934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPAN",
          "name": "RANGAPARA NORTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.653412,
            11.4723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "VEK",
          "name": "VELLARAKKAD",
          "zone": "SR",
          "address": "Vellarakkad Railway Station, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.3952511639,
            26.064069106999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CGON",
          "name": "CHAYGAON",
          "zone": "NFR",
          "address": "National Highway 37, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.97761799999999,
            27.153922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNAN",
          "name": "Gonda Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.210181,
            23.159049
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIE",
          "name": "Demai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.636044,
            32.2593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTKC",
          "name": "PATHANKOT CANTT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5436173315,
            25.4316280874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EC31",
          "name": "Kharuara Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.646229,
            27.35353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWA",
          "name": "Powai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.700138,
            20.579811999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "NAB",
          "name": "Nagbhir Junction",
          "zone": "SECR",
          "address": "Nagbhir, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.20223499999999,
            26.682578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAK",
          "name": "Naksalbari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65148636960001,
            24.775688911699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDPR",
          "name": "Udaipura",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.353537,
            21.823905999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNWF",
          "name": "Khandwa Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.206169,
            13.328283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVS",
          "name": "Srinivaspur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.905624,
            22.608628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NMDR",
          "name": "Nimdanri",
          "zone": "ER",
          "address": "Madhyampur Station Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.88310899999999,
            27.973382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KCU",
          "name": "Kachhiaa",
          "zone": "NER",
          "address": "Badaun, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.613385199,
            25.411453041399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHPR",
          "name": "Mahiyarpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1994253,
            21.8111685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTCN",
          "name": "Balaghat",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.19412,
            18.943255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHPE",
          "name": "Mhope",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.57412,
            21.729584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLRF",
          "name": "Jetalsar Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.424114,
            25.050378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDPH",
          "name": "Sardar Patel Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.53137103520001,
            25.3007943293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IMBG",
          "name": "Imlibigha Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27774552000001,
            25.336573948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XKA",
          "name": "Kamta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.67892,
            21.632916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKZ",
          "name": "Mahkepar Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.94712,
            23.971989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "SKAP",
          "name": "SK Para",
          "zone": "NFR",
          "address": "National Highway 44, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.275222,
            13.084876000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMCC",
          "name": "MOOR MARKET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.52942276,
            25.2611531167
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKR",
          "name": "Dekpura Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.74965900000001,
            27.070551000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRHT",
          "name": "KHARAHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.705438,
            29.42673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JDW",
          "name": "JARAUDA NARA",
          "zone": "NR",
          "address": "MuzaffarNagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.86074699999999,
            29.100107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAYR",
          "name": "Mayar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.442293656,
            11.9307785279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKM",
          "name": "VENKATESAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19083099999999,
            29.436815999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXQ",
          "name": "SUAHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.043777,
            9.299159000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PPVL",
          "name": "PIRAPPANVALASAI",
          "zone": "SR",
          "address": "Pirappan Valasai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.759969657,
            29.390441641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NARA",
          "name": "NARA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.73706466189999,
            26.045500525999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KER",
          "name": "KIRIHRAPUR",
          "zone": "NER",
          "address": "Kirihrapur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0327645691,
            28.515463592299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLVR",
          "name": "PALAM VIHAR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.80832813660001,
            29.992439273899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GGKR",
          "name": "GOVINDGARH KHOKHAR",
          "zone": "NR",
          "address": "Govindgarh, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2878959662,
            16.576700082699997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PLVA",
          "name": "PALLEVADA",
          "zone": "SCR",
          "address": "Pallevada, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.31706186039999,
            23.311548007099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDIM",
          "name": "NANDAIGRAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.915401,
            15.46241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "MGD",
          "name": "MUGAD",
          "zone": "SWR",
          "address": "State Highway 34, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.398096,
            22.990357999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "TBAE",
          "name": "TRIBENI",
          "zone": "ER",
          "address": "Tribeni, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.875839,
            11.127976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "URL",
          "name": "UNJALUR",
          "zone": "SR",
          "address": "Unjalur, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.90540899999999,
            22.988208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMM",
          "name": "BHASKARPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.823472,
            24.146307
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKW",
          "name": "DHANAKWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.87235,
            8.937878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "IVL",
          "name": "ILAVELANGAL",
          "zone": "SR",
          "address": "Ottanatham - Maniyachi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.801905,
            30.613851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "GZS",
          "name": "GIANI ZAIL SINGH SANDHWAN",
          "zone": "NR",
          "address": "Distt Faridkot, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.600949,
            23.035546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHB",
          "name": "Shahibag",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.914582,
            21.740994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BMC",
          "name": "Bhimalgondi",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.154497,
            26.392839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBR",
          "name": "Taiabpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.357286,
            21.485727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSWD",
          "name": "BHEMSWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.699488,
            26.797642000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKTB",
          "name": "THAKURBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.686954,
            25.609488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RMC",
          "name": "Ram Chaura Road",
          "zone": "NR",
          "address": "National Highway 24B, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.1078213831,
            30.2566065213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHLU",
          "name": "Ghallu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.886302,
            24.595571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BMN",
          "name": "BHIMANA",
          "zone": "NWR",
          "address": "Abu Road, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.155686,
            25.873873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRDH",
          "name": "PIR DULHE SHAH HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.103224,
            31.021478000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KEJ",
          "name": "Kathleeghat",
          "zone": "NR",
          "address": "Shoghi, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.55668499999999,
            26.301078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRDG",
          "name": "Maradanga",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.660732,
            20.095174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRDA",
          "name": "Maroda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.74624299999999,
            28.607969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LUA",
          "name": "Laluri Khera",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.263378,
            21.087653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEMP",
          "name": "Kempalsad",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.414033,
            26.723574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGUD",
          "name": "Siliguri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.01665499389999,
            27.890151968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHNH",
          "name": "Channi Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.674577,
            20.625413
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTGO",
          "name": "Kotgaon",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0183333807,
            21.3760800242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAAL",
          "name": "Pala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.726431,
            18.715021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXV",
          "name": "Suku",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2923767371,
            25.4485799037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NARD",
          "name": "Naika Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.523598,
            27.537943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAKD",
          "name": "Radha Kund",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6174634106,
            25.365523628400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHMA",
          "name": "Baghmara Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.44881699999999,
            29.762905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZPR",
          "name": "Zorawar Pura Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.04654,
            22.215082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "OM",
          "name": "Omkareshwar Road",
          "zone": "WR",
          "address": "SH 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8437572205,
            26.008145546599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGTR",
          "name": "Agauthar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2718057104,
            24.963884576399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NES",
          "name": "Natesar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.9593667969,
            14.2250994053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNLE",
          "name": "Kanale",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5781969,
            18.1536772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BRMT",
          "name": "Baramati",
          "zone": "CR",
          "address": "Baramati, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.80821,
            27.106537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHKT",
          "name": "MOHKHUTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82596,
            30.714577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNGR",
          "name": "GUNGRANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.90543100000001,
            26.62564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCO",
          "name": "MOTIHARI COURT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.87534699999999,
            10.629748000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXM",
          "name": "MINATCHIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.17972008790001,
            12.711563669599999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OPN",
          "name": "ONNUPURAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.82513399999999,
            13.406514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDH",
          "name": "Gidnahali",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.0778039648,
            30.305205841499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUKA",
          "name": "Khui Khera",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.98680179930001,
            22.9311978995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNJN",
          "name": "BINJANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.454923,
            27.970288
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDW",
          "name": "Gadhwala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1862,
            12.598975000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCL",
          "name": "MADIMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.726312,
            27.638101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUGN",
          "name": "BHURJIHA BARAGN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.841869,
            25.356832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKJN",
          "name": "LINK JN. CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.17640399999999,
            29.420302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHH",
          "name": "JHALRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.401113,
            23.824725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTES",
          "name": "KATNI SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5206561768,
            13.9821624414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGH",
          "name": "Kotegangur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.272803,
            29.014595999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLKN",
          "name": "CHUCHELA KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3596044824,
            15.1249217339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GDI",
          "name": "GUDGERI",
          "zone": "SWR",
          "address": "Gudgeri, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.8894849231,
            25.6322106511
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLPR",
          "name": "DAYALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2740297845,
            25.6975316046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKRD",
          "name": "CHAKMAKRAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.651062146,
            32.2419748422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHCH",
          "name": "BLOCK CABIN CHAKKI BANK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.176458,
            24.035537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KNSN",
          "name": "KARNA SUBARNA",
          "zone": "ER",
          "address": "Khagraghat - Chowrigacha, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5274862292,
            26.5016791611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APCH",
          "name": "ALIPURDUAR COLLEGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3061977655,
            15.3607491302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRZ",
          "name": "CARANZOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.324726,
            28.145913
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "KNSR",
          "name": "KANASAR",
          "zone": "NWR",
          "address": "Lalgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0849798193,
            24.6141943022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSDA",
          "name": "Hansdiha",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.449413,
            23.378259
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUSB",
          "name": "GHUMASAN BG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.34702300000001,
            20.960497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXZ",
          "name": "Mohdara Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.017247,
            25.102455000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OHAN",
          "name": "OHANCABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.705805,
            15.41363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIE",
          "name": "BHISTENHATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.941809,
            8.602296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AWT",
          "name": "Alwar Tirunagri",
          "zone": "SR",
          "address": "Thoothukudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2332042139,
            13.151294369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUDI",
          "name": "Mududi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.608155,
            29.38048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUGY",
          "name": "Bugia Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.126678,
            25.758384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "IGTA",
          "name": "Ingohta",
          "zone": "NCR",
          "address": "National Highway 86, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.986339,
            22.392169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNGT",
          "name": "Gangatolia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.905659,
            26.697202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHOA",
          "name": "Dhaulpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.628290531,
            26.3516649204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHER",
          "name": "Sher",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.709751,
            23.483611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BICI",
          "name": "Barkichampi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.950772,
            23.164248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBPN",
          "name": "Jabalpur Jn (Ng)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.961877,
            26.448981
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLQ",
          "name": "Salona Assam",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.74963299999999,
            21.296281999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DGT",
          "name": "Dhulghat",
          "zone": "SCR",
          "address": "Amlakhurd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.230454,
            21.107513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGY",
          "name": "Dighori Buzurg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.19991700000001,
            31.551327999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PH",
          "name": "Panoh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.29441999630001,
            25.4624795686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCY",
          "name": "Machriawan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.839712,
            10.925606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIK",
          "name": "Karaikal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8173945569,
            9.035098526
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIF",
          "name": "Kuri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.923705,
            22.020127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "PSJ",
          "name": "Patasahi",
          "zone": "SER",
          "address": "National Highway 23, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.340382,
            27.200025999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLT",
          "name": "Jhamat Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.201337,
            17.126458999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CKN",
          "name": "CHINTA KANI",
          "zone": "SCR",
          "address": "Chinta Kani Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.812342,
            22.560494000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "CBSA",
          "name": "Chaibasa",
          "zone": "SER",
          "address": "National Highway 75, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.722232,
            22.897133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKR",
          "name": "KHAKHRALA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8481621063,
            25.5999397486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPPA",
          "name": "SHERPUR DHIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.441313,
            23.401545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNWF",
          "name": "Dangarwa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.38808359379999,
            18.0060618945
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSS",
          "name": "PADSALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.42299399999999,
            23.375747
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CHRA",
          "name": "CHHARRA",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7316388525,
            19.118747261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PKNS",
          "name": "POKHARNI NARASIMHA",
          "zone": "SCR",
          "address": "Pokharni Nrsnha, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63427990000001,
            25.7882166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHAS",
          "name": "BHOJRAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.146758,
            19.726357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "HNL",
          "name": "HINGOLI DECCAN",
          "zone": "SCR",
          "address": "Hingoli, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.72420699999999,
            30.378176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALAL",
          "name": "ALAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6360518042,
            30.7263803992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KARR",
          "name": "KHARAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.834624,
            22.477066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHAL",
          "name": "BHATIEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11334699999999,
            12.414388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGM",
          "name": "AGARAM SIBBANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.29144328,
            29.249760774200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASAR",
          "name": "ASRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.74671000000001,
            32.005534999999995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "CTZ",
          "name": "CHAUNTRA BHATERH",
          "zone": "NR",
          "address": "Chauntra Bhater, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.30532600000001,
            11.976522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUY",
          "name": "MUGAIYUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.195107,
            12.092248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "PAY",
          "name": "PAYYANUR",
          "zone": "SR",
          "address": "Kannur District, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.719311,
            24.261080999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KRLR",
          "name": "KARAILA ROAD JN",
          "zone": "ECR",
          "address": "Main Road Singrauli-Jayant, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.60520100000001,
            19.322430999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "DAV",
          "name": "DEVALGAON AVCHAR",
          "zone": "SCR",
          "address": "Parbhani, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.806851,
            26.180382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMY",
          "name": "NOONMATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.630413,
            16.532028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VNEC",
          "name": "Vijayawada North East",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.9592046722,
            27.4656628037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "CKW",
          "name": "CHALKHOA",
          "zone": "NFR",
          "address": "Dibrugarh, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.890472,
            26.621071999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNDV",
          "name": "MANDAWARIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.790475,
            19.384261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLG",
          "name": "JULGAON DECCAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.6285053778,
            15.0927135285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKPY",
          "name": "TURAKAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6741555884,
            19.2418554705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAH",
          "name": "DEHARE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.094329,
            26.744291
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULG",
          "name": "UDALGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.57545400000001,
            26.810166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMM",
          "name": "NEW MISAMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.595773,
            26.811265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSMI",
          "name": "MISAMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.9437206531,
            18.442254377900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AMBR",
          "name": "Ambika Rohina",
          "zone": "SCR",
          "address": "Ambika Devi Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.644228,
            25.401119
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ORC",
          "name": "ORCHHA",
          "zone": "NCR",
          "address": "National Highway 75, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.856475,
            12.844375000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NTVT",
          "name": "NETHRAVATHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.8457729333,
            21.6319185846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNEO",
          "name": "Hathbandh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.764318,
            25.024084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BSSL",
          "name": "Basi Beriasal",
          "zone": "WCR",
          "address": "Bassi, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.443249,
            19.966168
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIP",
          "name": "Chichpalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.48312299999999,
            23.596660999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SNTD",
          "name": "Santaldih",
          "zone": "SER",
          "address": "Purulia, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.6377553125,
            23.292974228200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNTN",
          "name": "Santinagar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.531666,
            27.400358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SNTH",
          "name": "Sonthaliya",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.48327400000001,
            25.140667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPNR",
          "name": "Deepnagar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.155685,
            13.260622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKV",
          "name": "Gottihalli Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.14492,
            24.345509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JAMA",
          "name": "Jama",
          "zone": "ER",
          "address": "Jama, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.112944,
            21.431848000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HIVR",
          "name": "Hiwarkheda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.0603205811,
            24.7415365882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PETA",
          "name": "Pandetola",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.161102,
            27.415623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BWP",
          "name": "Bhawanipur Kalan",
          "zone": "NER",
          "address": "Bhawanipur Kalan, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.47962399999999,
            28.713124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LSE",
          "name": "Laseri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.975052,
            21.653448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "KKV",
          "name": "Kunkavav Junction",
          "zone": "WR",
          "address": "SH 104, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.03392099999999,
            22.871717999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LOK",
          "name": "Loknath",
          "zone": "ER",
          "address": "Loknath Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9954942,
            24.8901416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "CKH",
          "name": "Chakand",
          "zone": "ECR",
          "address": "Chakand, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.002313,
            28.951978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "CKK",
          "name": "Chakarpur",
          "zone": "NER",
          "address": "National Highway 125, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.749675,
            25.012661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DCA",
          "name": "DAMCHARA",
          "zone": "NFR",
          "address": "Diakkawn Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.88840400000001,
            27.147109999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOX",
          "name": "MORAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.954299,
            32.167399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BRMR",
          "name": "BHARMAR",
          "zone": "NR",
          "address": "State Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.518783,
            16.746018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CVV",
          "name": "CHERUVU MADHAVARAM",
          "zone": "SCR",
          "address": "Cheruvu Madhavaram Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.86371000000001,
            16.416601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "WDL",
          "name": "WANDAL",
          "zone": "SWR",
          "address": "Dam Inspection Rd, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.643889,
            18.929059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WDN",
          "name": "VADGAON NILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83395499999999,
            25.980207999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MOJ",
          "name": "MOHANA",
          "zone": "WCR",
          "address": "Mohana, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5378981451,
            19.872663603099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KMV",
          "name": "KARMAD",
          "zone": "SCR",
          "address": "Aurangabad, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.667743,
            25.777583999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "DRS",
          "name": "DHARESHWAR",
          "zone": "NWR",
          "address": "Sojat Road, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2102291101,
            19.6039649812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNJJ",
          "name": "KANNJARA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.916866,
            20.43948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAGD",
          "name": "BAGWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.415981,
            21.201567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRYA",
          "name": "MERIYANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.929837,
            27.006279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RDRA",
          "name": "RAMDEVRA",
          "zone": "NWR",
          "address": "Ramdevra, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.87141700000001,
            29.931792
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SGNR",
          "name": "SHRI GANGANAGAR",
          "zone": "NWR",
          "address": "Sri Ganganagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.387390437,
            26.7587697881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKPE",
          "name": "GORAKHPUR EAST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.942114,
            26.51743
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPCM",
          "name": "KUWARCHINTAWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.748617,
            22.236682000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SAPR",
          "name": "SARANGPUR ROAD",
          "zone": "WR",
          "address": "State Highway 38, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.4178,
            23.197355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GPAE",
          "name": "GUPTIPARA",
          "zone": "ER",
          "address": "Guptipara Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.401605,
            24.264112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "WDM",
          "name": "WYNDHAMGANJ",
          "zone": "ECR",
          "address": "Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.885771,
            11.076073000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KMD",
          "name": "KODUMUDI",
          "zone": "SR",
          "address": "Kodumudi, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.679777,
            24.16808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BUU",
          "name": "BHADANPUR",
          "zone": "WCR",
          "address": "National Highway 7, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.709215,
            22.591346
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETB",
          "name": "ITARSI B CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.872848,
            9.182578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CVP",
          "name": "KOVILPATTI",
          "zone": "SR",
          "address": "Kovilpatti, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.050174,
            22.304246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PAL",
          "name": "PALASNER",
          "zone": "WCR",
          "address": "Harda, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.301025,
            27.517012
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "RNIS",
          "name": "Ranolishishu",
          "zone": "NWR",
          "address": "National Highway 11, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.939264,
            13.321429
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "GBB",
          "name": "GUBBI",
          "zone": "SWR",
          "address": "Gubbi, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.81653700000001,
            15.707894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Goa",
          "code": "PERN",
          "name": "PERNEM",
          "zone": "KR",
          "address": "North Goa, Goa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.353285,
            29.653071999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "NAWN",
          "name": "NAWAN",
          "zone": "NWR",
          "address": "State Highway 7, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.157978,
            14.141045
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AEX",
          "name": "Adderi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.98423622680001,
            25.2012234752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNDO",
          "name": "Jehanabad Court",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.613684,
            16.662928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MCLE",
          "name": "MANCHILI",
          "zone": "SCR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71072699999999,
            12.723885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "AEK",
          "name": "Anekal Road",
          "zone": "SWR",
          "address": "Karnataka, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.05643400000001,
            13.478885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "DCU",
          "name": "Damalcheruvu",
          "zone": "SCR",
          "address": "National Highway 18, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.260998,
            26.860039999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBE",
          "name": "Gayabari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.25510299999999,
            17.500295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKPI",
          "name": "Gate Karepalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.245999,
            22.0353
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNJI",
          "name": "Karanjali",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.72088996,
            27.677209949999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BAGA",
          "name": "Bhagega",
          "zone": "NWR",
          "address": "State Highway 37B, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.9195740283,
            26.921354215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAGJ",
          "name": "Barharagani",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.10654500000001,
            22.058566000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLPR",
          "name": "Silpaprabesh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.52118300000001,
            10.458722
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLNI",
          "name": "Palani",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.88683900000001,
            30.727071000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chandigarh",
          "code": "CNDM",
          "name": "Chandi Mandir",
          "zone": "NR",
          "address": "Panchkula, Chandigarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.741807,
            21.219451
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNDB",
          "name": "Chandur Bazar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.46586180999999,
            22.06048608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMVR",
          "name": "Murga Mahadev Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.461904,
            17.426453000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSJ",
          "name": "HUSSAIN SAGAR J",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4760352539,
            25.272851221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRBG",
          "name": "Sarobag",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.62039399999999,
            16.529876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NWBV",
          "name": "NWBV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.87358352049999,
            21.837130552799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSPR",
          "name": "JASHAPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.74226717410001,
            26.4125623913
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "RUP",
          "name": "Rupahigaon",
          "zone": "NFR",
          "address": "Nagaon, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.8969253271,
            22.7738973454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RF",
          "name": "RAFALESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.187581,
            17.435563000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SGLA",
          "name": "SANGOLA",
          "zone": "CR",
          "address": "Sangole, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.673452,
            27.391523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSA",
          "name": "BHAINSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.179638,
            24.528647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNNR",
          "name": "BHINDAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.4650456,
            23.8426777354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOX",
          "name": "KACHUJOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.860507,
            17.727522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "INK",
          "name": "INTAKANNE",
          "zone": "SCR",
          "address": "Intikanne Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.57727100000001,
            17.974824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZJF",
          "name": "KAZIPET F CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9846195752,
            25.1482278323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDPT",
          "name": "Jagdishpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.069462,
            19.246247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UBCN",
          "name": "ULHAS BRIDGE CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.353587,
            28.235655
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RG",
          "name": "RAJGHAT NARORA",
          "zone": "NR",
          "address": "Babrala, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.731812,
            29.184058
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRDH",
          "name": "SARSADH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.6869809839,
            21.8234176248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IGRL",
          "name": "INGORALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.81431,
            24.464248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LGCE",
          "name": "LAGARGAWAN",
          "zone": "WCR",
          "address": "Lagargawan, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.52550400000001,
            24.273108999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "JDPR",
          "name": "Jagdishpur",
          "zone": "?",
          "address": "Giridih, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.94988,
            24.646721000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSM",
          "name": "HAUSNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.11095900000001,
            23.978724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "VST",
          "name": "BIJAYSOTA",
          "zone": "WCR",
          "address": "State Highway 10, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.094182,
            25.189878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKPA",
          "name": "SHRIKALYANPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.638091,
            8.430572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "DYS",
          "name": "DALAPATHY SAMUDRAM",
          "zone": "SR",
          "address": "Dhalapathisamudram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.65157199999999,
            29.311679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGNH",
          "name": "RAGHUNATHGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.991426,
            18.292228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "KVLS",
          "name": "Karakavalasa",
          "zone": "ECoR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.865129,
            30.871949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "SULH",
          "name": "Sulhani",
          "zone": "NR",
          "address": "Machhi Bugra, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.88480200000001,
            22.229374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "QCAB",
          "name": "QCAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.257363,
            24.23827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJLE",
          "name": "AZIMGANJ JN CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.16958199999999,
            25.303843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XCBN",
          "name": "Xcbn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.600455,
            20.014659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TWI",
          "name": "Totewahi Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.24465000000001,
            18.297688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "INP",
          "name": "INDAPUR",
          "zone": "KR",
          "address": "Indapur, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7612195679,
            13.2555864292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "INJ",
          "name": "INNANJE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.800122,
            27.449608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BJLP",
          "name": "BARAI JALALPUR",
          "zone": "NER",
          "address": "Sitapur National Highway 24, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.697715,
            10.626984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLGD",
          "name": "KOLLENGODE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.042923,
            19.188667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWJN",
          "name": "DIVA JN. CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7922887817,
            25.251877498200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRWH",
          "name": "BAHERWA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8692637848,
            28.4605176414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STKW",
          "name": "Sultanpur Kalia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.795395,
            21.328476000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "HCNR",
          "name": "Harichandanpur",
          "zone": "ECoR",
          "address": "Rd, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8423063,
            28.407739616900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMUE",
          "name": "Numaishgarh Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.797002,
            22.514232000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PTP",
          "name": "Patal Pani",
          "zone": "WR",
          "address": "Mhow, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4041,
            30.344248999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PTA",
          "name": "Patiala",
          "zone": "NR",
          "address": "Patiala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.836698,
            22.85663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "DRPH",
          "name": "Devri P.H.",
          "zone": "SECR",
          "address": "Kaladehi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.999618396,
            24.0683888436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLKT",
          "name": "Nalkata",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.234583,
            17.486717000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "PCZ",
          "name": "Pocharam",
          "zone": "SCR",
          "address": "Khammam To Yellanddu Rd, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.23393947000001,
            22.20374787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BSD",
          "name": "Basuldanga",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.64869900000001,
            10.786311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOM",
          "name": "Kodikkalpalaiym",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.667639,
            26.303308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TFGN",
          "name": "Tufanganj",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.451335,
            21.455554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWAD",
          "name": "Mowad",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.238213,
            16.964553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "COAC",
          "name": "Coac",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.445352,
            22.935794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPAW",
          "name": "Kanchrapara Workshop Gate",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.36512599999999,
            24.743292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NLBR",
          "name": "Nilambazar",
          "zone": "NFR",
          "address": "NH 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.075560105,
            24.695170582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KADL",
          "name": "Kumradol",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.946006,
            21.984896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKPR",
          "name": "Sikarpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.995446,
            21.326057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRSH",
          "name": "Morshi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.166445,
            31.597665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHTL",
          "name": "Churaru Takrala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.636363,
            22.734875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUNW",
          "name": "Surendra Nagar (W) Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.644706,
            22.753791000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUNE",
          "name": "Sune",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.30219143549999,
            25.4879748709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XGN",
          "name": "Gokulnagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9055848,
            31.5240737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "HSX",
          "name": "Hoshiarpur",
          "zone": "NR",
          "address": "Phagwara Road-Hoshiarpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.69025900000001,
            27.569887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSG",
          "name": "SITAPUR CITY (THOMASGANJ)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.30114461,
            29.11078373
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "GUB",
          "name": "GULARBHOJ",
          "zone": "NER",
          "address": "Gular Bhoj Railway Station, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0113177722,
            29.7715518226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LRNC",
          "name": "LAKSAR NORTH CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.085551,
            21.715431000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "LDU",
          "name": "Lunidhar",
          "zone": "WR",
          "address": "State Highway 104, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.732815,
            16.679401000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HOH",
          "name": "HONAGANAHALLI",
          "zone": "SWR",
          "address": "National Highway 218, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.79367,
            22.039641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SE",
          "name": "SEMLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.405133,
            11.933093999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TELI",
          "name": "TELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2001819324,
            9.8674013383
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "ILA",
          "name": "SILSIMAN",
          "zone": "SR",
          "address": "Silaiman Railway station, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.44955586180001,
            20.231473570600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMRB",
          "name": "MANMAD B CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.109242,
            28.604529
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSN",
          "name": "NASIRPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.4578628,
            16.3096804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REP",
          "name": "RESAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.34196899999999,
            26.45248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "COP",
          "name": "Kanpur M. G. Goods",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.656384,
            10.959358
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PEM",
          "name": "Peralam Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.5779045111,
            24.099500875799997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "UDR",
          "name": "AMDARA ROAD",
          "zone": "WCR",
          "address": "Amdara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.942883,
            27.029014999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SFR",
          "name": "SAFRAI",
          "zone": "NFR",
          "address": "Suffry Station Chariali, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9386234,
            23.6719137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BURN",
          "name": "BURNPUR",
          "zone": "SER",
          "address": "CHITRA CINEMA HALL MORE, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.46277400209999,
            23.841224628400003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQX",
          "name": "KACHUJOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.21490999999999,
            26.736799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RWTB",
          "name": "ROWTA BAGAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.01767731,
            26.85049224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAO",
          "name": "CHAURADANO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.256353,
            24.224697000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSNA",
          "name": "RASANA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.215756,
            26.74758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRNA",
          "name": "GURHANWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.34747200000001,
            27.209982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "PDPR",
          "name": "Purandarpur",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.35864099999999,
            26.805203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HJLI",
          "name": "HUGRAJULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.274766,
            24.21105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSO",
          "name": "Nashipur Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.865534,
            23.734566
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULTL",
          "name": "Kulti Link Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5990888062,
            14.9811881952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMDB",
          "name": "PAMIDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.08419099999999,
            19.203604
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DCTL",
          "name": "DOMBIVLI CTL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.13204400000001,
            13.124594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQZ",
          "name": "Kolar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.518905,
            15.477666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVTN",
          "name": "SHIVATHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.082136,
            21.003133000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMI",
          "name": "Gond Umri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.282876,
            26.01201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMGN",
          "name": "BAMUNI GAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.787953,
            16.105632000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BQU",
          "name": "Bhattiprolu",
          "zone": "SCR",
          "address": "Bhattiprolu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7887762634,
            13.3038846483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMGR",
          "name": "Chikkamanglur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7834807544,
            25.8968700235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BURA",
          "name": "Bahuara",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.563588,
            22.685772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BHKA",
          "name": "Bahira Kalibari",
          "zone": "ER",
          "address": "Bahira Kalibari Railway Station, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.152338,
            25.203127000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNRD",
          "name": "Genoli Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.651853,
            12.991200000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYPA",
          "name": "Baiyyappanahalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.042828,
            23.137395
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGDC",
          "name": "Viramgam D Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.276627,
            25.247947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URML",
          "name": "Upramal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.20809999999999,
            20.919626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEYT",
          "name": "Ntpc Exchange Yard Talcher",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.17854700000001,
            25.308243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRRI",
          "name": "Jrri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.21710499999999,
            21.987443000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NCPM",
          "name": "Nishchindapur Market Halt",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.408343,
            27.391265999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRSA",
          "name": "Barwa Kalan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.588278,
            27.52481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JWK",
          "name": "Jawaharpur Kamsan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.933497,
            22.246668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMZJ",
          "name": "Tmzj",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.94645,
            13.399832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HUN",
          "name": "Hunsenahalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2839236682,
            25.3817289022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DYW",
          "name": "Diyawan Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.944234,
            30.116782999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHB",
          "name": "Gahri Bhagi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.032106,
            27.476347999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JREA",
          "name": "Jhareda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.831803,
            30.799159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSL",
          "name": "JASSOWAL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.895843,
            27.299563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOWN",
          "name": "KHOWANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.73819999999999,
            25.775115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IDGH",
          "name": "INDARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.300793,
            29.200034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BROR",
          "name": "BERORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.649654,
            23.916311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWZ",
          "name": "MAHISHADAHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.89464732670001,
            26.6748122335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHAH",
          "name": "CHAILAHA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.07850599999999,
            20.467176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LHD",
          "name": "LOHOGAD",
          "zone": "SCR",
          "address": "Akola, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.213604,
            32.504286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jammu and Kashmir",
          "code": "GHGL",
          "name": "GHAGWAL",
          "zone": "NR",
          "address": "Vijaypur Jammu, Jammu and Kashmir"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.972274,
            23.227389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "ADTL",
          "name": "ADHARTAL",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.28054900000001,
            29.216155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SISN",
          "name": "SISAUNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.914081,
            24.169914000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSI",
          "name": "JASALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.014318,
            28.371336999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NWN",
          "name": "Nanwan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.764414,
            19.365201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRWA",
          "name": "PARWAKHURD HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.183119,
            26.771884
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWC",
          "name": "KUNDWA CHAINPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.79836399999999,
            25.114943999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "KSMB",
          "name": "KASTLA KASMABAD",
          "zone": "ECR",
          "address": "Kastla Kasmabad, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.452548,
            26.809213999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKJR",
          "name": "DHEKIAJILI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.110391,
            31.670727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AADR",
          "name": "Amb Andora",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.304041,
            28.719730000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "BHHZ",
          "name": "BEHTA HAZIPUR HALT",
          "zone": "NR",
          "address": "Behta Hazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63279,
            13.87259
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BYNR",
          "name": "MOOKAMBIKA ROAD",
          "zone": "KR",
          "address": "Baindur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.706617,
            25.250465000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KWH",
          "name": "KACHHWA ROAD",
          "zone": "NER",
          "address": "Kachhwa, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.431192,
            22.029943999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH4",
          "name": "IBH4",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.620084,
            22.019762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH5",
          "name": "IBH5",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.73791700000001,
            22.021243000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH6",
          "name": "IBH6",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.892472,
            22.016209999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH3",
          "name": "IBH3",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.955893,
            23.772498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "BRMO",
          "name": "BERMO",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.625911,
            21.861847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH1",
          "name": "IBH1",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.794136,
            21.87477
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBH2",
          "name": "IBH2",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.98842900000001,
            21.775184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHG",
          "name": "Ibhg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.91610235659999,
            18.4615191344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGHW",
          "name": "Nageshwadi Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.00444200000001,
            25.186362
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SISM",
          "name": "Singuli Shyam",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.776865,
            26.282385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSGR",
          "name": "Sisai Gulabrai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.031292,
            28.199841000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MWR",
          "name": "Mahansar",
          "zone": "NWR",
          "address": "Ramgarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.12555929080001,
            21.9661929569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGDR",
          "name": "Magardharra Ph",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.243784,
            28.647921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FGH",
          "name": "Fatehgarh Haryana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.517978,
            21.782515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DASF",
          "name": "Dhasa Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2213155725,
            25.1687228764
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAMR",
          "name": "Khurrampur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.568241,
            28.596912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SHTS",
          "name": "Sambhal Hatim Sarai",
          "zone": "NR",
          "address": "Chandausi, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.368894,
            27.972495
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BECN",
          "name": "Bikaner East",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.948477,
            11.144776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PKM",
          "name": "Periyanaikanpalayam",
          "zone": "SR",
          "address": "Periyanaickenpalayam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.815212,
            21.600830000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHE",
          "name": "Ibhe",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.20291900000001,
            26.142698000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRJK",
          "name": "Suraj Kunda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.89613299999999,
            21.682533000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHF",
          "name": "Ibhf",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.741998,
            21.38637
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHC",
          "name": "Ibhc",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8273760669,
            26.053610138099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKRH",
          "name": "Parsa Kerwan Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.288957,
            21.059452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TOR",
          "name": "Titur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.228415,
            26.721823999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TOI",
          "name": "Tamkuhi Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.06084100000001,
            20.862984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BFF",
          "name": "Barabhati",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.612747,
            27.516762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MROA",
          "name": "Mora",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.633302,
            14.288569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGDV",
          "name": "Soma Guddu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.73291400000001,
            22.218261000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KWB",
          "name": "Kaniwara",
          "zone": "SECR",
          "address": "Kanhiwada, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.47699312,
            22.08071726
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DJHR",
          "name": "Deojhar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.937159,
            25.89498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEPI",
          "name": "Deenapatti",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.384639,
            21.206629
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHA",
          "name": "Ibha",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.157907,
            17.429209999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKJB",
          "name": "Dornakal Bye Pass",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.81465299999999,
            25.070196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUYA",
          "name": "Jodhpuriya",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.139379776,
            26.8008621515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUE",
          "name": "Dhuri Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.69461,
            26.31374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKRH",
          "name": "Brij Kishor Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.84983299999999,
            26.416262999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "SMGR",
          "name": "Samaguri",
          "zone": "NFR",
          "address": "Samaguri, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.538085,
            26.466939
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BTDR",
          "name": "Batadrowa Road",
          "zone": "NFR",
          "address": "Mathaouri Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.153828,
            23.471537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RTJ",
          "name": "Rantej",
          "zone": "WR",
          "address": "State Highway 19, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.46403500000001,
            27.105025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRKG",
          "name": "NARKATIAGANJ JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.22648099999999,
            24.106842
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KGLE",
          "name": "KHAGRAGHAT ROAD",
          "zone": "ER",
          "address": "Khagraghat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.756723,
            16.823512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDK",
          "name": "BEDAG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.711657,
            27.299256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRK",
          "name": "PARKHAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5819742755,
            25.487150094300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWL",
          "name": "DHUWALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.533248,
            15.230476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYO",
          "name": "BAYALUVADDIGERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.79810400000001,
            30.375339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJMA",
          "name": "RAJOMAJRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.901334,
            19.86677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "LNT",
          "name": "LINGTI",
          "zone": "?",
          "address": "State Highway 234, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.38867400000001,
            24.363232999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYE",
          "name": "BANSI BOHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.67825500000001,
            22.930873000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPS",
          "name": "PIPILIYA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6671534143,
            17.9512834252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SQZ",
          "name": "VANCHANGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.21149321899999,
            30.3358409881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLMJ",
          "name": "Kalhe Majra",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5410748871,
            23.712696086799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMHT",
          "name": "KARMAHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65593299999999,
            10.980702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLMD",
          "name": "Kollumangudi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.17783,
            22.318357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDA",
          "name": "VADODARA C CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.11348526,
            26.52752005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAGA",
          "name": "Galgalia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.466008,
            25.847026
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAKD",
          "name": "NAIK DIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1127146875,
            21.1558479072
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSIH",
          "name": "Khodseoni",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.001342,
            10.653079
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POY",
          "name": "POLLACHI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2113217236,
            25.158611596700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRIH",
          "name": "Bairi Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.455842,
            25.121481000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDLK",
          "name": "SUNDLAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.74656300000001,
            28.796977000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "ISM",
          "name": "ISMAILA HARYANA",
          "zone": "NR",
          "address": "National Highway 10, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.981145,
            26.894084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPK",
          "name": "Pachpokharia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.768891,
            23.47934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYG",
          "name": "KIDIYANAGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0723825452,
            10.5893388627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VBU",
          "name": "VEMBUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6509052,
            10.8837044
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNM",
          "name": "Nannilam",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.642769,
            26.463321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYO",
          "name": "KHANDIKAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.228493,
            31.856906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BATM",
          "name": "Batala Sugar Mills",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.417826,
            24.859600999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HIS",
          "name": "Hisua",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.189646,
            21.930346999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLD",
          "name": "Lalavadi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.253954,
            25.947848999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IDS",
          "name": "Ishardaspur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.922584,
            23.037538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGE",
          "name": "Charghat Pipari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.300896,
            21.197156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLCB",
          "name": "Durg Link Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.35368317140001,
            24.9197820804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRSO",
          "name": "Sarsoo",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.809686,
            25.865541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGPI",
          "name": "Durgapuri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.86536472,
            13.39218973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IDT",
          "name": "Sidlaghatta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.01527999999999,
            26.122334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYQ",
          "name": "Bahai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.757499,
            23.173483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPBG",
          "name": "Sipra Bridge",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.650295,
            22.107567000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWO",
          "name": "Buramara",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9110166815,
            12.559346408400001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRNT",
          "name": "Periyanagathunai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.52010800000001,
            23.212087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IFFC",
          "name": "Iffco Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.129704,
            26.426933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKG",
          "name": "Thakurganj",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.384065,
            26.716817
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTRA",
          "name": "Matigara",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.923701,
            21.914681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRKH",
          "name": "Bisapur Kalan Halt",
          "zone": "SECR",
          "address": "State Highway 19, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.888897,
            30.782451000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUPR",
          "name": "Surajpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.593582,
            12.794145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HMB",
          "name": "Harebetta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.357082,
            26.527558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSU",
          "name": "Sasa Musa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.586249,
            20.279033000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGDI",
          "name": "Bagadia Ph",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.179429,
            26.310567000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POT",
          "name": "Pothia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.982606,
            26.897709199999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POU",
          "name": "Padrauna",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.03168600000001,
            26.756164000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HAN",
          "name": "HINDAUN CITY",
          "zone": "WCR",
          "address": "Karauli, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.07141899999999,
            32.079397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "MGRP",
          "name": "MEGH RAJ PURA",
          "zone": "NR",
          "address": "State Highway 22, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0528523303,
            22.0597389624
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPSA",
          "name": "Ghatparsia Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.92678000000001,
            26.054864000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GHAI",
          "name": "GHATIGAON",
          "zone": "WCR",
          "address": "Ghatigaon, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.58875599999999,
            23.067094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBIC",
          "name": "SABARMATI BRIDGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.686324,
            27.056054999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSX",
          "name": "DESANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.209986,
            23.604089
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUC",
          "name": "Sunak",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.642615,
            21.938836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKKD",
          "name": "CHARKHERA KHURD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.363817,
            9.386059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Kerala",
          "code": "AMPA",
          "name": "AMBALAPUZHA",
          "zone": "SR",
          "address": "ph 0477-2272620, Kerala"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.57027500000001,
            23.101488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBID",
          "name": "SABARMATI D CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0614295142,
            28.8992313011
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUI",
          "name": "Sui",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.636644,
            31.516333
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHWL",
          "name": "MACHROWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8612473071,
            30.0390118497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTLN",
          "name": "KHAT LABANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.463659,
            27.708763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "SUJH",
          "name": "Sujangarh",
          "zone": "NWR",
          "address": "Sujangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.057507,
            23.414967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NTDM",
          "name": "NITYANAND DHAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.231178,
            18.605282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TEK",
          "name": "Tekkali Halt",
          "zone": "ECoR",
          "address": "Tekkali, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.594991,
            26.486646999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLBS",
          "name": "SALSALA BARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.6653966,
            22.182670599999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTDF",
          "name": "BOTAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.56375499999999,
            23.083452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBIS",
          "name": "SABARMATI SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.146705,
            13.244119000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJT",
          "name": "Gannaghatta",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.703438,
            30.206149
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DQV",
          "name": "DAULA KOT BHAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1389845972,
            30.2258549701
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LHK",
          "name": "LAIHRA KHANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.171853,
            13.274642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNU",
          "name": "Dalsanur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3879062933,
            25.6720446969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKHT",
          "name": "GRAM KHARIKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.52847299999999,
            17.476299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "RKO",
          "name": "RAMAKRISHNAPURAM GATE",
          "zone": "SCR",
          "address": "Secunderabad, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.71680900000001,
            20.515625999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWG",
          "name": "Mindala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31954,
            28.673349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSAP",
          "name": "DELHI SHAHDARA A PANEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.143131,
            25.984617
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNCA",
          "name": "SONACHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.836714,
            26.90674
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKL",
          "name": "Ramkola",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.966758,
            29.922760999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKK",
          "name": "Ratangarh Kanakwal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.273103,
            26.692531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLGS",
          "name": "Duklangia",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7728427332,
            26.229288579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTLP",
          "name": "Katalpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.85767,
            27.32406
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SETB",
          "name": "Sessa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.135054,
            25.823021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "AUI",
          "name": "Aurahi",
          "zone": "ECR",
          "address": "Purnia, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.974542,
            29.043935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AUN",
          "name": "Aurang Nagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.95528499999999,
            30.008326
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNLS",
          "name": "Bangi Nihal Singh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.190689,
            26.016513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HAR",
          "name": "Hamirpur Road",
          "zone": "NCR",
          "address": "Hamirpur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.290406,
            19.009064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WTP",
          "name": "Water Pipe",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8337491992,
            25.9530212347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLHI",
          "name": "Shilhauri Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.342388,
            26.836122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RTG",
          "name": "Rangtong",
          "zone": "NFR",
          "address": "Hill Cart Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.908903,
            12.969667999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLLT",
          "name": "Ballupete",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.573582,
            26.373540000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTU",
          "name": "Ratan Sarai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.425807,
            28.453681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Haryana",
          "code": "SDRA",
          "name": "Sudharana",
          "zone": "NWR",
          "address": "Sudharana, Haryana"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.538335,
            16.543097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "BVRM",
          "name": "Bhimavaram Junction",
          "zone": "SCR",
          "address": "Bhimavaram, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.798221,
            21.426066
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Orissa",
          "code": "NKW",
          "name": "Nilakantheswar",
          "zone": "ECoR",
          "address": "Orissa, Orissa"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.858161,
            22.798847000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SKY",
          "name": "Shikara",
          "zone": "SECR",
          "address": "Shikara - Dhooma Rd, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.093936,
            25.5507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DJB",
          "name": "DIJAOBRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.60251865000001,
            15.59262851
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BLR",
          "name": "BALGANUR",
          "zone": "SWR",
          "address": "Gadag, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.48696237120001,
            27.0516270621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSHW",
          "name": "MUSHARWA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.89076200000001,
            25.228309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHIT",
          "name": "CHITHARI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.7400647568,
            22.8387443721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGP",
          "name": "BAJRANGPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7978789,
            23.1697663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BRGT",
          "name": "BHERAGHAT",
          "zone": "WCR",
          "address": "Bheraghat, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64132599999999,
            31.579622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWU",
          "name": "SANAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.588309,
            24.210039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "MHDA",
          "name": "MAHDEIYA",
          "zone": "ECR",
          "address": "Singrauli, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.3562287756,
            21.7849180856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGJ",
          "name": "BARGAON GUJAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.051031,
            23.383691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHWS",
          "name": "DHOSAWAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.686919,
            10.641667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDK",
          "name": "VADAKANNIKAPURM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.101024,
            23.808778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHNR",
          "name": "KACHNARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.77172300000001,
            10.626386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMDA",
          "name": "MUTHALAMADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.9335792004,
            22.7223787434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DV",
          "name": "DHUVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.957082,
            12.875341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FRG",
          "name": "Faringapete Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.276337,
            23.767566
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CASA",
          "name": "CHHANSARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.181545,
            23.759161000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SNLR",
          "name": "SANTALPUR",
          "zone": "WR",
          "address": "Santalpur, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.620232,
            23.380465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIA",
          "name": "SHIVLANKHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.971244,
            25.080389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNMR",
          "name": "Soniyana Mewar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.38875601560001,
            23.606146717799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSHM",
          "name": "MAHESANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.25971,
            27.416456999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SWW",
          "name": "Shahanagar Timarua",
          "zone": "NCR",
          "address": "Shahnagar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.89193900000001,
            26.948956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKV",
          "name": "NAKARDEI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.389062,
            26.678676999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMDL",
          "name": "MOHINI MANDAL(H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.72046680000001,
            26.526055630000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVR",
          "name": "GORESUAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.271,
            10.759868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRRB",
          "name": "SHORANUR B CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.840413,
            26.626143000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBY",
          "name": "KHOIRABARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.912165,
            22.237662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCAB",
          "name": "PCAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.912274,
            26.658355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNL",
          "name": "TANGLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.224585,
            27.382935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWT",
          "name": "Shivwala Tehu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.088256,
            21.145332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGPB",
          "name": "Nagpur (Se)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6601677197,
            12.4115632692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLHL",
          "name": "PALLAHALLI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.442721,
            26.682355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NJPS",
          "name": "New Jalpaiguri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.15025299999999,
            27.443262999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BARS",
          "name": "Barsana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.847166,
            22.691279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "LKMN",
          "name": "Lokmanya Nagar",
          "zone": "WR",
          "address": "Indore, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.38937,
            23.603326
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSHF",
          "name": "Mahesana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.68691899999999,
            24.958315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVTN",
          "name": "Thambawati Nagri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.326498,
            26.564981
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPYA",
          "name": "Sipaya",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.369793,
            26.17489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUBS",
          "name": "Jubbasahani",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.850571,
            22.096296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LSGS",
          "name": "Lavan Satyagraha Smarak P.H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9845895752,
            25.1553455368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPLB",
          "name": "Hatpuraini",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.493624,
            22.402193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "CHT",
          "name": "Champahati",
          "zone": "ER",
          "address": "Baruipur-Champahati Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.41562499999999,
            24.877385999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMDP",
          "name": "Mohamadpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.71037,
            14.674986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEHL",
          "name": "Nethranahalli H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.680149,
            22.342991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBS",
          "name": "Kusmuda Block Station",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.584174,
            12.755498000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVGL",
          "name": "Shrivagilu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.892616,
            17.448472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVP",
          "name": "Gate Vanampalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.791513,
            26.085299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOX",
          "name": "GOBINDPUR DUGLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.22272699999999,
            26.176561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KAKN",
          "name": "Kaimarkalan",
          "zone": "NCR",
          "address": "Kaimarkalan, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.77836099999999,
            26.994131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLV",
          "name": "Bhelwa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.887371,
            26.09571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLF",
          "name": "Balajan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.638201,
            21.727401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APRD",
          "name": "Ashapurna Devi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8986612061,
            13.327121199099999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNVH",
          "name": "Kanivehalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.85643400000001,
            27.117144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEPN",
          "name": "SEPON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.932199,
            23.156295999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HBG",
          "name": "HOWBAGH JABALPUR",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.059067,
            32.559965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHBT",
          "name": "BLOCK HUT BASANTPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59293500000001,
            19.167016
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMBL",
          "name": "JAMBHALI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.445955,
            26.631615
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNNH",
          "name": "TAPASWINARAYAN NAGAR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.52024899999999,
            17.428299000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STDB",
          "name": "SITAFALMANDI B",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.34081,
            11.966731999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AYD",
          "name": "AYANDUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.045323,
            23.040004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "AJKI",
          "name": "AJITKHERI",
          "zone": "WR",
          "address": "Devas, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.136269,
            17.265553999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSDG",
          "name": "MASOBA DONGARGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.925984,
            20.906867
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HXR",
          "name": "HANSAPORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.6476081836,
            16.5253522399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDUN",
          "name": "Madhura Nagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.306568,
            15.31821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDS",
          "name": "DUDH SAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.266275,
            24.459564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFN",
          "name": "KANOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.75366600000001,
            11.694558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CQS",
          "name": "CAPPER QUARRY",
          "zone": "SR",
          "address": "Cuddalore Township, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.929033,
            10.788547000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "AYN",
          "name": "AIYANAPURAM",
          "zone": "SR",
          "address": "Chinnakankeyampatti, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.871319,
            30.056984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FTH",
          "name": "FATUHI",
          "zone": "NR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5426915326,
            25.6487380699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDUA",
          "name": "BASUDEOPUR CHANDAIL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.854259,
            21.26585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLVR",
          "name": "Kolvihir",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.155355,
            15.348063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HBQ",
          "name": "HUBLI EAST CAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.672422,
            24.082494
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MITA",
          "name": "MITHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2170497089,
            26.8333020399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNWS",
          "name": "BHANWASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.908462,
            23.533540000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUBR",
          "name": "BHUTAKIA BHIMSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.775574,
            26.540366000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GEK",
          "name": "GEGAL AKHRI",
          "zone": "NWR",
          "address": "Kishangarh, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.345001,
            29.505405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SLWR",
          "name": "SILAWAR",
          "zone": "NR",
          "address": "State Highway 57, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.888267,
            21.809025000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LJKR",
          "name": "LAJKURA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.307379,
            22.42498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MDN",
          "name": "MIDNAPORE",
          "zone": "SER",
          "address": "Medinipur, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.748784,
            23.482582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MYX",
          "name": "METYAL SOHAR",
          "zone": "SER",
          "address": "West Bengal, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.052288244,
            24.2769136737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGF",
          "name": "JOGIDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.73642199999999,
            23.859295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "PWK",
          "name": "PIPARIYA KALAN",
          "zone": "WCR",
          "address": "State Highway 11, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.05598099999999,
            30.889186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KMTI",
          "name": "Kumarhatti",
          "zone": "NR",
          "address": "Kumarhatti, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.145074025,
            28.6469294838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "KRTN",
          "name": "KIRTI NAGAR",
          "zone": "NR",
          "address": "Kirti Nagar, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19753187,
            9.537360695
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TCH",
          "name": "Tiruchchuli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.69287100000001,
            23.492772000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADRE",
          "name": "ADRA EAST CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.764732,
            28.484266
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLSP",
          "name": "Kushal Pura",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8493670618,
            25.995645525199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TERH",
          "name": "Terha Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.306841,
            27.154414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LVR",
          "name": "Lokvidyapith Nagar",
          "zone": "NER",
          "address": "National Highway 29, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.14744900000001,
            18.018465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LVK",
          "name": "Lakkavarapukota",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.311942,
            26.586308000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGP",
          "name": "Jalalpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.159229,
            21.141189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNWD",
          "name": "Bhandewadi Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.79556600000001,
            21.974916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HEN",
          "name": "Heria Ph",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.83489,
            22.879170000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KDHI",
          "name": "Kaladehi",
          "zone": "SECR",
          "address": "National Highway 7, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.156976,
            25.296734999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ICBN",
          "name": "Icbn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.43383,
            22.024168000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBCN",
          "name": "Joda Block Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.124798,
            26.484618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPTN",
          "name": "Piprithan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.683653,
            21.677859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKLI",
          "name": "Sukli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2261571069,
            25.1913324416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANGM",
          "name": "Aungari Dham",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.734128,
            21.788742999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KATI",
          "name": "Kanthi P Halt",
          "zone": "?",
          "address": "Kanthi, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.54142999999999,
            9.880423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NTS",
          "name": "Nattrasankottai",
          "zone": "SR",
          "address": "Madurai - Thondi Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.45245,
            28.594924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDHN",
          "name": "Kandharan Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5683413623,
            28.5476822027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWBN",
          "name": "Bewar Bhojan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8211345959,
            26.069449321900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNDM",
          "name": "Gunrajpur Dhama",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.412296,
            29.322476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBKN",
          "name": "Khabra Kalan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.929025,
            25.427702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRKM",
          "name": "Khara-Kameri",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.65228900000001,
            29.342839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JES",
          "name": "Jaitsar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.739109,
            27.850344000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGG",
          "name": "Gangagarh Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.102087,
            23.687953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDLM",
          "name": "MUNDALARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.3984808771,
            25.149062471199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "KTDD",
          "name": "KATAIYA DANDI",
          "zone": "NCR",
          "address": "Kataiya, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6115177704,
            23.9163057543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNRI",
          "name": "KUNURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.06853600000001,
            24.570580999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHW",
          "name": "KHERODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.67795462,
            25.860076969999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "AJIT",
          "name": "AJIT",
          "zone": "NWR",
          "address": "State Highway 68, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.46359199999999,
            23.640520000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKAC",
          "name": "BARKAKANA AVOIDING CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.421027,
            32.110941000000004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "CMMG",
          "name": "CHAMUNDA MARG",
          "zone": "NR",
          "address": "Chamunda Marg, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.27608000000001,
            18.997761
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "X108",
          "name": "Aman Lodge",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.932732,
            32.215731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "BLDL",
          "name": "BALLE-DA-PIR-LA",
          "zone": "NR",
          "address": "Balle da pir larath, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.214928,
            22.199323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GURN",
          "name": "Gurudas Nagar",
          "zone": "ER",
          "address": "Diamond Harbour Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.7894786566,
            21.748110299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "SOA",
          "name": "SANOSRA",
          "zone": "WR",
          "address": "State Highway 25, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.278043,
            29.079065999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGRR",
          "name": "BAGARPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.61843900000001,
            30.942486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "FZR",
          "name": "FIROZPUR CANT",
          "zone": "NR",
          "address": "Firozpur, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.17674000000001,
            25.750176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LMGF",
          "name": "LUMDING JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.686507,
            26.464294
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDJN",
          "name": "Madar Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.408316,
            9.3702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TZH",
          "name": "TAKAZHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.85641199999999,
            19.228739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BVI",
          "name": "BORIVALI",
          "zone": "WR",
          "address": "Mumbai, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1235368176,
            12.849960168399999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNTR",
          "name": "PENNATHUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.13394,
            23.72788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GM",
          "name": "GARMADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.622949,
            11.784898
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MBU",
          "name": "MELPATTAM BAKKAM",
          "zone": "SR",
          "address": "Pattampakkam, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.9756196179,
            22.879185725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BANR",
          "name": "Bhaner",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.753441,
            11.748845000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "CUD",
          "name": "TIRUPPAPPULIYUR",
          "zone": "SR",
          "address": "Cuddalore, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.448864,
            25.562644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWBR",
          "name": "KATWAR BAZAAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.0880662097,
            12.6253503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NXH",
          "name": "NIDAGHATTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.246307,
            24.173693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "LCAE",
          "name": "LALBAGH COURT ROAD",
          "zone": "ER",
          "address": "Lalbagh Court Road, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.763075,
            26.681165999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKGN",
          "name": "DEKARGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.19442228000001,
            22.49283248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBGB",
          "name": "Komagata Maru Budge Budge",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5655589,
            13.98866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BTJL",
          "name": "BHATKAL",
          "zone": "KR",
          "address": "Bhatkal, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.329724,
            23.242082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGRA",
          "name": "BAGHNAPARA",
          "zone": "ER",
          "address": "Kalna-Memari Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.451972,
            22.138132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGTD",
          "name": "MEGHPUR TITODI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.163576,
            25.757118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LMGS",
          "name": "LUMDING SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.42154342159999,
            30.361156358899997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMWP",
          "name": "Dmw Patiala Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.2896485193,
            22.4068330073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CSY",
          "name": "COSSYE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.084478,
            25.573305
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DDNA",
          "name": "Dudhaunda",
          "zone": "NER",
          "address": "Aunrihar, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.523178,
            29.45876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNGR",
          "name": "Mohan Nagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.927742,
            26.875644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DALF",
          "name": "DALI GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.780127,
            14.527464
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMPS",
          "name": "Kumara Patnam Bg Sdg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8671171741,
            23.1138506265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MABG",
          "name": "MATANA BUZURG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.940934,
            26.037543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "IKD",
          "name": "Ikdori",
          "zone": "NCR",
          "address": "State Highway 2, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.755456,
            22.877384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BLDK",
          "name": "Balauda Takun",
          "zone": "WR",
          "address": "State Highway 27, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6057220984,
            25.4375440848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRMP",
          "name": "Kumaripur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.1867607922,
            26.7699800293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GSRM",
          "name": "Gauri Sriram",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.53283,
            16.549141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBRA",
          "name": "Bbra",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.946083,
            19.874057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNRD",
          "name": "Junagarh Road",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.948672,
            22.221674
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KSBP",
          "name": "Keshabpur",
          "zone": "SER",
          "address": "Haldia Mechada via Tamluk, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.62385717000001,
            28.96314483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVZ",
          "name": "Naloi Barwa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.342247,
            25.286385000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNRI",
          "name": "Jalandari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.268569,
            11.153243999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "TDPM",
          "name": "Todikkapulam Halt",
          "zone": "SR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.860374,
            22.343556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHR",
          "name": "Khairee Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.610161,
            24.561850000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LLBR",
          "name": "Lalabazar",
          "zone": "?",
          "address": "Kalachera Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8728203638,
            17.4835646427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "SSPD",
          "name": "Sadashivapet Road",
          "zone": "SCR",
          "address": "Sadashivapet, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.13556886469999,
            30.212074913600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CWDA",
          "name": "Chuhriwala Dhanna",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.894329,
            12.874231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PADL",
          "name": "Padil",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8886623096,
            24.413060577899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMKL",
          "name": "JAMUNIA KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.50216300000001,
            26.815895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PJPI",
          "name": "PRAJAPATI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.775752,
            16.585003999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGIH",
          "name": "KALGURKI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.112494,
            18.175095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDVR",
          "name": "Bodavara",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0334555469,
            25.4497699558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEHT",
          "name": "BIHAT HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.469303,
            15.431087999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNKP",
          "name": "KONDIKOPPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4222417542,
            29.6609552552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRPM",
          "name": "MATHURAPUR MOR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.912753,
            20.613113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCBG",
          "name": "Chicholi Buzurg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0966802881,
            25.4350086438
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHHT",
          "name": "SINGHAUL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.202726,
            12.672036
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDPT",
          "name": "SEDARAMPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.941759,
            26.924721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADX",
          "name": "ADAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.734685,
            26.709021999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKTB",
          "name": "BINDUKURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.832038,
            19.406761
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDBR",
          "name": "KHADKI BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2874685571,
            25.421986000100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHWN",
          "name": "Dhaniyawan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.245042,
            28.04419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STNR",
          "name": "Sitalnagar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.43738,
            26.83822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMK",
          "name": "GULMA KHOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.45849,
            24.861017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NTWL",
          "name": "Netawal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.38246,
            26.488339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKBR",
          "name": "Narkatia Bazar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.714894,
            22.902131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRL",
          "name": "BARWALA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.26505300000001,
            22.325255000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPTY",
          "name": "NIMPURA THROUGH YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0477742334,
            24.3548979309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMX",
          "name": "GURMURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.6355093567,
            13.0578558367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THSA",
          "name": "THANISANDRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.58456,
            25.27073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOG",
          "name": "GHORGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.88113670000001,
            12.866703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNKD",
          "name": "Kankanadi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.707766,
            25.854971000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Nagaland",
          "code": "RXR",
          "name": "RANGAPAHAR",
          "zone": "NFR",
          "address": "Bridge, Nagaland"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.988313,
            24.446331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OBAC",
          "name": "OBRA-A-CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.58559399999999,
            23.84693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "RDHP",
          "name": "RADHANPUR",
          "zone": "WR",
          "address": "Patan, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.885648,
            23.745854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STNC",
          "name": "Sitarampur Link Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.60945677000001,
            31.9834478985
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSBR",
          "name": "MUSAHIBPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.632658,
            31.459179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSPD",
          "name": "BIASPIND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0939686646,
            26.8285100187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHAF",
          "name": "Chaf Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.10053242309999,
            27.9655265319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBK",
          "name": "Darbari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.057491,
            21.846442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHH",
          "name": "Ibhh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.401679,
            23.521871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDNM",
          "name": "JAGUDAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.763465,
            21.443752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IBHD",
          "name": "Ibhd",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.45228599999999,
            22.226102
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "DBT",
          "name": "Dakshin Barasat",
          "zone": "ER",
          "address": "Barasat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.471105,
            23.326449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JULF",
          "name": "Jhulasan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.15839799999999,
            19.93393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWIP",
          "name": "Bhawanipatna",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.838015,
            20.369711
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJR",
          "name": "Kalajhari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.401879,
            23.522071
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDNF",
          "name": "Jagudan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.396587,
            27.481951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEHJ",
          "name": "Behj",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.33018200000001,
            19.990686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRCP",
          "name": "G. Ramchandrapur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.576076,
            28.545807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLKN",
          "name": "Gugalwa Kirtan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.28544676759999,
            14.017783668700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCLA",
          "name": "Kenchanalu Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.71098052250001,
            25.339562331299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNPR",
          "name": "Tejnarayanpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.497006,
            21.450802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "TORA",
          "name": "Torniya",
          "zone": "WR",
          "address": "Torniya, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8417412,
            20.7843263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "BRVR",
          "name": "Borvihir",
          "zone": "CR",
          "address": "Dhule, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.010141,
            22.560295999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "TPNI",
          "name": "Tuiya Pani",
          "zone": "SECR",
          "address": "Pindrai, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.648304,
            10.817963
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRK",
          "name": "Virkudi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.579542,
            20.712948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHRH",
          "name": "Bhuyar Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.75710400000001,
            26.941536
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTBG",
          "name": "Matia Barghat Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.63844900000001,
            24.792781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMPC",
          "name": "Shambhupura C Cabin",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.31352000000001,
            17.495052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GHPU",
          "name": "Gandhipuram Halt",
          "zone": "SCR",
          "address": "Gandhi Nagar, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.672822,
            21.774098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STLB",
          "name": "Sitalpur Bengal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.11853982,
            22.8311582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "HPL",
          "name": "Haripal",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.71074,
            21.020729000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WLGN",
          "name": "Walgaon",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.16988226000001,
            26.57689211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADQ",
          "name": "Adhikari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.951951,
            22.859111000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKCH",
          "name": "DAKACHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.12441763240001,
            12.809316794699999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNB",
          "name": "KANIYAMBADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.12035800000001,
            26.130622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGON",
          "name": "NAUGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.891216,
            16.132576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLCA",
          "name": "VELLALCHERUVU H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.552627,
            18.130093
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "SEI",
          "name": "SHENDRI",
          "zone": "CR",
          "address": "State Highway 77, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.432679,
            25.068848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHDH",
          "name": "Karahdih Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.264117,
            16.701278000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRMT",
          "name": "GUR MARKET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.33435399999999,
            19.1674
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTDI",
          "name": "MALTEKDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.26630999999999,
            22.952676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GPC",
          "name": "GHATPINDRAI",
          "zone": "WCR",
          "address": "Ghatpindra, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.970286,
            25.875223000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "VPO",
          "name": "BHUPIA MAU",
          "zone": "NR",
          "address": "Dandupur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.180007,
            21.449662999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNOD",
          "name": "Benoda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.8439823962,
            27.004637018300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "LXA",
          "name": "LAKSWA",
          "zone": "NFR",
          "address": "Kukurachowa Gaon Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.256817,
            13.108535
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPY",
          "name": "VYASARPADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.18245499999999,
            30.824281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMRL",
          "name": "SAMRALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75944219360001,
            22.6063459486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ITMA",
          "name": "ITARSI MARSHALING YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.246686,
            22.057632
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHUA",
          "name": "Chourai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.99009799999999,
            29.767847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCV",
          "name": "Sukhchain",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4572240295,
            17.732096310200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUCH",
          "name": "KUCHAVARAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1618431097,
            19.476361933099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUNX",
          "name": "JUNONA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.431257,
            23.682542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBQ",
          "name": "Pathardih Bazar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.79082100000001,
            19.624601000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "KLV",
          "name": "KELVA ROAD",
          "zone": "WR",
          "address": "Thane, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.660307,
            24.065548000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KNC",
          "name": "KANCHANPUR ROAD",
          "zone": "WCR",
          "address": "Jabalpur, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.91798399999999,
            26.859254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LCNC",
          "name": "LUCKNOW CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.543267,
            23.094443000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLDB",
          "name": "CHANDLODIYA (B)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.501037,
            21.681178
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRWN",
          "name": "Jirwan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.921021,
            20.920848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNST",
          "name": "GANDHI SMRITI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.54803399999999,
            23.156344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHDB",
          "name": "KHODIYAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.29008089999999,
            17.7223773
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WAT",
          "name": "WALTAIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.8529670435,
            25.9816211386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMGA",
          "name": "AMJONGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.119827,
            18.842284
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JITE",
          "name": "JITE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.332987,
            21.098924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUWA",
          "name": "Pauwara Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.292251,
            26.618891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPV",
          "name": "Tinpheria",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.716415,
            23.242661000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MADP",
          "name": "MADHAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.23955500000001,
            24.815024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EC28",
          "name": "PURA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.517436,
            22.142795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "HRG",
          "name": "Hirdagarh",
          "zone": "CR",
          "address": "Dongar Parasia, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.45140099999999,
            22.048253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MADM",
          "name": "Matradham Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.80252499999999,
            21.658885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SASR",
          "name": "Sausar",
          "zone": "SECR",
          "address": "Sausar, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.695652,
            22.013657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUCE",
          "name": "Kuchai",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.552115,
            22.367665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GOF",
          "name": "Ghutiari Sharif",
          "zone": "ER",
          "address": "Ghutiari Shariff, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.2058883679,
            30.185034097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJMA",
          "name": "Burj Mohar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.175047,
            26.606703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTSI",
          "name": "Batasi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.1484284265,
            23.2982849059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNU",
          "name": "Kanjh",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.781443,
            23.178193999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UJJN",
          "name": "Ujjain Jn",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.125978,
            12.816740000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRF",
          "name": "Neralakatte Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.362376,
            26.023661999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWMD",
          "name": "Sawai Madhopur D",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.99825916260001,
            25.0382570525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BELT",
          "name": "Bela Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.639066,
            16.548406999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZA",
          "name": "Gurujalas",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.50699749019999,
            24.7313106127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRRI",
          "name": "Birari",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.08413800000001,
            26.052652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRRZ",
          "name": "Barara Buzurg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.481349,
            26.317241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPGR",
          "name": "Benipurgram Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.812967,
            29.355217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHWL",
          "name": "SAHUWALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.68692399999999,
            26.776378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRSN",
          "name": "PARSA NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0436230264,
            29.8079295893
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "ATMO",
          "name": "AITHAL",
          "zone": "NR",
          "address": "Haridwar, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.924993,
            16.966118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "AGDL",
          "name": "AGRAN DHULGAON",
          "zone": "CR",
          "address": "Jath Rd, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2479039038,
            25.1919497817
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAHT",
          "name": "Ghusia Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.22381373900001,
            11.976052306
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRK",
          "name": "TIRUKOILUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.392141,
            21.574033
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXA",
          "name": "SAGPHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.591872,
            25.362076000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSRI",
          "name": "RAISHREE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.45304499999999,
            23.739452999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JO",
          "name": "JO JAGABOR",
          "zone": "NWR",
          "address": "Jo Jagabor, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.21232632290001,
            23.6493523213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXD",
          "name": "SIDULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.06155700000001,
            25.171242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "MGE",
          "name": "MIGRENDISA",
          "zone": "NFR",
          "address": "Migrendisa, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3421818591,
            25.6820902631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHBI",
          "name": "DHABAULI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.71007999999999,
            24.837109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXO",
          "name": "SRIKONA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.846763,
            10.687498999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLNK",
          "name": "VELANKANNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.671001,
            19.268289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBU",
          "name": "THARBAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.766204,
            23.807394000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "",
          "code": "DMBR",
          "name": "DUMRI BIHAR",
          "zone": "ECR",
          "address": ""
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.04112500000001,
            24.517687000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "AHN",
          "name": "AHIRAN",
          "zone": "ER",
          "address": "National Highway 34, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.205614,
            24.039636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SGAM",
          "name": "SAREIGRAM",
          "zone": "WCR",
          "address": "Sidhi, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.239275033,
            25.2547478737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPWN",
          "name": "Rambhawan Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.188311,
            23.909674000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZPE",
          "name": "KAZIPARA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.242284,
            26.687630000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTA",
          "name": "Hatighisa",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.908742,
            15.454853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGV",
          "name": "NAGLAVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.17146,
            23.853212000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MIAN",
          "name": "MIANGRAM",
          "zone": "ER",
          "address": "Bazarsau, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.861414,
            11.173276999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNY",
          "name": "KOLANALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.833638,
            9.088507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPM",
          "name": "KUMARAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.90022099999999,
            25.230055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGAC",
          "name": "SOGARIYA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8777,
            12.777131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PYV",
          "name": "PAZHAYA SEEVARAM",
          "zone": "SR",
          "address": "Palayasivaram, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.004024,
            26.863246999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNM",
          "name": "CHENGMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.792236,
            26.181703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGC",
          "name": "NEW GUWAHATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.27862400000001,
            28.926420999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "AHQ",
          "name": "AHERA HALT",
          "zone": "NR",
          "address": "Tatiri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.66986,
            16.799851999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "STVA",
          "name": "SATYAVADA",
          "zone": "SCR",
          "address": "Coastal Andhra Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8987694873,
            18.5259235991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GIT",
          "name": "GHORPURI TRANSH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.270833,
            26.653609000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRJ",
          "name": "Tariasujan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7465317596,
            10.4855987634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ODC",
          "name": "Oddam Hatram",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.9075993964,
            25.276911474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "HDT",
          "name": "HARDATTPUR",
          "zone": "NER",
          "address": "National Highway 2, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.448153,
            25.643573999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "RDP",
          "name": "Radhikapur",
          "zone": "NFR",
          "address": "State Highway 10A, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.852085,
            26.709463000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPH",
          "name": "Nurpura",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.700558,
            20.580232
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NABN",
          "name": "Nagbhir Jn (Ng)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.54376733149999,
            25.4317780874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRRH",
          "name": "Kharuara Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.176723,
            26.083606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DXU",
          "name": "Duhru",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.409001,
            18.934852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Chhattisgarh",
          "code": "GIZ",
          "name": "Gidam",
          "zone": "ECoR",
          "address": "National Highway 16, Chhattisgarh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9862695435,
            25.1634535646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "GIF",
          "name": "Gani Dham Halt",
          "zone": "ER",
          "address": "Gani Dham, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.2768154944,
            26.975829172700003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVKR",
          "name": "Mahawan Khor Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.308384,
            19.004154000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTT",
          "name": "Jumapatti",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.29427,
            23.459001999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTN",
          "name": "Jotana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37271299999999,
            22.242111
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "MGT",
          "name": "Magra Hat",
          "zone": "ER",
          "address": "Magra Hat, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6498049,
            11.024209599999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNX",
          "name": "Manganallur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.70660500000001,
            16.534799
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTPR",
          "name": "Chinta Parru Ha",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.721428,
            22.658005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "KMZA",
          "name": "Kankra Mirzanagar",
          "zone": "ER",
          "address": "Mirzanagar, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.41345000000001,
            20.801363000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRGN",
          "name": "Kargaon Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.354883,
            21.493095999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUSA",
          "name": "Pusla",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05456539999999,
            13.410991099999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMY",
          "name": "Chintamani",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.28453529789999,
            25.4095173407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGYW",
          "name": "Singriawan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1667579382,
            28.7502173348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DZL",
          "name": "Dhana Ladanpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.726457,
            21.294083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAHD",
          "name": "Mohadi Praganel",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.935071,
            10.624894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANM",
          "name": "ANAIMALAI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1470127649,
            12.1180073562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNI",
          "name": "TANDARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.921285,
            26.538089
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGAR",
          "name": "BANGARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.613163,
            26.399047
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "TKRP",
          "name": "Tikauli Rawatpur",
          "zone": "NR",
          "address": "Tikauli Rawatpur Railway Station, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.354461,
            15.434894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NVD",
          "name": "NAVALGUND RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.843318,
            24.876545999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "LUSA",
          "name": "LUSA",
          "zone": "NCR",
          "address": "State Highway 5, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.707775,
            21.963965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "CAER",
          "name": "CHHANERA",
          "zone": "WCR",
          "address": "Chhanera, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.52116372309999,
            29.742927896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "KTW",
          "name": "KOTDWARA",
          "zone": "NR",
          "address": "Kotdwara, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.34824731079999,
            26.1401115488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNGM",
          "name": "BANGURGRAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.65329374689999,
            26.3236717828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAC",
          "name": "LACHHIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.06203632569999,
            25.0437938949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMHT",
          "name": "Baraw More",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.055869,
            25.270917999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RNRD",
          "name": "RANIPUR ROAD",
          "zone": "NCR",
          "address": "Mau Ranipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.726017,
            21.123835999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIRL",
          "name": "Shirala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            69.84803099999999,
            21.764673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRSR",
          "name": "TARSAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.028821,
            23.597666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAA",
          "name": "LAKHPAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.10891500000001,
            30.822828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKX",
          "name": "Ghai Kalan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.191891,
            21.389118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKT",
          "name": "Gankhera Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            95.23363789119999,
            27.143953401599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BRNR",
          "name": "BARUANAGAR",
          "zone": "NFR",
          "address": "Moran to Naharkatiya Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.459554,
            17.049464999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANQ",
          "name": "AMNAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.75614999999999,
            12.824069
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NTT",
          "name": "NATHAPETTAI",
          "zone": "SR",
          "address": "Nathapettai - Kaliyanoor Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.89166900000001,
            26.948686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKU",
          "name": "NAKARDEI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.162249,
            26.790374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RRNH",
          "name": "RAMRUPNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.051876,
            13.120184
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRWS",
          "name": "PATTABIRAM WEST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.071068,
            22.851949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BAHW",
          "name": "Bahir Khanda",
          "zone": "ER",
          "address": "State Highway 2, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.38734198969999,
            29.5622491051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HHP",
          "name": "HARHAR FATEHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.273451449,
            26.447388357799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRKR",
          "name": "Salar Khurd",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.38511899999999,
            23.759404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UAR",
          "name": "UNAWA AITHOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3686992383,
            8.1946448621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "VRLR",
          "name": "VIRANI ALUR",
          "zone": "SR",
          "address": "Kanyakumari, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.067716,
            21.40605
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HKNT",
          "name": "Hiwarkheda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.655091,
            27.607734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZKA",
          "name": "Gzka",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.370599,
            20.913261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRLF",
          "name": "Veraval Mg",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.59038699999999,
            29.399942999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSWA",
          "name": "Masaniwala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.304203,
            22.053903000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KPDH",
          "name": "Kapurdha Halt",
          "zone": "SECR",
          "address": "SH 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.95205,
            30.040498999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKBH",
          "name": "Manwala Kot Bakhtu",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.25524899999999,
            28.313585999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRKA",
          "name": "Gurha Kemla",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.211777,
            24.248003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "GSGB",
          "name": "Gosaingram",
          "zone": "ER",
          "address": "Murshidabad District, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.73407,
            21.95074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VZR",
          "name": "Bhanjpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.542513,
            21.649222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAKL",
          "name": "Takal",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.515932,
            26.385382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRSD",
          "name": "New Runnisaidpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7325398,
            13.4313153
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "CBP",
          "name": "Chik Ballapur",
          "zone": "SWR",
          "address": "Chik Ballapur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.757001,
            29.956914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRUR",
          "name": "Prithwirajpur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.33712799999999,
            9.607657999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKK",
          "name": "Narikkudi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.633124,
            29.189764
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKN",
          "name": "Nyoli Kalan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9944876282,
            24.9936652146
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPDI",
          "name": "Pipradih",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9666857568,
            13.4826117961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLHI",
          "name": "Bisalehalli",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.48848000000001,
            14.209931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSHI",
          "name": "Doddasiddavanha",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.099097,
            29.524297999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJKN",
          "name": "Bajekan",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.13389600000001,
            13.374454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTT",
          "name": "Doddanatta Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.24124800000001,
            24.433621
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "CBZ",
          "name": "Churaibari",
          "zone": "NFR",
          "address": "Churaibari, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.053936,
            14.268888
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVA",
          "name": "Malaka Vemala",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.92244020000001,
            24.7524306
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIML",
          "name": "Bhimal",
          "zone": "NWR",
          "address": "Rajsamand, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.315031,
            21.033307999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZB",
          "name": "Mahuli Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.276164,
            21.458720999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WOC",
          "name": "Warud Orange City",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3684319092,
            25.4274073054
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SANH",
          "name": "SANHA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.535718,
            23.179203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IFCO",
          "name": "IFFCO CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.26256500000001,
            32.085157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Himachal Pradesh",
          "code": "KGRA",
          "name": "KANGRA",
          "zone": "NR",
          "address": "Kangra, Himachal Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.01907800000001,
            20.109731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJKN",
          "name": "MAJRI KHADAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5990446368,
            14.980726541100001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMD",
          "name": "PAMIDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.045744,
            29.327364
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "PRM",
          "name": "PIRUMADARA",
          "zone": "NER",
          "address": "Nainital District, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.790691,
            10.753121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SKK",
          "name": "SIKKAL",
          "zone": "SR",
          "address": "Sikkal, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.369788,
            28.295969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSC",
          "name": "RAMGANGA SOUTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.77248,
            19.698038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "PLG",
          "name": "PALGHAR",
          "zone": "WR",
          "address": "Palghar, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.13253484500001,
            19.286454997699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPLC",
          "name": "PIMPLA CHAURE HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.335819,
            23.365861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLNT",
          "name": "KALINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.18727200000001,
            25.54466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ARJ",
          "name": "AUNRIHAR JN",
          "zone": "NER",
          "address": "Ghazipur, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.108515,
            10.93648
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNDN",
          "name": "THANDONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.290171,
            27.099589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MBT",
          "name": "MARWAR BIRTHI",
          "zone": "NWR",
          "address": "National Highway 15, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.530851,
            23.817141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLE",
          "name": "PIPLEE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.924583,
            21.724542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTSK",
          "name": "MOTA SURKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.841953,
            8.862518999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NRK",
          "name": "NARAIKKINAR",
          "zone": "SR",
          "address": "Palayamkottai - Kolathur - Nagalapuram - Aruppukottai Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.6375275745,
            31.8706261437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPW",
          "name": "GHAUNSPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.74395299999999,
            30.006625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "PTRL",
          "name": "PATHRALA",
          "zone": "NWR",
          "address": "National Highway 64, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4345602,
            23.675524600000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Jharkhand",
          "code": "PEH",
          "name": "Pathardih Junction",
          "zone": "ECR",
          "address": "Jairampur More to Baliapur, Jharkhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.376438,
            20.295401
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIN",
          "name": "Nimkana",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.396612,
            27.361006
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIT",
          "name": "Naikot",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27791552000001,
            25.336743948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAMA",
          "name": "Kamta Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.927872196,
            22.1909239649
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "NDKR",
          "name": "Nandakumar P.H.",
          "zone": "?",
          "address": "Haldia Mechada via Tamluk, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.651361,
            21.474117
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNU",
          "name": "Ratnapur",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0778607251,
            26.3484669242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BMZ",
          "name": "Bamour Gaon",
          "zone": "NCR",
          "address": "National Highway 3, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.457351,
            24.857347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "BXG",
          "name": "Bhanga",
          "zone": "NFR",
          "address": "National Highway 44, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.32445458000001,
            26.697770440000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BORA",
          "name": "Bagdogra",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.05975199999999,
            13.136714000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTMS",
          "name": "Pattabiram Military",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3397643762,
            27.5318304825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LHNR",
          "name": "Lakshmanpur Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27902379519999,
            25.3541150645
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LHNA",
          "name": "Lohanda",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.667563,
            11.628136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLNC",
          "name": "Kullanchavadi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.60155400000001,
            15.283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGDI",
          "name": "Pagidirai Halt",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.13597700000001,
            26.021201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "JPD",
          "name": "Jalalpur Dhai",
          "zone": "?",
          "address": "State Highway 38, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.11856523189999,
            14.150947457100001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGV",
          "name": "Balegundi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.501362,
            22.130678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGY",
          "name": "Bangriposi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.94987,
            19.038845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMEZ",
          "name": "Kumar Maranga",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.653205,
            27.995122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLSR",
          "name": "Belasar",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.92847693120001,
            13.430423881200001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHYP",
          "name": "Sakarayapatna",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.895623,
            23.924910999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tripura",
          "code": "JWNR",
          "name": "Jawaharnagar",
          "zone": "NFR",
          "address": "National Highway 44, Tripura"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.64224200000001,
            22.125193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJLK",
          "name": "Rajalukah",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.739883,
            25.669354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "MRWS",
          "name": "Marwar Ranawas",
          "zone": "NWR",
          "address": "State Highway 61, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.853025,
            24.459872999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "NMH",
          "name": "NIMACH",
          "zone": "WR",
          "address": "MDR 11A, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.830108,
            27.33652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SUIA",
          "name": "SURAINCHA",
          "zone": "NER",
          "address": "Suraincha, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.858717,
            10.785689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SGM",
          "name": "SOLGAMPATTI",
          "zone": "SR",
          "address": "Thirunedukulam To Paper Mill Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.50959573360001,
            28.3284610746
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "DN",
          "name": "DHANARI",
          "zone": "NR",
          "address": "State Highway 39, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.641594,
            22.71246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "JVN",
          "name": "JORAWARNAGAR JN",
          "zone": "WR",
          "address": "Surendra Nagar, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.230351,
            21.824712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "UKLR",
          "name": "Ukilerhat Halt",
          "zone": "ER",
          "address": "National Highway 117, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.718883,
            10.755434000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "KOQ",
          "name": "KUTHUR",
          "zone": "SR",
          "address": "Nagappattinam - Trichy - Karur- Coimbatore - Mettupalayam - Ooty - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.198201,
            10.773265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MAV",
          "name": "MARIAMMANKOVIL",
          "zone": "SR",
          "address": "Mariammankoil Railway Station Rd, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.706939,
            12.936565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BLRR",
          "name": "Belandur Road",
          "zone": "SWR",
          "address": "Bengaluru, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.56963800000001,
            27.364691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "SRDW",
          "name": "SHRIMAD DWARAKAPURI",
          "zone": "NCR",
          "address": "Shrimad Dwarakapuri, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.93144699999999,
            24.083218
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SKBR",
          "name": "SHANKARPUR BHADAURA",
          "zone": "WCR",
          "address": "Madhya Pradesh, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.67629099999999,
            22.833649
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SCKR",
          "name": "SALICHAUKA ROAD",
          "zone": "WCR",
          "address": "Gadarwara, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.849246,
            25.635531
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "GGO",
          "name": "Goram Ghat",
          "zone": "NWR",
          "address": "Pali, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.15626035310001,
            28.660009076599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "PTNR",
          "name": "PATEL NAGAR",
          "zone": "NR",
          "address": "Najafgarh Road Industrial Area, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.325732,
            22.881747
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BGO",
          "name": "BOGRI ROAD",
          "zone": "SER",
          "address": "Bridge over Krishnanagore, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.59190873,
            23.209058770000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BNKA",
          "name": "Bankim Nagar",
          "zone": "ER",
          "address": "State Highway 11, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.987567,
            22.194485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "SSPH",
          "name": "Satish Samanta P.H.",
          "zone": "?",
          "address": "Mahishadal Geonkhali Rd, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.375029,
            28.298118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RGB",
          "name": "RAMGANGA",
          "zone": "NR",
          "address": "State Highway 33, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.138705,
            14.126112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "JRU",
          "name": "CHIKJAJUR JN",
          "zone": "SWR",
          "address": "Chikkajajur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.495114,
            10.898665999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "PLI",
          "name": "PETTAIVAYATALAI",
          "zone": "SR",
          "address": "Pettavaithalai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.599095,
            16.620491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "AVLI",
          "name": "ARAVALLI",
          "zone": "SCR",
          "address": "West Godavari, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8295941504,
            15.4642781905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHST",
          "name": "KASHANATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.39004938960001,
            13.2744988982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "HVL",
          "name": "HONNAVALLI ROAD",
          "zone": "SWR",
          "address": "National Highway 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.454627,
            13.260739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "SSNH",
          "name": "SREE SARADANAGAR HALT",
          "zone": "SWR",
          "address": "Tiptur, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.725745,
            28.876213999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "CXA",
          "name": "Chandsara",
          "zone": "NR",
          "address": "Chandsara, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.940231,
            30.150805
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTIB",
          "name": "BHATINDA B CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.766166,
            24.217385999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "ANPR",
          "name": "Anpara",
          "zone": "ECR",
          "address": "Sonebhadra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.557048,
            21.101116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CTRD",
          "name": "Chitrawad",
          "zone": "WR",
          "address": "Chitravad And Borvav Railway Station, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.43078299999999,
            16.86827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TNGM",
          "name": "TONDALAGOPAVARAM",
          "zone": "SCR",
          "address": "Tondalagopavaram Station, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72704800000001,
            27.240587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHSN",
          "name": "KHERA SANDHAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.34282,
            31.326976000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "RCF",
          "name": "KAPURTHALA RAIL COACH FACTORY",
          "zone": "NR",
          "address": "kapurthala, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.291923,
            23.287243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "UKR",
          "name": "UDALKACHAR",
          "zone": "SECR",
          "address": "National Highway 78, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8714580536,
            25.598419045
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FAH",
          "name": "FATEHA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.78308399999999,
            24.500035299999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BIWK",
          "name": "BISALWAS KALAN",
          "zone": "WR",
          "address": "MDR 11A, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.753349,
            11.748883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TDPR",
          "name": "TIRUPADRIPULYUR",
          "zone": "SR",
          "address": "Cuddalore, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.48785099999999,
            17.567577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "GOPL",
          "name": "GUNDLA POCHAMPALLI",
          "zone": "SCR",
          "address": "Telangana Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4021040503,
            18.0661191061
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNPH",
          "name": "SHANKAPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.534339,
            10.767733999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "TMU",
          "name": "TIRUMANTHIKUNAM",
          "zone": "SR",
          "address": "Nagappattinam - Trichy - Karur- Coimbatore - Mettupalayam - Ooty - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.239725,
            24.815193999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PURA",
          "name": "PURA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.352761,
            26.245715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bihar",
          "code": "TMA",
          "name": "Tamuria",
          "zone": "ECR",
          "address": "Bihar, Bihar"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.12696299999999,
            18.633565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TMB",
          "name": "Temburu",
          "zone": "ECoR",
          "address": "Paralakhemundi, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.057298,
            23.093053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKBN",
          "name": "SILAKHEDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.32878500000001,
            10.942234
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "SWI",
          "name": "SWAMIMALAI",
          "zone": "SR",
          "address": "Swamimalai, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.072554,
            23.161929
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "RCJ",
          "name": "RANYAL JASMIYA",
          "zone": "WR",
          "address": "Devas, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31951000000001,
            28.673319000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Delhi NCT",
          "code": "VVB",
          "name": "VIVEKA VIHAR",
          "zone": "NR",
          "address": "Jhilmil Industrial Area, Delhi NCT"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.507968,
            23.994180999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BARD",
          "name": "DUVRI KALAN",
          "zone": "WCR",
          "address": "State Highway 9, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.009802,
            29.298626000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttarakhand",
          "code": "GWS",
          "name": "GAUSHALA",
          "zone": "NER",
          "address": "National Highway 121, Uttarakhand"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            93.846465,
            26.13131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "NJN",
          "name": "NAOJAN",
          "zone": "NFR",
          "address": "local Rd, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2329218,
            16.9670099
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "CCT",
          "name": "KAKINADA TOWN",
          "zone": "SCR",
          "address": "East Godavari, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.13029,
            16.495533000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "MVP",
          "name": "MOKHASA KALVPDI",
          "zone": "SCR",
          "address": "Putlacheruvu, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            94.9019739417,
            27.4781132774
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Assam",
          "code": "DBRT",
          "name": "DIBRUGARH TOWN",
          "zone": "NFR",
          "address": "Dibrugarh, Assam"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4194318207,
            8.199571810899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "NJT",
          "name": "NAGERCOIL TOWN",
          "zone": "SR",
          "address": "Nagercoil, Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.299178,
            10.953711
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Tamil Nadu",
          "code": "MMH",
          "name": "MAHADANAPURAM",
          "zone": "SR",
          "address": "Nagappattinam - Gundlupet Road(Vandikara Street), Tamil Nadu"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.005205,
            26.059514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "HWT",
          "name": "HANWANT",
          "zone": "NWR",
          "address": "Basni, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.182282,
            26.216473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "GWO",
          "name": "Gwalior NG",
          "zone": "NCR",
          "address": "Gwalior, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.848386,
            31.203335000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Punjab",
          "code": "KASH",
          "name": "Kulthamabdullashah Halt",
          "zone": "NR",
          "address": "Kulthamabdullashah, Punjab"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.5442353925,
            25.762982552100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "BMSN",
          "name": "Bamsin",
          "zone": "NWR",
          "address": "SH 66, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.410617,
            23.815527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Bangladesh",
          "code": "DHCA",
          "name": "Dhaca Cantt.",
          "zone": "?",
          "address": "Dhaka, Bangladesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            92.37868,
            24.788432999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAKD",
          "name": "Suprakandi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.03937900000001,
            22.419012000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRRI",
          "name": "Khairranji",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.267274,
            25.996484000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "RCP",
          "name": "Ramachandrapur",
          "zone": "NR",
          "address": "Lucknow Allahabad Rd, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21785643429999,
            27.134445803800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Uttar Pradesh",
          "code": "GNTR",
          "name": "Gauntra Halt",
          "zone": "NR",
          "address": "Gauntra, Uttar Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.732428,
            20.902137
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAVI",
          "name": "Navi Amravati",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.09578200000001,
            24.81828
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Rajasthan",
          "code": "FAN",
          "name": "Fatehnagar",
          "zone": "NWR",
          "address": "Fatehnagar, Rajasthan"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.594519,
            22.214729000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Gujarat",
          "code": "CCP",
          "name": "Chhuchhapura Junction",
          "zone": "WR",
          "address": "SH 159, Gujarat"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.746619,
            25.786151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIF",
          "name": "Chhapra Kacheri Jn.",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.702691,
            21.458741
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCO",
          "name": "Chicholi",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.634016,
            23.274779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "West Bengal",
          "code": "BHGH",
          "name": "Bhairgachhi",
          "zone": "ER",
          "address": "Ranaghat-Bagula Bypass, West Bengal"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.689457,
            22.171372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "BHV",
          "name": "Bhoma",
          "zone": "SECR",
          "address": "State Highway 11A, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.80741,
            18.538466
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Maharashtra",
          "code": "WDLN",
          "name": "WADWAL NAGNATH",
          "zone": "SCR",
          "address": "Wadwal Nagnath, Maharashtra"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5967982178,
            25.7600467439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRHD",
          "name": "BABA RAGHUNI HALT DWARIKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.178109,
            15.163457000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Andhra Pradesh",
          "code": "TKBN",
          "name": "TSAKIBANDA",
          "zone": "SWR",
          "address": "Rayalaseema Region, Andhra Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.9845402875,
            22.9155569073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "KFY",
          "name": "KARAPGAON",
          "zone": "WCR",
          "address": "National Highway 26, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.29188099999999,
            22.727429
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Madhya Pradesh",
          "code": "SXF",
          "name": "SOBHAPUR",
          "zone": "WCR",
          "address": "State Highway 22, Madhya Pradesh"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5230602795,
            13.2656079329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": "Karnataka",
          "code": "BNK",
          "name": "BANASANKARAI HA",
          "zone": "SWR",
          "address": "National Highway 206, Karnataka"
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.751593,
            26.307287000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BXHT",
          "name": "Boxirhat",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.468815,
            28.663865
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFH",
          "name": "MAHRAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.49972700000001,
            28.679874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKNR",
          "name": "ADHYATMIK NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.528116,
            28.690718999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DS",
          "name": "DASNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.668008,
            28.712335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKW",
          "name": "PILKHUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7102953784,
            28.7210709953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKMB",
          "name": "KASTLA KASAMBAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.77957599999999,
            28.739613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPU",
          "name": "HAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.855523,
            28.747393000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBO",
          "name": "BABUGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.913232,
            28.746651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "QXR",
          "name": "KUCHESAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9499696448,
            28.7567148575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKHR",
          "name": "SIKHERA MB HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.98347199999999,
            28.765190999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMBL",
          "name": "SIMBHOOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.07350799999999,
            28.781743
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMS",
          "name": "GARHMUKTESAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1412204187,
            28.761399945399997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGB",
          "name": "GARHMUKTESAR BR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.180192128,
            28.8013612636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHE",
          "name": "KANKATHER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.250784,
            28.834937999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJL",
          "name": "GAJRAULA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.330321,
            28.856392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHHR",
          "name": "MAHESRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3593320288,
            28.8654124385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFPR",
          "name": "KAFURPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.474329,
            28.892688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMRO",
          "name": "AMROHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5605679086,
            28.8802519228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIV",
          "name": "KAIALSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.611771,
            28.874271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKLT",
          "name": "CHAK KALI LAIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65886363950001,
            28.8794821589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HKP",
          "name": "HAKIMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.7090987616,
            28.872301720499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDP",
          "name": "LODIPUR BISHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.71802799999999,
            28.869781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LPB",
          "name": "LODIPUR BISHANP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.998591,
            22.248412000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZR",
          "name": "BISRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.060382,
            22.266491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUL",
          "name": "BHALULATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.113703,
            22.309555999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRA",
          "name": "JARAIKELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.196894,
            22.373359
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOU",
          "name": "MANOHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.254753,
            22.447129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PST",
          "name": "POSOTIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.29977799999999,
            22.487947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRWN",
          "name": "DEROWAN P. HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.328849,
            22.510448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXW",
          "name": "MAHADEV SAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.37935200000001,
            22.505290000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOL",
          "name": "GOILKERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.426059,
            22.540308999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUX",
          "name": "TUNIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.464081,
            22.576498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWR",
          "name": "SONUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.565671,
            22.617663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LPH",
          "name": "LOTAPAHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.62777100000001,
            22.678979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKP",
          "name": "CHAKRADHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.72902995999999,
            22.70169729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRM",
          "name": "BARABAMBO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.811158,
            22.733525
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKSN",
          "name": "RAJKHARSAWAN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.879316,
            22.757135
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMV",
          "name": "MAHALI MARUP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.94702600000001,
            22.78946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SINI",
          "name": "SINI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01393866000001,
            22.79397464
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRBS",
          "name": "BIRBANS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.99154999999999,
            17.054312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WADI",
          "name": "WADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.27285,
            13.080627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPK",
          "name": "CHENNAI PARK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28293599999999,
            13.08354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSF",
          "name": "CHENNAI FORT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.291601,
            13.09034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSB",
          "name": "CHENNAI BEACH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.29315199999999,
            13.103503
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPM",
          "name": "ROYAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.28302000000001,
            13.1092227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WST",
          "name": "WASHERMANPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.394077,
            15.163466000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIM",
          "name": "TIMMANACHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.47667899999999,
            15.165309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKDO",
          "name": "NAKKANADODDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.521795,
            15.172077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLU",
          "name": "PATAKOTTACHERU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.625823,
            15.149208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GY",
          "name": "GOOTY JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.650955,
            15.140041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GYJ",
          "name": "GOOTY JUNCTION CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.726953,
            15.109121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKO",
          "name": "JAKKALACHERUVU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.82103500000001,
            15.055957000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLO",
          "name": "RAYALCHERUVU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.85137900000001,
            15.022098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VML",
          "name": "VEMULAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.898103,
            14.978847
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUR",
          "name": "JUTTURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.927597,
            14.936387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMQA",
          "name": "KOMALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.978985,
            14.907558000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TU",
          "name": "TADIPATRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.034438,
            14.876728
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLPE",
          "name": "CHALLAVARIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.080906,
            14.847442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRN",
          "name": "VANGANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.136002,
            14.813572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLL",
          "name": "REGADIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.193462,
            14.773549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDP",
          "name": "KONDAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2607534143,
            14.7366964885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUM",
          "name": "MANGAPATNAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.3238797852,
            14.7197004131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIN",
          "name": "CHINTAKUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.395393,
            14.688415
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOO",
          "name": "MUDDANURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.470174,
            14.667107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMH",
          "name": "KALAMALIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.534003,
            14.642328000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YA",
          "name": "YERRAGUNTLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.606374,
            14.618673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YGD",
          "name": "YERRAGUDIPAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.666113,
            14.591762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKM",
          "name": "KAMALAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.71746345,
            14.55411935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPY",
          "name": "GANGAYAPLLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.776559,
            14.493468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPU",
          "name": "KRISHNAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.829184,
            14.451690000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HX",
          "name": "CUDDAPAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.925335,
            14.428768999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNLP",
          "name": "KANAMALO PALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.97007317,
            14.4093627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKPT",
          "name": "BHAKARAPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.016272,
            14.392459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VNM",
          "name": "ONTIMITTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.078603,
            14.321639000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMPL",
          "name": "MANTAPAMPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.105722,
            14.264922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRE",
          "name": "NANDALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1216,
            14.218403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HAQ",
          "name": "HASTAVARMU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.15274099999999,
            14.184524999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJP",
          "name": "RAZAMPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.205606,
            14.120622000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMT",
          "name": "PULLAMPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.22553,
            14.093157999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPL",
          "name": "REDDIPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.268221,
            14.04083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OBVP",
          "name": "OBULAVARIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.30246799999999,
            13.999592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANE",
          "name": "ANANTARAJUPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.345687,
            13.948578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOU",
          "name": "KODURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.382302,
            13.87431
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SF",
          "name": "SETTIGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.403557,
            13.817150999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLPE",
          "name": "BALAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.458115,
            13.745895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRM",
          "name": "MAMANDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.510307,
            13.532380999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUDI",
          "name": "PUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.514324,
            13.511599
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVF",
          "name": "SRI VENKATAPERU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.526498,
            13.502044999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDK",
          "name": "TADUKU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.552666,
            13.439642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUT",
          "name": "PUTTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.559433,
            13.395013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGA",
          "name": "VEPAGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.573126,
            13.318603999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EKM",
          "name": "EKAMBARAKUPPAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.579647,
            13.307882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NG",
          "name": "NAGARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.58186500000001,
            13.270775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKZ",
          "name": "VENKATA NARASIMHA RAJU VARI PETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.588814,
            13.241368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POI",
          "name": "PONPADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.611386,
            13.179217000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRT",
          "name": "TIRUTTANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.638907,
            13.132795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ICP",
          "name": "ICHCHIPUTTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.64842900000001,
            13.086445000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJJN",
          "name": "ARAKKONAM JN NORTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.32055,
            18.88409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDI",
          "name": "PALASDARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.353331,
            18.852865
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKW",
          "name": "THAKURVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.35913113400001,
            18.854079332599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNCN",
          "name": "NAGNATH CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.36014800000001,
            18.815609
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAGC",
          "name": "NAGARGALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.36374599999999,
            18.769161
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHLC",
          "name": "MONKEY HILL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.377469,
            18.755087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAD",
          "name": "KHANDALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.407657,
            18.748883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNL",
          "name": "LONAVALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.48064000000001,
            18.743833000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVL",
          "name": "MALAVLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.552352,
            18.766938
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMST",
          "name": "KAMSHET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.59401600000001,
            18.762352
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNHE",
          "name": "KANHE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.636925,
            18.742927
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDN",
          "name": "VADGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.67201399999999,
            18.734829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGN",
          "name": "TALEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.69698199999999,
            18.720585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRWD",
          "name": "GHORAWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.705901,
            18.700940000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLRW",
          "name": "SHELARWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.708437,
            18.700263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGWI",
          "name": "BEGDAEWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.70903919999999,
            18.701176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGW",
          "name": "BEGDEWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.734169,
            18.680176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEHR",
          "name": "DEHU ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.76424399999999,
            18.64812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKRD",
          "name": "AKURDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.79139199999999,
            18.638903
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCH",
          "name": "CHINCHVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.801991,
            18.622977
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMP",
          "name": "PIMPRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8207010529,
            18.607555174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSWD",
          "name": "KASAR WADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.83279900000001,
            18.58005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAPD",
          "name": "DAPODI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.840513,
            18.563010000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KK",
          "name": "KHADKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.851147,
            18.531738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVJR",
          "name": "SHIVAJINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6488080432,
            18.4077648636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRB",
          "name": "BORIBIAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7243673877,
            18.3689511683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLM",
          "name": "MALTHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.77504200000001,
            18.317256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGVN",
          "name": "BHIGWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.87741682800001,
            18.3249544651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNTR",
          "name": "JINTI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.952285,
            18.289799000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRWD",
          "name": "PAREWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.003799,
            18.284021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WSB",
          "name": "WASHIMBE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.117846,
            18.292242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPJ",
          "name": "POPHLAJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.16247100000001,
            18.260933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JEUR",
          "name": "JEUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.199289,
            18.212962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLNI",
          "name": "BHALWANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.2746262701,
            18.177508793500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEM",
          "name": "KEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.3342619214,
            18.1296817393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHS",
          "name": "DHAVALAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.41711799999999,
            18.091348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWV",
          "name": "KURDUVADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4887746527,
            18.066132304299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WDS",
          "name": "WADSINGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.546507,
            18.029712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MA",
          "name": "MADHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.579724,
            17.988187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WKA",
          "name": "VAKAV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.599875,
            17.954256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AAG",
          "name": "ANGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.608752,
            17.899981999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKPT",
          "name": "MALIKPETH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.675995,
            17.805205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MO",
          "name": "MOHOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7065479559,
            17.7417646618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVE",
          "name": "MUNDHEWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7788740411,
            17.7248659449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PK",
          "name": "PAKNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8450861792,
            17.676185604199997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BALE",
          "name": "BALE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.893401,
            17.664496
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUR",
          "name": "SOLAPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.933458,
            17.614967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKWD",
          "name": "TIKEKARWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.989943,
            17.565668000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HG",
          "name": "HOTGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.069348,
            17.503553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLT",
          "name": "TILATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.13768900000001,
            17.450243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKOR",
          "name": "AKALKOT ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.182913,
            17.429086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGS",
          "name": "NAGANSUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2535626,
            17.395018500000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOT",
          "name": "BOROTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37871,
            17.362420999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUD",
          "name": "DUDHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.47059200000001,
            17.357949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUI",
          "name": "KULALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.530953,
            17.335973000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDGN",
          "name": "GAUDGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5903,
            17.340443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUR",
          "name": "GANAGAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.646807,
            17.352906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HHD",
          "name": "HUNSIHADGIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.71113700000001,
            17.339489999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVG",
          "name": "SAWALGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.779259,
            17.337569
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBD",
          "name": "BABLAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.82443799999999,
            17.314372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GR",
          "name": "GULBARGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.872212,
            17.258215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HQR",
          "name": "HIRENANDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.902717,
            17.199686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MR",
          "name": "MARTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.943513,
            17.121662999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDB",
          "name": "SHAHABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.02326,
            19.190223
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBQ",
          "name": "MUMBRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.997651,
            19.196049
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLVA",
          "name": "KALVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.879375,
            19.065221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLA",
          "name": "KURLA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.864262,
            19.047725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIN",
          "name": "MUMBAI SION",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.84984200000001,
            19.027347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTN",
          "name": "MUMBAI MATUNGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.842972,
            19.017179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DR",
          "name": "MUMBAI DADAR CENTRAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.149051,
            19.229233999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLDI",
          "name": "VITHALWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.163466,
            19.218215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULNR",
          "name": "ULHASNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.184756,
            19.210382000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABH",
          "name": "AMBARNATH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.2390496,
            19.166388299999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUD",
          "name": "BADLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.300967,
            19.094299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGI",
          "name": "VANGANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.317386,
            19.062985
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHLU",
          "name": "SHELU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.318955,
            19.027113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRL",
          "name": "NERAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.331324,
            18.970654999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVS",
          "name": "BHIVPURI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.320691,
            18.911157999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJT",
          "name": "KARJAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLYC",
          "name": "BLYC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWND",
          "name": "BWND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.564992,
            16.438256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAG",
          "name": "MANGALAGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.49582500000001,
            16.358721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBR",
          "name": "NAMBURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.48615482999999,
            16.34323912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDKN",
          "name": "PEDAKAKANI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.36280020000001,
            16.3655265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDPL",
          "name": "BANDARUPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.320507,
            16.388651999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDPD",
          "name": "MANDAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.295123,
            16.412602999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRPM",
          "name": "SIRIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.273943,
            16.439709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LIN",
          "name": "LINGAMGUNTLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.252989,
            16.459296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKPU",
          "name": "PEDDAKURAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.205304,
            16.433946000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPDE",
          "name": "GUDIPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.152704,
            16.405018
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAP",
          "name": "SATTENAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0878237878,
            16.4244756015
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUP",
          "name": "DHULIPALLA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.0508149304,
            16.442327720599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REM",
          "name": "REDDIGUDEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.006313,
            16.46998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMKD",
          "name": "BELLAMKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.962595,
            16.469897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANPM",
          "name": "ANUPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9255021252,
            16.4747594276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STRM",
          "name": "SITARAMAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.876294,
            16.477787000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGRL",
          "name": "PIDUGURALLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.83223332029999,
            16.5053044215
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPH",
          "name": "KAMEPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.80296299999999,
            16.527651000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMLU",
          "name": "TUMMALACHERUVU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.72356500000001,
            16.590634
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDKD",
          "name": "NADIKODE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65905031369999,
            16.6762442519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDGL",
          "name": "PONDUGULA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6451940808,
            16.686823777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VJRD",
          "name": "VAJIRABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6387859326,
            16.7045104904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VNUP",
          "name": "VISHNUPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.630821,
            16.725861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMCA",
          "name": "DAMARACHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6010754456,
            16.785299045400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDGL",
          "name": "KONDRAPOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.600292,
            16.784736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDRL",
          "name": "KONDRAPOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.533503,
            16.860392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRGA",
          "name": "MIRYALAGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.42309800000001,
            17.006536999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPPI",
          "name": "TIPPARTHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.283436,
            17.06118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLDA",
          "name": "NALGONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.212404903,
            17.1172617982
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRMR",
          "name": "SRIRAMPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.179267,
            17.199276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKPL",
          "name": "NARKETPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.135713114,
            17.221960824
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTYL",
          "name": "CHITYALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.087023,
            17.282344000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMNP",
          "name": "RAMANNAPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.008505,
            17.366663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLG",
          "name": "VANIGONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.97793132449999,
            17.3825749479
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMPR",
          "name": "MAMDAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.939987,
            17.422255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRDP",
          "name": "NAGIREDDIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8612265723,
            17.484445161900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMMP",
          "name": "BOMMAYAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-WADB",
          "name": "YY-WADB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-MLYB",
          "name": "YY-MLYB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.76013216189999,
            14.185769735700001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTCR",
          "name": "KOTHACHERUVU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.74596344999999,
            14.31123523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSPL",
          "name": "BASAMPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.757559,
            14.160468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSPN",
          "name": "SATYA SAI P NILAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.696047,
            14.128972000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRYP",
          "name": "NARAYANAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WDC",
          "name": "WDC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.05225732059999,
            17.0804109748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUH",
          "name": "SULEHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMLX",
          "name": "AMLX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AQX",
          "name": "AQX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.9925675,
            17.017117292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HLTS",
          "name": "HALKATTA SHARIEF",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59500059450001,
            14.7483502106
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAC",
          "name": "TATICHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.31677400000001,
            17.482874000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LPI",
          "name": "LINGAMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5427697052,
            14.9830781091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDT",
          "name": "KHADARPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.44160447690001,
            15.0448535871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPL",
          "name": "VENKATAMPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.39921,
            15.087043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPU",
          "name": "GULAPALYAMU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.333575,
            17.487697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDNR",
          "name": "CHANDANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.36265499999999,
            17.482275
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HFZ",
          "name": "HAFIZPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.38486099999999,
            17.4694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTCY",
          "name": "HITECH CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.37687315369999,
            15.1495189968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNMN",
          "name": "HANUMAN STATION",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.525617,
            17.441464
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LGD",
          "name": "LALLAGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55054100000001,
            17.44041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLY",
          "name": "MAULA ALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.40786013,
            17.45922612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRBD",
          "name": "BORABANDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42819281999999,
            17.46139991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTNR",
          "name": "BHARTNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4502575745,
            17.4561957266
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FNB",
          "name": "FATEHNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.45289083,
            17.44586316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NCHS",
          "name": "NATURE CURE HOSPITAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4586266,
            17.4388739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMT",
          "name": "BEGAMPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.475688,
            17.435951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJVP",
          "name": "SANJEEVAIAH PARK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.486911,
            17.433133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JET",
          "name": "JAMES STREET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.50148100000001,
            17.433146999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SC",
          "name": "SECUNDERABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.019937,
            16.936043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NW",
          "name": "NALWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.03309,
            16.87535
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPHL",
          "name": "SHAMPURHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.068276,
            16.803814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGDE",
          "name": "THANGUNDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.13036600000001,
            16.744396
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YG",
          "name": "YADGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.188998,
            16.670289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LGRE",
          "name": "LINGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.2497912268,
            16.5700011556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SADP",
          "name": "SAIDAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30225045,
            16.48536136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGTA",
          "name": "CHEGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.328563,
            16.409324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSN",
          "name": "KRISHNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.326596,
            16.384372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YDLP",
          "name": "YADALAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.343832,
            16.336647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CK",
          "name": "CHIKSUGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.34747,
            16.272482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YS",
          "name": "YERMARAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.339165,
            16.192441000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RC",
          "name": "RAICHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30931799999999,
            16.111362
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRC",
          "name": "MARICHETHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.302973,
            16.046723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTU",
          "name": "MATMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30620283569999,
            15.9712913703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPH",
          "name": "HANUMAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.299196,
            15.949035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MALM",
          "name": "MANTHRALAYAM RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.26376539,
            15.89181357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EGU",
          "name": "IRANAGALLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.23640800000001,
            15.847871999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KO",
          "name": "KOSGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.219229,
            15.726256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGL",
          "name": "KUPGAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.22981399999999,
            15.65781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ESV",
          "name": "ISIVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.27485,
            15.617035
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AD",
          "name": "ADONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31318399999999,
            15.542335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRR",
          "name": "NAGARUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.356253,
            15.454804000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASP",
          "name": "ASPARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.371821,
            15.357784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGV",
          "name": "MOLAGAVALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.37277900000001,
            15.297622
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMLU",
          "name": "NEMAKALLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.36883300000001,
            15.256053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLA",
          "name": "NANCHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.36661099999999,
            15.17556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTL",
          "name": "GUNTAKAL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.581419,
            14.924474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLU",
          "name": "KALLURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.592146,
            14.827323999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDE",
          "name": "GARLADINNE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.99057254760001,
            17.0207708951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HLKT",
          "name": "HALAKATTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.09045160000001,
            17.1197808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CT",
          "name": "CHITTAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.17396099999999,
            17.147112
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQR",
          "name": "MALKHAID ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.28353,
            17.180683000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEM",
          "name": "SERAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.359574,
            17.191165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQT",
          "name": "KURGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.427908244,
            17.2170998262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAW",
          "name": "NAWANDGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.5094807208,
            17.2276934669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVH",
          "name": "MANTATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.585037,
            17.25044
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDU",
          "name": "TANDUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.67542399999999,
            17.27365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMY",
          "name": "RUKMAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75853000000001,
            17.28952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRR",
          "name": "DHARUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.809709,
            17.280412
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWY",
          "name": "MAILARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.867119,
            17.29025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDQ",
          "name": "GODAMGURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.90940900000001,
            17.337132
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKB",
          "name": "VIKARABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.963909,
            17.391231
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTF",
          "name": "CHITGIDDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.03277293640001,
            17.4169638619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGD",
          "name": "GULLAGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.084165,
            17.439968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPK",
          "name": "RAVALPALLI KALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.131116,
            17.450825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKP",
          "name": "SHANKARPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.229966,
            17.460567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPL",
          "name": "NAGALAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4359471722,
            17.4616070935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNF",
          "name": "SANAT NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2472830347,
            26.973936860000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBR",
          "name": "JAHANGIRABAD ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2820328021,
            26.994136282600003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RFR",
          "name": "RAFINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.3252031305,
            27.0210232397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUR",
          "name": "BINDAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.520289,
            17.428339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STPD",
          "name": "SITAFAL MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.51966499999999,
            17.418129999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATC",
          "name": "ARTS COLLEGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.517784,
            17.411068999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOO",
          "name": "KACHEGUDA JAMIA OSMANIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.511657,
            17.402485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VAR",
          "name": "VIDYANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.52440299999999,
            17.440355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LGDH",
          "name": "LALAGUDA GATE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.529606,
            17.448392000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJF",
          "name": "MALKAJGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.57597160489999,
            13.063357741399999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDGH",
          "name": "KODIGEHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59531199999999,
            14.686478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATP",
          "name": "ANANTAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.602106203,
            14.6311777937
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSPY",
          "name": "PRASANNAYA PALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.640854,
            14.591854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZPL",
          "name": "ZANGALAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.68348800000001,
            14.517474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCA",
          "name": "CHIGICHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.717115,
            14.424368999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMM",
          "name": "DHARMAVARAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.64246703,
            14.32166253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGM",
          "name": "NAGASAMUDRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.62844748,
            14.19611832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKJ",
          "name": "MAKKAJIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.592521,
            14.099641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKD",
          "name": "PENUKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.578036,
            14.01783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REPI",
          "name": "RANGEPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.583123,
            13.99779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPL",
          "name": "CHAKARLAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.531591,
            13.866614
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLU",
          "name": "MALUGUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.500362,
            13.819144000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HUP",
          "name": "HINDUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.512236488,
            13.723806590299999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPE",
          "name": "DEVARAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.509785,
            13.665712999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VWA",
          "name": "VIDURASWATTHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.512781,
            13.613081000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBD",
          "name": "GAURIBIDANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.513961,
            13.55657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMWA",
          "name": "SOMESHWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.51457300000001,
            13.503502999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDV",
          "name": "THONDEBHAVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.50903739809999,
            13.4095451686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKL",
          "name": "MAKLIDRUG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.605619,
            17.457672000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHZ",
          "name": "CHARLAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.678217,
            17.453116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GT",
          "name": "GHATKESAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.794355,
            17.470511
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BN",
          "name": "BIBINAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.820893,
            17.484713000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGDP",
          "name": "PAGIDIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.89847,
            17.524696
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BG",
          "name": "BHONGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.94120199999999,
            17.550881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAG",
          "name": "RAIGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.983664,
            17.580557000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WP",
          "name": "WANGAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.04834140000001,
            17.6459553
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALER",
          "name": "ALER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11616,
            17.692296000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBP",
          "name": "PEMBARTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.15473399999999,
            17.725115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZN",
          "name": "JANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.20499763309999,
            17.736740181200002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YSPM",
          "name": "YASANTAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2553036017,
            17.7564788113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGP",
          "name": "RAGHUNATHPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3267055493,
            17.801311539300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPG",
          "name": "IPPUGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3768995,
            17.8506252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNP",
          "name": "GHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.426237,
            17.898101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSKL",
          "name": "NASKHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.541461,
            13.345829
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ORH",
          "name": "ODDARAHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.55016,
            13.27894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBU",
          "name": "DODBALLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.564915,
            13.174150999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNN",
          "name": "RAJANKUNTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.593069,
            13.102795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YNK",
          "name": "YELHANKA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VAH",
          "name": "VAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.20522299999999,
            16.993515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGL",
          "name": "BURGULA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27355,
            17.134017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HBLN",
          "name": "HBL NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.28622100000001,
            17.149739999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOTT",
          "name": "KOTTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4372038177,
            17.3237142919
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSVP",
          "name": "NPA SHIVARAMPALLI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.49171100000001,
            17.359554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YKA",
          "name": "YAKUTPURA KACHEGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.53416999999999,
            17.455347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLYG",
          "name": "MLYG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.548135,
            17.447219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLYC",
          "name": "MAULA ALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.48098,
            18.183408
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OPL",
          "name": "UPPAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.476918,
            18.290021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMKT",
          "name": "JAMIKUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.483604,
            18.348122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGSF",
          "name": "BISUGIRSHARIF",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4644454974,
            18.4290619835
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTKP",
          "name": "POTKAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4497442804,
            18.456554241099997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OEA",
          "name": "ODELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4199093701,
            18.496319274500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOLR",
          "name": "KOLNUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.40385903139999,
            18.5402681328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYOP",
          "name": "KOTTAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.39208,
            18.613795999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDPL",
          "name": "PEDDAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.411187,
            18.681242
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGPM",
          "name": "RAGHAVAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.43509999999999,
            18.763329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDM",
          "name": "RAMGUNDAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4268241321,
            18.802669374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPZ",
          "name": "PEDDEMPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.448848,
            18.878745000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCI",
          "name": "MANCHIRYAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.461396,
            18.936594000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RVKH",
          "name": "RAVINDRAKHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.47014800000001,
            18.971272
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMZ",
          "name": "MANDAMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.4863374,
            19.053689199999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPA",
          "name": "BELAMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.451894,
            19.140541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RECH",
          "name": "RECHNI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.430139,
            19.201953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLW",
          "name": "REPALLIWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.416719,
            19.256682
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASAF",
          "name": "ASIFABAD ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.437787,
            19.322909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLT",
          "name": "RALAPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.489201,
            19.345659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKZR",
          "name": "SIRPUR KAGAZNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.53458400000001,
            19.414389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VEM",
          "name": "VEMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.560418,
            19.479063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRUR",
          "name": "SIRPUR TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.44826,
            19.592555
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKDI",
          "name": "MAKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.430571,
            19.641288000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WIRR",
          "name": "WIRUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.44081899999999,
            19.725052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VHGN",
          "name": "WIHIRGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.398527,
            19.787693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAGH",
          "name": "MANIKGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3485858,
            19.8490173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPQ",
          "name": "BALHARSHAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3287230096,
            19.8826389297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNVR",
          "name": "GONDWANAVISAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.327833,
            19.884978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VSP",
          "name": "WASANAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.313027,
            19.923131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUPH",
          "name": "BABUPETH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.300505,
            19.960126000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CD",
          "name": "CHANDRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.259191,
            19.997086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VVKN",
          "name": "VIVEKANANDA NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.253005,
            20.000992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPW",
          "name": "CHOTIPADOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.187503,
            20.034835
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAE",
          "name": "TADALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.112528,
            20.084262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUX",
          "name": "BHANDAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.043522,
            20.139283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJRI",
          "name": "MAJRI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.00994399999999,
            20.244263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WRR",
          "name": "WARORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.95921700000001,
            20.325826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKNI",
          "name": "CHIKNI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.889984,
            20.406983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGI",
          "name": "NAGARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.837091,
            20.508138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YNA",
          "name": "YENOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.83392500000001,
            20.542631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HGT",
          "name": "HINGANGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.767038,
            20.585085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WG",
          "name": "WAGHOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.709706,
            20.629319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNN",
          "name": "SONEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.6700027,
            20.6738192
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPK",
          "name": "BHUGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.62974,
            20.708474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHJC",
          "name": "CHITODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.454715,
            17.925189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQL",
          "name": "PINDLAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.511025,
            17.974211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZJ",
          "name": "KAZIPET JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.41876900000001,
            23.278225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSZ",
          "name": "BHOPAL NISHATPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.48596599999999,
            23.34076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUW",
          "name": "SUKHISEWANIYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.535282,
            23.375165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVB",
          "name": "BHADBHADAGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59286700000001,
            23.429766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWG",
          "name": "DEWANGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.67275,
            23.461639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMT",
          "name": "SALAMATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.735783,
            23.488096
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCI",
          "name": "SANCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.787463,
            23.509200999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTWN",
          "name": "BETWA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8148356,
            23.5222886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHS",
          "name": "VIDISHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.848219,
            23.551699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SORI",
          "name": "SORAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.879427,
            23.624616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUMR",
          "name": "SUMER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.912351,
            23.684375
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLG",
          "name": "GULABHGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.931259,
            23.767491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAI",
          "name": "PABAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.944826,
            23.845347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAQ",
          "name": "GANJ BASODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.981122,
            23.885645
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKRI",
          "name": "RAO KHEDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.981962,
            23.886485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKDI",
          "name": "RAUKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.99967978000001,
            23.91494729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BET",
          "name": "BARETH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.018483,
            23.945912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLHT",
          "name": "CHULHETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05717800000001,
            23.978071
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAH",
          "name": "KALHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.08243700000001,
            24.054032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MABA",
          "name": "MANDI BAMORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.11497800000001,
            24.117852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIKA",
          "name": "KURWAI KETHORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.63498,
            25.496702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSV",
          "name": "MUSTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.687944,
            25.506271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRM",
          "name": "GARHMAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.73773,
            25.518402
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PIC",
          "name": "PARICHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.753962,
            25.525228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTSC",
          "name": "PARICHAA THERMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.805761,
            25.575053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGN",
          "name": "CHIRGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.872336,
            25.660949000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDK",
          "name": "NANKHAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.948673,
            25.74157
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOTH",
          "name": "MOTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.03502499999999,
            25.820641
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ERC",
          "name": "ERICH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1257213309,
            25.8668027818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRN",
          "name": "PARAUNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.230436,
            25.903719000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AIT",
          "name": "AIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3510848193,
            25.9455806635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHUA",
          "name": "BHUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.459533963,
            25.9825370076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ORAI",
          "name": "ORAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.5991430011,
            26.0445422657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATA",
          "name": "ATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.680325,
            26.080448999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URG",
          "name": "USARGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.755607,
            26.119339
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPI",
          "name": "KALPI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.782159,
            26.16506
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNH",
          "name": "CHAUNRAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.84088799999999,
            26.222964
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHN",
          "name": "POKHRAYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.907312,
            26.28889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLS",
          "name": "MALASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.948516,
            26.318818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLR",
          "name": "LALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.02677233760001,
            26.3381999708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLNH",
          "name": "TILAUNCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.08042,
            26.359934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PMN",
          "name": "PAMAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.118434,
            26.376288
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPGU",
          "name": "RASULPURGOGAMAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.178721,
            26.402727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNAR",
          "name": "BINAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21814499999999,
            26.419754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZM",
          "name": "BHIMSEN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.26021300000001,
            26.438271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HLIN",
          "name": "HOLDING LINE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.90960679930001,
            26.8373880081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASH",
          "name": "AISHBAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.917866,
            26.859109
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LC",
          "name": "LUCKNOW CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.927482,
            26.875384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAL",
          "name": "DALIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.9613606,
            26.8686134
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNZ",
          "name": "BADSHAHNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.003433,
            26.860344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTNR",
          "name": "GOMATI NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2144349255,
            26.820567771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMBG",
          "name": "RAMGHAT HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.204937,
            26.842992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEA",
          "name": "KATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.977064,
            27.153816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGB",
          "name": "NAWABGANJ GONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.070639,
            27.116135
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRE",
          "name": "TIKRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.55117399999999,
            13.022839000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YPR",
          "name": "YESVANTPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.674016,
            13.000534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJM",
          "name": "KRISHNARAJAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7614779608,
            12.9960174083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGWF",
          "name": "WHITE FIELD SATELLITE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.76130500000001,
            12.995253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WFD",
          "name": "WHITEFIELD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.831087,
            12.985647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKN",
          "name": "DEVANGONTHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.940033,
            12.992108
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLO",
          "name": "MALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.98850762000001,
            13.002039739999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BFW",
          "name": "BYATRAYANHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.070518,
            12.984096000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TCL",
          "name": "TYAKAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.108853,
            12.988346
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZV",
          "name": "MARALAHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.178188,
            12.984808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWT",
          "name": "BANGARAPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.562257,
            13.043169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LOGH",
          "name": "LOTTEGOLLAHALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.650886,
            12.990198
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYPL",
          "name": "BAIYYAPPANAHALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.62557964230001,
            13.0067311694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYPY",
          "name": "BAIYYAPPANAHALLI YARD CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.628196,
            13.00573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAND",
          "name": "BANASWADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.58606,
            13.042299
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HEB",
          "name": "HEBBAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1915680487,
            12.9226601895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VRDP",
          "name": "VARADAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.200119,
            12.880706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSM",
          "name": "KAMASAMUDRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.25031624,
            12.84262771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSM",
          "name": "BISANATTAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.27491,
            12.803672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDP",
          "name": "GUDUPULLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.339549,
            12.746834
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPN",
          "name": "KUPPAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.40638899999999,
            12.661484999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAR",
          "name": "MULANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4694944,
            12.586657299999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PU",
          "name": "PATCHUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.54245200000001,
            12.574922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKPT",
          "name": "SOMANAYAKKANPTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.176067,
            13.044566
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUM",
          "name": "BOMMASAMUDRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.178793,
            13.098011999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAM",
          "name": "RAMAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.138282,
            13.131636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PYX",
          "name": "PEYANAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.137135,
            13.160644999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIE",
          "name": "SIDDAMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.103252,
            13.219857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTO",
          "name": "CHITTOOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11625703979999,
            13.2783021967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RVSN",
          "name": "RVS NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.098727,
            13.373419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTT",
          "name": "PUTALAPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11408,
            13.451133
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAK",
          "name": "PAKALA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.210821,
            13.513241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAM",
          "name": "PANAPAKAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2410982153,
            13.5565460528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNPT",
          "name": "MUNGILIPATTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.2872542792,
            13.6063213389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEN",
          "name": "KOTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.31221500000001,
            13.61173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGI",
          "name": "CHANDRAGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.404624,
            13.624534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPW",
          "name": "TIRUPATI WEST HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.418968,
            13.62763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPTY",
          "name": "TIRUPATI MAIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.455703,
            13.631300000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TCNR",
          "name": "TIRUCHANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.50628999999999,
            13.636261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RU",
          "name": "RENIGUNTA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.578514,
            13.689108000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YPD",
          "name": "YERPEDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.629853,
            13.714631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RCG",
          "name": "RACHAGUNNARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6830412451,
            13.7463618318
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHT",
          "name": "SRI KALAHASTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.6686416895,
            13.7828960127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKY",
          "name": "AKKURTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.65057200000001,
            13.84646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YLK",
          "name": "YELLAKARU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.626238,
            13.893493999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YAL",
          "name": "YATALURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.60398699999999,
            13.943864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKI",
          "name": "VENKATAGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.660594,
            13.988306000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDZ",
          "name": "NIDIGALLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.72840000000001,
            14.026947000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDD",
          "name": "VENDODU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.79392,
            14.080027999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQA",
          "name": "KONDAGUNTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.91858699999999,
            26.831967000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LJN",
          "name": "LUCKNOW JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0157752,
            27.1945731
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGA",
          "name": "AGRA CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8327,
            24.522974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAWS",
          "name": "RAHATWAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.33412200000001,
            23.465605999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRNA",
          "name": "BERAWANYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.286337,
            23.442673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUH",
          "name": "KHACHROD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1888022272,
            23.413846043299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNH",
          "name": "RUNKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.1241457,
            23.3982156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOD",
          "name": "BANGROD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.067019,
            23.359789999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTME",
          "name": "RATLAM EAST CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.050686,
            23.340239999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTLM",
          "name": "RATLAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.050826,
            23.34038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTM",
          "name": "RATLAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.042913,
            23.335631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTD",
          "name": "RATLAM A CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.0060586914,
            23.310222115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRRN",
          "name": "DR. R K NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.9779687335,
            23.2764855209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRN",
          "name": "MORWANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.91853870000001,
            23.2255232
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BILD",
          "name": "BILDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.87867399999999,
            23.183572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTI",
          "name": "RAOTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7760641077,
            23.1449211037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOG",
          "name": "BHAIRONGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.7590158,
            23.0957635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMI",
          "name": "BAMNIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.747038,
            23.080689
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGR",
          "name": "AMARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.737549,
            23.06329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCN",
          "name": "PANCH PIPILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.69499300000001,
            23.042607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJG",
          "name": "BAJRANGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.666939,
            23.032708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THDR",
          "name": "THANDLA RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.539834,
            22.907459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGN",
          "name": "MEGHNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.500786,
            22.873881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRH",
          "name": "NAHARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.421513,
            22.866009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANAS",
          "name": "ANAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3470986,
            22.8664871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIO",
          "name": "BORDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.297046,
            22.846208
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHMA",
          "name": "DHAMARDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.25337400000001,
            22.843607000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHD",
          "name": "DAHOD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.210847,
            22.863268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RET",
          "name": "RENTIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.15935400000001,
            22.865616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKT",
          "name": "JEKOT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.102942,
            22.842055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "USRA",
          "name": "USRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.058492,
            22.83578
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAM",
          "name": "MANGAL MAHUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.984515,
            22.833894
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LMK",
          "name": "LIMKHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.9091,
            22.819148000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPD",
          "name": "PIPLOD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.80040253419999,
            22.7963945628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAT",
          "name": "SANT ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.71433934,
            22.795825439999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCL",
          "name": "CHANCHELAV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.64823,
            22.789343
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIZ",
          "name": "KANSUDHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.603672,
            22.776974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDA",
          "name": "GODHRA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1831998,
            24.170973500000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BINA",
          "name": "BINA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.314104,
            24.6405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUNA",
          "name": "GUNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.24174229549999,
            24.627745093
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUGA",
          "name": "MAHUGARHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.175827,
            24.531922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTA",
          "name": "RUTHIYAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1624114166,
            24.493169148
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VJP",
          "name": "VIJAY PUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.1547924854,
            24.435611305000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGG",
          "name": "RADOGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.079574,
            24.363097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHRJ",
          "name": "KUMBHRAJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.051454,
            24.242752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMQ",
          "name": "RAMRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.01660299999999,
            24.178144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBK",
          "name": "CHACHAURA BINAGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.005837,
            24.138711
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCF",
          "name": "PENCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.9259037006,
            24.0210993429
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFZ",
          "name": "SINDURIYA KACHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.907007,
            23.93591
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRRG",
          "name": "BIYAVRA RAJGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.878726,
            23.883958
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNRA",
          "name": "KACHNARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.808926,
            23.80408
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNWS",
          "name": "KARANWAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.741609,
            23.729095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PFR",
          "name": "PACHOR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.639447,
            23.680052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDK",
          "name": "UDYAN KHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5556067877,
            23.6266363986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQU",
          "name": "PARHANA MAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4871872211,
            23.5810641859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFW",
          "name": "SARANGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.36030299999999,
            23.474148000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFF",
          "name": "SUNERA PIRKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.297843,
            23.425074
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFY",
          "name": "SHAJAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.264491,
            23.332144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAZ",
          "name": "CHAUHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.225424,
            23.30556
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYO",
          "name": "SIROLIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.193689,
            24.217392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDVK",
          "name": "MAHADEOKHEDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.177733,
            24.288941
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRKI",
          "name": "SEMARKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.169132,
            24.331047
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXB",
          "name": "KANJAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.11099,
            24.402175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNV",
          "name": "MUNGAOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0625395081,
            24.4617725676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVB",
          "name": "GUNERU BAMORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.964258,
            24.503643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PIA",
          "name": "PIPRAIGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83266,
            24.522934000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTWS",
          "name": "RAHATWAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.799315,
            24.530939
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ORR",
          "name": "ORR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.770415,
            24.550797000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPKA",
          "name": "HINOTIA PIPALKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.726725,
            24.58015
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASKN",
          "name": "ASHOK NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.656544,
            24.59745
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RIKA",
          "name": "RATIKHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.584671,
            24.633366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHDR",
          "name": "SHADHORAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.49331000000001,
            24.660758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PIGT",
          "name": "PILIGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.44077,
            24.65577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGA",
          "name": "PAGARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.371542,
            24.641196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MABN",
          "name": "MABAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.61961099999999,
            25.987832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IAA",
          "name": "INDARA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.0404752448,
            26.8534491635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ML",
          "name": "MALHOUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.08856127690001,
            26.881922122
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRR",
          "name": "JUGAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.1166012347,
            26.8978573678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFH",
          "name": "SAFEDABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.18104,
            26.935335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBK",
          "name": "BARABANKI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.564347,
            25.93948
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAU",
          "name": "MAU JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.50513000000001,
            25.959544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLGH",
          "name": "PALIGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.455442,
            25.984989000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRT",
          "name": "KHURAHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.376007,
            26.026235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMA",
          "name": "MUHAMMADABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.28731699999999,
            26.034194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAA",
          "name": "SATHIAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.18766740909999,
            26.0377415583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDHA",
          "name": "SIDHARI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.2534495215,
            26.9314225458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RES",
          "name": "RASULL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.160614,
            26.038607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMH",
          "name": "AZAMGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.12149199999999,
            26.015838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKS",
          "name": "SARAI RANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.0299456195,
            26.007478447900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHY",
          "name": "PHARIHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.97217946560001,
            26.0173317932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJER",
          "name": "SANJARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.9239538,
            26.0281419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMZ",
          "name": "SARAI MIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.867173,
            26.089745999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRND",
          "name": "KHORASON ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.36116,
            26.914454
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGJ",
          "name": "SAFDARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.805191,
            26.092367
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DJD",
          "name": "DIDARGANJ ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.744969,
            26.088482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHJA",
          "name": "KHANJA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.462176,
            26.89846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYK",
          "name": "SAIDKHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.550114,
            26.861303
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DYD",
          "name": "DARYABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.625869,
            26.825007
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTH",
          "name": "PATRANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.70800700000001,
            26.785263999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RZN",
          "name": "RAUZAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.754202,
            26.762188000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDL",
          "name": "RUDAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.79366,
            26.757029
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMU",
          "name": "GAURIYAMAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.8470278857,
            26.755374103399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNM",
          "name": "BARAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.90635800000001,
            26.754368
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DELO",
          "name": "DEORAKOT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.983837,
            26.753048
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLW",
          "name": "SOHWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.013173,
            26.752552
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLRP",
          "name": "SALARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.135574,
            26.768996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FD",
          "name": "FAIZABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.156997,
            26.774576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACND",
          "name": "ACHARYA NARENDRA DEV NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.200604,
            26.787784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AY",
          "name": "AYODHYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2083842694,
            26.7522286283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRG",
          "name": "DARSHANNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.2689979,
            26.6813068
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLG",
          "name": "BILHAR GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.319243,
            26.632175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULN",
          "name": "ULNA BHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.384123,
            26.569524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGJ",
          "name": "GOSHAINGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.44776399999999,
            26.4952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTHE",
          "name": "KATAHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.539073,
            26.429818
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABP",
          "name": "AKBARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.5823193939,
            26.360277060599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JFG",
          "name": "JAFARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.63130000000001,
            26.281482
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLPR",
          "name": "MALIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.651701,
            26.215063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLGR",
          "name": "TULSI NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.665181,
            26.158966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWI",
          "name": "BILWAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.679662,
            26.061399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHG",
          "name": "SHAHGANJ JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.05132499999999,
            25.770309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PEP",
          "name": "PHEPHNA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.098657,
            25.758015
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVI",
          "name": "SAGARPALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.67908,
            25.963348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HLP",
          "name": "HALDHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.744472,
            25.921924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTP",
          "name": "RATANPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.788671,
            25.892921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJMP",
          "name": "RAJMALPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.85785,
            25.861714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSR",
          "name": "RASRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.910076,
            25.845461
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNRA",
          "name": "SANWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.965313,
            25.82699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHR",
          "name": "CHILKAHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.00627300000001,
            25.802194
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGKS",
          "name": "JIGNI KHAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.15311999999999,
            25.76057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUI",
          "name": "BALLIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.215238,
            25.8115832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCD",
          "name": "BANSDIH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.253936,
            25.824617
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CATA",
          "name": "CHHATA ASCHAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.295491,
            25.827831
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STW",
          "name": "SAHATWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.37469,
            25.833886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROI",
          "name": "REOTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.432615,
            25.82706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DCP",
          "name": "DAL CHAPRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.48002199999999,
            25.80918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIP",
          "name": "SURAIMANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.543411,
            25.797877
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKLA",
          "name": "BAKULHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.58475899999999,
            25.828485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHT",
          "name": "MANJHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.6394018433,
            25.787071962800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RVG",
          "name": "REVELGANJ GHAT HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.6634794519,
            25.7868591282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTST",
          "name": "GAUTAMSTHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.70782700000001,
            26.497967
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJF",
          "name": "BIJNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.78453400000001,
            26.504091000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTLD",
          "name": "PATILADAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.88828099999999,
            26.494286000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBE",
          "name": "SORBHOG JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.9639649,
            26.5028473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPRD",
          "name": "BARPETA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.01633199999999,
            26.501588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GSA",
          "name": "GUAGACHHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.0760593414,
            26.4996888512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPQ",
          "name": "SORUPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.176255,
            26.497475
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBL",
          "name": "PATHSALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.2211261203,
            26.4860621389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSRH",
          "name": "NIZ-SARIHA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.267699,
            26.471321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIHU",
          "name": "TIHU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.339022,
            26.450315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTCH",
          "name": "KAITHALKUCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.440291,
            26.450629
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLV",
          "name": "NALBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.539986,
            26.450741999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOE",
          "name": "GHOGRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.605929,
            26.447900999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNY",
          "name": "RANGIYA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.63681,
            26.374067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDKN",
          "name": "KENDUKANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.684343,
            26.343713
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIZ",
          "name": "BAIHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.693023,
            26.256204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGS",
          "name": "CHANGSARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.686006,
            26.20089324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGT",
          "name": "AGTHORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.689505,
            26.156154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYQ",
          "name": "KAMAKHYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            91.751851,
            26.182635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHY",
          "name": "GUWAHATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.70945300000001,
            26.559016999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPE",
          "name": "JALPAIGURI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.786571557,
            26.569680979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NQH",
          "name": "NEW DOMOHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.822868,
            26.578607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMX",
          "name": "NEW MAYNAGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.885559,
            26.612665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYXA",
          "name": "BETGARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.946682,
            26.608131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATM",
          "name": "ALTAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.994973,
            26.573532
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DQG",
          "name": "DHUPGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.052194,
            26.550420000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLGR",
          "name": "KHALAIGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.10552673400001,
            26.5460609943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXX",
          "name": "SALBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.19227488279999,
            26.5383964616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FLK",
          "name": "FALAKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.2392642694,
            26.4740709229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMZ",
          "name": "GUMANI HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.2398742694,
            26.4746809229
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUZ",
          "name": "GUMANI HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.2780105872,
            26.4228864281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDX",
          "name": "GHOKSADANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.35227413269999,
            26.4224452405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJRR",
          "name": "SAJHERPAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.393542,
            26.413245
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQZ",
          "name": "PUNDIBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.469766,
            26.353158999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NCB",
          "name": "NEW COOCH BEHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.5104217529,
            26.3950219126
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBS",
          "name": "NEW BANESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.541398,
            26.48572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NOQ",
          "name": "NEW ALIPURDAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.6346837433,
            26.483337452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMTA",
          "name": "SAMUKTALA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.721829,
            26.484168
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAMG",
          "name": "KAMAKHYAGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.8034610461,
            26.454875498099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOQ",
          "name": "JORAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.903914613,
            26.4269150632
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRPB",
          "name": "SRIRAMPUR ASSAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            89.9715891956,
            26.4291071597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOGH",
          "name": "GOSSAIGAON HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBY",
          "name": "GUABARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.08572902709999,
            26.4022417983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CROA",
          "name": "CHAUTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.11849600000001,
            26.386384000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQK",
          "name": "PRATAP KHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.186189,
            26.365237
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FKM",
          "name": "FAKIRAGRAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.225701,
            26.386181999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUF",
          "name": "SESAPANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.274125,
            26.405057
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOJ",
          "name": "KOKRAJHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.357339,
            26.44577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLKX",
          "name": "SALAKATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.42014999999999,
            26.460164000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSGN",
          "name": "BASUGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.46504900000001,
            26.465846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTX",
          "name": "DANGTAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.52148600919999,
            26.4721133248
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBQY",
          "name": "NEW BONGAIGAON YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.53684346440001,
            26.4758354405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBQ",
          "name": "NEW BONGAIGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.560548,
            26.478293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNGN",
          "name": "BONGAIGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            90.6120253168,
            26.4928550164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPQ",
          "name": "CHAPRAKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.389853,
            22.362509000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPR",
          "name": "JAKPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.44326799999999,
            22.369694000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPD",
          "name": "MADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.500479,
            22.363450999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMCK",
          "name": "SHYAM CHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.55335416,
            22.35818589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCK",
          "name": "BALICHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.58821800000001,
            22.354105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUAN",
          "name": "DUAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.612611,
            22.356484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDU",
          "name": "RADHAMOHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.655069,
            22.365254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HAUR",
          "name": "HAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.69662799999999,
            22.374504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHAI",
          "name": "KHIRAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7430237079,
            22.393102763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKU",
          "name": "PANSKURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.773512,
            22.39974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKEO",
          "name": "PANSKURA EAST OUTER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.773985,
            22.399692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPMR",
          "name": "NARAYAN PAKURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.809079,
            22.401522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOP",
          "name": "BHOGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.832458,
            22.404098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDGJ",
          "name": "NANDAHGANJA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.85883,
            22.414105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCA",
          "name": "MECHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.88087399999999,
            22.435188999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIG",
          "name": "KOLAGHAT GOODS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.914003,
            22.452857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTE",
          "name": "DEULTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.94424,
            22.458315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGTA",
          "name": "GHORAGHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.97071100000001,
            22.462515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZN",
          "name": "BAGNAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.020395,
            22.472352
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGY",
          "name": "KULGACHIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.306549,
            9.979712000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ERSD",
          "name": "ERNAKULAM D CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.352761,
            9.9501
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRTR",
          "name": "TRIUPUNITTURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37635,
            9.923276999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFE",
          "name": "KURIKAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.389673,
            9.901576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNTT",
          "name": "MULANTURUTTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.410949,
            9.862331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPTM",
          "name": "KANJIRAMITTAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.95120999999999,
            8.486679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVC",
          "name": "TRIVANDRUM CENTRAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.931673,
            8.494524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVP",
          "name": "TRIVANDRUM PETTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.454731,
            9.828669
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVRD",
          "name": "PIRAVAM ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.47461100000001,
            9.777648
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VARD",
          "name": "VAIKAM ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.49260000000001,
            9.756128
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDTY",
          "name": "KADUTURUTTI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.51060899999999,
            9.734535000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRPP",
          "name": "KURUPPANTHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.545152,
            9.676196000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETM",
          "name": "ETTUMANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.531679,
            9.617136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFQ",
          "name": "KUMARANALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.532168,
            9.594177
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTYM",
          "name": "KOTTAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.525885,
            9.537757000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGV",
          "name": "CHINGAVANAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.54841400000001,
            9.449881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGY",
          "name": "CHANGANASERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.580267,
            9.392148
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRVL",
          "name": "TIRUVALLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.608271,
            9.319504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNGR",
          "name": "CHENGANNUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.585087,
            9.273119000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CYN",
          "name": "CHERIYANAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.545692,
            9.239487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVLK",
          "name": "MAVELIKARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.512933,
            9.182586
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYJ",
          "name": "KAYANKULAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.525576,
            9.132022999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OCR",
          "name": "OCHIRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.54402300000001,
            9.065268000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPY",
          "name": "KARUNAGAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.59888294679999,
            9.0323103106
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STKT",
          "name": "SASTHANKOTTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.6119083966,
            8.9949977685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQO",
          "name": "MUNROTURUTTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.620341,
            8.947916000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRND",
          "name": "PERINAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.59675299999999,
            8.886586000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "QLN",
          "name": "KOLLAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.623059,
            8.866947999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IRP",
          "name": "IRAVIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.64733100000001,
            8.837759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYY",
          "name": "MAYYANAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.66823799999999,
            8.815758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVU",
          "name": "PARAVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.67962999999999,
            8.779435
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFI",
          "name": "KAPPIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.69747699999999,
            8.762883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EVA",
          "name": "EDAVAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.722872,
            8.740732
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VAK",
          "name": "VARKALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.758792,
            8.70031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMY",
          "name": "AKATHUMURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.766672,
            8.679078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVU",
          "name": "KADAKAVUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.7853703,
            8.658337900000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRY",
          "name": "CHIRAYINKIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.808211,
            8.631561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGZ",
          "name": "PERUNGUZHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.830245,
            8.613629999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQU",
          "name": "MURUKKAMPUZHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.854482,
            8.587612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXP",
          "name": "KANIYAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.872235,
            8.555848000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZK",
          "name": "KAZHAKUTTAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8829192029,
            8.5225364873
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VELI",
          "name": "VELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8961,
            8.509254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCVL",
          "name": "KOCHUVELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.39016,
            27.087081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUW",
          "name": "BURHWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.449932,
            27.100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKG",
          "name": "CHOWKA GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.679695,
            27.583612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPC",
          "name": "SITAPUR CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.6871628082,
            27.5646029424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STRK",
          "name": "SITAPUR KUTCHERY HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.698784,
            27.559468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCC",
          "name": "SITAPUR CANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.761641,
            27.579425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKUR",
          "name": "TAPPA KHAJURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.836816,
            27.582038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSN",
          "name": "PARSENDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.897107,
            27.545116
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAMR",
          "name": "RAMAIPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.990464292,
            27.4894825714
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVN",
          "name": "BISWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.01440500000001,
            27.447221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKLP",
          "name": "SHANKARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.042877,
            27.396332
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYU",
          "name": "SARAYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.070314,
            27.349971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBPR",
          "name": "BABUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.114404,
            27.288294999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMB",
          "name": "MAHMUDABAD AVADH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.139283,
            27.255493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGPA",
          "name": "MUNDA GOPAL ASHRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.157557,
            27.230021
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPE",
          "name": "PAINTEPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.203969,
            27.168445000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSF",
          "name": "TAHSIL FATEHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.28688016720001,
            27.121414190200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDAM",
          "name": "SUNDHIAMAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.007541,
            27.837593
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRTA",
          "name": "BARTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.068986,
            27.80926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UCH",
          "name": "UNCHAULIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.1392638623,
            27.782774131100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNQ",
          "name": "LAKHNAURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.172851,
            27.768418999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBG",
          "name": "JUNG BAHADURGNJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.20962499999999,
            27.754198000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZN",
          "name": "MAHMUDPUR SARAIYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.2393415985,
            27.743837588199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKH",
          "name": "JAHANIKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.3378219061,
            27.7175372166
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MINJ",
          "name": "MAIKALGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.393681,
            27.688832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NERI",
          "name": "NERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.451791,
            27.659295999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LCN",
          "name": "LALPUR CHANDRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.47707299999999,
            27.64872
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAHO",
          "name": "MAHOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.514006,
            27.632870999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDX",
          "name": "URDAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.5626491565,
            27.6162823291
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HMP",
          "name": "HEMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.614094,
            27.616759000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KATA",
          "name": "KATILI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.846025,
            25.827009999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGDS",
          "name": "BHAGWANPUR DESU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.907174,
            25.795385
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARG",
          "name": "ANGAR GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.975081,
            25.746997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRN",
          "name": "NARHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.035742,
            25.746302
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROA",
          "name": "RUSERA GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0634696423,
            25.7492132968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MABB",
          "name": "MABBI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.111011,
            25.749917999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NWC",
          "name": "NAYA NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.46525100000001,
            25.505891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGG",
          "name": "KHAGARIA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.554446,
            25.509398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNE",
          "name": "MANSI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5971965247,
            25.491823628699997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDBN",
          "name": "CHEDHABANNI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1373006799,
            25.7394205815
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAPN",
          "name": "BARAIPURA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.18537099999999,
            25.714772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPO",
          "name": "HASANPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.19704200000001,
            25.685978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSRH",
          "name": "SHASAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSKG",
          "name": "DSKG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.226371,
            25.631435999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOPR",
          "name": "SONMA PRANPUR(H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.260279,
            25.603476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLNA",
          "name": "SALAUNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.302557,
            25.555629
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IMLI",
          "name": "IMLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.324828,
            25.531869
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRGA",
          "name": "PAHARJAGANGAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.37012200000001,
            25.517459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OLP",
          "name": "OLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.641818,
            25.46082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSK",
          "name": "MAHES KHUNT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.674696,
            25.438433999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAI",
          "name": "GOUCHHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.743883,
            25.403765
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSR",
          "name": "PASRAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.806223,
            25.400724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRKD",
          "name": "BHARATKHAND HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.805753,
            25.400254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRKB",
          "name": "BHARATKHAND HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.85938800000001,
            25.398348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNR",
          "name": "NARAYANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.934758,
            25.393571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THB",
          "name": "THANA BIHPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9852379138,
            25.391339123
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIGH",
          "name": "BAGRIGRAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.014958,
            25.388883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHQ",
          "name": "KHARIK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.09387,
            25.385261
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNA",
          "name": "NAUGACHIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.19483600000001,
            25.400488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTRH",
          "name": "KATAREAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.253655,
            25.440774
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUE",
          "name": "KURSELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3353004456,
            25.4998407393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKHR",
          "name": "BAKHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3553198425,
            25.4900643144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRZR",
          "name": "BARARI BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3778270438,
            25.500006821499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRR",
          "name": "KARAGOLA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.47044100000001,
            25.524483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMO",
          "name": "SEMAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6025963629,
            25.5613904376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYT",
          "name": "NAYATOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6422368182,
            25.5724052479
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNQ",
          "name": "DANDKHORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.67179759090001,
            25.5854196549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRR",
          "name": "GORPHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7176386707,
            25.604753957099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SI",
          "name": "SONALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.74611696720001,
            25.614900999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHNP",
          "name": "BISHANPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.775042724,
            25.613217456300003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAU",
          "name": "JHAUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8024827136,
            25.6140514163
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MENP",
          "name": "MEENAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8375132959,
            25.6156715055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRI",
          "name": "SALMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDYD",
          "name": "BDYD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGBB",
          "name": "SGBB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSPC",
          "name": "BSPC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSGS",
          "name": "BSGS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.311408,
            9.741742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VAY",
          "name": "VAYALAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.325151,
            9.690992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRTL",
          "name": "SHERTALAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.32628100000001,
            9.641848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRVZ",
          "name": "TIRUVIZHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.31801800000001,
            9.597994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAKM",
          "name": "MARARIKULAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.318097,
            9.567254
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAVR",
          "name": "KALAVUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.321857,
            9.519169999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMPY",
          "name": "TUMBOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.32247600000001,
            9.483759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALLP",
          "name": "ALLEPPEY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.681926,
            11.292723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPM",
          "name": "TOTIYAPALAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.34104699999999,
            18.381027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMX",
          "name": "KOMATIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.346343,
            18.400797
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQJ",
          "name": "MARADAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.620208194,
            16.3531150213
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOD",
          "name": "MORAMPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.33390200000001,
            18.293291
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPI",
          "name": "GAJAPATINAGARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.350493,
            18.214489999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRBL",
          "name": "GARUDUBILLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.39573399999999,
            18.111319
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTLM",
          "name": "GOTLAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.412196,
            19.102692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LDX",
          "name": "LADDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.42234400000001,
            19.044582000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMPT",
          "name": "JIMIDIPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.314358,
            23.347039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AOR",
          "name": "ARGORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.440724,
            18.966946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNRT",
          "name": "KUNERU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.457557,
            18.889176000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMDA",
          "name": "GUMADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.433701,
            18.783875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVPT",
          "name": "PARVATIPURAM TN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.42696600000001,
            18.770670000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PVP",
          "name": "PARVATIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.406282,
            18.737614999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NSX",
          "name": "NARSIPURAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.375524,
            18.672547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNM",
          "name": "SITANAGARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.34572200000001,
            18.576763999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VBL",
          "name": "BOBBILI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.350942,
            18.468756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNV",
          "name": "DONKINAVALASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.389455,
            22.661900000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLH",
          "name": "BELGHARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.384761,
            22.682505000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AGP",
          "name": "AGARPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.382193,
            22.698850999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEP",
          "name": "SODPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37715700000001,
            22.725065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDH",
          "name": "KHARDAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37405700000001,
            22.741680000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGH",
          "name": "TITAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.371428,
            22.7610653
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BP",
          "name": "BARRACKPORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.369524,
            22.782449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTF",
          "name": "PALTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37342,
            22.799819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IP",
          "name": "ICHHAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.380423,
            22.828781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNR",
          "name": "SHYAMNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.39459599999999,
            22.850814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGDL",
          "name": "JAGADAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.40450100000001,
            22.86517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNR",
          "name": "KANKINARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.4185,
            22.888555
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NH",
          "name": "NAIHATI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.409935,
            22.908445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GFAE",
          "name": "GARIFA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3319472,
            22.5572086
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HYG",
          "name": "HOOGHLY GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.16169,
            23.758691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNH",
          "name": "DEONAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.16192797000001,
            23.75873842
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDGA",
          "name": "DUGDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.93066368230001,
            25.3156017921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LOT",
          "name": "LOHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.83483412599999,
            25.3398947588
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHH",
          "name": "CHAUKHANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.76954900000001,
            25.35077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWPR",
          "name": "SEWAPURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.717694,
            25.357848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEH",
          "name": "KAPSETI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.63716199999999,
            25.37052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRF",
          "name": "PARSIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.5583597,
            25.3952636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOY",
          "name": "BHADOHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.48463699999999,
            25.427572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOF",
          "name": "MONDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.423665,
            25.461039999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAW",
          "name": "SURIAWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.34519800000001,
            25.516633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SQN",
          "name": "SARAI KANSRAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.310962,
            25.549911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNH",
          "name": "JANGHAI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.379316,
            26.477460999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPB",
          "name": "KANPUR BRIDGE LEFT BANK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.428892,
            26.523705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGW",
          "name": "MAGARWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.486199,
            26.548354
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ON",
          "name": "UNNAO JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.559833,
            26.586191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIC",
          "name": "SONIK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.62098800000001,
            26.61721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJ",
          "name": "AJGAIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.6542605011,
            26.639853447100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVX",
          "name": "KUSUMBHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.68894609920001,
            26.6635672889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTU",
          "name": "JAITIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.74800300000001,
            26.70651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRN",
          "name": "HARAUNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.87313999999999,
            26.794514000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMV",
          "name": "LUCKNOW ALAMBAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKOY",
          "name": "LKOY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.798664,
            26.743403999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POF",
          "name": "PIPARSAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.841544,
            26.77443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMS",
          "name": "AMAUSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.890615,
            26.810176
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKG",
          "name": "MANAK NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.984093,
            26.687033
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLJ",
          "name": "MOHANLALGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.007582,
            26.618868
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKAH",
          "name": "KANKAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.045358,
            26.567225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHN",
          "name": "NIGOHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.077837,
            26.522626
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAGR",
          "name": "SHRIRAJNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.10545649999999,
            26.4773211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCN",
          "name": "BACHHRAWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.13642,
            26.395661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVG",
          "name": "KUNEANGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.160841,
            26.34316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HCP",
          "name": "HARCHANDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.201419,
            26.291456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GANG",
          "name": "GANGAGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.23932,
            26.232684
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RBL",
          "name": "RAE BARELI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.325346,
            26.230209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RUM",
          "name": "RUPAMAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.41565100000001,
            26.244522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FTG",
          "name": "FURSATGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.51914,
            26.262162
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAIS",
          "name": "JAIS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.539941,
            26.257189
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCJ",
          "name": "KASIMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.620936,
            26.227487999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BANI",
          "name": "BANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.68672600000001,
            26.20341
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNG",
          "name": "GAURIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.7361317209,
            26.1769257746
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLKH",
          "name": "TALAKHAJURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.806276,
            26.153024000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AME",
          "name": "AMETHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.84839619259999,
            26.114370421700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFL",
          "name": "MISRAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.864192,
            26.096303
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHJP",
          "name": "SAHAJIPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.8977879919,
            26.055162063100003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANTU",
          "name": "ANTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.92886622500001,
            26.0276401405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGJ",
          "name": "JAGESHARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.004241,
            25.947331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIL",
          "name": "CHILBILA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.00177799999999,
            25.913308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBH",
          "name": "PARTAPGARH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.012834469,
            25.855539958599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHV",
          "name": "PIRTHIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.040632,
            25.801595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DND",
          "name": "DANDUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.091774,
            25.749367000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRX",
          "name": "GAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.14656199999999,
            25.698842000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWS",
          "name": "SUWANSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.190552,
            25.652268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSE",
          "name": "BADSHAHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.24969700000001,
            25.600347
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBP",
          "name": "NIBHAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.943313,
            20.529594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MACR",
          "name": "MANGULI CHOWDWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGPW",
          "name": "KGPW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08116700000001,
            23.067871
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDBR",
          "name": "GOONDA BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0297573352,
            23.082344321900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHMR",
          "name": "JHIMRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0119228906,
            23.091614317500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNSL",
          "name": "HASLANG P.H.",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.98911700000001,
            23.098392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LTMD",
          "name": "LATEMDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.970936,
            23.095049
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKKI",
          "name": "BAKARKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.930834,
            23.132246
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TUL",
          "name": "TIRALDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.909013,
            23.196775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSIA",
          "name": "SUISA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.858638,
            23.26814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRAN",
          "name": "TORANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8587351117,
            23.291632860900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ILO",
          "name": "ILLOO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.31521255,
            28.650503565399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANVT",
          "name": "ANAND VIHAR TRM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.699434,
            28.347316
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WIR",
          "name": "WAIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2071583875,
            27.244947755000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WSC",
          "name": "SHIVARAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.102793,
            22.796889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMH",
          "name": "GAMHARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.13389199999999,
            23.811038
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TELO",
          "name": "TELO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.158954,
            22.782212
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADTP",
          "name": "ADITYAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.20056000000001,
            22.768954
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TATA",
          "name": "TATANAGAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.228747,
            22.75832
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLJR",
          "name": "SALGA JHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVDP",
          "name": "GVDP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.313908,
            22.712394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASB",
          "name": "ASANBONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.354133,
            22.674292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RHE",
          "name": "RAKHA MINES",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.410494,
            22.648871999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUD",
          "name": "GALUDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.479768,
            22.58616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTS",
          "name": "GHATSILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.551921,
            22.506293
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVM",
          "name": "DALBHUMGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.642797,
            22.474065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKPR",
          "name": "KOKPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.721161,
            22.478149
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKU",
          "name": "CHAKULIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.77839399999999,
            22.482202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNM",
          "name": "KANIMAHULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.853201,
            22.488471999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GII",
          "name": "GIDNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.93022400000001,
            22.477455
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KATB",
          "name": "KHATKURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.06450201000001,
            22.8235001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIRP",
          "name": "BIRARAJPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.047863,
            22.852966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KND",
          "name": "KANDRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.110242,
            23.420983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBDA",
          "name": "BARBENDA (RNC DIVISION)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.067757,
            22.875933
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KZU",
          "name": "KUNKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.142459,
            23.416141999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUG",
          "name": "GARH JAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.066359,
            22.919528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIK",
          "name": "MANIKUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.225425,
            23.415513
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAS",
          "name": "CHAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.07210900000001,
            22.956014
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNI",
          "name": "CHANDIL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.275105,
            23.396293999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTD",
          "name": "GOURINATHDHAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.142118,
            22.993643
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIM",
          "name": "NIMDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.19920954,
            23.04578437
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRMD",
          "name": "BIRAMDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.2112372,
            23.0912864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBM",
          "name": "BARABHUM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.253775,
            23.157777
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URMA",
          "name": "URMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.29629,
            23.21838
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTD",
          "name": "KANTADIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.352313,
            23.268988
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAO",
          "name": "TAMNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.37787,
            23.324911
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRR",
          "name": "PURULIA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9985316705,
            22.4549644863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGM",
          "name": "JHARGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.062669671,
            22.420974052600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNB",
          "name": "BANSTOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.12364500000001,
            22.380612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUA",
          "name": "SARDIHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.18486299999999,
            22.337768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSO",
          "name": "KHEMASULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.24399951,
            22.32945442
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKQ",
          "name": "KALAIKUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.26468600000001,
            22.332403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMP",
          "name": "NIMPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.993925,
            20.558554
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIS",
          "name": "KAPILAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0192559912,
            20.5965342443
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJDR",
          "name": "SRI JHADESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.034947,
            20.628257
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYY",
          "name": "BYREE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.05967143000001,
            20.65696901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRTG",
          "name": "BARITHENGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.11228,
            20.687806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNM",
          "name": "DHANMANDAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.116431,
            20.725882000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDS",
          "name": "HARIDASPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.07210599999999,
            20.798857
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGMP",
          "name": "NEW GARH MADHOPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.06508,
            20.850842
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JEN",
          "name": "JENAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0562098645,
            20.862952007599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRMI",
          "name": "BRAHMANI PH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.06533999999999,
            20.917821
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKPR",
          "name": "JAKHAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.132518,
            20.943544
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JJKR",
          "name": "JAJPUR KEONJHAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.17599399999999,
            20.959417000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRIH",
          "name": "KORAI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.23539299999999,
            20.986995999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTV",
          "name": "BAITARANI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.28087400000001,
            21.007019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLPT",
          "name": "DULAKHAPATNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.319698,
            21.012135999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZZ",
          "name": "MANJURI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.38353099999999,
            21.030623000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KED",
          "name": "KENDUAPADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.407911,
            21.040967000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPLD",
          "name": "KAPALI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4629040051,
            21.063446010800003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUDR",
          "name": "BAUDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5162305,
            21.091745799999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHC",
          "name": "BHADRAKH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLJC",
          "name": "RLJC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.55647400000001,
            21.149665
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNTL",
          "name": "RANITAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.596117,
            21.195555
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKO",
          "name": "MARKONA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.62923699999999,
            21.236882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SZZ",
          "name": "SABIRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.69253900000001,
            21.291083999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SORO",
          "name": "SORO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.76172839,
            21.33613403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNBR",
          "name": "BAHANAGA BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.790524,
            21.358714999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNPN",
          "name": "PANPANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.822911,
            21.39628
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHF",
          "name": "KHANTAPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.855469,
            21.426415000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGRD",
          "name": "NILGIRI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.91958199999999,
            21.500521
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLS",
          "name": "BALASORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.944638,
            21.535575
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKPL",
          "name": "TIKIRAPAL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.996648,
            21.571438999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HIP",
          "name": "HALDIPADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.018507,
            21.621995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROP",
          "name": "RUPSA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.02051399999999,
            18.491630999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LONI",
          "name": "LONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.13682,
            18.494237
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URI",
          "name": "URULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.873086,
            18.529378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUNE",
          "name": "PUNE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.8917897095,
            18.531687546900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPRW",
          "name": "GHORPURI WEST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.927844,
            18.526887000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDP",
          "name": "HADAPSAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.971424,
            18.512039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJBK",
          "name": "MANJARI BUDRUK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.2563176276,
            18.4889359723
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YT",
          "name": "YEVAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3154633105,
            18.4861041325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTT",
          "name": "KHUTBAV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.3763730994,
            18.482085398400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDG",
          "name": "KEDGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.42173336430001,
            18.4778037603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDTN",
          "name": "KADETHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4696527191,
            18.4732663908
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAA",
          "name": "PATAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.520934,
            18.468688
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDAP",
          "name": "DAUND PATAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.578622,
            18.463547
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DD",
          "name": "DAUND JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5689755029,
            18.464752713200003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDAM",
          "name": "DAUND A MMR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5607624341,
            18.4659368312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDJA",
          "name": "DAUND GOODS YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.5994004132,
            18.5252942346
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSTH",
          "name": "KASHTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.641466,
            18.598243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGND",
          "name": "SHRIGONDA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.621981,
            18.710718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWD",
          "name": "BELVANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.598861,
            18.82295
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPR",
          "name": "VISAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.60070300000001,
            18.870972
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNJD",
          "name": "RANJANGAON RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.64829499999999,
            18.947656000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRL",
          "name": "SAROLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.66906999999999,
            18.985409
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKR",
          "name": "AKOLNER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.721882467,
            19.075519477700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANG",
          "name": "AHMADNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.68854097469999,
            19.1183528756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NNB",
          "name": "NIMBLAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.675069,
            19.198579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VL",
          "name": "VILAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.684974,
            19.298579999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VBR",
          "name": "VAMBORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.677702,
            19.411550000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RRI",
          "name": "RAHURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.679765,
            19.453844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKMY",
          "name": "TAKLIMIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.69233,
            19.536709000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDGN",
          "name": "PADHEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.70471599999999,
            19.587951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPW",
          "name": "NIPANI VADGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6617443079,
            19.619569311800003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAP",
          "name": "BELAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.6239138208,
            19.6913918118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CIT",
          "name": "CHITALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.621959715,
            19.762025526800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PB",
          "name": "PUNTAMBA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.57128710079999,
            19.8172996303
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNGN",
          "name": "KANHEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.56058409549999,
            19.8832775017
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNVR",
          "name": "SANVATSAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.503328,
            19.902943
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPG",
          "name": "KOPARGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.503246,
            20.043188999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YL",
          "name": "YEOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4697560736,
            20.1510279143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANK",
          "name": "ANKAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.4368553961,
            20.183180430100002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AAK",
          "name": "ANKAI KILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.507613,
            20.932994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SS",
          "name": "SHIRSOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4584622504,
            20.870401921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWD",
          "name": "MHASAVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.424619975,
            20.7689244313
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYJ",
          "name": "MAHEJI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.58081,
            23.659779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RCGT",
          "name": "RICHUGHUTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.52562,
            23.69708
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEMU",
          "name": "DEMU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.46812,
            23.725092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LTHR",
          "name": "LATEHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.416974,
            23.763612000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BFQ",
          "name": "BENDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.352452,
            23.766868000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMND",
          "name": "KUMENDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.258524,
            23.790124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HHG",
          "name": "HEHEGARA HALT (E)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2023836389,
            23.8133333478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPDR",
          "name": "CHHIPADOHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.11877999999999,
            23.843836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRWD",
          "name": "BARWADIH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.111898,
            23.881768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAZ",
          "name": "MANGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.146829,
            23.928362999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCKI",
          "name": "KECKHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.11566,
            23.973902
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNF",
          "name": "CHIANKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.07484299999999,
            24.033075
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTO",
          "name": "DALTONGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0725284845,
            24.1067922143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFT",
          "name": "KAJRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.01554999999999,
            24.154400000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RHR",
          "name": "RAJHURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.979632,
            24.161147
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LBT",
          "name": "LALGARH BIHAR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.923324,
            24.18584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRZ",
          "name": "TOLRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.885116,
            24.214419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHD",
          "name": "GARWA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.204658,
            24.811468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCJ",
          "name": "BAGAHABISHUNPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.178495,
            24.773978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSYA",
          "name": "BARKISALALYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.169539,
            24.761166999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANH",
          "name": "ANKORAH AKORHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.094044,
            24.653944
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBG",
          "name": "NABENAGAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.05014,
            24.591041
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYF",
          "name": "KAJRAT NAWADIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.00286600000001,
            24.538968999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPL",
          "name": "JAPLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.951049,
            24.498081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDN",
          "name": "HAIDARNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.908564,
            24.478657000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVQ",
          "name": "KOSIARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.869978,
            24.42644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDJ",
          "name": "MUHAMMADGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.84911,
            24.386582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SZF",
          "name": "SATBAHINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.834497,
            24.34094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "URD",
          "name": "UNTARE ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.837652,
            24.296038000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRTA",
          "name": "KARKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.853323,
            24.260566999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SQS",
          "name": "SIGSIGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.520249,
            23.617327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMT",
          "name": "RAMGARH CANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.469553,
            23.624159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRKA",
          "name": "BARKAKANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.626042,
            23.574668000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAEL",
          "name": "MAEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3721452,
            23.6375642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHKD",
          "name": "BHURKUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.64647599999999,
            23.568766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRKP",
          "name": "BARKIPONA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.30587700000001,
            23.667024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTRU",
          "name": "PATRATU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.697139,
            23.545551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRE",
          "name": "GOLA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.239282,
            23.678639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKS",
          "name": "TOKISUD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.76022876969999,
            23.4999492658
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRBR",
          "name": "HARUBERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.169817,
            23.697803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNDR",
          "name": "HENDEGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.782435799,
            23.476936582300002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SND",
          "name": "SONDIMRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.104901,
            23.695961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOLE",
          "name": "KOLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.833473,
            23.449913
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLNG",
          "name": "BARLANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.077816,
            23.690082
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAY",
          "name": "RAY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.001627,
            23.651055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLRE",
          "name": "KHALARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.940855,
            23.651781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGME",
          "name": "MCCLUSKIEGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.854527,
            23.662240999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMLN",
          "name": "MAHUAMILAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.739774,
            23.680808
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TORI",
          "name": "TORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.646426,
            23.682881000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTQ",
          "name": "CHETAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.91385700000001,
            22.233321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCAB",
          "name": "K-CABIN BONDAMUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.945577,
            22.23969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACAB",
          "name": "A-CABIN BONDAMUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.974611,
            22.267173000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNKC",
          "name": "LINK C-CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.33352,
            23.348811
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNC",
          "name": "RANCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.37667200000001,
            23.351755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKM",
          "name": "NAMKON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.435706,
            23.369844999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIS",
          "name": "TATISILWAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5070501038,
            23.3613982411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAG",
          "name": "GANGAGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.5948557446,
            23.360567527599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWKC",
          "name": "KHERWA KOCHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6089136389,
            23.3589592105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JON",
          "name": "JONHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.76424803159999,
            23.364884802800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KITA",
          "name": "KITA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.82812938660001,
            23.351563397099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLF",
          "name": "SILLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8669906885,
            23.376284371
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MURI",
          "name": "MURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.902125,
            23.378363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THO",
          "name": "TULIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.97528199999999,
            23.370005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAA",
          "name": "JHALIDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01258700000001,
            23.383687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKDR",
          "name": "BEGUN KUDAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.079548,
            23.411691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSX",
          "name": "KOTSHILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08981,
            23.451359999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRGU",
          "name": "DAMRU GHUTU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPMY",
          "name": "RPMY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NFKS",
          "name": "NFKS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NFKN",
          "name": "NFKN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08957600000001,
            23.508909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNW",
          "name": "PUNDHAG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08906499999999,
            23.59787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDF",
          "name": "RADHAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08772599999999,
            23.649514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKCA",
          "name": "BOKARO STEEL CITY A CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.08501600000001,
            23.656551
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKSC",
          "name": "BOKARO STEEL CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.078541,
            23.671356
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKSN",
          "name": "BKSC NORTH CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0638904572,
            23.7136175901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKB",
          "name": "TUPKADIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.080936,
            23.751105
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJB",
          "name": "RAJABERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.119775,
            23.755676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRP",
          "name": "CHANDRAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1921944211,
            23.754897530999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNNA",
          "name": "JAMUNIATANR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.193727,
            23.762742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMX",
          "name": "JAMUNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.226577,
            23.77515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLJE",
          "name": "PHULWARTANR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.23544766479999,
            23.7785572386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDQ",
          "name": "BUDORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.252293,
            23.788733
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNO",
          "name": "TUNDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.265063,
            23.7915
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SZE",
          "name": "SONARDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.277173,
            23.790335
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNJE",
          "name": "TENTULLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.302399,
            23.795757000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTH",
          "name": "KATRASGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.319368,
            23.793062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANJE",
          "name": "ANGARPATHRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3297280994,
            23.7901556866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJA",
          "name": "SIJUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.354545,
            23.789931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZS",
          "name": "BANSJORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.381653,
            23.786754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZE",
          "name": "BASERIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.39589356020001,
            23.7899886114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDS",
          "name": "KASUNDA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8677507141,
            25.5946164603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWPB",
          "name": "BHAWANIPUR BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.88715343380001,
            25.6292447936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFA",
          "name": "MAKURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.9229421,
            25.651242200000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOE",
          "name": "BARSOI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.78193599999999,
            26.334952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDB",
          "name": "HALDIBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.76856599999999,
            26.384653999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSBI",
          "name": "KASHIABARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.754758,
            26.430757
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDG",
          "name": "MANDAL GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.73098599999999,
            26.476201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDX",
          "name": "KADABARI HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.723026,
            26.51816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPG",
          "name": "JALPAIGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.669651,
            26.53055
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOP",
          "name": "MOHITNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8098941528,
            25.4483399037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAHN",
          "name": "BABHABGAON HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.82685530639999,
            25.4911139274
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWE",
          "name": "KHURIAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8374616345,
            25.5183120087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMPH",
          "name": "KAMALPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8523278357,
            25.555555642399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AZR",
          "name": "AZAMNAGAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHMP",
          "name": "KHEMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.890377,
            25.706241000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJGM",
          "name": "SANJAY GRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.87948867189999,
            25.727853611399997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUD",
          "name": "SUDHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.848322,
            25.781637999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AHL",
          "name": "AJHARAIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.84221,
            25.806579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TETA",
          "name": "TELTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.83887700000001,
            25.873416
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLK",
          "name": "DALKOLHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.849258,
            25.935932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJKL",
          "name": "SURJA KAMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.864524,
            26.003232999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKA",
          "name": "KANKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.89303199999999,
            26.044989
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HWR",
          "name": "HATWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.9308854236,
            26.0849205699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THED",
          "name": "TAUHEED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.949657,
            26.097746
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNE",
          "name": "KISHANGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.0180577417,
            26.140165652700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PJP",
          "name": "PANJIPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IKC",
          "name": "IKARCHALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.0895072131,
            26.1848261159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GIL",
          "name": "GAISAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.132222,
            26.218465000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GEOR",
          "name": "GUNJARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.18561000000001,
            26.269681
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AUB",
          "name": "ALUABARI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.231234,
            26.361571
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBQ",
          "name": "DHULABARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.256909,
            26.412488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXJ",
          "name": "MANGURJAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.279995,
            26.436601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMH",
          "name": "TIN MILE HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.296508,
            26.47724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMZ",
          "name": "DUMDANGI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.323376,
            26.548827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAT",
          "name": "CHATTAR HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.337505,
            26.587597
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NJB",
          "name": "NIJBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37438599999999,
            26.664263000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNI",
          "name": "RANGAPANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.443391,
            26.683025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NJP",
          "name": "NEW JALPAIGURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.510593,
            26.641553000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ABFC",
          "name": "AMBARI FALAKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.583312,
            26.591441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLK",
          "name": "BELAKOBA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.64829300000001,
            26.544663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RQJ",
          "name": "RANINAGAR JALPAIGIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.504707,
            26.594374000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMI",
          "name": "SITAMARHI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.533501,
            26.576528999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHSA",
          "name": "BHISA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.574611,
            26.55631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSZ",
          "name": "PARSAUNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.62061299999999,
            26.546976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJT",
          "name": "BAJPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.652916,
            26.508916000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AWPR",
          "name": "AWAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.66252866580001,
            26.4946286292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCPR",
          "name": "BACHHARPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.70085599999999,
            26.467914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNR",
          "name": "JANAKPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.72316500000001,
            26.440884
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNX",
          "name": "CHANDAUNA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.74673274050001,
            26.407114392700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGA",
          "name": "JOGIARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.76995,
            26.384826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBLI",
          "name": "DEBRABANDHAULI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.786612,
            26.369764999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRTA",
          "name": "MURAITHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.808624,
            26.337348000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KML",
          "name": "KAMTAUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.846291,
            26.275833
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TQR",
          "name": "TEKTAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.852798,
            26.236878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHP",
          "name": "MUHAMMADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.863867,
            26.206032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHEO",
          "name": "SHISHO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.378006,
            26.122311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFP",
          "name": "MUZAFFARPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.409122,
            26.089052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRPA",
          "name": "NARAYANPUR ANANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.44337800000001,
            26.048926
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLT",
          "name": "SILAUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.50594000000001,
            26.00841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIHO",
          "name": "SIHO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.562495,
            25.976952
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOL",
          "name": "DHOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.617108,
            25.948784999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUBH",
          "name": "DUBAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.64428754389999,
            25.931020256700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VBH",
          "name": "VISHNUPUR BATHUA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.672677,
            25.906434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRBP",
          "name": "KHUDIRAM B PUSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.72417800000001,
            25.869802
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPGM",
          "name": "KARPURIGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.64432343200001,
            25.4955599448
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUQ",
          "name": "KURETHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.67395400000001,
            25.478182
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAJH",
          "name": "SAHJA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.70486823670001,
            25.4623621243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQD",
          "name": "PRANPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.74514032169999,
            25.439646755400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAV",
          "name": "LABHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.778612,
            25.432721
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DVJ",
          "name": "DILLI DEWAN GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.0833990649,
            25.1883357022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBC",
          "name": "MAHANANDA BGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.065592,
            25.218816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMRJ",
          "name": "KUMARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.0440169482,
            25.2570761576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRPU",
          "name": "SRIPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.00975700000001,
            25.276197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SM",
          "name": "SAMSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.95782,
            25.303708999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFZ",
          "name": "MALAHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.9121276,
            25.3425716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKRD",
          "name": "BHALUKA ROAD F",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8962231482,
            25.368746039300003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQG",
          "name": "MILANGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.867051,
            25.403999000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HCR",
          "name": "HARISCHANDRPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.80435766599999,
            25.4334484028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDPR",
          "name": "KUMEDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.566409,
            25.548947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIR",
          "name": "KATIHAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6069420667,
            25.5232741031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIYN",
          "name": "MANIYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.578873,
            25.552735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIRB",
          "name": "KATIHAR CBO MB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.560853,
            25.6118
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLF",
          "name": "DALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.523472,
            25.674635000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RWA",
          "name": "RAUTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.514183,
            25.735576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNX",
          "name": "RANIPATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.519124,
            25.790678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRNA",
          "name": "PURNIA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.529476,
            25.860675
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUB",
          "name": "KASBA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.535041,
            25.915091
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBN",
          "name": "GARH BANAILI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.529496,
            25.953959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAG",
          "name": "JALARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.508427,
            26.001172999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SATR",
          "name": "SATR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.47981800000001,
            26.057858000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSY",
          "name": "KUSIARGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.446912,
            26.114921000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARQ",
          "name": "ARARIYA COURT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.41199809689999,
            26.137118266799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARR",
          "name": "ARARIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.35900971560001,
            26.165935176599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HOD",
          "name": "HALDITA BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.32135753540001,
            26.1944356285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMH",
          "name": "SIMRAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.284335,
            26.250043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPE",
          "name": "GOGIPOTHIA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.27139700000001,
            26.2748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLZ",
          "name": "DHOLBAJA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.262803,
            26.298901
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FBG",
          "name": "FORBESGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.237767,
            26.341854
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTF",
          "name": "BATHNAHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.263262,
            26.402263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBN",
          "name": "JOGBANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.318224,
            22.661604999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZL",
          "name": "BELANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.757636,
            25.232648
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLGT",
          "name": "BALURGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.676426,
            25.325703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKRH",
          "name": "MALLICKPUR HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.60611,
            25.358763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMPB",
          "name": "RAMPUR BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.586067,
            25.368716000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLNH",
          "name": "MALANCHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.516447,
            25.390399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRMP",
          "name": "GANGARAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.402088,
            25.386267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNDP",
          "name": "BUNIADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33278722,
            25.32611273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLPH",
          "name": "DAULATPUR HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.294214,
            25.274328999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOTL",
          "name": "DEOTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.251102,
            25.247077
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MANG",
          "name": "MAHANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.19888499999999,
            25.224984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZO",
          "name": "GAZOLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.81052581,
            24.82345158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDAG",
          "name": "BONIDANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.859263,
            24.792848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDBS",
          "name": "BINDUBASINI HAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.873801,
            24.786749
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDLE",
          "name": "TILDANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.91579300000001,
            24.782115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NFKB",
          "name": "NEW FARAKKA-B-CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.915236,
            24.794558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NFK",
          "name": "NEW FARAKKA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.96334499999999,
            24.821334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMX",
          "name": "CHAMAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.021646,
            24.875742000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTJ",
          "name": "KHALTIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.06775999999999,
            24.916623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMQ",
          "name": "JAMIRGHATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.11078197,
            24.947944439999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZM",
          "name": "GOUR MALDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.130338,
            25.015659
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLDT",
          "name": "MALDA TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.143718,
            25.053174000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OMLF",
          "name": "OLD MALDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.12318,
            25.114396999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADF",
          "name": "ADINA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.099492,
            25.167868000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EKI",
          "name": "EKLAKHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YY-BJUC",
          "name": "YY-BJUC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.820294,
            27.08393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JDA",
          "name": "JASODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.78027300000001,
            27.098961
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHDP",
          "name": "KHUDLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.732035,
            27.118804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHJ",
          "name": "GURSAHAIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.690181,
            27.161781
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLKP",
          "name": "MALIKPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.666264,
            27.184464000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDJ",
          "name": "KHUDAGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.62941099999999,
            27.262707
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGRP",
          "name": "SINGHIRAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.620832,
            27.292663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLJ",
          "name": "KAMALGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.618493,
            27.32595
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YAG",
          "name": "YAQUTGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.62159,
            27.369608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FGR",
          "name": "FATEHGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.57173700000001,
            27.38236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FBD",
          "name": "FARRUKHABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.49825360349999,
            27.4300235579
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSY",
          "name": "HARSINGPUR GOBA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.471943,
            27.44826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHX",
          "name": "SHUKHARULLAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.438167,
            27.469911999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSD",
          "name": "SHAMSABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.38388843,
            27.50375661
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHTS",
          "name": "BHATASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.3272155493,
            27.5409770085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMJ",
          "name": "KAIMGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.265007,
            27.567619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXF",
          "name": "KAMPIL ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.186618,
            27.579928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDN",
          "name": "RUDAIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.1451478125,
            27.5865985855
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAPR",
          "name": "BALLUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0824955792,
            27.5980988686
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRO",
          "name": "DARYAOGONJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.02313742000001,
            27.6329307124
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHX",
          "name": "NARTHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.9841732028,
            27.6843201328
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTI",
          "name": "PATIALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.939319176,
            27.7264028171
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWA",
          "name": "GANJ DUNDWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.8842900433,
            27.748517604099998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRAK",
          "name": "GARKHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.831344,
            27.794835
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWRT",
          "name": "SAHAWAR TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.734589,
            27.799053
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHK",
          "name": "BADHARI KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.64602599999999,
            27.801887
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSJ",
          "name": "KASGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.55504400000001,
            27.746474000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHA",
          "name": "MARAHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.44455300000001,
            27.705676999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AUL",
          "name": "AGSAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.387573,
            27.684085999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKA",
          "name": "SIKANDRA RAO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.285451,
            27.66742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTRO",
          "name": "BASTOI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.24056900000001,
            27.654398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKN",
          "name": "RATI KA NAGLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.136899,
            27.622436999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTJ",
          "name": "HATHRAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.09995699999999,
            27.614247
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MEU",
          "name": "MENDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.05506869999999,
            27.599816899999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTC",
          "name": "HATHRAS CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.9363,
            27.574211
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSN",
          "name": "MURSAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.88736,
            27.570504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYZ",
          "name": "SONAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.7868838446,
            27.5524062276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAYA",
          "name": "RAYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.685113,
            27.491013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRT",
          "name": "MATHURA CANT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTUJ",
          "name": "MTUJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.328444,
            26.456232999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPA",
          "name": "KANPUR ANWARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.298113,
            26.482981000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPO",
          "name": "RAWATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.25289199999999,
            26.503485
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAP",
          "name": "KALIANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.222507,
            26.557002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDA",
          "name": "MANDHANA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.21142400000001,
            26.598927999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBR",
          "name": "CHAUBEPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.13867760000001,
            26.685165899999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJR",
          "name": "BARRAJPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.095478,
            26.73974
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UTP",
          "name": "UTRIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.08140399999999,
            26.798349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUO",
          "name": "DHAURSALAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.060383,
            26.839002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLU",
          "name": "BILHAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.03776699999999,
            26.87806
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKTS",
          "name": "BAKOTHIKHAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.01863300000001,
            26.920662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARL",
          "name": "AROUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9979940411,
            26.958804182199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWP",
          "name": "GANGAWAPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.9651825488,
            26.98913852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNMU",
          "name": "MANI MAU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.926109,
            27.026507
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJN",
          "name": "KANNAUJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.909983,
            27.039814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJNC",
          "name": "KANNAUJ CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8736107968,
            27.0623131608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPP",
          "name": "JALALPUR PANWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.403718,
            28.3556564
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BC",
          "name": "BAREILLY CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.518125,
            30.776469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMDA",
          "name": "NEW MORINDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.37261500000001,
            31.370543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLDM",
          "name": "NANGAL DAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.420351792,
            31.2990814767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNLY",
          "name": "BHANUPLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.50060350000001,
            31.2305136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANSB",
          "name": "ANANDPUR SAHIB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.563302,
            31.186811000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KART",
          "name": "KIRATPUR SAHIB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.59140316889999,
            31.1104617696
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BARJ",
          "name": "BHARATGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.59214300000001,
            31.025994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GANL",
          "name": "GHANAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.533241,
            30.972816
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPAR",
          "name": "RUPNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.567332,
            30.911265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNPR",
          "name": "MINAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.580921825,
            30.856220295700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTLI",
          "name": "CHATOULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.57193000000001,
            30.835005000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRLI",
          "name": "KURALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.502461,
            30.791519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRND",
          "name": "MORINDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.44328700000001,
            30.738403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGWN",
          "name": "NOGANWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4096692,
            30.6884592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSPN",
          "name": "BASSI PATHANAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.38812399999999,
            30.650726
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FGSB",
          "name": "FATEHGARH SAHIB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCSJ",
          "name": "CCSJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19892399999999,
            27.292310999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTI",
          "name": "MITAWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19231699999999,
            27.32891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRN",
          "name": "BARHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.342818,
            28.670746
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSBP",
          "name": "DELHI SHAHDARA B PANEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.33120100000001,
            28.662871000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNJ",
          "name": "CHANDER NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.3140236,
            28.6486144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANVR",
          "name": "ANAND VIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.253315,
            28.613384
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWC",
          "name": "MANDAWALI CHANDER VIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.180143,
            27.393031999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMR",
          "name": "CHAMROLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.16993,
            27.44546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLS",
          "name": "JALESAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.154207,
            27.532025
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PORA",
          "name": "PORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.13706699999999,
            27.62483
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRS",
          "name": "HATHRAS JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1224601752,
            27.704263607599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNS",
          "name": "SASNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.1102865904,
            27.7686349988
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXK",
          "name": "MANDRAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.09879699999999,
            27.830504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAQ",
          "name": "DAUD KHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.074559,
            27.889584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALJN",
          "name": "ALIGARH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.364182,
            28.674037
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBB",
          "name": "SAHIBABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.431099,
            28.649702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZB",
          "name": "GHAZIABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.45934600000001,
            28.627976999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CYZ",
          "name": "CHIPYANA BUZURG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.495428,
            28.586886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIU",
          "name": "MARIPAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.537422,
            28.537940000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DER",
          "name": "DADRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.56361700000001,
            28.507907999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRKY",
          "name": "BORAKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.59373730349999,
            28.4728347205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJR",
          "name": "AJAIBPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.64321100000001,
            28.415313
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKDE",
          "name": "DANKAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.69926699999999,
            28.347255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WAIR",
          "name": "WAIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.72940894,
            28.31216512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHL",
          "name": "CHOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.75473000000001,
            28.28297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNRL",
          "name": "GANGRAUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.783931,
            28.247759000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKQ",
          "name": "SIKANDARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.81903999999999,
            28.206407000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRJ",
          "name": "KHURJA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83998100000001,
            28.182673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAMP",
          "name": "KAMALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.88391252,
            28.129968509999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAR",
          "name": "DANWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.948193,
            28.052674
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOM",
          "name": "SOMNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.007062,
            27.982509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLA",
          "name": "KULWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.04247000000001,
            27.937875000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWUE",
          "name": "MAHRAWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.70823399999999,
            20.153076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUR",
          "name": "KHURDA ROAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.760851,
            20.199417999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RTN",
          "name": "RETANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.79623099999999,
            20.228282
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRKT",
          "name": "SARKANTRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.81587200000001,
            20.234947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LGTR",
          "name": "LINGARAJ TEMPLE ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.84259499999999,
            20.265388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBS",
          "name": "BHUBANESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.852653,
            20.292611
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNBH",
          "name": "BANI BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.84486600000001,
            20.321861
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCS",
          "name": "MANCHESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.841374,
            20.349273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTAB",
          "name": "PATIA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.832573,
            20.400699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRAG",
          "name": "BARANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.839992,
            19.811459000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PURI",
          "name": "PURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.83177,
            19.865258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLT",
          "name": "MALATIPATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.81927,
            19.888365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JKDP",
          "name": "JANKIDAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.816074,
            19.942882
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIL",
          "name": "SAKHI GOPAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.80635131,
            19.99039983
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRST",
          "name": "BIR PURUSHOTTAMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.786572,
            20.018355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPRD",
          "name": "JENAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.786976,
            20.018615
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JERD",
          "name": "JENAPUR PH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.76695,
            20.038474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DEG",
          "name": "DELANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.7428,
            20.06205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KASR",
          "name": "KANAS ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.72919200000001,
            20.090583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWQ",
          "name": "MOTARI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.707856,
            20.133571999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPGM",
          "name": "HARIPUR GRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.779525,
            20.459324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NQR",
          "name": "NARAJ MARTHAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.744973,
            20.482906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPSR",
          "name": "SARPESWAR PH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.736186,
            20.510921999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHNH",
          "name": "GHANTIKHAL NIDHIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.754538,
            20.559919999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RQP",
          "name": "RADHAKISHOREPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.741633,
            20.576104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJGR",
          "name": "RAJTHAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.640413,
            20.623365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRZ",
          "name": "JORANDA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.684256,
            28.025029999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSRK",
          "name": "KASRAK HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.616279,
            20.642413
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCPR",
          "name": "SHYAMA CHARANPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.222356,
            29.58301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZM",
          "name": "MUZZAMPUR NARAIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.8839,
            30.294992
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOKY",
          "name": "DUKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.933887,
            30.264574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KES",
          "name": "KESRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.98871199999999,
            30.23388
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDW",
          "name": "TANDWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.035501,
            30.213325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAA",
          "name": "BARARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.123303,
            30.181009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFB",
          "name": "MUSTAFABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.195721,
            30.152546
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DZP",
          "name": "DARAZPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.240819,
            30.135558
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUDW",
          "name": "JAGADHRI WORKSHOP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.287602,
            30.116844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUD",
          "name": "YAMUNANAGAR-JAGADHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.341227,
            30.082428
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNZ",
          "name": "KALANOUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.42396000000001,
            30.024226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSW",
          "name": "SARSAWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.472879,
            29.997528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKY",
          "name": "PILKHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.54173899999999,
            29.960529
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRE",
          "name": "SAHARANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.597212,
            29.940002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNC",
          "name": "HINDAN CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.63368,
            29.9285
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAE",
          "name": "BALIAKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.683524,
            29.913102000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNKE",
          "name": "SUNEHTI KHARKHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.28757996649999,
            30.1174255334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YJUD",
          "name": "YAMUNANAGAR JAGADHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.98059500000001,
            26.802226
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHAW",
          "name": "BLOCK HUT A (BHA)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.974031,
            26.763061
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UTR",
          "name": "UTRAHTIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.7825431824,
            27.9522550839
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHDH",
          "name": "BAHADURPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.82818,
            27.929239
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTRA",
          "name": "BANTHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.904605,
            27.894224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPN",
          "name": "SHAHJEHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.913517,
            27.890862000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXK",
          "name": "SHAJAHANPURCORT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.956038,
            27.853121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROZA",
          "name": "ROZA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.957596,
            27.814705
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRPM",
          "name": "PT RAM PRASAD BISMIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.1361698425,
            25.287126389900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGMY",
          "name": "MUGHAL SARAI MARSHALING YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.771397,
            25.471614
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKRH",
          "name": "KARAHIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.80443100000001,
            25.488685
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMR",
          "name": "GAHMAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.8494928638,
            25.4915162541
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQW",
          "name": "BARAKALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.89633140379999,
            25.4984911003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CSA",
          "name": "CHAUSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.960138,
            27.775896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KH",
          "name": "KAHILIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.963236,
            27.700598000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AIG",
          "name": "ALGAWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.973314,
            27.643994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJI",
          "name": "ANJHI SHAHABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.003288,
            27.603853
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHRM",
          "name": "SHARMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.020029,
            27.579601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDP",
          "name": "TODARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.054854,
            27.530433600000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEG",
          "name": "BEHTAGOKUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.966867,
            26.83113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LBH",
          "name": "DILKUSHA CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.719014,
            25.708436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ZBD",
          "name": "ZAFARABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.75211999999999,
            25.643114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIQ",
          "name": "SARKONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.772465,
            25.612516
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLL",
          "name": "JALALGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.804378,
            25.564702
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLMD",
          "name": "TRILOCHAN MAHDO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.83174700000001,
            25.525798
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSF",
          "name": "KHALISPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.851162,
            25.497052
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDRD",
          "name": "PINDRA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.8800248,
            25.453616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTP",
          "name": "BABATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.917265,
            25.398912999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRPT",
          "name": "BIRAPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.946972,
            25.355419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOP",
          "name": "SHIUPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.9751998096,
            25.3252123487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSBY",
          "name": "VARANASI YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.9359635205,
            25.5276889185
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWXP",
          "name": "PAWANI KUMARPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.982184,
            25.562067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BXR",
          "name": "BUXAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.08917199999999,
            25.569781000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUE",
          "name": "BARUNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.051489,
            26.776082000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKKS",
          "name": "BAKAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.126093,
            26.757292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APG",
          "name": "ANUPGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.1558950446,
            26.744797359499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMNR",
          "name": "RAHMAT NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.23044,
            26.699202
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDRL",
          "name": "CHHANDRAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.281105,
            26.667533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TEG",
          "name": "TIRBEDIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.363162,
            26.621533
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HGH",
          "name": "HAIDERGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.43362396730001,
            26.576806771900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHBS",
          "name": "CHAUBISI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.501628,
            26.531456000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKJ",
          "name": "AKBARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.56944,
            26.492121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYW",
          "name": "SINDURWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.632581,
            26.460131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHH",
          "name": "NIHALGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.6954767206,
            26.434504081500002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WGJ",
          "name": "WARIS GANJ HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.726848,
            26.418478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AHZ",
          "name": "ADHINPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.79981699999999,
            26.379585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MFKA",
          "name": "MUSAFIR KHANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.882608,
            26.336305000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHNG",
          "name": "SHIVNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.99539200000001,
            26.271965
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDKN",
          "name": "BANDHUA KALAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.066106,
            26.263027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLN",
          "name": "SULTANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.122568,
            26.205027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKX",
          "name": "PAKHRULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.169148,
            26.171113000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDIN",
          "name": "BHADAIYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.223283,
            26.133687000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LBA",
          "name": "LAMBHUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.288578,
            26.087672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MWP",
          "name": "MAHRANI PACHHIM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.30754300000001,
            26.063822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHDA",
          "name": "CHANDA (HALT)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.33959499999999,
            26.02164
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEPR",
          "name": "KOIRIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.364038,
            25.992862000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIMG",
          "name": "NIMA GOPALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.405421,
            25.947582999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRPG",
          "name": "HARPALGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.447803,
            25.88403
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKN",
          "name": "SRIKRISHNA NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.506309,
            25.835891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVZ",
          "name": "SARAI HARKHU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.57318000000001,
            25.78236
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKSA",
          "name": "BAKHSHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.666999,
            25.734125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOP",
          "name": "JAUNPUR CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.692607,
            25.725186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNUK",
          "name": "JAUNPUR KUTCHERRY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.66259600000001,
            25.548889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARA",
          "name": "ARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.70946636229999,
            25.558928076100003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMIR",
          "name": "JAMIRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.757807,
            25.568172999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUA",
          "name": "KULHARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPPR",
          "name": "CPPR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.14386499999999,
            25.571984
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DURE",
          "name": "DUMRAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.218022,
            25.568766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TWG",
          "name": "TWINING GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.254875,
            25.567584999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKDH",
          "name": "VEER KUNWAR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.305227,
            25.565439
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPR",
          "name": "RAGHUNATHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.34768584109999,
            25.5633290199
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKRI",
          "name": "SIKARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.389916,
            25.561301
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYN",
          "name": "BANAHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.409699,
            25.560087000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASJP",
          "name": "AMAR SAHEED JAGDEV P HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.448984,
            25.558893
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEA",
          "name": "BIHIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.49105300000001,
            25.554842
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNTE",
          "name": "RAMANAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.5006828052,
            25.554117487
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "XSAN",
          "name": "SARVODAYA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.5212854932,
            25.5509123644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KYA",
          "name": "KAURIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.563141,
            25.549488
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRS",
          "name": "KARISATH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.630119,
            25.547891999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGWL",
          "name": "JAGJIVAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.792055,
            25.5687
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWR",
          "name": "KOELWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.806277,
            25.559662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VHCH",
          "name": "BLOCK CABIN CHAKKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAIL",
          "name": "PAIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8740874,
            25.560948600000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTA",
          "name": "BIHTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.89852553829999,
            25.5647952991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PATL",
          "name": "PATEL HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.916992,
            25.566169000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDE",
          "name": "SADISOPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.961546925,
            25.5720007108
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNHI",
          "name": "GANDHI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.986523,
            25.57459
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEO",
          "name": "NEORA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.044629,
            25.581921
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNR",
          "name": "DANAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.07942999999999,
            25.586297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWS",
          "name": "PHULWARI SHARIF",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1173521326,
            25.5962020296
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCY",
          "name": "SACHIWALAY HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULR",
          "name": "ULTADANGA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLWS",
          "name": "CLWS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUNC",
          "name": "JUNC",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETYD",
          "name": "ETYD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LCAB",
          "name": "LCAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.375198,
            22.566792999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CP",
          "name": "KOLKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37956299999999,
            22.606458
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TALA",
          "name": "TALLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.36524556,
            22.60512587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBR",
          "name": "BAGH BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.35493799999999,
            22.597663999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOLA",
          "name": "SOVABAZAR-AHIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.351803,
            22.590907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZB",
          "name": "BARRA BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.34685800000001,
            22.577047
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBDB",
          "name": "BENOY BADAL DINESH GARDENS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.339472,
            22.565207
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EDG",
          "name": "EDEN GARDENS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33202200000001,
            22.557153999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPGT",
          "name": "PRINCEP GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.315889,
            22.5357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIRP",
          "name": "KHIDIRPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.316273,
            22.525427999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMTR",
          "name": "REMOUNT ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.322256,
            22.518978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJT",
          "name": "MAJERHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33492,
            22.510862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NACC",
          "name": "NEW ALIPUR (CALCUTTA)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.346908,
            22.507909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLG",
          "name": "TOLLY GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.343231,
            22.509943999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MILE",
          "name": "MILE-FIVEB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.35237095000001,
            22.50794733
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKF",
          "name": "LAKE GARDENS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37206854,
            22.51983196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLN",
          "name": "BALLYGUNGE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.374176,
            22.541428999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PQS",
          "name": "PARK CIRCUS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BELG",
          "name": "BELG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.59087799999999,
            20.676825
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNKL",
          "name": "DHENKANAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.85341299999999,
            16.592578000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKUR",
          "name": "DOKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.163648,
            16.859603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJAP",
          "name": "RAJAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.53418,
            20.715235
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNDL",
          "name": "DANDI MAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.9603252667,
            21.4830514376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBP",
          "name": "SAMBALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.9757114679,
            21.4779003477
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBPD",
          "name": "SAMBALPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.504693,
            20.735286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSPR",
          "name": "SADASHIBAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.475883,
            20.750947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHDB",
          "name": "MAHADIA PH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.424456,
            20.768563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HND",
          "name": "HINDOL ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.382445,
            20.781585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBT",
          "name": "NAYABAGIRTHIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.30494300000001,
            20.802387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRDL",
          "name": "MERAMANDOLIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.25582286000001,
            20.853156679999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDPK",
          "name": "BURHAPANKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.207934,
            20.886651
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLHD",
          "name": "TALCHER ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.099994,
            20.859445
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANGL",
          "name": "ANGUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.97670600000001,
            20.855813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPJG",
          "name": "KEREJANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.887597,
            20.885087
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRPD",
          "name": "JARAPADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.74820639999999,
            20.9200366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BONA",
          "name": "BOINDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.684665,
            20.943324
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNPA",
          "name": "HANDAPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.565386,
            20.98404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRGP",
          "name": "SARAGIPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.475865,
            21.006738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAMR",
          "name": "BAMUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.333562,
            21.046744999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAIR",
          "name": "RAIRAKHOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.204417,
            21.095391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHAR",
          "name": "CHARMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.134611,
            21.239342
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUJA",
          "name": "JUJUMURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.11793899999999,
            21.309386
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HATB",
          "name": "HATIBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.04398800000001,
            21.411344
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MANE",
          "name": "MANESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0076171613,
            21.4770222859
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBPY",
          "name": "SAMBALPUR CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.644255,
            24.514517
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSME",
          "name": "JASIDIH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.6144068317,
            24.568995679700002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TTN",
          "name": "TULSITANR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.6001380908,
            24.6403400956
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LHB",
          "name": "LAHABON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.55630277830001,
            24.694736735
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLB",
          "name": "TALWA BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.541782,
            24.713365
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STL",
          "name": "SIMULTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4985295715,
            24.741624780800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHN",
          "name": "GHORPARAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4578033173,
            24.7439751193
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRGO",
          "name": "NARGANJO HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4230669043,
            24.7563630003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJLA",
          "name": "RAJLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.399854,
            24.779456
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAJ",
          "name": "JHAJHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.34907600000001,
            24.829399000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPX",
          "name": "DADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.324005,
            24.856169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHR",
          "name": "GIDHAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.287062,
            24.918896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CUX",
          "name": "CHAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.25477500000001,
            24.971949
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMU",
          "name": "JAMUI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1856142041,
            25.0513208329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDER",
          "name": "KUNDER HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.172874585,
            25.0732160325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BFM",
          "name": "BHALUI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.15540512449999,
            25.1049319287
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNP",
          "name": "MANANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.14781537840001,
            25.1202705418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLHR",
          "name": "BALAHAPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1448204578,
            25.1245132245
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSQP",
          "name": "BANSIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.13641071170001,
            25.139184912599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LCK",
          "name": "LAKHOCHAK HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1301575793,
            25.1511094241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHLT",
          "name": "MAHESH LETA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8567601593,
            25.43034549
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOR",
          "name": "MOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.205232,
            25.597542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZH",
          "name": "GULZARBAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.230942,
            25.585856
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNC",
          "name": "PATNA SAHEB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2575111389,
            25.5695431489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDGJ",
          "name": "DEEDARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.27274200000001,
            25.547008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKG",
          "name": "BANKA GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.305488,
            25.50144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FUT",
          "name": "FATWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3313080389,
            25.4968269712
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCYN",
          "name": "BUDHDEVCHAK Y NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3276678,
            25.496924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDE",
          "name": "HARDAS BIGHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.387309,
            25.484893
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOO",
          "name": "KHUSROPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.422051,
            25.478252
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJM",
          "name": "MANJHAULIGRAM H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.46824000000001,
            25.468639
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWO",
          "name": "KARAUTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.44577199999999,
            25.473618
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMBH",
          "name": "SALIM PUR BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.485186,
            25.465009000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKBG",
          "name": "TEKA BIGHA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.52956400000001,
            25.456110000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKP",
          "name": "BAKHTIYARPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.600631,
            25.453799999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPML",
          "name": "JAI PRAKASH MAHULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.6213724115,
            25.453014977800002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATL",
          "name": "ATHMAL GOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.661087,
            25.45273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ACU",
          "name": "ACHUARA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.70942600000001,
            25.46165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BARH",
          "name": "BARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.7277666345,
            25.475692697299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHT",
          "name": "SAHARI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.75569245480001,
            25.4829711754
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLE",
          "name": "RAILEY ENGLISH HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.7783575873,
            25.482271329699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LEB",
          "name": "LEMUABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.803757,
            25.481495000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHK",
          "name": "PUNARAKH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.82685199999999,
            25.472507999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMKB",
          "name": "MEMRAKHABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.838512,
            25.45827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNHP",
          "name": "KANHAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.86024300000001,
            25.427352
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHAD",
          "name": "BARHAPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.880824,
            25.40468
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SIVN",
          "name": "SIVNAR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.91304899999999,
            25.391885
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKA",
          "name": "MOKAMEH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.94936398009999,
            25.3804334414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANAH",
          "name": "AUNTA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.136824,
            25.60256
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNBE",
          "name": "PATNA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.162449,
            25.603351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJPB",
          "name": "RAJENDRA NAGAR BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.64534800000001,
            24.467308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBQ",
          "name": "KUMRABAD ROHINI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.637552,
            24.427623
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNQ",
          "name": "SANKARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.631692,
            24.386518000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUW",
          "name": "MATHURAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.64786699999999,
            24.318387
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPZ",
          "name": "NAWAPATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.642183,
            24.270633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDP",
          "name": "MADHUPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.707773,
            24.201663
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRW",
          "name": "JORAMOW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.70195,
            24.140881
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNC",
          "name": "MADANKATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.69841500000001,
            24.103820000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDS",
          "name": "VIDYASAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.75251,
            24.004444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEE",
          "name": "KASEETAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.805183,
            23.958904
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMT",
          "name": "JAMTARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.852624,
            23.897286
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BM",
          "name": "BODMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.878036,
            23.857138000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRJ",
          "name": "CHITTARANJAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.885481,
            23.818703000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNPR",
          "name": "RUPNARAYANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.876395,
            23.760355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLS",
          "name": "SALANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.39220399999999,
            22.600493
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPY",
          "name": "CHAPRAMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.395055,
            22.605667999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PTKR",
          "name": "PATIPUKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.44197299999999,
            16.300784
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNT",
          "name": "GUNTUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.397756,
            16.308263
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NLPD",
          "name": "NALLAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.339519,
            16.319601000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRCA",
          "name": "PERECHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.255894,
            16.292376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPM",
          "name": "PHIRANGIPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.193376,
            16.273407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDPU",
          "name": "NUDURUPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.13881099999999,
            16.254465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STUR",
          "name": "SATULUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.041607,
            16.231527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRT",
          "name": "NARASARAOPET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.981319,
            16.190922
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUK",
          "name": "MUNUMAKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.91505599999999,
            16.147071
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAB",
          "name": "SANTAMAGULUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.8886640826,
            16.1317836869
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VLE",
          "name": "VELLALCHERUVU H",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.81953,
            16.102801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYM",
          "name": "SAVALYAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.748004,
            16.050748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKN",
          "name": "VINUKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.682842,
            16.011698
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CEM",
          "name": "CHEEKATEEGALPLM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.645362,
            15.985909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKM",
          "name": "GUNDALUKAMMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.572175,
            15.913391
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCD",
          "name": "KURICHEDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.523864,
            15.873337
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POO",
          "name": "POTLAPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.484112,
            15.834631000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKD",
          "name": "DONAKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.401298,
            15.777382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJJ",
          "name": "GAJJELAKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.385179,
            22.601174999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOAA",
          "name": "KOLKATA CHITPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KANL",
          "name": "KANL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7396679724,
            23.3336497763
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "INCI",
          "name": "ISHAN CHANDI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.681236,
            23.358032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLI",
          "name": "GALSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.62750500000001,
            23.387863
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAJ",
          "name": "PARAJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.555822,
            23.420373
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNAE",
          "name": "MANKAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.439587,
            23.443034
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAN",
          "name": "PANAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.372314,
            23.476136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RBH",
          "name": "RAJBANDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.297902,
            23.495046000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGR",
          "name": "DURGAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.298232,
            23.505136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DCOP",
          "name": "DURGAPUR COKE OVEN PLANT EXCHANGE YARD SDG WARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.246177,
            23.537562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OYR",
          "name": "WARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.232307,
            23.549642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POL",
          "name": "PINJRAPOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.20360799999999,
            23.569452000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EOC",
          "name": "UDL EAST OUTER CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.199696,
            23.57172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDLE",
          "name": "ANDAL-EAST/CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.181194,
            23.579012000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDL",
          "name": "ANDAL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.168796,
            23.584024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UDLW",
          "name": "ANDAL WEST CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9917268481,
            24.053224666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCK",
          "name": "CHICHAKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9155275354,
            24.1289485964
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRBH",
          "name": "GAREA BIHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.39469277709999,
            23.8022772592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHN",
          "name": "BHULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.34357,
            23.814441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TET",
          "name": "TETULMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.28776500000001,
            23.825908
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPJE",
          "name": "NICHITPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.239035,
            23.840519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRQ",
          "name": "MATARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.14823,
            23.873106
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMO",
          "name": "GOMOH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.116898,
            23.898444
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLME",
          "name": "BHOLLDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.07621300000001,
            23.932092
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMG",
          "name": "NIMIAGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.037862,
            23.987971
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNME",
          "name": "PARASNATH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.964766012,
            24.0842485151
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CEME",
          "name": "CHEGRO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9464457367,
            24.1118528783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDB",
          "name": "CHAUDHRIBANDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.973866,
            24.07875
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRMB",
          "name": "KARMABAD BH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.892035,
            24.174583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HZD",
          "name": "HAZARIBAGH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.86420199999999,
            24.194872999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSHR",
          "name": "KESHWARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8160239368,
            24.2372952691
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBH",
          "name": "CHAUBE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.790855545,
            24.274602265600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSME",
          "name": "DASARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.73873,
            24.307559
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSB",
          "name": "PARSABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.70089899999999,
            24.329874999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YDD",
          "name": "YADUDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.65550400000001,
            24.344874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SMND",
          "name": "SARMATANR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.596658,
            24.38304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRE",
          "name": "HIRODIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.562123,
            24.407020999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LRB",
          "name": "LARABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.52945799999999,
            24.434741000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQR",
          "name": "KODERMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.463683,
            24.498121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJD",
          "name": "GUJHANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.446702,
            24.509662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LBZ",
          "name": "LALBAG BH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.41970863,
            24.53731788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLW",
          "name": "DILWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.385094,
            24.545616
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGY",
          "name": "NATH GANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.366148,
            24.547613
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSCP",
          "name": "BASKATWA B. H.",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.32401399999999,
            24.543289
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YGM",
          "name": "YADUGRAM BH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.29708,
            24.562542
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAP",
          "name": "GURPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.204571,
            24.624931999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRP",
          "name": "PAHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.16182409000001,
            24.66529241
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNSL",
          "name": "BANSINALA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.119402,
            24.704046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKN",
          "name": "TANKUPPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.06620037,
            24.75197374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNF",
          "name": "BANDHUA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.044738,
            24.807111000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPO",
          "name": "MANPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.01490964999999,
            24.80741419
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SICY",
          "name": "SHAHEED ISHWAR CHOWDHARY HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.999294,
            24.803978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAYA",
          "name": "GAYA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.971299,
            24.798676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WCB",
          "name": "WEST CABIN GAYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.921751,
            24.802484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSTA",
          "name": "KASTHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.85079200000001,
            24.809464
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRY",
          "name": "PARAIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.791831,
            24.811113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRRU",
          "name": "GURARU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.723433,
            24.815523000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IMGE",
          "name": "ISMAILPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.634804,
            24.819694000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RFJ",
          "name": "RAFIGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.5634225305,
            24.823073676299998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DORD",
          "name": "DEO ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.52567784239999,
            24.8288034748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHN",
          "name": "JAKHIM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.41808499999999,
            24.845489
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PES",
          "name": "PHESAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.3298444,
            24.855955599999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AUBR",
          "name": "ANUGRAHA NARAYAN ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.269003,
            24.863478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPBH",
          "name": "CHIRAILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.22927299999999,
            24.882212999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEB",
          "name": "SON NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.19221399999999,
            24.909345
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADSR",
          "name": "ADSR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.185434,
            24.914894999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOS",
          "name": "DEHRI ON SONE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.144817,
            24.932644
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PHE",
          "name": "PAHALEJA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.301115,
            15.714678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRK",
          "name": "MARKAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.803634,
            27.181549999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RAI",
          "name": "RAIBHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.91435,
            27.176397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCP",
          "name": "BICHPURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.997807,
            27.17265
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IDH",
          "name": "IDGAH AGRA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.0191,
            27.183308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AF",
          "name": "AGRA FORT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.03812699999999,
            27.191512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAB",
          "name": "YAMUNA BRIDGE AGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.084141,
            27.212079000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHLR",
          "name": "CHHALESAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.128812,
            27.221966
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBP",
          "name": "KUBERPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.197973,
            27.221535999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETUE",
          "name": "ETMADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.864916,
            25.379668
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "COI",
          "name": "CHEOKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.916127,
            25.313666
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCN",
          "name": "KARCHANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.98924,
            25.265024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEP",
          "name": "BHEERPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.089337,
            25.221545
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJA",
          "name": "MEJA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.1633748193,
            25.1924197817
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UND",
          "name": "UNCHDIH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.32555500000001,
            25.157891
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JIA",
          "name": "JIGNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.38624899999999,
            25.16045
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAE",
          "name": "GAIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.449749,
            25.173789
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEO",
          "name": "BIROHE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.31659800000001,
            28.014139
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKN",
          "name": "BIKANER JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.3438365942,
            27.922973867499998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UMS",
          "name": "UDRAMSAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.273605,
            27.845285999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAE",
          "name": "PALANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.338483,
            27.791303
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSO",
          "name": "DESHNOK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.442249,
            27.706677000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPO",
          "name": "SURPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.47484800000001,
            27.556203
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NOK",
          "name": "NOKHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.52546099999999,
            27.446624
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLO",
          "name": "CHILO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.5373822888,
            27.416761083900003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBLJ",
          "name": "SHRI BALAJI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.58576799999999,
            27.337097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALAI",
          "name": "ALAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.663015,
            27.311355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWS",
          "name": "BADWASI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.72357199999999,
            27.199991
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGO",
          "name": "NAGAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.815965,
            27.063606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDW",
          "name": "MARWAR MUNDWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.861764,
            26.923262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJW",
          "name": "KHAJWANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.885443,
            26.850374000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSL",
          "name": "DESWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.9073942175,
            26.7834880329
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCPE",
          "name": "MARWAR CHAPRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.79827999999999,
            26.873067000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GADJ",
          "name": "GANDHINAGAR JAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.148636,
            15.449372
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMH",
          "name": "BETAMCHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.832384,
            26.836925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTJT",
          "name": "GETOR JAGATPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.89049800000001,
            26.835709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KWP",
          "name": "KHATIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.955996106,
            26.842216007599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUT",
          "name": "KANAUTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.047213,
            26.840616999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAI",
          "name": "BASSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.1146920331,
            26.852187381900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHIR",
          "name": "JHIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.863463,
            16.494924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXI",
          "name": "KURUMURTHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.142206,
            26.856115
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSKO",
          "name": "BANSKHO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.21946200000001,
            15.660536
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLU",
          "name": "TARLUPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2140548169,
            26.8688202925
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JW",
          "name": "JATWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.110832,
            15.592947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBM",
          "name": "CUMBUM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.032655,
            15.833715
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRNT",
          "name": "KURNOOL TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.047012,
            15.895657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALPR",
          "name": "ALAMPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.25247399999999,
            26.878914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNDN",
          "name": "BHANDANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.04160499999999,
            15.915692
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBBJ",
          "name": "JOGULAMBA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.941968,
            15.994114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOA",
          "name": "MANOPAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.852531,
            16.074589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IKI",
          "name": "ITKYALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.330336,
            26.900046999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DO",
          "name": "DAUSA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83502,
            16.100078
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDNA",
          "name": "PEDDADINNE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.820931,
            16.153896999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDO",
          "name": "PUDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.80967199999999,
            16.22509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GWD",
          "name": "GADWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4094554639,
            26.962546793999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAK",
          "name": "BHAN KARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.862369,
            16.270533999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARPL",
          "name": "AREPALLI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.865894,
            16.306568
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRNR",
          "name": "SRIRAMNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8736361627,
            16.3467833327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJK",
          "name": "AJAKOLLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.46876528439999,
            26.9894838995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KVGM",
          "name": "KOLVAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.88514998289999,
            16.3820535896
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "WPR",
          "name": "WANPARTI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.884404,
            16.424621000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KONN",
          "name": "KONNUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.850451,
            16.538962
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQQ",
          "name": "KAUKUNTLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.838261,
            16.630504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKC",
          "name": "DEVARKADRE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.869477,
            16.668744
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTKA",
          "name": "KOTAKADRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.904883,
            16.683425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQN",
          "name": "MANYAMKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.982649,
            16.742879000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHBT",
          "name": "MAHBUBNAGAR TOWN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.999429,
            16.757768
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBNR",
          "name": "MAHBUBNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.036337,
            16.758073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YNG",
          "name": "YENUGONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.56672300000001,
            27.039271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKI",
          "name": "BANDIKUI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.071072,
            15.551155999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBK",
          "name": "JAGGAMBOTLA KRISHNAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.038111,
            15.4534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDV",
          "name": "SOMIDEVIPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.010485,
            15.424449000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMA",
          "name": "GUDIMETTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YADA",
          "name": "YADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.925583,
            15.379008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GID",
          "name": "GIDDALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.883844,
            15.372380000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSTE",
          "name": "KISTAMSETTIPALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.826206,
            15.394929999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMT",
          "name": "DIGUVAMETTA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.695312,
            15.422638000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMZ",
          "name": "CHALAMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.600125,
            15.430850000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GZL",
          "name": "GAZULAPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.472545,
            15.468024
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDL",
          "name": "NANDYAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.339158,
            15.50076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNM",
          "name": "PANYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.5171796234,
            27.0101287751
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARNA",
          "name": "ARNIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.267907,
            15.505221
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KEF",
          "name": "KRISHNAMA KONA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.19541209,
            15.48632233
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEY",
          "name": "B CEMENT NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.059153,
            15.406776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGM",
          "name": "RANGAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.96755599999999,
            15.368943999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLK",
          "name": "MALKAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.914295,
            15.473393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BVO",
          "name": "BOGOLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.865727,
            15.396729
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHNE",
          "name": "DHONE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.927621,
            15.553699
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VDI",
          "name": "VELDURTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.968687,
            15.652934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UKD",
          "name": "ULINDAKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.01325899999999,
            15.742888
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUU",
          "name": "DUPADU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.03755196290001,
            15.8120341064
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOLA",
          "name": "KOTLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.08417499999999,
            16.740883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTP",
          "name": "DIVITI PALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.17617700000001,
            22.8813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CDAE",
          "name": "CHANDANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.15914000000001,
            22.916427
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBZ",
          "name": "PORABAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.15233111,
            22.93272742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMAE",
          "name": "BELMURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.143651,
            22.95019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNHL",
          "name": "DHANIAKHALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.13369,
            22.974465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHBC",
          "name": "SIBAICHANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.126806,
            22.993969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CRAE",
          "name": "CHERAGRAM BH.",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.121403,
            23.007032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HIH",
          "name": "HAJIGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.112282,
            23.02534
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRAE",
          "name": "GURAP",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.091187,
            23.064129
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPQ",
          "name": "JHAPANDANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.080887,
            23.078737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JRAE",
          "name": "JAUGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.06690900000001,
            23.09918
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NBAE",
          "name": "NABAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.042182,
            23.134791
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSAE",
          "name": "MASAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.025521,
            23.157905
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHC",
          "name": "CHANCHAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.00912100000001,
            23.179393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRAE",
          "name": "PALLA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.291491,
            22.677422
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKAE",
          "name": "DANKUNI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.279274,
            22.695504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBRA",
          "name": "GOBRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.26510999999999,
            22.722379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOX",
          "name": "JANAI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.255644,
            22.737627
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPAE",
          "name": "BEGUMPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.237381,
            22.766787
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRPA",
          "name": "BARUIPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.224626,
            22.78738
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBE",
          "name": "MIRZAPUR BANKIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.21216842,
            22.8068127
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLAE",
          "name": "BALARAMBATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.205127,
            22.821631
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQU",
          "name": "KAMARKUNDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.204309,
            22.820899
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQLS",
          "name": "KAMARKUNDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.193499,
            22.845108
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDSE",
          "name": "MADHU SUDANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.374713,
            22.566758
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDAH",
          "name": "KOLKATA SEALDAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37910995610001,
            22.5719746424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKG",
          "name": "NARKALDENGA COACHING YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.385079,
            22.572603
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGK",
          "name": "KANKURGACHHI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.391045,
            22.592432
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNXR",
          "name": "BIDHAN NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.392835,
            22.621717
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDJ",
          "name": "DUM DUM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.37831077,
            22.6530928
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BARN",
          "name": "BARANAGAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.36363519999999,
            22.6534841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAKE",
          "name": "DAKSHINESWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.347431,
            22.652173
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLYG",
          "name": "BALLY GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33860800000001,
            22.652604
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLYH",
          "name": "BALIHALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.321219,
            22.652739
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RCD",
          "name": "RAJCHANDRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.315286,
            22.652855
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCRL",
          "name": "CC RLY LINK CAB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.305512,
            22.657411
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCLW",
          "name": "CCLINK-WEST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.018783,
            25.269767
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGSY",
          "name": "GANGA SAHAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0283957056,
            25.2459362417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMRX",
          "name": "DUMRI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.141972,
            16.752658
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JCL",
          "name": "JADCHERLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.047715,
            25.220474
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DJS",
          "name": "DHURANT JWAS HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.134871,
            26.589465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JYG",
          "name": "JAYNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.78736,
            25.858304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPJ",
          "name": "SAMASTIPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.15361374000001,
            26.529839579999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRHA",
          "name": "KORAHIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1614216113,
            26.4839415697
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJI",
          "name": "KHAJAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0053080969,
            25.4409119004
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHX",
          "name": "GARHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1562,
            26.436785
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLPR",
          "name": "LALIT LAKSHMIPR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.152987,
            26.401801
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJA",
          "name": "RAJANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.987422,
            25.351823
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTZU",
          "name": "HATHIDAH UPPER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1100315369,
            26.371005066000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGPI",
          "name": "MANGARPATTI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.076784,
            26.348165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MBI",
          "name": "MADHUBANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.79465626460001,
            25.845989179900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLRI",
          "name": "BELARI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0816757483,
            26.30637807
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPRN",
          "name": "SALEMPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.799605,
            25.780378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UJP",
          "name": "UJIARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0866355896,
            26.2804866136
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDW",
          "name": "PANDAUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.80632700000001,
            25.724315
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAZJ",
          "name": "NAZIRGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0925137378,
            26.2506466399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UGNA",
          "name": "UGNA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.8109824023,
            25.6921049276
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSRA",
          "name": "BASARHIA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.078137,
            26.208947000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKI",
          "name": "SAKRI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.84146519999999,
            25.6647486
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSS",
          "name": "DALSINGH SARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.04289692260001,
            26.2010198369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSNS",
          "name": "SHAHEED S N SINGH HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.013311,
            26.194364999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRS",
          "name": "TARSARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.976126,
            26.186676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJIH",
          "name": "BIJULI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.94826499999999,
            26.183484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKHT",
          "name": "KAKARGHATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.86778899539999,
            25.631007269799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "STJT",
          "name": "SATHAJAGAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.90014240000001,
            25.5812576
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCA",
          "name": "BACHWARA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.014464,
            25.411453
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKGS",
          "name": "SIMARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9312678741,
            25.5321961227
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPNH",
          "name": "MAJNUPUR NAWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.941774,
            25.511020000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGA",
          "name": "TEGHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.96507848819999,
            25.4735905867
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUJ",
          "name": "BARAUNI FLAG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.907575,
            26.157088
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBG",
          "name": "DARBHANGA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.903825,
            26.110633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LSI",
          "name": "LAHERIA SARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.89750699999999,
            26.072734999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLWA",
          "name": "THALWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.87821199999999,
            26.020321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HYT",
          "name": "HAIAGHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.86518199999999,
            25.977172
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RBZ",
          "name": "RAMBHADDARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.840384,
            25.925351000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSP",
          "name": "KISHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.79556,
            25.884676
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKPR",
          "name": "MUKTAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.626717,
            25.242819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRPA",
          "name": "KHARIAPIPRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9546829749,
            25.3787000521
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TALL",
          "name": "TALL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.0006415784,
            25.3308632845
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDUM",
          "name": "RAMPUR DUMRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.01507679999999,
            25.2832026
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRYA",
          "name": "BARHIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.056352,
            25.207216
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKB",
          "name": "MANKATHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.093210587,
            25.1716617005
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKR",
          "name": "LUCKEESARAI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.106198,
            25.171504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIUL",
          "name": "KIUL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1406539392,
            25.1721586608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMPR",
          "name": "RAMPUR HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1591750293,
            25.1719686608
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNRE",
          "name": "DHANAURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.1965221936,
            25.1712233008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PWBN",
          "name": "PAWAI BROHMASTHAN HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.2142016541,
            25.1712379405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UREN",
          "name": "UREN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.261571,
            25.182766
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KJH",
          "name": "KAJRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3040822156,
            25.2058083716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GIBP",
          "name": "GHOGHI BARIARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.32307245909999,
            25.2159864584
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AHA",
          "name": "ABHAIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.3578830731,
            25.2352713892
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSDN",
          "name": "MASUDAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4115380682,
            25.2578934114
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRH",
          "name": "DHARHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.46453228029999,
            25.265181819000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DRTP",
          "name": "DASHARATHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.4765552539,
            25.273371220999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRB",
          "name": "SAROBAG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.492285,
            25.313906
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JMP",
          "name": "JAMALPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5327701855,
            25.323255198600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PATM",
          "name": "PATAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.57608,
            25.279794000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPUR",
          "name": "RATANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5623576038,
            25.294435959199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RIKD",
          "name": "RISHIKUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5740372229,
            25.2810286076
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUP",
          "name": "BARIARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.5806353711,
            25.275564886399998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPRD",
          "name": "KALYANPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.627487,
            25.243589
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAPP",
          "name": "KHARIAPIPRA HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.593002,
            25.265514
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNNA",
          "name": "GANGANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.6457233716,
            25.2369518098
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMGH",
          "name": "KAMARGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.736175,
            25.24085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGG",
          "name": "SULTANGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.775451,
            25.242369
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AJUG",
          "name": "ABJUGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.799239,
            25.236912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MVV",
          "name": "MAHESHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.8345333624,
            25.234880766899998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKN",
          "name": "AKBARNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.87393632569999,
            25.234493190600002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTMP",
          "name": "CHHIT MAKHANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.886908,
            25.232759
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPY",
          "name": "MURARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.935373,
            25.237052000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAT",
          "name": "NATHNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            86.9767918,
            25.241909300000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGP",
          "name": "BHAGALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.045918,
            25.239083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBO",
          "name": "SABAUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.1050667084,
            25.21827008
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LMM",
          "name": "LAILAKH MAMLKHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.16086164,
            25.2103054225
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGA",
          "name": "GHOGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.2138658521,
            25.227623048199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "EKC",
          "name": "EKCHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.2337518945,
            25.2621879424
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLG",
          "name": "KAHALGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3002182,
            25.30004436
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKSL",
          "name": "BIKRAM SHILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.3330938318,
            25.2937259592
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVRP",
          "name": "SHIVANARAYANPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.36336,
            25.288258000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKB",
          "name": "LAKSHMIPUR BHORANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.4218244568,
            25.2903753934
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPT",
          "name": "PIRPAINTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.4585745682,
            25.2724414889
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMPL",
          "name": "AMMAPALLI HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.4979796002,
            25.2587767836
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZC",
          "name": "MIRZA CHEUKI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.5531429022,
            25.2554212612
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRMA",
          "name": "KARAMTOLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.63457700000001,
            25.241391999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBG",
          "name": "SAHIBGANJ JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.715967,
            25.243358999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLJ",
          "name": "SAKRIGALI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.74030800000001,
            25.219587
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJP",
          "name": "MOHARAJPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7340281628,
            25.136401449999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPTO",
          "name": "KARANPUR ATO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7416879089,
            25.0823504143
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLJ",
          "name": "TALJHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.736758,
            25.03452
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXE",
          "name": "KALIAN CHAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7438070306,
            24.996925863999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPH",
          "name": "TINPAHAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7615573059,
            24.9603876804
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDX",
          "name": "DHAMDHAMIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.157285,
            16.818903000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLY",
          "name": "GOLLAPALLY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.68120499999999,
            23.949741
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNT",
          "name": "SAINTHIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.691891,
            24.013197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GHLE",
          "name": "GADADHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.726275,
            24.080029
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLV",
          "name": "MALLARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7522050836,
            24.1265252897
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TPF",
          "name": "TARAPITH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.78214700000001,
            24.179797
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPH",
          "name": "RAMPUR HAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.822587,
            24.253477999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDLE",
          "name": "SWADINPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.83867000000001,
            24.295706
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NHT",
          "name": "NALHATI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.849257,
            24.372846
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTR",
          "name": "CHATRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.85865299999999,
            24.440886
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRR",
          "name": "MURARAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.862439,
            24.502610999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSBR",
          "name": "BANSHLAI BRIDGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.86763599999999,
            24.544607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJG",
          "name": "RAJGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8680748517,
            24.590321491500003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NGF",
          "name": "NAGARNABI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.856054,
            24.638002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKR",
          "name": "PAKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.842581,
            24.677287999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBB",
          "name": "TILBHITA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.823724,
            24.741141
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLP",
          "name": "KOTALPUKUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.8052776343,
            24.798952096399997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GMAN",
          "name": "GUMANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.750181,
            23.367646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTL",
          "name": "JHAPATER DHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.74725106999999,
            23.40073073
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPS",
          "name": "BANPAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.745014,
            23.433926999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRX",
          "name": "NOADAR DHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.733397,
            23.489998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GKH",
          "name": "GUSKARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.720914,
            23.54342
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PCQ",
          "name": "PICHKURIRDHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.711813,
            23.586642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDH",
          "name": "BHEDIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.698136,
            23.657804000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHP",
          "name": "BOLPUR SHANTINIKETAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.693501,
            23.694435000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNE",
          "name": "PRANTIK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.689548,
            23.759063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPLE",
          "name": "KOPAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6928410938,
            23.8325090573
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMP",
          "name": "AHMADPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.6806814746,
            23.8802785594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSLE",
          "name": "BATASPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.971782,
            23.205879
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKG",
          "name": "SAKTIGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.928116,
            23.223525000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRP",
          "name": "GANGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.87028099999999,
            23.249718
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWN",
          "name": "BARDDHAMAN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.00322154050001,
            23.197030709800003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLAE",
          "name": "PALSIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.819122,
            23.29331
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIT",
          "name": "TALIT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.039994,
            23.187229000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RSLR",
          "name": "RASULPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.769547,
            23.320577
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAN",
          "name": "KHANA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.069346,
            23.180297999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NMF",
          "name": "NIMO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.095637,
            23.172935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MYM",
          "name": "MEMARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3793623,
            22.9236565
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDC",
          "name": "BANDEL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.12200441,
            23.16014581
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGF",
          "name": "BAGILA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.375632,
            22.904835
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HGY",
          "name": "HOOGHLY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.15599765809999,
            23.1446129567
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DBP",
          "name": "DEBIPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3695,
            22.890190999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CNS",
          "name": "CHUCHURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.196286,
            23.1191243
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOI",
          "name": "BAINCHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.354421,
            22.867469999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CGR",
          "name": "CHANDAN NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.21629817,
            23.10588031
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCGM",
          "name": "BAINCHIGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.346368,
            22.846462000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUU",
          "name": "MANKUNDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.23121300000001,
            23.095679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLG",
          "name": "SIMLAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.270115,
            23.070861999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDA",
          "name": "PUNDOOAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.34153486,
            22.8279397
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHR",
          "name": "BHADRESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.314472,
            23.046222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHN",
          "name": "KHANYAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33203765,
            22.79549199
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBAE",
          "name": "BAIDYABATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.345088,
            23.009788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLO",
          "name": "TALANDU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.36829,
            22.983124999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MUG",
          "name": "MAGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.328809,
            22.774749
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHE",
          "name": "SEORAPHULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.378872,
            22.95447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADST",
          "name": "ADI SAPTAGRAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.338031,
            22.753967999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRP",
          "name": "SERAMPORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.343378,
            22.726146
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RIS",
          "name": "RISHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.34183442140001,
            22.6994528322
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOG",
          "name": "KONNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.341605,
            22.683736
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HMZ",
          "name": "HIND MOTOR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.34131699999999,
            22.667652
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UPA",
          "name": "UTTARPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.3406065536,
            22.6552655812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLY",
          "name": "BALLY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33950084,
            22.635912010000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEQ",
          "name": "BELUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.339559,
            22.621521
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLH",
          "name": "LILUAH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.336795,
            22.609028
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SYAE",
          "name": "LILUAH (SORTING YARD)",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.33468799799999,
            22.5900953465
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HWHG",
          "name": "HOWRAH GOODS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            88.34099900000001,
            22.584077999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HWH",
          "name": "HOWRAH JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.7749068933,
            24.9345107267
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKLE",
          "name": "BAKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.77449299999999,
            24.857748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHW",
          "name": "BARHARWA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            87.798331,
            24.822167
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDLC",
          "name": "BONIDANGA LINK CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.719436,
            18.792189999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRPU",
          "name": "KORAPUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.72400599999999,
            18.797786
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRPB",
          "name": "KORAPUT B CABIN B.H.",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.803933,
            18.780367000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMRT",
          "name": "DUMURIPUT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.865768,
            18.767771
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DMNJ",
          "name": "DAMANJODI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.96578736,
            18.827043709999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGUA",
          "name": "BAIGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.007309,
            18.923382
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKGM",
          "name": "KAKIRIGUMMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.104298,
            19.003986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKMR",
          "name": "LAXMIPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.10500400000001,
            19.110931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGRM",
          "name": "SINGARAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.09910500000001,
            19.177667000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKRI",
          "name": "TIKIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.16728,
            19.230268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RUL",
          "name": "RAULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.208299,
            19.304006
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LLGM",
          "name": "LELIGUMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.29429956000001,
            19.32812783
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLMK",
          "name": "BHALUMASKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.32010335,
            19.391899549999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SKPI",
          "name": "SIKARPAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.368072,
            19.303471000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTGA",
          "name": "KEUTGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.410505,
            19.175642
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGDA",
          "name": "RAYAGADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.403066,
            19.258673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPRD",
          "name": "SINGAPURAM ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.42130900000001,
            19.335826
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THV",
          "name": "THERUBALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.458973,
            19.487437999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMCK",
          "name": "BISSAMCUTTACK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.47977300000001,
            19.529222
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNRL",
          "name": "SANSARTALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.488343,
            19.62753
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNGD",
          "name": "MUNIGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.48161132000001,
            19.74188249
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKLU",
          "name": "DOIKALLU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.45635247989999,
            19.8363234649
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMB",
          "name": "AMBODALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.4003416858,
            19.9434221772
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LJR",
          "name": "LANJIGARH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.373301,
            20.041394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRLR",
          "name": "NORLA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.330956,
            20.08447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPRD",
          "name": "RUPRA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.27892999999999,
            20.126381000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDLR",
          "name": "KANDEL ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.224709,
            20.188102999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSNG",
          "name": "KESINGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.18083299999999,
            16.901936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RRGA",
          "name": "RANGAREDDY GUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.84405122380001,
            30.0663927023
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPP",
          "name": "DHIRPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.854437,
            30.120677999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHMZ",
          "name": "DHOLA MAZRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.861554,
            30.166186
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHDM",
          "name": "SHAHBAD MARKNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.850054,
            30.255264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MOY",
          "name": "MOHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.247277,
            28.615267999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PGMD",
          "name": "PRAGATI MAIDAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4216048,
            24.9469398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJA",
          "name": "BIJROTHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30734799999999,
            28.411473
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FDB",
          "name": "FARIDABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.254249,
            28.587329999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NZM",
          "name": "DELHI H NIZAMUDDIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.237995,
            28.627633
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKJ",
          "name": "TILAK BRIDGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.226612,
            28.634101
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CSB",
          "name": "SHIVAJI BRIDGE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.22000399999999,
            28.642314
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDLS",
          "name": "NEW DELHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.216852,
            28.658656
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSB",
          "name": "DELHI SADAR BAZAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.199696,
            28.668683
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SZM",
          "name": "SUBZI MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.176713,
            28.702645999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DAZ",
          "name": "DELHI AZADPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.900028,
            29.904968
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMIN",
          "name": "AMIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.842512,
            30.018915000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHKR",
          "name": "DHODA KHEDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.125212,
            22.082796000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CORD",
          "name": "BILASPUR CHORD CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.111546,
            22.102548
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "USL",
          "name": "USALAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.085321,
            22.16819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTK",
          "name": "GHUTKU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.0576347339,
            22.2342458973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLTR",
          "name": "KALMITAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.026832,
            22.297935
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGB",
          "name": "KARGI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.010606,
            22.36745
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLKR",
          "name": "SALKAROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.032374,
            22.431085
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIG",
          "name": "BELGAHNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.99575,
            22.507027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGQ",
          "name": "TENGANMADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.934151,
            22.551352
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGS",
          "name": "KHONGSARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.897794,
            22.607916
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHTK",
          "name": "BHANWAR TONK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.93569600000001,
            22.665094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOI",
          "name": "KHODRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.91042900000001,
            22.710501999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBRA",
          "name": "SARBAHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.89917,
            22.755824999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PND",
          "name": "PENDRA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.882604,
            22.82794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HRB",
          "name": "HARRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.872187,
            22.923269
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKR",
          "name": "VENKATNAGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.874215,
            22.961546000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NIQ",
          "name": "NIGAURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.7970919489,
            23.0451865524
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JTI",
          "name": "JAITHARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.73707,
            23.106724
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLF",
          "name": "CHHULHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.695967,
            23.117312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APR",
          "name": "ANUPPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.593507,
            23.173469
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AAL",
          "name": "AMLAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.528406,
            23.213203999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUH",
          "name": "BURHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.47142699999999,
            23.237624
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHDX",
          "name": "CHHADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.4075072687,
            23.253960575599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNGP",
          "name": "SINGHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.36407299999999,
            23.285961999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SDL",
          "name": "SHAHDOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.28636818000001,
            23.2939334
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDWA",
          "name": "BADHWA BARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.1862407697,
            23.3436391121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGT",
          "name": "GHUNGHUTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.0950669171,
            23.3586036574
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDXR",
          "name": "MUDARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.0433529,
            23.367211700000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRS",
          "name": "BIRSINGHPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.980363,
            23.394425
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRZB",
          "name": "NOWROZABAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.919056,
            23.460957
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKI",
          "name": "KARKELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.823244,
            23.52273
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UMR",
          "name": "UMARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.75161299999999,
            23.581228
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LOA",
          "name": "LORHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.701436,
            23.6454399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHD",
          "name": "CHANDIA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.610102,
            23.708924
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VYK",
          "name": "VILAYATKALAN RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.573345,
            23.731755
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RPD",
          "name": "RUPAUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            80.473608,
            23.772191
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JLW",
          "name": "JHALWARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.188891,
            16.947622000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BABR",
          "name": "BALANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.989515,
            18.435565999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ULM",
          "name": "URLAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0726809924,
            18.4704858205
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIU",
          "name": "TILARU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.1214378076,
            18.4790934357
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HCM",
          "name": "HARISHCHANDRAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.199842,
            18.488355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBM",
          "name": "KOTABOMMALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.241768,
            18.542976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGB",
          "name": "DINDU GOPALA PURAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.2829220099,
            18.5794097271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NWP",
          "name": "NAUPADA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.3356803912,
            18.6209643431
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMZ",
          "name": "ROUTHPURAM HALT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.370679,
            18.665917999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUN",
          "name": "PUNDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.42209100000001,
            18.756782
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSA",
          "name": "PALASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.467259,
            18.808896999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SUDV",
          "name": "SUMMADEVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.494113,
            18.840628000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MMS",
          "name": "MANDASA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.554233,
            18.900748
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAV",
          "name": "BARUVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.58149999999999,
            18.981296999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SPT",
          "name": "SOMPETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.613031,
            19.028201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JPI",
          "name": "JHADUPUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.680427,
            19.110752
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IPM",
          "name": "ICHCHPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.719145,
            19.177016000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLRD",
          "name": "SURLA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.740668,
            19.219065
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTA",
          "name": "GOLANTHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.7966912,
            19.2961348
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAM",
          "name": "BRAHMAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8681439825,
            19.330648607
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JNP",
          "name": "JAGAN NATHAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9387492847,
            19.3632939389
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRSP",
          "name": "NARASIMHAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.97327299999999,
            19.3589572
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAP",
          "name": "CHATRAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.982543,
            19.358941
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CAPC",
          "name": "CHATRAPUR COURT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.046719,
            19.395827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GAM",
          "name": "GANJAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.07588799999999,
            19.443283
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HMA",
          "name": "HUMMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.09407900000001,
            19.537957
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RBA",
          "name": "RAMBHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.128091,
            19.611204999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIT",
          "name": "KHALLIKOT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.151414,
            19.647603999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLJI",
          "name": "KALIJAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.17991400000001,
            19.686694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CLKA",
          "name": "CHILKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.200953,
            19.747366
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BALU",
          "name": "BALUGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.24778599999999,
            19.800255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLZ",
          "name": "SOLARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.29323500000001,
            19.815673
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNGD",
          "name": "GANGADHARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.3527,
            19.859987
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUU",
          "name": "KUHURI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.40365100000001,
            19.896585
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KAPG",
          "name": "KALUPARA GHAT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.437914,
            19.919138
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKTP",
          "name": "MUKETASHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.4764825,
            19.951792700000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSDP",
          "name": "BHUSANDPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.53046400000001,
            19.991740999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKP",
          "name": "NIRAKARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.555928,
            20.016866
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GLBA",
          "name": "GOLABAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.580989,
            20.052879
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAP",
          "name": "TAPANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.60477200000001,
            20.079331999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DLMH",
          "name": "DHAULIMUHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.639666,
            20.10484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPXR",
          "name": "KAIPADA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.67088007,
            20.12442672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARGL",
          "name": "ARGUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.289514,
            17.72159
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VSKP",
          "name": "VISHAKAPATNAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.252629,
            17.7363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIPM",
          "name": "MARRIPALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.220449,
            17.744667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCM",
          "name": "SIMHACHALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.209636,
            17.752094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCMN",
          "name": "SIMHACHALAM NORTH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.195211,
            17.813498
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDT",
          "name": "PENDURTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.18509999999999,
            17.892374999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTV",
          "name": "KOTTAVALASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.20820499999999,
            17.954718999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPL",
          "name": "KANTAKAPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.271604,
            18.012510000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALM",
          "name": "ALAMANDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.320296,
            18.054196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KUK",
          "name": "KORUKONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.39558600000001,
            18.111374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VZM",
          "name": "VIZIANAGRAM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.456046,
            18.183131
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NML",
          "name": "NELLIMARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.5108882898,
            18.230678746400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GUL",
          "name": "GUJJANGIVALASA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.5308410095,
            18.2732681907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVI",
          "name": "GARIVIDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.569608,
            18.316259000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPP",
          "name": "CHIPURUPALLE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.62409646,
            18.34308678
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTVA",
          "name": "BATUVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.68144099999999,
            18.360601
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGDM",
          "name": "SIGADAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.776548,
            18.362876
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDU",
          "name": "PONDURU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.857682,
            18.369969
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DUSI",
          "name": "DUSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.903771,
            18.408679
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHE",
          "name": "SRIKAKULAM ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.213273,
            17.064646
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHNR",
          "name": "SHADNAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            71.340823,
            23.006336
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CUL",
          "name": "CHULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            70.696477,
            23.231063000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRBR",
          "name": "SURBARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.499756,
            17.389561
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KCG",
          "name": "KACHEGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.494244,
            17.375398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MXT",
          "name": "MALAKPET KACHEGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.490284,
            17.366083
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DQR",
          "name": "KACHEGUDA DBR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": null,
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "YAKUT PUR(YKA",
          "name": "YAKUT PUR(YKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.48196499999999,
            17.34071
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HPG",
          "name": "HUPPUGUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.474481,
            17.332197
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FM",
          "name": "KACHEGUDA FALAKNUMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.288344,
            17.169347000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMX",
          "name": "TIMMAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.393143,
            17.262932000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UR",
          "name": "UMDANAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.4245182,
            17.3093773
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDVL",
          "name": "BUDVEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.601516,
            23.025515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ADI",
          "name": "AHMEDABAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.607102,
            23.008817
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKF",
          "name": "KANKARIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.610413,
            23.000142
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MAN",
          "name": "MANI NAGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.6294363164,
            22.959728549199998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VTA",
          "name": "VATVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.65515099999999,
            22.917292
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GER",
          "name": "GERATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.6778863,
            22.895426699999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BJD",
          "name": "BAREJADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.705473,
            22.867203999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KANJ",
          "name": "KANIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.725207,
            22.847183
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NEP",
          "name": "NENPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.752481,
            22.819556000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MHD",
          "name": "MAHEMDAVAD KHEDA RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.8013557965,
            22.759953846400002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GTE",
          "name": "GOTHAJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.855718,
            22.694105999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ND",
          "name": "NADIAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.88880999999999,
            22.654716
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UTD",
          "name": "UTARSANDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.921535,
            22.614849
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBRV",
          "name": "KANJARI BORIYAV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.902305,
            21.160311
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NOL",
          "name": "NIYOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.95617,
            21.157909
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHM",
          "name": "CHALTHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            72.996091,
            21.149694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGMR",
          "name": "BAGUMRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.043566,
            21.141419000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GGAR",
          "name": "GANGADHARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.1058944,
            21.1342405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIY",
          "name": "BARDOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.144398,
            21.146312
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TBV",
          "name": "TIMBARVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.182172,
            21.156258
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGRL",
          "name": "MANGROLLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.24268000000001,
            21.145672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MID",
          "name": "MADHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.294162,
            21.137196
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRAI",
          "name": "KAHET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.202399,
            21.153797
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LTV",
          "name": "LOTARVA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.383847,
            21.118142
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VYA",
          "name": "VYARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.481234,
            21.12908
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKRD",
          "name": "KIKAKUI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.502728,
            21.130512
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DSD",
          "name": "DOSVADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.55554,
            21.147743
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "USD",
          "name": "UKAI SONGADH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.603025,
            21.163562
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKKD",
          "name": "LAKKADKOT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.693754,
            21.166264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBAI",
          "name": "BHADBHUNJA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.771183,
            21.166844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NWU",
          "name": "NAVAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.893108,
            21.165523
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KFF",
          "name": "KOLDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.93924200000001,
            21.206379000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPD",
          "name": "CHINCHPADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.992081,
            21.26417
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHTG",
          "name": "KHATGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.101865,
            21.324398
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBH",
          "name": "KHANDBAARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.09831697,
            21.323159150000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BAWD",
          "name": "BHADWAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.13857,
            21.339179
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DWD",
          "name": "DHEKVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.246482,
            21.374812
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDB",
          "name": "NANDURBAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.319097,
            21.377019
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CUE",
          "name": "CHOUPALE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.364777,
            21.382994
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TISI",
          "name": "TISI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.47179,
            21.372813
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNL",
          "name": "RANALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.563104,
            21.331414
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDE",
          "name": "DONDAICHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.617881,
            21.302739000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VKH",
          "name": "VIKHRAN ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.66225600000001,
            21.274379
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNSL",
          "name": "SONSHELU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.73536700000001,
            21.234022
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNK",
          "name": "SINDKHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.784857,
            21.198376
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HOL",
          "name": "HOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.82874699999999,
            21.178209
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NDN",
          "name": "NARDANA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.886199,
            21.149244
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEW",
          "name": "BETAVAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.924335,
            21.132043
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDP",
          "name": "PADSE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.995996,
            21.102507999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRTK",
          "name": "BHORTEX",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.057314,
            21.049008999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AN",
          "name": "AMALNER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.167017,
            21.03491
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TKHE",
          "name": "TAKARKHEDE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.22247700000001,
            21.018946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BHNE",
          "name": "BHONE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.286092,
            21.006447
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DXG",
          "name": "DHARANGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.352237,
            21.024084000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHLK",
          "name": "CHAVALKHEDE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.46157,
            21.022831999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLD",
          "name": "PALDHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.088986,
            21.146062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MIB",
          "name": "NAGPUR MOTI BAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.64428199999999,
            21.26848
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RVH",
          "name": "RAIPUR R-V BLOCK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.772807,
            21.226191999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MNDH",
          "name": "MANDIR HASAUD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.890652,
            21.201827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LAE",
          "name": "LAKHOLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.955513,
            21.189614
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ANMD",
          "name": "ARANG MAHANADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.03562500000001,
            21.155027
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLSN",
          "name": "BELSONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.10389,
            21.113138000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSMD",
          "name": "MAHASAMUND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.183013,
            21.102097
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ARN",
          "name": "ARAND",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.289327,
            21.080325
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMKJ",
          "name": "BHIMKHOJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.3841486,
            21.0462946
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGBR",
          "name": "BAGBAHRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.45769200000001,
            20.979703
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMK",
          "name": "KOMAKHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.510513277,
            20.901385555799997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRAR",
          "name": "KHARIAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.562564,
            20.809717
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPD",
          "name": "NAWAPARA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.662489,
            20.729266
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKNA",
          "name": "LAKHNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.7660362524,
            20.6267890734
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HSK",
          "name": "HARISHANKER RD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.154382,
            20.284399
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TIG",
          "name": "TITLAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.8147164221,
            20.563094809
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TRKR",
          "name": "TUREKALA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.199696,
            20.337936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFK",
          "name": "SIKIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.906517,
            20.474059
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KBJ",
          "name": "KANTABANJI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.27114900000001,
            20.384900000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BUDM",
          "name": "BADMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.016704,
            20.385885000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MRBL",
          "name": "MURIBAHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.335045,
            20.433878
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SFC",
          "name": "SAINTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.08993099999999,
            20.329013
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RNBT",
          "name": "RAHENBATA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.430063,
            20.554779
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DFR",
          "name": "DEOGAN ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.494207,
            20.688591
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLGR",
          "name": "BALANGIR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.498557,
            20.715094
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNRD",
          "name": "BALANGIR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.514083,
            20.852667
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LSX",
          "name": "LOISINGHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.536231,
            20.950218
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KHPL",
          "name": "KHALIPALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.559213,
            21.060872999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DJX",
          "name": "DUNGRIPADI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.59487580000001,
            21.183637700000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRPL",
          "name": "BARPALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.642586,
            21.332902999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRGA",
          "name": "BARGARH ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.792637,
            21.388039
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATS",
          "name": "ATTABIRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.856969,
            21.416457
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBQ",
          "name": "GODBHAGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            83.905097,
            21.479827
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HKG",
          "name": "HIRAKUD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.0718776697,
            21.8876435594
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSRY",
          "name": "JHARSUGUDA R YARD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.09109799999999,
            21.896881999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DTV",
          "name": "DHUTRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.139173,
            21.915685999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNPL",
          "name": "PANPALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.18631,
            21.951046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BEH",
          "name": "BAGDIHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.223691,
            21.995145
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DIH",
          "name": "DHARUADIHI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.291044,
            22.051355
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BMB",
          "name": "BAMRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.35861200000001,
            22.09407
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TGM",
          "name": "TANGARMUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.418434,
            22.134204
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GPH",
          "name": "GARPOS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.459478,
            22.153756
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SOGR",
          "name": "SOGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.519562,
            22.166579000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXN",
          "name": "SONAKHAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.58205099999999,
            22.186062000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GP",
          "name": "RAJ GANGPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.66628899999999,
            22.215278
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXN",
          "name": "KANSHBAHAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.200385,
            20.932262
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLHR",
          "name": "TALCHER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.21416081,
            20.91053519
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLTM",
          "name": "TALCHER THERMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.21712600000001,
            20.905032
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TTPT",
          "name": "TALCHER THERMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.73367300000001,
            22.222144
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLG",
          "name": "KALUNGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.804627,
            22.228538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPO",
          "name": "PANPOSH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.86313200000001,
            22.22844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ROU",
          "name": "ROURKELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.92505809999999,
            22.2355174
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNDM",
          "name": "BONDAMUNDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9552704382,
            22.3505683976
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BGKA",
          "name": "BANGURKELA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9464074011,
            22.3740198995
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSPK",
          "name": "BISPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.94203300000001,
            22.386583
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NXN",
          "name": "NUAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.91578,
            22.448548
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ORGA",
          "name": "ORGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.949201,
            22.497616999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBB",
          "name": "PARBATONIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.918457,
            22.558381999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TATI",
          "name": "TATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.8694214429,
            22.5755618647
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BRBR",
          "name": "BARBERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.877026,
            22.600484
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNRN",
          "name": "KANAROAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.90298541140001,
            22.6647837973
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BANO",
          "name": "BANO",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9352760571,
            22.7199286684
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCZ",
          "name": "MAHABUANG",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.931238,
            22.763217
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRKR",
          "name": "KURKURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.93690600000001,
            22.828158
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKC",
          "name": "PAKRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.951364,
            22.89496
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PKF",
          "name": "POKLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9537296552,
            22.9810196446
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKPR",
          "name": "BAKASPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.9866928088,
            23.0088422742
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JGX",
          "name": "JARIAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.0435794019,
            23.061857490599998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBX",
          "name": "GOVINDPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1327416565,
            23.1219323537
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRRA",
          "name": "KARRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.1658139636,
            23.1666884029
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNGO",
          "name": "BINGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2001212549,
            23.2233502762
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LOM",
          "name": "LODHMA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.2812029169,
            23.2691273563
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLRG",
          "name": "BALASIRING",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.308218,
            23.311612999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTE",
          "name": "HATIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.11923700000001,
            21.157819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ITR",
          "name": "ITWARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.917896,
            26.728077000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTD",
          "name": "MERTA ROAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.014573,
            26.759393000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQW",
          "name": "KHEDULI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.07446200000001,
            26.788798
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "REN",
          "name": "REN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.194547,
            26.844883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JAC",
          "name": "JALSU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.250429,
            26.868694
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JACN",
          "name": "JALSU NANAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.316388,
            26.897349
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNA",
          "name": "DEGANA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.454426,
            26.953522
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GCH",
          "name": "GACHHIPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.560725,
            26.990674000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSRL",
          "name": "BESROLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.66939656189999,
            27.0283068063
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOW",
          "name": "BORAWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.72314899999999,
            27.037532
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKN",
          "name": "MAKRANA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8514568066,
            27.0535465396
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KMNC",
          "name": "KUCHAMAN CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.8979728705,
            27.055138193999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THMR",
          "name": "THATHANA MITHRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.93509671199999,
            27.0383856624
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NYK",
          "name": "NAYA KHARADIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            74.99883200000001,
            27.029479
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NAC",
          "name": "NAWA CITY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.093144,
            27.03169
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GVMR",
          "name": "GOVINDI MARWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.128292,
            26.950042
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GA",
          "name": "GUDHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.179915,
            26.904308
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SBR",
          "name": "SAMBHAR LAKE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.24545599999999,
            26.872814
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "FL",
          "name": "PHULERA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.327459,
            26.885336000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HDA",
          "name": "HIRNODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.361206,
            26.890931
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHND",
          "name": "DHINDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.02319,
            26.283765
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JU",
          "name": "JODHPUR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.039744,
            26.291628000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RKB",
          "name": "RAIKA BAGH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.075277,
            26.304264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JUCT",
          "name": "JODHPUR CANTT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.146431,
            26.33951
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNO",
          "name": "BANAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.2228278314,
            26.3680583662
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JWL",
          "name": "JAJIWAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.30532500000001,
            26.397374
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AAS",
          "name": "ASRANADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.3907543195,
            26.4285105345
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSW",
          "name": "KHERI SALWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.448703,
            26.452849999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPR",
          "name": "PIPAR ROAD JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.509748,
            26.48978
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWF",
          "name": "SATHIN ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.605896,
            26.549322
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UMED",
          "name": "UMED",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.686313,
            26.609843
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KXG",
          "name": "KHARIA KHANGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.737284,
            26.652200999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOTN",
          "name": "GOTAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            73.818142,
            26.697862
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOM",
          "name": "JOGI MAGRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.35622,
            26.018279
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SWM",
          "name": "SAWAI MADHOPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.326705,
            25.961351
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTA",
          "name": "KUSHTALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.313705,
            25.887841
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RWJ",
          "name": "RAWANIA DUNGAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.286156,
            25.828156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMLI",
          "name": "AMLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.423467,
            26.900084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JOB",
          "name": "ASALPUR JOBNER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.232938,
            25.727874
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "IDG",
          "name": "INDARGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.4980458,
            26.9056364
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BOBS",
          "name": "BOBAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.567278,
            26.913378
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SHNX",
          "name": "SHEO SINGH PURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.192147,
            25.639864
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LKE",
          "name": "LAKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.45804600000001,
            23.168701000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MSO",
          "name": "MISROD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.43942899999999,
            23.221979
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HBJ",
          "name": "HABIBGANJ",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.4131428577,
            23.266884384300003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BPL",
          "name": "BHOPAL JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.33622,
            23.273607199999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BIH",
          "name": "BAIRAGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.252499,
            23.261737
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BQE",
          "name": "BAKANIAN BHAUNR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.207965,
            25.574304
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LBN",
          "name": "LABAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.08649199999999,
            25.444815000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPZ",
          "name": "KAPREN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.88511100000001,
            25.198051
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTSC",
          "name": "KOTA JN SOUTH C",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.865501,
            25.145187999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKNT",
          "name": "DAKANIYA TALAV",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.898016,
            25.077840000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDV",
          "name": "DADHDEVI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.90874587280001,
            25.0316757156
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ALNI",
          "name": "ALNIYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.93221182010001,
            24.9874595619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKQ",
          "name": "DHANI KASAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.958203,
            24.933449
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RDT",
          "name": "RAVTHA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.005784,
            24.832608999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DARA",
          "name": "DARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.98301000000001,
            24.788625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KIW",
          "name": "KANWALPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.97333900000001,
            24.730953
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKX",
          "name": "MORAK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.939411,
            24.644606
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RMA",
          "name": "RAMGANJ MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.919686,
            24.541893
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHW",
          "name": "JHALAWAR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.89805,
            24.504973000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DKRA",
          "name": "DHUANKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.82981,
            24.419472000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWM",
          "name": "BHAWANI MANDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7260090143,
            24.354200582
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRLS",
          "name": "KURLAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.68432197690001,
            24.2790919912
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GOH",
          "name": "GAROT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.642842,
            24.191141000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SGZ",
          "name": "SHAMGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64670478090001,
            24.1312068327
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HNU",
          "name": "HANSPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64834400000001,
            24.069844
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVA",
          "name": "SUWASRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.64345572270001,
            24.0200433795
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKH",
          "name": "NATHUKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.60086100000001,
            23.948204999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CMU",
          "name": "CHAU MAHLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.596821,
            23.903499
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TLZ",
          "name": "TALAVLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.55457,
            23.833165
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "THUR",
          "name": "THURIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.53837899999999,
            23.765121
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VMA",
          "name": "VIKRAMGARH ALOT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.51367,
            23.660393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LNR",
          "name": "LUNI RICHHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.500338,
            23.567509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MEP",
          "name": "MAHIDPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.460561,
            23.482264
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RLK",
          "name": "ROHAL KHURD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.189255,
            23.232986
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUD",
          "name": "PHANDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.128302,
            23.208785000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PNWN",
          "name": "PACHWAN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.605607,
            26.91819
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DNK",
          "name": "DHANAKYA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.07788199999999,
            23.215095
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SEH",
          "name": "SEHORE",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.659572,
            26.924224
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDYK",
          "name": "BINDAYAKA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.702559,
            26.92883
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKU",
          "name": "KANAKPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.038514,
            23.234083000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BKTL",
          "name": "BAKTAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.012393,
            23.250527
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRB",
          "name": "PARBATI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.889341,
            23.294103999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JBX",
          "name": "JABRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.834002,
            23.332775
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KPP",
          "name": "KALAPIPAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.779716546,
            23.3530905936
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKOD",
          "name": "CHAKROD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.72347500000001,
            23.381235999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SJP",
          "name": "SHUJALPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.781403,
            23.178154
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UJN",
          "name": "UJJAIN JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.665757,
            23.384268
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MQE",
          "name": "MOHAMMADKHERA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.599245,
            23.378255
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AKD",
          "name": "AKODIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.4864047449,
            23.361400652599997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BLX",
          "name": "BOLAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.430306,
            23.344476
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSH",
          "name": "KALI SINDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.371773,
            23.301733000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KONY",
          "name": "KISONI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.33419289999999,
            23.282480800000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BCH",
          "name": "BERCHHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.2299643091,
            23.2558608677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PUO",
          "name": "PIR UMROD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.159116,
            23.251625
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKC",
          "name": "MAKSI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.0076378418,
            23.2563508677
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVT",
          "name": "SHIVPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.9235679364,
            23.2169677672
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TJP",
          "name": "TAJPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.8603440417,
            23.2111360434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PLW",
          "name": "PINGLESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.801153,
            23.184543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UJNC",
          "name": "UJN C CABIN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.251747,
            26.03271
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPZ",
          "name": "DEVPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.077102,
            26.118939
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SURL",
          "name": "SURELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.030637,
            26.170515
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ISA",
          "name": "ISARDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            76.00108138670001,
            26.2571138478
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRAS",
          "name": "SIRAS",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.93511006349999,
            26.383927808699998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BNLW",
          "name": "BANSTHALI NIWAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.920457,
            26.49509
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHNN",
          "name": "CHANNANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.910778,
            26.592917999999997
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CKS",
          "name": "CHAKSU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.88328200000001,
            26.71363
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAS",
          "name": "SHIVDASPURA PADAMPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.421478,
            23.392187
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BTSD",
          "name": "BHATISUDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.433639,
            23.357472
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PPG",
          "name": "PIPLODA BAGLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.472209,
            23.316776
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "UNL",
          "name": "UNHEL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.5315691821,
            23.260650211799998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PSO",
          "name": "PALSORA MAKRAWA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.60816015629999,
            23.231264309
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ASL",
          "name": "ASLAODA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.637187,
            23.226323
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GRFB",
          "name": "GAMBHIR BR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.689198,
            23.196778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NKI",
          "name": "NAIKHERI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.772245,
            26.814113
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNGN",
          "name": "SANGANER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.786436,
            26.855947
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DPA",
          "name": "DURGAPURA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            75.7898479,
            26.90199441
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BSGD",
          "name": "BAIS GODAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.068176,
            21.21103
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GNQ",
          "name": "GODHANI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.0171081,
            21.231695
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BWRA",
          "name": "BHARATWADA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.91586099999999,
            21.229072
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSWR",
          "name": "KALMESHWAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.807616,
            21.273749
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KOHL",
          "name": "KOHLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.725612,
            21.274259
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNKB",
          "name": "SONKHAMB",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.65915899999999,
            21.26917
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MER",
          "name": "METPANJRA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.590664,
            21.263201
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KATL",
          "name": "KATOL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.558413,
            21.344635
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KLBA",
          "name": "KALAMBHA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.523446,
            21.407727
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TNH",
          "name": "TINKHEDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.52481399999999,
            21.468725
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRKR",
          "name": "NARKHER",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5411,
            21.536803
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DDMT",
          "name": "DARIMETA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.525394,
            21.589996
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PAR",
          "name": "PANDHURNA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.460308,
            21.637046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TEO",
          "name": "TEEGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.42563,
            21.655105000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDKP",
          "name": "GHUNDANKHAPA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.352032,
            21.709709
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CCD",
          "name": "CHICHONDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.334344,
            21.761852
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HTN",
          "name": "HATNA PUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.26283000000001,
            21.783543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTY",
          "name": "MULTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.123187,
            21.919717000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "AMLA",
          "name": "AMLA JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.967468,
            21.904426
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MALK",
          "name": "MALKAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.90589700000001,
            21.896393
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BZU",
          "name": "BETUL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.883002,
            21.946123
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MJY",
          "name": "MARAMJHIRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.949934,
            22.009297
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DHQ",
          "name": "DHARAKHOH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.001987,
            22.126423
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GDYA",
          "name": "GHORADONGRI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.920758,
            22.197033
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBTR",
          "name": "BARBATPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.894738,
            22.248104
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGRD",
          "name": "MAGARDOH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.875226,
            22.281504
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DOH",
          "name": "DHODRA MOHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.85334900000001,
            22.347657
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "POX",
          "name": "POLA-PATTHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.818115,
            22.394548999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KQE",
          "name": "KALA AKHAR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.819849,
            22.488281
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAHL",
          "name": "SAHELI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8158087866,
            22.5155599418
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TAKU",
          "name": "TAKU",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8065090723,
            22.541216959
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KSLA",
          "name": "KESLA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.77251,
            22.538155
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KRTH",
          "name": "KIRATGARH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.755836,
            22.598117000000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JHP",
          "name": "JUHJARPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.846233,
            21.632219
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "HN",
          "name": "HATHBANDH",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            81.946062,
            21.732464
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BYT",
          "name": "BHATAPARA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.044275,
            21.826434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NPI",
          "name": "NIPANIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            82.069143,
            21.905693
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "DGS",
          "name": "DAGORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.017534,
            21.511822
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLRA",
          "name": "SARALA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.03917034669999,
            21.5595350543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SSN",
          "name": "SASON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.042299,
            21.636895
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RGL",
          "name": "RENGALI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.03998,
            21.732794
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "LPG",
          "name": "LAPANGA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.016216,
            21.806503000000003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BXQ",
          "name": "BRUNDAMAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            84.010526,
            21.850125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "JSGR",
          "name": "JHARSUGUDA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.83518699999999,
            20.382175
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BBSN",
          "name": "BHUBANESWAR NEW",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.87790899999999,
            20.432634999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GBK",
          "name": "GOPALPUR BALKDA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.896353,
            20.45107
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KTJI",
          "name": "KATHA JORI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.90156,
            20.466587999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CTC",
          "name": "CUTTACK",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.917437,
            20.498481
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KNPR",
          "name": "KENDRAPARA ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.959078,
            20.538381
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "NRG",
          "name": "NERGUNDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.768912,
            20.569003
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MZY",
          "name": "MACHAPUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.828478,
            20.570067
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "GJTA",
          "name": "GARUDIJHATIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.87382799,
            20.570588309999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "BDPG",
          "name": "BADA PADAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.9113306,
            20.569383900000002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CBT",
          "name": "CHARBATIA",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            85.957487,
            20.56434
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SQQ",
          "name": "SALAGAON",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.986107,
            9.738427999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVK",
          "name": "SIVARAKOTTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.88414,
            9.524914
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLGM",
          "name": "SANKARALINGAPRM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.57086691235357,
            9.354660443863636
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CPM",
          "name": "CHOLAPURAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.11031399999999,
            9.919907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MDU",
          "name": "MADURAI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.065958,
            9.879446999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TDN",
          "name": "TIRUPARANKNDRM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.99090199999999,
            9.824778
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TMQ",
          "name": "TIRUMANGALAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.97402,
            9.695381000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KGD",
          "name": "KALLIGUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.95773700000001,
            9.596405
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VPT",
          "name": "VIRUDUNAGAR JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.24185,
            8.9874125
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SCT",
          "name": "SENGOTTAI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.30733799999999,
            8.963993
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TSI",
          "name": "TENKASI JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.36319769999999,
            9.085394
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KDNL",
          "name": "KADAYNALLUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.431001,
            9.130538
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PBKS",
          "name": "PAMBA KOVIL SHANDY",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.525924,
            9.176539
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SNKL",
          "name": "SANKARANKOVIL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.561026,
            9.452404
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "RJPM",
          "name": "RAJAPALAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.64494,
            9.497497
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVPR",
          "name": "SRIVILLIPUTTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.78924400000001,
            9.463321
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SVKS",
          "name": "SIVAKASI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.8108864374,
            9.4772991528
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TTL",
          "name": "TIRUTTANGAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.240919,
            11.566638000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MKSP",
          "name": "MUKHASA PARUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            79.154962,
            11.591907
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "KKTI",
          "name": "KUTTAKUDI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.989079,
            11.633792
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PRV",
          "name": "PUKKIRIVARI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.943922,
            11.636294000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SRVT",
          "name": "SIRUVATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.873821,
            11.631062
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "CHSM",
          "name": "CHINNA SALEM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.814791,
            11.615910999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MLYR",
          "name": "MELNARIYAPANUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.75084,
            11.596392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TVS",
          "name": "TALAIVASAL",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.592092,
            11.594788
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ATU",
          "name": "ATTUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.5053,
            11.647063000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "PDKM",
          "name": "PEDANAYAKANPALAYAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.096286,
            11.696253
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MGSJ",
          "name": "MAGNESITE JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.468545,
            11.651009
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "ETP",
          "name": "ETTAPUR ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.113415,
            11.671726000000001
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SA",
          "name": "SALEM JN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.400452,
            11.654623999999998
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "VGE",
          "name": "VALAPPADI G HLT",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.2849851605,
            11.671079675900002
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MPLI",
          "name": "MINNAMPALLI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.233642,
            11.671392
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "APN",
          "name": "AYODHYAPATTANAM",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.178121,
            11.662932
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SLME",
          "name": "SALEM EAST",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.162096,
            11.660046
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SXT",
          "name": "SALEM TOWN",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.142621,
            11.654084
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "SAMT",
          "name": "SALEM MARKET",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            78.04678001590001,
            11.7385543
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "OML",
          "name": "OMALUR",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.979547,
            11.761619
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "TOS",
          "name": "TOLASAMPATTI",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.921857,
            11.804210999999999
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MCRD",
          "name": "MECHERI ROAD",
          "zone": null,
          "address": null
        }
      },
      {
        "geometry": {
          "type": "Point",
          "coordinates": [
            77.83200799999999,
            11.807081
          ]
        },
        "type": "Feature",
        "properties": {
          "state": null,
          "code": "MTDM",
          "name": "METUR DAM",
          "zone": null,
          "address": null
        }
      }
    ]
  }

  export default AllTrainStations;