
const getTrainRoutes = async (fromStationCode, toStationCode, dateOfJourney) => {
    const url = `https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations?fromStationCode=${fromStationCode}&toStationCode=${toStationCode}&dateOfJourney=${dateOfJourney}`;
    const options = {
        method: 'GET',
        headers: {
            'x-rapidapi-key': '67404f592bmsha6ca6765514e6cfp14674bjsndc50100b8885',
	    	'x-rapidapi-host': 'irctc1.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.data || [];
    } catch (error) {
        console.error(error);
    }
}


export default getTrainRoutes;