// Function to get autocomplete suggestions
export const getAutocompleteSuggestions = async (inputValue) => {
    if (inputValue.length > 2) { // Only search if input is at least 3 characters long
      const url = `${process.env.REACT_APP_API_URL}/api/autocomplete?input=${inputValue}`;
  
      try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching autocomplete suggestions: ", error);
        return [];
      }
    }
    return [];
  };
  
  // Function to get geocode details from a place_id 
  export const getGeocodeFromPlaceId = async (placeId) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    
    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();
      
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      }
    } catch (error) {
      console.error("Error fetching geocode details: ", error);
    }
    return null;
  };
  